import React, {useState, useEffect, useCallback} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { FormLabelLeft, FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import axios from 'axios'
import {Alerts, WarningModal} from '../components/notify'
import {ServerUrl, IMAGES_URL, Currency, FormatNumber, Token, config, API_URL} from '../components/include'
import Select from 'react-select'
import Country from '../components/country';
import {Type} from 'react-bootstrap-table2-editor';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { compare } from '../components/globalFunction';

 const Settings =()=> {

	const [content, setContent] = useState([])
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });

	const [errors, setErrors] = useState({});
    const [activeStep, setActiveStep] = useState(1);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) }; 
    const [province, setProvince] = useState([]);
    const [lga, setLga] = useState([])

const [address, setAddress] = useState({
    ID:Math.random().toString(36).substring(2,9),
	contactName:'',
	isActive:true,
    address:'',
    country:'',
    state:'',
    lga:'',
    telephone:'',
    postalCode:'',
    city:'',
    order:0,
    deliveryFee:0
})
const handleChangeAddress =(event)=>{
    let {name, value} = event.target;
    if( name ==='isActive' ){
        value = value==='false'?true:false
    }
    setAddress({...address, [name]:value})
}

const handleAddAddress =(event)=>{
	event.preventDefault()
	
    if(address.address===''){
        Alerts('Error!', 'danger', 'Please enter pickup address')

    }else{

        let close =   document.getElementById('closeAddress')
        close.click();
	const newContent = [...content] 
	const newAddress = JSON.parse(newContent[0].pickupAddress);
    const addressList = newAddress.concat(address)
    newContent[0].pickupAddress = JSON.stringify(addressList)
    setContent(newContent)
    submit(newContent[0]);		
    
   setAddress({
       ID:Math.random().toString(36).substring(2,9),
	contactName:'',
	isActive:true,
    address:'',
    country:'',
    state:'',
    lga:'',
    telephone:'',
    postalCode:'',
    city:'',
    order:0,
    deliveryFee:0
})
    }

}
const handleSelectAddress = (option, action)=>{
    setAddress({...address, [action.name]:option.value })
	if(action.name==='state'){
	fetchLga(option.value) 
	}
}

    const steps = [{title: 'Basic Information'}, {title: 'Logo & Social'}, {title: 'Pickup Address'}, {title: 'Global Settings'}]
   
    let currency =   [{ value: 'Naira', label: '₦ - Naira' },
        { value: 'Euro', label: '€ - Euro' },
        { value: 'Pound', label: '£ - Pound' },
        { value: 'Dollar', label: '$ - Dollar' },
        { value: 'Yuan', label: '¥ - Yuan' }]

const getCurrency=(value)=>{
    const result = currency.filter(list=>list.value===value);
        const answer = result.map(c=>c.label);
        return  answer
}
const fetchProvince =()=>{
	
	var sql ="Select ID, stateName from tbl_states order by ID ASC";
	
	const fd = new FormData();
	fd.append("sql", sql);
    fd.append('jwt', Token)
	let url = ServerUrl+'/fetchBySql_controller.php';
	
	axios.post(url, fd, config).then(result=>{ 
		setProvince(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})

}

const fetchLga =(stateId)=>{
	
	var sql ="Select ID, stateId, lgaName from tbl_lga where stateId='"+stateId+"' order by ID ASC";
	 
	const fd = new FormData();
	fd.append("sql", sql);
    fd.append('jwt', Token)
	let url = ServerUrl+'/fetchBySql_controller.php';
	
	axios.post(url, fd, config).then(result=>{ 
		setLga(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})

}

const fetchNameByField =(Id, table, field)=>{
	const result = table.filter((list,_)=>list.ID===Id);
  const answer = result.map((ans)=>ans[field])
	return answer
  }

const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		newContent[0][name]= value
		setContent(newContent);
	}


    const handleOnClickStepper = (step) => {
            setActiveStep(step); 
        }
    
        const handleOnClickNext = useCallback(() => {
            setActiveStep(activeStep + 1);
        }, [activeStep])
        
        const handleOnClickBack = useCallback(() => {
            setActiveStep(activeStep - 1);
        }, [activeStep])


        const [picture, setPicture] = useState({
            logoImage:'',
            banner:''
        })
    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        let url = API_URL+'/company/display/all';
        axios.get(url, config).then(result=>{ 

			if(Array.isArray(result.data.data)){
            setContent(result.data.data)
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
    
    


      const  handleFile = function(fieldName){
        return function(newValue){                
       readURL(newValue, fieldName);   
       }
       
      } 
    
     
const readURL = (input, fileId)=>{    
   
    let displayMessage = '';
      var fileName =  document.getElementById(input.target.name).value;
      
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="ico" || extFile==="ICO" || extFile==="GIF"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 3) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 3mb';
       document.getElementById(input.target.name).value = '';
       setPicture({...picture, [input.target.name]:''})
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
           var reader = new FileReader();//Initialize FileReader.
          reader.onload = function (e) {
       
          const img = document.getElementById(fileId);
                     img.src = e.target.result             
              
          };
          reader.readAsDataURL(input.target.files[0]);  
          setPicture({...picture, [input.target.name]:input.target.files[0]})  
        }
    }
    }else{
        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 3mb'
       document.getElementById(input.target.name).value = '';
       setPicture({...picture, [input.target.name]:''})
    }   
    
    if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
} 

    const handleSelect = (option, action)=>{
        const newContent =JSON.parse(JSON.stringify(content))   
        newContent[0][action.name] = option.value;
        setContent(newContent);
    };
  

   function submit(formData){  
    setNotice({...notice, 
      isLoading: true})     
         
      
      const fd = new FormData();
      for(let k in formData){
        fd.append(k, formData[k])
      }
     console.log(formData)
         if(picture.logoImage!==''){
            fd.append('image', picture.logoImage);
            
        } 
        if(picture.banner!==''){
            fd.append('bannerImage', picture.banner);
            
        } 
       
         let url = API_URL+'/company/update';
        axios.post(url, fd, config)
        .then(response =>{
         if(response.data.type ==='success'){
            
            Alerts('Saved!', 'success', response.data.message)
           // window.open('/general/settings', '_self')
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            })
            setPicture({
                logoImage:'',
                banner:''
            })
        }) 
}


const  tableAddressHeader = [
	{dataField: 'contactName',  text: 'Contact Person'},
    {dataField: 'address',  text: 'Address'},	
    {dataField: 'state',  text: 'State',  sort: true, editable: false, formatter:(cell, row)=><div style={{display:'block',  width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{row.country==='Nigeria'?<div>{fetchNameByField(row.state, province, 'stateName')} {fetchNameByField(row.lga, lga, 'lgaName')}</div>:'Others'}</div>},	
    {dataField: 'deliveryFee',  text: 'Delivery Fee', formatter:(cell, row)=>Currency+FormatNumber(cell)},
	{dataField: 'isActive',  text: 'Active', editor: {
		type: Type.SELECT, options:[{value:true, label:'Active'},
		{value:false, label:'In Active'}]
      }, formatter:(cell, row)=>row.isActive===true? <div style={{cursor:'pointer'}} className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div style={{cursor:'pointer'}}className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>},
      
	  {dataField: 'ID', text: 'Action', formatter: (cell)=><div>
	  <WarningModal  handleAction={()=>handleRemoveAddress(cell)} mID={cell} />
		  <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>
	  </div>, editable: false},

			  ];
		 
const handleRemoveAddress =(ID)=>{
let close =   document.getElementById('btnWarningDialog-'+ID)
    close.click();

const newContent = [...content]
    const newAddress = JSON.parse(newContent[0].pickupAddress);
    newContent[0].pickupAddress = JSON.stringify(newAddress.filter(list=>list.ID!==ID))
    submit(newContent[0])
   // setContent(newContent)
}
	
	const TableAddressRecord=(props)=>{
		const handleUpdate=(column, newValue, ID)=>{     		
			 const newContent = [...content]
			const newAddress = JSON.parse(newContent[0].pickupAddress);
			const otherAddress = newAddress.filter(list=>list.ID!==ID)
			const addressData = newAddress.filter((list)=>list.ID===ID)	
			addressData[0][column.dataField] = newValue
			const currentAddress = otherAddress.concat(addressData)
            newContent[0].pickupAddress = JSON.stringify(currentAddress)
            
            setContent(newContent)
			//submit(newContent[0])  
			
		} 
				 const { SearchBar } = Search;
				 
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='ID' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										
								 
	<div className="dt-buttons btn-group"> 
		 <button data-toggle="modal"   data-target='#addAddress' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New Address</button>
 </div>                   
	 </div>
										 
	<BootstrapTable
				{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
					cellEdit={ cellEditFactory({
						mode: 'dbclick',
						blurToSave: true,
						autoSelectText: true,
			  beforeSaveCell: (oldValue, newValue, row, column) => {
				if(oldValue !==newValue){
				handleUpdate(column, newValue, row.ID);
				
			   return true;
			  }
			  
			}
					  }) }
				/>
			</div>
			)
		}
</ToolkitProvider>
			  }



    useEffect(() => {    
   fetchContent()
   fetchProvince()
    }, []); 

        return (<Template 
			menu="General"
		submenu="Settings">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="System Settings" description='Update system settings here' icon='fa fa-cogs'>
  
					</PageHeader>



{/* The Page Body start here */}
<div className="page-body">
 
<div className="modal fade " id='addAddress' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add Pickup Address</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
			  <div className="form-group row">
					<div className="col-sm-4">
						<FormLabel name ="Contact Person" toolTip={'Enter contact name'} />
					</div>
									
						<div className="col-sm-8 ">
						<input type="text" className="form-control" onChange={handleChangeAddress}  name="contactName" value={address.contactName} />
						</div>
				</div>
				
				<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Country" toolTip={'Select Country'} />
					</div>
									
						<div className="col-sm-8 ">
							<Country handleSelect={handleSelectAddress} country={address.country} />
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="State " toolTip={'Select State / province'} />
					</div>
									
						<div className="col-sm-8 ">
						{address.country==='Nigeria'?
						<Select  
				  options={province&&province.map((state, idx)=> {
					return {key:idx, value: state.ID, label: state.stateName, index:idx };
				  })}
onChange={handleSelectAddress}  name="state"  value={{value: address.state, label: fetchNameByField(address.state, province, 'stateName')}} styles={selectStyles} />:'Others'} 

							</div>
				</div>


		{address.country==='Nigeria'?		<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Lga" toolTip={'Enter Lga'} />
					</div>
									
						<div className="col-sm-8 ">
			<Select   options={lga&&lga.map((lg, idx)=> {
                         return {key:idx, value: lg.ID, label: lg.lgaName,index:idx };
                                  })}
onChange={handleSelectAddress}  name="lga" value={{value: address.lga, label: fetchNameByField(address.lga, lga, 'lgaName')}} styles={selectStyles} />

							</div>
				</div>:''}

				<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="City" toolTip={'Enter city'} />
					</div>
									
						<div className="col-sm-8 ">
						<input type="text" className="form-control" onChange={handleChangeAddress}  name="city" value={address.city} />
							</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Address" toolTip={'Enter Address'} />
					</div>
									
						<div className="col-sm-8 ">
						<input type="text" className="form-control" onChange={handleChangeAddress}  name="address" value={address.address} />
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Telephone" toolTip={'Enter Telephone'} />
					</div>
									
						<div className="col-sm-8 ">
						<input type="text" className="form-control" onChange={handleChangeAddress}  name="telephone" value={address.telephone} />
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-4">
						<FormLabel name ="Zip code" toolTip={'Enter Zip / postal code'} />
					</div>
									
						<div className="col-sm-8 ">
						<input type="text" className="form-control" onChange={handleChangeAddress}  name="postalCode" value={address.postalCode} />
						</div>
				</div>
                <div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Delivery Fee" toolTip={'Delievry amount to this store'} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" onChange={handleChangeAddress}  name="deliveryFee" value={address.deliveryFee} />
							</div>
                            <div className="col-sm-4 "><b>{address.deliveryFee!==0?Currency+FormatNumber(address.deliveryFee):''}</b></div>
				</div>
                <div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Display Order" toolTip={'Display Order'} />
					</div>
									
                    <div className="col-sm-4 ">
						<input type="number" className="form-control" onChange={handleChangeAddress}  name="order" value={address.order} />
							</div>
                           
				</div>

                <div className="form-group row">
			<div className="col-sm-4">
					<FormLabel name ="Is Active" toolTip={'Check to display this address to customer'}  />
			</div>
							
				<div className="col-sm-5 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={address.isActive} onChange={handleChangeAddress} name="isActive" checked={address.isActive}  />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>	
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeAddress' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={handleAddAddress}  className="btn btn-inverse">Save</button>
              </div>
          
		  </div>
      </div>
  </div>

<div className="row">
<div className="col-sm-12">

				{/* <!-- Promotion container start here  --> */}
				
	<div className="card z-depth-0">	
		<div className="card-header">
			<h5>Initial Setup</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">	
        <div className="container">
        
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
       {content&&content.map((data, idx)=> <div key={idx} >
        {activeStep === 1 ? 

<div className="row" id="basic">		    
			<div className="col-md-12">				
			<div className="card z-depth-0">	
		<div className="card-block">
			<div className="row">	
			<section className="col-md-6">
            
            <div className="form-group">
            <FormLabelLeft name ="Company Name" toolTip={'Company Name'} />
                     <input type="text" className={errors.companyName  ? 'form-control formerror' : 'form-control'} name="companyName" onChange={handleChange} value={data.companyName} />
                     <span className="error">{errors.companyName}</span>
            </div>
       
            <div className="form-group">
            <FormLabelLeft name ="Motto " toolTip={'Company Motto'} />
            <input type="text" className='form-control' name="motto" onChange={handleChange} value={data.motto} />
            </div>

              <div className="form-group">
            <FormLabelLeft name ="Registration No " toolTip={'Registration Number'} />
            <input type="text" className='form-control' name="regNo" onChange={handleChange} value={data.regNo} />
               </div>

           </section>	

	<section className="col-md-6">
    
          <div className="form-group">
             <FormLabelLeft name ="Software Starting Date" toolTip={'The date software started functioning'} />
            <input type="date" className='form-control' name="softwareDate" onChange={handleChange} value={data.softwareDate} />
              </div>
                      
                        <div className="form-group">
            <FormLabelLeft name ="Abbreviation " toolTip={'Company Abbreviation'} />
            <input type="text" className='form-control' name="abbreviation" onChange={handleChange} value={data.abbreviation} />
                        </div>


                        <div className="form-group">
            <FormLabelLeft name ="Office Address " toolTip={'Office Address'} />
            <textarea  type="text" className="form-control " value={data.address} name="address" rows="3" onChange={handleChange}  >{data.address}</textarea>
                        </div>

	</section>
                </div>
                </div></div>
            
        <div className="card z-depth-0">	
		<div className="card-block">
        <h3 >Contact Information </h3><hr/><br/> 
                        <div className="row">
                        
			<section className="col-md-6">

            <div className="form-group">
            <FormLabelLeft name ="City" toolTip={'Company City'} />
            <input type="text" className='form-control' name="city" onChange={handleChange} value={data.city} />
                     
                                 </div>
       
                        <div className="form-group">
             <FormLabelLeft name ="Country" toolTip={'Company Country'} />
             <Country handleSelect={handleSelect}  country={data.country} />
                        </div>

                        <div className="form-group">
            <FormLabelLeft name ="Province/State " toolTip={'The company state'} />
            
<input type="text" className='form-control' name="province" onChange={handleChange} value={data.province} />
                        </div>

                        
                        </section>	

	<section className="col-md-6">
    <div className="form-group">
    <FormLabelLeft name ="Email Address" toolTip={'Default Email'} />
    <input type="email" className='form-control' name="email" onChange={handleChange} value={data.email} />
                        </div>

                      
                        <div className="form-group">
            <FormLabelLeft name ="Telephone" toolTip={'Company Mobile'} />
            <input type="text" className='form-control' name="mobile" onChange={handleChange} value={data.mobile} />
                        </div>


                        <div className="form-group">
            <FormLabelLeft name ="Whatsapp Mobile " toolTip={'Whatsapp Number'} />
            <input type="text" className='form-control' name="whatsappPhone" onChange={handleChange} value={data.whatsappPhone} />
                        </div>

									</section>
		</div>
										  
  	</div></div></div>
			

</div>
 :  activeStep === 2 ?  
<div className="row" id="logo">		    
			<div className="col-md-12">				
			<div className="card z-depth-0">	
	<div className="card-block">
			<div className="card z-depth-0">	
			<div className="card-block">
			<div className="row">								
			<section className="col-md-6">
            <div className="form-group">
                                        
							<label className="btn btn-inverse" htmlFor="logoImage" > <i className="fa fa-upload"></i>Upload  Logo </label>
                    <input id="logoImage"  onChange={handleFile('cLogo')} className="form-control file" name="logoImage" type="file" accept="image/*" /> 
					
                    	<div className="text-red" >
			<strong>Size 500 kb,</strong>gif|jpg|png|jpeg Only Allow
		</div>	
										</div>

										
										<div className="form-group">
						<label className="btn btn-inverse" htmlFor="banner" > <i className="fa fa-upload"></i>Upload  Banner </label>
                    <input id="banner"  onChange={handleFile('banner1')} className="form-control file" name="banner" type="file" accept="image/*" /> 
						<div className="text-red">
			<strong>Size 500 kb,</strong>gif|jpg|png|jpeg Only Allow</div>	
										</div>
										
									</section>	
									

									
                            <section className="col-md-6">

                            <div className="form-group"> 
                            {data.logo!==''&&picture.logoImage===''?
            
            <img id="logo1" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+'/noImage.png'}}  src={`${IMAGES_URL}/about/${data.logoUrl}`}  style={{ height:'50px'}} title="Company Logo"  alt="Company Logo" /> :

            <img id="cLogo"  onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+'/noImage.png'}} src={picture.logoImage}  className="img-100" title="Company Logo"  alt="Company Logo" />       }
                                            </div>

                                          
                                            <div className="form-group"> 
                            {data.banner!==''&&picture.banner===''?

     <img id="banner2" className="user-img img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+'/about/background.png'}} src={`${IMAGES_URL}/about/${data.banner}`}  style={{display:'block', height:'200px',width:'300px'}} title="Banner" alt='Banner'  /> :

                <img id="banner1" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+'/about/background.png'}} src={picture.banner}  style={{display:'block',height:'200px',width:'300px'}} title="Banner" alt='' />     }
                                            </div>
                                
									</section>
									</div>
	
        </div>
			
			</div>		  


		{/* 	<!-- Social here  --> */}

			<div className="card z-depth-0">
	
			<div className="card-block">
				<div className="row">

				<div className="col-sm-12" >
				<h3 >Social Media </h3><hr/><br/>
				</div>
				</div>
			<div className="row">
			<section className="col-md-6">
           
            <div className="form-group">
            <FormLabelLeft name ="Website Address" toolTip={'Enter Website Address'} />
            <input type="website" className='form-control' name="website" onChange={handleChange} value={data.website} />
                        </div>
                        
                        <div className="form-group">
            <FormLabelLeft name ="YouTube Page" toolTip={'Enter YouTube Page Link'} />
            <input type="website" className='form-control' name="youtube" onChange={handleChange} value={data.youtube} />
                        </div>

                        <div className="form-group">
            <FormLabelLeft name ="App Link " toolTip={'Enter App Link '} />
            <input type="website" className='form-control' name="app" onChange={handleChange} value={data.app} />
                        </div>
     
     </section>	
									
									<section className="col-md-6">
                                        
                                    <div className="form-group">
           
            <FormLabelLeft name ="Facebook Page " toolTip={'Enter Facebook Page Link '} />
            <input type="website" className='form-control' placeholder="https://www.facebook.com" name="facebook" onChange={handleChange} value={data.facebook} />
                        </div>

                        <div className="form-group">
            <FormLabelLeft name ="Twitter Page " toolTip={'Enter Twitter Page Link '} />
            <input type="website" className='form-control' placeholder="https://www.twitter.com" name="twitter" onChange={handleChange} value={data.twitter} />
                        </div>

                        <div className="form-group">
            <FormLabelLeft name ="Linkedin Page " toolTip={'Enter Linkedin Page Link '} />
            <input type="website" className='form-control' placeholder="https://www.twitter.com" name="linkedin" onChange={handleChange} value={data.linkedin} />
                        </div>
		
									</section>

									</div>
									</div>
			
			</div>		  
			</div>
			
			</div>		
			</div>
			
</div>
: activeStep === 3 ?  
	<div className="row" id="contact">	    
			<div className="col-md-12">				
			<div className="card z-depth-0">	
            <div className="card-block">
    <div className="col-md-12 table-responsive">
		 <TableAddressRecord data={JSON.parse(data.pickupAddress).sort(compare)}  columns={tableAddressHeader}  />  
            </div>
           
    				
		  </div></div></div>
			
</div>
: <div className="row" id="global">		    
			<div className="col-md-12">				
			<div className="card z-depth-0">	
		<div className="card-block">
			<div className="row">	
			<section className="col-md-6">
 
			<div className="form-group">
            <FormLabelLeft name ="Display Currency Symbol " toolTip={'Choose where to display currency symbol'} />
                    <Select options={[
                            { value: 'Disable', label: 'Disable' },
                            { value: 'Before', label: 'Before' },
                            { value: 'After', label: 'After' }
                        ]} 
                    onChange={handleSelect} name="currencySymbol" value={{value:data.currencySymbol, label:data.currencySymbol}} styles={selectStyles} />	
                </div>

            <div className="form-group">
            <FormLabelLeft name ="Currency " toolTip={'Choose default currency'} />
            <Select  options={currency}
   onChange={handleSelect}  name="currency" value={{value:data.currency, label:getCurrency(data.currency)}}  styles={selectStyles}
            />
            											
        </div>

        <div className="form-group">
        <FormLabelLeft name ="Theme " toolTip={'Choose software theme'} />
        <Select  options={[
                    { value: 'Default', label: 'Default' },
                    { value: 'Dark Mode', label: 'Dark Mode' }
                ]} 
   onChange={handleSelect}  name="theme" value={{value:data.theme, label:data.theme}}  styles={selectStyles}
            />										
        </div>
          
         
        </section>	

<section className="col-md-6"><br/>

                <div className="form-group">
                <FormLabelLeft name ="Allow Profile Update " toolTip={'Allow Profile Update By Customer'} />
            <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={data.profileUpdate ==="Yes"} onChange={handleChange} value="Yes" name="profileUpdate" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={data.profileUpdate ==="No"} onChange={handleChange} value="No" name="profileUpdate"  />
										<i className="helper"></i>No</label></div>

                    </div>
            
            </div>	
            

                    <div className="form-group">
                    <FormLabelLeft name ="Play Sound " toolTip={'Choose to play sound on every action'} />    
            <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={data.playSound ==="Yes"} onChange={handleChange} value="Yes" name="playSound" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={data.playSound ==="No"} onChange={handleChange} value="No" name="playSound"  />
										<i className="helper"></i>No</label></div>

                    </div>
            
            
            	 </div>					

                    <br/>

                    <div className="form-group">
                    <FormLabelLeft name ="Maintenance Mode " toolTip={'Maintenance Mode'} /> 
                    <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={data.maintenance ==="Yes"} onChange={handleChange} value={"Yes"} name="maintenance" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={data.maintenance ==="No"} onChange={handleChange} value={"No"} name="maintenance"  />
										<i className="helper"></i>No</label></div>

                    </div>
                    </div>
                </section>
                </div>
										  
  	</div></div>
      
      <div className="card z-depth-0">
	
    <div className="card-block">
        <div className="row">

        <div className="col-sm-12" >
        <h3 >Advance Settings </h3><hr/><br/>
        </div>
        </div>
       
        <div className="row">	
			<section className="col-md-6">
            
            <div className="form-group">
            <FormLabelLeft name ="Default Tax (%) " toolTip={'Tax to apply at checkout'} /> 
            <input type="number" className='form-control' name="tax" onChange={handleChange} value={data.tax} />
              </div>
              <div className="form-group">
              <FormLabelLeft name ="Email Validation Required After Registration " toolTip={'Choose yes for user to verify their email before activation'} /> 
                    <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={data.isValidRequire ==="Yes"} onChange={handleChange} value="Yes" name="isValidRequire" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={data.isValidRequire ==="No"} onChange={handleChange} value="No" name="isValidRequire"  />
										<i className="helper"></i>No</label></div>

                    </div>
                    </div>
                    
                </section>	

	<section className="col-md-6">
			   
        <div className="form-group">
        <FormLabelLeft name ="KM Price " toolTip={'Price Per Kilometer'} /> 
            <label > </label>
            <input type="number" className='form-control' name="kmPrice" onChange={handleChange} value={data.kmPrice} />
                        </div>

                        <div className="form-group">
                        <FormLabelLeft name ="Enable Comment on Product Review " toolTip={'Allow comment box on product reveiew'} /> 
                    <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={data.allowComment ==="Yes"} onChange={handleChange} value={"Yes"} name="allowComment" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={data.allowComment ==="No"} onChange={handleChange} value={"No"} name="allowComment"  />
										<i className="helper"></i>No</label></div>

                    </div>
                    </div>
			</section>
				</div>

           </div>
    
    </div>
			
			</div></div>}
            </div>)}
            
    <button onClick={activeStep === steps.length ? ()=>submit(content&&content[0]) : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Finish</i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
            </div>
</div>
   </div>
                </div>
            </div>
            


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Settings) 