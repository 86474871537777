import React, {useState, useEffect} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import {Alerts} from '../components/notify'
import {ServerUrl, FormatNumber, Currency, config, Token, API_URL} from '../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import {  compareSort, convertDate,  longDate} from '../components/globalFunction'
import {Bar, Line, Pie} from 'react-chartjs-2'

 const Dashboard =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });

    const [logList, setLogList] = useState([])
    const [customerGender, setCustomerGender] = useState([])
    const [order, setOrders] = useState([])
    const [topProduct, setTopProduct] = useState([])
    const [products, setProducts] = useState([])
    const [revenue, setRevenue] = useState([])
    
    var today = new Date().toISOString().slice(0,10);
    var date = new Date()
    var first = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var last = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
  
    var firstDayOfYear = new Date(date.getFullYear(), 0, 1, 23, 59, 59)
  var lastDayOfYear = new Date(date.getFullYear()+1, 0, 0, 23, 59, 59)

  const [content, setContent] = useState({
    customer:0,
    ticket:0,
    orderNotice:0,
    pendingOrder:0
  })

    const fetchContent =()=>{
       
        let url = API_URL+'/customer/statistics';
        axios.get(url, config)
        .then(result =>{ 

            if(result.data.status===200){
                setContent({
                    customer:result.data.customer.total,
                    ticket:result.data.ticket.total,
                    orderNotice:result.data.order.filter(list=>list.is_new==='Yes'),
                    pendingOrder:result.data.order.filter(list=>list.order_status==='Processing')
                })
                setCustomerGender(result.data.gender)
                setOrders(result.data.order)
            }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })

  }


  const genderCustomer = {
    labels: customerGender.map((data)=>data.gender),
    datasets: [
      {
        label: 'Top 5',
        backgroundColor: [
          '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#6800B4'],
        hoverBackgroundColor: [
        '#501800', '#4B5000', '#175000', '#003350', '#35014F'],
        data: customerGender.map((data)=>data.data), //[65, 59, 80, 81, 56]
      }
    ]
  }


    const fetchProducts = async()=>{
        let url = API_URL+'/company/products';
        const {data} = await axios.get(url, config)
        setProducts(data.data)
    }

    
    const tableTodayHeader = [

        {dataField: 'orderId',  text: 'ID',  sort: true, editable: false},
        {dataField: 'orderStatus',  text: 'Order Status',  sort: true, editable: false, formatter:(cell, row)=><span 
    
    className={`badge  m-0 
                    ${cell==='Delivered'?'badge-success':
                    cell==='In Progress'?'badge-info':
                    cell==='Canceled'?'badge-danger':
                    cell==='Pending'?'badge-dark':
                    cell==='Delayed'?'badge-warning':
                    'badge-accent'}`}> {cell}</span>},
        
{dataField: 'customerDetails',  text: 'Customer',  sort: true, editable: false, formatter:(cell, row)=><span>{JSON.parse(row.customerDetails)[0].fullname}</span>},
        {dataField: 'dateCreate',  text: 'Created On',  sort: true, editable: false},
        {dataField: 'orderTotal',  text: 'Total ('+Currency+')',  sort: true, editable: false, formatter:(cell)=>FormatNumber(cell)}
         ];

         const TableTodayRecord=(props)=>{
            const { SearchBar } = Search;
            const { ToggleList } = ColumnToggle;
            //const { ExportCSVButton } = CSVExport;
            const customTotal = (from, to, size) => (
               <span >&nbsp;Showing { from } to { to } of { size } items</span>
             );
            const options = {
               showTotal: true,
             paginationTotalRenderer: customTotal,
             sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
             { text: 'All', value: props.data.length }]
              
             };
            return  <ToolkitProvider search columnToggle 
                        keyField='orderId' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                <div >
                                    
                        <BootstrapTable 
                           { ...props.baseProps }
                           striped
                       hover
                       condensed
                       noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                   pagination={paginationFactory(options) }
                 
                                    />
                                </div>
                                )
                            }
         </ToolkitProvider>
         }
         

         const topSellingOption = {
            labels: topProduct.map((data)=>data.label),
            datasets: [
              {
                label: 'Total Order',
                backgroundColor: [
                    '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b'],
                  hoverBackgroundColor: [
                  '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
                data: topProduct.map((data)=>data.data)
              }
            ]
          }


          const fetchTopProduct =()=>{
           var sql = "Select p.productName as label,  count(*) as data from tbl_product_orders s, tbl_products p where s.status ='Complete' and p.code = s.product_code and   s.created_at >= '"+convertDate(first)+"' and s.created_at <= '"+convertDate(last)+"' group by label order by  data DESC  LIMIT 10"

            const fd = new FormData();
            fd.append("sql", sql);
            fd.append("jwt", Token);
            let url = ServerUrl+'/fetchBySql_controller.php';
            axios.post(url, fd, config).then(result=>{ 
                if(Array.isArray(result.data)){
                    setTopProduct(result.data)
              }
                
            })
            .catch((error)=>{
                Alerts('Error! - Top product', 'danger', error.message)
            })
        }
        
       
        const getFullMonth =(date)=>{
            const ndate  = new Date(date)
            const month = ndate.toLocaleString('default', {month:'long'})
            return month
          }
          const revenueChart = {
            labels: revenue.map((data)=>getFullMonth(data.label)),
            datasets: [
              {
                label: 'Amount '+Currency,
                fill: false,
                lineTension: 0.5,
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: revenue.map((data)=>data.data)
              }
            ]
          }

          const fetchRevenue =()=>{
            var sql = "Select created_at as label, sum(order_total) as data from tbl_orders where created_at >=  '"+convertDate(firstDayOfYear)+"' and created_at  <= '"+convertDate(lastDayOfYear)+"' GROUP BY MONTH(created_at) order by label  "
 
             const fd = new FormData();
             fd.append("sql", sql);
             fd.append("jwt", Token);
             let url = ServerUrl+'/fetchBySql_controller.php';
             axios.post(url, fd, config).then(result=>{ 
                
                 setRevenue(result.data)
             })
             .catch((error)=>{
                 Alerts('Error! - Revenue', 'danger', error.message)
             })
         }


         const fetchLog =()=>{
           
            const fd = new FormData();
            fd.append("url", "loginreport.json");
            fd.append("actionType", "getData");
            fd.append("jwt", Token);
            let url = ServerUrl+'/fetch_jsonData.php';
            axios.post(url, fd, config).then(result=>{  
                setLogList(JSON.parse(result.data))
              
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
        }

        const tableLogHeader = [  
           
        {dataField: 'fullname', text: 'Name', headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},        
        
            {dataField: 'dateTime',  text: 'Login On', headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},

            {dataField: 'ip', text: 'IP Address', headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},  
                
            {dataField: 'browser', text: 'Browser', headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},
            {dataField: 'device', text: 'Device',headerStyle: {backgroundColor: '#f0ff4d'}, editable:false}
               
             ];
            
        
       
            
             const TableLogRecord=(props)=>{
                 const { SearchBar } = Search;  
                 const customTotal = (from, to, size) => (
                    <span >&nbsp;Showing { from } to { to } of { size } items</span>
                  );
                 const options = {
                    showTotal: true,
                  paginationTotalRenderer: customTotal,
                  sizePerPageList: [{text: '5', value: 5}, {text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
                  { text: 'All', value: props.data.length }]
                   
                  };
                 return  <ToolkitProvider search columnToggle 
                             keyField='order' data={ props.data } columns={ props.columns } >
                                 
                                 {
                                     props => (
                                     <div >
                    
                             <BootstrapTable 
                                { ...props.baseProps }
                                striped
                            hover
                            condensed
                            noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                        pagination={paginationFactory(options) }
                      
                                         />
                                     </div>
                                     )
                                 }
              </ToolkitProvider>
              }


           
   useEffect(()=>{
    fetchContent()
    fetchProducts()
    fetchTopProduct()

    fetchRevenue()
    fetchLog()
},[]);
 

        return (<Template 
            menu="Dashboard"
            submenu="">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="Dashboard" description='System usage statistics' icon='feather icon-home'>
                        </PageHeader>
                        


{/* The Page Body start here */}
<div className="page-body">
         
<div className="row">
        <div className="col-lg-12">
		  <div id="contentHere" >

      <div className="row">

<div className="col-md-6 col-xl-3">
    <div className="card user-widget-card bg-c-blue">
        <div className="card-block">
            <i className="feather icon-user bg-simple-c-blue card1-icon"></i>
            <h4>{content.customer}</h4>
            <p>Registered Customer</p>
            <a href="/customers" className="more-info">More Details</a>
        </div>
    </div>
</div>

<div className="col-md-6 col-xl-3">
    <div className="card user-widget-card bg-c-pink">
        <div className="card-block">
            <i className="feather icon-shopping-cart bg-simple-c-pink card1-icon"></i>
            <h4>{content.pendingOrder.length}</h4>
            <p>Pending Order</p>
            <a href="/sales/orders" className="more-info">More Details</a>
        </div>
    </div>
</div>


<div className="col-md-6 col-xl-3">
<div className="card user-widget-card bg-c-green">
    <div className="card-block">
        <i className="feather icon-book bg-simple-c-green card1-icon"></i>
        <h4>{products&&products.length}</h4>
        <p>All Products</p>
        <a href="/products" className="more-info">More Details</a>
    </div>
</div>
</div>


<div className="col-md-6 col-xl-3">
        <div className="card user-widget-card bg-c-yellow">
            <div className="card-block">
                <i className="feather icon-pie-chart bg-simple-c-yellow card1-icon"></i>
                <h4>{content.ticket}</h4>
                <p>Open Ticket</p>
                <a href="/communication/tickets" className="more-info">More Details</a>
            </div>
        </div>
    </div>

      </div>






      <div className="card">
<div className="card-body">


<div className="row">
<div className="label-icon">
<button className="btn btn-app" onClick={()=>window.open('/sales/orders', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-shopping-cart fa-2x"></i> 
    <label className="badge badge-primary badge-top-right">{content.orderNotice.length}</label>
    <br/>Orders </button>
</div>&nbsp;&nbsp;

<button className="btn btn-app" onClick={()=>window.open('/product-review', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-envelope fa-2x"></i> 
    <br/>Reviews </button>
&nbsp;&nbsp;

<button className="btn btn-app" onClick={()=>window.open('/users', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-users fa-2x"></i> 
    <br/>Users </button>
&nbsp;&nbsp;



<button className="btn btn-app" onClick={()=>window.open('/general/database/restore', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-database fa-2x"></i>
    <br/>Database </button>
&nbsp;&nbsp;

<button className="btn btn-app" onClick={()=>window.open('/reports/dailysales', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-money fa-2x"></i> 
    <br/>Sales </button>
&nbsp;&nbsp;


<button className="btn btn-app" onClick={()=>window.open('/general/sitemap', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-map fa-2x"></i> 
    <br/>Sitemap </button>
&nbsp;&nbsp;

<button className="btn btn-app" onClick={()=>window.open('/general/settings', '_self')} style={{height:'70px', width:'100px'}} ><i className="fa fa-cogs fa-2x"></i> 
    
    <br/>Settings  </button>
&nbsp;&nbsp;

</div>


</div>
</div>

<div className="row">
        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
          <div className="card">
          <div className="card-header">
            <strong> Today Order ({longDate(new Date())})</strong>
				</div>
            <div className="card-body">

            <div className="table-responsive">
          
          {notice.isDataFetching ? <TableLoader />:
   <TableTodayRecord data={order&&order.filter(list=>list.dateCreate===today)} columns={tableTodayHeader}  />}   
</div>
            </div>
            </div>
          </div>
        </div>


      <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> Top Selling Products ({getFullMonth(new Date())})</strong>
				</div>
             <div className="card-body">

              <Bar
          data={topSellingOption}
          options={{ legend:{
              display:false,
              position:'right'
            }
          }}
        />  
             </div>
          </div>
        </div></div>




        <div className="row">
      <div className="col-8 col-lg-8 col-xl-8">
          <div className="card">
		   <div className="card-header"><strong>
     Today User Login (Year: {convertDate(today)})</strong>
				</div>
             <div className="card-body">

             <div className="table-responsive">
          
          {notice.isDataFetching ? <TableLoader />:
   <TableLogRecord data={logList.filter(item=>item.dateCreate ===today).sort(compareSort)} columns={tableLogHeader}  />}   
    </div>

             </div>
          </div>
        </div>

        <div className="col-4 col-lg-4 col-xl-4">

<div className="card">
<div className="card-header">
              <strong> Customers Gender</strong>
				</div>
             <div className="card-body">
                <div className="card-body" >

        <Pie data={genderCustomer}             
            options={{
            legend:{
            display:true,
            position:'right'
            }
        }}
        />
        </div>

             </div>
  </div></div>



      </div>
        

      <div className="row">
      <div className="col-12">
          <div className="card">
		   <div className="card-header"><strong>
      Monthly Revenue (Year: {new Date().getFullYear()})</strong>
				</div>
             <div className="card-body">

              <Line
          data={revenueChart}
          options={{
            legend:{
              display:false,
              position:'right'
            }
          }}
        /> 

             </div>
          </div>
        </div></div>


          </div>
        </div>
      </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Dashboard) 