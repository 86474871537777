import React, {useState, useEffect, useRef} from 'react'
import {Alerts} from '../components/notify'
import {ServerUrl, PictureUrl} from '../components/include'
import Preloader from '../components/preloader'
import { LoadEffect } from '../components/loader'
import { useNavigate } from 'react-router-dom'

 const Error500 =()=> {
    const navigate = useNavigate();
        return (<>
          <Preloader />

		  <section style={{background: `url(${PictureUrl}/images/bg.jpg) no-repeat`}} 
		  className="login-block">
       <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center error-pages">
                            <h1 className="error-title text-warning"> 500</h1>
                            <h2 className="error-sub-title text-white">Internal server error</h2>
    
                            <p className="error-message text-white text-uppercase"> We will work on fixing that right away.
                            Please try after some time</p>
                            
                            <div className="mt-4">
                              <a href="/" className="btn btn-warning btn-round shadow-warning m-1">Go To Home </a>
                              <a href="#!" onClick={()=>navigate.goBack()} className="btn btn-outline-warning btn-round m-1">Previous Page </a>
                            </div>
    
                            <div className="mt-4">
                                <p className="text-light">Copyright © 2020  <span className="text-warning">Admotek Solutions </span>| All rights reserved.</p>
                            </div>
                               <hr className="w-50" />
                            <div className="mt-2">
                                <a href="#!" className="btn-social btn-social-circle btn-facebook waves-effect waves-light m-1"><i className="fa fa-facebook"></i></a>
                                <a href="#!" className="btn-social btn-social-circle btn-google-plus waves-effect waves-light m-1"><i className="fa fa-google-plus"></i></a>
                                <a href="#!" className="btn-social btn-social-circle btn-behance waves-effect waves-light m-1"><i className="fa fa-behance"></i></a>
                                <a href="#!" className="btn-social btn-social-circle btn-dribbble waves-effect waves-light m-1"><i className="fa fa-dribbble"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>



         
</> 

 );
        
}

export default React.memo(Error500) 