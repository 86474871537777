import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, Token, config} from '../../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'
 const Edit =()=> {
	 let myRef = useRef()
	 const [defaultUrl, setDefaultUrl] = useState('');
	
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
		isDataFetching: false,
		token:false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select * from tbl_message_template where code = '" +params.code+"'";
        
      
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append("jwt", Token);
        axios.post(url, fd, config).then(result=>{ 
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
	
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;
		if(name==='isActive'){
			value =value ==='true'?'false':'true'
		}	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
 

    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		   
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append("jwt", Token);
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_message_template';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
        let errors = {};   
        
        if(!values.emailMessage){
            errors.emailMessage =Tooltip.required;
        }
		if(!values.emailSubject){
            errors.emailSubject =Tooltip.required;
		}
		if(!values.emailAccount){
            errors.emailAccount =Tooltip.required;
        }
        return errors;
		}
	
		const handleCopyTemplate=()=>{
			setNotice({...notice, 
				isLoading: true}) 
				 
				let formData = content[0];
				const fd = new FormData();
				for(let k in formData){
				  fd.append(k, formData[k])
				}
				let code = Math.random().toString(36).substr(2,9)
				fd.append('uniqId', code)
				fd.append("jwt", Token);
				   let url = ServerUrl+'/save_controller.php?tablename=tbl_message_template';
				  axios.post(url, fd, config)
				  .then(response =>{
				   if(response.data.type ==='success'){
					  
					  Alerts('Saved!', 'success', 'Template successfully copied')
					  window.open('/communication/template/edit/'+code, '_self')
						  } else{
					  Alerts('Error!', 'danger', response.data)
						  }   
				  })
				  .catch((error)=>{
					Alerts('Error!', 'danger', error.message)
				  }).finally(()=>{
					  setNotice({...notice, 
						  isLoading: false
					  })
				  }) 
		}

useEffect(()=>{
	fetchContent()
},[]);	
	
        return (<Template 
			menu="Communications"
		  submenu="Message Template">
			
		   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		 
		 <div  className="main-body">
			  <div className="page-wrapper">
		
		<PageHeader title="Edit Message Template" description='Update Message Template Information' icon='fa fa-commenting-o'>
		<li className="breadcrumb-item"><a href="/communication/template">Template</a> </li>
				</PageHeader>
  
{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-inverse btn-sm" onClick={(e)=>handleSubmit(e, '/communication/template')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

<button type="button" onClick={(e)=>handleSubmit(e, '/communication/template/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
<button type="button" onClick={handleCopyTemplate} className="btn btn-success btn-sm"><i className="fa fa-copy"></i> Copy template</button>&nbsp;
	<a href="/communication/template" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;



			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i> Email Template info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Allowed message tokens" toolTip={'This is a list of the message tokens you can use in your emails & sms'} />
					</div>
									
						<div className="col-sm-9 ">
						<a href="#!" onClick={()=>setNotice({...notice, token:!notice.token})}>{notice.token?'Hide':'Show'}</a>
						<br/>

				<div >{notice.token? Tooltip.messageToken :''}</div>

					</div>
					</div>
					
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name	" toolTip={'The name of this template (read only).'} />
					</div>
									
						<div className="col-sm-9 ">{data.templateName}
					</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Subject" toolTip={'The subject of the message (email). TIP - You can include tokens in your subject.'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="emailSubject" value={data.emailSubject} className={errors.emailSubject  ? 'form-control formerror' : 'form-control '}/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.emailSubject}</span>
							</div>
					</div>
				



					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Body" toolTip={'The body of your message.'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<textarea type="text" onChange={handleChange}   name="emailMessage" value={data.emailMessage} rows="20" className={errors.emailMessage  ? 'form-control formerror' : 'form-control '}>{data.emailMessage}</textarea>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.emailMessage}</span>


							<div>
					<h4>Preview</h4>
							<div dangerouslySetInnerHTML={{__html:  data.emailMessage}} ></div>
							</div>
							</div>
					</div>



					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="BCC" toolTip={'The blind carbon copy (BCC) recipients for this e-mail message.'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="emailBcc" value={data.emailBcc} className='form-control'/>
							
							</div>
							</div>
					</div>




					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Email Account" toolTip={'The email account that will be used to send this message template.'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="emailAccount" value={data.emailAccount} className='form-control'/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.emailAccount}</span>
							</div>
					</div>


					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Active" toolTip={'Indicating Weather the template is activate'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.isActive} name="isActive" checked={data.isActive==='true'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

		

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
	<div className="card-header">
			<h5><i className="fa fa-info"></i> SMS Template info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                  
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Sender" toolTip={'Sender Name (read only).'} />
					</div>
									
						<div className="col-sm-9 ">Iscreen Store
					</div>
					</div>
					

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Body" toolTip={'The body of your message.'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<textarea type="text" onChange={handleChange}   name="smsMessage" value={data.smsMessage} rows="4" className='form-control '>{data.smsMessage}</textarea>

							</div>
					<span style={{color:'green'}}>

					{data.smsMessage.length} Characters,  {(Math.ceil(Number(data.smsMessage.length)/160))}  Pages, Remaining {(160 -(Number(data.smsMessage.length)%160))}</span>
							</div>
					</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 