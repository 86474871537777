import React, {useEffect, useState} from 'react';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import AddBrand from './catalog/brand/addManufacturer';
import EditBrand from './catalog/brand/editManufacturer';
import ImportBrand from './catalog/brand/importManufacturer';
import Brand from './catalog/brand/manufacturer';

import AddCategory from './catalog/category/addCategory';
import EditCategory from './catalog/category/editCategory';
import ImportCategory from './catalog/category/importCategory';
import Category from './catalog/category/category';


import AddAttribute from './catalog/productAttribute/addAttribute';
import EditAttribute from './catalog/productAttribute/editAttribute';
import Attribute from './catalog/productAttribute/attributes';

import AddSpecification from './catalog/specificationAttribute/addSpecification';
import EditSpecification from './catalog/specificationAttribute/editSpecification';
import Specification from './catalog/specificationAttribute/specification';


import AddCheckoutAttribute from './catalog/checkoutAttribute/addCheckoutAttributes';
import EditCheckoutAttribute from './catalog/checkoutAttribute/editCheckoutAttributes';
import CheckoutAttribute from './catalog/checkoutAttribute/checkoutAttributes';



import EditProductReview from './catalog/productReview/editProductReview';
import ProductReview from './catalog/productReview/productReview';

import EditProductTag from './catalog/productTag/editTag';
import ProductTag from './catalog/productTag/productTag';


import AddProduct from './catalog/products/addProduct';
import EditProduct from './catalog/products/editProduct';
import ImportProduct from './catalog/products/importProduct';
import Products from './catalog/products/products';



import EditOrder from './sales/order/editOrder';
import Order from './sales/order/order';
import Wishlist from './sales/wishList';
import ReturnRequest from './sales/returnRequest';

import AddUsers from './users/addUser';
import EditUsers from './users/editUsers';
import Users from './users/users';

import AddGiftcard from './sales/giftcard/addGiftcard';
import EditGiftcard from './sales/giftcard/editGiftcard';
import Giftcard from './sales/giftcard/giftcard';

import AddVendor from './customers/vendors/addVendor';
import EditVendor from './customers/vendors/editVendor';
import Vendors from './customers/vendors/vendors';


import ShippmentList from './sales/shippmentList';
import OnlineCustomer from './customers/onlineCustomer';
import ActivityLog from './customers/activityLog';
import AddCustomer from './customers/addCustomer';
import EditCustomer from './customers/editCustomer';
import Customers from './customers/customers';

import Subscribers from './promotions/subscribers';

import AddDiscount from './promotions/discount/addDiscount';
import EditDiscount from './promotions/discount/editDiscount';
import Discount from './promotions/discount/discount';


import AddNewsletter from './promotions/newsletter/addNewsletter';
import EditNewsletter from './promotions/newsletter/editNewsletter';
import Newsletter from './promotions/newsletter/newsletter';


import ErrorLog from './settings/errorList';
import Settings from './settings/settings';
import PaymentGateway from './settings/paymentGateway';
import Sitemap from './settings/sitemap';
import Navigation from './settings/navigation';
import UserGroup from './users/groups';
import UserRoles from './users/roles';
import Backup from './settings/backup';
import Restore from './settings/restore';


import Dailysales from './reports/dailysales';
import Monthlysales from './reports/monthlysales';
import TopCustomer from './reports/topCustomer';
import TopProduct from './reports/topProduct';
import Dashboard from './reports/dashboard';
import Profile from './users/profile';

import AddSlider from './cms/slider/addSlider';
import EditSlider from './cms/slider/editSlider';
import Slider from './cms/slider/sliders';
import Template from './communication/template/template';
import EditTemplate from './communication/template/editTemplate';
import SmsApi from './communication/smsApi';
import EmailApi from './communication/emailApi';
import Error404 from './settings/error404';
import Purchases from './inventory/purchases/purchases';
import AddPurchase from './inventory/purchases/addPurchase';
import EditPurchase from './inventory/purchases/editPurchase';
import Inventory from './inventory/inventory';
import Lowstock from './inventory/lowstock';

import Neverorder from './inventory/neverorder';
import Banner from './cms/banner/banner';
import EditBanner from './cms/banner/editBanner';

import Collections from './cms/collection/collections';
import AddCollection from './cms/collection/addCollection';
import EditCollection from './cms/collection/editCollection';


import BlogCategory from './blog/category/category';
import AddBlogCategory from './blog/category/addCategory';
import EditBlogCategory from './blog/category/editCategory';

import Blog from './blog/post/blog';
import AddBlog from './blog/post/addBlog';
import EditBlog from './blog/post/editBlog';

import Promo from './promotions/promocode/promo';
import AddPromo from './promotions/promocode/addPromo';
import EditPromo from './promotions/promocode/editPromo';
import Wallet from './wallet/wallet';
import AddMethod from './shipping/shippingMethod/addMethod';
import Method from './shipping/shippingMethod/method';
import EditMethod from './shipping/shippingMethod/editMethod';

import ShippingLocation from './shipping/shippingLocation/location';
import Tickets from './communication/tickets/tickets';
import Replyticket from './communication/tickets/replyticket';
import Tracker from './shipping/tracker/tracker';


import axios from 'axios'
import { SessionTimeout, Alerts } from './components/notify'
import Cookies from 'js-cookie'
import {config, ServerUrl} from './components/include'
import {encrypt, decrypt} from './components/authentication'
import EditShipment from './sales/shipment/editShipment';
import Shipments from './sales/shipment/shipments';



const ProtectedView =()=>{


const openWarningModal =(interver)=>{
      let openWarning =   document.getElementById('openTimeoutModal')
              openWarning.click();
              clearInterval(interver)
}
 

const handleReconnect =()=>{

      let closeWarning =   document.getElementById('btnLogoutsession')
              closeWarning.click();

           
const fd = new FormData();

var code =decrypt(Cookies.get('stfmscd'))
var groupId = decrypt(Cookies.get('mspgcd'))
const jwt = decrypt(Cookies.get('msptk'))

fd.append('code', code)
  fd.append('groupId', groupId)
fd.append('jwt', jwt)

    let url = ServerUrl+'/login_refresh_controller.php?tablename=tbl_refresh_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){

          //1000 * 60 * SESSION_IDEL_MINUTES
          
  var inSixtyMinutes = Date.now() + 419 * 60 * 1000;
  var inOneMinutes = Date.now() + 418 * 60 * 1000;
    var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);



	localStorage.setItem('_tmlsp', inSixtyMinutes)
	localStorage.setItem('_btmlsp', inOneMinutes)

	
Cookies.set('stfmscd', response.data.code, {expires: inOneHours })
Cookies.set('mspgcd', response.data.groupId, {expires: inOneHours })
Cookies.set('msptk', response.data.jwt,  {expires: inOneHours }) 

			  

      Alerts('Info!', 'success', 'Successfuly reconnected')

        window.location.reload()
             } else {
				handleLogout()
			}  
       

		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
     handleLogout()
		})
}



const handleLogout =()=>{
Alerts('Info!', 'default', 'Your session has expired')
    window.location.href='/logout'
}


const startWarning = ()=>{
  var interver;
  interver = setInterval(() => {
    //one minute before expiry time
 var beforeExpired = Number(localStorage.getItem('_btmlsp'))
  if(beforeExpired < Date.now()){
    openWarningModal(interver)
//timeout in one minutes time
  }
}, 5000)
}
  
     

useEffect(()=>{
//startWarning()
   
   setInterval(() => {
 var expiredTime = Number(localStorage.getItem('_tmlsp'))
  if(expiredTime < Date.now()){
   //handleLogout()
  }
}, 5000)
}, [])

    return ( <>
    <a href="#!" data-toggle="modal" id='openTimeoutModal' data-target='#timeoutModal' ></a>
    <SessionTimeout  Logout={handleLogout} Connected ={handleReconnect} /> 
    
    <Routes  >
      
      
      <Route  path="/profile/:code" element={<Profile />} />
      <Route  path="/dashboard" element={<Dashboard />} />
        <Route  path="/category" element={<Category />} /> 
        <Route  path="/category/create" element={<AddCategory />} />
        <Route  path="/category/import" element={<ImportCategory />} />
        <Route  path="/category/edit/:code" element={<EditCategory />} /> 



        <Route  path="/products" element={<Products />} /> 
        <Route  path="/products/create" element={<AddProduct />} />
        <Route  path="/products/import" element={<ImportProduct />} />
        <Route  path="/products/edit/:code" element={<EditProduct />} />


        <Route  path="/brands" element={<Brand />} /> 
        <Route  path="/brands/create" element={<AddBrand />} /> 
        <Route  path="/brands/import" element={<ImportBrand />} /> 
        <Route  path="/brand/edit/:code" element={<EditBrand />} /> 


        <Route  path="/product-tag" element={<ProductTag />} />
        <Route  path="/product-tag/edit/:code" element={<EditProductTag />} />


      <Route  path="/vendors" element={<Vendors />} />
      <Route  path="/vendor/create" element={<AddVendor />} />
      <Route  path="/vendor/edit/:code" element={<EditVendor />} />


      <Route  path="/product-attributes" element={<Attribute />} />
      <Route  path="/product-attributes/create" element={<AddAttribute />} />
      <Route  path="/product-attributes/edit/:code" element={<EditAttribute />} />



      <Route  path="/specification-attributes" element={<Specification />} />
      <Route  path="/specification-attributes/create" element={<AddSpecification />} />
      <Route  path="/specification-attributes/edit/:code" element={<EditSpecification />} />

      <Route  path="/checkout-attributes" element={<CheckoutAttribute />} />
      <Route  path="/checkout-attributes/create" element={<AddCheckoutAttribute />} />
      <Route  path="/checkout-attributes/edit/:code" element={<EditCheckoutAttribute />} />
      <Route  path="/product-review" element={<ProductReview />} />
      <Route  path="/product-review/edit/:code" element={<EditProductReview />} />
     

      <Route  path="/sales/orders" element={<Order />} />
      <Route  path="/orders/edit/:code" element={<EditOrder />} />
      <Route  path="/sales/wishlist" element={<Wishlist />} />
      <Route  path="/sales/return-request" element={<ReturnRequest />} />


      <Route  path="/sales/giftcards" element={<Giftcard />} />
      <Route  path="/giftcard/create" element={<AddGiftcard />} />
      <Route  path="/giftcard/edit/:code" element={<EditGiftcard />} />
      <Route  path="/sales/shipmentList" element={<Shipments />} />
      <Route  path="/sales/shipmentList/edit/:code" element={<EditShipment />} />
      <Route  path="/online-customers" element={<OnlineCustomer />} />
      <Route  path="/activity-log" element={<ActivityLog />} />



      <Route  path="/customers" element={<Customers />} />
      <Route  path="/customer/create" element={<AddCustomer />} />
      <Route  path="/customer/edit/:code" element={<EditCustomer />} />
      <Route  path="/users" element={<Users />} />
      <Route  path="/user/create" element={<AddUsers />} />
      <Route  path="/user/edit/:code" element={<EditUsers />} />


      <Route  path="/cms/sliders" element={<Slider />} />
      <Route  path="/cms/slider/create" element={<AddSlider />} />
      <Route  path="/cms/slider/edit/:code" element={<EditSlider />} />
      <Route  path="/users/roles" element={<UserRoles />} />
      <Route  path="/users/groups" element={<UserGroup />} />
      <Route  path="/subscribers" element={<Subscribers />} />

     

      <Route  path="/discount" element={<Discount />} />
      <Route  path="/discount/create" element={<AddDiscount />} />
      <Route  path="/discount/edit/:code" element={<EditDiscount />} />
      <Route  path="/newsletter" element={<Newsletter />} />
      <Route  path="/newsletter/create" element={<AddNewsletter />} />
      <Route  path="/newsletter/edit/:code" element={<EditNewsletter />} />



      <Route  path="/general/errorlog" element={<ErrorLog />} />
      <Route  path="/general/settings" element={<Settings />} />
      <Route  path="/general/payment-gateway" element={<PaymentGateway />} />
      <Route  path="/general/sitemap" element={<Sitemap />} />
      <Route  path="/general/navigation" element={<Navigation />} />
      <Route  path="/general/database/backup" element={<Backup />} />
      <Route  path="/general/database/restore" element={<Restore />} />
      <Route  path="/reports/monthlysales" element={<Monthlysales />} />

      

      <Route  path="/reports/dailysales" element={<Dailysales />} />
      <Route  path="/reports/topcustomer" element={<TopCustomer />} />
      <Route  path="/reports/topProduct" element={<TopProduct />} />
      <Route  path="/communication/template" element={<Template />} />
      <Route  path="/communication/template/edit/:code" element={<EditTemplate />} />
      <Route  path="/communication/smsapi" element={<SmsApi />} />
      <Route  path="/communication/tickets" element={<Tickets />} />


      <Route  path="/communication/emailapi" element={<EmailApi />} />
      <Route  path="/communication/ticket/edit/:code" element={<Replyticket />} />
      <Route  path="/cms/collections" element={<Collections />} />
      <Route  path="/cms/collection/create" element={<AddCollection />} />
      <Route  path="/cms/collection/edit/:code" element={<EditCollection />} />



      <Route  path="/cms/blog/categories" element={<BlogCategory />} />
      <Route  path="/cms/blog/category/create" element={<AddBlogCategory />} />
      <Route  path="/cms/blog/category/edit/:code" element={<EditBlogCategory />} />
      <Route  path="/cms/blogs" element={<Blog />} />
      <Route  path="/cms/blog/create" element={<AddBlog />} />
      <Route  path="/cms/blog/edit/:code" element={<EditBlog />} />



      <Route  path="/cms/banners" element={<Banner />} />
      <Route  path="/cms/banner/edit/:code" element={<EditBanner />} />
      <Route  path="/promo" element={<Promo />} />
      <Route  path="/promo/create" element={<AddPromo />} />
      <Route  path="/promo/edit/:code" element={<EditPromo />} />
      <Route  path="/wallet" element={<Wallet />} />
      <Route  path="/customerlocation" element={<ShippingLocation />} />
      <Route  path="/shippingmethod" element={<Method />} />
      <Route  path="/shippingmethod/create" element={<AddMethod />} />
      <Route  path="/shippingmethod/edit/:code" element={<EditMethod />} />

      

      <Route  path="/trackorder" element={<Tracker />} />
      <Route  path="/inventory/purchases" element={<Purchases />} />
      <Route  path="/inventory/purchase/create" element={<AddPurchase />} />

      <Route  path="/inventory/purchase/edit/:code" element={<EditPurchase />} />
      <Route  path="/inventory" element={<Inventory />} />
      <Route  path="/inventory/lowstock" element={<Lowstock />} />
      <Route  path="/inventory/neverorder" element={<Neverorder />} />



        <Route exact  component={Error404} />

      </Routes>
      </>
    );
  
}
export default ProtectedView;
