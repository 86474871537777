import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle, CSVExport } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import axios from 'axios'
import {ServerUrl} from './include'


//Basic requirement

export const CustomToggleList = ({
  columns,
  onColumnToggle,
  toggles
}) => ( 
<div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
    {
      columns
        .map(column => ({
          ...column,
          toggle: toggles[column.dataField]
        }))
        .map(column => (
          
          <button
            type="button"
            key={ column.dataField }
            className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
            data-toggle="button"
            aria-pressed={ column.toggle ? 'true' : 'false' }
            onClick={ () => onColumnToggle(column.dataField) }
          >
            { column.text }
          </button>
        ))
    }
  </div>
  
);



const customTotal = (from, to, size) => (
  <span >&nbsp;Showing { from } to { to } of { size } items</span>
);
const { SearchBar } = Search;
    const { ToggleList } = ColumnToggle;
    const { ExportCSVButton } = CSVExport;
    
    const expandRow2 = {
      renderer: row => (
          <div>
            <p>{ `This Expand row is belong to rowKey ${row.ID}` }</p>
            <p>You can render anything here, also you can add additional data on every row object</p>
            <p>expandRow.renderer callback will pass the origin row object to you</p>
      </div>),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandColumnPosition: 'left',
      onlyOneExpanding: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
          if (isAnyExpands) {
            return <a href="#!"  title="Close All"><i className=" fa fa-minus-square"></i></a>
          }
          return <a href="#!"  title="View All"><i className=" fa fa-plus-square"></i></a>
        },
        expandColumnRenderer: ({ expanded }) => {
          if (expanded) {
            return <a href="#!" title="Close"><i className=" fa fa-minus-square"></i></a>
          }
          return <a href="#!"  title="View"><i className=" fa fa-plus-square"></i></a>
        }
      
    } 


















/* const getGrade=(ID)=>{
  const result = grade && grade.filter((list,_)=>list.ID===ID);
const answer = result.map((c)=>c.grade);
return  answer
} */



  export    const TestTable = ()=>{
        const data = [{"ID":"15","fees":"SCHOOL FEES","frequency":"Session","status":"true"},{"ID":"17","fees":"LABORATORY FEES","frequency":"Session","status":"true"},{"ID":"18","fees":"STATE ASSOCIATION","frequency":"Session","status":"true"},{"ID":"19","fees":"LIBRARY FEES","frequency":"Session","status":"true"},{"ID":"20","fees":"MEDICAL FEES","frequency":"Session","status":"true"},{"ID":"21","fees":"EXAMINATION FEES","frequency":"Session","status":"true"},{"ID":"22","fees":"RESULT VERIFICATION","frequency":"Session","status":"false"},{"ID":"23","fees":"DATA CAPTURE","frequency":"Session","status":"true"}];
    
    const handleDelete=(ID)=>{
        console.log(ID, 'Was clicked');
    }
    
    
    const handleAction = (cell, row)=>{
        return <React.Fragment> 
            
    
        <a href="#!"  data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit"><i className="icofont icofont-edit"></i></a>&nbsp;/&nbsp;
        
        <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${row.ID}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></a>
        {/* 
        <WarningModal title="This is very dangerous, you shouldn't do it! are you really really sure.?" handleDelete={()=>handleDelete(row.ID)} mID={row.ID} /> */}
        </React.Fragment> 
    }
    
    
    const columnFormat=(cell, row)=>{
        /* if (row.onSale) {
          return (
            <span>
              <strong style={ { color: 'red' } }>$ {cell} NTD(Sales!!)</strong>
            </span>
          );
        } */
      
        return (
          <span>{cell==='true'?'ACTIVE':'INACTIVE'}</span>
        );
      }
        const columns = [
           {
            dataField: 'fees',
            text: 'Fees',
            sort: true
           /*  filter: textFilter({
                placeholder: 'Search Fees'
            }) */
          }, {
            dataField: 'frequency',
            text: 'Frequency',
            sort: true
            /* filter: textFilter({
                placeholder: 'Search Frequency'
            }) */
          },
          {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: columnFormat,
            csvFormatter:(cell, row, rowIndex) =>  `${cell==='true'?'ACTIVE':'INACTIVE'}`
          },
        {   
            dataField: '',
            text: 'Action',
            formatter: handleAction,
            csvExport: false
    
        }
        ];
    
    
          
    
    return (
    <DataTable 
    data={data } 
    columns={ columns }
    
    />
    )
    }

 const DataTable = React.memo((props)=>{
    
    const customTotal = (from, to, size) => (
        <span >Showing { from } to { to } of { size } entries</span>
      );
      
    const options = {
        // pageStartIndex: 0,
       // sizePerPage: 5,
        showTotal: true,
  paginationTotalRenderer: customTotal,
  sizePerPageList: [{text: '5', value: 5}, {text: '10', value: 10}, 
  { text: 'All', value: props.data.length }]
       // hideSizePerPage: true
      };
     
 

    return <React.Fragment>  
        <div className="table-responsiv">
      
                <ToolkitProvider search columnToggle exportCSV
                    keyField='ID' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            <div >
                                  <div className="btn-group" role="group">
                  <ExportCSVButton className="btn btn-primary" { ...props.csvProps }>CSV</ExportCSVButton>
                  <button className="btn btn-success" >Copy</button>
                  <button className="btn btn-danger" >Print</button>
                  <button className="btn btn-warning" >PDF</button>
                  <ToggleList { ...props.columnToggleProps } />
                                <SearchBar
                                { ...props.searchProps } style={{height:'40px', width:'300px'}} className="form-control" placeholder="Type to search!!!" />
                                
                                </div>
                                <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    expandRow={expandRow2}
                                />
                            </div>
                            )
                        }
</ToolkitProvider>

    </div>
    </React.Fragment>
});



export const BasicTab=(props)=>{
  const options = {
      showTotal: false,
  paginationTotalRenderer: customTotal,
  sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
  { text: 'All', value: props.data.length }]
     
    };

  return <React.Fragment>  
 
          <ToolkitProvider search className="table-responsive"
              keyField='ID' data={ props.data } columns={ props.columns } >
                  
                  {
                      props => (
                      <div >
                          <SearchBar
                          { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                          <BootstrapTable
                          { ...props.baseProps }
                                      striped
                                  hover
                                  condensed
                                  noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                              pagination={paginationFactory(options) }
                            
                          />
                      </div>
                      )
                  }
</ToolkitProvider>
</React.Fragment>
}

export const TransportTable=(props)=>{

   const handleUpdate=(column, newValue, ID)=>{       
    const fd = new FormData(); 
        fd.append('columnField', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
      const config = {
          headers: { 'content-type': 'multipart/form-data'}
      }
       let url = ServerUrl+'/function/update_controller/tbl_distance';
      axios.post(url, fd, config)
      .then()
      .catch((error)=>console.log(error)) 
      
    } 

    const options = {
        showTotal: false,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
    { text: 'All', value: props.data.length }]
       
      };

    return <React.Fragment>  
   
            <ToolkitProvider search className="table-responsive"
                keyField='ID' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        <div >
                            <SearchBar
                            { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                            <BootstrapTable
                            { ...props.baseProps }
                                        striped
                                    hover
                                    condensed
                                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                pagination={paginationFactory(options) }
                                cellEdit={ cellEditFactory({
                                  mode: 'click',
                                  blurToSave: true,
                                  autoSelectText: true,
                        beforeSaveCell: (oldValue, newValue, row, column) => {
                          if(oldValue !==newValue){
                          handleUpdate(column, newValue, row.ID);
                          
                         return props.fetchdata;
                        }
                        
                      }
                                }) }
                            />
                        </div>
                        )
                    }
</ToolkitProvider>
</React.Fragment>
}

export const ClassFeeTable=(props)=>{
    
    const expandRow = {
        renderer: row => (
           
            <div>
                
               <p><b>Fee Type:</b> </p>
              <div className="table-responsive">
        <table className="table ">
              <thead style={{textAlign:'center'}}>
                  <tr >
                      <th  >Fee Type</th>
                      <th >Status</th>
                      <th >Amount</th>
                  </tr>
              </thead>
          <tbody >
         
                 {JSON.parse(row.feesDetail).map((list, idx)=>{
               return (
                props.studentFees.map((dat, _)=>
                dat.ID ===list.feeID?
          <tr key={idx}>
              <td>{dat.fees}</td>
              <td>{list.feeStatus==='true'?'ACTIVE':'INACTIVE'}</td>
              <td>{list.amount?list.amount:'0' }</td>
          </tr>:[]))})   }    
          </tbody>
          </table> 
          </div> 
        </div>),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'left',
        onlyOneExpanding: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
              return <a href="#!"  title="Close All"><i className=" fa fa-minus-square"></i></a>
            }
            return <a href="#!"  title="View All"><i className=" fa fa-plus-square"></i></a>
          },
          expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
              return <a href="#!" title="Close"><i className=" fa fa-minus-square"></i></a>
            }
            return <a href="#!"  title="View"><i className=" fa fa-plus-square"></i></a>
          }
        
      } 



    const options = {
        showTotal: false,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
    { text: 'All', value: props.data.length }]
       
      };

    return <React.Fragment>  
   
            <ToolkitProvider search className="table-responsive"
                keyField='ID' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        <div >
                            <SearchBar
                            { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                            <BootstrapTable
                            { ...props.baseProps }
                                    hover
                                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                pagination={paginationFactory(options) }
                                expandRow={expandRow } 
                            />
                        </div>
                        )
                    }
</ToolkitProvider>
</React.Fragment>
}




export const ReminderTable=(props)=>{

  

  const handleUpdate=(column, newValue, ID)=>{       
   const fd = new FormData(); 
       fd.append('columnField', newValue);
       fd.append('column', column.dataField);
       fd.append('ID', ID);
     const config = {
         headers: { 'content-type': 'multipart/form-data'}
     }
      let url = ServerUrl+'/function/updateById_controller/tbl_fee_reminder';
     axios.post(url, fd, config)
     //.then(result => console.log(result.data))
     .then()
     .catch((error)=>console.log(error)) 
     
   } 

   const options = {
       showTotal: true,
   paginationTotalRenderer: customTotal,
   sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
   { text: 'All', value: props.data.length }]
      
     };

   return <React.Fragment>  
  
           <ToolkitProvider search columnToggle className="table-responsive"
               keyField='ID' data={ props.data } columns={ props.columns } >
                   
                   {
                       props => (
                       <div >
                         <div className="form-group">
                           <SearchBar
                           { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                           &nbsp;
                         
                           <div className="btn-group dropdown-split-success">

<button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<i className="fa fa-search-plus"></i> Columns
</button>

<div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'400px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
  <CustomToggleList { ...props.columnToggleProps } />
</div>
</div>

                           </div>
                           
                    
           
                           <BootstrapTable
                           { ...props.baseProps }
                                       striped
                                   hover
                                   condensed
                                   noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                               pagination={paginationFactory(options) }
                               cellEdit={ cellEditFactory({
                                 mode: 'dbclick',
                                 blurToSave: true,
                                 autoSelectText: true,
                       beforeSaveCell: (oldValue, newValue, row, column) => {
                         if(oldValue !==newValue){
                         handleUpdate(column, newValue, row.ID);
                         
                        return props.fetchdata;
                       }
                       
                     }
                               }) }
                           />
                       </div>
                       )
                   }
</ToolkitProvider>
</React.Fragment>
}


export const BasicTable=(props)=>{

  const handleUpdate=(column, newValue, ID)=>{       
   const fd = new FormData(); 
       fd.append('columnField', newValue);
       fd.append('column', column.dataField);
       fd.append('ID', ID);
     const config = {
         headers: { 'content-type': 'multipart/form-data'}
     }
      let url = ServerUrl+'/function/updateById_controller/'+props.table+'';
     axios.post(url, fd, config)
     .then(response =>{

      if (response.data.type ==='info'){
        alert(response.data.message)
        
      }})
     .catch((error)=>alert(error)) 
     
   } 

   const options = {
       showTotal: false,
   paginationTotalRenderer: customTotal,
   sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
   { text: 'All', value: props.data.length }]
      
     };

   return <React.Fragment>  
  
           <ToolkitProvider search  className="table-responsive"
               keyField='ID' data={ props.data } columns={ props.columns } >
                   
                   {
                       props => (
                       <div >
                         
                           <SearchBar
                           { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                           
                           <BootstrapTable
                           { ...props.baseProps }
                                       striped
                                   hover
                                   condensed
                                   noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                               pagination={paginationFactory(options) }
                               cellEdit={ cellEditFactory({
                                 mode: 'dbclick',
                                 blurToSave: true,
                                 autoSelectText: true,
                       beforeSaveCell: (oldValue, newValue, row, column) => {
                         if(oldValue !==newValue){
                         handleUpdate(column, newValue, row.ID);
                         
                        return props.fetchdata;
                       }
                       
                     }
                               }) }
                           />
                       </div>
                       )
                   }
</ToolkitProvider>
</React.Fragment>
}


export const SubjectTable=(props)=>{
  const handleUpdate=(column, newValue, ID)=>{       
    const fd = new FormData(); 
        fd.append('columnField', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
      const config = {
          headers: { 'content-type': 'multipart/form-data'}
      }
       let url = ServerUrl+'/function/updateById_controller/tbl_subject';
      axios.post(url, fd, config)
      .then()
      .catch((error)=>console.log(error)) 
      
    } 
    const getClassName = (ID) =>{        
      const result = props.schoolClass.filter((list,_)=>list.ID === ID);
      return   result.map((c)=>c.cname);
    }
   
    const getSectionName = (cID, sID, isSection) =>{   

      const result = isSection==='1'? props.schoolClass.map((list)=> {
        return list.section.map((sec)=>{ 
            return sec.ID === sID? sec.sname:[]
        }) 
      }):getClassName(cID);


      return result
    }
  const expandRow = {
      renderer: row => (
         
          <div>
              
             <p><b>Class:</b> </p>
            <div className="table-responsive">
      <table className="table ">
            <thead style={{textAlign:'center'}}>
                <tr >
                    <th  >Class</th>
                    <th >Section</th>
                </tr>
            </thead>
        <tbody >
       
               {(JSON.parse(row.subjectClass)).map((list, idx)=>{
             return (
              
        <tr key={idx}>
            <td>{getClassName(list.classID)}</td>
            <td>{getSectionName(list.classID, list.sectionID, list.isSection)}</td>
        </tr>
  )} )  }      
        </tbody>
        </table> 
        </div> 
      </div>),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandColumnPosition: 'left',
      onlyOneExpanding: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
          if (isAnyExpands) {
            return <a href="#!"  title="Close All"><i className=" fa fa-minus-square"></i></a>
          }
          return <a href="#!"  title="View All"><i className=" fa fa-plus-square"></i></a>
        },
        expandColumnRenderer: ({ expanded }) => {
          if (expanded) {
            return <a href="#!" title="Close"><i className=" fa fa-minus-square"></i></a>
          }
          return <a href="#!"  title="View"><i className=" fa fa-plus-square"></i></a>
        }
      
    } 



  const options = {
      showTotal: false,
  paginationTotalRenderer: customTotal,
  sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
  { text: 'All', value: props.data.length }]
     
    };

  return <React.Fragment>  
 
          <ToolkitProvider search className="table-responsive"
              keyField='ID' data={ props.data } columns={ props.columns } >
                  
                  {
                      props => (
                      <div >
                          <SearchBar
                          { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                          <BootstrapTable
                          { ...props.baseProps }
                                  hover
                                  noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                              pagination={paginationFactory(options) }
                              expandRow={expandRow } 
                              cellEdit={ cellEditFactory({
                                mode: 'dbclick',
                                blurToSave: true,
                                autoSelectText: true,
                      beforeSaveCell: (oldValue, newValue, row, column) => {
                        if(oldValue !==newValue){
                        handleUpdate(column, newValue, row.ID);
                        
                       return props.fetchdata;
                      }
                      
                    }
                              }) }
                          />
                      </div>
                      )
                  }
</ToolkitProvider>
</React.Fragment>
}

export const StudentTable=(props)=>{

  
  const handleUpdate=(column, newValue, ID)=>{       
   const fd = new FormData(); 
       fd.append('columnField', newValue);
       fd.append('column', column.dataField);
       fd.append('ID', ID);
     const config = {
         headers: { 'content-type': 'multipart/form-data'}
     }
      let url = ServerUrl+'/function/updateById_controller/tbl_students';
     axios.post(url, fd, config)
     //.then(result => console.log(result.data))
     .then()
     .catch((error)=>console.log(error)) 
     
   } 

   const options = {
       showTotal: true,
   paginationTotalRenderer: customTotal,
   sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
   { text: 'All', value: props.data.length }]
      
     };

   return <React.Fragment>  
  
           <ToolkitProvider search columnToggle 
               keyField='ID' data={ props.data } columns={ props.columns } >
                   
                   {
                       props => (
                       <div >
                         <div className="form-group">
                           <SearchBar
                           { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                           &nbsp;
                         
                           <div className="btn-group dropdown-split-success">

<button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<i className="fa fa-search-plus"></i> Columns
</button>

<div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
  <CustomToggleList { ...props.columnToggleProps } />
</div>
</div>

                           </div>
                           
                    
           
                           <BootstrapTable
                           { ...props.baseProps }
                                       striped
                                   hover
                                   condensed
                                   noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                               pagination={paginationFactory(options) }
                               cellEdit={ cellEditFactory({
                                 mode: 'dbclick',
                                 blurToSave: true,
                                 autoSelectText: true,
                       beforeSaveCell: (oldValue, newValue, row, column) => {
                         if(oldValue !==newValue){
                         handleUpdate(column, newValue, row.ID);
                         
                        return props.fetchdata;
                       }
                       
                     }
                               }) }
                           />
                       </div>
                       )
                   }
</ToolkitProvider>
</React.Fragment>
}




export const CustomTable=(props)=>{

  const handleUpdate=(column, newValue, ID)=>{       
   const fd = new FormData(); 
       fd.append('columnField', newValue);
       fd.append('column', column.dataField);
       fd.append('ID', ID);
     const config = {
         headers: { 'content-type': 'multipart/form-data'}
     }
      let url = ServerUrl+'/function/updateCustom_controller/'+props.table+'';
     axios.post(url, fd, config)
     .then(response =>{

      if (response.data.type ==='error'){
        alert(response.data.message)
        
      }})
     .catch((error)=>alert(error)) 
     
   } 

   const options = {
       showTotal: false,
   paginationTotalRenderer: customTotal,
   sizePerPageList: [{text: '20', value: 20}, {text: '30', value: 30}, {text: '60', value: 60},
   { text: 'All', value: props.data.length }]
      
     };

   return <React.Fragment>  
  
           <ToolkitProvider search  className="table-responsive"
               keyField='ID' data={ props.data } columns={ props.columns } >
                   
                   {
                       props => (
                       <div >
                         
                           <SearchBar
                           { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                           
                           <BootstrapTable
                           { ...props.baseProps }
                                       striped
                                   hover
                                   condensed
                                   noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                               pagination={paginationFactory(options) }
                               cellEdit={ cellEditFactory({
                                 mode: 'dbclick',
                                 blurToSave: true,
                                 autoSelectText: true,
                       beforeSaveCell: (oldValue, newValue, row, column) => {
                         if(oldValue !==newValue){
                         handleUpdate(column, newValue, row.ID);
                         
                        return props.fetchdata;
                       }
                       
                     }
                               }) }
                           />
                       </div>
                       )
                   }
</ToolkitProvider>
</React.Fragment>
}




export const StaffTable=(props)=>{

  const handleUpdate=(column, newValue, ID)=>{       
   const fd = new FormData(); 
       fd.append('columnField', newValue);
       fd.append('column', column.dataField);
       fd.append('ID', ID);
     const config = {
         headers: { 'content-type': 'multipart/form-data'}
     }
      let url = ServerUrl+'/function/updateCustom_controller/tbl_staff';
     axios.post(url, fd, config)
     //.then(result => console.log(result.data))
     .then()
     .catch((error)=>console.log(error)) 
     
   } 

   const options = {
       showTotal: true,
   paginationTotalRenderer: customTotal,
   sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
   { text: 'All', value: props.data.length }]
      
     };

   return <React.Fragment>  
  
           <ToolkitProvider search columnToggle 
               keyField='ID' data={ props.data } columns={ props.columns } >
                   
                   {
                       props => (
                       <div >
                         <div className="form-group">
                           <SearchBar
                           { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                           &nbsp;
                           <ExportCSVButton className="btn btn-inverse" { ...props.csvProps }><i className="fa fa-download"></i>Export</ExportCSVButton>&nbsp;
                  <button className="btn btn-inverse" ><i className="fa fa-print"></i>Print</button>&nbsp;
                  <button className="btn btn-inverse" > <i className="fa fa-file"></i>PDF</button>

                           <div className="btn-group dropdown-split-success pull-right">

<button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<i className="fa fa-search-plus"></i> Columns
</button>

<div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
  <CustomToggleList { ...props.columnToggleProps } />
</div>
</div>

                           </div>
                           
                    
           
                           <BootstrapTable
                           { ...props.baseProps }
                                       striped
                                   hover
                                   condensed
                                   noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                               pagination={paginationFactory(options) }
                               cellEdit={ cellEditFactory({
                                 mode: 'dbclick',
                                 blurToSave: true,
                                 autoSelectText: true,
                       beforeSaveCell: (oldValue, newValue, row, column) => {
                         if(oldValue !==newValue){
                         handleUpdate(column, newValue, row.ID);
                         
                        return props.fetchdata;
                       }
                       
                     }
                               }) }
                           />
                       </div>
                       )
                   }
</ToolkitProvider>
</React.Fragment>
}


export const UserTable=(props)=>{

   const options = {
       showTotal: true,
   paginationTotalRenderer: customTotal,
   sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
   { text: 'All', value: props.data.length }]
      
     };

   return <React.Fragment>  
  
           <ToolkitProvider search columnToggle 
               keyField='ID' data={ props.data } columns={ props.columns } >
                   
                   {
                       props => (
                       <div >
                         
                           <BootstrapTable
                           { ...props.baseProps }
                                       striped
                                   hover
                                   condensed
                                   noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                               pagination={paginationFactory(options) }
                               
                           />
                       </div>
                       )
                   }
</ToolkitProvider>
</React.Fragment>
}




export const TableWithButton=(props)=>{
  const expandRow = {
    renderer: row => (
       
          <div className="table-responsive">
    <table className="table">
      <tbody >
        <tr><td><b>Purchase Price:</b></td><td>{row.purchasePrice}</td></tr>
        <tr><td><b>Cash Memo / Bill No:</b></td><td>{row.billNo}</td></tr>
        <tr><td><b>No Of Pages:</b></td><td>{row.noPages}</td></tr>
        <tr><td><b>Call No:</b></td><td>{row.callNo}</td></tr>
        <tr><td><b>Volume No:</b></td><td>{row.volumeNo}</td></tr>
        <tr><td><b>Nature of Binding:</b></td><td>{row.bindingNature}</td></tr>
        <tr><td><b>Division:</b></td><td>{row.division}</td></tr>
        <tr><td><b>Book Rack:</b></td><td>{row.bookRack}</td></tr>
        <tr><td><b>Row:</b></td><td>{row.bookRow}</td></tr>
        <tr><td><b>Category:</b></td><td>{row.category}</td></tr>
        <tr><td><b>Type:</b></td><td>{row.bookType}</td></tr>
        <tr><td><b>Remarks:</b></td><td>{row.remarks}</td></tr>
      </tbody>
      </table> 
    </div>),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnPosition: 'left',
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <a href="#!"  title="Close All"><i className=" fa fa-minus-square"></i></a>
        }
        return <a href="#!"  title="View All"><i className=" fa fa-plus-square"></i></a>
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return <a href="#!" title="Close"><i className=" fa fa-minus-square"></i></a>
        }
        return <a href="#!"  title="View"><i className=" fa fa-plus-square"></i></a>
      }
    
  } 




  const handleUpdate=(column, newValue, ID)=>{       
   const fd = new FormData(); 
       fd.append('columnField', newValue);
       fd.append('column', column.dataField);
       fd.append('ID', ID);
     const config = {
         headers: { 'content-type': 'multipart/form-data'}
     }
      let url = ServerUrl+'/function/updateCustom_controller/'+props.table+'';
     axios.post(url, fd, config)
     //.then(result => console.log(result.data))
     .then()
     .catch((error)=>console.log(error)) 
     
   } 

   const options = {
       showTotal: true,
   paginationTotalRenderer: customTotal,
   sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
   { text: 'All', value: props.data.length }]
      
     };

   return <React.Fragment>  
  
           <ToolkitProvider search columnToggle 
               keyField='ID' data={ props.data } columns={ props.columns } >
                   
                   {
                       props => (
                       <div >
                         <div className="form-group">
                           <SearchBar
                           { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                           &nbsp;
                           <ExportCSVButton className="btn btn-inverse" { ...props.csvProps }><i className="fa fa-download"></i>Export</ExportCSVButton>&nbsp;
                  <button className="btn btn-inverse" ><i className="fa fa-print"></i>Print</button>&nbsp;
                  <button className="btn btn-inverse" > <i className="fa fa-file"></i>PDF</button>

                           <div className="btn-group dropdown-split-success pull-right">

<button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<i className="fa fa-search-plus"></i> Columns
</button>

<div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
  <CustomToggleList { ...props.columnToggleProps } />
</div>
</div>

                           </div>
                           
                    
           
                           <BootstrapTable
                           { ...props.baseProps }
                                       striped
                                   hover
                                   condensed
                                   noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                               pagination={paginationFactory(options) }
                               expandRow={expandRow }
                               cellEdit={ cellEditFactory({
                                 mode: 'dbclick',
                                 blurToSave: true,
                                 autoSelectText: true,
                       beforeSaveCell: (oldValue, newValue, row, column) => {
                         if(oldValue !==newValue){
                         handleUpdate(column, newValue, row.ID);
                         
                        return props.fetchdata;
                       }
                       
                     }
                               }) }
                           />
                       </div>
                       )
                   }
</ToolkitProvider>
</React.Fragment>
}




export const GeneralReportTable=(props)=>{
  
   const options = {
       showTotal: true,
   paginationTotalRenderer: customTotal,
   sizePerPageList: [{text: '20', value: 20}, {text: '40', value: 40}, {text: '100', value: 100},
   { text: 'All', value: props.data.length }]
      
     };

   return <React.Fragment>  
  
           <ToolkitProvider search columnToggle 
               keyField='ID' data={ props.data } columns={ props.columns } >
                   
                   {
                       props => (
                       <div >
                         <div className="form-group">
                           <SearchBar
                           { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                           &nbsp;
                           <ExportCSVButton className="btn btn-inverse" { ...props.csvProps }><i className="fa fa-download"></i>Export</ExportCSVButton>&nbsp;
                  <button className="btn btn-inverse" ><i className="fa fa-print"></i>Preview</button>&nbsp;
                  <button className="btn btn-inverse" > <i className="fa fa-file"></i>PDF</button>

                           <div className="btn-group dropdown-split-success pull-right">

<button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<i className="fa fa-search-plus"></i> Columns
</button>

<div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
  <CustomToggleList { ...props.columnToggleProps } />
</div>
</div>

                           </div>
                           
                    
           
                           <BootstrapTable
                           { ...props.baseProps }
                                       striped
                                   hover
                                   condensed
                                   noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                               pagination={paginationFactory(options) }
                              
                           />
                       </div>
                       )
                   }
</ToolkitProvider>
</React.Fragment>
}




export const ExamMasterTable=(props)=>{


  const getGrade=(ID)=>{
    const result = props.grade.filter((list,_)=>list.ID===ID);
  const answer = result.map((c)=>c.grade);
  return  answer
}

  const expandRow = {
    renderer: row => (
       
          <div className="table-responsive">
    <table className="table">
      <thead>
        <tr>
        <th>Grade</th><th>Maximum</th><th>Minimum</th><th>Result</th>
        </tr>
      </thead>
      <tbody >
      {JSON.parse(row.markDetails).map((list, idx)=>
          <tr key={idx}>
            <td>{getGrade(list.gradeId)}</td>
              <td>{list.maximum}</td>
              <td>{list.minimum}</td>
              <td>{list.result}</td>
          </tr>) } 

      </tbody>
      </table> 
    </div>),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnPosition: 'left',
    onlyOneExpanding: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <a href="#!"  title="Close All"><i className=" fa fa-minus-square"></i></a>
        }
        return <a href="#!"  title="View All"><i className=" fa fa-plus-square"></i></a>
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return <a href="#!" title="Close"><i className=" fa fa-minus-square"></i></a>
        }
        return <a href="#!"  title="View"><i className=" fa fa-plus-square"></i></a>
      }
    
  } 




  const handleUpdate=(column, newValue, ID)=>{       
   const fd = new FormData(); 
       fd.append('columnField', newValue);
       fd.append('column', column.dataField);
       fd.append('ID', ID);
     const config = {
         headers: { 'content-type': 'multipart/form-data'}
     }
      let url = ServerUrl+'/function/updateCustom_controller/'+props.table+'';
     axios.post(url, fd, config)
     //.then(result => console.log(result.data))
     .then()
     .catch((error)=>console.log(error)) 
     
   } 

   const options = {
       showTotal: true,
   paginationTotalRenderer: customTotal,
   sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
   { text: 'All', value: props.data.length }]
      
     };

   return <React.Fragment>  
  
           <ToolkitProvider search columnToggle 
               keyField='ID' data={ props.data } columns={ props.columns } >
                   
                   {
                       props => (
                       <div >
                         <div className="form-group">
                           <SearchBar
                           { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                           &nbsp;
                          
                           <div className="btn-group dropdown-split-success pull-right">

<button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<i className="fa fa-search-plus"></i> Columns
</button>

<div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
  <CustomToggleList { ...props.columnToggleProps } />
</div>
</div>

                           </div>
                           
                    
           
                           <BootstrapTable
                           { ...props.baseProps }
                                       striped
                                   hover
                                   condensed
                                   noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                               pagination={paginationFactory(options) }
                               expandRow={expandRow }
                               cellEdit={ cellEditFactory({
                                 mode: 'dbclick',
                                 blurToSave: true,
                                 autoSelectText: true,
                       beforeSaveCell: (oldValue, newValue, row, column) => {
                         if(oldValue !==newValue){
                         handleUpdate(column, newValue, row.ID);
                         
                        return props.fetchdata;
                       }
                       
                     }
                               }) }
                           />
                       </div>
                       )
                   }
</ToolkitProvider>
</React.Fragment>
}
export default React.memo(ClassFeeTable)