import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, Token, config, IMAGES_URL, API_URL} from '../../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

 const Banner =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    
  
    const [content, setContent] = useState([]);
    

    const fetchContent =()=>{
        setNotice({...notice, isDataFetching: true  });
        let url = API_URL+'/banners/display/all';
        axios.get(url, config).then(result=>{ 
            if(Array.isArray(result.data.data)){
            setContent(result.data.data)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 
    }


  

const handleCheckOne =(e)=>{ 

    
    let {name, value} = e.target

    const currentContent = content.map((item)=>{   
        if(item.code ===name){
            return {...item, status:item.status==='false'?'true':'false'}
        }

         return item
          })

   setContent(currentContent) 
   
       
      }

const tableHeader = [
        
   
{dataField: 'layoutUrl', text: 'Sample', editable: false, formatter:(cell, row)=><img id={cell} className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+"/noImage.png"}} src={`${IMAGES_URL}/banners/${cell}`} title={row.productName}  alt={row.coverUrl} />},
{dataField: 'title', text: 'Title',  editable: false},
{dataField: 'preferWidth', text: 'Prefer Width',  editable: false},
{dataField: 'preferHeight', text: 'Prefer Heigth',  editable: false},
    {dataField: 'status', text: 'Status', sort: true, editor: {
        type: Type.SELECT, options:[{value:'Active', label:'Published'},
        {value:'Inactive', label:'Unpublished'}]
      }, formatter:(cell)=>cell==='Active'? <div className="text-center"><i title="Double click to change" className="fa fa-check  badge-inverse-success"></i></div>: <div className="text-center"><i title="Double click to change" className="fa fa-times  badge-inverse-danger"></i></div>}, 
    {dataField: 'code', text: 'Action', formatter: (cell, row)=><a href={`/cms/banner/edit/${row.code}`} className="btn btn-success btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Add Image</a>, editable: false}
 ];

    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, code)=>{       
            const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('code', code);
           let url = API_URL+'/banner/change_field'
          axios.post(url, fd, config)
              .then(result => fetchContent())
              .catch((error)=>Alerts('Error!', 'danger', error.message)) 
            } 
         const { SearchBar } = Search;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                              beforeSaveCell: (oldValue, newValue, row, column) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.code);
                                
                               return '';
                              }
                              
                            }
                                      }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      


      const handleDeleteWithImage =()=>{
          
       
    let dataId = []
    var newContent = JSON.parse(JSON.stringify(content));
    for (var i in newContent){
        if(newContent[i].status===true){
            dataId.push(newContent[i].ID)
        }
    }
    let close =   document.getElementById('btnWarningDialog-delAll')
    if(dataId.length===0){
        Alerts('Error!', 'danger', Tooltip.deleteWarning)
          
    }else{
        setNotice({...notice, 
            isLoading: true}) 
    var data = JSON.stringify(dataId, null, 2)

        const fd = new FormData();
        fd.append('data', data)
        fd.append("jwt", Token);
    let url = ServerUrl+'/deleteMultiple_controller.php?tablename=tbl_banner';
          axios.post(url, fd, config)
          .then(response =>{
           if(response.data.type ==='success'){
              Alerts('Saved!', 'success', response.data.message)
              
                  } else{
              Alerts('Error!', 'danger', response.data)
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, 
                  isLoading: false
              })
              fetchContent()
          }) 
        }
        close.click();
}



     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
			menu="Content Management"
		submenu="Banners">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Banners" description='Displaying website banners' icon='feather icon-layers'>
					</PageHeader>


<WarningModal  handleAction={handleDeleteWithImage} mID={'delAll'} /> 

{/* The Page Body start here */}
<div className="page-body">



							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Banners</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Banner) 