import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, Currency, FormatNumber, Token, config } from '../../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { convertDateTime } from '../../components/globalFunction'

 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	
    const handleDelete =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
		

		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'code')
		fd.append('data', params.code)
		fd.append("jwt", Token);
	let url = ServerUrl+'/delete_controller.php?tablename=tbl_discount';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/discount', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
		
		
}

function generateCardNo(t){
	t||(t=16);
	for(var e="",a=0;a<t;a++){
		var n=Math.floor(Math.random()*"1234567890".length);
		e+="1234567890".substring(n,n+1)
		}
		const newContent =JSON.parse(JSON.stringify(content))  
		newContent[0].couponCode = e
		setContent(newContent);
		
		return e
}

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select * from tbl_discount where code = '" +params.code+"'";
        
        
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append("jwt", Token);
        axios.post(url, fd, config).then(result=>{ 
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
	const [defaultUrl, setDefaultUrl] = useState('');
	
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		if(name==='isPercentage'){
			value = value==='false'?'true':'false'
		}
		if(name==='requiredCode'){
			value = value==='false'?'true':'false'
		}
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}

	
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		  const config = {
			  headers: { 'content-type': 'multipart/form-data'}
		  }    
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append("jwt", Token);
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_discount';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.discountName){
			errors.discountName =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}

		const tableOrderHeader = [
        
			{dataField: 'ID',  text: 'Order #',  sort: true, editable: false},
			{dataField: 'used',  text: 'used',  sort: true, editable: false},
			{dataField: 'orderTotal',  text: 'Total ('+Currency+')',  sort: true, editable: false, formatter:(cell)=>FormatNumber(cell)}
				   
				 ];
				
				 const TableOrderRecord=(props)=>{
				
					 const customTotal = (from, to, size) => (
						<span >&nbsp;Showing { from } to { to } of { size } items</span>
					  );
					 const options = {
						showTotal: true,
					  paginationTotalRenderer: customTotal,
					  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
					  { text: 'All', value: props.data.length }]
					   
					  };
					 return  <ToolkitProvider search columnToggle 
								 keyField='ID' data={ props.data } columns={ props.columns } >
									 
									 {
										 props => (
										 <div >
										  
											 
								 <BootstrapTable 
									{ ...props.baseProps }
									striped
								hover
								condensed
								noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
							pagination={paginationFactory(options) }
						  
											 />
										 </div>
										 )
									 }
				  </ToolkitProvider>
				  }
				  
		
useEffect(()=>{
	fetchContent()
},[]);	
	
        return (<Template 
            menu="Promotions"
          submenu="Discount">
            
           {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
         
         <div  className="main-body">
              <div className="page-wrapper">
        
        <PageHeader title="Edit Discount" description='Update discount details' icon='feather icon-package'>
        <li className="breadcrumb-item"><a href="/discount">Discount</a> </li>
                </PageHeader>

{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, '/discount')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

<button type="button" onClick={(e)=>handleSubmit(e, '/discount/edit/'+params.code)} className="btn btn-inverse "><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;

	<a href="/discount" className="btn btn-warning "><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger " data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDelete()} mID={'delAll'} /> 


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Discount info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={'The name of the discount'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  name="discountName" value={data.discountName} className={errors.discountName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.discountName}</span>
							</div>
					</div>
					
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Discount type" toolTip={Tooltip.discountType} />
					</div>
									
						<div className="col-sm-9 ">
						<select onChange={handleChange} value={data.discountType}
                                      name="discountType"
									  className="form-control"
                                    >
                                      <option value="total">Assigned to order total</option>
									<option value="products">Assigned to products</option>
									<option value="categories">Assigned to categories</option>
									<option value="manufacturers">Assigned to brand</option>
									<option value="shipping">Assigned to shipping</option>
									<option value="subtotal">Assigned to order subtotal</option>
                                    </select>
							</div>
					</div>	
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Use percentage" toolTip={Tooltip.isPercentage}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.isPercentage} name="isPercentage" checked={data.isPercentage==='true'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			{data.isPercentage==='false'?<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Discount amount" toolTip={Tooltip.discountAmount} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange}  name="discountAmount" value={data.discountAmount} className='form-control '/>
							
							</div>
							</div>
					</div>:''}
					
					{data.isPercentage==='true'?<>	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Discount percentage" toolTip={Tooltip.percentage} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange}  name="percentage" value={data.percentage} className='form-control '/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Maximum discount amount" toolTip={Tooltip.maximumDiscount} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange}  name="maximumDiscount" value={data.maximumDiscount} className='form-control '/>
							
							</div>
							</div>
					</div></>:''}
					
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Requires coupon code" toolTip={Tooltip.requiredCode}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.requiredCode} name="requiredCode" checked={data.requiredCode==='true'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


			{data.requiredCode==='true'?	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Coupon code" toolTip={Tooltip.couponCode} />
					</div>
									
						<div className="col-sm-9 ">


					
							<div className="input-group">
						<input type="text" onChange={handleChange}  name="couponCode" value={data.couponCode} className={errors.couponCode  ? 'form-control formerror' : 'form-control '}/>
						<span class="input-group-addon" onClick={()=>generateCardNo(12)} id="basic-addon">Generate Code</span>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.couponCode}</span>
							</div>
					</div>:''}
					
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Start Date" toolTip={Tooltip.startDate} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="datetime-local" onChange={handleChange}  name="startDate" value={convertDateTime(data.startDate)} className='form-control '/>
							
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="End Date" toolTip={Tooltip.endDate} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="datetime-local" onChange={handleChange}  name="endDate" value={convertDateTime(data.endDate)} className='form-control '/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Admin Comment" toolTip={Tooltip.adminComment} />
		</div>
						
			<div className="col-sm-9 ">
				
			<textarea type="text" className="form-control" rows="5" name="comment" value={data.comment} onChange={handleChange}>{data.comment}
			</textarea>

				</div>
		</div>
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


			

	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-cart-plus"></i> Usage History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>	<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                  

       	 <div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
		<TableOrderRecord data={JSON.parse(data.usageHistory)}  columns={tableOrderHeader}  /> 
            </div>
           
    
    </div></div>
					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>




				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 