import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {  LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, FormatNumber, Currency, IMAGES_URL, API_URL,  Token, config} from '../../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Select from 'react-select'
import { longDate, compare, convertDateTime } from '../../components/globalFunction'
 const EditProduct =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [store, setStore] = useState([])
	const [defaultUrl, setDefaultUrl] = useState('');
	const [tier, setTier] = useState({
			ID:Math.random().toString(36).substr(2,9),
			quantity:'',
			price:''
	})
	const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
	});
	
	const [vendor, setVendor] = useState([])
	const [attributeSpecification, setAttributeSpecification] = useState([])
	const [specOption, setSpecOption] = useState([])
	const [specification, setSpecification] = useState({	      
		ID:Math.random().toString(36).substring(2,9),
		attribute:'',
		attributeOption:'',
		showOnProduct:'true',
		order:0
})
const [relatedList, setRelatedList] = useState({	      
	ID:Math.random().toString(36).substring(2,9),
	code:'',
	order:0
})

const [discount, setDiscount] = useState([])
const [orders, setOrders] = useState([])
	const [productAttribute, setProductAttribute] = useState({
		ID:Math.random().toString(36).substr(2,9),
		attribute:'',
		order:1,
		controlType:''
})

	const [attributeProduct, setAttributeProduct] = useState([])
	const [group, setGroup] = useState([])
	const [products, setProducts] = useState([])
	const [category, setCategory] = useState([])
	const [manufacturer, setManufacturer] = useState([])
	const [tags, setTags] = useState([])
	
	const [content, setContent] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

	let basic = {
		basic:true,
		infoDisplay:false,
		displayPrice:false,
		displayShipping:false,
		displayInventory:false,
		displayPicture:true,
		displayPrinting:true,
		displayProductAttribute:false,
		displaySpecAttribute:false,
		seoDisplay:false,
		productDisplay:false,
		displayRelated:false,
		displayCrossSell:false,
		displayOrders:false,
		displayHistory:false,
	}

	const [panel, setPanel] = useState({
		...basic,
		info:true,
		price:true,
		shipping:true,
		inventory:true,
		picture:true,
		printing:true,
		productAttribute:true,
		specAttribute:true,
		seo:false,
		product:false,
		related:false,
		crossSell:false,
		orders:false,
		history:false,
	})

	const goAdvance=()=>{
		setPanel({...panel,
		basic:false,
		infoDisplay:true,
		displayPrice:true,
		displayShipping:true,
		displayInventory:true,
		displayPicture:true,
		displayProductAttribute:true,
		displaySpecAttribute:true,
		seoDisplay:true,
		productDisplay:true,
		displayRelated:true,
		displayCrossSell:true,
		displayOrders:true,
		displayPrinting:true,
		displayHistory:true
	})
	}

	const goBasic=()=>{
		setPanel({...panel,
			...basic})
	}

	const handleChangeTier =(event)=>{
		const {name, value} = event.target;
		setTier({...tier, [name] : value });
	}

	const handleRemoveTier =(ID)=>{
		const newContent = [...content] 
		const newTier = JSON.parse(newContent[0].bulkPrices);
		newContent[0].bulkPrices = JSON.stringify(newTier.filter((list)=>list.ID!==ID))
		setContent(newContent);
	}

	const handleChangeAttribute =(event)=>{
		let {name, value} = event.target;
		setProductAttribute({...productAttribute, [name] : value });
	}

	const handleChangeSpecification =(event)=>{
		let {name, value} = event.target;
		if(name ==='showOnProduct' ){
			value = value ==='true'?'false':'true'
		}
		if(name ==='attribute' ){
			getAttributeOption(value)
		}
		
		setSpecification({...specification, [name] : value });
	}


	
	const fetchVendor =()=>{
	
		let url = API_URL+'/vendor/display/all'
		axios.get(url, config).then(result=>{ 
			if(Array.isArray(result.data.data)){  
			setVendor(result.data.data)
			}
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}


	
	const fetchOrders =()=>{
		let url = API_URL+'/product/order/'+params.code;
		axios.get(url, config).then(result=>{
			if(Array.isArray(result.data.data)){   
			setOrders(result.data)
			}
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}

	const fetchDiscount =()=>{
		var sql ="Select ID, code, discountName,  code from tbl_discount order by discountName ASC" ;
		const fd = new FormData();
		fd.append("sql", sql);
		let url = ServerUrl+'/fetchBySql_controller.php';
		fd.append('jwt', Token)
		axios.post(url, fd, config).then(result=>{  
			setDiscount(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}
	
	const handleAddSpecifications =(event)=>{
		event.preventDefault()
		let close =   document.getElementById('closeSpecification')
		close.click();
		const newContent = [...content]
		const newSpecifications = JSON.parse(newContent[0].specifications);
		const specificationsList = newSpecifications.concat(specification)
		newContent[0].specifications = JSON.stringify(specificationsList, null, 2)
		setContent(newContent);
		
				setSpecification({
					ID:Math.random().toString(36).substring(2,9),
					attribute:'',
					attributeOption:'',
					showOnProduct:'true',
					order:0
			})		
}

const handleCrossSell =(event)=>{
	event.preventDefault()
	let close =   document.getElementById('closeCrossSell')
	close.click();

	const newContent = JSON.parse(JSON.stringify(content))
	let records = []
		var newProducts = JSON.parse(JSON.stringify(products));
		const oldCrossSell = JSON.parse(newContent[0].crossSell);
        for (var i in newProducts){
            if(newProducts[i].status==='true'){
				records.push({ID:Math.random().toString(36).substring(2,9), code:newProducts[i].code, order:Number(oldCrossSell.length) +Number(i)+1})
				newProducts[i].status='false'
            }
		}
	
	const crossSellProduct = oldCrossSell.concat(records)

const allCrossSell =	crossSellProduct.map(e=>e['code'])
	.map((e,i,final)=>final.indexOf(e)===i&&i)
	.filter(e=>crossSellProduct[e])
	.map(e=>crossSellProduct[e])
	newContent[0].crossSell = JSON.stringify(allCrossSell)
	setContent(newContent);
		
				setStatus({...status, checkAll:false})
				setProducts(newProducts)
}

const handleAddRelated =(event)=>{
	event.preventDefault()
	let close =   document.getElementById('closeRelated')
	close.click();

	const newContent = JSON.parse(JSON.stringify(content))
	let records = []
		var newProducts = JSON.parse(JSON.stringify(products));
		const oldRelated = JSON.parse(newContent[0].related);
        for (var i in newProducts){
            if(newProducts[i].status==='true'){
				records.push({ID:Math.random().toString(36).substring(2,9), code:newProducts[i].code, order:Number(oldRelated.length) +Number(i)+1})
				newProducts[i].status='false'
            }
		}
	
	const relatedProduct = oldRelated.concat(records)

const allProduct =	relatedProduct.map(e=>e['code'])
	.map((e,i,final)=>final.indexOf(e)===i&&i)
	.filter(e=>relatedProduct[e])
	.map(e=>relatedProduct[e])
	newContent[0].related = JSON.stringify(allProduct)
	setContent(newContent);
		
				setStatus({...status, checkAll:false})
				setProducts(newProducts)
}



	const handleAddProductAttribute =(event)=>{
		event.preventDefault()

		if(productAttribute.attribute==='' || productAttribute.controlType===''){
			Alerts('Error!', 'danger', 'Please select attribute and Control type')
		}else{

			try{

			
		const newContent = JSON.parse(JSON.stringify(content)) 
		const newAttributes = JSON.parse(newContent[0].attributes);

		const attributes = newAttributes.concat(productAttribute)
		newContent[0].attributes = JSON.stringify(attributes)
		setContent(newContent);
		

		const fd = new FormData(); 
		fd.append('newValue', JSON.stringify(attributes, null, 2));
		fd.append('column', 'attributes');
		fd.append('code', params.code);
	   let url = API_URL+'/product/change_field'
	  axios.post(url, fd, config)
	  .then(response =>{
	   if(response.data.status ===200){
			Alerts('Saved!', 'success', response.data.message)
					   
						   } else{
					   Alerts('Error!', 'danger', response.data)
						   }  
				  
			   })
			   .catch((error)=>{
				 Alerts('Error!', 'danger', error.message)
			   }).finally(()=>{
				setProductAttribute({
					ID:Math.random().toString(36).substring(2,9),
					attribute:'',
					order:1,
					controlType:''
						})
				   setNotice({...notice, 
					   isLoading: false
					 }) 
			   })
			}catch(e){
				
			}
}

		
let close =   document.getElementById('closeProductAttribute')
close.click();
	}

	const handleAddTier =(event)=>{
		event.preventDefault()
		if(tier.price<0 || !tier.price){
			alert("please enter bulk price")
		}else if(tier.quantity<0 || !tier.quantity){
			alert("please enter bulk quantity")
		}else{

		
		const newContent = [...content] 
		const newTier = JSON.parse(newContent[0].bulkPrices);
		
		newContent[0].bulkPrices = JSON.stringify(newTier.concat(tier))
		setContent(newContent);
		let close =   document.getElementById('closeTier')
		close.click();

	setTier({
			ID:Math.random().toString(36).substr(2,9),
			quantity:'',
			price:'',
	})
}

}





const handleDelete =()=>{
	let close =   document.getElementById('btnWarningDialog-delAll')
	close.click();

	setNotice({...notice,  isLoading: true}) 

let url = API_URL+'/product/delete/'+params.code;

axios.get(url, config).then(response=>{ 
	   if(response.data.type ==='success'){
		  Alerts('Saved!', 'success', response.data.message)
		  window.open('/products', '_self')
			  } else{
		  Alerts('Error!', 'danger', response.data)
			  }   
	  })
	  .catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	  }).finally(()=>{
		  setNotice({...notice,  isLoading: false  })
	  }) 	
}


    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        let url = API_URL+'/product/display/one/'+params.code;
		axios.get(url, config).then(result=>{
			if(Array.isArray(result.data.data)){ 
            setContent(result.data.data)
			}
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
  const fetchAttributeProduct =()=>{
	
	let url = API_URL+'/product/attributes/display/all';
     
	axios.get(url,  config).then(result=>{ 
		if(Array.isArray(result.data.data)){
			setAttributeProduct(result.data.data)
		}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})

}
	
const fetchProducts =()=>{
	let url = API_URL+'/product/display/all';
	axios.get(url, config).then(result=>{
		if(Array.isArray(result.data.data)){ 
		setProducts(result.data.data.filter(item=>item.code!==params.code))
		}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}

const fetchAttributeSpecification =()=>{
	
	let url = API_URL+'/specification/display/all';

	axios.get(url, config).then(result=>{
		if(Array.isArray(result.data.data)){ 
			setAttributeSpecification(result.data.data)
		}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})

}

	const handleChangeNumber =(event)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;
		if(name==='pageName'){
			value = value.replace(/,?[ ]/g, '-')
		}	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
	const generateCode =()=>{

		const newContent =JSON.parse(JSON.stringify(content))
		newContent[0].sku= (Math.random().toString(36).substr(2,9)).toUpperCase() 
		setContent(newContent);
		
	}

	const handleSelect = (option, action)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		newContent[0][action.name]= option.value
		setContent(newContent);
		setErrors({...errors, [action.name]:''})
	
	}

	

	const handleChangeInventory =(event)=>{
		const newContent = JSON.parse(JSON.stringify(content)) 
		const newInventory = JSON.parse(newContent[0].inventories);
		let {name, value} = event.target;
		    
		if(name ==='isMultipleWharehouse' ){
			value = value ==='true'?'false':'true'
		}
		if( name ==='isDisplayAvailability' ){
			value = value ==='true'?'false':'true'
		}
		if(name ==='isDisplayStockQuantity' ){
			value = value ==='true'?'false':'true'
		}
		if( name ==='isBackInStock' ){
			value = value ==='true'?'false':'true'
		}
		if( name ==='isReturnable' ){
			value = value ==='true'?'false':'true'
		}
		newInventory[0][name] = value
		newContent[0].inventories = JSON.stringify(newInventory)
		setContent(newContent);
}
const handleChangeCheck =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content)) 
	let {name, value} = event.target;	
	value = value ==='true'?'false':'true'
		newContent[0][name]= value
		setContent(newContent);
}

	const handleChangeShipping =(event)=>{
		const newContent = JSON.parse(JSON.stringify(content)) 
		const newShippings = JSON.parse(newContent[0].shippingDetails);
		let {name, value} = event.target;
		if(name ==='isFreeShipping' ){
			value = value ==='true'?'false':'true'
		}
		if( name ==='isShippedSeperate' ){
			value = value ==='true'?'false':'true'
		}
		newShippings[0][name] = value
		newContent[0].shippingDetails = JSON.stringify(newShippings)
		setContent(newContent);
}

	const handleChangeDescription =(data, name)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		newContent[0][name] = data
		setContent(newContent);
	}

	const handleSelectMultiple = (option, action)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		var value = [];
			  for (var i = 0, l = (option || []).length; i < l; i++) {
				value.push({code:option[i].value});
			  }
			  newContent[0][action.name] = JSON.stringify(value)
			  setContent(newContent);

	  }
	  const handleSelectWithID = (option, action)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		var value = [];
			  for (var i = 0, l = (option || []).length; i < l; i++) {
				value.push(option[i].value);
			  }
			  newContent[0][action.name] = JSON.stringify(value)
			  setContent(newContent);
	  }
	
	const fetchStore =()=>{
        var sql ="Select ID, storeName,  code from tbl_stores order by storeName ASC" ;
        const fd = new FormData();
        fd.append("sql", sql);
		fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller.php';
        axios.post(url, fd, config).then(result=>{  
            setStore(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
  }
  const fetchGroup =()=>{
	var sql ="Select ID, groupName from tbl_groups order by groupName ASC" ;
	const fd = new FormData();
	fd.append("sql", sql);
	fd.append('jwt', Token)
	let url = ServerUrl+'/fetchBySql_controller.php';
	axios.post(url, fd, config).then(result=>{  
		setGroup(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}

	const fetchCategory =()=>{
        let url = API_URL+'/category/display/all';
        axios.get(url, config).then(result=>{ 

			if(Array.isArray(result.data.data)){
            setCategory(result.data.data)
			}
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
  }


  const fetchManufacturer =()=>{
	let url = API_URL+'/brand/display/all';
        axios.get(url, config).then(result=>{ 

			if(Array.isArray(result.data.data)){ 
		setManufacturer(result.data.data)
			}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}

const fetchTags =()=>{
	let url = API_URL+'/tag/display/all';
        axios.get(url, config).then(result=>{ 

			if(Array.isArray(result.data.data)){
		setTags(result.data.data)
			}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
} 
	

const getAttributeOption = (code) =>{ 
	const spec = attributeSpecification.filter(list=>list.code===code);
const answer = JSON.parse(spec[0].option);
setSpecOption(answer);
	 return  answer;
   }

   const getProductName = (code) =>{ 
	const result = products.filter((list)=>list.code===code);
const answer = result.map((c)=>c.productName)
	 return  String(answer);
   }
   

const getItemLabel = (code, mainData, recordName) =>{ 
	const result = mainData.filter((list)=>list.code===code);
const answer = result.map((c)=>c[recordName])

	 return  String(answer);
   }

   const getItemLabelID = (ID, mainData, recordName) =>{ 
	const result = mainData.filter((list)=>list.ID===ID);
const answer = result.map((c)=>c[recordName])
	 return  String(answer);
   }


const getTagData=(field, mainData, recordName)=>{
	var value = [];
		var item;
  JSON.parse(field).map((itm, idx)=>{
	item ={value:itm, label:getItemLabelID(itm, mainData, recordName), key:idx}
		value.push(item);
	});
	
  return value;
  
  }

   const getMultipleItem=(field, mainData, recordName)=>{
	var value = [];
		var item;
  JSON.parse(field).map((itm, idx)=>{
	item ={value:itm.code, label:getItemLabel(itm.code, mainData, recordName), key:idx}
		value.push(item);
	});
	
  return value;
  
  } 
  


  const getCategoryList =(categoryList)=>{
	if(categoryList.length!==0){
		categoryList.map(data =>{
	const result = category.filter(item=>item.code===data.code)
	const allCategory = JSON.parse(result[0].productList);
	const newCategoryList = allCategory.concat([{code:params.code}])
const currentCategory =	newCategoryList.map(e=>e['code'])
				.map((e,i,final)=>final.indexOf(e)===i&&i)
				.filter(e=>newCategoryList[e])
				.map(e=>newCategoryList[e])


				
		const fd = new FormData();
		  fd.append('code', data.code)
		  fd.append('totalItem', currentCategory.length)
		  fd.append('jwt', Token)
		  fd.append('productList', JSON.stringify(currentCategory, null, 2))
	let url = ServerUrl+'/update_controller.php?tablename=tbl_product_category';
	axios.post(url, fd, config)
	.then()
	.catch((error)=>{
	  Alerts('Error!', 'danger', error.message)
	})
})
	}
return ''
}


const getBrand=(code)=>{
const result = manufacturer.filter(item=>item.code===code)
const allProductList = JSON.parse(result[0].productList);
const newProductList = allProductList.concat([{code:params.code}])
const allBrand =	newProductList.map(e=>e['code'])
				.map((e,i,final)=>final.indexOf(e)===i&&i)
				.filter(e=>newProductList[e])
				.map(e=>newProductList[e])
return allBrand
}
	
const getVendor=(code)=>{
const result = vendor.filter(item=>item.code===code)
const allVendorList = JSON.parse(result[0].productList);
const newVendorList = allVendorList.concat([{code:params.code}])

const allVendor =	newVendorList.map(e=>e['code'])
				.map((e,i,final)=>final.indexOf(e)===i&&i)
				.filter(e=>newVendorList[e])
				.map(e=>newVendorList[e])

return allVendor
}


    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		    
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }

		  const brandList =content[0].manufacturers===''?'':getBrand(content[0].manufacturers)
		var totalBrand = content[0].manufacturers===''?'0':brandList.length
		const vendorList = content[0].vendor
		var totalVendor = vendorList&&vendorList.length

		//const categoryList = getCategoryList(JSON.parse(content[0].categories))

		fd.append('brandList', JSON.stringify(brandList, null, 2))
		  fd.append('totalBrand', totalBrand)
		  fd.append('vendorList', JSON.stringify(vendorList, null, 2))
		  fd.append('totalVendor', totalVendor)
		

			 let url = API_URL+'/product/update/'+params.code;

			 axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			})  
	}
	
	const handleCopyProduct=()=>{
		setNotice({...notice, 
			isLoading: true}) 
			   
			let formData = content[0];
			const fd = new FormData();
			for(let k in formData){
			  fd.append(k, formData[k])
			}
			let code = 'product-'+ Math.random().toString(36).substring(2,9);
			const brandList =content[0].manufacturers===''?'':getBrand(content[0].manufacturers)
			var totalBrand = content[0].manufacturers===''?'0':brandList.length
			const vendorList = content[0].vendor
			var totalVendor = vendorList&&vendorList.length
			fd.append('brandList', JSON.stringify(brandList, null, 2))
			fd.append('totalBrand', totalBrand)
			fd.append('vendorList', JSON.stringify(vendorList, null, 2))
			fd.append('totalVendor', totalVendor)

			fd.append('shipping', content[0].shippingDetails)
			fd.append('inventories', content[0].inventories)
		  fd.append('uniqId', code)
		  fd.append('saveType', 'copy')
		  fd.append('productCategories', content[0].categories)
		  fd.append('productTags',content[0].tags)
		  fd.append('productManufacturer', content[0].manufacturers)
		  fd.append('productCustomerRole', content[0].customerRoles)
		  fd.append('productLimitedStore', content[0].limitedStore)
		  fd.append("pageNames", content[0].pageName)

		  let url = API_URL+'/product/create';	
		    axios.post(url, fd, config)
			  .then(response =>{
			   if(response.data.type ==='success'){
				  
				  Alerts('Saved!', 'success', 'Product successfully copied')
				  window.open('/products/edit/'+code, '_self')
					  } else{
				  Alerts('Error!', 'danger', response.data)
					  }   
			  })
			  .catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			  }).finally(()=>{
				  setNotice({...notice, 
					  isLoading: false
				  })
			  }) 
	}


	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.productName){
			errors.productName =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}

	const getSelectCategory=(field)=>{
			var value = [];
				var item;
		  JSON.parse(field).map((itm, idx)=>{
			item ={value:itm.code, label:getCatLabel(itm.code), key:idx}
				value.push(item);
			});
			
		  return value;
		  
	 }
	 
	 const getCatLabel=(code)=>{	  
		let details = get_options();
		const result = details&&details.filter((list)=>list.code===code)
		return (result.map((node)=>node.categoryName))
	}

		const DisplaySelect=(props)=>{	  
			let details = get_options();
			return ( <Select isMulti options={
				details&&details.map((node, id)=>{
					return {value:node.code, key:id, label:node.categoryName}
				})} 
                
	onChange={handleSelectMultiple}  name="categories"  value={getSelectCategory(props.field)} styles={selectStyles} /> 
	)

}
	

 function get_options(parent="root", indent="") {
			var result= []
			category&&category.map(cat=>{
				if(cat.parentCategory===parent){
					result.push({code:cat.code, categoryName:indent+cat.categoryName})
					result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
				}
			})
			return result;
		  }


	

		const tableTierHeader = [
			{dataField: 'quantity',  text: 'Quantity'},
			{dataField: 'price', text: 'price', formatter: (cell, row)=>Currency+FormatNumber(cell)},
			{dataField: 'ID', text: 'Action', formatter: (cell, row)=><button  className="btn btn-danger btn-sm" onClick={()=>handleRemoveTier(row.ID)} ><i className="icofont icofont-delete-alt"></i> Delete</button>, editable: false}
		   
		 ];

	const TableTierRecord=(props)=>{

			const handleUpdate=(column, newValue, ID)=>{     
				
		const newContent = JSON.parse(JSON.stringify(content)) 
		const newTier = JSON.parse(newContent[0].bulkPrices);
		const otherTier = newTier.filter((list)=>list.ID!==ID)
		const tierData = newTier.filter((list)=>list.ID===ID)		
		tierData[0][column.dataField] = newValue
		const currentTier = otherTier.concat(tierData)
		newContent[0].bulkPrices = JSON.stringify(currentTier)

		setContent(newContent);
			 } 


			 const { SearchBar } = Search;
			 
			 const customTotal = (from, to, size) => (
				<span >&nbsp;Showing { from } to { to } of { size } items</span>
			  );
			 const options = {
				showTotal: true,
			  paginationTotalRenderer: customTotal,
			  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
			  { text: 'All', value: props.data.length }]
			   
			  };
			 return  <ToolkitProvider search columnToggle 
						 keyField='ID' data={ props.data } columns={ props.columns } >
							 
							 {
								 props => (
								 <div >
								   <div className="form-group">
									 <SearchBar
									 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
									 
									 
									 
		<div className="dt-buttons btn-group pull-right"> 
			 <button data-toggle="modal"   data-target='#addTier' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</button>
	 </div>                   
		 </div>
									 
						 <BootstrapTable
									 { ...props.baseProps }
												 striped
											 hover
											 condensed
											 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
										 pagination={paginationFactory(options) }
										 cellEdit={ cellEditFactory({
										   mode: 'dbclick',
										   blurToSave: true,
										   autoSelectText: true,
								 beforeSaveCell: (oldValue, newValue, row, column) => {
								   if(oldValue !==newValue){
								   handleUpdate(column, newValue, row.ID);
								   
								  return true;
								 }
								 
							   }
										 }) }
									 />
								 </div>
								 )
							 }
		  </ToolkitProvider>
		  }



	const handleDropFile=(file)=>{
   
			let displayMessage = '';
			var fileName =  file.name;
		 
		  var idxDot = fileName.lastIndexOf(".") + 1;
		  var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
		  if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
			  //TO DO    
			  
			   var FileSize = file.size / 1024 / 1024;  // in MB
		  if (FileSize > 0.5) {
			  displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500 kb';
		  } else {
			  if (file) { //Check if input has files.
				 
				 let code = Math.random().toString(36).substring(2, 9)
				  let imageUrl = code+'_.png'
	let items = [{code:code, imageUrl:imageUrl, order:Number(JSON.parse(content[0].imageList).length)+1, status:'true', cover:'NO'}]
				  handleSubmitImage(file, imageUrl, items)
			  }
		  }
		  }else{
		 
			  displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500 kb'
		  }   
		  
		  if(displayMessage.length > 0){
			  Alerts('Error!', 'danger', displayMessage)
			}
		 
		}
	
	const  handleFile = function(fieldName){
				 return function(newValue){     
				readURL(newValue);
		}			
	 } 
		 
	 const readURL = (input)=>{
				
				 let displayMessage = '';
				   var fileName =  document.getElementById('photo').value;
		 
				 var idxDot = fileName.lastIndexOf(".") + 1;
				 var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
				 if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
					 //TO DO    
					 
					  var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
				 if (FileSize > 0.5) {
					 displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500kb';
					document.getElementById(input.target.name).value = '';
				 } else {
					 if (input.target.files && input.target.files[0]) { //Check if input has files.
						
						let code = Math.random().toString(36).substring(2, 9)
						 let imageUrl = code+'.png'
						 let items = [{code:code, imageUrl:imageUrl, order:Number(JSON.parse(content[0].imageList).length)+1, status:'true', cover:'NO'}]
					
						 handleSubmitImage(input.target.files[0],  imageUrl, items)
					 }
				 }
				 }else{
		 
					 displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500kb'
					document.getElementById(input.target.name).value = '';
				 }   
				 
				 if(displayMessage.length > 0){
					 Alerts('Error!', 'danger', displayMessage)
				   }
				 
			 } 

			 const handleRemoveSpecification =(e, ID)=>{
		 
				e.preventDefault()
				
					const newContent = [...content] 
					const newSpecifications = JSON.parse(newContent[0].specifications);
			
					const otherSpecifications = newSpecifications.filter((list)=>list.ID!==ID)
					
					newContent[0].specifications = JSON.stringify(otherSpecifications)

			setContent(newContent);
			 
			}  

			const handleRemoveRelated =(e, ID)=>{
		 
				e.preventDefault()
				
					const newContent = JSON.parse(JSON.stringify(content)) 
					const newRelated = JSON.parse(newContent[0].related);
					const otherRelated = newRelated.filter((list)=>list.ID!==ID)
					newContent[0].related = JSON.stringify(otherRelated)

			setContent(newContent);
			 
			}
			
			const handleRemoveCrossSell =(e, ID)=>{
		 
				e.preventDefault()
				
					const newContent = JSON.parse(JSON.stringify(content)) 
					const newCrossSell = JSON.parse(newContent[0].crossSell);
					const otherCrossSell = newCrossSell.filter((list)=>list.ID!==ID)
					newContent[0].crossSell = JSON.stringify(otherCrossSell)

			setContent(newContent);
			 
			} 

			 const handleRemoveAttribute =(e, ID)=>{
		 
				e.preventDefault()
				setNotice({...notice, 
					isLoading: true
				  }) 
				let close =   document.getElementById('btnWarningDialog-'+ID)
					close.click();
				
					const newContent = [...content] 
					const newAttributes = JSON.parse(newContent[0].attributes);
			
					const otherAttributes = newAttributes.filter((list)=>list.ID!==ID)
					
					newContent[0].attributes = JSON.stringify(otherAttributes)


			setContent(newContent);


			const fd = new FormData(); 
			fd.append('newValue', JSON.stringify(otherAttributes, null, 2));
			fd.append('column', 'attributes');
			fd.append('code', params.code);
		   let url = API_URL+'/product/change_field'
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.status ===200){
							Alerts('Saved!', 'success', response.data.message)
							
								} else{
							Alerts('Error!', 'danger', response.data)
								}  
					   
					})
					.catch((error)=>{
					  Alerts('Error!', 'danger', error.message)
						 
					}).finally(()=>{
						//fetchContent();
						setNotice({...notice, 
							isLoading: false
						  }) 
					})
			}  	 
				 
		 const handleRemovePhoto =(e, code, imageUrl)=>{
		 
		e.preventDefault()
		let close = document.getElementById('btnWarningDialog-'+code)
			close.click();
			
		
	const newContent = JSON.parse(JSON.stringify(content)) 
	const newImageList = JSON.parse(newContent[0].imageList);
	const otherImageList = newImageList.filter(list=>list.code!==code)
	newContent[0].imageList = JSON.stringify(otherImageList)
	setContent(newContent);

	 const fd = new FormData(); 
	 fd.append("imageUrl", imageUrl);
	fd.append("imageList", JSON.stringify(otherImageList, null, 2));

	setNotice({...notice, 
		isLoading: false
	  }) 
	 let url = API_URL+'/product/remove_image/'+params.code;
			axios.post(url, fd, config)
			.then(response =>{              
				if(response.data.status ===200){
					Alerts('Saved!', 'success', response.data.message)
					
						} else{
					Alerts('Error!', 'danger', response.data)
						}  
			   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
				 
			}).finally(()=>{
				fetchContent();
				setNotice({...notice, 
					isLoading: false
				  }) 
			})
	}   
							  
			 const handleSubmitImage = (image, imageUrl, items) =>{

				let imageList = JSON.stringify(JSON.parse(content[0].imageList).concat(items), null, 2)
				

				 const fd = new FormData(); 
				 fd.append("imageUrl", imageUrl);
				 fd.append('image', image);   
				 fd.append('imageList', imageList); 
				 setNotice({...notice, 
					 isLoading: true}) 

					 let url = API_URL+'/product/add_image/'+params.code;
					 axios.post(url, fd, config)
					 .then(response =>{
					if (response.data.status ===200){
						   Alerts('Success!', 'success', response.data.message)
						   
						   }  
					   else{
						Alerts('Error!', 'danger', response.data)
					   }
					   
					})
					   .catch((error)=>{
						 Alerts('Error!', 'danger', error.message)
							 
					   }).finally(()=>{
						 fetchContent()
						 setNotice({...notice, 
							 isLoading: false
						   })
					   })  
			 } 
		 
	 const thumbnail ={
			 width: '150px',
			 height: '100px',
			 padding: '2px',
			 border: '2px solid lightgray',
			 borderRadius: '3px',
			 float: 'left',
			 margin: '5px'
		 }
		 
	const uploadAreaH2 ={
			 textAlign: 'center',
			 fontWeight: 'normal',
			 fontFamily: 'sans-serif',
			 lineHeight: '50px',
			 color: 'darkslategray'
		 }
		 
	 const uploadArea = {
			 textAlign: 'center',
			 overflow: 'auto'
			 
			 }
		 
	const UpdateCover=(fd)=>{
		setNotice({...notice, 
			isLoading: false
		  }) 
		 let url = API_URL+'/product/change_cover/'+params.code;
				axios.post(url, fd, config)
				.then(response =>{              
				  if (response.data.status !==200){                 
					  Alerts('Error!', 'danger', response.data)
					}  
				   
				})
				.catch((error)=>{
				  Alerts('Error!', 'danger', error.message)
					 
				}).finally(()=>{
					//fetchContent();
					setNotice({...notice, 
						isLoading: false
					  }) 
				})
		 }
		 
	
		 
		 const handleCover=(e, code, imageUrl)=>{ 
e.preventDefault()
	const newContent = JSON.parse(JSON.stringify(content)) 
	const option = JSON.parse(newContent[0].imageList);
			  
			  var value = [];
			 var items;
			 for (var i = 0, l = (option || []).length; i < l; i++) {

			items = {code:option[i].code, imageUrl:option[i].imageUrl, order:option[i].order, status:option[i].status, cover:option[i].code===code?'YES':'NO' }
			 value.push(items);
			 }
		 
			newContent[0].imageList = JSON.stringify(value)

	setContent(newContent);
		 
		 const fd = new FormData(); 
		 fd.append("coverUrl", imageUrl);
		fd.append("imageList", JSON.stringify(value, null, 2));
		
		UpdateCover(fd) 

		 }
		 
			 const openFile=()=>{
				 document.getElementById('photo').click();
			 }
			 const dragOver=(e)=>{        
				 e.preventDefault();
				 e.stopPropagation();

			 }
			 const dragEnter=(e)=>{        
				 e.preventDefault();
				 e.stopPropagation();
			 }
		 
			 const dropFile=(e)=>{        
				e.preventDefault();
        e.stopPropagation();
        var file = e.dataTransfer.files;
       
         handleDropFile(file[0])
            e.dataTransfer.clearData(); 
			 }
			
		   const tableImageHeader = [
			 
			{dataField: 'imageUrl', text: 'Picture', editable: false, formatter:(cell, row)=><img id={cell} className="img-90" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+"/noImage.png"}} src={`${IMAGES_URL}/products/${cell}`}  style={thumbnail} title={row.imageUrl}  alt={row.order} />},
		 
			 {dataField: 'order', text: 'Order', sort:true, validator: (newValue, row, column) => {
				if (!Number(newValue)) {
				  return {
					valid: false,
					message: 'This field required valid number'
				  };
				}
				return true;
			}, formatter:(cell)=><div className="text-center">{cell}</div>},
		 
			 {dataField: 'status', text: 'Status', sort:true,  editor: {
				type: Type.SELECT, options:[{value:'true', label:'Active'},
				{value:'false', label:'Inactive'}]
			  }, formatter:(cell, row)=>row.status==='true'? <div style={{cursor:'pointer'}} className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div style={{cursor:'pointer'}}className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>},
		 
			 {dataField: 'cover', text: 'Cover', sort:true, editable: false, formatter:(cell, row)=><button onClick={(e)=>handleCover(e, row.code, row.imageUrl)} title="make cover" className={row.cover==='YES'?'btn btn-primary btn-sm':'btn btn-default btn-sm'}>{cell}</button>},
		 
			 {dataField: 'code', text: 'Action', formatter:(cell, row)=><div>

			 <WarningModal  handleAction={(e)=>handleRemovePhoto(e, row.code, row.imageUrl)} mID={row.code} />
			
			 <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>
			 
			 </div>, editable: false}
			  ];
		 
			

	const TablePictureRecord=(props)=>{

		const handleUpdate=(column, newValue, code)=>{     
			 
	const newContent = JSON.parse(JSON.stringify(content)) 
	const newImageList = JSON.parse(newContent[0].imageList);
	const otherImageList = newImageList.filter((list)=>list.code!==code)
	const imageData = newImageList.filter((list)=>list.code===code)		
	imageData[0][column.dataField] = newValue
	const currentImageList = otherImageList.concat(imageData)
	newContent[0].imageList = JSON.stringify(currentImageList)

	setContent(newContent); 
		 } 


		 
		 const customTotal = (from, to, size) => (
			<span >&nbsp;Showing { from } to { to } of { size } items</span>
		  );
		 const options = {
			showTotal: true,
		  paginationTotalRenderer: customTotal,
		  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
		  { text: 'All', value: props.data.length }]
		   
		  };
		 return  <ToolkitProvider search columnToggle 
					 keyField='ID' data={ props.data } columns={ props.columns } >
						 
						 {
							 props => (
							 <div >
							   				 
					 <BootstrapTable
								 { ...props.baseProps }
											 striped
										 hover
										 condensed
										 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
									 pagination={paginationFactory(options) }
									 cellEdit={ cellEditFactory({
									   mode: 'dbclick',
									   blurToSave: true,
									   autoSelectText: true,
							 beforeSaveCell: (oldValue, newValue, row, column) => {
							   if(oldValue !==newValue){
							   handleUpdate(column, newValue, row.code);
							   
							  return true;
							 }
							 
						   }
									 }) }
								 />
							 </div>
							 )
						 }
	  </ToolkitProvider>
	  }

	
	  const tableProductAttributeHeader = [
		{dataField: 'attribute', text: 'Attribute', sort:true, editable: false, formatter:(cell, row)=>getItemLabel(row.attribute, attributeProduct, 'attribute_name')},
		 
		  {dataField: 'controlType', text: 'Control type', sort:true, editor: {
			type: Type.SELECT, options:[
			{value:'select', label:'Drop-down list'},
			{value:'radio', label:'Radio button list'},
		]
		  }},
		  {dataField: 'option', text: 'Options', editable: false, formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>
		
		  { JSON.parse(getItemLabel(row.attribute, attributeProduct, 'predefine_values')).map((data, id)=><div key={id}>Name: <b>{data.name}</b>&nbsp; - <b>{Currency+FormatNumber(data.price)}</b> </div>)} </span> },

		{dataField: 'order', text: 'Order',  sort:true, validator: (newValue, row, column) => {
			if (!Number(newValue)) {
			  return {
				valid: false,
				message: 'This field required valid number'
			  };
			}
			return true;
		}, formatter:(cell)=><div className="text-center">{cell}</div>},
		 {dataField: 'ID', text: 'Action', formatter:(cell, row)=><div>

		 <WarningModal  handleAction={(e)=>handleRemoveAttribute(e, row.ID)} mID={row.ID} />
		
		 <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.ID}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>
		 
		 </div>, editable: false}
		  ];
	 


		  const TableProductAttributeRecord=(props)=>{

			const handleUpdate=(column, newValue, ID)=>{     
				
		const newContent = JSON.parse(JSON.stringify(content)) 
		const newAttributes = JSON.parse(newContent[0].attributes);


		const currentContent = newAttributes.map((item)=>{
                   
			if(item.ID ===ID){
				return {...item, [column.dataField]:newValue}
			}
	
			 return item
			  })


		newContent[0].attributes = JSON.stringify(currentContent, null, 2)

		setContent(newContent); 
		
		
	 	const fd = new FormData(); 
		fd.append('newValue', JSON.stringify(currentContent, null, 2));
		fd.append('column', 'attributes');
		fd.append('code', params.code);
	   let url = API_URL+'/product/change_field'
	  axios.post(url, fd, config)
	  .then(response =>{
			   })
			   .catch((error)=>{
				 Alerts('Error!', 'danger', error.message)
					
			   }) 
			 } 


			 const { SearchBar } = Search;
			 
			 const customTotal = (from, to, size) => (
				<span >&nbsp;Showing { from } to { to } of { size } items</span>
			  );
			 const options = {
				showTotal: true,
			  paginationTotalRenderer: customTotal,
			  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
			  { text: 'All', value: props.data.length }]
			   
			  };
			 return  <ToolkitProvider search columnToggle 
						 keyField='ID' data={ props.data } columns={ props.columns } >
							 
							 {
								 props => (
								 <div >
								   <div className="form-group">
									 <SearchBar
									 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
									 
									 
									 
		<div className="dt-buttons btn-group pull-right"> 
			 <button data-toggle="modal"   data-target='#addProductAttribute' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</button>
	 </div>                   
		 </div>
									 
						 <BootstrapTable
									 { ...props.baseProps }
												 striped
											 hover
											 condensed
											 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
										 pagination={paginationFactory(options) }
										 cellEdit={ cellEditFactory({
										   mode: 'dbclick',
										   blurToSave: true,
										   autoSelectText: true,
								 beforeSaveCell: (oldValue, newValue, row, column) => {
								   if(oldValue !==newValue){
								   handleUpdate(column, newValue, row.ID);
								   
								  return true;
								 }
								 
							   }
										 }) }
									 />
								 </div>
								 )
							 }
		  </ToolkitProvider>
		  }


const getOption =(code, ID)=>{
	const attribute = JSON.parse(getItemLabel(code, attributeSpecification, 'option'))
	const option = attribute.filter(item=>item.ID===ID)
	return option.length!==0?option[0].name:''
}
		  const tableSpecificationHeader = [
			{dataField: 'attribute', text: 'Attribute', sort:true, editable: false, formatter:(cell, row)=>getItemLabel(row.attribute, attributeSpecification, 'specification_name')},
			{dataField: 'attributeOption', text: 'Selected', sort:true, editable: false, formatter:(cell, row)=>getOption(row.attribute, row.attributeOption)},
			  {dataField: 'showOnProduct', text: 'Show on Product', sort:true, editor: {
				type: Type.SELECT, options:[{value:'true', label:'Show on product page'},
				{value:'false', label:'Do not show on product home'}]
			  }, formatter:(cell, row)=>row.showOnProduct==='true'? <div style={{cursor:'pointer'}} className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div style={{cursor:'pointer'}}className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>},

			{dataField: 'order', text: 'Order', sort:true, validator: (newValue, row, column) => {
				if (!Number(newValue)) {
				  return {
					valid: false,
					message: 'This field required valid number'
				  };
				}
				return true;
			}, formatter:(cell)=><div className="text-center">{cell}</div>},
			 {dataField: 'ID', text: 'Action', formatter:(cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={(e)=>handleRemoveSpecification(e, row.ID)}title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>, editable: false}
			  ];
		 
	
	
			  const TableSpecificationRecord=(props)=>{
	
			const handleUpdate=(column, newValue, ID)=>{     
					
			const newContent = JSON.parse(JSON.stringify(content)) 
			const newSpecifications = JSON.parse(newContent[0].specifications);
	
			const otherSpecifications = newSpecifications.filter((list)=>list.ID!==ID)
			const specificationsData = newSpecifications.filter((list)=>list.ID===ID)	
			specificationsData[0][column.dataField] = newValue
			const currentSpecifications = otherSpecifications.concat(specificationsData)
			newContent[0].specifications = JSON.stringify(currentSpecifications)
			setContent(newContent); 
			
		} 
	
	
				 const { SearchBar } = Search;
				 
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='ID' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										 <SearchBar
										 { ...props.searchProps } style={{height:'40px'}} className="form-control" ID="searchAble" placeholder="Type to search!!!" />
										 
										 
										 
			<div className="dt-buttons btn-group pull-right"> 
				 <button data-toggle="modal"   data-target='#addSpecification' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</button>
		 </div>                   
			 </div>
										 
							 <BootstrapTable
										 { ...props.baseProps }
													 striped
												 hover
												 condensed
												 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
											 pagination={paginationFactory(options) }
											 cellEdit={ cellEditFactory({
											   mode: 'dbclick',
											   blurToSave: true,
											   autoSelectText: true,
									 beforeSaveCell: (oldValue, newValue, row, column) => {
									   if(oldValue !==newValue){
									   handleUpdate(column, newValue, row.ID);
									   
									  return true;
									 }
									 
								   }
											 }) }
										 />
									 </div>
									 )
								 }
			  </ToolkitProvider>
			  }
	
	 const handleCheckOne =(e)=>{  

		let {name, value} = e.target

		const currentContent = products.map((item)=>{
					   
			if(item.code ===name){
				return {...item, status:item.status==='false'?'true':'false'}
			}
	
			 return item
			  })

	  setStatus({...status, checkAll:false})
	   setProducts(currentContent) 
				   
				  }

		const handleCheckAll =()=>{
				
				const currentContent = products.map((item)=>{
						return {...item, status:String(!status.checkAll)}
					
					  })

				setStatus({...status, checkAll:!status.checkAll})
				setProducts(currentContent)
			 }

	 const generalCheck =()=><div className="checkbox-fade fade-in-success">
			  <label>
			<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
				  <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
			  </label>
			</div> 

			  const  tableProductHeader = [
				{dataField: 'ID',  text: generalCheck(), editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={row.status} name={row.code}  checked={row.status==='true'?true:false} onChange={(e)=>handleCheckOne(e)} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>},
				{dataField: 'productName', text: 'Name', sort:true, editable: false},
				
				  {dataField: 'isPublished', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.isPublished==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>}
				  ];
			 
		
		
		const TableProductRecord=(props)=>{
		
					 const { SearchBar } = Search;
					 
					 const customTotal = (from, to, size) => (
						<span >&nbsp;Showing { from } to { to } of { size } items</span>
					  );
					 const options = {
						showTotal: true,
					  paginationTotalRenderer: customTotal,
					  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
					  { text: 'All', value: props.data.length }]
					   
					  };
					 return  <ToolkitProvider search columnToggle 
								 keyField='ID' data={ props.data } columns={ props.columns } >
									 
									 {
										 props => (
										 <div >
										   <div className="form-group">
											 <SearchBar
											 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
											                 
				 </div>
											 
		<BootstrapTable
					{ ...props.baseProps }
								striped
							hover
							condensed
							noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
						pagination={paginationFactory(options) }
						cellEdit={ cellEditFactory({
						mode: 'dbclick',
						blurToSave: true,
						autoSelectText: true
						}) }
					/>
				</div>
				)
			}
</ToolkitProvider>
				  }



		 const  tableRelatedProductsHeader = [
			{dataField: 'code',  text: 'Product', editable: false,  formatter:(cell, row)=>getProductName(row.code)},
						
			{dataField: 'order', text: 'Order', sort:true, validator: (newValue, row, column) => {
				if (!Number(newValue)) {
				  return {
					valid: false,
					message: 'This field required valid number'
				  };
				}
				return true;
			}, formatter:(cell)=><div className="text-center">{cell}</div>},
			 {dataField: 'ID', text: 'Action', formatter:(cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={(e)=>handleRemoveRelated(e, row.ID)} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>, editable: false}


					  ];
				 
			
			
			const TableRelatedRecord=(props)=>{
				const handleUpdate=(column, newValue, ID)=>{     
					
					const newContent = JSON.parse(JSON.stringify(content)) 
					const newRelated = JSON.parse(newContent[0].related);
			
					const otherRelated = newRelated.filter((list)=>list.ID!==ID)
					const relatedData = newRelated.filter((list)=>list.ID===ID)	
					relatedData[0][column.dataField] = newValue
					const currentRelated = otherRelated.concat(relatedData)
					newContent[0].related = JSON.stringify(currentRelated)
					setContent(newContent); 
					
				} 
						 const { SearchBar } = Search;
						 
						 const customTotal = (from, to, size) => (
							<span >&nbsp;Showing { from } to { to } of { size } items</span>
						  );
						 const options = {
							showTotal: true,
						  paginationTotalRenderer: customTotal,
						  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
						  { text: 'All', value: props.data.length }]
						   
						  };
						 return  <ToolkitProvider search columnToggle 
									 keyField='ID' data={ props.data } columns={ props.columns } >
										 
										 {
											 props => (
											 <div >
											   <div className="form-group">
												
										 
			<div className="dt-buttons btn-group"> 
				 <button data-toggle="modal"   data-target='#addRelated' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New Related Product</button>
		 </div>                   
			 </div>
												 
			<BootstrapTable
						{ ...props.baseProps }
									striped
								hover
								condensed
								noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
							pagination={paginationFactory(options) }
							cellEdit={ cellEditFactory({
								mode: 'dbclick',
								blurToSave: true,
								autoSelectText: true,
					  beforeSaveCell: (oldValue, newValue, row, column) => {
						if(oldValue !==newValue){
						handleUpdate(column, newValue, row.ID);
						
					   return true;
					  }
					  
					}
							  }) }
						/>
					</div>
					)
				}
	</ToolkitProvider>
					  }
	

	const  tableCrossSellHeader = [
	{dataField: 'code',  text: 'Product', editable: false,  formatter:(cell, row)=>getProductName(row.code)},
		{dataField: 'ID', text: 'Action', formatter:(cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={(e)=>handleRemoveCrossSell(e, row.ID)} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>, editable: false}
				];
							 
						
						
		const TableCrossSellRecord=(props)=>{
				
									 
		const customTotal = (from, to, size) => (
		<span >&nbsp;Showing { from } to { to } of { size } items</span>
		);
		const options = {
		showTotal: true,
		paginationTotalRenderer: customTotal,
		sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
		{ text: 'All', value: props.data.length }]
		
		};
		return  <ToolkitProvider search columnToggle 
					keyField='ID' data={ props.data } columns={ props.columns } >
						
						{
			props => (
			<div >
									
<div className="form-group">
			
		
			<div className="dt-buttons btn-group"> 
					<button data-toggle="modal"   data-target='#addCrossSell' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add Cross-sell Product</button>
			</div>                   
												 </div>							
		<BootstrapTable
					{ ...props.baseProps }
								striped
							hover
							condensed
							noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
						pagination={paginationFactory(options) }
						
					/>
				</div>
				)
			}
				</ToolkitProvider>
								  }
				




	const  tableOrderHeader = [
		{dataField: 'orderId',  text: 'ID',  sort: true, editable: false},
    {dataField: 'orderStatus',  text: 'Order Status',  sort: true, editable: false, formatter:(cell, row)=><a href={`/orders/edit/${row.orderId}`} className={cell==='Complete'?'grid-report-item green':cell==='Cancelled'?'btn btn-danger btn-sm':cell==='Processing'?'grid-report-item blue':'grid-report-item yellow'} rel="noopener noreferrer"><i className="fa fa-eye"></i> {cell}</a>},
	{dataField: 'paymentStatus',  text: 'Payment Status',  sort: true, editable: false, formatter:(cell)=><span style={{display:'block', width:100,  whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
	{dataField: 'shippingStatus',  text: 'Shipping Status',  sort: true, editable: false, formatter:(cell)=><span style={{display:'block',  width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
	{dataField: 'customerCode',  text: 'Customer',  sort: true, editable: false, formatter:(cell, row)=><a href={`/customer/edit/${row.customerId}`} style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</a>},
	{dataField: 'dateCreate',  text: 'Created On',  sort: true, editable: false},
				];
								
						
						
		const TableOrderRecord=(props)=>{
				
			const { SearchBar } = Search;						
		const customTotal = (from, to, size) => (
		<span >&nbsp;Showing { from } to { to } of { size } items</span>
		);
		const options = {
		showTotal: true,
		paginationTotalRenderer: customTotal,
		sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
		{ text: 'All', value: props.data.length }]
		
		};
		return  <ToolkitProvider search columnToggle 
					keyField='orderId' data={ props.data } columns={ props.columns } >
						
						{
			props => (
			<div >
					<div className="form-group">
							<SearchBar
							{ ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
											
</div>						
		<BootstrapTable
					{ ...props.baseProps }
								striped
							hover
							condensed
							noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
						pagination={paginationFactory(options) }
						
					/>
				</div>
				)
			}
				</ToolkitProvider>
									}
												
useEffect(()=>{
	fetchCategory()
	fetchManufacturer()
	fetchTags()
	fetchContent()
	fetchStore()
	fetchGroup()
	fetchAttributeProduct()
	fetchAttributeSpecification() 
	fetchProducts()
	fetchDiscount()
	fetchVendor()
	fetchOrders()
},[]);
        return (<Template 
            menu="Catalog"
        submenu="Products">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">

<PageHeader title="Edit Product" description='Update product information here' icon='feather icon-book'>
	
<li className="breadcrumb-item"><a href="/products">Products</a> </li>

                    </PageHeader>


					   {/* tiers modal start here */}

	

{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								

<div className="modal fade " id='addTier' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add new bulk price</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Quantity" toolTip={Tooltip.tierQuantity} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="number" className="form-control" onChange={handleChangeTier}  name="quantity" value={tier.quantity} />
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.quantity}</span>
							</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Price" toolTip={'Specify the price.'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="number" className="form-control" onChange={handleChangeTier}  name="price" value={tier.price} />
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.price}</span>
							</div>
				</div>
				
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeTier' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddTier(event)} className="btn btn-inverse">Save</button>
              </div>
          </div>
      </div>
  </div>

  <div className="modal fade " id='addProductAttribute' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add a new attribute</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="form-group row">
					<div className="col-sm-5">
							<FormLabel name ="Attribute" toolTip={Tooltip.productAttribute} />
					</div>
									
						<div className="col-sm-7 ">
						<select  name="attribute" value={productAttribute.attribute} className="form-control" onChange={handleChangeAttribute}>
                        <option value="">[None]</option>
{attributeProduct&&attributeProduct.map((st, id)=><option key={id} value={st.code}>{st.attribute_name} -  {st.description}</option>)}
                    </select>

							</div>
				</div>
				

			<div className="form-group row">
					<div className="col-sm-5">
							<FormLabel name ="Control Type" toolTip={Tooltip.controlType} />
					</div>
									
						<div className="col-sm-7 ">
						<select name="controlType" value={productAttribute.controlType} className="form-control" onChange={handleChangeAttribute}>
						<option value="">[None]</option>
					<option value="select">Drop-down list</option>
					<option value="radio">Radio button list</option>
                    </select>

							</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-5">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="order" value={productAttribute.order} onChange={handleChangeAttribute} />

							</div>
			</div>
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeProductAttribute' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddProductAttribute(event)} className="btn btn-inverse">Save</button>
              </div>
          </div>
      </div>
  </div>



  <div className="modal fade " id='addSpecification' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add a new specification</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">


			  <div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Specification" toolTip={Tooltip.attribute} />
					</div>
									
						<div className="col-sm-8 ">
						<select  name="attribute" value={specification.attribute} className="form-control"  onChange={handleChangeSpecification}>
                        <option  value=''>[None]</option>
                        {attributeSpecification&&attributeSpecification.map((st, id)=><option key={id} value={st.code}>{st.specification_name}</option>)}
                    </select>

							</div>
				</div>
						<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Attribute Option" toolTip={'The value of the specification attribute'} />
					</div>
									
						<div className="col-sm-8 ">
						<select  name="attributeOption" value={specification.attributeOption} className="form-control" onChange={handleChangeSpecification}>
                        <option  value=''>[None]</option>
                        {specOption&&specOption.map((st, id)=><option key={id} value={st.ID}>{st.name}</option>)}
                    </select>

							</div>
				</div>

			<div className="form-group row">
			<div className="col-sm-4">
					<FormLabel name ="Show on product" toolTip={Tooltip.showOnProduct}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={specification.showOnProduct} name="showOnProduct" checked={specification.showOnProduct==='false'?false:true} onChange={handleChangeSpecification} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

				<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" className="form-control" onChange={handleChangeSpecification}  name="order" min="0" value={specification.order} />
						
							
							</div>
							</div>
				</div>
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeSpecification' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(e)=>handleAddSpecifications(e)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>




  <div className="modal fade " id='addRelated' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add new related product</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">



			  <div className="table-responsive">
        
        <TableProductRecord data={products}  columns={tableProductHeader}  />
            </div>


              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeRelated' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddRelated(event)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>



  <div className="modal fade " id='addCrossSell' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add new cross-sell product</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">



			  <div className="table-responsive">
        
        <TableProductRecord data={products}  columns={tableProductHeader}  />
            </div>


              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeCrossSell' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleCrossSell(event)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>

			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-inverse btn-sm" onClick={(e)=>handleSubmit(e, '/products')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/products/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;

	<button type="button" onClick={handleCopyProduct} className="btn btn-success btn-sm"><i className="fa fa-copy"></i> Copy Product</button>&nbsp;

	<a href="/products" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={handleDelete} mID={'delAll'} /> 

	
<div className="pull-right">
	{panel.basic?
	<button type="button" onClick={goAdvance} className="btn btn-sm  "> Basic Mode <i className="fa fa-check"></i></button> :
	<button type="button" onClick={goBasic} className="btn btn-primary btn-sm ">  Advanced Mode <i className="fa fa-check"></i></button>}
  </div>

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, info:!panel.info})}>
			<h5><i className="fa fa-info"></i> Product Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.info?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.info?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Product Name" toolTip={Tooltip.productName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  ref={myRef} name="productName" value={data.productName} className={errors.productName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.productName}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Short description" toolTip={Tooltip.shortDescription} />
					</div>
									
						<div className="col-sm-9 ">
							
						


						<QuillText value={data.shortDescription} onChange={(data)=>handleChangeDescription(data, 'shortDescription')} />


							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Full Description" toolTip={Tooltip.fullDescription} />
					</div>
									
						<div className="col-sm-9 ">
						<QuillText value={data.fullDescription} onChange={(data)=>handleChangeDescription(data, 'fullDescription')} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Product Code" toolTip={Tooltip.sku} />
					</div>
									
					<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" autoComplete="off" onChange={handleChange}   name="sku" value={data.sku} className='form-control ' />
							
							<span className="input-group-addon" onClick={generateCode} id="basic-addon">Generate Code</span>
							</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Categories" toolTip={Tooltip.category} />
					</div>
									
						<div className="col-sm-9 ">
						<DisplaySelect field={data.categories} /> 
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Brand" toolTip={Tooltip.productManufacturer} />
					</div>
									
						<div className="col-sm-9 ">
						<Select options={
				manufacturer&&manufacturer.map((data, id)=>{
					return {value:data.code, key:id, label:data.brandName}
				})}  
                
onChange={handleSelect}  name="manufacturers"  value={{value:data.manufacturers, label:getItemLabel(data.manufacturers, manufacturer, 'brandName')}} styles={selectStyles} /> 
							</div>
					</div>

					{panel.infoDisplay?<>
					
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Published"  toolTip={Tooltip.published} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={data.isPublished} name="isPublished" checked={data.isPublished==='false'?false:true} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Is Featured"  toolTip={'Featured products appears on featured page'} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={data.isFeatured} name="isFeatured" checked={data.isFeatured==='false'?false:true} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Rating" toolTip={'Select product rating'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="rating" value={data.rating} className="form-control" onChange={handleChange}>
							<option value="1">1</option>
                        	<option value="2">2</option>

                        	<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
                    </select>
							
							</div>
							</div>
			</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Products Tag" toolTip={Tooltip.tags} />
					</div>
									
						<div className="col-sm-9 ">
						<Select isMulti options={
				tags&&tags.map((data, id)=>{
					return {value:data.code, key:id, label:data.tagName}
				})}   
                
onChange={handleSelectWithID}  name="tags"  value={getTagData(data.tags, tags, 'tagName')} styles={selectStyles} /> 
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="GTIN" toolTip={Tooltip.gtin} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="gtin" value={data.gtin} className='form-control' />
							
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Youtube Url" toolTip={'Youtube Video of product'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="youtubeUrl" value={data.youtubeUrl} className='form-control' />
							
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Manufacturer Part Number" toolTip={Tooltip.partNumber} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="mPartNumber" value={data.mPartNumber} className='form-control' />
							
							</div>
							</div>
					</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Show on home page"  toolTip={Tooltip.showOnHome} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={data.showOnHome} name="showOnHome" checked={data.showOnHome==='false'?false:true} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

{data.showOnHome==='true'?
			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={data.displayOrder} onChange={handleChangeNumber} />

							</div>
			</div>:''}

			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Product Type" toolTip={Tooltip.productType} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="productType" value={data.productType} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
                        <option value="simple">Simple</option>
                        <option value="grouped">Grouped</option>
                    </select>
							
							</div>
							</div>
			</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Customer Roles" toolTip={Tooltip.customerRole} />
					</div>
									
						<div className="col-sm-9 ">
						<Select isMulti options={
				group&&group.map((node, id)=>{
					return {value:node.code, key:id, label:node.groupName}
				})} 
                
onChange={handleSelectWithID}  name="customerRoles"  value={getTagData(data.customerRoles, group, 'groupName')} styles={selectStyles} /> 
									
</div>


							</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Limited To Store" toolTip={Tooltip.limitedStore} />
					</div>
									
						<div className="col-sm-9 ">
						
				<Select isMulti options={
				vendor&&vendor.map((node, id)=>{
					return {value:node.code, key:id, label:node.vendorName}
				})} 
                
onChange={handleSelectMultiple}  name="limitedStore"  value={getMultipleItem(data.limitedStore, vendor, 'vendorName')} styles={selectStyles} /> 
					</div></div>



				<div className="form-group row">
				<div className="col-sm-3">
						<FormLabel name ="Vendor" toolTip={Tooltip.vendor} />
				</div>
								
					<div className="col-sm-9 ">
				<div className="input-group">
				<select  name="vendor" value={data.vendor} className="form-control" onChange={handleChange}>
				<option value="">[None]</option>
				{vendor&&vendor.map((node, id)=>{
					return <option  key={id} value={node.code}>{node.vendorName}</option>
				})} 
				</select>
						
						</div>
						</div>
				</div>



				<div className="form-group row">
				<div className="col-sm-3">
						<FormLabel name ="Allow customer reveiw" toolTip={Tooltip.customerReview}  />
				</div>
								
					<div className="col-sm-9 ">
					<div className="checkbox-fade fade-in-success">
					<label>
				<input type="checkbox" value={data.isCustomerReveiw} name="isCustomerReveiw" checked={data.isCustomerReveiw==='false'?false:true} onChange={handleChangeCheck} />
						<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
					</label>
				</div>
						</div>
				</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Available Start" toolTip={Tooltip.availableStart} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="datetime-local" onChange={handleChange}  name="availableStart" value={convertDateTime(data.availableStart)} className='form-control' />
							
							</div>
							</div>
					</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Available End" toolTip={Tooltip.availableEnd} />
			</div>
							
				<div className="col-sm-4 ">
					<div className="input-group">
				<input type="datetime-local" onChange={handleChange}  name="availableEnd" value={convertDateTime(data.availableEnd)} className='form-control' />
					
					</div>
					</div>
			</div>


			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Mark as New" toolTip={Tooltip.markNew}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.isNew} name="isNew" checked={data.isNew==='false'?false:true} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

{data.isNew==='true'?<>
			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Mark new Start" toolTip={Tooltip.markNewStart} />
			</div>
							
				<div className="col-sm-4 ">
					<div className="input-group">
				<input type="datetime-local" onChange={handleChange}  name="isNewStart" value={convertDateTime(data.isNewStart)} className='form-control' />
					
					</div>
					</div>
			</div>


		<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Mark New End" toolTip={Tooltip.markNewEnd} />
		</div>
						
			<div className="col-sm-4 ">
				<div className="input-group">
			<input type="datetime-local" onChange={handleChange}  name="isNewEnd" value={convertDateTime(data.isNewEnd)} className='form-control' />
				
				</div>
				</div>
		</div>
		</>:''}


		<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Admin Comment" toolTip={Tooltip.adminComment} />
		</div>
						
			<div className="col-sm-9 ">
				
			<textarea type="text" className="form-control" rows="5" name="adminComment" value={data.adminComment} onChange={handleChange}>{data.adminComment}
			</textarea>

				</div>
		</div>
					
					</>:''}
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>




{/* Price panel start here  */}
							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, price:!panel.price})}>
			<h5><i className="fa fa-money"></i> Price</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.price?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.price?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           

						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Price" toolTip={Tooltip.price} />
					</div>
					
					<div className="col-sm-9 ">	
					<div className="row">	
						<div className="col-sm-5 ">
						<input type="number" className="form-control" min="0" name="price" 
						value={data.price} onChange={handleChangeNumber} />
						</div>
						<div className="col-sm-5 "><b>{data.price!==0?Currency+FormatNumber(data.price):''}</b></div>
							
					</div>
					</div>	
					
					</div>
					{panel.displayPrice?<>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Old Price" toolTip={Tooltip.oldPrice} />
					</div>
									
					<div className="col-sm-9 ">	
					<div className="row">	
						<div className="col-sm-5 ">
						<input type="number" className="form-control" min="0" name="oldPrice" 
						value={data.oldPrice} onChange={handleChangeNumber} />
						</div>
						<div className="col-sm-5 "><b>{data.oldPrice!==0?Currency+FormatNumber(data.oldPrice):''}</b></div>
							
					</div>
					</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Product Cost" toolTip={Tooltip.costPrice} />
					</div>
									
					<div className="col-sm-9 ">	
					<div className="row">	
						<div className="col-sm-5 ">
						<input type="number" className="form-control" min="0" name="costPrice" 
						value={data.costPrice} onChange={handleChangeNumber} />
						</div>
						<div className="col-sm-5 "><b>{data.costPrice!==0?Currency+FormatNumber(data.costPrice):''}</b></div>
							
					</div>
					</div>
					</div>


					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Disable Add To Cart" toolTip={Tooltip.buyButon}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.isOutOfStock} name="isOutOfStock" checked={data.isOutOfStock==='false'?false:true} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Disable wishlist" toolTip={Tooltip.wishList}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.isWishlistDisabled} name="isWishlistDisabled" checked={data.isWishlistDisabled==='false'?false:true} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Available for pre-order" toolTip={Tooltip.preorder}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.isPreorder} name="isPreorder" checked={data.isPreorder==='false'?false:true} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
			{data.isPreorder?
	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Pre-order  startdate" toolTip={Tooltip.preorderStart} />
					</div>
									
					<div className="col-sm-4 ">
					<div className="input-group">
				<input type="datetime-local" onChange={handleChange}  name="preorderStart" value={convertDateTime(data.preorderStart)} className='form-control' />
					
					</div>
					</div>
		</div>:''}

		<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Call for Price" toolTip={Tooltip.callforPrice}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.isCallforPrice} name="isCallforPrice" checked={data.isCallforPrice==='false'?false:true} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Customer enters price" toolTip={Tooltip.customerPrice}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.isCustomerPrice} name="isCustomerPrice" checked={data.isCustomerPrice==='false'?false:true} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			{data.isCustomerPrice==='true'?<>

			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Minimum Amount" toolTip={Tooltip.minAmount} />
					</div>
									
					<div className="col-sm-9 ">	
					<div className="row">	
						<div className="col-sm-5 ">
						<input type="number" className="form-control" min="0" name="minAmount" 
						value={data.minAmount} onChange={handleChangeNumber} />
						</div>
						<div className="col-sm-5 "><b>{data.minAmount!==0?Currency+FormatNumber(data.minAmount):''}</b></div>
							
					</div>
					</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Maximum Amount" toolTip={Tooltip.maxAmount} />
					</div>
									
					<div className="col-sm-9 ">	
					<div className="row">	
						<div className="col-sm-5 ">
						<input type="number" className="form-control" min="0" name="maxAmount" 
						value={data.maxAmount} onChange={handleChangeNumber} />
						</div>
						<div className="col-sm-5 "><b>{data.maxAmount!==0?Currency+FormatNumber(data.maxAmount):''}</b></div>
							
					</div>
					</div>
					</div></>:''}





					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Discount" toolTip={Tooltip.discount} />
					</div>
									
						<div className="col-sm-9 ">
						<select  name="discount" value={data.discount} className="form-control" onChange={handleChange}>
						<option value="none">[none]</option>
                        {discount&&discount.map((node, id)=>{
					return <option  key={id} value={node.code}>{node.discountName}</option>
				})} 
                    </select>

							</div>
					</div>
					</>:''}

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Tax exempt"  toolTip={Tooltip.isTaxExempt} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={data.isTaxExempt} name="isTaxExempt" checked={data.isTaxExempt==='false'?false:true} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


			{data.isTaxExempt!=='true'?	
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Tax Category" toolTip={Tooltip.taxCategory} />
					</div>
									
						<div className="col-sm-9 ">
						<select  name="taxCategory" value={data.taxCategory} className="form-control" onChange={handleChange}>
						<option value="none">[Default]</option>
                    </select>

							</div>
					</div>:''}
					
					

	</section></div>
	

	{panel.displayPrice?
	<div className="row">
	<div className="col-sm-12">
					<div className="card z-depth-0">
							<section className="card-block">
<div className="row">


	<h5 >Bulk Prices</h5>
	<div className="bg-success col-sm-12">
		<p>Bulk pricing is a promotional tool that allows a store to price items differently for higher quantities.</p>
		</div>
</div><br/>
<div className="table-responsive">

	{<TableTierRecord data={JSON.parse(data.bulkPrices)} columns={tableTierHeader}  />}
		</div>
							</section>
					</div>
			</div>
		</div>:''}
	
	
	</div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>




{/* printing panel start here */}
	{/* 		
<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, printing:!panel.printing})}>
			<h5><i className="fa fa-print"></i> Printing Product</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.printing?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.printing?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Is Product Printing"  toolTip={Tooltip.isPrinting} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={data.isPrinting} name="isPrinting" checked={data.isPrinting==='true'?true:false} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
				
			{data.isPrinting ==='true'?<>

						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Graphic Design Fee" toolTip={'Design fee will be added only when customer want your expert to design it '} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="designFee" 
						value={data.designFee} onChange={handleChange} />

							</div>

						<div className="col-sm-5 "><b>{data.designFee!==0?Currency+FormatNumber(data.designFee):''}</b></div>
					</div>
					

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Note" toolTip={'Design Note'} />
					</div>
									
						<div className="col-sm-8 ">
						<textarea type="text" className="form-control" rows="5" name="designNote" value={data.designNote} onChange={handleChange}>{data.designNote}
			</textarea>

							</div>
					</div>


					</>:''}	

	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>


 */}

{/* shipping panel start here */}
			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, shipping:!panel.shipping})}>
			<h5><i className="fa fa-bus"></i> Shipping</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.shipping?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.shipping?		<div className="card-block" >
		{JSON.parse(data.shippingDetails).map((ship, k)=>
 <div className="row" key={k}>
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Shipping enabled"  toolTip={Tooltip.isShipped} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={data.isShippable} name="isShippable" checked={data.isShippable==='false'?false:true} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
				
			{data.isShippable==='true'?<>

				
			
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Weight" toolTip={Tooltip.weight} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="text" className="form-control" min="0" name="weight" 
						value={ship.weight} onChange={handleChangeShipping} />

							</div>
					</div>
					

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Length" toolTip={Tooltip.length} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="text" className="form-control" min="0" name="length" 
						
						value={ship.length} onChange={handleChangeShipping} />

							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Width" toolTip={Tooltip.width} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="text" className="form-control" min="0" name="width" 
						
						value={ship.width} onChange={handleChangeShipping} />

							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Height" toolTip={Tooltip.height} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="text" className="form-control" min="0" name="height" value={ship.height} onChange={handleChangeShipping} />

							</div>
					</div>

					{panel.displayShipping?<>
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Free Shipping" toolTip={Tooltip.isFreeShipping}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox"  name="isFreeShipping" checked={ship.isFreeShipping==='false'?false:true} value={ship.isFreeShipping} onChange={handleChangeShipping} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Ship separately" toolTip={Tooltip.shipSeperate}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox"  name="isShippedSeperate" checked={ship.isShippedSeperate==='false'?false:true} value={ship.isShippedSeperate} onChange={handleChangeShipping} />

					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Shipping charge" toolTip={Tooltip.shippingCharge} />
					</div>
									
					<div className="col-sm-9 ">	
					<div className="row">	
						<div className="col-sm-5 ">
						<input type="number" className="form-control" min="0" name="shippingCharge" 
						value={ship.shippingCharge} onChange={handleChangeShipping} />
						</div>
						<div className="col-sm-5 "><b>{ship.shippingCharge!==0?Currency+FormatNumber(ship.shippingCharge):''}</b></div>
							
					</div>
					</div>
			</div>

			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Delivery Period" toolTip={Tooltip.deliveryDate} />
					</div>
									
						<div className="col-sm-9 ">
					<select  name="deliveryPeriod" className="form-control" 
						value={ship.deliveryPeriod} onChange={handleChangeShipping}>
						<option  value="0">None</option>
						<option value="1">1-2 days</option>
						<option value="2">3-5 days</option>
						<option value="3">1 week</option>
                    </select>

							</div>
					</div></>:''}	
					</>:''}	

	</section></div></div></div>)}
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>








			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, inventory:!panel.inventory})}>
			<h5><i className="fa fa-sitemap"></i> Inventory</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.inventory?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.inventory?		<div className="card-block" >
		{JSON.parse(data.inventories).map((inventory, k)=> 	
 <div className="row" key={k}>
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Inventory method"  toolTip={Tooltip.inventoryMethod} />
			</div>
							
				<div className="col-sm-9 ">
					
				<select  name="inventoryMethod" value={data.inventoryMethod} className="form-control" onChange={handleChange}>
				<option  value="0">Don't track inventory</option>
				<option value="1">Track inventory</option>
				<option value="2">Track inventory by product attributes</option>
                    </select>
					</div>
			</div>
			{data.inventoryMethod ==='1'? 
			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Stock Quantity" toolTip={Tooltip.stockQuantity} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="quantity"  value={data.quantity} onChange={handleChange} />

							</div>
					</div>:''}

					{panel.displayInventory && data.inventoryMethod !=='0'?<>
			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Wharehouse"  toolTip={Tooltip.wharehouse} />
			</div>
							
				<div className="col-sm-9 ">
					
				<select  name="wharehouse" className="form-control" value={inventory.wharehouse} onChange={handleChangeInventory}>
						<option value="default">Default Wharehouse</option>
                    </select>
					</div>
			</div>
			
			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Multiple warehouses" toolTip={Tooltip.multipleWharehouse}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox"  name="isMultipleWharehouse" checked={inventory.isMultipleWharehouse==='false'?false:true} value={inventory.isMultipleWharehouse} onChange={handleChangeInventory} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Display availability" toolTip={Tooltip.displayAvailability}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" name="isDisplayAvailability" checked={inventory.isDisplayAvailability==='false'?false:true} value={inventory.isDisplayAvailability} onChange={handleChangeInventory}  />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Display stock quantity" toolTip={Tooltip.displayStockQuantity}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" name="isDisplayStockQuantity" checked={inventory.isDisplayStockQuantity==='false'?false:true} value={inventory.isDisplayStockQuantity} onChange={handleChangeInventory} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>




			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Minimum stock qty" toolTip={Tooltip.minimumQuantity} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="minQuantity"  value={inventory.minQuantity} onChange={handleChangeInventory} />

							</div>
					</div>
					
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Low stock activity"  toolTip={Tooltip.lowStoreActivity} />
			</div>
							
				<div className="col-sm-9 ">
					
				<select  name="lowStockActivity" value={inventory.lowStockActivity} onChange={handleChangeInventory} className="form-control" >
				<option  value="0">Nothing</option>
				<option value="1">Disable buy button</option>
				<option value="2">Unpublish</option>
                    </select>
					</div>
			</div>


			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Notify for qty below" toolTip={Tooltip.notifyAdmin} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="notifyAdminNum"  value={inventory.notifyAdminNum} onChange={handleChangeInventory} />

							</div>
					</div>

					
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Allow back in stock subscriptions" toolTip={Tooltip.backInStock}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox"  name="isBackInStock" checked={inventory.isBackInStock==='false'?false:true} value={inventory.isBackInStock} onChange={handleChangeInventory}  />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Product availability range"  toolTip={Tooltip.availableRange} />
			</div>
							
				<div className="col-sm-9 ">
					
				<select  name="availableRange" className="form-control" value={inventory.availableRange} onChange={handleChangeInventory}>
				<option  value="0">None</option>
				<option value="1">2-4 days</option>
				<option value="2">7-10 days</option>
				<option value="3">2 week</option>
                    </select>
					</div>
			</div>

			</>:''}


			
			{panel.displayInventory?<>
			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Minimum cart qty	" toolTip={Tooltip.minCart} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="minCart"  value={inventory.minCart} onChange={handleChangeInventory} />

							</div>
			</div>

			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Maximum cart qty	" toolTip={Tooltip.maxCart} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="maxCart"  value={inventory.maxCart} onChange={handleChangeInventory} />

							</div>
					</div>
			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Allowed quantities" toolTip={Tooltip.allowedQty} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" min="0" name="allowedQty"  value={inventory.allowedQty} onChange={handleChangeInventory} />

							</div>
			</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Not returnable" toolTip={Tooltip.isReturnable}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox"  name="isReturnable" checked={inventory.isReturnable==='false'?false:true} value={inventory.isReturnable} onChange={handleChangeInventory} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div></>:''}


	</section></div></div></div>)}
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>






							{panel.displayPicture?	<div className="row  " id="product-pictures">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, picture:!panel.picture})}>
			<h5><i className="fa fa-image"></i> {data.isPrinting==='true'?'Templates':'Pictures'}</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.picture?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.picture?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">

					<div className="col-sm-12" id="UploadPhotos" aria-expanded="true" >
  <div className="well well-sm">
 
           
            <div className="upload-area"  onDragEnter={(e)=>dragEnter(e)} onDrop={(e)=>dropFile(e)} onDragOver={(e)=>dragOver(e)} style={uploadArea} id="uploadfile">
			<i className="fa fa-cloud-download fa-3x"></i>
			<h3 id="dragText">Drag &amp; Drop files here</h3> 
						<span >or</span><br/>
<button type="text"  className="btn btn-primary waves-effect waves-light" onClick={openFile}>Browse File</button>


<input type="file" style={{display:'none'}} onChange={handleFile('photo')} accept="image/*" name="photo" id="photo" />
            </div>
       

  </div>

</div>

</div>


					
	</section></div>
	
	
	
	{panel.displayPicture?
	<div className="row" >
	<div className="col-sm-12">
					<div className="card z-depth-0">
							<section className="card-block">
							<div className="table-responsive">
		{<TablePictureRecord data={JSON.parse(data.imageList).sort(compare)} columns={tableImageHeader}  />}
		</div>
							</section>
					</div>
			</div>
		</div>:''}
	
	
	
	</div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}



							{panel.displayProductAttribute?	<div className="row  " id="product-attributes">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, productAttribute:!panel.productAttribute})}>
			<h5><i className="fa fa-paperclip"></i> Products Attributes</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.productAttribute?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.productAttribute?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
						<div className="card bg-success p-2 ">

		Product attributes are quantifiable or descriptive aspects of a product (such as, color). For example, if you were to create an attribute for color, with the values of blue, green, yellow, and so on, you may want to apply this attribute to shirts, which you sell in various colors (you can adjust a price or weight for any of existing attribute values). You can add attribute for your product using existing list of attributes, or if you need to create a new one go to Catalog {'>'} Attributes {'>'} Product attributes. Please note that any attribute with associated fee will be multiply by total quantity and added to either original price or bulk purchase price as base on customer choice. (for example a product with original price of {Currency+FormatNumber(500)} but color attribute of green is 100 and quantity ordered is 5. The calculation will be (100*5) + 2,500 then the total price of such item will be {Currency+FormatNumber(6000)}  ).
					</div>
					</div>

					<div className="row">
					<div className="table-responsive">
		{<TableProductAttributeRecord data={JSON.parse(data.attributes).sort(compare)} columns={tableProductAttributeHeader}  />}
		</div>
						</div>


					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}




							{panel.displaySpecAttribute?	<div className="row  " >
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, specAttribute:!panel.specAttribute})}>
			<h5><i className="fa fa-cog"></i> Specification Attributes</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.specAttribute?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.specAttribute?		<div className="card-block" id="specification-attributes">
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="row">
						<div className="card bg-success p-2">
					Specification attributes are product features i.e, screen size, number of USB-ports, visible on product details page. Specification attributes can be used for filtering products on the category details page, brand product page and vendor product page. Unlike product attributes, specification attributes are used for information purposes only. You can add attribute for your product using existing list of attributes, or if you need to create a new one go to Catalog {'>'} Attributes {'>'} Specification attributes.
					</div></div>


					<div className="row">
					<div className="table-responsive">
		{<TableSpecificationRecord data={JSON.parse(data.specifications).sort(compare)} columns={tableSpecificationHeader}  />}
		</div>
						</div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}


	{panel.seoDisplay?			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, seo:!panel.seo})}>
			<h5><i className="fa fa-search-plus"></i> SEO</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.seo?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.seo?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Name" toolTip={Tooltip.seoName} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="text" className="form-control" name="pageName" 
						value={data.pageName} onChange={handleChange} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Title" toolTip={Tooltip.metaTitle} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaTitle" 
						value={data.metaTitle} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Keyword" toolTip={Tooltip.metaKeywords} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaKeywords" 
						value={data.metaKeywords} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Description" toolTip={Tooltip.metaDescription} />
					</div>
									
						<div className="col-sm-9 ">
						
						<textarea type="text" className="form-control" rows="5" name="metaDescription" 
						value={data.metaDescription} onChange={handleChange}>{data.metaDescription}</textarea>
					</div></div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}



						{panel.displayRelated?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, related:!panel.related})}>
			<h5><i className="fa fa-object-group"></i> Related Products</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.related?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.related?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="row">
						<div className="card bg-success p-2">
					The Related Products option provides the opportunity to advertise products that are not part of the selected category, to your visitors. These products are displayed on the product details pages.
					</div></div>

					<div className="row" id="relatedProducts">
					<div className="table-responsive">
		{<TableRelatedRecord data={data.related.length!==0?JSON.parse(data.related).sort(compare):[]} columns={tableRelatedProductsHeader}  />}
		</div>
						</div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}






							{panel.displayCrossSell?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, crossSell:!panel.crossSell})}>
			<h5><i className="fa fa-random"></i> Cross Sell</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.crossSell?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.crossSell?		<div className="card-block" id="displayCrossSell" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="row">
						<div className="card bg-info p-2">
					The Cross-sell products option provides the opportunity to buy additional products that generally go with the selected product. They are displayed at the bottom of the checkout page.
					</div></div><br/>


					<div className="row" id="crossSell">
					<div className="table-responsive">
		{<TableCrossSellRecord data={data.crossSell.length!==0?JSON.parse(data.crossSell).sort(compare):[]} columns={tableCrossSellHeader}  />}
		</div>
						</div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}








							{panel.displayOrders?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, orders:!panel.orders})}>
			<h5><i className="fa fa-shopping-cart"></i> Purchased with orders</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.orders?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.orders?		<div className="card-block" id="displayorders" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="row">
						<div className="card bg-info p-2">
						Here you can see a list of orders in which this product was purchased.
					</div></div><br/>


					<div className="row" id="crossSell">
					<div className="table-responsive">
		{<TableOrderRecord data={orders} columns={tableOrderHeader}  />}
		</div>
						</div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}


							{panel.displayHistory?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, history:!panel.history})}>
			<h5><i className="fa fa-history"></i> Stock quantity history</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.history?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.history?		<div className="card-block" id="displayhistory" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="row">
						Here you can see a history of the product stock quantity changes.
					</div><br/>


					<div className="row" id="crossSell">
					<div className="table-responsive">
		

		</div>
						</div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}

				</div>)}
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(EditProduct) 