import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { PictureUrl } from './include'
const ComingSoon = ()=>{
  const navigate = useNavigate();

    return   <section style={{background: `url(${PictureUrl}/images/bg.jpg) no-repeat`}} 
    className="login-block">
    <div className="container">
      <div className="row">
          <div className="col-sm-12">
          <div className="row">
       <div className="col-md-12">
           <div className=" error-pages">
               <h1 className="error-title text-warning"> Coming Soon</h1>
               <h2 className="error-sub-title text-white">Oops! This page is under development</h2>
    
               <p className="error-message text-white text-uppercase"> We could not let you access this page.
               ONCE WE'RE DONE WE WILL LET YOU KNOW</p>
               
               <div className="mt-4">
                 <a href="/" className="btn btn-danger btn-round shadow-danger m-1">Go To Home </a>
                 <a href="#!" onClick={()=>navigate.goBack()} className="btn btn-outline-danger btn-round m-1">Previous Page </a>
               </div>
    
           </div>
       </div>
    </div>
          </div>
      </div>
    </div>
    </section>
}
export default React.memo(ComingSoon)