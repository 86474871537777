import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import axios from 'axios'
import {Alerts} from '../components/notify'
import {ServerUrl, Token, config} from '../components/include'


 const ActivityLog =()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    const SearchTable =(event)=>{
        event.preventDefault();
        
     }
     const [logList, setLogList] = useState([])
     const handleChange =(event)=>{
        let {name, value} = event.target;
        setSearch({...search, [name] : value });
    }
    const [search, setSearch]= useState({
        startDate:'',
        endDate:'',
    })

    const compare=(a,b)=>{
        let result = 0;
        if(b.order >a.order){
            result = 1
        }else if (b.order <a.order){
            result = -1
        }
        return result
        }
    const fetchLog =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
		const fd = new FormData();
        fd.append("url", "errorlog.json");
        fd.append("actionType", "getData");
        fd.append("jwt", Token);
		let url = ServerUrl+'/fetch_jsonData.php';
		axios.post(url, fd, config).then(result=>{  
            setLogList(JSON.parse(result.data))
            setNotice({...notice, 
                isDataFetching: false
            });
		})
		.catch((error)=>{
            Alerts('Error!', 'danger', error.message)
		})
    }

    const Clearlog =()=>{
        setNotice({...notice, 
            isLoading: true
        });
        
		const fd = new FormData();
        fd.append("url", "errorlog.json");
        fd.append("actionType", "deleteData");
        fd.append("jwt", Token);
		let url = ServerUrl+'/fetch_jsonData.php';
		axios.post(url, fd, config).then(result=>{  
            fetchLog();
            setNotice({...notice, 
                isLoading: false
            });
		})
		.catch((error)=>{
            Alerts('Error!', 'danger', error.message)
		})
    }
    


    const tableInHeader = [  
        {dataField: 'url', footer:'Link', text: 'link', footerStyle: {
            backgroundColor: '#c8e6c9'},  formatter:(cell)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>, headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},        
        
          {dataField: 'dateCreate', footer:'Created On', text: 'Created On', footerStyle: {
            backgroundColor: '#c8e6c9'},headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},
 
                
            {dataField: 'message', footer:'Message', text: 'Message', formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>, footerStyle: {
                    backgroundColor: '#c8e6c9'},headerStyle: {backgroundColor: '#f0ff4d'}, editable:false}
   
     ];
    


     useEffect(()=>{
       // fetchLog()
    },[]);	
    
    
     const TableRecord=(props)=>{
         const { SearchBar } = Search;  
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '70', value: 70}, {text: '150', value: 150}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                             <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />

                                 
        <div className="dt-buttons btn-group pull-right"> 
            <a href="#!" onClick={()=>Clearlog()} className="btn btn-outline-danger" > <i className="fa fa-trash"></i>Clear Log</a>         
                </div>
                
                     <BootstrapTable 
                        { ...props.baseProps }
                        striped
                    hover
                    condensed
                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                pagination={paginationFactory(options) }
              
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
   
        return (<Template 
			menu="People"
		submenu="Activity Log">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Activity Log" description='Website Activities and Error Logs' icon='feather icon-user'>
 
					</PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         

							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Activity Log</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
           
   <TableRecord data={logList.sort(compare)}  columns={tableInHeader}  /> 
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(ActivityLog) 