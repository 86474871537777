import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../../components/notify'
import {ServerUrl} from '../../components/include'
import axios from 'axios'
import {PictureUrl, staffCode, Token, config} from '../../components/include'

import Select from 'react-select'
 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	let initialState = {
		code:'blogcat-'+Math.random().toString(36).substr(2,9),
		categoryName:'',
		showOnHome:true,
		parentCategory:{value:'xrt', parentCategory:'root', label:'[Root]'},
		pageSize:9,
		pageSizeOption:'9,15,27,33',
		exclusiveTo:'',
		pageName:'',
		metaTitle:'',
		metaKeywords:'',
		metaDescription:'',
		allowPageSize:true,
		blogList:[],
		published:true,
		displayOrder:1,
		staffCreate:staffCode,
	}


	
	const [content, setContent] = useState(initialState)
	const [defaultUrl, setDefaultUrl] = useState('');
	const [category, setCategory] = useState([])

	const fetchCategory =()=>{
        var sql ="Select ID, parentCategory, categoryName, code from tbl_blog_category order by categoryName ASC" ;
            
        const fd = new FormData();
        fd.append("sql", sql);
		fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        axios.post(url, fd, config).then(result=>{ 
			let initial= [{ID:'xrt', code:'xrt', parentCategory:'root', categoryName:'[Root]'}]
			
			setCategory(initial.concat(result.data))
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
		})

  }

	const handleChangeNumber =(event)=>{
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleChangeCheck =(event)=>{
		let {name, value} = event.target;	
		value = value ==='true'?false:true
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='pageName'){
			value = value.replace(/,?[ ]/g, '-')
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleSelect = (option, action)=>{
		setContent({...content, [action.name]: option});
		setErrors({...errors, [action.name]:''})
		 }

	
	const ValidateExistence=()=>{
        if(content.categoryName!==''){
               
            const fd = new FormData();
            fd.append("data", content.categoryName)
			fd.append("jwt", Token);
            let url = ServerUrl+'/existence_controller.php?tablename=tbl_blog_category&column=categoryName';
            axios.post(url, fd, config).then(response=>{
                if(response.data===true){
                    myRef.current.focus()
                    setErrors({...errors, categoryName:content.categoryName + ' already exist'})
                    setContent({...content, categoryName:''});
                    return response.data;
                }
          })
        }
	} 
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		    
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }

		fd.append('parent', content.parentCategory.value==='xrt'?'root':content.parentCategory.value)
		fd.append("jwt", Token);
			if(content.pageName===''){
				fd.append("pageNames", content.categoryName.replace(/,?[ ]/g, '-'))
			}else{
				fd.append("pageNames", content.pageName)
			}
			
			 let url = ServerUrl+'/save_controller.php?tablename=tbl_blog_category';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.categoryName){
			errors.categoryName =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}


	  const DisplaySelect=()=>{	  
		let details = get_options();
		return ( <Select  options={
			details&&details.map((node, id)=>{
				return {value:node.code, key:id, label:node.categoryName}
			})} 
			
onChange={handleSelect}  name="parentCategory" className={errors.categories ? 'form-control formerror' : ''} value={content.parentCategory} styles={selectStyles} /> 
)

}


function get_options(parent="root", indent="") {
		var result= []
		category&&category.map(cat=>{
			if(cat.parentCategory===parent){
				result.push({code:cat.code, categoryName:indent+cat.categoryName})
				result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
			}
		})
		return result;
	  }

	  const fetchOrder =()=>{
		var sql ="Select count(*) as num from tbl_blog_category" ;
		  
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append('jwt', Token)
		let url = ServerUrl+'/fetchBySql_controller.php';
		axios.post(url, fd, config).then(result=>{ 
			if(Array.isArray(result.data))
			setContent({...content, displayOrder:Number(result.data[0].num)+1})
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}
		useEffect(()=>{
			fetchCategory()
			fetchOrder()
		   },[]);
        return (<Template 
			menu="Content Management"
		submenu="Blogs">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Add Blog Category" description='Create New Blog Category' icon='feather icon-layers'>
  <li className="breadcrumb-item"><a href="/cms/blogs/categories">Categories</a> </li>
					</PageHeader>


{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success btn-sm" onClick={(e)=>handleSubmit(e, '/cms/blog/categories')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/cms/blog/category/create')}
  className="btn btn-inverse btn-sm" ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/cms/blog/category/edit/'+content.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/cms/blog/categories" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								
			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Category info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.categoryName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} onBlur={ValidateExistence} ref={myRef} name="categoryName" value={content.categoryName} className={errors.categoryName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.categoryName}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Parent" toolTip={Tooltip.parentCategory} />
					</div>
							
						<div className="col-sm-9 ">

							   {category&&<DisplaySelect />}
							</div>
					</div>
	
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								
			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-television"></i>  Display info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Published"  toolTip={Tooltip.published} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={content.published} name="published" checked={content.published} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Show on home page"  toolTip={Tooltip.showOnHome} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={content.showOnHome} name="showOnHome" checked={content.showOnHome} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Allow page size" toolTip={Tooltip.customerPageSize}  />
					</div>
									
						<div className="col-sm-9 ">
						<div className="checkbox-fade fade-in-success">
						<label>
					<input type="checkbox" value={content.allowPageSize} name="allowPageSize" checked={content.allowPageSize} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>
					
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Size Option" toolTip={Tooltip.pageSizeOption} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" name="pageSizeOption" 
						value={content.pageSizeOption}  onChange={handleChange}  />

							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={content.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								
			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-search-plus"></i>  SEO</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Name" toolTip={Tooltip.seoName} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="text" className="form-control" name="pageName" 
						value={content.pageName} onChange={handleChange} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Title" toolTip={Tooltip.metaTitle} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaTitle" 
						value={content.metaTitle} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Keyword" toolTip={Tooltip.metaKeywords} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaKeywords" 
						value={content.metaKeywords} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Description" toolTip={Tooltip.metaDescription} />
					</div>
									
						<div className="col-sm-9 ">
						
						<textarea type="text" className="form-control" rows="5" name="metaDescription" 
						value={content.metaDescription} onChange={handleChange}>{content.metaDescription}</textarea>
					</div></div>
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>



							<div className="row  ">
			<div className="col-sm-12">
								
			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th-list"></i> Blog Post</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					
You need to save the category before you can add blog post for this category page.
					</div>



					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 