import React from 'react'

const PageHeader = (props)=>{
    return   <div className="page-header p-0">
            <div className=" front-icon-breadcrumb row align-items-end">
                <div className="breadcrumb-header col">
                    <div className="big-icon">
                        <i className={props.icon}></i>
                    </div>
                    <div className="d-inline-block">
                        <h5>{props.title}</h5>
                        <span>{props.description}</span>
                    </div>
                </div>
                <div className="col">
                <div className="page-header-breadcrumb">
                    <ul className="breadcrumb-title">
                        <li className="breadcrumb-item"><a href="/dashboard"><i className="feather icon-home"></i></a>
                        </li>
                        {props.children}
                        <li className="breadcrumb-item"><a href="#!">{props.title}</a>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
             </div>
}

export default React.memo(PageHeader)