import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts, WarningModal} from '../components/notify'
import {CustomToggleList} from '../components/dataTable'
import {ServerUrl, FormatNumber, Currency, Token, config} from '../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

import Select from 'react-select'
 const TopCustomer =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
    const [search, setSearch]= useState({
        fromDate:'',
        toDate:'',
        customerId:'',
        paymentStatus:'all'
    })
      
    
    const [content, setContent] = useState([]);

	const [customer, setCustomer] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const SearchTable =(event)=>{
       event.preventDefault();
       fetchContent();
    }

  const fetchCustomer =()=>{
	var sql ="Select id, first_name, last_name, code, telephone from tbl_customers" ;
	const fd = new FormData();
	fd.append("sql", sql);
    fd.append('jwt', Token);
	let url = ServerUrl+'/fetchBySql_controller.php';
	axios.post(url, fd, config).then(result=>{ 
        
		setCustomer(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}


    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select SUM(o.order_total) as total, o.customer_code, count(o.id) as number, c.first_name, c.last_name from tbl_orders o, tbl_customers c  where o.customer_code = c.code and o.order_status ='Complete'  "

        if(search.fromDate.length!==0 && search.toDate.length!==0 ){
            sql = sql + ' and o.created_at between "'+search.fromDate+'" and "'+search.toDate+'"'
        }
        
         if(search.customerId.length!==0){
            sql = sql + ' and o.customer_code = "'+search.customerId.value+'"'
        }
        if(search.paymentStatus!=='all'){
            sql = sql + ' and o.payment_status= "'+search.paymentStatus+'"'
        }
        
        
        sql = sql + ' group by o.customer_code order by SUM(o.order_total) DESC'

         
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  

            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}

 
    

    const tableHeader = [
        
{dataField: 'fullname',  text: 'Customer',  sort: true, editable: false, formatter:(cell)=><span style={{display:'block', width:300, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
{dataField: 'total',  text: 'Total ('+Currency+')',  sort: true, editable: false, formatter:(cell)=>FormatNumber(cell)},
{dataField: 'number',  text: 'Number of Order',  sort: true, editable: false, formatter:(cell)=><span className="text-center">{cell}</span>},
{dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/customer/edit/${row.customerId}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> View</a>, editable: false}   
     ];
    
     const TableRecord=(props)=>{
         const { SearchBar } = Search;  
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='customerId' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                            
                     <BootstrapTable 
                        { ...props.baseProps }
                        striped
                    hover
                    condensed
                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                pagination={paginationFactory(options) }
              
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
      const handleSelect = (option, action)=>{
		setSearch({...search, [action.name]:option})
	  } 

     useEffect(()=>{
       // fetchContent()
        fetchCustomer()
       },[]);

        return (<Template 
            menu="Reports"
          submenu="Top Customer">
            
           {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
         
         <div  className="main-body">
              <div className="page-wrapper">
        
        <PageHeader title="Top Customers" description='List of the most loyal customer' icon='fa fa-bar-chart'>
        
                </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">   

            <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="From Date" toolTip={Tooltip.fromDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="fromDate" value={search.fromDate} />
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="To Date" toolTip={Tooltip.toDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="toDate" value={search.toDate} />
                        </div>
                    </div>
                  
             
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">

                <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Customer" toolTip={'Customer'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                       <Select  options={
				customer&&customer.map((data, id)=>{
					return {value:data.code, key:id, label:data.fullname}
				})}  
                
onChange={handleSelect}  name="customerId"  value={search.customerId} styles={selectStyles} /> 
                        </div>
                    </div>
                  


                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Payment Status" toolTip={Tooltip.paymentStatus} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="paymentStatus" value={search.paymentStatus} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Authorized">Authorized</option>

                        <option value="Processing">Processing</option>
                        <option value="Paid">Paid</option>
                        <option value="Partially refunded">Partially refunded</option>
                        <option value="Refunded">Refunded</option>
                        <option value="Voided">Voided</option>
                    </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Top list</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content} fetchdata={fetchContent} columns={tableHeader}  />}
            </div>
           
    
    </div></div></div>
			

							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(TopCustomer) 