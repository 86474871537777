import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts, WarningModal} from '../../components/notify'
import {API_URL, ServerUrl} from '../../components/include'
import axios from 'axios'
import { IMAGES_URL, config, Token} from '../../components/include'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import cellEditFactory from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Type} from 'react-bootstrap-table2-editor';
import { compare, sort } from '../../components/globalFunction'
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [category, setCategory] = useState([])
	const [products, setProducts] = useState([])
	const [content, setContent] = useState([])
	const [productList, setProductList] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	const [specOption, setSpecOption] = useState([])
	const [specification, setSpecification] = useState([])
	 

    const handleDeleteWithImage =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
	
		setNotice({...notice,  isLoading: true}) 

	let url = API_URL+'/category/delete/'+params.code;
	
	axios.get(url, config).then(response=>{ 
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/category', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice,  isLoading: false  })
		  }) 	
}




const fetchContent =()=>{
	setNotice({...notice, isLoading: true  });
   
	let url = API_URL+'/category/display/one/'+params.code;
	
	axios.get(url, config).then(result=>{ 
		if(Array.isArray(result.data.data)){

		
		setContent(result.data.data)
		setProductList(JSON.parse(result.data.data[0].productList))
		setFilter({...filter, order:Number(JSON.parse(result.data.data[0].filter).length)+1})
	}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, 
			isLoading: false
		});
	}) 

}

const [filter, setFilter] = useState({
	code:'f'+Math.random().toString(36).substring(2,9),
	filterType:'Category',
	mainCategory:'',
	order:1,
	isPublished:'true',
	options:[]

})

const handleSelectFilter = (option, action)=>{
	setFilter({...filter, [action.name] : option});

}



const getItemName =(code, field)=>{
	const result = products&&products.filter(item=>item.code===code)
	const answer = result.length!==0?result[0][field]:[]
	return String(answer)
}

	const getProductName =()=>{
		let result = []
		productList&&productList.map((item, id)=>result.push({code:item, key:id, isPublished:getItemName(item, 'isPublished'), productName:getItemName(item, 'productName')}))
		return result
	}


    
	const [defaultUrl, setDefaultUrl] = useState('');
	const [image, setImage] = useState('');

	const handleChangeNumber =(event)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		if(name==='pageName'){
			value = value.replace(/,?[ ]/g, '-')
		}
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}

	const handleChangeCheck =(event)=>{
		const newContent =JSON.parse(JSON.stringify(content))  
			let {name, value} = event.target;
			value = value ==='true'?'false':'true'	
			newContent[0][name]= value
			setContent(newContent);
			setErrors({...errors, [name]:''});
		}

	const handleChangeDescription =(data)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		newContent[0].description = data
		setContent(newContent);
	}

	const setLogo =()=>{
		const img = document.getElementById('viewLogo');
		img.src = IMAGES_URL+"/noImage.png" 
		document.getElementById('logo').value = '';
		setImage('')
	}
	const removeImageUrl =()=>{
		const newContent =JSON.parse(JSON.stringify(content))
		 newContent[0].imageUrl = '' 
		setContent(newContent);

		const img = document.getElementById('viewLogo');
		img.src = IMAGES_URL+"/noImage.png" 
		document.getElementById('logo').value = '';
		setImage('')
	}
	const [panel, setPanel] = useState({
		info:true,
		display:true,
		seo:false,
		product:false,
		mapping:false,
		filter:false,
		basic:true,
		seoDisplay:false,
		productDisplay:false,
		mappingDisplay:false,
		infoDisplay:false,
		displayFilter:false,
		displayDisplay:false
	})

	const goAdvance=()=>{
		setPanel({...panel,
		seoDisplay:true,
		productDisplay:true,
		mappingDisplay:true,
		infoDisplay:true,
		displayDisplay:true,
		displayFilter:true,
		basic:false
	})
	}

	const goBasic=()=>{
		setPanel({...panel,
		seoDisplay:false,
		productDisplay:false,
		mappingDisplay:false,
		infoDisplay:false,
		displayFilter:false,
		displayDisplay:false,
		basic:true
	})
	}

	
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		     
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		 
			if(image!==''){
				fd.append("image", image);
			}
			
			let url = API_URL+'/category/update/'+params.code;
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				setTimeout(() => {
					
					window.open(defaultUrl, '_self')
					}, 3000);
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.categoryName){
			errors.categoryName =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}


		const  handleFile = function(fieldName){
			return function(newValue){                 
				const value = newValue.target.files[0]
			  // setImage(value)
		   readURL(newValue);
		   }
		   
		  } 
		
		   const readURL = (input)=>{       
			let displayMessage = '';
			  var fileName =  document.getElementById('logo').value;
			var idxDot = fileName.lastIndexOf(".") + 1;
			var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
			if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
				//TO DO    
				
				 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
			if (FileSize > 1) {
				displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1 mb';
			   document.getElementById(input.target.name).value = '';
			   setImage('')
			} else {
				if (input.target.files && input.target.files[0]) { //Check if input has files.
				  var reader = new FileReader();//Initialize FileReader.
				  reader.onload = function (e) {
			   			const newContent =JSON.parse(JSON.stringify(content))
							 newContent[0].imageUrl = '' 
							 setContent(newContent);
							 
				  const img = document.getElementById('viewLogo');
							 img.src = e.target.result             
							
				  };
				  reader.readAsDataURL(input.target.files[0]); 
				  setImage(input.target.files[0])
				  
				}
			}
			}else{
				displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500kb'
			   document.getElementById(input.target.name).value = '';
			   setImage('');
			}   
			
			if(displayMessage.length > 0){
			
				Alerts('Error!', 'danger', displayMessage)
			  }
		} 

		const DisplaySelect=({value})=>{	  
			let options = get_options();
		   return (
			   <select name="parentCategory" value={value} className="form-control"   onChange={handleChange}>
				{options.map((node, id)=>{
					return <option key={id} value={node.code} >{node.categoryName}</option>
				})}
				</select>
		   )
				
	}
	
		  function get_options(parent="root", indent="") {
			var result= []
			category.map(cat=>{
				if(cat.parentCategory===parent){
					result.push({code:cat.code, categoryName:indent+cat.categoryName})
					result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
				}
				return ''
			})
			return result;
		  }


		  const fetchProducts =()=>{
		
			let url = API_URL+'/product/display/all';
			axios.get(url,  config).then(result=>{ 
				if(Array.isArray(result.data.data)){
					setProducts(result.data.data)
				}else{
					setProducts([])
				}
			})
			.catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			})
		
		}

		const handleRemoveCategory =(e, code)=>{
			e.preventDefault()

			let close =   document.getElementById('btnWarningDialog-'+code)
				close.click();
				
			

			const newContent = [...content] 
			const catList = JSON.parse(newContent[0].productList);
			const otherCategory = catList.filter(list=>list!==code)


			const newProducts = [...products] 
			const selectProduct = newProducts.filter(list=>list.code===code)
			const categories = JSON.parse(selectProduct[0].categories);
			const productCategory = categories.filter(list=>list!==params.code)
			
			UpdateProductsCategory(productCategory, otherCategory, code)
		
	}

		  const tableCategoryProductHeader = [

			{dataField: 'productName', text: 'Product', sort:true, editable: false, formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
			{dataField: 'isPublished', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.isPublished==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>},

			{dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>
				<a href={`/products/edit/${row.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>&nbsp;
				
		<WarningModal  handleAction={(e)=>handleRemoveCategory(e, row.code)} mID={row.code} />
			
		<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button></div>, editable: false}
		   
		 ];
	
	const TableCategoryProduct=(props)=>{
	
			 const { SearchBar } = Search;
			 
			 const customTotal = (from, to, size) => (
				<span >&nbsp;Showing { from } to { to } of { size } items</span>
			  );
			 const options = {
				showTotal: true,
			  paginationTotalRenderer: customTotal,
			  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
			  { text: 'All', value: props.data.length }]
			   
			  };
			 return  <ToolkitProvider search columnToggle 
						 keyField='code' data={ props.data } columns={ props.columns } >
							 
							 {
								 props => (
								 <div >
								   <div className="form-group">
									 <SearchBar
									 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
									 
									 <div className="dt-buttons btn-group pull-right"> 
			 <button data-toggle="modal"   data-target='#addNewProduct' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add a new product</button>
	 </div>  
				                 
		 </div>
									 
						 <BootstrapTable
									 { ...props.baseProps }
												 striped
											 hover
											 condensed
											 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
										 pagination={paginationFactory(options) }
										 
									 />
								 </div>
								 )
							 }
		  </ToolkitProvider>
		  }
	
		  const handleAddFilter =(ID)=>{
			let close =   document.getElementById('closeFilter')
			close.click();
	
			const newContent = [...content]
			//get old product list
			const newFilter = JSON.parse(newContent[0].filter);
			
				const currentList = newFilter.concat(filter)
				//remove the duplicate item
				const allFilter =	currentList.map(e=>e['filterType'])
				.map((e,i,final)=>final.indexOf(e)===i&&i)
				.filter(e=>currentList[e])
				.map(e=>currentList[e])
				const filterList = JSON.stringify(allFilter, null, 2)

				UpdateFilterOption(filterList, ID)
	
		}
	
		const UpdateFilterOption =(filterList, code)=>{
			  
			setNotice({...notice, 
				isLoading: true}) 

				const fd = new FormData(); 
				fd.append('newValue', filterList);
				fd.append('column', 'filter');
				fd.append('code', code);
			   let url = API_URL+'/category/change_field'
			  axios.post(url, fd, config)
			  .then(response =>{
			   if(response.data.status ===200){
				 //Alerts('Saved!', 'success', response.data.message)
				  
					  } else{
				  Alerts('Error!', 'danger', JSON.stringify(response.data))
					  }   
			  })
			  .catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			  }).finally(()=>{
				  setNotice({...notice, 
					  isLoading: false
				  })
				  fetchContent()
	
				setFilter({
					code:'f'+Math.random().toString(36).substr(2,9),
				filterType:'Category',
				mainCategory:'',
				order:1,
				isPublished:'true',
				options:[]})
			  }) 
			
	}

	
		const handleRemoveFilter =(e, code)=>{
			e.preventDefault()
			let close =   document.getElementById('btnWarningDialog-'+code)
				close.click();
	
			const newContent = JSON.parse(JSON.stringify(content)) 
			const newFilter = JSON.parse(newContent[0].filter);
			const id = JSON.parse(newContent[0].code)
			const otherFilter = newFilter.filter(list=>list.code!==code)
			const filterList = JSON.stringify(otherFilter)
			UpdateFilterOption(filterList, id)
		}
	
	 const getSpecLabel=(code)=>{	  
			const result = specification&&specification.filter(list=>list.code===code)
			return (result.map((node)=>node.specification_name))
		}
	
	
		const tableFilterHeader = [
			{dataField: 'filterType', text: 'Filter type', editable: false, formatter:(cell, row)=>cell ==='Category'?cell:cell ==='Brand'?cell:cell ==='Price'?cell:cell ==='Rating'?cell:cell ==='Tags'?'Tags':getSpecLabel(cell)},
			 {dataField: 'isPublished', text: 'Published', sort:true, editor: {
				type: Type.SELECT, options:[{value:true, label:'Show on category page'},
				{value:false, label:'Do not show on category page'}]
			  }, formatter:(cell, row)=>row.isPublished==='true'? <div style={{cursor:'pointer'}} className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div style={{cursor:'pointer'}}className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>},
	
			{dataField: 'order', text: 'Order', sort:true, validator: (newValue, row, column) => {
				if (!Number(newValue)) {
				  return {
					valid: false,
					message: 'This field required valid number'
				  }
				}
				return true;
			}, formatter:(cell)=><div className="text-center">{cell}</div>},
			 {dataField: 'code', text: 'Action', formatter:(cell, row)=><div>
	
				<WarningModal  handleAction={(e)=>handleRemoveFilter(e, row.code)} mID={row.code} />
			
			<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button></div>, editable: false
			 }
			  ]
		 
			  
			const TableFilterRecord=(props)=>{
	
				const handleUpdate=(column, newValue, code)=>{     
					
					 const newContent = JSON.parse(JSON.stringify(content)) 
					const newFilter = JSON.parse(newContent[0].filter);
					const id = JSON.parse(newContent[0].code)
					const otherFilter = newFilter.filter(list=>list.code!==code)
					const filterData = newFilter.filter((list)=>list.code===code)		
					filterData[0][column.dataField] = newValue
					const currentFilter = otherFilter.concat(filterData)
					const filterList = JSON.stringify(currentFilter)
					
					UpdateFilterOption(filterList, id)
						 } 
	
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='code' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										 
			<div className="dt-buttons btn-group pull-right"> 
				 <button data-toggle="modal"   data-target='#addFilter' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</button>
		 </div>                   
			 </div>
										 
							 <BootstrapTable
										 { ...props.baseProps }
													 striped
												 hover
												 condensed
												 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
											 pagination={paginationFactory(options) }
											 cellEdit={ cellEditFactory({
											   mode: 'dbclick',
											   blurToSave: true,
											   autoSelectText: true,
									 beforeSaveCell: (oldValue, newValue, row, column) => {
									   if(oldValue !==newValue){
									   handleUpdate(column, newValue, row.code);
									   
									  return true;
									 }
									 
								   }
											 }) }
										 />
									 </div>
									 )
								 }
			  </ToolkitProvider>
			  }


		const fetchSpecification =()=>{
	
			let url = API_URL+'/specification/display/all';
			axios.get(url, config).then(result=>{ 
				if(Array.isArray(result.data.data)){

			setSpecification(result.data.data)
				}else{
					setSpecification([])	
				}
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}
			
	
		const fetchCategory =()=>{
			 let url = API_URL+'/category/display/all';
        axios.get(url, config).then(result=>{ 
			let initial= [{ID:'xrt', code:'xrt', parentCategory:'root', categoryName:'[Root]'}]
			if(Array.isArray(result.data.data)){
			setCategory(initial.concat(result.data.data))
			}else{
				setCategory(initial)	
			}

			})
			.catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			})
	
	  }
	
	
	  const handleChangeSelectFilter =(event)=>{
		let {name, value} = event.target;

		if( value !=='Brand' && value !=='Category' && value !=='Price' && value !=='Rating' && value !=='Tags'){
			getAttributeOption(value)
		}
		setFilter({...filter, [name] : value, options:[] });
	}
	
	  const handleChangeFilter =(event)=>{
		let {name, value} = event.target;
		
		if(name==='isPublished'){
			value= value==='true'?false:true
		}
		if(name ==='order' && Number(value)<0){
			value =0
		}
	
		setFilter({...filter, [name] : value });
	}
	
	  const getAttributeOption = (code) =>{ 
		try{
		
		const spec = specification.filter((list)=>list.code===code);
		
		const answer = JSON.parse(spec[0].option);
		setSpecOption(answer);
	}catch(e){
			
	}
	   }
	
	
	
	function get_options(parent="root", indent="") {
		var result= []
		category&&category.map(cat=>{
			if(cat.parentCategory===parent){
				result.push({code:cat.code, categoryName:indent+cat.categoryName, parentCategory:parent})
				result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
			}
			return ''
		})
		return result;
	  }
	

	  const handleAddNewProduct =(code)=>{
		
		try{

			

		 let records = []
		let productItem =[]
		//get old product list
		const newProductList = [...productList];

		 records.push(code)
		 productItem.push(params.code)
		
		 const selectProduct = products.filter(item=>item.code===code)
		 const newCategory = JSON.parse(selectProduct[0].categories);
		const allCategory  = newCategory.concat(productItem)
		 

			//merger old prouct list with selected product
			const currentList = newProductList.concat(records)
			//remove the duplicate item
			const uniqueProduct = [...new Set(currentList)]; 
			  const uniqueCategory = [...new Set(allCategory)];

			
			if(records.length===0){
			Alerts('Error!', 'danger', 'Please select at least one product to add')
			}else{


			UpdateProductsCategory(uniqueCategory, uniqueProduct, code)
			}
			let close =   document.getElementById('closeNewProduct')
			close.click();  

		}catch(e){
			
		}
	}

	const UpdateProductsCategory =(categoryList, productList, productCode)=>{
          
		setNotice({...notice, isLoading: true}) 
	
		const fd = new FormData();
		fd.append('productCode', productCode)
		fd.append('categoryList', JSON.stringify(categoryList, null, 2))
		fd.append('productList', JSON.stringify(productList, null, 2))
		fd.append('categoryCode', params.code)
		fd.append('totalItem', productList.length)
		fd.append('jwt', Token)




	let url = ServerUrl+'/updateMultiple_controller.php?tablename=tbl_category';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  
				  } else{
			  Alerts('Error!', 'danger', JSON.stringify(response.data))
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
			  fetchContent()
		  }) 
		
}


	  const  tableProductHeader = [
		{dataField: 'ID',  text: '#!', editable: false,  formatter:(cell, row, index)=>
		<button type="button" onClick={()=>handleAddNewProduct(row.code)} className="btn btn-inverse">Select</button>},
		{dataField: 'productName', text: 'Name', sort:true, editable: false},
		
		  {dataField: 'isPublished', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.isPublished==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>}
		  ];
	 


const TableProductRecord=(props)=>{

			 const { SearchBar } = Search;
			 
			 const customTotal = (from, to, size) => (
				<span >&nbsp;Showing { from } to { to } of { size } items</span>
			  );
			 const options = {
				showTotal: true,
			  paginationTotalRenderer: customTotal,
			  sizePerPageList: [
				  {text: '20', value: 20}, 
			  {text: '50', value: 50}, 
			  {text: '70', value: 70}, 
			  {text: '100', value: 100}, 
			  {text: '150', value: 150},
			  { text: 'All', value: props.data.length }]
			   
			  };
			 return  <ToolkitProvider search columnToggle 
						 keyField='code' data={ props.data } columns={ props.columns } >
							 
							 {
								 props => (
								 <div >
								   <div className="form-group">
									 <SearchBar
									 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
													 
		 </div>
									 
<BootstrapTable
			{ ...props.baseProps }
						striped
					hover
					condensed
					noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
				pagination={paginationFactory(options) }
				
			/>
		</div>
		)
	}
</ToolkitProvider>
		  }




useEffect(()=>{
	fetchContent()
	fetchCategory()
	fetchProducts()
	fetchSpecification()
},[]);	
	
        return (<Template 
            menu="Catalog"
        submenu="Category">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">

<PageHeader title="Edit Category" description='Update category information here' icon='feather icon-book'>
	
<li className="breadcrumb-item"><a href="/category">Category</a> </li>

                    </PageHeader>



<div className="modal fade " id='addNewProduct' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add new  product</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
  <div className="table-responsive">
        
        <TableProductRecord data={products}  columns={tableProductHeader}  />
            </div>
    </div>
              <div className="modal-footer">
                  <button type="button"  id='closeNewProduct' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
              </div>
          </div>
      </div>
  </div>


{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								


<div className="modal fade " id='addFilter' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add new filter option</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Filter Type" toolTip={'Choose Filter Type'} />
					</div>
									
						<div className="col-sm-8 ">
					<select  name="filterType" value={filter.filterType} className="form-control" onChange={handleChangeSelectFilter}>
                        <option value="Category">Category</option>
						<option value="Price">Price Range</option>
						<option value="Rating">Rating</option>
						<option value="Tags">Product Tags</option>
						 {specification&&specification.map((st, id)=><option key={id} value={st.code}>{st.specification_name}</option>)} 
                    </select>
							</div>
				</div>




			{filter.filterType==='Category'?	<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Filter Option" toolTip={'Select option you want to appear on filter sidebar'} />
					</div>
									
						<div className="col-sm-8 ">
							

							<select  name="mainCategory" value={filter.mainCategory} className="form-control" onChange={handleChangeFilter}>
						 {category&&category.filter(item=>item.parentCategory==='root').map((st, id)=><option key={id} value={st.code}>{st.categoryName}</option>)} 
                    </select></div>

					</div>:''}



					<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Published"  toolTip={Tooltip.published} />
					</div>
									
						<div className="col-sm-8 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={filter.isPublished} name="isPublished" checked={filter.isPublished==='true'?true:false} onChange={handleChangeFilter} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="order" value={filter.order} onChange={handleChangeFilter} />

							</div>
			</div>
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeFilter' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={()=>handleAddFilter(data.code)} className="btn btn-inverse">Save</button>
              </div>
          </div>
      </div>
  </div>



			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-inverse btn-sm" onClick={(e)=>handleSubmit(e, '/category')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/category/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	<a href="/category" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={handleDeleteWithImage} mID={'delAll'} /> 

	{panel.basic?
	<button type="button" onClick={goAdvance} className="btn  btn-sm pull-right"> Basic Mode <i className="fa fa-check"></i></button> :
	<button type="button" onClick={goBasic} className="btn btn-sm btn-primary pull-right">  Advanced Mode <i className="fa fa-check"></i></button>}

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, info:!panel.info})}>
			<h5><i className="fa fa-info"></i> Category Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.info?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.info?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.categoryName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  ref={myRef} name="categoryName" value={data.categoryName} className={errors.categoryName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.categoryName}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Description" toolTip={Tooltip.categoryDescription} />
					</div>
									
						<div className="col-sm-9 ">
						<QuillText value={data.description} onChange={handleChangeDescription} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Parent" toolTip={Tooltip.parentCategory} />
					</div>
							
						<div className="col-sm-9 ">

							   {category&&<DisplaySelect value={data.parentCategory} />}
							</div>
					</div>


				{panel.infoDisplay?		<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Picture" toolTip={Tooltip.picture} />
					</div>
									
						<div className="col-sm-9 ">
						
						<div className="form-group">
							<img className="img-70" id="viewLogo" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+"/noImage.png"}} src={IMAGES_URL+"/category/"+data.imageUrl} alt="Pictures "  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
			{data.imageUrl?	<button type="button" onClick={removeImageUrl} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>	:''}

			{image?	<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>	:''}

										</div>
							</div>
					</div>:''}	
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>
				<div  className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, display:!panel.display})}>
			<h5><i className="fa fa-television"></i> Display</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.display?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.display?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
				{panel.displayDisplay?<>	
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Published"  toolTip={Tooltip.published} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={data.published} name="published" checked={data.published==='true'?true:false} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Show on home page"  toolTip={Tooltip.showOnHome} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={data.showOnHome} name="showOnHome" checked={data.showOnHome==='true'?true:false} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Include in top menu"  toolTip={Tooltip.includeTopMenu} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={data.includeTopMenu} name="includeTopMenu" checked={data.includeTopMenu==='true'?true:false} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Allow page size" toolTip={Tooltip.customerPageSize}  />
					</div>
									
						<div className="col-sm-9 ">
						<div className="checkbox-fade fade-in-success">
						<label>
					<input type="checkbox" value={data.allowPageSize} name="allowPageSize" checked={data.allowPageSize==='true'?true:false} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>
					
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Size Option" toolTip={Tooltip.pageSizeOption} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" name="pageSizeOption" 
						value={data.pageSizeOption}  onChange={handleChange}  />

							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Price Ranges" toolTip={Tooltip.priceRange} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" name="priceRange" 
						value={data.priceRange} onChange={handleChange}  />

							</div>
					</div></>:''}

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={data.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>



				{panel.seoDisplay?			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, seo:!panel.seo})}>
			<h5><i className="fa fa-search-plus"></i> SEO</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.seo?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.seo?		<div className="card-block" >
		
 <div  className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Name" toolTip={Tooltip.seoName} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="text" className="form-control" name="pageName" 
						value={data.pageName} onChange={handleChange} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Title" toolTip={Tooltip.metaTitle} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaTitle" 
						value={data.metaTitle} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Keyword" toolTip={Tooltip.metaKeywords} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaKeywords" 
						value={data.metaKeywords} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Description" toolTip={Tooltip.metaDescription} />
					</div>
									
						<div className="col-sm-9 ">
						
						<textarea type="text" className="form-control" rows="5" name="metaDescription" 
						value={data.metaDescription} onChange={handleChange}>{data.metaDescription}</textarea>
					</div></div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}



							{panel.displayFilter?	<div className="row  " >
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, filter:!panel.filter})}>
			<h5><i className="fa fa-cog"></i> Filter List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.filter?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.filter?		<div className="card-block" id="specification-attributes">
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                       
					<div className="row">
					<div className="table-responsive">
		 {<TableFilterRecord data={JSON.parse(data.filter).sort(compare)} columns={tableFilterHeader}  />} 
		</div>
						</div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}


						{panel.productDisplay?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, product:!panel.product})}>
			<h5><i className="fa fa-th-list"></i> Products</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.product?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.product?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="row">
					<div className="table-responsive">
		{<TableCategoryProduct data={getProductName()} columns={tableCategoryProductHeader}  />}
		</div>
					</div>

	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}






				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 