import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { Popover, LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, DirectUrl} from '../../components/include'
import axios from 'axios'
import {PictureUrl, staffCode, Token, config} from '../../components/include'
import { useParams } from 'react-router-dom'

import Select from 'react-select'
 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const [content, setContent] = useState([])
	const [vendor, setVendor] = useState([])
	
    const handleDelete =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
		 

		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'code')
		fd.append('data', params.code)
		fd.append('jwt', Token)
	let url = ServerUrl+'/delete_controller.php?tablename=tbl_checkout_attribute';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/checkout-attributes', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
		
		
}

const handleSelectMultiple = (option, action)=>{
	const newContent =JSON.parse(JSON.stringify(content))
	var value = [];
		  for (var i = 0, l = (option || []).length; i < l; i++) {
			value.push({code:option[i].value});
		  }
		  newContent[0][action.name] = JSON.stringify(value)
		  setContent(newContent);
  }

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select * from tbl_checkout_attribute where code = '" +params.code+"'";
           
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append('jwt', Token)
        axios.post(url, fd, config).then(result=>{ 
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
	const [defaultUrl, setDefaultUrl] = useState('');
	
	const handleChangeNumber =(event)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}

	const handleChangeCheck =(event)=>{
		const newContent =JSON.parse(JSON.stringify(content))  
			let {name, value} = event.target;
			value = value ==='true'?false:true	
			newContent[0][name]= value
			setContent(newContent);
			setErrors({...errors, [name]:''});
		}

	
		const [panel, setPanel] = useState({
			info:true,
			values:true,
			condition:false,
			
			basic:true,
			infoDisplay:false,
			valuesDisplay:true,
			conditionDisplay:false
		})
	
		const goAdvance=()=>{
			setPanel({...panel,
				infoDisplay:true,
				valuesDisplay:true,
				conditionDisplay:true,
				condition:true,
				basic:false
		})
		}
	
		const goBasic=()=>{
			setPanel({...panel,
				infoDisplay:true,
				valuesDisplay:true,
				conditionDisplay:false,
				basic:true,
				condition:false
		})
		}
	
		const getItemLabel = (ID, mainData, recordName) =>{ 
			const result = mainData.filter((list)=>list.code===ID);
		const answer = result.map((c)=>c[recordName])
			 return  String(answer);
		   }
		
		
		const getTagData=(field, mainData, recordName)=>{
			
			try{

			
			var value = [];
				var item;
		  JSON.parse(field).map((itm, idx)=>{
			item ={value:itm.code, label:getItemLabel(itm.code, mainData, recordName), key:idx}
				value.push(item);
			});
			
		  return value;
		}catch(e){
				
		}
		  }
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		     
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
			
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_checkout_attribute';
			 fd.append('jwt', Token)
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.checkoutName){
			errors.checkoutName =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}
		const fetchVendor =()=>{
			var sql ="Select ID, vendorName,  code from tbl_vendors order by vendorName ASC" ;
			const fd = new FormData();
			fd.append("sql", sql);
			fd.append('jwt', Token)
			let url = ServerUrl+'/fetchBySql_controller.php';
			axios.post(url, fd, config).then(result=>{  
				setVendor(result.data)
			})
			.catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			})
		}

useEffect(()=>{
	fetchContent()
	fetchVendor()
},[]);	
	
        return (<Template 
            menu="Catalog"
        submenu="Attributes">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">

<PageHeader title="Edit checkout attribute details" description='Update  checkout attribute information here' icon='feather icon-book'>
	
<li className="breadcrumb-item"><a href="/checkout-attributes">Checkout Attributes</a> </li>
                    </PageHeader>
		

{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success btn-sm" onClick={(e)=>handleSubmit(e, '/checkout-attributes')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/checkout-attributes/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	<a href="/checkout-attributes" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDelete()} mID={'delAll'} /> 

	{panel.basic?
	<button type="button" onClick={goAdvance} className="btn btn-sm  pull-right"> Basic Mode <i className="fa fa-check"></i></button> :
	<button type="button" onClick={goBasic} className="btn btn-sm btn-primary pull-right">  Advanced Mode <i className="fa fa-check"></i></button>}

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, info:!panel.info})}>
			<h5><i className="fa fa-info"></i> Attribute info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.info?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.info?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
				
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.checkoutName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  ref={myRef} name="checkoutName" value={data.checkoutName} className={errors.checkoutName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.checkoutName}</span>
							</div>
					</div>


					<div className="form-group row">
                    <div className="col-sm-3">
                            <FormLabel name ="Control type	" toolTip={Tooltip.controlType} />
                    </div>
                                    
					<div className="col-sm-9 ">
                    <select name="controlType" value={data.controlType} className="form-control" onChange={handleChange}>
					<option value="select">Drop-down list</option>
					<option value="radio">Radio button list</option>
					<option value="checkbox">Checkboxes</option>
					<option value="text">Textbox</option>
					<option value="textarea">Multiline textbox</option>
					<option value="date">Date picker</option>
					<option value="file">File upload</option>
					<option value="color">Color squares</option>
					<option value="readOnly">Read-only checkboxes</option>
                    </select>
                </div>
                </div>
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Required"  toolTip={Tooltip.isRequired} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={data.isRequired} name="isRequired" checked={data.isRequired} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={data.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>



					{panel.infoDisplay?<>	
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Text prompt" toolTip={Tooltip.textPrompt} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  name="textPrompt" value={data.textPrompt} className={'form-control '}/>
							
							</div>
							</div>
					</div>
			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Shippable required"  toolTip={Tooltip.shippedAble} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={data.shippedAble} name="shippedAble" checked={data.shippedAble} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Tax exempt"  toolTip={Tooltip.isTaxExempt} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={data.isTaxExempt} name="isTaxExempt" checked={data.isTaxExempt} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

                   {/*  <div className="form-group row">
                    <div className="col-sm-3">
                            <FormLabel name ="Tax Category	" toolTip={Tooltip.taxCategory} />
                    </div>
                                    
					<div className="col-sm-9 ">
                    <select name="taxCategory" value={data.taxCategory} className="form-control" onChange={handleChange}>
					<option value="1">Books</option>
					<option value="2">Electronics &amp; Software</option>
					<option value="3">Downloadable Products</option>
					<option value="4">Jewelry</option>
					<option value="5">Apparel</option>
                    </select>
                </div>
                </div> */}

				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Limited To Store" toolTip={Tooltip.limitedStore} />
					</div>
									
						<div className="col-sm-9 ">
						<Select isMulti options={
				vendor&&vendor.map((node, id)=>{
					return {value:node.code, key:id, label:node.vendorName}
				})} 
                
onChange={handleSelectMultiple}  name="limitedStore"  value={getTagData(data.limitedStore, vendor, 'vendorName')} styles={selectStyles} /> 
					</div></div>

					</>:''}
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>
				
							{panel.valuesDisplay?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, values:!panel.values})}>
			<h5><i className="fa fa-television"></i> Attribute values</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.values?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.values?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					You need to save the checkout attribute before you can add values for this checkout attribute page.
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}


							{panel.conditionDisplay?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, condition:!panel.condition})}>
			<h5><i className="fa fa-bookmark"></i>conditions</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.condition?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.condition?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					You need to save the checkout attribute before you can edit conditional attributes.
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}
				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 