import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import { LoadEffect } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts} from '../components/notify'
import {ServerUrl, staffCode, Token, config,  API_URL, IMAGES_URL} from '../components/include'
import axios from 'axios'
import Select from 'react-select'

 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [defaultUrl, setDefaultUrl] = useState('');
	const [group, setGroup] = useState([])
	
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	const [content, setContent] = useState({
		code:Math.random().toString(36).substr(2,9),
		email:'',
		password:'',
		fullname:'',
		gender:'',
		telephone:'',
		isLogo:false, 
		group:'',
		status:true,
		staffCreate: staffCode
	})
	
	const [image, setImage] = useState('');

	const setLogo =()=>{
		const img = document.getElementById('viewLogo');
		img.src = content.logoUrl 
		document.getElementById('logo').value = '';
		setImage('')
		setContent({...content, isLogo:false})
	}
	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='status'){
			value = value==='false'?true:false
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const fetchGroup =()=>{
		var sql ="Select ID, groupName from tbl_account_group order by groupName ASC" ;
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append('jwt', Token)
		let url = ServerUrl+'/fetchBySql_controller.php';
		axios.post(url, fd, config).then(result=>{  
			setGroup(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}
	const handleSelect = (option, action)=>{
		setContent({...content, [action.name]: option});
		setErrors({...errors, [action.name]:''})
		 }
	
	const ValidateExistence=()=>{
        if(content.email!==''){
              
            const fd = new FormData();
            fd.append("data", content.email)
			fd.append('jwt', Token)
            let url = ServerUrl+'/existence_controller.php?tablename=tbl_users&column=email';
            axios.post(url, fd, config).then(response=>{
                if(response.data===true){
                    myRef.current.focus()
                    setErrors({...errors, email:content.email + ' already exist'})
                    setContent({...content, email:''});
                    return response.data;
                }
          })
        }
	} 
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		   

		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
			fd.append('group_code', content.group.value)
			if(image!==''){
				fd.append("imageFile", image);
			}
			 let url = API_URL+'/user/create';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.status ===200){
				
				Alerts('Saved!', 'success', response.data.message)

				setTimeout(() => {
					
				window.open(defaultUrl, '_self')
				}, 2000);
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();
	  ValidateExistence();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
        
        if(!values.fullname){
            errors.fullname =Tooltip.required;
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }
		

        if(values.password.length <6){
            errors.password ='Please enter minimum of 6 characters';
        } 
       
       
        return errors;
		}
		
		const  handleFile = function(fieldName){
			return function(newValue){                 
				//const value = newValue.target.files[0]
			  // setImage(value)
		   readURL(newValue);
		   }
		   
		  } 
		
		   const readURL = (input)=>{       
			let displayMessage = '';
			  var fileName =  document.getElementById('logo').value;
			var idxDot = fileName.lastIndexOf(".") + 1;
			var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
			if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
				//TO DO    
				
				 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
			if (FileSize > 1) {
				displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1 mb';
			   document.getElementById(input.target.name).value = '';
			   setImage('')
			} else {
				if (input.target.files && input.target.files[0]) { //Check if input has files.
				  var reader = new FileReader();//Initialize FileReader.
				  reader.onload = function (e) {
			   
				  const img = document.getElementById('viewLogo');
							 img.src = e.target.result             
					  
				  };
				  reader.readAsDataURL(input.target.files[0]); 
				  setImage(input.target.files[0])  
				  setContent({...content, isLogo:true})
				}
			}
			}else{
				displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 1Mb'
			   document.getElementById(input.target.name).value = '';
			   setImage('');
			}   
			
			if(displayMessage.length > 0){
			
				Alerts('Error!', 'danger', displayMessage)
			  }
		} 
		

	useEffect(()=>{
		fetchGroup()
		},[]);


        return (<Template 
			menu="People"
		submenu="Users">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Add new User" description='Create a new user' icon='feather icon-user'>
  <li className="breadcrumb-item"><a href="/users">Customers</a> </li>
					</PageHeader>
		


{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success btn-sm" onClick={(e)=>handleSubmit(e, '/users')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/user/create')}
  className="btn btn-inverse btn-sm" ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/user/edit/'+content.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/users" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i> User info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Email Address" toolTip={'User email'} />
					</div>
									
						<div className="col-sm-9 ">

							<div className="input-group">
						<input type="text" onChange={handleChange}  onMouseOut={ValidateExistence} ref={myRef} name="email" value={content.email} className={errors.email  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.email}</span>
							</div>
					</div>




					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Password" toolTip={Tooltip.password} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="password" autoComplete="off" onChange={handleChange}   name="password" value={content.password} className={errors.password  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.password}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Fullname" toolTip={Tooltip.fullname} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="fullname" value={content.fullname} className={errors.fullname  ? 'form-control formerror' : 'form-control '}/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.fullname}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Telephone" toolTip={'User Telephone'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="telephone" value={content.telephone} className='form-control'/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Gender" toolTip={Tooltip.gender} />
					</div>
									
						<div className="col-sm-9 ">
						
			<div className="form-radio">
						<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Male' checked={content.gender==='Male'?true:false} />
						<i className="helper"></i>Male
					</label>
				</div>
				<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Female' checked={content.gender==='Female'?true:false} />
						<i className="helper"></i>Female
					</label>
				</div>
				</div>

							</div>
					</div>


					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Status" toolTip={'Determine if the user is active'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.status} name="status" checked={content.status} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="User group" toolTip={'Group user belong to'} />
					</div>
									
						<div className="col-sm-9 ">
						<Select  options={
				group&&group.map((node, id)=>{
					return {value:node.ID, key:id, label:node.groupName}
				})} 
                
onChange={handleSelect}  name="group"  value={content.group} styles={selectStyles} /> 
</div>
	</div>
	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Profile Image" toolTip={Tooltip.picture} />
					</div>
									
						<div className="col-sm-9 ">
						

						<div className="form-group">
						<img className="img-100" id="viewLogo"  src={IMAGES_URL+"/noImage.png"} alt="Pictures"  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
				{content.isLogo?<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>:''}	
										</div>
							</div>
					</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 