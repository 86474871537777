import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl} from '../../components/include'
import axios from 'axios'
import {PictureUrl, config, Token} from '../../components/include'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
 
import { longDate, compare, convertDateTime } from '../../components/globalFunction'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [category, setCategory] = useState([])
	const [content, setContent] = useState([])
	const [blogpost, setBlogpost] = useState([])
	const [tags, setTags] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	

	const [defaultUrl, setDefaultUrl] = useState('');
	const [image, setImage] = useState('');

    const handleDeleteWithImage =(imageLink)=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
		
		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'code')
		fd.append('data', params.code)
		fd.append('imagelink', imageLink)
		fd.append("jwt", Token);
	let url = ServerUrl+'/deleteSingleWithImage_controller.php?tablename=tbl_blog_post';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/blogs', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
		
		
}



const fetchTags =()=>{
	var sql ="Select ID, tagName from tbl_product_tag order by tagName ASC" ;
	const fd = new FormData();
	fd.append("sql", sql);
	fd.append("jwt", Token);
	let url = ServerUrl+'/fetchBySql_controller.php';
	axios.post(url, fd, config).then(result=>{  
		setTags(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}


	const handleChangeNumber =(event)=>{
		const newContent =[...content]
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
	
	const handleChange =(event)=>{
	const newContent =[...content]  
		let {name, value} = event.target;	
		if(name==='pageName'){
			value = value.replace(/,?[ ]/g, '-')
		}
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}

	const handleChangeCheck =(event)=>{
		const newContent =[...content]  
			let {name, value} = event.target;
			value = value ==='true'?'false':'true'	
			newContent[0][name]= value
			setContent(newContent);
			setErrors({...errors, [name]:''});
		}

	const handleChangeDescription =(data)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		newContent[0].fullDescription = data
		setContent(newContent);
	}

	const setLogo =()=>{
		const img = document.getElementById('viewLogo');
		img.src = PictureUrl+"/noImage.png" 
		document.getElementById('logo').value = '';
		setImage('')
	}
	const removeImageUrl =()=>{
		const newContent =JSON.parse(JSON.stringify(content))
		 newContent[0].imageUrl = '' 
		setContent(newContent);

		const img = document.getElementById('viewLogo');
		img.src = PictureUrl+"/noImage.png" 
		document.getElementById('logo').value = '';
		setImage('')
	}
	const [panel, setPanel] = useState({
		info:true,
		display:true,
		seo:false,
		related:false,
		comment:false,
		basic:true,
		seoDisplay:false,
		displayrelated:false,
		displayComment:false,
		infoDisplay:false,
		displayDisplay:false
	})

	const goAdvance=()=>{
		setPanel({...panel,
		seoDisplay:true,
		infoDisplay:true,
		displayDisplay:true,
		displayFilter:true,
		displayRelated:true,
		displayComment:true,
		basic:false
	})
	}

	const goBasic=()=>{
		setPanel({...panel,
		seoDisplay:false,
		infoDisplay:false,
		displayFilter:false,
		displayDisplay:false,
		displayRelated:false,
		displayComment:false,
		basic:true
	})
	}

	

	const getCategoryList =(categoryList)=>{
		if(categoryList.length!==0){

			categoryList.map(data =>{
		const result = category.filter(item=>item.code===data.code)
		const allBloglist = JSON.parse(result[0].blogList);
		const newBlogList = allBloglist.concat([{code:params.code}])
		const currentBloglist =	newBlogList.map(e=>e['code'])
					.map((e,i,final)=>final.indexOf(e)===i&&i)
					.filter(e=>newBlogList[e])
					.map(e=>newBlogList[e])

			const fd = new FormData();
			  fd.append('catCode', data.code)
			  fd.append("jwt", Token);
			  fd.append('totalItem', currentBloglist.length)
			  fd.append('blogList', JSON.stringify(currentBloglist, null, 2))
		let url = ServerUrl+'/update_controller.php?tablename=tbl_update_blog_cat';
		axios.post(url, fd, config)
		.then()
		.catch((error)=>{
		  Alerts('Error!', 'danger', error.message)
		})
	})
		}
	return ''
	}

	const fetchBlogpost =()=>{
		
		var sql ="Select * from tbl_blog_post ";
		
		const fd = new FormData();
		fd.append("sql", sql);
		let url = ServerUrl+'/fetchBySql_controller.php';
		fd.append("jwt", Token);
		axios.post(url, fd, config).then(result=>{ 
			setBlogpost(result.data)
			setContent(result.data.filter(item=>item.code===params.code))
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		   
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }

		  const categoryList = getCategoryList(JSON.parse(content[0].categories))
		 
			if(image!==''){
				fd.append("image", image);
			}
			fd.append("jwt", Token);
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_blog_post';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	const handleSelect = (option, action)=>{
		const newContent =[...content]
		var value = [];
			  for (var i = 0, l = (option || []).length; i < l; i++) {
				value.push({ID:option[i].value});
			  }
			  newContent[0][action.name] = JSON.stringify(value)
			  setContent(newContent);
	  }
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.title){
			errors.title =Tooltip.required;
			myRef.current.focus()
		}
		if(!values.author){
			errors.author =Tooltip.required;
			
		}
		return errors;
		}


		const  handleFile = function(fieldName){
			return function(newValue){                 
				const value = newValue.target.files[0]
			  // setImage(value)
		   readURL(newValue);
		   }
		   
		  } 
		
		   const readURL = (input)=>{       
			let displayMessage = '';
			  var fileName =  document.getElementById('logo').value;
			var idxDot = fileName.lastIndexOf(".") + 1;
			var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
			if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
				//TO DO    
				
				 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
			if (FileSize > 0.5) {
				displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1 mb';
			   document.getElementById(input.target.name).value = '';
			   setImage('')
			} else {
				if (input.target.files && input.target.files[0]) { //Check if input has files.
				  var reader = new FileReader();//Initialize FileReader.
				  reader.onload = function (e) {
			   			const newContent =JSON.parse(JSON.stringify(content))
							 newContent[0].imageUrl = '' 
							 setContent(newContent);
							 
				  const img = document.getElementById('viewLogo');
							 img.src = e.target.result             
							
				  };
				  reader.readAsDataURL(input.target.files[0]); 
				  setImage(input.target.files[0])
				  
				}
			}
			}else{
				displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 1mb'
			   document.getElementById(input.target.name).value = '';
			   setImage('');
			}   
			
			if(displayMessage.length > 0){
			
				Alerts('Error!', 'danger', displayMessage)
			  }
		} 

		
	
		const fetchCategory =()=>{
			var sql ="Select ID, parentCategory, blogList, categoryName, code from tbl_blog_category order by categoryName ASC" ;
			  
			const fd = new FormData();
			fd.append("sql", sql);
			fd.append("jwt", Token);
			let url = ServerUrl+'/fetchBySql_controller.php';
			axios.post(url, fd, config).then(result=>{
				let initial= [{ID:'xrt', code:'xrt', parentCategory:'root', categoryName:'[Root]'}]
			
			setCategory(initial.concat(result.data))
				
			})
			.catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			})
	
	  }
	
	function get_options(parent="root", indent="") {
		var result= []
		category&&category.map(cat=>{
			if(cat.parentCategory===parent){
				result.push({code:cat.code, categoryName:indent+cat.categoryName, parentCategory:parent})
				result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
			}
		})
		return result;
	  }
	

const getCatLabel=(code)=>{	  
	let details = get_options();
	const result = details&&details.filter((list)=>list.code===code)
	return (result.map((node)=>node.categoryName))
}


const getSelectCategory=(field)=>{
	var value = [];
		var item;
  JSON.parse(field).map((itm, idx)=>{
	item ={value:itm.code, label:getCatLabel(itm.code), key:idx}
		value.push(item);
	});
	
  return value;
  
}

const DisplaySelect=(props)=>{	  
	let details = get_options();
	return ( <Select isMulti options={
		details&&details.map((node, id)=>{
			return {value:node.code, key:id, label:node.categoryName}
		})} 
		
onChange={handleSelectMultiple}  name="categories"  value={getSelectCategory(props.field)} styles={selectStyles} /> 
)

}


const handleSelectMultiple = (option, action)=>{
	const newContent =[...content]
	var value = [];
		  for (var i = 0, l = (option || []).length; i < l; i++) {
			value.push({code:option[i].value});
		  }
		  newContent[0][action.name] = JSON.stringify(value)
		  setContent(newContent);
  }


function get_options(parent="root", indent="") {
	var result= []
	category&&category.map(cat=>{
		if(cat.parentCategory===parent){
			result.push({code:cat.code, categoryName:indent+cat.categoryName})
			result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
		}
	})
	return result;
  }


const getItemLabelID = (ID, mainData, recordName) =>{ 
	const result = mainData.filter((list)=>list.ID===ID);
const answer = result.map((c)=>c[recordName])
	 return  String(answer);
   }


const getTagData=(field, mainData, recordName)=>{
	var value = [];
		var item;
  JSON.parse(field).map((itm, idx)=>{
	item ={value:itm.ID, label:getItemLabelID(itm.ID, mainData, recordName), key:idx}
		value.push(item);
	});
	
  return value;
  }



  const getBlogTitle = (code) =>{ 
	const result = blogpost.filter(list=>list.code===code);
const answer = result.map((c)=>c.title)
	 return  String(answer);
   }

  const  tableRelatedHeader = [
	{dataField: 'code',  text: 'Title', editable: false,  formatter:(cell, row)=>getBlogTitle(row.code)},
				
	{dataField: 'order', text: 'Order', sort:true, validator: (newValue, row, column) => {
		if (!Number(newValue)) {
		  return {
			valid: false,
			message: 'This field required valid number'
		  };
		}
		return true;
	}, formatter:(cell)=><div className="text-center">{cell}</div>},
	 {dataField: 'ID', text: 'Action', formatter:(cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={(e)=>handleRemoveRelated(e, row.ID)} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>, editable: false}


			  ];
		 
			const handleRemoveRelated =(e, ID)=>{
		
			e.preventDefault()
			
				const newContent = [...content] 
				const newRelated = JSON.parse(newContent[0].relatedBlog);
				const otherRelated = newRelated.filter(list=>list.ID!==ID)
				newContent[0].relatedBlog = JSON.stringify(otherRelated)

		setContent(newContent);
			
		}

		const handleRemoveComment =(e, code)=>{
		
			e.preventDefault()
			
				const newContent = [...content] 
				const newRelated = JSON.parse(newContent[0].comment);
				const otherComment = newRelated.filter(list=>list.code!==code)
				newContent[0].comment = JSON.stringify(otherComment)

		setContent(newContent);
			
		}
	
	const TableRelatedRecord=(props)=>{
		const handleUpdate=(column, newValue, ID)=>{     
			
			const newContent = [...content] 
			const newRelated = JSON.parse(newContent[0].relatedBlog);
	
			const otherRelated = newRelated.filter(list=>list.ID!==ID)
			const relatedData = newRelated.filter(list=>list.ID===ID)	
			relatedData[0][column.dataField] = newValue
			const currentRelated = otherRelated.concat(relatedData)
			newContent[0].relatedBlog = JSON.stringify(currentRelated)
			setContent(newContent); 
			
		} 
				 const { SearchBar } = Search;
				 
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='ID' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										
								 
	<div className="dt-buttons btn-group"> 
		 <button data-toggle="modal"   data-target='#addRelated' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add  Related Blog</button>
 </div>                   
	 </div>
										 
	<BootstrapTable
				{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
					cellEdit={ cellEditFactory({
						mode: 'dbclick',
						blurToSave: true,
						autoSelectText: true,
			  beforeSaveCell: (oldValue, newValue, row, column) => {
				if(oldValue !==newValue){
				handleUpdate(column, newValue, row.ID);
				
			   return true;
			  }
			  
			}
					  }) }
				/>
			</div>
			)
		}
</ToolkitProvider>
			  }

			
			  const  tableCommentHeader = [
				
				{dataField: 'blogCode', text: 'Blog Title', editable: false, formatter:(cell, row)=><a style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} href={"/blog/edit/"+row.blogCode} >{cell}</a>},

				{dataField: 'customerCode', text: 'Customer', editable: false, formatter:(cell, row)=><a style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} href={"/customer/edit/"+row.customerCode} >{row.fullname}<br/>{row.email}</a>},

				{dataField: 'comment', text: 'Comment',  formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} >{cell}</span>},
				{dataField: 'isApproved', text: 'Is Approved', sort: true, editor: {
					type: Type.SELECT, options:[{value:'true', label:'Approved'},
					{value:'false', label:'Disapproved'}]
				  }, formatter:(cell)=>cell==='true'? <div className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i title="Double click to change" className="fa fa-times  badge-inverse-danger"></i></div>}, 
		
				  {dataField: 'dateTime', text: 'Created On', editable: false},
		
				  {dataField: 'ID', text: 'Action', formatter:(cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={(e)=>handleRemoveComment(e, row.code)} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>, editable: false}
							];
										 			
									
					const TableCommentRecord=(props)=>{
							
						const { SearchBar } = Search;	 
					const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
					);
					const options = {
					showTotal: true,
					paginationTotalRenderer: customTotal,
					sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
					{ text: 'All', value: props.data.length }]
					
					};
					return  <ToolkitProvider search columnToggle 
								keyField='ID' data={ props.data } columns={ props.columns } >
									
									{
						props => (
						<div >
								<SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />										
					<BootstrapTable
								{ ...props.baseProps }
											striped
										hover
										condensed
										noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
									pagination={paginationFactory(options) }
									
								/>
							</div>
							)
						}
							</ToolkitProvider>
											  }
							
			
			
			
const handleAddRelated =(event)=>{
	event.preventDefault()
	let close =   document.getElementById('closeRelated')
	close.click();

	const newContent = [...content]
	let records = []
		const newBlogpost = [...blogpost]
		const oldRelated = JSON.parse(newContent[0].relatedBlog);
        for (var i in newBlogpost){
            if(newBlogpost[i].status===true){
				records.push({ID:Math.random().toString(36).substr(2,9), code:newBlogpost[i].code, order:Number(oldRelated.length) +Number(i)+1})
				newBlogpost[i].status=false
            }
		}
	
	const relatedBlog = oldRelated.concat(records)

const allBlog =	relatedBlog.map(e=>e['code'])
	.map((e,i,final)=>final.indexOf(e)===i&&i)
	.filter(e=>relatedBlog[e])
	.map(e=>relatedBlog[e])
	newContent[0].relatedBlog = JSON.stringify(allBlog)
	setContent(newContent);
	
	setStatus({...status, checkAll:false})
}



const handleCheckOne =(e)=>{  

	let {name, value} = e.target

	var newBlogpost = [...blogpost]
	const otherContent = newBlogpost.filter(item=>item.code!==name)
	const item = newBlogpost.filter(item=>item.code===name)
	const status = value ===''? true:value==='true'?false:true 
	item[0].status = status
	const currentProduct = item.concat(otherContent)
  setStatus({...status, checkAll:false})
  setBlogpost(currentProduct) 
			   
			  }

	const handleCheckAll =()=>{
			var newBlogpost = [...blogpost]
			for (var i in newBlogpost){
				newBlogpost[i].status=!status.checkAll
			}
			setStatus({...status, checkAll:!status.checkAll})
			setBlogpost(newBlogpost)
		 }

 const generalCheck =()=><div className="checkbox-fade fade-in-success">
		  <label>
		<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
			  <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
		  </label>
		</div> 


		  const  tableBlogHeader = [
			{dataField: 'code',  text: generalCheck(), editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
			<label>
		<input type="checkbox" value={row.status} name={row.code}  checked={row.status} onChange={(e)=>handleCheckOne(e)} />
				<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
			</label>
		</div>},

			{dataField: 'title', text: 'Title', sort:true, editable: false},
			
			  {dataField: 'isActive', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.isActive==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>}
			  ];
		 
	
	
	const TableBlogRecord=(props)=>{
	
				 const { SearchBar } = Search;
				 
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='ID' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										 <SearchBar
										 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
														 
			 </div>
										 
	<BootstrapTable
				{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
					cellEdit={ cellEditFactory({
					mode: 'dbclick',
					blurToSave: true,
					autoSelectText: true
					}) }
				/>
			</div>
			)
		}
</ToolkitProvider>
			  }


useEffect(()=>{
	fetchCategory()
	fetchTags()
	fetchBlogpost()
},[]);	
	
        return (<Template 
			menu="Content Management"
		submenu="Blogs">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Edit Blog Post" description='Update Blog Post' icon='feather icon-layers'>
  <li className="breadcrumb-item"><a href="/cms/blogs">Blogs</a> </li>
					</PageHeader>




<div className="modal fade " id='addRelated' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add related blog</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">



			  <div className="table-responsive">
        
        <TableBlogRecord data={blogpost}  columns={tableBlogHeader}  />
            </div>


              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeRelated' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddRelated(event)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>




{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<a href="#!" className="btn btn-success btn-sm"><i className="fa fa-eye"></i>  Preview</a>&nbsp;
	<button type="button" className="btn btn-inverse btn-sm" onClick={(e)=>handleSubmit(e, '/cms/blogs')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/cms/blog/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	<a href="/cms/blogs" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;


	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDeleteWithImage(data.imageUrl)} mID={'delAll'} /> 

	{panel.basic?
	<button type="button" onClick={goAdvance} className="btn  btn-sm pull-right"> Basic Mode <i className="fa fa-check"></i></button> :
	<button type="button" onClick={goBasic} className="btn btn-sm btn-primary pull-right">  Advanced Mode <i className="fa fa-check"></i></button>}

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								
	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, info:!panel.info})}>
			<h5><i className="fa fa-info"></i> Category Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.info?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.info?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Title" toolTip={'Title of this blog post'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} name="title" value={data.title} className={errors.title  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.title}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Author" toolTip={'Author of this blog post'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  name="author" value={data.author} className={errors.author  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.author}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Short description" toolTip={'If specified then it will be used on the main blog page instead of full description'} />
					</div>
									
						<div className="col-sm-9 ">
							
						<textarea type="text" className="form-control" rows="5" name="shortDescription" value={data.shortDescription} onChange={handleChange}>{data.shortDescription}
						
						</textarea>

							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Full Description" toolTip={'Full description of this post'} />
					</div>
									
						<div className="col-sm-9 ">
						<QuillText value={data.fullDescription} onChange={handleChangeDescription} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Blog Tag" toolTip={'Tags are keywords that this blog post can also be identified by. Select from list of the tags to be associated with this blog post.'} />
					</div>
									
						<div className="col-sm-9 ">
						<Select isMulti options={
				tags&&tags.map((data, id)=>{
					return {value:data.ID, key:id, label:data.tagName}
				})}   
                
onChange={handleSelect}  name="tags"  value={getTagData(data.tags, tags, 'tagName')} styles={selectStyles} /> 
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Categories" toolTip={'Categories the blog belong to'} />
					</div>
									
						<div className="col-sm-9 ">
						<DisplaySelect field={data.categories} /> 
							</div>
					</div>


				{panel.infoDisplay?		<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Picture" toolTip={Tooltip.picture} />
					</div>
									
						<div className="col-sm-9 ">
						
						<div className="form-group">
							<img className="img-70" id="viewLogo" onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/noImage.png"}} src={PictureUrl+"/blog/"+data.imageUrl} alt="Picture "  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
			{data.imageUrl?	<button type="button" onClick={removeImageUrl} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>	:''}

			{image?	<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>	:''}

										</div>
							</div>
					</div>:''}	
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>
				<div  className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, display:!panel.display})}>
			<h5><i className="fa fa-television"></i> Display</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.display?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.display?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                         	
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Published"  toolTip={Tooltip.published} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={data.isActive} name="isActive" checked={data.isActive==='true'?true:false} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>


			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Allow Comment"  toolTip={'When checked, customers can leave comments about your blog entry.'} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={data.allowComment} name="allowComment" checked={data.allowComment==='true'?true:false} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={data.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>



				{panel.seoDisplay?			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, seo:!panel.seo})}>
			<h5><i className="fa fa-search-plus"></i> SEO</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.seo?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.seo?		<div className="card-block" >
		
 <div  className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Name" toolTip={Tooltip.seoName} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="text" className="form-control" name="pageName" 
						value={data.pageName} onChange={handleChange} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Title" toolTip={Tooltip.metaTitle} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaTitle" 
						value={data.metaTitle} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Keyword" toolTip={Tooltip.metaKeywords} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaKeywords" 
						value={data.metaKeywords} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Description" toolTip={Tooltip.metaDescription} />
					</div>
									
						<div className="col-sm-9 ">
						
						<textarea type="text" className="form-control" rows="5" name="metaDescription" 
						value={data.metaDescription} onChange={handleChange}>{data.metaDescription}</textarea>
					</div></div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}



							{panel.displayRelated?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, related:!panel.related})}>
			<h5><i className="fa fa-object-group"></i> Related Blog</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.related?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.related?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="row">
						<div className="card bg-info">
					The Related blog option provides the opportunity to advertise blog post that are not part of the selected category, to your visitors. These post are displayed on below the blog details pages.
					</div></div>

					<div className="row" id="relatedProducts">
					<div className="table-responsive">
		{<TableRelatedRecord data={JSON.parse(data.relatedBlog).sort(compare)} columns={tableRelatedHeader}  />}
		</div>
						</div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}




							{panel.displayComment?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, comment:!panel.comment})}>
			<h5><i className="fa fa-commenting-o"></i> Blog Comments</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.comment?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.comment?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                       

					<div className="row" id="comment">
					<div className="table-responsive">
		{<TableCommentRecord data={JSON.parse(data.comment).sort(compare)} columns={tableCommentHeader}  />}
		</div>
						</div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}


				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 