import React, {useState, useEffect} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {Currency, FormatNumber, ServerUrl, API_URL, IMAGES_URL, config, Token} from '../../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
 const Attribute =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);

    const handleDeleteSelected =()=>{
          
        

        let dataId = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status==='true'){
                dataId.push(newContent[i].code)
            }
        }
        let close =   document.getElementById('btnWarningDialog-delAll')
        if(dataId.length===0){
            Alerts('Error!', 'danger', Tooltip.deleteWarning)
              
        }else{
            setNotice({...notice, 
                isLoading: true}) 
        var data = JSON.stringify(dataId, null, 2)
        
            const fd = new FormData();
            fd.append('data', data)
        let url = API_URL+'/product/attributes/delete_multiple';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  
                      } else{
                  Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
                  fetchContent()
              }) 
            }
            close.click();
    }

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        let url = API_URL+'/product/attributes/display/all';
     
        axios.get(url,  config).then(result=>{ 
			if(Array.isArray(result.data.data)){
            setContent(result.data.data)
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  

  const handleCheckOne =(e)=>{  


    let {name, value} = e.target

    const currentContent = content.map((item)=>{
                   
        if(item.code ===name){
            return {...item, status:item.status==='false'?'true':'false'}
        }

         return item
          })

  setStatus({...status, checkAll:false})
   setContent(currentContent)

        
       }
    

  const handleCheckAll =()=>{
    var newContent = JSON.parse(JSON.stringify(content));
    for (var i in newContent){
        newContent[i].status=String(!status.checkAll)
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent)
 }

  const GeneralCheck =()=><div className="checkbox-fade fade-in-success">
  <label>
<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
      <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
  </label>
</div> 

    const tableHeader = [
        {dataField: 'code',  text: <GeneralCheck />, editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.code}  checked={row.status==='true'?true:false} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},
        {dataField: 'attribute_name', text: 'Name', formatter: (cell, row)=><div>{row.attribute_name} <br/><b>{row.description}</b></div>},
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/product-attributes/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a>, editable: false},
       
     ];
    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, code)=>{       
         const fd = new FormData(); 
         fd.append('newValue', newValue);
         fd.append('column', column.dataField);
         fd.append('code', code);
        let url = API_URL+'/product/attributes/change_field'
       axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '20', value: 20}, 
			  {text: '50', value: 50}, 
			  {text: '70', value: 70}, 
			  {text: '100', value: 100}, 
			  {text: '150', value: 150},
			  { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                 
                                 
                                 <div className="dt-buttons btn-group pull-right"> 
    <a href="/product-attributes/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</a>
                                
    <button className="btn btn-outline-danger" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button>
 </div>                   

      
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.code);
                               
                               return props.fetchContent;
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      





     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
            menu="Catalog"
            submenu="Attributes">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="All Products Attributes" description='Listing all products attributes' icon='feather icon-book'>
	
    
                        </PageHeader>
        


{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal  handleAction={handleDeleteSelected} mID={'delAll'} /> 

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
				<div className="card-block bg-success" >
       	
 <div className="row">
<div className="col-sm-12">
Product attributes are quantifiable or descriptive aspects of a product (such as, color). For example, if you were to create an attribute for color, with the values of blue, green, yellow, and so on, you may want to apply this attribute to shirts, which you sell in various colors (you can adjust a price or weight for any of existing attribute values). You can add attribute for your product using existing list of attributes, or if you need to create a new one go to Catalog {'>'} Attributes {'>'} Product attributes. Please note that any attribute with associated fee will be multiply by total quantity and added to either original price or bulk purchase price as base on customer choice. (For example, a product with original price of {Currency+FormatNumber(500)} but color attribute of green is 100 and quantity ordered is 5. The calculation will be (100*5) + 2,500 then the total price of such item will be {Currency+FormatNumber(6000)}  ).

</div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Attribute list</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Attribute) 