import React, {useState, useEffect} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts, WarningModal} from '../components/notify'
import {CustomToggleList} from '../components/dataTable'
import {ServerUrl, FrontEndUrl, Currency, FormatNumber, Token, config, PictureUrl} from '../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { longDate } from '../components/globalFunction'

 const Wallet =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    
    const [search, setSearch]= useState({
        fullname:'',
        published:'all',
        wallet:'',
        lastUsed:''
    })
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);

    const handleDeleteSelected =()=>{
          
        let dataId = []
        var newContent = {...content};
        for (var i in newContent){
            if(newContent[i].status===true){
                dataId.push(newContent[i].ID)
            }
        }
        
        if(dataId.length===0){
            Alerts('Error!', 'danger', Tooltip.deleteWarning)
              
        }else{
            setNotice({...notice, 
                isLoading: true}) 
        var data = JSON.stringify(dataId, null, 2)

            const fd = new FormData();
            fd.append('data', data)
            fd.append('jwt', Token);
        let url = ServerUrl+'/deleteMultiple_controller.php?tablename=tbl_wallet';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  
                      } else{
                  Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
                  fetchContent()
              }) 
            }
            let close =   document.getElementById('btnWarningDialog-delAll')
            close.click();
    }

    const fetchContent =()=>{
        setNotice({...notice, isDataFetching: true
        });
       
        var sql ="Select concat(c.first_name, ' ', c.last_name) as fullname, c.gender, c.code, c.status, w.wallet, w.isActive, w.balance, w.lienAmount, w.lastUsed, w.dateCreate from tbl_wallet w, tbl_customers c where c.code = w.userCode and  w.wallet <> ''  " ;

        if(search.fullname.length!==0){
            sql = sql + ' and c.fullname = "'+search.fullname+'"'
        }
        if(search.published!=='all'){
            sql = sql + '  and w.isActive = "'+search.published+'"'
        }
        if(search.wallet.length!==0){
            sql = sql + ' and w.wallet = "'+search.wallet+'"'
        }

        sql = sql + ' order by w.ID ASC'
           
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append('jwt', Token);
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}

  const handleCheckOne =(e)=>{  
    let {name, value} = e.target

    const newContent = [...content];
    const otherContent = newContent.filter(item=>item.code!==name)
    const brand = newContent.filter(item=>item.code===name)
    const status = value ===''? true:value==='true'?false:true 
    brand[0].status = status
    const currentContent = brand.concat(otherContent)
  setStatus({...status, checkAll:false})
   setContent(currentContent) 

       }
    

  const handleCheckAll =()=>{
    var newContent = [...content];
    for (var i in newContent){
        newContent[i].status=!status.checkAll
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent)
 }


    
  const GeneralCheck =()=><div className="checkbox-fade fade-in-primary">
  <label>
<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
      <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
  </label>
</div> 

    const tableHeader = [
        {dataField: 'code',  text: <GeneralCheck />, editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-primary">
        <label>
    <input type="checkbox" value={row.status} name={row.code}  checked={row.status} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-primary"></i></span>
        </label>
    </div>},
    {dataField: 'imageUrl', text: 'Picture', editable: false, formatter:(cell, row)=><img id={cell} className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/"+row.gender+".png"}} src={FrontEndUrl+"/users/"+row.imageUrl} title={row.fullname}  alt={row.coverUrl} />},
        {dataField: 'fullname', text: 'Name', sort: true, editable: false},
        
{dataField: 'lienAmount',  text: 'Lien Amount ('+Currency+')',  sort: true, editable: false, formatter:(cell, row)=>FormatNumber(row.lienAmount)},

{dataField: 'balance',  text: 'Balance ('+Currency+')',  sort: true, editable: false, formatter:(cell, row)=>FormatNumber(row.balance)},
        {dataField: 'isActive', text: 'Active', sort: true, editable: false, formatter:(cell)=>cell==='true'? <div className="text-center"><i className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i className="fa fa-times badge-inverse-danger"></i></div>}, 
       
        {dataField: 'lastUsed', text: 'Last Used', sort: true, editable: false, formatter:(cell)=>longDate(cell)},
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><a  href={`/customer/edit/${row.code}#wallet`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>, editable: false},
       
     ];
    
     const TableRecord=(props)=>{

    
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '20', value: 20}, 
			  {text: '50', value: 50}, 
			  {text: '70', value: 70}, 
			  {text: '100', value: 100}, 
			  {text: '150', value: 150},
			  { text: 'All', value: props.data.length }]
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                               
  
                                 
                                 
                                 <div className="dt-buttons btn-group pull-right"> 
                   <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'220px', width:'200px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
      <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>             
<div className="btn-group dropdown-split-primary">
    <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <i className="fa fa-download"></i>Export
    </button>
    <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <a className="dropdown-item waves-effect waves-light" href="#!">Export to Excel</a>
        <a className="dropdown-item waves-effect waves-light" href="#!">Export to PDF</a>
    </div>
</div>         
                 
                   <button className="btn btn-outline-danger" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button>
 </div>                   

      
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                   
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      





     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
            menu="Wallet"
          submenu="">
            
           {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
         
         <div  className="main-body">
              <div className="page-wrapper">
        
        <PageHeader title="Wallet Account" description='List of wallet accounts' icon='fa fa-money'>
        
                </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal  handleAction={handleDeleteSelected} mID={'delAll'} /> 

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	

 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Customer Name" toolTip={'search by customer name'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}   name="fullname" value={search.fullname} />
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Wallet" toolTip={'search by wallet'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}   name="wallet" value={search.wallet} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">


                <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Last Used" toolTip={'search by last transaction'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control" onChange={handleChange}  name="lastUsed" value={search.lastUsed} />
                        </div>
                    </div>


                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="IsActive" toolTip={'Search by a active property'} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select name="published" value={search.published} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="true">Active Only</option>
                        <option value="false">UnActive Only</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={fetchContent}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Wallet list</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content} fetchdata={fetchContent} columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Wallet) 