import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts} from '../../components/notify'
import {API_URL, ServerUrl, Token, config, staffCode} from '../../components/include'
import axios from 'axios'

 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [defaultUrl, setDefaultUrl] = useState('');
	let initialState = {
		code:'a-'+Math.random().toString(36).substr(2,9),
		attributeName:'',	
		description:'',	
		predefine:[],
		staffCreate:staffCode
	}
	const [content, setContent] = useState(initialState)
	
	const handleChange =(event)=>{
		let {name, value} = event.target;
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	

	
	const ValidateExistence=()=>{
        if(content.attributeName!==''){
                
            let url = API_URL+'/product/attributes/existence/'+content.attributeName;
            axios.get(url, config).then(response=>{
                if(response.data.status===200){
                    myRef.current.focus()
                    setErrors({...errors, attributeName:content.attributeName + ' already exist'})
                    setContent({...content, attributeName:''});
                    return response.data;
                }
          })
        }
	} 
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		      
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
			 let url = API_URL+'/product/attributes/create';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}



	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.attributeName){
			errors.attributeName =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}

        return (<Template 
            menu="Catalog"
        submenu="Attributes">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">

<PageHeader title="Add a new product attribute" description='Create new product attribute here' icon='feather icon-book'>
	
<li className="breadcrumb-item"><a href="/products">Products</a> </li>

                    </PageHeader>
		
		

{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, '/product-attributes')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/product-attributes/create')}
  className="btn btn-inverse " ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/product-attributes/edit/'+content.code)} className="btn btn-inverse "><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/product-attributes" className="btn btn-warning "><i className="fa fa-arrow-left"></i> Return</a>


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.attributeName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} onBlur={ValidateExistence} onMouseOut={ValidateExistence} ref={myRef} name="attributeName" value={content.attributeName} className={errors.attributeName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.attributeName}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Description" toolTip={Tooltip.attributeDescription} />
					</div>
									
						<div className="col-sm-9 ">
		<textarea name="description" value={content.description} onChange={handleChange} rows="3" className="form-control">{content.description}</textarea>
							</div>
					</div>	
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-check"></i> Predefined values</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
			<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					You need to save the product attribute before you can add values for this page.
					</div>



					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 