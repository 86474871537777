import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import { API_URL,  config, IMAGES_URL} from '../../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { sort } from '../../components/globalFunction'

 const Slider =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    const [search, setSearch]= useState({
        caption:'',
        isActive:'all'
    })
   
    const [content, setContent] = useState([]);
    const SearchTable =(event)=>{
        event.preventDefault();
        fetchContent();
     }


     const fetchContent =()=>{
        setNotice({...notice, isDataFetching: true  });
        let url = API_URL+'/sliders/display/all';
        axios.get(url, config).then(result=>{ 
            if(Array.isArray(result.data.data)){
            setContent(result.data.data)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 
    }
    
  

  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}

const handleCheckOne =(e)=>{ 

    
    let {name, value} = e.target
    const currentContent = content.map((item)=>{   
        if(item.code ===name){
            return {...item, slider_status:item.slider_status==='false'?'true':'false'}
        }

         return item
          })

   setContent(currentContent)  
       
      }

const tableHeader = [
        
    {dataField: 'code',  text: '#', editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
    <label>
<input type="checkbox" value={row.slider_status} name={row.code}  checked={row.slider_status==='false'?false:true} onChange={(e)=>handleCheckOne(e)} />
        <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
    </label>
</div>},
{dataField: 'image_url', text: 'Picture', editable: false, formatter:(cell, row)=><img id={cell} className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+"/noImage.png"}} src={`${IMAGES_URL}/sliders/${cell}`} title={row.productName}  alt={row.coverUrl} />},
{dataField: 'action_link', text: 'Url',  sort: true, validator: (newValue) => {
    if (!newValue) {
      return {
        valid: false,
        message: Tooltip.isRequired
      };
    }
    return true;
}, formatter:(cell)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
    {dataField: 'caption', text: 'Caption',  sort: true, formatter:(cell)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},

    {dataField: 'status', text: 'Status', sort: true, editor: {
        type: Type.SELECT, options:[{value:'Active', label:'Published'},
        {value:'Inactive', label:'Unpublished'}]
      }, formatter:(cell)=>cell==='Active'? <div className="text-center"><i title="Double click to change" className="fa fa-check  badge-inverse-success"></i></div>: <div className="text-center"><i title="Double click to change" className="fa fa-times  badge-inverse-danger"></i></div>}, 

    {dataField: 'display_order',  text: 'Order',  sort: true, validator: (newValue, row, column) => {
        if (!Number(newValue)) {
          return {
            valid: false,
            message: 'This field required valid number'
          };
        }
        return true;
    }, formatter:(cell)=><div className="text-center">{cell}</div>},
    {dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/cms/slider/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a>, editable: false},
   
 ];

    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, code)=>{       
            const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('code', code);
           let url = API_URL+'/slider/change_field'
          axios.post(url, fd, config)
              .then(result => fetchContent())
              .catch((error)=>Alerts('Error!', 'danger', error.message)) 
            } 
         const { SearchBar } = Search;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                            
                                 
                                 <div className="dt-buttons btn-group pull-right"> 
    <a href="/cms/slider/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</a>
    <button className="btn btn-outline-danger" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button> 
 </div>                   

      
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                              beforeSaveCell: (oldValue, newValue, row, column) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.code);
                                
                               return '';
                              }
                              
                            }
                                      }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      

      const handleDeleteWithImage =()=>{
          

        let data = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status==='true'){
                data.push({code:newContent[i].code, image:newContent[i].image_url})
               
            }
        }
        if(data.length===0){
            Alerts('Error!', 'danger', Tooltip.deleteWarning)
              
        }else{
            let close =   document.getElementById('btnWarningDialog-delAll')
            close.click();
            setNotice({...notice, 
                isLoading: true}) 

            const fd = new FormData();
            fd.append('data', JSON.stringify(data, null, 2))
              
        let url = API_URL+'/sliders/delete_multiple';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  
                      } else{
                  Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
                  fetchContent()
              }) 
            }
    }




     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
			menu="Content Management"
		submenu="Sliders">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Sliders" description='All Website Sliders' icon='feather icon-layers'>
  
					</PageHeader>
<WarningModal  handleAction={handleDeleteWithImage} mID={'delAll'} /> 

{/* The Page Body start here */}
<div className="page-body">

<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Caption" toolTip={'Search with caption'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="caption" value={search.caption} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Status" toolTip={'Status '} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select name="isActive" value={search.isActive} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Sliders</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content.sort(sort)}  columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Slider) 