import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import { API_URL, config} from '../../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import { compare } from '../../components/globalFunction'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle, CSVExport } from 'react-bootstrap-table2-toolkit';

 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	const [newOption, setNewOption] = useState({
		ID:Math.random().toString(36).substr(2,9),
		name:'',
		order:0
	})
	const [products, setProducts] = useState([])
    const handleDelete =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
		

		setNotice({...notice, 
			isLoading: true}) 

	let url = API_URL+'/specification/delete/'+params.code;
		  axios.get(url, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/specification-attributes', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
				
}



    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        let url = API_URL+'/specification/display/one/'+params.code;
        
        axios.get(url, config).then(result=>{ 
			if(Array.isArray(result.data.data)){
            setContent(result.data.data)
			setNewOption({...newOption, order:Number(JSON.parse(result.data.data[0].option).length)+1})
			}
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
	const [defaultUrl, setDefaultUrl] = useState('');
	
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}


    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		     
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
			 let url = API_URL+'/specification/update/'+params.code;
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.specification_name){
			errors.specification_name =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}





		const tableOptionsHeader = [
			{dataField: 'name', text: 'Name', sort:true, validator: (newValue, row, column) => {
				if (!newValue) {
				  return {
					valid: false,
					message: 'This field required valid number'
				  };
				}
				return true;
			}},
			{dataField: 'order', text: 'Order', sort:true, validator: (newValue, row, column) => {
				if (!Number(newValue)) {
				  return {
					valid: false,
					message: 'This field required valid number'
				  };
				}
				return true;
			}, formatter:(cell)=><div className="text-center">{cell}</div>},
			 {dataField: 'ID', text: 'Action', formatter:(cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleRemoveOption(row.ID)} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>, editable: false}
			  ];
		 
	
	
const TableOptionsRecord=(props)=>{
	
				const handleUpdate=(column, newValue, ID)=>{     
					
			const newContent = JSON.parse(JSON.stringify(content)) 
			const options = JSON.parse(newContent[0].options);
	
			const otherOptions = options.filter((list)=>list.ID!==ID)
			const optionsData = options.filter((list)=>list.ID===ID)		
			optionsData[0][column.dataField] = newValue
			const currentOptions = otherOptions.concat(optionsData)
			newContent[0].options = JSON.stringify(currentOptions)
	
			setContent(newContent); 
				 } 
	
	
				 const { SearchBar } = Search;
				 
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [{text: '20', value: 20}, 
			  {text: '50', value: 50}, 
			  {text: '70', value: 70}, 
			  {text: '100', value: 100}, 
			  {text: '150', value: 150},
			  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='ID' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										 <SearchBar
										 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
										 
										 
										 
			<div className="dt-buttons btn-group pull-right"> 
				 <button data-toggle="modal"   data-target='#addOptions' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</button>
		 </div>                   
			 </div>
										 
							 <BootstrapTable
										 { ...props.baseProps }
													 striped
												 hover
												 condensed
												 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
											 pagination={paginationFactory(options) }
											 cellEdit={ cellEditFactory({
											   mode: 'dbclick',
											   blurToSave: true,
											   autoSelectText: true,
									 beforeSaveCell: (oldValue, newValue, row, column) => {
									   if(oldValue !==newValue){
									   handleUpdate(column, newValue, row.ID);
									   
									  return true;
									 }
									 
								   }
											 }) }
										 />
									 </div>
									 )
								 }
			  </ToolkitProvider>
			  }
	
	
	


			  const handleChangeOption =(event)=>{
				const {name, value} = event.target;
				setNewOption({...newOption, [name] : value });
			}

			const handleRemoveOption =(ID)=>{
				const newContent = JSON.parse(JSON.stringify(content)) 
				const options = JSON.parse(newContent[0].option);
				newContent[0].option = JSON.stringify(options.filter((list)=>list.ID!==ID))
				setContent(newContent);
			}

			const handleAddOptions =(event)=>{
				event.preventDefault()
				if(newOption.name===''){
					alert('Please enter option name')
				}else{
				const newContent = JSON.parse(JSON.stringify(content)) 
				const options = JSON.parse(newContent[0].option);
				
				newContent[0].option = JSON.stringify(options.concat(newOption))
				setContent(newContent);
				let close =   document.getElementById('closeOption')
				close.click();
		
			setNewOption({
				ID:Math.random().toString(36).substring(2,9),
				name:'',
				order:setNewOption({...newOption, order:Number(JSON.parse(content[0].option).length)+1})
			})
		}
	}





	const TableSpecificationRecordHeader = [

		{dataField: 'productName', text: 'Product', sort:true, editable: false, formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
		{dataField: 'isPublished', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.isPublished==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>},

		{dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/products/edit/${row.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>, editable: false}
	   
	 ];

const TableSpecificationRecord=(props)=>{

		 const { SearchBar } = Search;
		 
		 const customTotal = (from, to, size) => (
			<span >&nbsp;Showing { from } to { to } of { size } items</span>
		  );
		 const options = {
			showTotal: true,
		  paginationTotalRenderer: customTotal,
		  sizePerPageList: [{text: '20', value: 20}, 
			  {text: '50', value: 50}, 
			  {text: '70', value: 70}, 
			  {text: '100', value: 100}, 
			  {text: '150', value: 150},
			  { text: 'All', value: props.data.length }]
		   
		  };
		 return  <ToolkitProvider search columnToggle 
					 keyField='ID' data={ props.data } columns={ props.columns } >
						 
						 {
							 props => (
							 <div >
							   <div className="form-group">
								 <SearchBar
								 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
								 
								 
							 
	 </div>
								 
					 <BootstrapTable
								 { ...props.baseProps }
											 striped
										 hover
										 condensed
										 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
									 pagination={paginationFactory(options) }
									 
								 />
							 </div>
							 )
						 }
	  </ToolkitProvider>
	  }

	  const fetchProducts =()=>{
		
		let url = API_URL+'/product/attributes/search/'+params.code;
			axios.get(url,  config).then(result=>{ 
				if(Array.isArray(result.data.data)){
			setProducts(result.data.data)
				}
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}

useEffect(()=>{
	fetchContent()
	fetchProducts()
},[]);	
	
        return (<Template 
            menu="Catalog"
        submenu="Attributes">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">

<PageHeader title="Edit specification attribute" description='Update product specification attributes here' icon='feather icon-book'>
	
<li className="breadcrumb-item"><a href="/products">Products</a> </li>
<li className="breadcrumb-item"><a href="/specification-attributes">Specification</a> </li>
                    </PageHeader>
		

<div className="modal fade " id='addOptions' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add a new option</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Name" toolTip={'The name of the option'} />
					</div>
									
						<div className="col-sm-8 ">
							<div className="input-group">
						<input type="text" className="form-control " onChange={handleChangeOption}  name="name" value={newOption.name} />
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.name}</span>
							</div>
				</div>
				
				<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" className="form-control" onChange={handleChangeOption}  name="order" min="0" value={newOption.order} />
							
							</div>
							</div>
				</div>
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeOption' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddOptions(event)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>



{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, '/specification-attributes')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

<button type="button" onClick={(e)=>handleSubmit(e, '/specification-attributes/edit/'+params.code)} className="btn btn-inverse "><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;

	<a href="/specification-attributes" className="btn btn-warning "><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger " data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDelete()} mID={'delAll'} /> 


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Attribute info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.specification_name} />
					</div>
									
						<div className="col-sm-6 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  ref={myRef} name="specification_name" value={data.specification_name} className={errors.specification_name  ? 'form-control formerror form-control-lg ' : 'form-control form-control-lg  '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.specification_name}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-5 ">
						<input type="number" min="0" className="form-control form-control-lg " name="displayOrder" value={data.displayOrder} onChange={handleChange} />

							</div>
					</div>	

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>



							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
	<div className="card-header " >
			<h5><i className="fa fa-object-group"></i> Options</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
<div className="table-responsive">
		{<TableOptionsRecord data={JSON.parse(data.option).sort(compare)} columns={tableOptionsHeader}  />}
		</div>

					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-th-list"></i> Used by products</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					<div className="table-responsive">
		{<TableSpecificationRecord data={products} columns={TableSpecificationRecordHeader}  />}
		</div>
					</div>

					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>





				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 