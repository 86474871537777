import React, { useEffect } from 'react'
import Cookies from 'js-cookie'
function Logout() {

  let stop = Date.now();
const clearCookies=()=>{
	
  Cookies.remove('stfmscd')
  Cookies.remove('mspgcd')
  Cookies.remove('msptk')


	localStorage.setItem('_tmlsp', stop)
  localStorage.setItem('_btmlsp', stop)
}

useEffect(()=>{  
	 
  clearCookies()
  window.open('/', '_self')
  },[]); 

  return (
    <div>Please wait ...</div>
  )
}

export default Logout