import React from 'react';
import logo from './logo.svg';

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Login from './users/login';

import Forgotpassword from './users/forgotpassword';
import Error403 from './settings/error403';
import Error500 from './settings/error500';
import Error404 from './settings/error404';
import ProtectedView from './protected_view';
import NewPassword from './users/create_new_password';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Logout from './users/Logout';









function App() {

   
  return (
    <>
 <ReactNotifications />
    <Router>
          <Routes>
         <Route  path="/" element={<Login />} />
         <Route  path="/logout" element={<Logout />} />

         <Route  path="/forgot_password" element={<Forgotpassword />} />
         <Route  path="/create_new_password/:myemail/:mytoken/:accessDate" element={<NewPassword />} />
         <Route  path="/error403" element={<Error403 />} />
         <Route  path="/error500" element={<Error500 />} />

         </Routes>
          <ProtectedView  />  
    </Router>

    
</>
  );
}
export default App;
