import React from 'react'

export const FormLabel = (props) =>{
    return  <label className="bold pull-right">{props.name}	<a className="mytooltip" href="#!"> <i className="fa fa-question-circle"></i><span className="tooltip-content5">
            
            <span className="tooltip-text3">
                <span className="tooltip-inner2">{props.toolTip}</span>
                </span>
            </span>
            </a>
            </label>
             
}
export const FormLabelLeft = (props) =>{
    return  <label className="bold ">{props.name}	<a className="mytooltip" href="#!"> <i className="fa fa-question-circle"></i><span className="tooltip-content5">
            
            <span className="tooltip-text3">
                <span className="tooltip-inner2">{props.toolTip}</span>
                </span>
            </span>
            </a>
            </label>
             
}

export default React.memo(FormLabel)