import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { Popover, LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, Token, config, API_URL, IMAGES_URL} from '../../components/include'
import axios from 'axios'
import {PictureUrl} from '../../components/include'
import { useParams } from 'react-router-dom'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {Type} from 'react-bootstrap-table2-editor';
import {  compare } from '../../components/globalFunction'
import Select from 'react-select'
import Country from "../../components/country";
 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});



	const [panel, setPanel] = useState({
		info:true,
		display:true,
		seo:false,
		collection:false,
		wallet:false,
		filter:false,
		product:false,
		note:false,
		basic:true,
		seoDisplay:false,
		noteDisplay:false,
		infoDisplay:false,
		productDisplay:false,
		collectionDisplay:false,
		walletDisplay:false,
		displayDisplay:false
	})

	const goAdvance=()=>{
		setPanel({...panel,
		seoDisplay:true,
		noteDisplay:true,
		infoDisplay:true,
		displayDisplay:true,
		walletDisplay:true,
		collectionDisplay:true,
		productDisplay:true,
		displayFilter:true,
		basic:false
	})
	}

	const goBasic=()=>{
		setPanel({...panel,
		seoDisplay:false,
		infoDisplay:false,
		displayDisplay:false,
		walletDisplay:true,
		noteDisplay:true,
		collectionDisplay:true,
		displayFilter:true,
		productDisplay:true,
		basic:true
	})
	}
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [category, setCategory] = useState([])
	const [specOption, setSpecOption] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	const [province, setProvince] = useState([]);
	const [lga, setLga] = useState([])
	const [content, setContent] = useState([])
	const [brand, setBrand] = useState([])
	const [vendorProduct, setVendorProduct] = useState([])
	const [products, setProducts] = useState([])
	const [specification, setSpecification] = useState([])
	const [note, setNote] = useState({
		ID:Math.random().toString(36).substr(2,9),
		dateCreate:new Date().toISOString().slice(0,10)+' '+ new Date().toLocaleTimeString(),
		note:''
	
	})
	
	const [filter, setFilter] = useState({
		code:'f'+Math.random().toString(36).substr(2,9),
		filterType:'Category',
		mainCategory:'',
		brands:[],
		order:1,
		isPublished:'true',
		options:[]

	})
	const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
	
	const handleSelectFilter = (option, action)=>{
		setFilter({...filter, [action.name] : option});
	
	}



    const handleDeleteWithImage =()=>{
          
        let data = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status==='true'){
                data.push({code:newContent[i].code, image:newContent[i].imageUrl})
               
            }
        }
        if(data.length===0){
            Alerts('Error!', 'danger', Tooltip.deleteWarning)
              
        }else{
            let close =   document.getElementById('btnWarningDialog-delAll')
            close.click();
            setNotice({...notice, 
                isLoading: true}) 

            const fd = new FormData();
            fd.append('data', JSON.stringify(data, null, 2))
              
        let url = API_URL+'/vendor/delete';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  
                      } else{
                  Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
                  fetchContent()
              }) 
            }
    }

  

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
		let url = API_URL+'/vendor/display/one/'+params.code;
	
		axios.get(url, config).then(result=>{ 
			if(Array.isArray(result.data.data)){
            setContent(result.data.data)
			}
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }


	const [defaultUrl, setDefaultUrl] = useState('');
	const [image, setImage] = useState('');

	const handleChangeNumber =(event)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		if(name==='pageName'){
			value = value.replace(/,?[ ]/g, '-')
		}
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}

	const handleChangeCheck =(event)=>{
		const newContent =JSON.parse(JSON.stringify(content))  
			let {name, value} = event.target;
			value = value ==='true'?'false':'true'	
			newContent[0][name]= value
			setContent(newContent);
			setErrors({...errors, [name]:''});
		}

	const handleChangeDescription =(data)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		newContent[0].description = data
		setContent(newContent);
	}

	const setLogo =()=>{
		const img = document.getElementById('viewLogo');
		img.src = IMAGES_URL+"/noImage.png" 
		document.getElementById('logo').value = '';
		setImage('')
	}
	const removeImageUrl =()=>{
		const newContent =JSON.parse(JSON.stringify(content))
		 newContent[0].imageUrl = '' 
		setContent(newContent);

		const img = document.getElementById('viewLogo');
		img.src = IMAGES_URL+"/noImage.png" 
		document.getElementById('logo').value = '';
		setImage('')
	}
	
	const fetchProvince =()=>{
	
		var sql ="Select ID, stateName from tbl_states order by ID ASC";
		  
		const fd = new FormData();
		fd.append("sql", sql);
		let url = ServerUrl+'/fetchBySql_controller.php';
		fd.append("jwt", Token);
		axios.post(url, fd, config).then(result=>{ 
			setProvince(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}
	
	const fetchLga =()=>{
		
		var sql ="Select ID, stateId, lgaName from tbl_lga"
		  
		const fd = new FormData();
		fd.append("sql", sql);
		let url = ServerUrl+'/fetchBySql_controller.php';
		fd.append("jwt", Token);
		axios.post(url, fd, config).then(result=>{ 
			setLga(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}
	const handleSelect = (option, action)=>{
		const newContent = JSON.parse(JSON.stringify(content)) 
		newContent[0][action.name] = option.value
		setContent(newContent);
	}

	const fetchNameByField =(Id, table, field)=>{
		const result = table.filter(list=>list.ID===Id);
	  const answer = result.map((ans)=>ans[field])
		return answer
	  }

    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		 
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		 
			if(image!==''){
				fd.append("image", image);
			}
			
			
			 
			let url = API_URL+'/vendor/update/'+params.code;
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
        
        if(!values.vendorName){
            errors.vendorName =Tooltip.required;
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }

        return errors;
        }


		const  handleFile = function(fieldName){
			return function(newValue){                 
				const value = newValue.target.files[0]
			  // setImage(value)
		   readURL(newValue);
		   }
		   
		  } 
		
		   const readURL = (input)=>{       
			let displayMessage = '';
			  var fileName =  document.getElementById('logo').value;
			var idxDot = fileName.lastIndexOf(".") + 1;
			var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
			if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
				//TO DO    
				
				 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
			if (FileSize > 0.5) {
				displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500 Kb';
			   document.getElementById(input.target.name).value = '';
			   setImage('')
			} else {
				if (input.target.files && input.target.files[0]) { //Check if input has files.
				  var reader = new FileReader();//Initialize FileReader.
				  reader.onload = function (e) {
			   			const newContent =JSON.parse(JSON.stringify(content))
							 newContent[0].imageUrl = '' 
							 setContent(newContent);
							 
				  const img = document.getElementById('viewLogo');
							 img.src = e.target.result             
							
				  };
				  reader.readAsDataURL(input.target.files[0]); 
				  setImage(input.target.files[0])
				  
				}
			}
			}else{
				displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500kb'
			   document.getElementById(input.target.name).value = '';
			   setImage('');
			}   
			
			if(displayMessage.length > 0){
			
				Alerts('Error!', 'danger', displayMessage)
			  }
		} 

		const handleRemoveNote =(ID)=>{
			
			const newContent = JSON.parse(JSON.stringify(content)) 
				const newNote = JSON.parse(newContent[0].notes);
			newContent[0].notes = JSON.stringify(newNote.filter(list=>list.ID!==ID))
				setContent(newContent)
		 }

		const handleAddNote =(event)=>{
			event.preventDefault()
			let close =   document.getElementById('closeNote')
			close.click();
			const newContent = JSON.parse(JSON.stringify(content)) 
			const newNote = JSON.parse(newContent[0].notes);
			newContent[0].notes = JSON.stringify(newNote.concat(note))
			setContent(newContent)
			setNote({
				ID:Math.random().toString(36).substr(2,9),
			dateCreate:new Date().toISOString().slice(0,10)+' '+ new Date().toLocaleTimeString(),
			note:''
			})
		}


		const  tableVendorNoteHeader = [
			{dataField: 'dateCreate',  text: 'Created On', editable: false},
			{dataField: 'note',  text: 'Note'},
			  {dataField: 'ID', text: 'Action', formatter: (cell)=><div>
				  <button type="button" className="btn btn-danger btn-sm" onClick= {()=>handleRemoveNote(cell)} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>
			  </div>, editable: false},
		
					  ];
				 
			
			
			const TableVendorNoteRecord=(props)=>{
				const handleUpdate=(column, newValue, ID)=>{     
					
					 const newContent = JSON.parse(JSON.stringify(content)) 
					const newNote = JSON.parse(newContent[0].notes);
			
					const otherNote = newNote.filter((list)=>list.ID!==ID)
					const noteData = newNote.filter((list)=>list.ID===ID)	
					noteData[0][column.dataField] = newValue
					const currentNote = otherNote.concat(noteData)
					newContent[0].notes = JSON.stringify(currentNote)
					setContent(newContent)
					
				} 
						 const { SearchBar } = Search;
						 
						 const customTotal = (from, to, size) => (
							<span >&nbsp;Showing { from } to { to } of { size } items</span>
						  );
						 const options = {
							showTotal: true,
						  paginationTotalRenderer: customTotal,
						  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
						  { text: 'All', value: props.data.length }]
						   
						  };
						 return  <ToolkitProvider search columnToggle 
									 keyField='ID' data={ props.data } columns={ props.columns } >
										 
										 {
											 props => (
											 <div >
											   <div className="form-group">
												
										 
			<div className="dt-buttons btn-group"> 
				 <button data-toggle="modal"   data-target='#addNote' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New Note</button>
		 </div>                   
			 </div>
												 
			<BootstrapTable
						{ ...props.baseProps }
									striped
								hover
								condensed
								noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
							pagination={paginationFactory(options) }
							cellEdit={ cellEditFactory({
								mode: 'dbclick',
								blurToSave: true,
								autoSelectText: true,
					  beforeSaveCell: (oldValue, newValue, row, column) => {
						if(oldValue !==newValue){
						handleUpdate(column, newValue, row.ID);
						
					   return true;
					  }
					  
					}
							  }) }
						/>
					</div>
					)
				}
		</ToolkitProvider>
					  }



	const handleChangeNote =(event)=>{
	let {name, value} = event.target;
	setNote({...note, [name]:value})
}




const handleAddFilter =(ID)=>{
	let close =   document.getElementById('closeFilter')
	close.click();

	const newContent = [...content] 
	//get old product list
	const newFilter = JSON.parse(newContent[0].filter);
	
		const currentList = newFilter.concat(filter)
		//remove the duplicate item
		const allFilter =	currentList.map(e=>e['filterType'])
		.map((e,i,final)=>final.indexOf(e)===i&&i)
		.filter(e=>currentList[e])
		.map(e=>currentList[e])
		const filterList = JSON.stringify(allFilter, null, 2)
		UpdateFilterOption(filterList, ID)

}

const UpdateFilterOption =(filterList, code)=>{
	  
	setNotice({...notice, 
		isLoading: true}) 

	const fd = new FormData(); 
	fd.append('newValue', filterList);
	fd.append('column', 'filter');
	fd.append('code', code);
   let url = API_URL+'/vendor/change_field'
  axios.post(url, fd, config)
	  .then(response =>{
	   if(response.data.type ==='success'){
		 // Alerts('Saved!', 'success', response.data.message)
		  
			  } else{
		  Alerts('Error!', 'danger', JSON.stringify(response.data))
			  }   
	  })
	  .catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	  }).finally(()=>{
		  setNotice({...notice, 
			  isLoading: false
		  })
		  fetchContent()

		setFilter({
			code:'f'+Math.random().toString(36).substr(2,9),
		filterType:'Category',
		mainCategory:'',
		brands:[],
		order:1,
		isPublished:'true',
		options:[]})
	  }) 
	
}

const handleRemoveFilter =(e, code)=>{
	e.preventDefault()
	let close =   document.getElementById('btnWarningDialog-'+code)
		close.click();

	const newContent = [...content] 
	const newFilter = JSON.parse(newContent[0].filter);
	const id = JSON.parse(newContent[0].ID)
	const otherFilter = newFilter.filter(list=>list.code!==code)
	const filterList = JSON.stringify(otherFilter)
	UpdateFilterOption(filterList, id)
}

const getSpecLabel=(code)=>{	  
	const result = specification&&specification.filter(list=>list.code===code)
	return (result.map((node)=>node.specificationName))
}

const handleCheckOne =(e)=>{  
	let {name, value} = e.target

	const newProducts = [...products];
	const otherProduct = newProducts.filter(item=>item.code!==name)
	const product = newProducts.filter(item=>item.code===name)

	
	const status = value ===''? true:value==='true'?false:true 
	product[0].status = status
	const currentProduct = product.concat(otherProduct)
  setStatus({...status, checkAll:false})
  setProducts(currentProduct)  
	   
	  }


const  tableProductHeader = [
	{dataField: 'ID',  text: '#!', editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
	<label>
<input type="checkbox" value={row.status}  name={row.code}  checked={row.status} onChange={(e)=>handleCheckOne(e)} />
		<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
	</label>
</div>},
	{dataField: 'productName', text: 'Name', sort:true, editable: false},
	
	  {dataField: 'isPublished', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.isPublished==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>}
	  ];
 


const TableProductRecord=(props)=>{

		 const { SearchBar } = Search;
		 
		 const customTotal = (from, to, size) => (
			<span >&nbsp;Showing { from } to { to } of { size } items</span>
		  );
		 const options = {
			showTotal: true,
		  paginationTotalRenderer: customTotal,
		  sizePerPageList: [
			  {text: '20', value: 20}, 
		  {text: '50', value: 50}, 
		  {text: '70', value: 70}, 
		  {text: '100', value: 100}, 
		  {text: '150', value: 150},
		  { text: 'All', value: props.data.length }]
		   
		  };
		 return  <ToolkitProvider search columnToggle 
					 keyField='ID' data={ props.data } columns={ props.columns } >
						 
						 {
							 props => (
							 <div >
							   <div className="form-group">
								 <SearchBar
								 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
												 
	 </div>
								 
<BootstrapTable
		{ ...props.baseProps }
					striped
				hover
				condensed
				noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
			pagination={paginationFactory(options) }
			
		/>
	</div>
	)
}
</ToolkitProvider>
	  }


const tableFilterHeader = [
	
	{dataField: 'filterType', text: 'Filter type', editable: false, formatter:(cell, row)=>cell ==='Category'?cell:cell ==='Price'?cell:cell ==='Rating'?cell:cell ==='Tags'?'Tags':getSpecLabel(cell)},
	  {dataField: 'isPublished', text: 'Published', sort:true, editor: {
		type: Type.SELECT, options:[{value:true, label:'Show on brand page'},
		{value:false, label:'Do not show on brand page'}]
	  }, formatter:(cell, row)=>row.isPublished==='true'? <div style={{cursor:'pointer'}} className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div style={{cursor:'pointer'}}className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>},
	{dataField: 'order', text: 'Order', sort:true, validator: (newValue, row, column) => {
		if (!Number(newValue)) {
		  return {
			valid: false,
			message: 'This field required valid number'
		  };
		}
		return true;
	}, formatter:(cell)=><div className="text-center">{cell}</div>},
	 {dataField: 'code', text: 'Action', formatter:(cell, row)=><div>
		<WarningModal  handleAction={(e)=>handleRemoveFilter(e, row.code)} mID={row.code} />
	
	<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button></div>, editable: false
	 }
	  ];
 
	  
	const TableFilterRecord=(props)=>{

		const handleUpdate=(column, newValue, code)=>{     
			
			 const newContent = JSON.parse(JSON.stringify(content)) 
			const newFilter = JSON.parse(newContent[0].filter);
			const id = JSON.parse(newContent[0].code)
			const otherFilter = newFilter.filter(list=>list.code!==code)
			const filterData = newFilter.filter((list)=>list.code===code)		
			filterData[0][column.dataField] = newValue
			const currentFilter = otherFilter.concat(filterData)
			const filterList = JSON.stringify(currentFilter)
			
			UpdateFilterOption(filterList, id)
				 } 

		 const customTotal = (from, to, size) => (
			<span >&nbsp;Showing { from } to { to } of { size } items</span>
		  );
		 const options = {
			showTotal: true,
		  paginationTotalRenderer: customTotal,
		  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
		  { text: 'All', value: props.data.length }]
		   
		  };
		 return  <ToolkitProvider search columnToggle 
					 keyField='code' data={ props.data } columns={ props.columns } >
						 
						 {
							 props => (
							 <div >
							   <div className="form-group">
								 
	<div className="dt-buttons btn-group pull-right"> 
		 <button data-toggle="modal"   data-target='#addFilter' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</button>
 </div>                   
	 </div>
								 
					 <BootstrapTable
								 { ...props.baseProps }
											 striped
										 hover
										 condensed
										 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
									 pagination={paginationFactory(options) }
									 cellEdit={ cellEditFactory({
									   mode: 'dbclick',
									   blurToSave: true,
									   autoSelectText: true,
							 beforeSaveCell: (oldValue, newValue, row, column) => {
							   if(oldValue !==newValue){
							   handleUpdate(column, newValue, row.code);
							   
							  return true;
							 }
							 
						   }
									 }) }
								 />
							 </div>
							 )
						 }
	  </ToolkitProvider>
	  }

	  const fetchSpecification =()=>{
	
		let url = API_URL+'/specification/display/all';
		axios.get(url, config).then(result=>{ 
			if(Array.isArray(result.data.data)){
		setSpecification(result.data.data)
			}else{
				setSpecification([])	
			}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})

}


	
const fetchCategory =()=>{
	let url = API_URL+'/category/display/all'
	axios.get(url, config).then(result=>{ 
		if(Array.isArray(result.data.data)){

			setCategory(result.data.data)
		}
	})
	
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})

}


const handleChangeSelectFilter =(event)=>{
let {name, value} = event.target;
if( value !=='Brand' && value !=='Category' && value !=='Price' && value !=='Rating' && value !=='Tags'){
	getAttributeOption(value)
}
setFilter({...filter, [name] : value, options:[] });
}

const handleChangeFilter =(event)=>{
let {name, value} = event.target;

if(name==='isPublished'){
	value= value==='true'?false:true
}
if(name ==='order' && Number(value)<0){
	value =0
}

setFilter({...filter, [name] : value });
}

const getAttributeOption = (code) =>{ 
const spec = specification.filter((list)=>list.code===code);
const answer = JSON.parse(spec[0].options);
setSpecOption(answer);
 return  answer;
}


const fetchProducts =()=>{
		
	
	let url = API_URL+'/product/display/all'
	axios.get(url, config).then(result=>{ 
		if(Array.isArray(result.data.data)){
	let vendorProduct = result.data.filter(item=>item.vendor===params.code)
		setVendorProduct(vendorProduct)
		setProducts(result.data)
		}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})

}


const tableVendorProductHeader = [

	{dataField: 'productName', text: 'Product', sort:true, editable: false, formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},

	{dataField: 'isPublished', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.isPublished==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>},

	{dataField: 'ID', text: 'Action', formatter: (cell, row)=><div><a href={`/products/edit/${row.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>&nbsp; 
	
	<WarningModal  handleAction={(e)=>handleRemoveProduct(e, row.code)} mID={row.code} />

	<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button></div>, editable: false}
   
 ];

const TableVendorProduct=(props)=>{

	 const { SearchBar } = Search;
	 
	 const customTotal = (from, to, size) => (
		<span >&nbsp;Showing { from } to { to } of { size } items</span>
	  );
	 const options = {
		showTotal: true,
	  paginationTotalRenderer: customTotal,
	  sizePerPageList: [
	  {text: '20', value: 20}, 
	  {text: '50', value: 50}, 
	  {text: '70', value: 70}, 
	  {text: '100', value: 100}, 
	  {text: '150', value: 150},
	  { text: 'All', value: props.data.length }]
	   
	  };
	 return  <ToolkitProvider search columnToggle 
				 keyField='ID' data={ props.data } columns={ props.columns } >
					 
					 {
						 props => (
						 <div >
						   <div className="form-group">
							 <SearchBar
							 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
							 
							 
							 
<div className="dt-buttons btn-group pull-right"> 
	 <button data-toggle="modal"   data-target='#addNewProduct' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add a new product</button>
</div>                   
 </div>
							 
				 <BootstrapTable
							 { ...props.baseProps }
										 striped
									 hover
									 condensed
									 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
								 pagination={paginationFactory(options) }
								 
							 />
						 </div>
						 )
					 }
  </ToolkitProvider>
  }

  const handleRemoveProduct =(e, code)=>{
	e.preventDefault()
	let close =   document.getElementById('btnWarningDialog-'+code)
		close.click();
		let productId = []
	const newContent = [...content]
	//get old product list
	const newProductList = JSON.parse(newContent[0].productList);
	const allProduct = newProductList.filter(list=>list.code!==code)
	productId.push(code)
	UpdateVendorProducts(productId, allProduct, '')
	
}


const handleAddNewProduct =(event)=>{
	event.preventDefault()
	
	let records = []
	let productId = []
	const newContent = [...content]
	//get old product list
	const newProductList = JSON.parse(newContent[0].productList);
	
	 const newProducts = [...products]
		for (var i in newProducts){
			if(newProducts[i].status===true){
				records.push({code:newProducts[i].code})
				productId.push(newProducts[i].code)
			}
		}

		//merger old prouct list with selected product
		const currentList = newProductList.concat(records)
		//remove the duplicate item
		const allProduct =	currentList.map(e=>e['code'])
		.map((e,i,final)=>final.indexOf(e)===i&&i)
		.filter(e=>currentList[e])
		.map(e=>currentList[e])

		if(records.length===0){
		Alerts('Error!', 'danger', 'Please select at least one product to add')
		}else{
		UpdateVendorProducts(productId, allProduct, params.code)
		}
		let close =   document.getElementById('closeNewProduct')
		close.click();
}


const UpdateVendorProducts =(productId, products, data)=>{
  
	setNotice({...notice, 
		isLoading: true}) 
var productCode = JSON.stringify(productId, null, 2)
var productList = JSON.stringify(products, null, 2)

	const fd = new FormData();
	fd.append('productCode', productCode)
	fd.append('productList', productList)
	fd.append('newValue', data)
	fd.append('vendorCode', params.code)
	fd.append('totalItem', products.length)
	fd.append("jwt", Token);
let url = ServerUrl+'/updateMultiple_controller.php?tablename=tbl_vendors';
	  axios.post(url, fd, config)
	  .then(response =>{
	   if(response.data.type ==='success'){
		  Alerts('Saved!', 'success', response.data.message)
		  
			  } else{
		  Alerts('Error!', 'danger', JSON.stringify(response.data))
			  }   
	  })
	  .catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	  }).finally(()=>{
		  setNotice({...notice, 
			  isLoading: false
		  })
		  fetchProducts()
	  }) 
	
}


useEffect(()=>{
	fetchContent()
	fetchProvince()
	fetchLga()
	fetchProducts()
	fetchCategory()
	fetchSpecification()
},[]);	
	
        return (<Template 
			menu="People"
		submenu="Vendors">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Edit Vendor" description='Update vendor details' icon='feather icon-user'>
  <li className="breadcrumb-item"><a href="/vendors">Vendors</a> </li>
					</PageHeader>
		
	

<div className="modal fade " id='addNewProduct' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add new  product</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
  <div className="table-responsive">
        
        <TableProductRecord data={products}  columns={tableProductHeader}  />
            </div>
    </div>
              <div className="modal-footer">
                  <button type="button"  id='closeNewProduct' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddNewProduct(event)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>

<div className="modal fade " id='addNote' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add Vendor Note</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Note" toolTip={'Enter this order note message'} />
					</div>
									
						<div className="col-sm-9 ">
					<textarea rows="5" name="note" className="form-control" onChange={handleChangeNote} value={note.note} >{note.note}</textarea>
							</div>
				</div>
			
			
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeNote' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={handleAddNote} className="btn btn-inverse">Save</button>
              </div>
          </div>
      </div>
  </div>




{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								


<div className="modal fade " id='addFilter' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add new filter option</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Filter Type" toolTip={'Choose Filter Type'} />
					</div>
									
						<div className="col-sm-8 ">
					<select  name="filterType" value={filter.filterType} className="form-control" onChange={handleChangeSelectFilter}>
                        <option value="Category">Category</option>
						<option value="Brand">Brand</option>

						<option value="Price">Price Range</option>
						<option value="Rating">Rating</option>
						<option value="Tags">Product Tags</option>
						 {specification&&specification.map((st, id)=><option key={id} value={st.code}>{st.specificationName}</option>)} 
                    </select>
							</div>
				</div>


			{filter.filterType==='Category'?	<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Filter Option" toolTip={'Select option you want to appear on filter sidebar'} />
					</div>
									
						<div className="col-sm-8 ">
						<select  name="mainCategory" value={filter.mainCategory} className="form-control" onChange={handleChangeFilter}>
						<option  value=''>[None]</option>
						 {category&&category.filter(item=>item.parentCategory==='root').map((st, id)=><option key={id} value={st.code}>{st.categoryName}</option>)} 
                    </select>
							</div>
					</div>:''}

					{filter.filterType==='Brand'?	<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Filter Option" toolTip={'Choose other brand to appear on filter sidebar'} />
					</div>
									
						<div className="col-sm-8 ">
						<Select isMulti options={
		brand&&brand.map((node, id)=>{
			return {value:node.code, key:id, label:node.manufacturerName}
		})} 
		
onChange={handleSelectFilter}  name="brands" value={filter.brands} styles={selectStyles} /> 	
							</div>
					</div>:''}


					<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Published"  toolTip={Tooltip.published} />
					</div>
									
						<div className="col-sm-8 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={filter.isPublished} name="isPublished" checked={filter.isPublished==='true'?true:false} onChange={handleChangeFilter} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="order" value={filter.order} onChange={handleChangeFilter} />

							</div>
			</div>
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeFilter' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={()=>handleAddFilter(data.ID)} className="btn btn-inverse">Save</button>
              </div>
          </div>
      </div>
  </div>



			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success btn-sm" onClick={(e)=>handleSubmit(e, '/vendors')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/vendor/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	<a href="/vendors" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDeleteWithImage(data.imageUrl)} mID={'delAll'} /> 

	{panel.basic?
	<button type="button" onClick={goAdvance} className="btn  btn-sm pull-right"> Basic Mode <i className="fa fa-check"></i></button> :
	<button type="button" onClick={goBasic} className="btn btn-sm btn-primary pull-right">  Advanced Mode <i className="fa fa-check"></i></button>}

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, info:!panel.info})}>
			<h5><i className="fa fa-info"></i> Vendor Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.info?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.info?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                              
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.vendorName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} name="vendorName" value={data.vendorName} className={errors.vendorName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.vendorName}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Description" toolTip={'Description of the vendor'} />
					</div>
									
						<div className="col-sm-9 ">
						<QuillText value={data.description} onChange={handleChangeDescription} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Email" toolTip={Tooltip.email} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  name="email" value={data.email} className={errors.email  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.email}</span>
							</div>
					</div>

					
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Active" toolTip={'A value indicating whether a vendor is active'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.isActive} name="isActive" checked={data.isActive==='true'?true:false} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
			
			
	{panel.infoDisplay?	<>	
				
				
				<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Admin Comment" toolTip={Tooltip.adminComment} />
		</div>
						
			<div className="col-sm-9 ">
				
			<textarea type="text" className="form-control" rows="5" name="comment" value={data.comment} onChange={handleChange}>{data.comment}
			</textarea>

				</div> 
		</div>	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Picture" toolTip={Tooltip.picture} />
					</div>
									
						<div className="col-sm-9 ">
						
						<div className="form-group">
							<img className="img-70" id="viewLogo" onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/noImage.png"}} src={PictureUrl+"/vendors/"+data.imageUrl} alt="Picture "  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
			{data.imageUrl?	<button type="button" onClick={removeImageUrl} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>	:''}

			{image?	<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>	:''}

										</div>
							</div>
					</div></>:''}	
	</section></div>
	
	
	{panel.infoDisplay?	<div className="card z-depth-0">
						<section className="card-block">
                                           

						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Country" toolTip={'Select Country'} />
					</div>
									
						<div className="col-sm-9 ">
						<Country handleSelect={handleSelect} country={data.country} />
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="State " toolTip={'Select State / province'} />
					</div>
									
						<div className="col-sm-9 ">
						{data.country==='Nigeria'?
						<Select  
				  options={province&&province.map((state, idx)=> {
					return {key:idx, value: state.ID, label: state.stateName, index:idx };
				  })}
onChange={handleSelect}  name="stateId"  value={{value: data.stateId, label: fetchNameByField(data.stateId, province, 'stateName')}} styles={selectStyles} />:'Others'} 

							</div>
				</div>

				
		{data.country==='Nigeria'?		<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Lga" toolTip={'Enter Lga'} />
					</div>
									
						<div className="col-sm-9 ">
			<Select   options={lga&&lga.map((lg, idx)=> {
                         return {key:idx, value: lg.ID, label: lg.lgaName, index:idx };
                                  })}
onChange={handleSelect}  name="lga" value={{value: data.lga, label: fetchNameByField(data.lga, lga, 'lgaName')}} styles={selectStyles} />

							</div>
				</div>:''}

				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="City" toolTip={'Enter city'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChange}  name="city" value={data.city} />
							</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Address" toolTip={'Enter Address'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChange}  name="address" value={data.address} />
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Telephone" toolTip={'Enter Telephone'} />
					</div>
					
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChange}  name="telephone" value={data.telephone} />
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-3">
						<FormLabel name ="Zip code" toolTip={'Enter Zip / postal code'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChange}  name="postalCode" value={data.postalCode} />
						</div>
				</div>
				
	</section></div>:''}
	
	</div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>
				<div  className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, display:!panel.display})}>
			<h5><i className="fa fa-television"></i> Display</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.display?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.display?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						{panel.displayDisplay?<>	
				
			
				<div className="form-group row">
				<div className="col-sm-3">
						<FormLabel name ="Allow page size" toolTip={Tooltip.customerPageSize}  />
				</div>
								
					<div className="col-sm-9 ">
					<div className="checkbox-fade fade-in-success">
					<label>
				<input type="checkbox" value={data.allowPageSize} name="allowPageSize" checked={data.allowPageSize==='true'?true:false} onChange={handleChangeCheck} />
						<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
					</label>
				</div>
						</div>
				</div>
				
				<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Page Size"  toolTip={Tooltip.customerPageSize} />
		</div>
						
			<div className="col-sm-4 ">
					<input type="number" className="form-control" onChange={handleChange}  name="pageSize" value={data.pageSize} />
						</div>
		</div>

				<div className="form-group row">
				<div className="col-sm-3">
						<FormLabel name ="Page Size Option" toolTip={Tooltip.pageSizeOption} />
				</div>
								
					<div className="col-sm-9 ">
					<input type="text" className="form-control" name="pageSizeOption" 
					value={data.pageSizeOption}  onChange={handleChange}  />

						</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Price Range" toolTip={Tooltip.priceRange} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" name="priceRange" 
						value={data.priceRange}  onChange={handleChange}  />

							</div>
					</div>
</>:''}

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={data.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>


							{panel.displayFilter?	<div className="row  " >
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, filter:!panel.filter})}>
			<h5><i className="fa fa-cog"></i> Filter List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.filter?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.filter?		<div className="card-block" id="specification-attributes">
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                       
					<div className="row">
					<div className="table-responsive">
		 {<TableFilterRecord data={JSON.parse(data.filter).sort(compare)} columns={tableFilterHeader}  />} 
		</div>
						</div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}

							



				{panel.seoDisplay?			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, seo:!panel.seo})}>
			<h5><i className="fa fa-search-plus"></i> SEO</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.seo?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.seo?		<div className="card-block" >
		
 <div  className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Name" toolTip={Tooltip.seoName} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="text" className="form-control" name="pageName" 
						value={data.pageName} onChange={handleChange} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Title" toolTip={Tooltip.metaTitle} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaTitle" 
						value={data.metaTitle} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Keyword" toolTip={Tooltip.metaKeywords} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaKeywords" 
						value={data.metaKeywords} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Description" toolTip={Tooltip.metaDescription} />
					</div>
									
						<div className="col-sm-9 ">
						
						<textarea type="text" className="form-control" rows="5" name="metaDescription" 
						value={data.metaDescription} onChange={handleChange}>{data.metaDescription}</textarea>
					</div></div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}



							{panel.walletDisplay?			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, wallet:!panel.wallet})}>
			<h5><i className="fa fa-money"></i> Wallet</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.wallet?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.wallet?		<div className="card-block" >
		
 <div  className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Wallet Balance" toolTip={'Vendor Account balance'} />
					</div>
									
						<div className="col-sm-9 ">
				comming soon
							</div>
					</div>

	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}



							{panel.productDisplay?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, product:!panel.product})}>
			<h5><i className="fa fa-th-list"></i> Products</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.product?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.product?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					<div className="table-responsive">
		{<TableVendorProduct data={vendorProduct} columns={tableVendorProductHeader}  />}
		</div>
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}


			{panel.noteDisplay?		<div className="row " id="vendorNote">
			<div className="col-sm-12">
								
	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, note:!panel.note})}>
			<h5><i className="fa fa-sticky-note-o"></i> Vendor Notes</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.note?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.note?		<div className="card-block" >
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                             

					<div className="row">
					<div className="table-responsive">
		 {<TableVendorNoteRecord data={JSON.parse(data.notes).sort(compare)} columns={tableVendorNoteHeader}  />} 
		</div>
						</div>


					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}

				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 