import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts} from '../../components/notify'
import {ServerUrl, Token, config} from '../../components/include'
import axios from 'axios'

import { WarningModal } from "../../components/notify";
import { useParams } from 'react-router-dom'
import { convertDate } from '../../components/globalFunction'

 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [group, setGroup] = useState([])
	const [content, setContent] = useState([])
	
	const fetchGroup =()=>{
		var sql ="Select ID, groupName from tbl_groups order by groupName ASC" ;
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append("jwt", Token);
		let url = ServerUrl+'/fetchBySql_controller.php';
		axios.post(url, fd, config).then(result=>{  
			setGroup(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}
    const handleDelete =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
		

		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'code')
		fd.append('data', params.code)
		fd.append("jwt", Token);
	let url = ServerUrl+'/delete_controller.php?tablename=tbl_newsletter';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/newsletter', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
		
		
}

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select * from tbl_newsletter where code = '" +params.code+"'";
        
        
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append("jwt", Token);
        axios.post(url, fd, config).then(result=>{ 
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
	const [defaultUrl, setDefaultUrl] = useState('');
	
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}

	
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		    
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append("jwt", Token);
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_newsletter';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
        let errors = {};   
        
        if(!values.subject){
            errors.subject =Tooltip.required;
        }
		if(!values.newsName){
            errors.newsName =Tooltip.required;
        }
		if(!values.body){
            errors.body =Tooltip.required;
        }
        return errors;
        }

		
		
useEffect(()=>{
	fetchContent()
	fetchGroup()
},[]);	
	
        return (<Template 
			menu="Communications"
		  submenu="Newsletter">
			
		   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		 
		 <div  className="main-body">
			  <div className="page-wrapper">
		
		<PageHeader title="Edit Newsletter" description='Update Newletter  Information here' icon='fa fa-commenting-o'>
		<li className="breadcrumb-item"><a href="/newsletter">Template</a> </li>
				</PageHeader>

{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-inverse btn-sm" onClick={(e)=>handleSubmit(e, '/newsletter')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

<button type="button" onClick={(e)=>handleSubmit(e, '/newsletter/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;

<button type="button" className="btn btn-success btn-sm"><i className="fa fa-envelope-o"></i> Send Mass eMail</button>&nbsp;
	<a href="/newsletter" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDelete()} mID={'delAll'} /> 


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Newsletter info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.newsname} />
					</div>
									
						<div className="col-sm-9 ">

							<div className="input-group">
						<input type="text" onChange={handleChange}  name="newsName" value={data.newsName} className={errors.newsName  ? 'form-control formerror' : 'form-control '} />
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.newsName}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Subject" toolTip={Tooltip.subject} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="subject" value={data.subject} className={errors.subject  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.subject}</span>
							</div>
					</div>
					<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Body" toolTip={Tooltip.body} />
		</div>
						
			<div className="col-sm-9 ">
			<div className="input-group">
			<textarea type="text" className={errors.body  ? 'form-control formerror' : 'form-control '} rows="8" name="body" value={data.body} onChange={handleChange}>{data.body}
			</textarea>
			<span className="required-span"> *</span>
				</div>
				<span className="error">{errors.body}</span>
		</div>
		</div>
			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Planned date of sending" toolTip={Tooltip.planDate} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="date" onChange={handleChange}   name="planDate" value={convertDate(data.planDate)} className='form-control'/>
							
							</div>
							</div>
			</div>


			<div className="form-group row">
				<div className="col-sm-3">
						<FormLabel name ="Limited to customer role" toolTip={Tooltip.limitedRole} />
				</div>
								
					<div className="col-sm-9 ">
				<div className="input-group">
				<select  name="receiverId" value={data.receiverId} className="form-control" onChange={handleChange}>
				<option value="all">All</option>
				{group&&group.map((node, id)=>{
					return <option key={id} value={node.ID}>{node.groupName}</option>
				})}
				</select>
						
						</div>
						</div>
				</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


			

	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-cart-plus"></i> Usage History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>	<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                  

       	 <div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
		
            </div>
           
    
    </div></div>
					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>




				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 