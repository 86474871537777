import React, { useState } from "react";
import Template from "../../components/template";
import PageHeader from "../../components/pageheader";
import { LoadEffect } from "../../components/loader";
import Tooltip from "../../components/toolTips";
import { Alerts } from "../../components/notify";
import { ServerUrl } from "../../components/include";
import axios from "axios";
import { staffCode, config, Token } from "../../components/include";

const Import = () => {
  const [notice, setNotice] = useState({
    isLoading: false,
  });
  const [errors, setErrors] = useState({});
  let initialState = {
    
    categories:[],
    manufacturers:[],
    customerRoles:[],
    limitedStore:[],
    tags:[],
    isPublished:true,
    gtin:'',
    mPartNumber:'',
    showOnHome:true,
    displayOrder:1,
    productType:'simple',
    vendor:'',
    isCustomerReveiw:true,
    availableStart:'0000-00-00T00:00',
    availableEnd:'0000-00-00T00:00',
    isNew:false,
    isNewStart:'0000-00-00T00:00',
    isNewEnd:'0000-00-00T00:00',
    adminComment:'',
    staffCreate:staffCode,
    price:0,
    oldPrice:0,
    costPrice:0,
    isBuyButonDisabled:false,
    isWishlistDisabled:false,
    isPreorder:false,
    preorderStart:'0000-00-00T00:00',
    isCallforPrice:false,
    isCustomerPrice:false,
    minAmount:0,
    maxAmount:0,
    discount:0,
    isTaxExempt:false,
    taxCategory:'',	
    isShippable:false,
    shippings:[{
        weight:0,
        height:0,
        length:0,
        width:0,
        isFreeShipping:false,
        deliveryPeriod:'',
        isShippedSeperate:false,
        shippingCharge:''
    }],
    inventoryMethod:'0',
    inventory:[{
        wharehouse:'0',
        isMultipleWharehouse:false,
        isDisplayAvailability:false,
        isDisplayStockQuantity:false,
        minQuantity:0,
        lowStockActivity:'0',
        notifyAdminNum:'0',
        isBackInStock:true,
        availableRange:'0',
        minCart:1,
        maxCart:'',
        allowedQty:1,
        isReturnable:false
    }]
}
const [content, setContent] = useState(initialState)
  const [excelFile, setExcelFile] = useState({
    productExcel: "",
    fileName: "",
  });

  const handleDownloadSample = () => {
    let Url =
      ServerUrl + "/downloadCsvSample_controller.php?tablename=tbl_products";
    window.open(Url, "_blank");
  };

  const handleFile = function (fieldName) {
    return function (newValue) {
      setErrors({ ...errors, [fieldName]: "" });
      readURL(newValue);
    };
  };

  const readURL = (input) => {
    let displayMessage = "";
    var fileName = document.getElementById("excelFile").value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile === "csv" || extFile === "CSV") {
      //TO DO

      var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
      if (FileSize > 1) {
        displayMessage =
          " File uploaded is more than maximum size Allow. it exceeds 1 Mb";
        document.getElementById(input.target.name).value = "";
      } else {
        if (input.target.files && input.target.files[0]) {
          //Check if input has files.
          const value = input.target.files[0];
          setExcelFile({
            ...excelFile,
            productExcel: value,
            fileName: value.name,
          });
        }
      }
    } else {
      displayMessage = "Only excel  files are allowed with maximum of 1mb";
      document.getElementById(input.target.name).value = "";
      setExcelFile({
        ...excelFile,
        productExcel: "",
        fileName: "Please select .csv files",
      });
    }

    if (displayMessage.length > 0) {
      Alerts("Error!", "danger", displayMessage);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!excelFile.productExcel) {
      errors.productExcel = "You have not choose any file to upload";
      Alerts("Error!", "danger", errors.productExcel);
    } else {
      setNotice({ ...notice, isLoading: true });
      let formData = content;
      const fd = new FormData();
      fd.append('jwt', Token)
      for(let k in formData){
        fd.append(k, formData[k])
      }
      fd.append('shipping', JSON.stringify(content.shippings, null, 2))
      fd.append('inventories', JSON.stringify(content.inventory, null, 2))
      fd.append("excelFile", excelFile.productExcel);
     
      let url =
        ServerUrl + "/importExcel_controller.php?tablename=tbl_products";
      axios
        .post(url, fd, config)
        .then((response) => {
          if (response.data.type === "success") {
            Alerts("Success!", "success", response.data.message);
          } else {
            Alerts("Error!", "danger", response.data);
          }
        })
        .catch((error) => {
          Alerts("Error!", "danger", error.message);
        })
        .finally(() => {
          setNotice({ ...notice, isLoading: false });
          document.getElementById("excelFile").value = "";
          setExcelFile({
            ...excelFile,
            productExcel: "",
            fileName: "Please select .csv files",
          });
        }); 
    }
  };

  return (
    <Template 
    menu="Catalog"
submenu="Products">
  
 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }

<div  className="main-body">
        <div className="page-wrapper">

<PageHeader title="Import Products" description='Upload bulk product from excel file' icon='feather icon-book'>

<li className="breadcrumb-item"><a href="/products">Products</a> </li>

            </PageHeader>

          {/* The Page Body start here */}
          <div className="page-body">
            <div className="row  ">
              <div className="col-sm-12">
                <div className="card z-depth-0">
                  <div className="card-header ">
                    <h5>
                      <i className="fa fa-upload"></i> Upload Bulk Products
                    </h5>
                    <div className="card-header-right">
                      <ul className="list-unstyled card-option">
                        <li>
                          <i className="feather icon-maximize full-card"></i>
                        </li>
                        <li>
                          <i className="feather icon-minus minimize-card"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-block">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card z-depth-0">
                          <section className="card-block ">
                            <h4 className="text-bold">
                              Prepare your products file:
                            </h4>
                            <div className="row">
                              <div className="col-sm-3 text-right bold text-red">
                                <u>Required Columns</u>
                              </div>
                              <div className="col-sm-7 bold">
                                <u>Allowed values</u>
                              </div>
                              <div className="col-sm-3 text-right text-bold">
                                Code
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.sku} <br />
                              </div>
                              <div className="col-sm-3 text-right text-bold">
                                Product Name
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.productName} <br />
                              </div>
                              <div className="col-sm-3 text-right text-bold">
                                Price
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.price} <br />
                              </div>
                              <div className="col-sm-3 text-right text-bold">
                                Display Order
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.displayOrder}
                                <br />
                                <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold text-yellow">
                                <u>Optional Columns</u>
                              </div>
                              <div className="col-sm-7 text-bold">
                                <u>Allowed Values</u>
                              </div>
                              <div className="col-sm-3 text-right text-bold">
                                Short Description
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.shortDescription}
                                <br />
                              </div>
                              <div className="col-sm-3 text-right text-bold">
                                Full Description
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.fullDescription}
                                <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                Old Price
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.oldPrice} <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                Cost Price
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.costPrice} <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                Quantity
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.stockQuantity} <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                Published
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.published} <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                GTIN
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.gtin} <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                Part Number
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.partNumber} <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                Show on Homepage
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.showOnHome} <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                isCustomerReveiw
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.isReveiw} <br />
                              </div>
                              <div className="col-sm-3 text-right text-bold">
                                isBuyButonDisabled
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.buyButon} <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                isWishlistDisabled
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.wishList} <br />
                              </div>
                              <div className="col-sm-3 text-right text-bold">
                                Is Preorder
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.preorder} <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                Admin Comment
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.adminComment} <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                isCallforPrice
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.callforPrice} <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                Is TaxExempt
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.isTaxExempt} <br />
                              </div>

                              <div className="col-sm-3 text-right text-bold">
                                Page Name
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.seoName}
                                <br />
                              </div>
                              <div className="col-sm-3 text-right text-bold">
                                Meta Title
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.metaTitle}
                                <br />
                              </div>
                              <div className="col-sm-3 text-right text-bold">
                                Meta Keywords
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.metaKeywords}
                                <br />
                              </div>
                              <div className="col-sm-3 text-right text-bold">
                                Meta Description
                              </div>
                              <div className="col-sm-7">
                                {Tooltip.metaDescription}
                                <br />
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>

                    <div className="row " >
        <div className=" card ">
            <div className="well"><h4 className="alert-heading ">Download CSV File</h4>
                        
                        
            <form method='POST' action={ServerUrl + "/downloadCsvSample_controller.php?tablename=tbl_products"} target='_blank'>
                <input type='hidden' name='jwt' value ={Token} />
                
        <button type="submit" className="btn btn-warning btn-sm pull-right"> <i className="fa fa-download"></i> Download Sample File</button>
        
        </form> 
                    

                        <p>
                          <span className="text-info">
                            The first line in downloaded Excel file should
                            remain as it is. Please do not change the order of
                            columns.
                          </span>
                          <br />
                          <span className="text-success">
                            The correct column order is (
                            <b>
                              code, productName, price, oldPrice, costPrice,
                              quantity, shortDescription, fullDescription,
                              isPublished, gtin, mPartNumber, showOnHome,
                              displayOrder, isCustomerReveiw, adminComment,
                              isBuyButonDisabled, isWishlistDisabled,
                              isPreorder, isCallforPrice, isTaxExempt, Page
                              Name, Meta Title, Meta Keywords, Meta Description
                            </b>
                            )
                          </span>
                          <span className="text-primary">
                            &amp; you must follow this.
                          </span>
                        </p>

 <a href="https://www.ablebits.com/office-addins-blog/2014/04/24/convert-excel-csv/" className="bold" target="_blank">Also, Click to seeHow to convert Excel file to CSV</a>
                      </div>
                    </div>
                  </div> </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="card text-white bg-primary shadow-primary">
                            <section className="col-md-12 ">
                              
            <h4 className="alert-heading ">Upload Excel File</h4>
                              {Tooltip.uploadHelp}
                              <hr />
                              <br />
                              <div className="form-row">
                                <input
                                  id="excelFile"
                                  name="excelFile"
                                  onChange={handleFile("excelFile")}
                                  className="form-control"
                                  type="file"
                                  accept=".csv"
                                />
                                <div className="note">{excelFile.fileName}</div>{" "}
                              </div>

                              <span style={{ color: "red" }}>
                                {errors.productExcel}
                              </span>

                              <hr />
                              <button
                                type="button"
                                id="submit"
                                onClick={handleSubmit}
                                className="btn  btn-success pull-right"
                              >
                                <i className="fa fa-save"></i> Upload from Excel
                              </button>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* The Page Body close here */}
        </div>
      </div>
    </Template>
  );
};

export default React.memo(Import);
