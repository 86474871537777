import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import { API_URL, config, IMAGES_URL} from '../../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'
 const Edit =()=> {
	 let myRef = useRef()
	 const [defaultUrl, setDefaultUrl] = useState('');
	
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	
	

	   const [image, setImage] = useState('');

	  
	   const handleDeleteWithImage =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
	
		setNotice({...notice,  isLoading: true}) 

	let url = API_URL+'/slider/delete/'+params.code;
	
	axios.get(url, config).then(response=>{ 
		   if(response.content.type ==='success'){
			  Alerts('Saved!', 'success', response.content.message)
			  window.open('/cms/sliders', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice,  isLoading: false  })
		  }) 	
}

const setLogo =()=>{
	const img = document.getElementById('viewLogo');
	img.src = IMAGES_URL+"/noImage.png" 
	document.getElementById('logo').value = '';
	setImage('')
}
const removeImageUrl =()=>{
	setContent({...content, image_url:''});

	const img = document.getElementById('viewLogo');
	img.src = IMAGES_URL+"/noImage.png" 
	document.getElementById('logo').value = '';
	setImage('')
}



const fetchContent =()=>{
	setNotice({...notice, isLoading: true  });
   
	let url = API_URL+'/sliders/display/one/'+params.code;
	
	axios.get(url, config).then(result=>{ 
		if(result.data.data.length!==0){
		setContent(result.data.data)
	}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, 
			isLoading: false
		});
	}) 

}


	

	const handleChange =(event)=>{  
		let {name, value} = event.target;
		if(name==='status'){
			value =value ==='Active'?'Inactive':'Active'
		}	
		setContent({...content, [name]:value});
		setErrors({...errors, [name]:''});
	}
 
    
const  handleFile = function(fieldName){
	return function(newValue){                 
		const value = newValue.target.files[0]
	  // setImage(value)
   readURL(newValue);
   }
   
  } 

   const readURL = (input)=>{       
	let displayMessage = '';
	  var fileName =  document.getElementById('logo').value;
	var idxDot = fileName.lastIndexOf(".") + 1;
	var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
	if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
		//TO DO    
		
		 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
	if (FileSize > 3) {
		displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 3Mb Kb';
	   document.getElementById(input.target.name).value = '';
	   setImage('')
	} else {
		if (input.target.files && input.target.files[0]) { //Check if input has files.
		  var reader = new FileReader();//Initialize FileReader.
		  reader.onload = function (e) {
				   const newContent =JSON.parse(JSON.stringify(content))
					 newContent.imageUrl = '' 
					 setContent(newContent);
					 
		  const img = document.getElementById('viewLogo');
					 img.src = e.target.result             
					
		  };
		  reader.readAsDataURL(input.target.files[0]); 
		  setImage(input.target.files[0])
		  
		}
	}
	}else{
		displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 3Mb'
	   document.getElementById(input.target.name).value = '';
	   setImage('');
	}   
	
	if(displayMessage.length > 0){
	
		Alerts('Error!', 'danger', displayMessage)
	  }
} 

    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		    
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		 
		  if(image!==''){
			fd.append("imageFile", image);
		}
		let url = API_URL+'/slider/update/'+params.code;
		  axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.status ===200){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
        let errors = {};   
		if(!values.action_link){
            errors.action_link =Tooltip.required;
        }
       
       
        return errors;
		}
		
	

useEffect(()=>{
	fetchContent()
},[]);	
	
        return (<Template 
			menu="Content Management"
		submenu="Sliders">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Edit Slide" description='Update slide details' icon='feather icon-layers'>
  <li className="breadcrumb-item"><a href="/cms/sliders">Sliders</a> </li>
					</PageHeader>


{/* The Page Body start here */}
<div  className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-inverse btn-sm" onClick={(e)=>handleSubmit(e, '/cms/sliders')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

<button type="button" onClick={(e)=>handleSubmit(e, '/cms/slider/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	<a href="/cms/sliders" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDeleteWithImage(content.imageUrl)} mID={'delAll'} /> 


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Slider info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
			
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Title" toolTip={'Slider Title'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="title" value={content.title} className='form-control '/>
							</div>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							
					<FormLabel name ="Caption" toolTip={'Slider Text'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="caption" value={content.caption} className='form-control '/>
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Slide Url" toolTip={'Slider link to open when click'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="action_link" value={content.action_link} className={errors.action_link  ? 'form-control formerror' : 'form-control '}/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.action_link}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange}   name="display_order" value={content.display_order} className='form-control'/>
							
							</div>
							</div>
					</div>


					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Status" toolTip={'Check to appear on home page'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.status} name="status" checked={content.status==='Active'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


	
	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Picture" toolTip={Tooltip.picture} />
					</div>
									
						<div className="col-sm-9 ">
						
						<div className="form-group">
							<img className="img-70" id="viewLogo" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+"/noImage.png"}} src={IMAGES_URL+"/sliders/"+content.image_url} alt="Picture "  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
			{content.image_url?	<button type="button" onClick={removeImageUrl} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>	:''}

			{image?	<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>	:''}

										</div>
							</div>
					</div>
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

				</div>
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 