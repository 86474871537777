import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, FormatNumber, Currency, config, Token, API_URL} from '../../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { convertDate } from '../../components/globalFunction'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	
    const handleDelete =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
		

		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'codeId')
		fd.append('data', params.code)
		fd.append("jwt", Token);
	let url = ServerUrl+'/delete_controller.php?tablename=tbl_promo_code';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/promo', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
		
		
}


const fetchContent  =()=>{
	setNotice({...notice, 
		isLoading: true
	});
   
	let url = API_URL+'/promo/display/one/'+params.code
	axios.get(url, config).then(result=>{ 
		if(Array.isArray(result.data.data)){
		
			setContent(result.data.data)
		}else{
			setContent([])
		}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, 
			isLoading: false
		});
	}) 
}

  
	const [defaultUrl, setDefaultUrl] = useState('');
	
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;
		if(name==='status'){
			value =value ==='Active'?'Inactive':'Active'
		}	
		if(name==='percentage' && value.length>2){
			value = value.slice(0,2)
		}
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}

	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		    
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
			 let url = API_URL+'/promo/update/'+params.code;
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.promo_code){
			errors.promo_code =Tooltip.required;
		}
		if(!values.percentage){
			errors.percentage =Tooltip.required;
			
		}
		return errors;
		}




		const tableHeader = [
			{dataField: 'used',  text: 'Used',  sort: true, editable: false, formatter:(cell)=><span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
			{dataField: 'created_at',  text: 'Order',  sort: true, editable: false},
			{dataField: 'ID',  text: 'Used Amount ('+Currency+')',  sort: true, editable: false, formatter:(cell)=>FormatNumber(cell)},
				   
				 ];
				
				 const TableRecord=(props)=>{
					 const { SearchBar } = Search;  
					 const customTotal = (from, to, size) => (
						<span >&nbsp;Showing { from } to { to } of { size } items</span>
					  );
					 const options = {
						showTotal: true,
					  paginationTotalRenderer: customTotal,
					  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
					  { text: 'All', value: props.data.length }]
					   
					  };
					 return  <ToolkitProvider search columnToggle 
								 keyField='ID' data={ props.data } columns={ props.columns } >
									 
									 {
										 props => (
										 <div >
										
								 <BootstrapTable 
									{ ...props.baseProps }
									striped
								hover
								condensed
								noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
							pagination={paginationFactory(options) }
						  
											 />
										 </div>
										 )
									 }
				  </ToolkitProvider>
				  }
				  

useEffect(()=>{
	fetchContent()
},[]);	
	
        return (<Template menu="Promotions"
        submenu="Promo Code"
        pageUrl="/promo/edit/">
            
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">


  <PageHeader title="Edit promo code " description='Update promo code information' icon='feather icon-package'>
  <li className="breadcrumb-item"><a href="/promo">Promo Code</a> </li>
					</PageHeader>



{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, '/promo')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

<button type="button" onClick={(e)=>handleSubmit(e, '/promo/edit/'+params.code)} className="btn btn-inverse "><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;

	<a href="/promo" className="btn btn-warning "><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger " data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDelete()} mID={'delAll'} /> 


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Promo Code Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Code" toolTip={"Code use during checkout"} />
					</div>
									
						<div className="col-sm-9 ">


					
							<div className="input-group">
						<input type="text" onChange={handleChange} disabled={true}  name="promo_code" value={data.promo_code} className={errors.promo_code  ? 'form-control formerror' : 'form-control '}/>
						<span className="input-group-addon"  id="basic-addon">Generate Code</span>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.promo_code}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Percentage" toolTip={"The code value in %"} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="number" autoComplete="off" onChange={handleChange}   name="percentage" max='2' value={data.percentage} className={errors.percentage  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.percentage}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Created Date" toolTip={"Date created"} />
					</div>
									
						<div className="col-sm-9 ">
							{convertDate(data.created_at)}
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Expiry Date" toolTip={"Code expiry date"} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="date" onChange={handleChange}   name="expiry_date" value={data.expiry_date} className='form-control'/>
							
							</div>
							</div>
					</div>


					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Status" toolTip={'Determine if the code is activated'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.status} name="status" checked={data.status==='Active'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-th-list"></i> Usage History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                  

       	 <div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
		  <TableRecord data={JSON.parse(data.usage_history)}  columns={tableHeader}  />
            </div>
           
    
    </div></div>
					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>




				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 