import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import { LoadEffect } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts, WarningModal} from '../components/notify'
import {ServerUrl, Token, config} from '../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'
 const EmailApi =()=> {
	 let myRef = useRef()
	 const [defaultUrl, setDefaultUrl] = useState('');
	
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
		isDataFetching: false,
		token:false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select * from tbl_email_api where ID = 1";
        
          
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
		fd.append("jwt", Token);
        axios.post(url, fd, config).then(result=>{ 
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
  const handleChangeTest = event =>{		
	const {name, value} = event.target;		
	setTestSms({...testSms, [name]:value})
	setErrors({...errors, [name]:''})
}
const [testSms, setTestSms] = useState({
	email:'',
	message:'',
	response:''

})
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
 

    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		     
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append("jwt", Token);
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_email_api';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	

const sendTest=()=>{

}

	
useEffect(()=>{
	fetchContent()
},[]);	
	
        return (<Template 
			menu="Communications"
		  submenu="Email API Settings">
			
		   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		 
		 <div  className="main-body">
			  <div className="page-wrapper">
		
		<PageHeader title="Email API Settings" description='Configure Email API Information here' icon='fa fa-commenting-o'>
		
				</PageHeader>


<div id="testSmsModalBox" className="modal fade in" role="dialog" aria-hidden="false" >
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                  <h4 className="modal-title">Send Test Mail</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
                <div className="modal-body">
                    <div className="row form-group">
                        <label className="col-md-3 control-label text-left">Receivers mail</label>
                        <div className="col-md-9">
                            <input type="text" name="email" value={testSms.email} onChange={handleChangeTest} className={errors.email ? 'form-control formerror' : 'form-control'} placeholder="Email" />
                        </div>
                    </div>
                    <div className="row form-group">
                        <label className="col-md-3 control-label text-left">Message</label>
                        <div className="col-md-9">
                            <textarea type="text" value={testSms.message} onChange={handleChangeTest} className={errors.message ? 'form-control formerror' : 'form-control'} name="message"  placeholder="Message">{testSms.message} </textarea>
                            <span id="res_messag">{testSms.response}</span>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={sendTest} className="btn btn-success" id="btnSendSms">Send</button>
                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>


{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-inverse btn-sm" onClick={submit} ><i className="fa fa-save"></i> Update</button>&nbsp;

<button type="button" data-toggle="modal"  data-target='#testSmsModalBox' id="openModal" className="btn btn-success btn-sm"><i className="fa fa-copy"></i> Test Email</button>



			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i> Email API info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                    
	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="API Token" toolTip={'API token provided by the mailer platform'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="apitoken" value={data.apitoken} className='form-control ' />
							</div>
							</div>
	</div>
				
	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Sender email" toolTip={'Sender Email'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="senderEmail" value={data.senderEmail} className={errors.senderEmail  ? 'form-control formerror' : 'form-control '}/>
							</div>
							</div>
	</div>


	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="API Password" toolTip={'Password associated with this API'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="password" onChange={handleChange}   name="password" value={data.password} className={errors.password  ? 'form-control formerror' : 'form-control '}/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.password}</span>
							</div>
	</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Username" toolTip={'Email or Username'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="username" value={data.username} className='form-control'/>
							
							</div>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Global Header" toolTip={'Enter Email Header'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<textarea rows="20" type="text" onChange={handleChange}   name="globalHeader" value={data.globalHeader} className='form-control'>{data.globalHeader}</textarea>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Global Footer" toolTip={'Enter Email Footer'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<textarea rows="20" type="text" onChange={handleChange}   name="globalFooter" value={data.globalFooter} className='form-control'>{data.globalFooter}</textarea>
							
							</div>
							</div>
					</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(EmailApi) 