
const Tooltips = {
    required:'This field is required',
    manufacturerName: 'The brand\'s name e.g Apple, HP',
    manufacturerDescription:'The description of the  brand',
    manufacturerLogo:'The brand logo',
    publishManufacturer:'To publish this brand (Visible in store). Uncheck to unpublish (brand not available in store)',
    customerPageSize:'Weather customers are allowed to select the page size from predefined list of options',
    manufacturerPageSize:'Set the page size for products in this brand e.g. \'4\' products per page.',
    pageSizeOption:'Comma separated list of page size options (e.g. 10, 15, 30, 200). First option is the default page size if none are selected.',
    priceRange:'Define the price ranges for the store price range filter. Separate ranges with a semicolon e.g. \'0-199;200-300;301-\'; (301- means 301 and over).',
    displayOrder:'Set the display order. 1 represents the top of the list.',
    discount: 'Select discounts to apply. You can manage discounts by selecting Discounts from the Promotions menu.',
    exclusive:'Select groups for which this will be shown. Leave empty if you want this  to be visible to all users.',
    limitedStore:'Option to limit this to a certain store. If you have multiple stores, choose one or several from the list. If you don\'t use this option just leave this field empty.',
    seoName:'Set a search engine friendly page name e.g. \'the-best-seller\' to make your page URL \'http://www.yourStore.com/the-best-seller\'. Leave empty to generate it automatically.',
    metaTitle:'Override the page title. The default is the name.',
    metaKeywords:'Meta keywords to be added to this  page header. use comma to seprate keyword (e.g. Hp, HP Products, etc)',
    metaDescription:'Meta description to be added to this page header.',
    published:'Search by a \'Published\' property.',
    deleteWarning: 'Please select at least one record to delete',
    importExcel:'Imported files are distinguished by ID. If the ID already exists, then its corresponding manufacturer will be updated. You should not specify ID (leave 0) for new manufacturers. Import requires a lot of memory resources. That\'s why it\'s not recommended to import more than 500 - 1,000 records at once. If you have more records, it\'s better to split them to multiple Excel files and import separately.',
    uploadHelp: 'To upload Excel file with .csv extention, please note that any duplicate record will be skipped. Already imported Record will be skipped as well. Import requires a lot of memory resources. That\'s why it\'s not recommended to import more than 500 - 1,000 records at once. If you have more records, it\'s better to split them to multiple Excel files and import separately.',
    categoryName:'The category\'s name.',
    IsPublished:'Search by \'Published\' property',
    categoryDescription:'The description of the category.',
    showOnHome:'Check if you want to show this on home page.',
    parentCategory:'Select a parent category for this category. Choose root to make this the root level category.',
    picture:'Add picture to this',
    published:'To publish this (Visible in store). Uncheck to unpublish ( not available in store)',
    vendor:'Choose a vendor associated to this product. This can be very useful when running a multi-vendore store to keep track of goods associated with vendor',
    includeTopMenu:'Display in the top menu bar. If this category is a subcategory, then ensure that its parent category also has this property enabled.',
    attributeName:'The name of the attribute',
    attributeDescription:'The description of the product attribute.',
    specificationName:'The name of the specification attribute',
    checkoutName: 'The name of the checkout attribute.',
    controlType:'Choose how to display your attribute values.',
    isRequired:'When an attribute is required, the customer must choose an appropriate attribute value before they can continue.',
    shippedAble:'An option indicating whether shippable products are required in order to display this attribute.',
    isTaxExempt:' Determines whether this option is tax exempt (tax will not be applied to this option at checkout).',
    taxCategory:'The tax classification for this attribute (used to calculate tax). You can manage tax categories by selecting Configuration > Tax > Tax Categories.',
    defaultValue:'Enter default value for attribute.',
    reviewProduct:'The name of the product that the review is for.',
    reveiwStore: 'A store name in which this review was written.',
    customerReview: 'The customer who created the review.',
    reviewTitle: 'The title of the product review.',
    reveiwText: 'The review text.',
    replyText:'The reply text (by a store owner). If specified, then will be visible to a customer. Leave empty to ignore this functionality.',
    rating:'The customer\'s product rating.',
    isApproved:'Is the review approved? Marking it as approved means that it is visible to all your site\'s visitors.',
    dateCreate:'The date/time that the review was created.',
    fromDate:'The create from date for the search',
    toDate:'The creattion to date for the search',
    approved:'Search by \' approved \' property',
    store:' Search by store',
    productName:'The name of the product.',
    shortDescription:'Short description is the text that is displayed in product list i.e. сategory / manufacturer pages.',
    fullDescription: 'Full description is the text that is displayed in product page.',
    sku:'Product stock keeping unit (SKU). Your internal unique identifier that can be used to track this product.',
    category:'Choose categories. You can manage product categories by selecting Catalog > Categories.',
    productManufacturer:'Choose the manufacturer. You can manage manufacturers by selecting Catalog > Manufacturers.',
    tags:'Product tags are the keywords for product identification. The more products associated with a particular tag, the larger it will show on the tag cloud.',
    gtin:'Enter global trade item number (GTIN). These identifiers include UPC (in North America), EAN (in Europe), JAN (in Japan), and ISBN (for books).',
    partNumber:'The manufacturer\'s part number for this product.',
    productType:'Product type can be simple or grouped. In most cases your product will have the Simple product type. You need to use Grouped product type when a new product consists of one or more existing products that will be displayed on one single product details page.',
    visibility:'Check it if you want the product to be on catalog or search results. You can uncheck this box to hide associated products from catalog and make them accessible only from grouped product details page.',
    customerRole:'Choose one or several customer roles i.e. administrators, vendors, guests, who will be able to see this product in catalog. If you don\'t need this option just leave this field empty.',
    otherProductRequire:'Automatically add these products to the cart',
    isReveiw:'Check to allow customers to review this product.',
    availableStart:'The start of the product\'s availability in Coordinated Universal Time (UTC).',
    availableEnd:'The end of the product\'s availability in Coordinated Universal Time (UTC).',
    markNew:'Check to mark the product as new. Use this option for promoting new products.',
    markNewStart:'Set Product as New from Date in Coordinated Universal Time (UTC).',
    markNewEnd:'Set Product as New to Date in Coordinated Universal Time (UTC).',
    adminComment:'This comment is for internal use only, not visible for customers.',
    price:'The price of the product. You can manage currency by selecting General >Settings -> Currencies.',
    oldPrice:'The old price of the product. If you set an old price, this will display alongside the current price on the product page to show the difference in price.',
    costPrice:'Product cost is a prime product cost. This field is only for internal use, not visible for customers.',
    buyButon:'Check to disable the buy button for this product. This may be necessary for products that are available upon request',
    wishList:'Check to disable the wishlist button for this product.',
    preorder:'Check if this item is available for Pre-Order. It also displays \'Pre-order\' button instead of \'Add to cart\'.',
    preorderStart:'The availability start date of the product configured for pre-order in Coordinated Universal Time (UTC). \'Pre-order\' button will automatically be changed to \'Add to cart\' at this moment.',
    callforPrice:'Check to show \'Call for Pricing\' or \'Call for quote\' instead of price.',
    customerPrice:'An option indicating whether customer should enter price.',
    minAmount:'Enter a minimum amount.',
    maxAmount:'Enter a maximum amount.',
    amountInProduct:'Enter an amount in product.',
    productUnit:'Enter a unit of product.',
    referenceAmount:'Enter a reference amount.',
    referenceUnit:'Enter a reference unit.',
    productDiscount:'Select discounts to apply to this product. You can manage discounts by selecting Discounts from the Promotions menu.',
    isShipped:'Check if the product can be shipped. You can manage shipping settings by selecting Configuration > Shipping.',
    weight:'The product weight (Please indicate whether lb(s), kg(s), gram(s), or Ounce(s)).',
    length:'The product length  (Please indicate whether inches, feet, meters, or millimeters).',
    width:'The product width in (Please indicate whether inches, feet, meters, or millimeters).',
    height:'The product height in (Please indicate whether inches, feet, meters, or millimeters).',
    isFreeShipping:'Check if this product comes with FREE shipping.',
    shipSeperate:'Check if the product should be shipped separately from other products (in single box). But notice that if the order includes several items of this product, all of them will be shipped in single box.',
    shippingCharge:'The additional shipping charge.',
    deliveryDate:'Choose a delivery date which will be displayed in the public store. You can manage delivery dates by selecting Configuration > Shipping > Dates and ranges.',
    inventoryMethod:'Select inventory method. There are three methods: Don’t track inventory, Track inventory and Track inventory by attributes. You should use Track inventory by attributes when the product has different combinations of these attributes and then manage inventory for these combinations.',
    stockQuantity: 'The current stock quantity of this product.',
    wharehouse:'Choose the warehouse which will be used when calculating shipping rates. You can manage warehouses by selecting Configuration > Shipping > Warehouses.',
    multipleWharehouse:'Check if you want to support shipping and inventory management from multiple warehouses.',
    displayAvailability:'Check to display stock availability. When enabled, customers will see stock availability.',
    displayStockQuantity:'Check to display stock quantity. When enabled, customers will see stock quantity.',
    minimumQuantity:'If you have enabled \'Manage Stock\' you can perform a number of different actions when the current stock quantity falls below (reaches) your minimum stock quantity.',
    lowStoreActivity:'Action to be taken when your current stock quantity falls below (reaches) the \'Minimum stock quantity\'. Activation of the action will occur only after an order is placed.',
    notifyAdmin:'When the current stock quantity falls below (reaches) this quantity, a store owner will receive a notification.',
    backInStock:'Allow customers to subscribe to a notification list for a product that has gone out of stock.',
    availableRange:'Choose the product availability range that indicates when the product is expected to be available when out of stock (e.g. Available in 10-14 days). You can manage availability ranges by selecting Configuration > Shipping > Dates and ranges.',
    minCart:'Set the minimum quantity allowed in a customer\'s shopping cart e.g. set to 3 to only allow customers to purchase 3 or more of this product.',
    maxCart:'Set the maximum quantity allowed in a customer\'s shopping cart e.g. set to 5 to only allow customers to purchase 5 of this product.',
    allowedQty:'Enter a comma separated list of quantities you want this product to be restricted to. Instead of a quantity textbox that allows them to enter any quantity, they will receive a dropdown list of the values you enter here.',
    onlyExisting:'Check to allow adding to the cart/wishlist only attribute combinations that exist and have stock greater than zero. In this case you have to create all existing product attribute combinations that you have in stock.',
    isReturnable:'Check if this product is not returnable. In this case a customer won\'t be allowed to submit return request.',
    tierStore: 'Option to limit this tier price to a certain store. If you have multiple stores, choose one from the list.',
    tierStart:'The start date of the tier price in Coordinated Universal Time (UTC).',
    tierEnd:'The end date of the tier price in Coordinated Universal Time (UTC).',
    tierQuantity:'Specify quantity for which this tier price will be available.',
    productAttribute:'Choose an attribute',
    textPrompt:'Enter text prompt (you can leave it empty)',
    attributeIsRequired:'When an attribute is required, the customer must choose an appropriate attribute value before they can continue.',
    attributeDefault:'Enter default value for attribute.',
    minLength:'Specify minimum length. Leave empty to skip this validation.',
    maxlength:'Specify maximum length. Leave empty to skip this validation.',
    extentionAllowed:'Specify a comma-separated list of allowed file extensions. Leave empty to allow any file extension.',
    fileSize:'Specify maximum file size in kilobytes. Leave empty to skip this validation.',
    attributeType:' Choose attribute type.',
    attribute:'Choose a product specification attribute. You can manage specification attributes from Catalog > Attributes > Product Specifications.',
    htmlValue:'Custom value (text, hyperlink, etc).',
    allowFilter:'Allow product filtering by this attribute.',
    showOnProduct:'Show on product page',
    orderNumber:'The unique number of this order.',
    createdOn:'The date/time the order was placed/created.',
    customerId:'The customer who placed this order.',
    orderStatus:'The status of this order.',
    orderHint:'This option is only for advanced users (not recommended to change manually). All appropriate actions (such as inventory adjustment, sending notification emails, reward points, gift card activation/deactivation, etc) should be done manually in this case.',
    orderSubtotal:'The subtotal of this order.',
    orderShipping:'The total shipping cost for this order.',
    orderTax:'Total tax applied to this order. Manage your tax settings from Configuration > Tax.',
    profit:'Profit of this order',
    orderTotal:'The total cost of this order (includes discounts, shipping and tax).',
    editSubtotal:'Edit the subtotal of this order.',
    editDiscount:'Edit the subtotal discount of this order.',
    editShippingOrder:'Edit the total shipping cost for this order.',
    additionalPaymentFee:'Edit the payment method additional fee for this order.',
    taxRate:'Edit the tax rates applied to this order ([rate]:[taxtotal] - separated by semicolon).',
    editTax:'Edit the total tax applied to this order.',
    editDiscount:'Edit the total discount applied to this order.',
    editCost:'Edit the total cost of this order.',
    paymentMethod:'The payment method used for this transaction. You can manage Payment Methods from Configuration > Payment > Payment Methods.',
    paymentStatus:'The status of the payment.',
    storeName:'A store name in which this order was placed.',
    guid:'Internal reference for order.',
    customerIp:'Customer IP address from where order has been placed.',
    customerEmail:'The customer \'s email.',
    password:'The customer\'s password.',
    fullname:'The customer\'s full name.',
    gender:'The customer\'s gender.',
    birthday:'The customer\'s date of birth.',
    companyName:'The company name.',
    customerRoles:'Choose customer roles of this user.',
    customerVendor:'Choose a vendor associated to this customer account. When associated this customer will be able to login to the chosen vendor portal and manage his products and orders.',
    customerActive:'Is this customer\'s account active. To disable/suspend a customer\'s account, uncheck this.',
    vendorName:'The name of the vendor.',
    vendorDescription:'The description of the vendor.',
    email:'Enter email address',
    discountType:'The type of discount.',
    isPercentage:'Determines whether to apply a percentage discount to the order/SKUs. If not enabled, a set value is discounted.',
    percentage:'The percentage discount to apply to order/SKUs.',
    maximumDiscount:'Maximum allowed discount amount. Leave empty to allow any discount amount. If you\'re using \'Assigned to products\' discount type, then it\'s applied to each product separately.',
    discountAmount:'The discount amount to apply to the order/SKUs.',
    requiredCode:'If checked, a customer must supply a valid coupon code for the discount to be applied.',
    couponCode:'The discount coupon code.',
    startDate:'The start of the discount period in Coordinated Universal Time (UTC).',
    endDate:'The end of the discount period in Coordinated Universal Time (UTC).',
    subject:'The subject of your campaign email.',
    newsname:'The name of this campaign.',
    body:'The body of your campaign email.',
    planDate:'Enter a specific date and time to send the campaign. Leave empty to send it immediately',
    limitedRole:'Choose a customer role which subscribers will get this email.',
    messageToken:'%Store.Name%, %Store.URL%, %Store.Email%, %Store.CompanyName%, 		%Store.CompanyAddress%, %Store.LogoURL%, %Customer.FullName%, %Facebook.URL%, %Twitter.URL%, %YouTube.URL%, %Customer.Email%, %Store.Currency%,  %Order.OrderTotal%, %Customer.PasswordRecoveryURL%, %Customer.AccountActivationURL%, %Customer.EmailRevalidationURL%.  Please do not modify the message token',
    isPrinting:'Check if product is design and printing work. After selecting customer will be giving and option to choose from design template, let expert design it or upload their design'

    





}

export default Tooltips