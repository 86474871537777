import React from 'react'
import axios from 'axios'
import {ServerUrl} from './include'



export const generateCardNo=(t)=>{
  t||(t=16);
  for(var e="",a=0;a<t;a++){
    var n=Math.floor(Math.random()*"1234567890".length);
    e+="1234567890".substring(n,n+1)
    }
   
    return e
}

export  const longDate=(conv)=>{
  var options  = {year:'numeric', month:'long', day:'numeric'}
  var ndate = new Date(conv);
  return ndate.toLocaleDateString("en-US", options)
}
export const getMonthYear=()=>{
  var months = ['January', 'February', 'March', 
               'April', 'May', 'June', 'July', 
               'August', 'September', 'October', 'November', 'December'];
  var date = new Date()
 var nDate =  months[date.getMonth()] +' '+ date.getFullYear()
 return nDate
}

export const getMonth=()=>{
  var months = ['January', 'February', 'March', 
               'April', 'May', 'June', 'July', 
               'August', 'September', 'October', 'November', 'December'];
  var date = new Date()
 var nDate =  months[date.getMonth()]
 return nDate
}
 export const getTime =(value)=>{
  var timeString = String(value)
 var hourEnd = timeString.indexOf(":");
 var H = timeString.substr(0,2);
 var h = H % 12 || 12;
 var ampm =  (H<12 || H===24)?"AM":"PM";
  if (h < 10) h = "0" + h
 var result = h + timeString.substr(hourEnd, 3)+" "+ampm


return result;
}
export const sort=(a,b)=>{
  let result = 0;
  if(Number(a.display_order)>Number(b.display_order)){
      result = 1
  }else if (Number(a.display_order)<Number(b.display_order)){
      result = -1
  }
  return result
  }

  export  const compareSort=(a,b)=>{
    let result = 0;
    if(b.order >a.order){
        result = 1
    }else if (b.order <a.order){
        result = -1
    }
    return result
    }
export const compare=(a,b)=>{
  let result = 0;
  if(Number(a.order)>Number(b.order)){
      result = 1
  }else if (Number(a.order)<Number(b.order)){
      result = -1
  }
  return result
  }

  export const convertDateTime=(date)=>{
    let result = ''
    if(date!=='0000-00-00 00:00:00' && date!=='' && date!==null){
   result = new Date(date).toISOString().slice(0,19)
    }else{
      result =''
    }
    return result
  }
export const convertDate=(date)=>{
  let result = ''
  if(date!=='0000-00-00'){
 result = new Date(date).toISOString().slice(0,10)
  }else{
    result =''
  }
  return result
}

export const getDays=(date)=>{
  let day;
  switch (new Date(date).getDay()){
      case 0:
          day ="Sunday";
          break;
      case 1:
          day ="Monday";
          break;
      case 2:
          day ="Tuesday";
         break;
      case 3:
          day ="Wednessday";
              break;
      case 4:
          day ="Thursday";
              break;
      case 5:
          day ="Friday";
          break;
      case 6:
          day ="Saturday"
          break;
      default:
          day="Sunday"
  }
      return day
  }
export  const getDateDifference=(from, to)=>{
  var fromDate =  new Date(from);
  var toDate =  new Date(to);
  var diff_time = toDate.getTime()-fromDate.getTime()
  var differ = diff_time/(1000*3600*24); 
  return differ
}

export  const CheckExistence =(table, column, data)=>{
  
  if(data===''){
    return false
  }else{
   let res ='';
  let url = ServerUrl + '/function/checkExistence/'+table+'/'+column+'/'+data;
  axios.get(url).then(response=>{
    res = response.data})
    return res;
}

}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

export const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span style={{color:'black', fontSize:'15px',fontWeight: 'bold', }}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);