import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { Popover, LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, FrontEndUrl} from '../../components/include'
import axios from 'axios'
import { staffCode, config, Token} from '../../components/include'
import { useParams } from 'react-router-dom'
import { longDate } from '../../components/globalFunction'

 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});


    const [content, setContent] = useState([])
	
	
    const handleDelete =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
		
		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'code')
		fd.append('data', params.code)
		fd.append("jwt", Token);
	let url = ServerUrl+'/delete_controller.php?tablename=tbl_tickets';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/communication/tickets', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
		
		
}

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select t.ID, c.email, c.fullname, c.telephone, t.code, t.response, t.fileUrl, t.description, t.dateCreate, t.dateTime, t.customerCode, t.subject, t.ticketType, t.priority, t.ticketStatus, t.status from tbl_customers c, tbl_tickets t where c.code = t.customerCode and t.code = '" +params.code+"'";
        
         
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
		fd.append("jwt", Token);
        axios.post(url, fd, config).then(result=>{ 
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
	const [defaultUrl, setDefaultUrl] = useState('');
	
	
	const handleChange =(event)=>{
	const newContent =[...content] 
		let {name, value} = event.target;	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}

	
	
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		     
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append("jwt", Token);
			fd.append("StaffId", staffCode)
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_tickets';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.title){
			errors.title =Tooltip.required;
			myRef.current.focus()
		}
		if(!values.reviewText){
			errors.reviewText =Tooltip.required;
		}
		return errors;
		}


useEffect(()=>{
	fetchContent()
},[]);	
	
        return (<Template 
			menu="Communications"
		  submenu="Tickets">
			
		   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		 
		 <div  className="main-body">
			  <div className="page-wrapper">
		
		<PageHeader title="Tickets Update" description='Reply / Update Customer Ticket' icon='fa fa-commenting-o'>
		
				</PageHeader>

{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-inverse btn-sm" onClick={(e)=>handleSubmit(e, '/communication/tickets')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/communication/ticket/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	<a href="/communication/tickets" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDelete()} mID={'delAll'} /> 


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-chat"></i> Ticket</h5>
			<div className="card-header-right">
			<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
			<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
				
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Type" toolTip={'Ticket Type'} />
					</div>
									
						<div className="col-sm-9 ">{data.ticketType}
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Customer" toolTip={'Customer'} />
					</div>
									
						<div className="col-sm-9 ">
							<a  href={"/customer/edit/"+data.customerCode}>{data.telephone}<br/>{data.email}<br/>{data.fullname}</a>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Subject" toolTip={'Ticket Subject'} />
					</div>
									
						<div className="col-sm-9 ">{data.subject}
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Description" toolTip={'Description'} />
					</div>
									
					<div className="col-sm-9 "><b>{data.description}</b>
							</div>
					</div>

				
			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Created On"  toolTip={Tooltip.dateCreate} />
			</div>
							
				<div className="col-sm-9 ">{longDate(data.dateCreate)+data.dateTime}
					</div>
			</div>
			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Attachment"  toolTip={'Tickets Attachement'} />
			</div>
							
				<div className="col-sm-9 ">{data.fileUrl.length!==0?<a target="_blank" rel="noopener noreferrer" href={FrontEndUrl+"/images/tickets/"+data.fileUrl}> Click here to view</a>:''}
					</div>
			</div>

	</section></div></div></div>
			
							</div>


							<div className="card-block" >
       	
		   <div className="row">
		  <div className="col-sm-12">
		  
						  <div className="card z-depth-0">
								  <section className="card-block">
												<h5>Response option coming soon</h5>	
							  {/* <div className="form-group row">
							  <div className="col-sm-3">
									  <FormLabel name ="Reply" toolTip={'Ticket Type'} />
							  </div>
											  
								  <div className="col-sm-9 ">Response option coming soon
									  </div>
							  </div> */}
		  
			  </section></div></div></div>
					  
									  </div>
							</div>
							</div>
	                   
                            </div>
				
				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 