import React, {useState, useEffect} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts} from '../../components/notify'
import {CustomToggleList} from '../../components/dataTable'
import {ServerUrl, FormatNumber, Token, config, API_URL} from '../../components/include'
import axios from 'axios'
import { Currency} from '../../components/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

import Select from 'react-select'
 const Orders =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
    const [search, setSearch]= useState({
    fromDate:'',
        toDate:'',
        customerId:{label:'', value:''},
        paymentStatus:'all',
            orderStatus:'all',
            paymentMethod:'all',
            orderId:'',
            shippingStatus:'all'
    })
    
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState({
        customer:[],
        ticket:0,
        orderNotice:0,
        pendingOrder:0,
        orders:[]
    
      })
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const SearchTable =(event)=>{
       event.preventDefault();
       fetchContent();
    }


const handleExportSelectedPdf =()=>{
       

let data = []
var newContent = JSON.parse(JSON.stringify(content));
for (var i in newContent){
    if(newContent[i].status===true){
        data.push(newContent[i].ID)
    }
}
if(data.length===0){
    Alerts('Error!', 'danger', 'Please select order to export') 
}else{

let url = ServerUrl+'/exportPDF_controller.php?tablename=tbl_selectedOrders&data='+String(data);
window.open(url, '_self') 
    }
}

const handleExportSelected =()=>{

let data = []
var newContent = JSON.parse(JSON.stringify(content));
for (var i in newContent){
    if(newContent[i].status===true){
        data.push(newContent[i].ID)
    }
}
if(data.length===0){
    Alerts('Error!', 'danger', 'Please select order to export') 
}else{

let url = ServerUrl+'/exportCsv_controller.php?tablename=tbl_selectedOrders&data='+String(data);
window.open(url, '_self') 
    }
}


const fetchContent =()=>{
       setNotice({...notice, isDataFetching:true})
    let url = API_URL+'/customer/order';
    const fd = new FormData();
        fd.append("fromDate", search.fromDate);
        fd.append("toDate", search.toDate);
        fd.append("paymentStatus", search.paymentStatus);
        fd.append("customerId", search.customerId.value);
        fd.append("orderId", search.orderId);
        fd.append("orderStatus", search.orderStatus);
        fd.append("shippingStatus", search.shippingStatus);
        
        
    axios.post(url, fd, config)
    .then(result =>{ 
        if(result.data.status===200){
            setContent({
                orders:result.data.order.filter(item=>item.order_status!=='Pending'),
                customer:result.data.customer
            })
        }
        
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isDataFetching:false})
    })

}

  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}
const handleCheckAll =()=>{

    var newContent = JSON.parse(JSON.stringify(content.orders));
    for (var i in newContent){
        newContent[i].status=String(!status.checkAll)
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent)


    var newContent = JSON.parse(JSON.stringify(content));
    for (var i in newContent){
        newContent[i].status=!status.checkAll
    }
    setStatus({...status, checkAll:!status.checkAll})
    
   setContent({...content, orders:newContent}) 
 }



  const handleCheckOne =(e)=>{  


    let {name, value} = e.target

    const currentContent = content.orders.map((item)=>{
                   
        if(item.code ===name){
            return {...item, status:item.status==='false'?'true':'false'}
        }

         return item
          })

  setStatus({...status, checkAll:false})
   setContent({...content, orders:currentContent}) 
        
       }



       const handleCustomer = (row)=>{
        try{
            let item = '';
            let newContent = JSON.parse(row);
        if(newContent.length!==0){
            item = newContent[0].first_name+' - ' + newContent[0].last_name + ' ' +newContent[0].telephone
        }
            
return item;
        }catch(e){

        }
   }

       const GeneralCheck =()=><div className="checkbox-fade fade-in-success">
       <label>
     <input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
           <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
       </label>
     </div> 
    const tableHeader = [
        
        {dataField: 'status',  text: <GeneralCheck />, editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.code}  checked={row.status==='true'?true:false} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},  
    {dataField: '',  text: 'Action',  sort: true, editable: false, formatter:(cell, row)=>row.order_status!=='Pending'?<a href={"/orders/edit/"+row.order_code} className="btn btn-warning btn-sm" ><i className="fa fa-eye"></i>View </a>:[]},
    {dataField: 'order_code',  text: 'Order ID',  sort: true, editable: false},
    {dataField: 'order_status',  text: 'Order Status',  sort: true, editable: false, formatter:(cell, row)=><span 
    
    className={` badge 
                    ${cell==='Delivered'?'badge-success':
                    cell==='Canceled'?'badge-danger':
                    cell==='Pending'?'badge-dark':
                    cell==='Delayed'?'badge-warning':
                    cell==='Processing'?'badge-danger':
                    'badge-default'}`}> {cell}</span>},

{dataField: 'payment_status',  text: 'Payment Status',  sort: true, editable: false, formatter:(cell)=><span className={`badge  m-0 
${cell==='Paid'?'badge-success': 'badge-info' }`}>{cell}</span>},



{dataField: 'customer_details',  text: 'Customer',  sort: true, editable: false, formatter:(cell, row)=><span > {handleCustomer(row.customer_details)}</span>},

{dataField: 'created_at',  text: 'Created On',  sort: true, editable: false},
{dataField: 'order_total',  text: 'Total ('+Currency+')',  sort: true, editable: false, formatter:(cell)=>FormatNumber(cell)}
       
     ];
    
     const TableRecord=(props)=>{
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='orderId' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                               <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                               
  <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'220px', width:'200px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
      <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>
                                 
                                 
                 <div className="dt-buttons btn-group pull-right"> 
                              

<div className="btn-group dropdown-split-primary">
    <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <i className="fa fa-download"></i>Export
    </button>
    <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>

        <a className="dropdown-item waves-effect waves-light" href={ServerUrl+'/exportCsv_controller.php?tablename=tbl_orders&recordType=allfound'}>Export to Excel (all found)</a> 

        <a href="#!" onClick={handleExportSelected} className="dropdown-item waves-effect waves-light" >Export to Excel (only selected)</a>
    </div>
</div>         
                  

<div className="btn-group dropdown-split-success">
    <button type="button" className="btn btn-outline-success dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <i className="fa fa-download"></i>Print as PDF
    </button>
    <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <a className="dropdown-item waves-effect waves-light" href={ServerUrl+'/exportPDF_controller.php?tablename=tbl_orders&recordType=allfound'}>Download as PDF (all found)</a>
        <a className="dropdown-item waves-effect waves-light" onClick={handleExportSelectedPdf} href="#!">Download as PDF (only selected)</a>
    </div>
</div>   
 </div>                   

      
                                 </div>
                                 
                     <BootstrapTable 
                        { ...props.baseProps }
                        striped
                    hover
                    condensed
                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                pagination={paginationFactory(options) }
              
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
      const handleSelect = (option, action)=>{
		setSearch({...search, [action.name]:option})
	  } 

     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
            menu="Sales"
            submenu="Orders">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="Orders" description='Listing All Sales Record' icon='feather icon-shopping-cart'>
                        </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">   

            <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="From Date" toolTip={Tooltip.fromDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="fromDate" value={search.fromDate} />
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="To Date" toolTip={Tooltip.toDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="toDate" value={search.toDate} />
                        </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Customer" toolTip={Tooltip.customerId} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                       <Select  options={
				content.customer&&content.customer.map((data, id)=>{
					return {value:data.code, key:id, label:data.first_name + ' '+ data.last_name}
				})}  
                
onChange={handleSelect}  name="customerId"  value={search.customerId} styles={selectStyles} /> 
                        </div>
                    </div>
                  
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Order Status" toolTip={Tooltip.orderStatus} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">

                    <select  name="orderStatus" value={search.orderStatus} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Canceled">Canceled</option>
                <option value="Pending">Pending</option>
                <option value="Delayed">Delayed</option>
                <option value="Delivered">Delivered</option>
                <option value="Processing"> Processing</option>
                <option value="Completed"> Completed</option>
                    </select>
                        </div>
                    </div>
             
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">

                <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Order ID" toolTip={'Unique identifier of this order'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="orderId" value={search.orderId} />
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Shipping Status" toolTip={"Status of the shipping"} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="shippingStatus" value={search.shippingStatus} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                    <option value="Shipping not required">Shipping not required</option>
                        <option value="Not yet shipped">Not yet shipped</option>
                        <option value="Partially shipped">Partially shipped</option>
                        <option value="Shipped">Shipped</option>
                        <option value="Delivered">Delivered</option>
                    </select>
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Payment Status" toolTip={Tooltip.paymentStatus} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="paymentStatus" value={search.paymentStatus} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Authorized">Authorized</option>

                        <option value="Processing">Processing</option>
                        <option value="Paid">Paid</option>
                        <option value="Partially refunded">Partially refunded</option>
                        <option value="Refunded">Refunded</option>
                        <option value="Voided">Voided</option>
                    </select>
                        </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Payment Method" toolTip={Tooltip.paymentMethod} />
                    </div>
                   
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="paymentMethod" value={search.paymentMethod} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Online">Online</option>
                        <option value="Bank Payment">Bank Payment</option>
                    </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Order list</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content.orders}  columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Orders) 