import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import {Alerts} from '../components/notify'
import {ServerUrl, FormatNumber, Currency, Token, config} from '../components/include'
import axios from 'axios'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

 const Dailysales =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
   
    const [content, setContent] = useState([])
    const [details, setDetails] = useState([])
var today = new Date().toISOString().slice(0,10);
    function renderEventContent(eventInfo){ 
        var data = eventInfo.event.extendedProps 
        return <table className="table ">
        <tbody>
        <tr>
        <td><br/>Subtotal<br/> ShippingFee <br/> Tax<br/>Discount<span style={{fontWeight:'bold'}}><br/>Total</span></td>
        <td style={{textAlign:'right'}}><br/>
        {Currency+FormatNumber(data.subtotal)}<br/>
        {Currency+FormatNumber(data.shippingFee)}<br/>
        {Currency+FormatNumber(data.tax)}<br/>
        {Currency+FormatNumber(data.discount)}
        <span style={{fontWeight:'bold'}}><br/> {Currency+FormatNumber(data.order_total)}</span></td>
        </tr>
        </tbody>
        </table>
    }
    const fetchDetails =()=>{
      setNotice({...notice, 
          isLoading: true
      });
     
  var sql = "SELECT SUM(o.handling_fee) as shippingFee, SUM(o.tax) as tax, SUM(o.discount) as discount, SUM(o.order_total) as order_total, h.payment_status from tbl_payment_history h, tbl_orders o  where h.payment_status='paid' and  h.created_at='"+today+"'"

        
      const fd = new FormData();
      fd.append("sql", sql);
      fd.append('jwt', Token);
      let url = ServerUrl+'/fetchBySql_controller.php';
      
      axios.post(url, fd, config).then(result=>{ 
        setDetails(result.data)
      })
      .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
      }).finally(()=>{
          setNotice({...notice, 
              isLoading: false
          });
      }) 

}

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
    var sql = "Select SUM(subtotal) as subtotal, SUM(handling_fee) as shippingFee, SUM(tax) as tax, SUM(discount) as discount, SUM(order_total) as order_total,  created_at as date from tbl_orders where payment_status='paid' group by DATE_FORMAT('%Y-%m-%d',created_at)"

      
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append('jwt', Token);
        axios.post(url, fd, config).then(result=>{ 
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }

     useEffect(()=>{
        fetchContent()
        fetchDetails()
       },[]);

        return (<Template 
            menu="Reports"
          submenu="Daily Sales">
            
           {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
         
         <div  className="main-body">
              <div className="page-wrapper">
        
        <PageHeader title="Daily Sales" description='Displaying daily sales report' icon='fa fa-bar-chart'>
        
                </PageHeader>
        
        


{/* The Page Body start here */}
<div className="page-body">
         

<div className="row">
        <div className="col-lg-12">
		  <div id="contentHere" >

          <div className="row">
          <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
            <div class="card bg-c-green text-white">
                <div class="card-body">
                    <div class="row align-items-center">
                    <div class="col col-auto text-right">
                            <i class="feather icon-shopping-cart f-50 text-c-green"></i>
                        </div>
                        <div class="col">
                            <p class="m-b-5">Today Sales</p>
                            <h4 class="m-b-0">{Currency+FormatNumber(details&&Number(details.map(data=>data.order_total)))}</h4>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>


        <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
            <div class="card bg-c-pink text-white">
                <div class="card-body">
                    <div class="row align-items-center">
                    <div class="col col-auto text-right">
                            <i class="feather icon-shopping-cart f-50 text-c-pink"></i>
                        </div>
                        <div class="col">
                            <p class="m-b-5">Today Tax</p>
                            <h4 class="m-b-0">{Currency+FormatNumber(details&&Number(details.map(data=>data.tax)))}</h4>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
            <div class="card bg-c-blue text-white">
                <div class="card-body">
                    <div class="row align-items-center">
                    <div class="col col-auto text-right">
                            <i class="feather icon-shopping-cart f-50 text-c-blue"></i>
                        </div>
                        <div class="col">
                            <p class="m-b-5">Today Discount</p>
                            <h4 class="m-b-0">{Currency+FormatNumber(details&&Number(details.map(data=>data.discount)))}</h4>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
            <div class="card bg-c-yellow text-white">
                <div class="card-body">
                    <div class="row align-items-center">
                    <div class="col col-auto text-right">
                            <i class="feather icon-shopping-cart f-50 text-c-yellow"></i>
                        </div>
                        <div class="col">
                            <p class="m-b-5">Shipping Fee</p>
                            <h4 class="m-b-0">{Currency+FormatNumber(details&&Number(details.map(data=>data.handling_fee)))}</h4>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
             <div className="card-body">
             <FullCalendar
             events={content}          
             eventContent={renderEventContent}
            headerToolbar = { {
            left: "prev, today",
            center: "title",
            right: "next"
          }}
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth"
      />
             </div>
          </div>
        </div>
      </div>

          </div>
        </div>
      </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Dailysales) 