import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts} from '../../components/notify'
import { API_URL, config} from '../../components/include'
import axios from 'axios'

 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [defaultUrl, setDefaultUrl] = useState('');
	
	const [content, setContent] = useState({
		code:'c'+Math.random().toString(36).substring(2,9),
		title:'',
		collection_type:'product',
		layout_type:'',
		display_order:1,
		status:'Active',
		item_list:'[]',
	})
	
	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='status'){
			
			value = value==='Active'?'Active':'Inactive'
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		    
	
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }

		  let url = API_URL+'/collection/create';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	


	const handleSubmit = (event, url) =>{
	  event.preventDefault();     
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
        let errors = {};   
        
        if(!values.title){
            errors.title =Tooltip.required;
        }
        if(!values.collection_type){
            errors.collection_type =Tooltip.required;
        }
		if(!values.layout_type){
            errors.layout_type =Tooltip.required;
        }
        return errors;
		}
		

        return (<Template 
			menu="Content Management"
		submenu="Homepage">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Add Collections" description='Create new homepage collection' icon='feather icon-layers'>
  <li className="breadcrumb-item"><a href="/cms/collections">Collections</a> </li>
					</PageHeader>
					
					


{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, '/cms/collections')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/cms/collection/create')}
  className="btn btn-inverse " ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/cms/collection/edit/'+content.code)} className="btn btn-inverse "><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/cms/collections" className="btn btn-warning "><i className="fa fa-arrow-left"></i> Return</a>


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i> Collection info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                        
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Title" toolTip={'Collection title as you want it to appear on home pape'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="title" value={content.title} className='form-control '/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.title}</span>
							</div>
					</div>
					
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Collection Type" toolTip={'Select the type of collection you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="collection_type" value={content.collection_type} className="form-control" onChange={handleChange}>
							<option value="product">Product</option>
							<option value="category">Category</option>
							<option value="vendor">Vendor</option>
							<option value="blog">Blog</option>
							<option value="review">Customer Review</option>
							<option value="banner">Banner</option>
							<option value="video">Video</option>
							</select>
							</div>
							</div>
					</div>

{content.collection_type==='brand'?
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Brand Layout 1">Brand Layout 1</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:
					content.collection_type==='vendor'?


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Vendor Layout 1">Vendor Layout 1</option>
							<option value="Vendor Layout 2">Vendor Layout 2</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:
					content.collection_type==='video'?


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Video Layout 1">Video Layout 1</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>: content.collection_type==='product'?



					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Product Layout 1">Product Layout 1</option>
							<option value="Product Layout 2">Product Layout 2</option>
							<option value="Product Layout 3">Product Layout 3</option>
							<option value="Product Layout 4">Product Layout 4</option>
							<option value="Product Layout 5">Product Layout 5</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:content.collection_type==='blog'?

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Blog Layout 1">Blog Layout 1</option>
							<option value="Blog Layout 2">Blog Layout 2</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:content.collection_type==='review'?

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Customer Review Layout 1">Customer Review Layout 1</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:content.collection_type==='banner'?
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Layout 1">Layout 1</option>
							<option value="Layout 2">Layout 2</option>
							<option value="Layout 3">Layout 3</option>
							<option value="Layout 4">Layout 4</option>
							<option value="Layout 5">Layout 5</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>
					:
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Category Layout 1">Category Layout 1</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>}


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.display_order} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange}   name="display_order" value={content.display_order} className='form-control'/>
							
							</div>
							</div>
					</div>



					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Is Active" toolTip={'Check to appear on home page'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.status} name="status" checked={content.status==='Active'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


								<div className="row  ">
			<div className="col-sm-12">
								

			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th-list"></i>Products</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					
You need to save the collection before you can add products for this collection.
					</div>


	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 