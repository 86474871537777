import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import { LoadEffect } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts} from '../components/notify'
import {ServerUrl, Token, config} from '../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'

 const SMSApi =()=> {
	 let myRef = useRef()
	 const [defaultUrl, setDefaultUrl] = useState('');
	
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
		isDataFetching: false,
		token:false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select * from tbl_sms_api where ID = 1";
        
         
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append("jwt", Token);
        axios.post(url, fd, config).then(result=>{ 
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
  const handleChangeTest = event =>{		
	const {name, value} = event.target;		
	setTestSms({...testSms, [name]:value})
	setErrors({...errors, [name]:''})
}
const [testSms, setTestSms] = useState({
	telephone:'',
	message:'',
	response:''

})
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;
		if(name==='sender'){
		value = value.length <=10?value:value.slice(0,10);
		}	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
 

    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		     
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append("jwt", Token);
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_sms_api';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	

const sendTest=()=>{

	

}

	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
        let errors = {};   
        
        if(!values.email){
            errors.email =Tooltip.required;
        }
		if(!values.apitoken){
            errors.apitoken =Tooltip.required;
		}
		 
        return errors;
		}
	
	
useEffect(()=>{
	fetchContent()
},[]);	
	
        return (<Template 
			menu="Communications"
		  submenu="SMS API Settings">
			
		   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		 
		 <div  className="main-body">
			  <div className="page-wrapper">
		
		<PageHeader title="SMS API Settings" description='Configure SMS API Information here' icon='fa fa-commenting-o'>
		
				</PageHeader>


<div id="testSmsModalBox" className="modal fade in" role="dialog" aria-hidden="false" >
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                  <h4 className="modal-title">Send Test SMS</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
                <div className="modal-body">
                    <div className="row form-group">
                        <label className="col-md-3 control-label text-left">Receivers Number</label>
                        <div className="col-md-9">
                            <input type="text" name="telephone" value={testSms.telephone} onChange={handleChangeTest} className={errors.telephone ? 'form-control formerror' : 'form-control'} placeholder="Number" />
                            <small id="recept">Use number without +. e.g 08032950881</small>
                        </div>
                    </div>
                    <div className="row form-group">
                        <label className="col-md-3 control-label text-left">Message</label>
                        <div className="col-md-9">
                            <textarea type="text" value={testSms.message} onChange={handleChangeTest} className={errors.message ? 'form-control formerror' : 'form-control'} name="message"  placeholder="Message">{testSms.message} </textarea>
                            <span id="res_messag">{testSms.response}</span>
                        </div>
						<span style={{color:'green'}}>

					{testSms.message.length} Characters,  {(Math.ceil(Number(testSms.message.length)/160))}  Pages, Remaining {(160 -(Number(testSms.message.length)%160))}</span>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={sendTest} className="btn btn-success" id="btnSendSms">Send</button>
                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>


{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-inverse btn-sm" onClick={handleSubmit} ><i className="fa fa-save"></i> Update</button>&nbsp;

<button type="button" data-toggle="modal"  data-target='#testSmsModalBox' id="openModal" className="btn btn-success btn-sm"><i className="fa fa-copy"></i> Send Test</button>



			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i> SMS API info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                    
	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="API Token" toolTip={'API token provided by the sms platform'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="apitoken" value={data.apitoken} className={errors.apitoken  ? 'form-control formerror' : 'form-control '}/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.apitoken}</span>
							</div>
	</div>
				
	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="API email" toolTip={'Email Address associated with this API'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="email" value={data.email} className={errors.email  ? 'form-control formerror' : 'form-control '}/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.email}</span>
							</div>
	</div>


	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="API Password" toolTip={'Password associated with this API'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="password" onChange={handleChange}   name="password" value={data.password} className={errors.password  ? 'form-control formerror' : 'form-control '}/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.password}</span>
							</div>
	</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Sender" toolTip={'The name that will display to customer.'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="sender" value={data.sender} className='form-control'/>
							
							</div>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="API Endpoint" toolTip={'Endpoint associated to this account'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="endpoint" value={data.endpoint} className='form-control'/>
							
							</div>
							</div>
					</div>

					

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

				</div>)}
				



				<div >
        <p>For Documentation : <a className="alert alert-success" href="https://www.multitexter.com/MultiTexter_HTTP_SMS_API%202.0.pdf" target="_blank" rel="noopener noreferrer"><strong>Click Here</strong></a></p>
    </div>

                  <div className="row ">
        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
          <div className="card ">
            <div className="card-body">
             
            <div className="table-responsive">
        <table  className="table">
            <thead>
                <tr>
                    <th><i className="fa fa-list-ol"></i></th>
                    <th>Response Code</th>                   
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr><td>1</td><td>1</td><td>Ok : Message sent successful
</td></tr>
                <tr><td>2</td><td>-2</td><td>Invalid Parameter</td></tr>
                <tr><td>3</td><td>-3</td><td>Account suspended due to fraudulent message</td></tr>
                <tr><td>4</td><td>-4</td><td>Invalid Display name</td></tr>
                <tr><td>5</td><td>-5</td><td>Invalid Message content</td></tr>
                <tr><td>6</td><td>-6</td><td>Invalid recipient </td></tr>
                <tr><td>7</td><td>-7</td><td>Insufficient unit</td></tr>
                <tr><td>8</td><td>-10</td><td>Unknown error</td></tr>
                <tr><td>9</td><td>401</td><td>Unauthenticated
</td></tr>
                <tr>
                    <td colSpan="3">Above are the list of the Server Responses and their meanings</td></tr>              
             
            </tbody> 
        </table>

        </div> 

            </div>
          </div>
        </div>
      </div>  


          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(SMSApi) 