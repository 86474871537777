import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import { LoadEffect } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts, WarningModal} from '../components/notify'
import {ServerUrl,   Token, config, API_URL, IMAGES_URL} from '../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { compareSort } from '../components/globalFunction'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import Select from 'react-select'
 const Edit =()=> {
	 let myRef = useRef()
	 const [defaultUrl, setDefaultUrl] = useState('');
	
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
const [password, setPassword] = useState('')
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	const [group, setGroup] = useState([])
	const [logList, setLogList] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	

	const getItemLabel = (id, mainData, recordName) =>{ 
		const result = mainData.filter((list)=>list.ID===id);
	const answer = result.map((c)=>c[recordName])
		 return  String(answer);
	   }
	   const [image, setImage] = useState('');



const handleDeleteWithImage =()=>{
	let close =   document.getElementById('btnWarningDialog-delAll')
	close.click();
	

	setNotice({...notice, 
		isLoading: true}) 

let url = API_URL+'/user/delete/'+params.code;
	  axios.get(url, config)
	  .then(response =>{
	   if(response.data.status ===200){
		  Alerts('Saved!', 'success', response.data.message)

		  setTimeout(() => {
			
			window.open('/users', '_self')
		  }, 2000);
			  } else{
		  Alerts('Error!', 'danger', JSON.stringify(response.data))
			  }   
	  })
	  .catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	  }).finally(()=>{
		  setNotice({...notice, 
			  isLoading: false
		  })
	  }) 
}

const setLogo =()=>{
	const img = document.getElementById('viewLogo');
	img.src = IMAGES_URL+"/noImage.png" 
	document.getElementById('logo').value = '';
	setImage('')
}
const removeImageUrl =()=>{
	const newContent =JSON.parse(JSON.stringify(content))
	 newContent[0].imageUrl = '' 
	setContent(newContent);

	const img = document.getElementById('viewLogo');
	img.src = IMAGES_URL+"/noImage.png" 
	document.getElementById('logo').value = '';
	setImage('')
}




const fetchContent =()=>{

	setNotice({...notice, 
		isLoading: true
	});

	  let url = API_URL+'/user/display/one/'+params.code;
	  axios.get(url, config).then(result=>{
		  if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
			setContent(result.data.data)
		  }
	  })
	  .catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	  }).finally(()=>{
		setNotice({...notice, 
			isLoading: false
		});
	  })
}

	
  const handleSelect = (option, action)=>{
	const newContent =JSON.parse(JSON.stringify(content))
	newContent[0][action.name]= option.value  
	setContent(newContent)
	 }

	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;
		if(name==='isActive'){
			value =value ==='true'?'false':'true'
		}	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
 
const changePassword =(event)=>{
	let {name, value} = event.target;
	setPassword(value);
}

    function updatePassword(){  
		if(password===''){
			Alerts('Error!', 'danger', 'Please enter  new password')
		}else{
		 setNotice({...notice, 
		  isLoading: true})     
		  
		  const fd = new FormData();
		  fd.append('password', password)
			 let url = API_URL+'/user/password/'+params.code;
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.status ===200){	
				Alerts('Saved!', 'success', response.data.message)
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
			})  
	}
}
	
const  handleFile = function(fieldName){
	return function(newValue){                 
		const value = newValue.target.files[0]
	  // setImage(value)
   readURL(newValue);
   }
   
  } 

   const readURL = (input)=>{       
	let displayMessage = '';
	  var fileName =  document.getElementById('logo').value;
	var idxDot = fileName.lastIndexOf(".") + 1;
	var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
	if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
		//TO DO    
		
		 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
	if (FileSize > 1) {
		displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1 Mb';
	   document.getElementById(input.target.name).value = '';
	   setImage('')
	} else {
		if (input.target.files && input.target.files[0]) { //Check if input has files.
		  var reader = new FileReader();//Initialize FileReader.
		  reader.onload = function (e) {
				   const newContent =JSON.parse(JSON.stringify(content))
					 newContent[0].imageUrl = '' 
					 setContent(newContent);
					 
		  const img = document.getElementById('viewLogo');
					 img.src = e.target.result             
					
		  };
		  reader.readAsDataURL(input.target.files[0]); 
		  setImage(input.target.files[0])
		  
		}
	}
	}else{
		displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500kb'
	   document.getElementById(input.target.name).value = '';
	   setImage('');
	}   
	
	if(displayMessage.length > 0){
	
		Alerts('Error!', 'danger', displayMessage)
	  }
} 

    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		    
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }

		 
		  if(image!==''){
			fd.append("imageFile", image);
		}
			 let url = API_URL+'/user/update/'+params.code;
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.status ===200){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	const fetchGroup =()=>{
		var sql ="Select ID, groupName from tbl_account_group order by groupName ASC" ;
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append('jwt', Token)
		let url = ServerUrl+'/fetchBySql_controller.php';
		axios.post(url, fd, config).then(result=>{  
			setGroup(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}
	const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
        
        if(!values.fullname){
            errors.fullname =Tooltip.required;
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }
       
       
        return errors;
		}
		
		const ClearUserlog =()=>{
			setNotice({...notice, 
				isLoading: true
			});
			
			const fd = new FormData();
			fd.append("url", "loginreport.json");
			fd.append("actionType", "clearuserdata");
			fd.append('jwt', Token)
			fd.append("usercode", params.code);
			let url = ServerUrl+'/fetch_jsonData.php';
			axios.post(url, fd, config).then(result=>{  
				fetchLog();
				setNotice({...notice, 
					isLoading: false
				});
			})
			.catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			})
		}

	const tableHeader = [
		{dataField: 'fullname',  text: 'Name',  sort: true, editable: false},
		{dataField: 'dateCreate',  text: 'Date Login',  sort: true, editable: false},
		{dataField: 'browser',  text: 'Browser',  sort: true, editable: false},
		{dataField: 'device',  text: 'Device',  sort: true, editable: false},
		{dataField: 'ip',  text: 'IP',  sort: true, editable: false}
		];


				
				 const TableRecord=(props)=>{
					 const { SearchBar } = Search;  
					 const customTotal = (from, to, size) => (
						<span >&nbsp;Showing { from } to { to } of { size } items</span>
					  );
					 const options = {
						showTotal: true,
					  paginationTotalRenderer: customTotal,
					  sizePerPageList: [{text: '70', value: 70}, {text: '150', value: 150}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
					  { text: 'All', value: props.data.length }]
					   
					  };
					 return  <ToolkitProvider search columnToggle 
								 keyField='dateCreate' data={ props.data } columns={ props.columns } >
									 
									 {
										 props => (
										 <div >
										 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />

        <div className="dt-buttons btn-group pull-right"> 
            <a href="#!" onClick={()=>ClearUserlog()} className="btn btn-outline-danger" > <i className="fa fa-trash"></i>Clear Log</a>         
                </div>
								 <BootstrapTable 
									{ ...props.baseProps }
									striped
								hover
								condensed
								noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
							pagination={paginationFactory(options) }
						  
											 />
										 </div>
										 )
									 }
				  </ToolkitProvider>
				  }
				

				  const fetchLog =()=>{
           
					const fd = new FormData();
					fd.append("url", "loginreport.json");
					fd.append("actionType", "getData");
					let url = ServerUrl+'/fetch_jsonData.php';
					fd.append('jwt', Token)
					axios.post(url, fd, config).then(result=>{  
						setLogList(JSON.parse(result.data))
					  
					})
					.catch((error)=>{
						Alerts('Error!', 'danger', error.message)
					})
				}
useEffect(()=>{
	fetchContent()
	fetchGroup()
	fetchLog()
},[]);	
	
        return (<Template 
			menu="People"
		submenu="Users">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Edit User Account" description='Update user details' icon='feather icon-user'>
  <li className="breadcrumb-item"><a href="/users">Customers</a> </li>
					</PageHeader>
		
{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-inverse btn-sm" onClick={(e)=>handleSubmit(e, '/users')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

<button type="button" onClick={(e)=>handleSubmit(e, '/user/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	<a href="/users" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDeleteWithImage(data.photo)} mID={'delAll'} /> 


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  User info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Email Address" toolTip={'User Email'} />
					</div>
									
						<div className="col-sm-9 ">

							<div className="input-group">
						<input type="text" onChange={handleChange} disabled={true} name="email" value={data.email} className={errors.email  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.email}</span>
							</div>
					</div>




					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Password" toolTip={Tooltip.password} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="password" autoComplete="off" onChange={changePassword}   name="password" value={password} className='form-control ' />
							
							<span className="input-group-addon" onClick={updatePassword} id="basic-addon">Change Password</span><span className="required-span"> *</span>
							</div>
							<span className="error">{errors.password}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Fullname" toolTip={Tooltip.fullname} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="fullname" value={data.fullname} className={errors.fullname  ? 'form-control formerror' : 'form-control '}/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.fullname}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Telephone" toolTip={'User Telephone'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="telephone" value={data.telephone} className='form-control'/>
							
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Gender" toolTip={Tooltip.gender} />
					</div>
									
						<div className="col-sm-9 ">
						
			<div className="form-radio">
						<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Male' checked={data.gender==='Male'?true:false} />
						<i className="helper"></i>Male
					</label>
				</div>
				<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Female' checked={data.gender==='Female'?true:false} />
						<i className="helper"></i>Female
					</label>
				</div>
				</div>

							</div>
					</div>


					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Status" toolTip={'Determine if the user is active'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.status} name="status" checked={data.status==='true'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="User Roles" toolTip={Tooltip.customerRole} />
					</div>
									
						<div className="col-sm-9 ">
						<Select  options={
				group&&group.map((node, id)=>{
					return {value:node.ID, key:id, label:node.groupName}
				})} 
                
onChange={handleSelect}  name="group_code"  value={{value:data.group_code, label:getItemLabel(data.group_code, group, 'groupName')}} styles={selectStyles} /> 
</div>
	</div>
	
	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Picture" toolTip={Tooltip.picture} />
					</div>
									
						<div className="col-sm-9 ">
						
						<div className="form-group">
							<img className="img-70" id="viewLogo" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+"/noImage.png"}} src={IMAGES_URL+"/users/"+data.photo} alt="Picture "  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
			{data.photo?	<button type="button" onClick={removeImageUrl} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>	:''}

			{image?	<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>	:''}

										</div>
							</div>
					</div>
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-edit"></i>Log History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                  

       	 <div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
		<TableRecord data={logList.filter(item=>item.code===params.code).sort(compareSort)}  columns={tableHeader}  /> 
            </div>
           
    
    </div></div>
					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 