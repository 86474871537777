import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import { LoadEffect } from '../components/loader'
import {Alerts} from '../components/notify'
import {ServerUrl, Token, config} from '../components/include'
import axios from 'axios'
import { compare} from '../components/globalFunction'


 const Navigation =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [navigation, setNavigation]=useState([])
    const [menu, setMenu]=useState([])
    const [restore, setRestore]=useState([])  
    const [isDisplay, setIsDisplay] = useState({ "0": true });     
    
  
	const fetchNavigation =()=>{
		  
        var sql ="Select * from tbl_navigation " ;
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller.php';

        axios.post(url, fd, config).then(result=>{ 
        if(Array.isArray(result.data) && result.data.length!==0){
            setRestore(JSON.parse(result.data.map((data)=>data.original)))
            setMenu(JSON.parse(result.data.map((data)=>data.menu)).sort(compare))
            setNavigation(result.data)
        }
        })
	}	

	

	const addMenu =()=>{
    setMenu(menu.concat(
        
    {menuName: "", description: "", menuIcon:'', status:true, isActive:'Yes', menuUrl:'', order:Number(menu.length)+1, ID:Math.random().toString(36).substr(2, 9), 

    submenu:[{subName: "", subUrl: "", status:true, isActive:'Yes', subDescription:'', ID:Math.random().toString(36).substr(2, 9), 

    subsubmenu:[{subsubName: "", subsubUrl: "", status:true, isActive:'Yes', subsubDescription:'', ID:Math.random().toString(36).substr(2, 9)}]

}]

}))
window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
   
}

const handleAddSubmenu=(mainIndex)=>{
    setMenu(menu.map((list, id) => 
       mainIndex === id
         ? { ...list, submenu: [...list.submenu, {subName:'',  subUrl:'', subDescription:'', isActive:'Yes', status:true, ID:Math.random().toString(36).substring(2, 9),  
         subsubmenu:[{subsubName: "", status:true, subsubUrl: "", isActive:'Yes', subsubDescription:'', ID:Math.random().toString(36).substr(2, 9)}] }] }
         : list
   )); 
}


const  handleChangeMenu=(e, i)=>{
  const { name, value } = e.target;
let newmenu = [...menu];
newmenu[i] = {...newmenu[i], [name]: value};
setMenu(newmenu);
}


const handleAddSubSubmenu=(subIndex, mainIndex)=>{      
        var newMenu = menu.filter((d, i) => i === mainIndex)[0]
         var newSubmenu = newMenu.submenu.map((list, id) => 
        subIndex === id
          ? { ...list, subsubmenu: [...list.subsubmenu, {subsubName: "", status:true, isActive:'Yes', subsubUrl: "", subsubDescription:'', ID:Math.random().toString(36).substr(2, 9)} ]}
          : list
    ); 
        setMenu(menu.map((list, id) => 
        mainIndex === id
        ? { ...list, submenu: newSubmenu}:list))
 }



 const removeSubSubMenu=(k, sub, main)=>{
    var newMenu = menu.filter((d, i) => i === main)[0]
    var newSubmenu = newMenu.submenu.map((list, id) => 
   sub === id
     ? { ...list, subsubmenu: list.subsubmenu.filter((o, id)=>id!==k)}
     : list
); 

setMenu(menu.map((list, id) => 
main === id
? { ...list, submenu: newSubmenu}:list))
   }

 const removeMenu=(index)=>{
    setMenu(menu.filter((d, i) => i !== index)); 
   }


  const removeSubmenu =(sub, main) =>{
    setMenu(menu.map((list, id) => 
    main === id
      ? { ...list, submenu: list.submenu.filter((d, i)=>i!==sub) }
      : list
)); 
  }


    
      const  handleToggleSub = (status, subIndex, mainIndex) => {
        const newmenu =JSON.parse(JSON.stringify(menu))
         newmenu[mainIndex].submenu[subIndex].status = !status;      
         setMenu(newmenu); 
        }

      const handleToggleDisplay=(id)=>{
        setIsDisplay({...isDisplay, [id]: !isDisplay[id]});
    }
    

  
    const createMenuInterface =()=>{
    
      return menu && menu.map((data, index) => (
   
          <div key={index} className="row mt-4">
      <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
        <div className="card  shadow-primary">
          <div className="card-body p-4">

              <div className="form-group">
                     <div className="input-group">
                     
          {isDisplay[index] ? <button type="button" onClick={()=>handleToggleDisplay(index)} className="btn btn-secondary btn-sm"> <i className="fa fa-minus"></i></button>:
       <button type="button" onClick={()=>handleToggleDisplay(index)} className="btn btn-primary btn-sm"> <i className="fa fa-plus"></i></button>
                  }
                  
                  &nbsp; 
               <input type="text" name="menuName" value={data.menuName} onChange={(e)=>handleChangeMenu(e, index)} className="form-control" placeholder="Menu Name" />&nbsp;
               <input type="text" name="description" value={data.description} onChange={(e)=>handleChangeMenu(e, index)} className="form-control" placeholder="Description" />
               &nbsp;
               <input type="text" name="menuUrl" value={data.menuUrl} onChange={(e)=>handleChangeMenu(e, index)} className="form-control" placeholder="Menu Url" />
               &nbsp;
               <input type="text" name="menuIcon" value={data.menuIcon} onChange={(e)=>handleChangeMenu(e, index)} className="form-control" placeholder="Menu Icon Class" />&nbsp;
               <input type="number" name="order" value={data.order} onChange={(e)=>handleChangeMenu(e, index)} className="form-control" placeholder="Order" />&nbsp;&nbsp;
                <button type="button"  className="btn btn-dark btn-sm" onClick={()=>handleAddSubmenu(index)} > Add Sub Menu</button>&nbsp;
               <button type="button" onClick={()=>removeMenu(index)} className="btn btn-danger btn-sm delete"><i className="fa fa-trash"></i></button>



               {data.isActive==='Yes'?
               <button type="button" onClick={()=>handleChangeMenuToggle('No', index)} className="btn ml-1 btn-success btn-sm "><i className="fa fa-check"></i></button>:
               <button type="button" onClick={()=>handleChangeMenuToggle('Yes',  index)} className="btn btn-warning ml-1 btn-sm "><i className="fa fa-times"></i></button>}
               </div>
               </div>

               <div className="row">
              <div className="col-sm-1 " ></div>
              <div className="col-sm-10">
               {/* create submenu here */}
               {data.submenu && data.submenu.map((smenu, i)=>{
                           return (   
                              <div  key={i} style={{display: isDisplay[index] ? 'block': 'none'}} >               
         <div  className="form-group">
  <div className="input-group">
             {smenu.status ? <button type="button" onClick={()=>handleToggleSub(smenu.status, i, index)} className="btn btn-secondary btn-sm "> <i className="fa fa-minus"></i></button>:
       <button type="button" onClick={()=>handleToggleSub(smenu.status, i, index)} className="btn btn-primary btn-sm"> <i className="fa fa-plus"></i></button>
                  }&nbsp; 
  
               <input type="text" name="subName" value={smenu.subName} onChange={(e)=>handleSectionChange(e, i, index)} className="form-control" placeholder="Submenu Name" />&nbsp;

               <input type="text" name="subUrl" value={smenu.subUrl} onChange={(e)=>handleSectionChange(e, i, index)} className="form-control" placeholder="Submenu Url" />&nbsp;

               <input type="text" name="subDescription" value={smenu.subDescription} onChange={(e)=>handleSectionChange(e, i, index)} className="form-control" placeholder="Description" />&nbsp;&nbsp;
               
                <button type="button"  className="btn btn-dark btn-sm" onClick={()=>handleAddSubSubmenu(i, index)} > Add Sub Submenu</button>&nbsp;
         <button type="button" onClick={()=>removeSubmenu(i, index)} className="btn btn-danger btn-sm delete"><i className="fa fa-trash"></i></button>
         
{smenu.isActive==='Yes'?
               <button type="button" onClick={()=>handleSectiontoggle('No',  i, index)} className="btn ml-1 btn-success btn-sm "><i className="fa fa-check"></i></button>:
               <button type="button" onClick={()=>handleSectiontoggle('Yes', i, index)} className="btn btn-warning ml-1 btn-sm "><i className="fa fa-times"></i></button>}
         </div></div>
         
         <div className="row">
              <div className="col-sm-1" > </div>
               <div className="col-sm-10" >
               
               {smenu.subsubmenu && smenu.subsubmenu.map((subsub, k)=>{
                           return (
                          <div  key={k} style={{display: smenu.status ? 'block': 'none'}} > 
<div className="form-group" >
                     <div className="input-group">
                  
                  &nbsp; 
               <input type="text" name="subsubName" value={subsub.subsubName} onChange={(e)=>handleSubSubChange(e, k, i, index)} className="form-control" placeholder="Sub submenu Name" />&nbsp;
               <input type="text" name="subsubUrl" value={subsub.subsubUrl} onChange={(e)=>handleSubSubChange(e, k, i, index)} className="form-control" placeholder="sub submenu Url" />
               &nbsp;
               <input type="text" name="subsubDescription" value={subsub.subsubDescription} onChange={(e)=>handleSubSubChange(e, k, i, index)} className="form-control" placeholder="Description" />&nbsp;&nbsp;
               <button type="button" onClick={()=>removeSubSubMenu(k, i, index)} className="btn btn-danger btn-sm delete"><i className="fa fa-trash"></i></button>
{subsub.isActive==='Yes'?
               <button type="button" onClick={()=>handleSubSubtoggle('No', k, i, index)} className="btn ml-1 btn-success btn-sm "><i className="fa fa-check"></i></button>:
               <button type="button" onClick={()=>handleSubSubtoggle('Yes', k, i, index)} className="btn ml-1 btn-warning btn-sm "><i className="fa fa-times"></i></button>}

               </div>
               </div></div>
               )})}

               </div>
               <div className="col-sm-1" ></div>
               </div> 
               
                </div>   
                           )})}                         
               {/* <!-- it end here --> */}
               </div> 
               <div className="col-sm-1" ></div>
               </div>

               </div>
               </div>
              {/* sub submenu start here */}



</div></div>   
))
  
  }
    
    




const  handleChangeMenuToggle=(value, i)=>{
let newmenu = [...menu];
newmenu[i] = {...newmenu[i], isActive: value};
setMenu(newmenu);
}


  const  handleSectiontoggle = (value, subIndex, mainIndex) => {
    const newmenu =JSON.parse(JSON.stringify(menu))
     newmenu[mainIndex].submenu[subIndex].isActive = value;      
     setMenu(newmenu); 
    }
    
    const  handleSubSubtoggle = (value, subsub, subIndex, mainIndex) => {     
        const newmenu =JSON.parse(JSON.stringify(menu))
         newmenu[mainIndex].submenu[subIndex].subsubmenu[subsub].isActive = value;      
         setMenu(newmenu); 
        }



    const  handleSectionChange = (e, subIndex, mainIndex) => {
      const { name, value } = e.target;      
      const newmenu =JSON.parse(JSON.stringify(menu))
       newmenu[mainIndex].submenu[subIndex][name] = value;      
       setMenu(newmenu); 
      }
      
      const  handleSubSubChange = (e, subsub, subIndex, mainIndex) => {
          const { name, value } = e.target;      
          const newmenu =JSON.parse(JSON.stringify(menu))
           newmenu[mainIndex].submenu[subIndex].subsubmenu[subsub][name] = value;      
           setMenu(newmenu); 
          }


    
     

  		

    const handleRestore =()=>{
        setMenu(restore)
    }
   
    


function handleSubmit(){  
    
    setNotice({...notice,  isLoading: true}) 
    
	const fd = new FormData();
	fd.append("menu", JSON.stringify(menu, null, 2));
	fd.append('jwt', Token)
  let url = ServerUrl+'/update_controller.php?tablename=tbl_navigation';
  axios.post(url, fd, config)
  .then(response =>{
      if (response.data.type ==='info'){
          Alerts('Saved!', 'info', response.data.message)
      } else{
          Alerts('Error!', 'danger', JSON.stringify(response.data))
  }  
  })
  .catch((error)=>{
      Alerts('Error!', 'danger', JSON.stringify(error.message)) 
  }).finally(()=>{   
        setNotice({...notice, 
            isLoading: false
        })
})
}


useEffect(()=>{
    fetchNavigation() 
 },[]);


        return (<Template
          menu="General"
          submenu="Navigations"
        >
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">
<PageHeader title="Navigation"
	   				menu="Settings"
					   menuLink="/settings"
					   subsubmenu="Navigation"
					   />

{/* The Page Body start here */}
<div className="page-body">

			
	<div className="row  ">
	<div className="col-sm-12">
		  <div id="contentHere" > 
		     
   
      <div className="row">
        <div className="col-lg-12">
           <div className="card ">
              <div className="card-body"> 
              
           
                <div className="row">
                <div className="alert alert-default">
               
     <button type="button" onClick={handleRestore}  id="saveClass" className="btn btn-primary shadow-primary "  >Restore Default</button>&nbsp;&nbsp;
     <button type="button" onClick={handleSubmit}   className="btn btn-success shadow-success"  >Update Navigation</button>
     &nbsp;&nbsp;
     <button type="button" onClick={addMenu}   className="btn btn-dark shadow-dark "  ><i className="fa fa-plus"></i> Add Menu</button>
                     </div>
                </div>  
            </div>
          </div>
        </div>
      </div> 
         
      
      <div className="row mt-4"  >
        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
          <div className="card  shadow-primary">
            <div className="card-body p-4">
           
            {createMenuInterface()} 

            </div>
          </div>
        </div>
      </div>  
          </div>
        </div>
      </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Navigation) 