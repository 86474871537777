import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {API_URL, config} from '../../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
 const Edit =()=> {
	 let myRef = useRef()
	 const [defaultUrl, setDefaultUrl] = useState('');
	
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	const [brand, setBrand] = useState([])
	const [category, setCategory] = useState([])
	const [productList, setProductList] = useState([])
	const [products, setProducts] = useState([])
	const [vendor, setVendor] = useState([])
	const [review, setReview] = useState([])
	const [blog, setBlog] = useState([])
	



	
const handleDelete =()=>{
	let close =   document.getElementById('btnWarningDialog-delAll')
	close.click();
	
		setNotice({...notice,  isLoading: true}) 

	let url = API_URL+'/collection/delete/'+params.code;
	
	axios.get(url, config).then(response=>{ 
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/cms/collections', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice,  isLoading: false  })
		  }) 	
}




const fetchContent =()=>{
	setNotice({...notice, isLoading: true  });
   
	let url = API_URL+'/collection/display/one/'+params.code;
	
	axios.get(url, config).then(result=>{ 
		if(Array.isArray(result.data.data)){

		
		setContent(result.data.data)
		setProductList(JSON.parse(result.data.data[0].item_list))
	}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, 
			isLoading: false
		});
	}) 

}


	
const fetchProducts =()=>{
		
	let url = API_URL+'/product/display/all';
	axios.get(url,  config).then(result=>{ 
		if(Array.isArray(result.data.data)){
			setProducts(result.data.data)
		}else{
			setProducts([])
		}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})

}


	const handleChange =(event)=>{
	const newContent =[...content]  
		let {name, value} = event.target;
		if(name==='status'){
			value =value ==='Active'?'InActive':'Active'
		}	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
 
 

    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		  
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  let url = API_URL+'/collection/update/'+params.code;
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
        let errors = {};   
		
        if(!values.title){
            errors.title =Tooltip.required;
        }
		if(!values.layout_type){
            errors.layout_type =Tooltip.required;
        }
       
       
        return errors;
		}
		
	
const getItemName =(code, field, item)=>{
	const result = item.length!==0?item.filter(item=>item.code===code):[]
	const answer = result.length!==0?result[0][field]:''
	return String(answer)
}

	const getProductName =()=>{
		let result = []
		productList&&productList.map((item, id)=>result.push({code:item, key:id, isPublished:getItemName(item, 'isPublished', products), productName:getItemName(item, 'productName', products)}))
		return result
	}


	const getBrandName =()=>{
		let result = []
		productList&&productList.map((item, id)=>result.push({code:item, key:id, published:getItemName(item, 'published', brand), manufacturerName:getItemName(item, 'manufacturerName', brand)}))
		return result
	}

	const getBlogName =()=>{
		let result = []
		productList&&productList.map((item, id)=>result.push({code:item, key:id, status:getItemName(item, 'status', blog), title:getItemName(item, 'title', blog)}))
		return result
	}

	const getCategoryName =()=>{
		let result = []
		productList&&productList.map((item, id)=>result.push({code:item, key:id, published:getItemName(item, 'published', category), categoryName:getItemName(item, 'categoryName', category)}))
		return result
	}

	const getReviewName =()=>{
		let result = []
		productList&&productList.map((item, id)=>result.push({code:item, key:id, isApproved:getItemName(item, 'isApproved', review), title:getItemName(item, 'title', review)}))
		return result
	}

	const getVendorName =()=>{
		let result = []
		productList&&productList.map((item, id)=>result.push({code:item, key:id, status:getItemName(item, 'status', vendor), vendorName:getItemName(item, 'vendorName', vendor)}))
		return result
	}	
	
	const handleAddNewProduct =(event)=>{
			event.preventDefault()
			
			let records = []
			const newProductList = JSON.parse(content[0].item_list);
			for (var i in products){
				if(products[i].status==='true'){
					records.push(products[i].code)
				}
			}

				//merger old prouct list with selected product
				const currentList = newProductList.concat(records)
				//remove the duplicate item
				const item_list =	[...new Set(currentList)];

				if(records.length===0){
				Alerts('Error!', 'danger', 'Please select at least one product to add')
				}else{

				UpdateProductsCollection(item_list, content[0].code)
				}
				let close =   document.getElementById('closeNewProduct')
				close.click();
		}


		const handleAddNewBrand =(event)=>{
			event.preventDefault()
			
			let records = []
			const newContent = [...content] 
			const newBrandList = JSON.parse(newContent[0].item_list);
			 const newBrand = [...brand]
				for (var i in newBrand){
					if(newBrand[i].status==='true'){
						records.push(newBrand[i].code)
					}
				}
				//merger old prouct list with selected brand
				const currentList = newBrandList.concat(records)
				//remove the duplicate item
				const item_list =	[...new Set(currentList)];

				if(records.length===0){
				Alerts('Error!', 'danger', 'Please select at least one brand to add')
				}else{

				UpdateProductsCollection(item_list, newContent[0].code)
				}
				let close =   document.getElementById('closeNewBrand')
				close.click();
		}

		const handleAddNewCategory =(event)=>{
			event.preventDefault()
			
			let records = []
			const newContent = [...content] 
			const newCategoryList = JSON.parse(newContent[0].item_list);
			 const newCategory = [...category]

				for (var i in newCategory){
					if(newCategory[i].status==='true'){
						records.push(newCategory[i].code)
					}
				}
				//merger old prouct list with selected brand
				const currentList = newCategoryList.concat(records)
				//remove the duplicate item
				const item_list =	[...new Set(currentList)];

				if(records.length===0){
				Alerts('Error!', 'danger', 'Please select at least one brand to add')
				}else{

				UpdateProductsCollection(item_list, newContent[0].code)
				}
				let close =   document.getElementById('closeNewCategory')
				close.click();
		}

		const handleAddNewVendor =(event)=>{
			event.preventDefault()
			
			let records = []
			const newContent = [...content] 
			const newVendorList = JSON.parse(newContent[0].item_list);
			 const newVendor = [...vendor]

				for (var i in newVendor){
					if(newVendor[i].status==='true'){
						records.push(newVendor[i].code)
					}
				}
				//merger old prouct list with selected brand
				const currentList = newVendorList.concat(records)
				//remove the duplicate item
				const item_list =	[...new Set(currentList)];

				if(records.length===0){
				Alerts('Error!', 'danger', 'Please select at least one brand to add')
				}else{

				UpdateProductsCollection(item_list, newContent[0].code)
				}
				let close =   document.getElementById('closeNewVendor')
				close.click();
		}

		const handleAddNewReview =(event)=>{
			event.preventDefault()
			
			let records = []
			const newContent = [...content] 
			const newReviewList = JSON.parse(newContent[0].item_list);
			 const newReview = [...review]

				for (var i in newReview){
					if(newReview[i].status==='true'){
						records.push(newReview[i].code)
					}
				}
				//merger old prouct list with selected brand
				const currentList = newReviewList.concat(records)
				//remove the duplicate item
				const item_list =	[...new Set(currentList)];

				if(records.length===0){
				Alerts('Error!', 'danger', 'Please select at least one brand to add')
				}else{

				UpdateProductsCollection(item_list, newContent[0].code)
				}
				let close =   document.getElementById('closeNewReview')
				close.click();
		}

		const handleAddNewBlog =(event)=>{
			event.preventDefault()
			
			let records = []
			const newContent = [...content] 
			const newBlogList = JSON.parse(newContent[0].item_list);
			 const newBlog = [...blog]

				for (var i in newBlog){
					if(newBlog[i].status==='true'){
						records.push(newBlog[i].code)
					}
				}
				//merger old prouct list with selected brand
				const currentList = newBlogList.concat(records)
				//remove the duplicate item
				const item_list =	[...new Set(currentList)];

				if(records.length===0){
				Alerts('Error!', 'danger', 'Please select at least one brand to add')
				}else{

				UpdateProductsCollection(item_list, newContent[0].code)
				}
				let close =   document.getElementById('closeNewBlog')
				close.click();
		}

		const handleRemoveProduct =(e, code)=>{
			e.preventDefault()
			let close =   document.getElementById('btnWarningDialog-'+code)
				close.click();
			const newContent = [...content] 
			//get old product list
			const newProductList = JSON.parse(newContent[0].item_list);
			const item_list = newProductList.filter(list=>list!==code)

			UpdateProductsCollection(item_list, newContent[0].code)
			
		}


		const UpdateProductsCollection =(item_list, code)=>{
          
            setNotice({...notice, 
                isLoading: true}) 
		var productList = JSON.stringify(item_list, null, 2)
		

		const fd = new FormData(); 
				fd.append('newValue', productList);
				fd.append('column', 'item_list');
				fd.append('code', code);
			   let url = API_URL+'/collection/change_field'
			  axios.post(url, fd, config)
			  .then(response =>{
			   if(response.data.status ===200){
                  Alerts('Saved!', 'success', response.data.message)
                      } else{
                  Alerts('Error!', 'danger', JSON.stringify(response.data))
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
                  fetchContent()
              })  
    }

		const tableCollectionListHeader = [

			{dataField: 'productName', text: 'Product', sort:true, editable: false, formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
			
			{dataField: 'isPublished', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.isPublished==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>},

			{dataField: 'code', text: 'Action', formatter: (cell, row)=><div><a href={`/products/edit/${row.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>&nbsp; 
			
			<WarningModal  handleAction={(e)=>handleRemoveProduct(e, row.code)} mID={row.code} />
		
			<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button></div>, editable: false}
		   
		 ];
	
	const TableCollectionList=(props)=>{
	
			 const { SearchBar } = Search;
			 
			 const customTotal = (from, to, size) => (
				<span >&nbsp;Showing { from } to { to } of { size } items</span>
			  );
			 const options = {
				showTotal: true,
			  paginationTotalRenderer: customTotal,
			  sizePerPageList: [
			  {text: '20', value: 20}, 
			  {text: '50', value: 50}, 
			  {text: '70', value: 70}, 
			  {text: '100', value: 100}, 
			  {text: '150', value: 150},
			  { text: 'All', value: props.data.length }]
			   
			  };
			 return  <ToolkitProvider search columnToggle 
						 keyField='code' data={ props.data } columns={ props.columns } >
							 
							 {
								 props => (
								 <div >
								   <div className="form-group">
									 <SearchBar
									 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
									 
									 
									 
		<div className="dt-buttons btn-group pull-right"> 
			 <button data-toggle="modal"   data-target='#addNewProduct' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add Product</button>
	 </div>                   
		 </div>
									 
						 <BootstrapTable
									 { ...props.baseProps }
												 striped
											 hover
											 condensed
											 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
										 pagination={paginationFactory(options) }
										 
									 />
								 </div>
								 )
							 }
		  </ToolkitProvider>
		  }
	
		  
		const handleCheckOne =(e)=>{  
			let {name, value} = e.target

			const currentContent = products.map((item)=>{
                   
				if(item.code ===name){
					return {...item, status:item.status==='false'?'true':'false'}
				}
		
				 return item
				  })

		  setProducts(currentContent)  
			   
			  }
		const  tableProductHeader = [
			{dataField: 'ID',  text: '#!', editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
			<label>
		<input type="checkbox" value={row.status}  name={row.code}  checked={row.status==='false'?false:true} onChange={(e)=>handleCheckOne(e)} />
				<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
			</label>
		</div>},
			{dataField: 'productName', text: 'Name', sort:true, editable: false},
			
			  {dataField: 'isPublished', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.isPublished==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>}
			  ];
		 
	
	
	const TableProductRecord=(props)=>{
	
				 const { SearchBar } = Search;
				 
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [
					  {text: '20', value: 20}, 
				  {text: '50', value: 50}, 
				  {text: '70', value: 70}, 
				  {text: '100', value: 100}, 
				  {text: '150', value: 150},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='ID' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										 <SearchBar
										 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
														 
			 </div>
										 
	<BootstrapTable
				{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
					
				/>
			</div>
			)
		}
</ToolkitProvider>
			  }







			  const tableBrandListHeader = [

				

				{dataField: 'published', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.published==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>},
	
				{dataField: 'code', text: 'Action', formatter: (cell, row)=><div><a href={`/brand/edit/${row.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>&nbsp; 
				
				<WarningModal  handleAction={(e)=>handleRemoveProduct(e, row.code)} mID={row.code} />
			
				<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button></div>, editable: false}
			   
			 ];
		
		const TableBrandList=(props)=>{
		
				 const { SearchBar } = Search;
				 
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [
				  {text: '20', value: 20}, 
				  {text: '50', value: 50}, 
				  {text: '70', value: 70}, 
				  {text: '100', value: 100}, 
				  {text: '150', value: 150},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='code' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										 <SearchBar
										 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
										 
										 
										 
			<div className="dt-buttons btn-group pull-right"> 
				 <button data-toggle="modal"   data-target='#addNewBrand' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add Brand</button>
		 </div>                   
			 </div>
										 
							 <BootstrapTable
										 { ...props.baseProps }
													 striped
												 hover
												 condensed
												 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
											 pagination={paginationFactory(options) }
											 
										 />
									 </div>
									 )
								 }
			  </ToolkitProvider>
			  }
		

			  const fetchBrand =()=>{
				
				let url = API_URL+'/brand/display/all'
				axios.get(url, config).then(result=>{ 
					if(Array.isArray(result.data.data)){
					
					setBrand(result.data.data)
					}else{
						setBrand([])
					}
				})
				.catch((error)=>{
					Alerts('Error!', 'danger', error.message)
				}) 
			}


		  const handleCheck =(e)=>{  
			let {name, value} = e.target

			const currentContent = brand.map((item)=>{
                   
				if(item.code ===name){
					return {...item, status:item.status==='false'?'true':'false'}
				}
		
				 return item
				  })


		  setBrand(currentContent)  
			   
			  }
		

		const  tableBrandHeader = [
			{dataField: 'ID',  text: '#!', editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
			<label>
		<input type="checkbox" value={row.status}  name={row.code}  checked={row.status} onChange={(e)=>handleCheck(e)} />
				<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
			</label>
		</div>},
			{dataField: 'manufacturerName', text: 'Name', sort:true, editable: false},
			
			  {dataField: 'published', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.published==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>}
			  ];
		 
	
	
	const TableBrandRecord=(props)=>{
	
				 const { SearchBar } = Search;
				 
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [
					  {text: '20', value: 20}, 
				  {text: '50', value: 50}, 
				  {text: '70', value: 70}, 
				  {text: '100', value: 100}, 
				  {text: '150', value: 150},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='ID' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										 <SearchBar
										 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
														 
			 </div>
										 
	<BootstrapTable
				{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
					
				/>
			</div>
			)
		}
</ToolkitProvider>
			  }





//category start here

			  const tableCategoryListHeader = [

				{dataField: 'categoryName', text: 'Name', editable: false, formatter:(cell, row)=><DisplayCategory code={row.code} />},

				{dataField: 'published', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.published==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>},
	
				{dataField: 'code', text: 'Action', formatter: (cell, row)=><div><a href={`/category/edit/${row.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>&nbsp; 
				
				<WarningModal  handleAction={(e)=>handleRemoveProduct(e, row.code)} mID={row.code} />
			
				<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button></div>, editable: false}
			   
			 ];
		
		const TableCategoryList=(props)=>{
		
				 const { SearchBar } = Search;
				 
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [
				  {text: '20', value: 20}, 
				  {text: '50', value: 50}, 
				  {text: '70', value: 70}, 
				  {text: '100', value: 100}, 
				  {text: '150', value: 150},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='code' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										 <SearchBar
										 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
										 
										 
										 
			<div className="dt-buttons btn-group pull-right"> 
				 <button data-toggle="modal"   data-target='#addNewCategory' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add Category</button>	
		 </div>                   
			 </div>
										 
							 <BootstrapTable
										 { ...props.baseProps }
													 striped
												 hover
												 condensed
												 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
											 pagination={paginationFactory(options) }
											 
										 />
									 </div>
									 )
								 }
			  </ToolkitProvider>
			  }
		

			  const DisplayCategory=({code})=>{	  
				let options = get_options();
			   return (
				   <div>
					{options.map((data) =>data.code===code?data.categoryName:'') }
					</div> 
			   )
					
			}
			
			  function get_options(parent="root", indent="") {
				var result= []
				category&&category.map(cat=>{
					if(cat.parentCategory===parent){
						result.push({code:cat.code, categoryName:indent+cat.categoryName})
						result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
					}
				})
				return result;
			  }


			  const fetchCategory =()=>{
				let url = API_URL+'/category/display/all';
		   axios.get(url, config).then(result=>{ 
			 
			   if(Array.isArray(result.data.data)){
			   setCategory(result.data.data)
			   }else{
				   setCategory([])	
			   }
   
			   })
			   .catch((error)=>{
				   Alerts('Error!', 'danger', error.message)
			   })
	   
		 }

			
				  
			  const handleCheckCat =(e)=>{  
				let {name, value} = e.target
	
				const currentContent = category.map((item)=>{
                   
					if(item.code ===name){
						return {...item, status:item.status==='false'?'true':'false'}
					}
			
					 return item
					  })

			  setCategory(currentContent)  
				   
				  }
			
	
			const  tableCategoryeader = [
				{dataField: 'ID',  text: '#!', editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={row.status}  name={row.code}  checked={row.status==='false'?false:true} onChange={(e)=>handleCheckCat(e)} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>},
				{dataField: 'categoryName', text: 'Name', editable: false, formatter:(cell, row)=><DisplayCategory code={row.code} />},
				
				  {dataField: 'published', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.published==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>}
				  ];
			 
		
		
		const TableCategoryRecord=(props)=>{
		
					 const { SearchBar } = Search;
					 
					 const customTotal = (from, to, size) => (
						<span >&nbsp;Showing { from } to { to } of { size } items</span>
					  );
					 const options = {
						showTotal: true,
					  paginationTotalRenderer: customTotal,
					  sizePerPageList: [
						  {text: '20', value: 20}, 
					  {text: '50', value: 50}, 
					  {text: '70', value: 70}, 
					  {text: '100', value: 100}, 
					  {text: '150', value: 150},
					  { text: 'All', value: props.data.length }]
					   
					  };
					 return  <ToolkitProvider search columnToggle 
								 keyField='ID' data={ props.data } columns={ props.columns } >
									 
									 {
										 props => (
										 <div >
										   <div className="form-group">
											 <SearchBar
											 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
															 
				 </div>
											 
		<BootstrapTable
					{ ...props.baseProps }
								striped
							hover
							condensed
							noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
						pagination={paginationFactory(options) }
						
					/>
				</div>
				)
			}
	</ToolkitProvider>
				  }





				  const fetchVendor =()=>{
					let url = API_URL+'/vendor/display/all';
			   axios.get(url, config).then(result=>{ 
				 
				   if(Array.isArray(result.data.data)){
					setVendor(result.data.data)
				   }else{
					setVendor([])	
				   }
	   
				   })
				   .catch((error)=>{
					   Alerts('Error!', 'danger', error.message)
				   })
			
			  }

				  const handleCheckVendor =(e)=>{  
					let {name, value} = e.target
					const currentContent = vendor.map((item)=>{
                   
						if(item.code ===name){
							return {...item, status:item.status==='false'?'true':'false'}
						}
				
						 return item
						  })

				  setVendor(currentContent)  
					   
					  }
				
		
				const  tableVendorHeader = [
					{dataField: 'ID',  text: '#!', editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
					<label>
				<input type="checkbox" value={row.status}  name={row.code}  checked={row.status} onChange={(e)=>handleCheckVendor(e)} />
						<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
					</label>
				</div>},
					{dataField: 'vendorName', text: 'Name', sort:true, editable: false},
					
					  {dataField: 'status', text: 'Is Active', sort:true, editable: false, formatter:(cell, row)=>row.status==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>}
					  ];
				 
			
			
			const TableVendorRecord=(props)=>{
			
						 const { SearchBar } = Search;
						 
						 const customTotal = (from, to, size) => (
							<span >&nbsp;Showing { from } to { to } of { size } items</span>
						  );
						 const options = {
							showTotal: true,
						  paginationTotalRenderer: customTotal,
						  sizePerPageList: [
							  {text: '20', value: 20}, 
						  {text: '50', value: 50}, 
						  {text: '70', value: 70}, 
						  {text: '100', value: 100}, 
						  {text: '150', value: 150},
						  { text: 'All', value: props.data.length }]
						   
						  };
						 return  <ToolkitProvider search columnToggle 
									 keyField='ID' data={ props.data } columns={ props.columns } >
										 
										 {
											 props => (
											 <div >
											   <div className="form-group">
												 <SearchBar
												 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
																 
					 </div>
												 
			<BootstrapTable
						{ ...props.baseProps }
									striped
								hover
								condensed
								noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
							pagination={paginationFactory(options) }
							
						/>
					</div>
					)
				}
		</ToolkitProvider>
					  }
		
		
					  const tableVendorListHeader = [

						{dataField: 'vendorName', text: 'Brand', sort:true, editable: false, formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
		
						{dataField: 'status', text: 'Is Active', sort:true, editable: false, formatter:(cell, row)=>row.status==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>},
			
						{dataField: 'code', text: 'Action', formatter: (cell, row)=><div><a href={`/vendor/edit/${row.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>&nbsp; 
						
						<WarningModal  handleAction={(e)=>handleRemoveProduct(e, row.code)} mID={row.code} />
					
						<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button></div>, editable: false}
					   
					 ];
				
				const TableVendorList=(props)=>{
				
						 const { SearchBar } = Search;
						 
						 const customTotal = (from, to, size) => (
							<span >&nbsp;Showing { from } to { to } of { size } items</span>
						  );
						 const options = {
							showTotal: true,
						  paginationTotalRenderer: customTotal,
						  sizePerPageList: [
						  {text: '20', value: 20}, 
						  {text: '50', value: 50}, 
						  {text: '70', value: 70}, 
						  {text: '100', value: 100}, 
						  {text: '150', value: 150},
						  { text: 'All', value: props.data.length }]
						   
						  };
						 return  <ToolkitProvider search columnToggle 
									 keyField='code' data={ props.data } columns={ props.columns } >
										 
										 {
											 props => (
											 <div >
											   <div className="form-group">
												 <SearchBar
												 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
												 
												 
												 
					<div className="dt-buttons btn-group pull-right"> 
						 <button data-toggle="modal"   data-target='#addNewVendor' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add Vendor</button>
				 </div>                   
					 </div>
												 
									 <BootstrapTable
												 { ...props.baseProps }
															 striped
														 hover
														 condensed
														 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
													 pagination={paginationFactory(options) }
													 
												 />
											 </div>
											 )
										 }
					  </ToolkitProvider>
					  }
				
		




					  const tableBlogListHeader = [

						{dataField: 'title', text: 'Title', sort:true, editable: false, formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
		
						{dataField: 'status', text: 'Is Active', sort:true, editable: false, formatter:(cell, row)=>row.status==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>},
			
						{dataField: 'code', text: 'Action', formatter: (cell, row)=><div><a href={`/blog/edit/${row.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>&nbsp; 
						
						<WarningModal  handleAction={(e)=>handleRemoveProduct(e, row.code)} mID={row.code} />
					
						<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button></div>, editable: false}
					   
					 ];
				
				const TableBlogList=(props)=>{
				
						 const { SearchBar } = Search;
						 
						 const customTotal = (from, to, size) => (
							<span >&nbsp;Showing { from } to { to } of { size } items</span>
						  );
						 const options = {
							showTotal: true,
						  paginationTotalRenderer: customTotal,
						  sizePerPageList: [
						  {text: '20', value: 20}, 
						  {text: '50', value: 50}, 
						  {text: '70', value: 70}, 
						  {text: '100', value: 100}, 
						  {text: '150', value: 150},
						  { text: 'All', value: props.data.length }]
						   
						  };
						 return  <ToolkitProvider search columnToggle 
									 keyField='code' data={ props.data } columns={ props.columns } >
										 
										 {
											 props => (
											 <div >
											   <div className="form-group">
												 <SearchBar
												 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
												 
												 
												 
					<div className="dt-buttons btn-group pull-right"> 
						 <button data-toggle="modal"   data-target='#addNewBlog' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add Blog</button>
				 </div>                   
					 </div>
												 
									 <BootstrapTable
												 { ...props.baseProps }
															 striped
														 hover
														 condensed
														 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
													 pagination={paginationFactory(options) }
													 
												 />
											 </div>
											 )
										 }
					  </ToolkitProvider>
					  }
				
					  const fetchBlog =()=>{
					
						/* var sql ="Select ID, title, status,  status, display_order, code from tbl_blog_post  " ;
				  
						const fd = new FormData();
						fd.append("sql", sql);
						let url = ServerUrl+'/fetchBySql_controller.php';
						fd.append("jwt", Token);
						axios.post(url, fd, config).then(result=>{  
							setBlog(result.data)
						})
						.catch((error)=>{
							Alerts('Error!', 'danger', error.message)
						}) */
				
				  }
	
					  const handleCheckBlog =(e)=>{  
						let {name, value} = e.target
						const currentContent = blog.map((item)=>{
                   
							if(item.code ===name){
								return {...item, status:item.status==='false'?'true':'false'}
							}
					
							 return item
							  })

					  setBlog(currentContent)  
						   
						  }
					
			
					const  tableBlogHeader = [
						{dataField: 'ID',  text: '#!', editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
						<label>
					<input type="checkbox" value={row.status}  name={row.code}  checked={row.status} onChange={(e)=>handleCheckBlog(e)} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>},
						
						{dataField: 'title', text: 'Title', sort:true, editable: false, formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
						
						  {dataField: 'status', text: 'Is Active', sort:true, editable: false, formatter:(cell, row)=>row.status==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>}
						  ];
					 
				
				
				const TableBlogRecord=(props)=>{
				
							 const { SearchBar } = Search;
							 
							 const customTotal = (from, to, size) => (
								<span >&nbsp;Showing { from } to { to } of { size } items</span>
							  );
							 const options = {
								showTotal: true,
							  paginationTotalRenderer: customTotal,
							  sizePerPageList: [
								  {text: '20', value: 20}, 
							  {text: '50', value: 50}, 
							  {text: '70', value: 70}, 
							  {text: '100', value: 100}, 
							  {text: '150', value: 150},
							  { text: 'All', value: props.data.length }]
							   
							  };
							 return  <ToolkitProvider search columnToggle 
										 keyField='ID' data={ props.data } columns={ props.columns } >
											 
											 {
												 props => (
												 <div >
												   <div className="form-group">
													 <SearchBar
													 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
																	 
						 </div>
													 
				<BootstrapTable
							{ ...props.baseProps }
										striped
									hover
									condensed
									noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
								pagination={paginationFactory(options) }
								
							/>
						</div>
						)
					}
			</ToolkitProvider>
						  }




						  const tableReviewListHeader = [

							{dataField: 'title', text: 'Title', sort:true, editable: false, formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
			
							{dataField: 'isApproved', text: 'Is Approved', sort:true, editable: false, formatter:(cell, row)=>row.isApproved==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>},
				
							{dataField: 'code', text: 'Action', formatter: (cell, row)=><div><a href={`/product-review/edit/${row.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>&nbsp; 
							
							<WarningModal  handleAction={(e)=>handleRemoveProduct(e, row.code)} mID={row.code} />
						
							<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button></div>, editable: false}
						   
						 ];
					
					const TableReviewList=(props)=>{
					
							 const { SearchBar } = Search;
							 
							 const customTotal = (from, to, size) => (
								<span >&nbsp;Showing { from } to { to } of { size } items</span>
							  );
							 const options = {
								showTotal: true,
							  paginationTotalRenderer: customTotal,
							  sizePerPageList: [
							  {text: '20', value: 20}, 
							  {text: '50', value: 50}, 
							  {text: '70', value: 70}, 
							  {text: '100', value: 100}, 
							  {text: '150', value: 150},
							  { text: 'All', value: props.data.length }]
							   
							  };
							 return  <ToolkitProvider search columnToggle 
										 keyField='code' data={ props.data } columns={ props.columns } >
											 
											 {
												 props => (
												 <div >
												   <div className="form-group">
													 <SearchBar
													 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
													 
													 
													 
						<div className="dt-buttons btn-group pull-right"> 
							 <button data-toggle="modal"   data-target='#addNewReview' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add Review</button>
					 </div>                   
						 </div>
													 
										 <BootstrapTable
													 { ...props.baseProps }
																 striped
															 hover
															 condensed
															 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
														 pagination={paginationFactory(options) }
														 
													 />
												 </div>
												 )
											 }
						  </ToolkitProvider>
						  }
					
						  const fetchReview =()=>{
						
						/* 	var sql ="Select ID, title, status,  isApproved, code from tbl_product_review  " ;
					  
							const fd = new FormData();
							fd.append("sql", sql);
							let url = ServerUrl+'/fetchBySql_controller.php';
							fd.append("jwt", Token);
							axios.post(url, fd, config).then(result=>{  
								setReview(result.data)
							})
							.catch((error)=>{
								Alerts('Error!', 'danger', error.message)
							}) */
					
					  }
		
						  const handleCheckReview =(e)=>{  
							let {name, value} = e.target
				
							const currentContent = review.map((item)=>{
                   
								if(item.code ===name){
									return {...item, status:item.status==='false'?'true':'false'}
								}
						
								 return item
								  })

						  setReview(currentContent)  
							   
							  }
						
				
						const  tableReviewHeader = [
							{dataField: 'ID',  text: '#!', editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
							<label>
						<input type="checkbox" value={row.status}  name={row.code}  checked={row.status} onChange={(e)=>handleCheckReview(e)} />
								<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
							</label>
						</div>},
							
							{dataField: 'title', text: 'Title', sort:true, editable: false, formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
							
							  {dataField: 'status', text: 'Status', sort:true, editable: false, formatter:(cell, row)=>row.status==='Active'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>}
							  ];
						 
					
					
					const TableReviewRecord=(props)=>{
					
								 const { SearchBar } = Search;
								 
								 const customTotal = (from, to, size) => (
									<span >&nbsp;Showing { from } to { to } of { size } items</span>
								  );
								 const options = {
									showTotal: true,
								  paginationTotalRenderer: customTotal,
								  sizePerPageList: [
									  {text: '20', value: 20}, 
								  {text: '50', value: 50}, 
								  {text: '70', value: 70}, 
								  {text: '100', value: 100}, 
								  {text: '150', value: 150},
								  { text: 'All', value: props.data.length }]
								   
								  };
								 return  <ToolkitProvider search columnToggle 
											 keyField='ID' data={ props.data } columns={ props.columns } >
												 
												 {
													 props => (
													 <div >
													   <div className="form-group">
														 <SearchBar
														 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
																		 
							 </div>
														 
					<BootstrapTable
								{ ...props.baseProps }
											striped
										hover
										condensed
										noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
									pagination={paginationFactory(options) }
									
								/>
							</div>
							)
						}
				</ToolkitProvider>
							  }
	
useEffect(()=>{
	fetchProducts()
	fetchContent()
	fetchCategory()
	fetchVendor()
	fetchBlog()
	fetchReview()
},[]);	
	
        return (<Template 
			menu="Content Management"
		submenu="Homepage">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Edit collection" description='Update homepage collection' icon='feather icon-layers'>
  <li className="breadcrumb-item"><a href="/cms/collections">Collections</a> </li>
					</PageHeader>
		



<div className="modal fade " id='addNewBlog' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add  Blog Post</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
  <div className="table-responsive">
        
        <TableBlogRecord data={blog}  columns={tableBlogHeader}  />
            </div>
    </div>
              <div className="modal-footer">
                  <button type="button"  id='closeNewBlog' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddNewBlog(event)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>


<div className="modal fade " id='addNewVendor' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add  Vendor</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
  <div className="table-responsive">
        
        <TableVendorRecord data={vendor}  columns={tableVendorHeader}  />
            </div>
    </div>
              <div className="modal-footer">
                  <button type="button"  id='closeNewVendor' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddNewVendor(event)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>



<div className="modal fade " id='addNewCategory' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add  Category</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
  <div className="table-responsive">
        
        <TableCategoryRecord data={category}  columns={tableCategoryeader}  />
            </div>
    </div>
              <div className="modal-footer">
                  <button type="button"  id='closeNewCategory' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddNewCategory(event)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>


<div className="modal fade " id='addNewProduct' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add  product</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
  <div className="table-responsive">
        
        <TableProductRecord data={products}  columns={tableProductHeader}  />
            </div>
    </div>
              <div className="modal-footer">
                  <button type="button"  id='closeNewProduct' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddNewProduct(event)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>



  <div className="modal fade " id='addNewReview' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
             <h6 className="modal-title"><i className='fa fa-info'></i> Add  Customer Review</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
  <div className="table-responsive">
        
        <TableReviewRecord data={review}  columns={tableReviewHeader}  />
            </div>
    </div>
              <div className="modal-footer">
                  <button type="button"  id='closeNewReview' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddNewReview(event)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>


  <div className="modal fade " id='addNewBrand' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
             <h6 className="modal-title"><i className='fa fa-info'></i> Add  brand</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
  <div className="table-responsive">
        
        <TableBrandRecord data={brand}  columns={tableBrandHeader}  />
            </div>
    </div>
              <div className="modal-footer">
                  <button type="button"  id='closeNewBrand' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddNewBrand(event)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>
{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-inverse btn-sm" onClick={(e)=>handleSubmit(e, '/cms/collections')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

<button type="button" onClick={(e)=>handleSubmit(e, '/cms/collection/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	<a href="/cms/collections" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={handleDelete} mID={'delAll'} /> 


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Collection info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Title" toolTip={'Collection title as you want it to appear on home pape'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="title" value={data.title} className='form-control '/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.title}</span>
							</div>
					</div>
					
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Collection Type" toolTip={'Select the type of collection you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="collection_type" disabled value={data.collection_type} className="form-control" onChange={handleChange}>
							<option value="product">Product</option>
							<option value="category">Category</option>
							<option value="brand">Brand</option>
							<option value="vendor">Vendor</option>
							<option value="blog">Blog</option>
							<option value="review">Customer Review</option>
							<option value="banner">Banner</option>
							<option value="video">Product Video</option>
							</select>
							</div>
							</div>
					</div>

{data.collection_type==='brand'?
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={data.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Brand Layout 1">Brand Layout 1</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:
					data.collection_type==='vendor'?


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={data.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Vendor Layout 1">Vendor Layout 1</option>
							<option value="Vendor Layout 2">Vendor Layout 2</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:
					data.collection_type==='video'?


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={data.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Video Layout 1">Video Layout 1</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>: data.collection_type==='product'?



					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={data.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Product Layout 1">Product Layout 1</option>
							<option value="Product Layout 2">Product Layout 2</option>
							<option value="Product Layout 3">Product Layout 3</option>
							<option value="Product Layout 4">Product Layout 4</option>
							<option value="Product Layout 5">Product Layout 5</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:data.collection_type==='blog'?

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={data.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Blog Layout 1">Blog Layout 1</option>
							<option value="Blog Layout 2">Blog Layout 2</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:data.collection_type==='review'?

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={data.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Customer Review Layout 1">Customer Review Layout 1</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:data.collection_type==='banner'?
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={data.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Layout 1">Layout 1</option>
							<option value="Layout 2">Layout 2</option>
							<option value="Layout 3">Layout 3</option>

							<option value="Layout 4">Layout 4</option>
							<option value="Layout 5">Layout 5</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>
					:
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Layout Type" toolTip={'Select the type of Layout you want'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={data.layout_type} className="form-control" onChange={handleChange}>
							<option value="">[None]</option>
							<option value="Category Layout 1">Category Layout 1</option>
							</select>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>}


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.display_order} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange}   name="display_order" value={data.display_order} className='form-control'/>
							
							</div>
							</div>
					</div>



					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Status" toolTip={'Check to appear on home page'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.status} name="status" checked={data.status==='Active'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>




							{data.collection_type==='product' || data.collection_type==='video' ?	<div className="row  ">
			<div className="col-sm-12">
								
			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th-list"></i>  Products</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					<div className="table-responsive">
		{<TableCollectionList  data={data.collection_type==='product' || data.collection_type==='video'?getProductName():[]} columns={tableCollectionListHeader}  />}
		</div>
					</div>



					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>: 
							
							data.collection_type ==='category'?


				<div className="row  ">
				<div className="col-sm-12">
					
				<div className="card z-depth-0">
				<div className="card-header">
				<h5><i className="fa fa-th-list"></i>  Category</h5>
				<div className="card-header-right">
				<ul className="list-unstyled card-option">
				<li><i className="feather icon-maximize full-card"></i></li>
				<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>

				</div>
				</div>
				<div className="card-block" >

				<div className="row">
				<div className="col-sm-12">

				<div className="card z-depth-0">
				<section className="card-block">
							
				<div className="row">

		<div className="table-responsive">

		{<TableCategoryList  data={data.collection_type==='category'?getCategoryName():[]} columns={tableCategoryListHeader}  />}

		</div>
				</div>

</section></div></div></div>

</div>

</div>
</div>

</div>:
data.collection_type ==='vendor'?
<div className="row  ">
			<div className="col-sm-12">
								
			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th-list"></i>  Vendor</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					<div className="table-responsive">
		{<TableVendorList  data={data.collection_type==='vendor'?getVendorName():[]} columns={tableVendorListHeader}  />}
		</div>
					</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
							</div>:



data.collection_type ==='blog'?

<div className="row  ">
			<div className="col-sm-12">
								
			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th-list"></i>  Blog Post</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					<div className="table-responsive">
		{<TableBlogList  data={data.collection_type==='blog'?getBlogName():[]} columns={tableBlogListHeader}  />}
		</div>
					</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
							</div>:


data.collection_type ==='review'?

<div className="row  ">
			<div className="col-sm-12">
								
			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th-list"></i>  Customer Review</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					<div className="table-responsive">
		{<TableReviewList  data={data.collection_type==='review'?getReviewName():[]} columns={tableReviewListHeader}  />}
		</div>
					</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
							</div>:
			''}




				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 