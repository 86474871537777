import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts, WarningModal} from '../components/notify'
import {CustomToggleList} from '../components/dataTable'
import {ServerUrl, Currency, FormatNumber, staffCode,API_URL, Token, config} from '../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

 const Customers =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    const [search, setSearch]= useState({
        email:'',
        isActive:'all'
    })
   
    const [group, setGroup] = useState([])
    const [content, setContent] = useState([]);
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const SearchTable =(event)=>{
        event.preventDefault();
        fetchContent();
     }

     const fetchGroup =()=>{
		var sql ="Select ID, groupName from tbl_groups order by groupName ASC" ;
		const fd = new FormData();
		fd.append("sql", sql);
        fd.append("jwt", Token);
		let url = ServerUrl+'/fetchBySql_controller.php';
		axios.post(url, fd, config).then(result=>{  
			setGroup(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
    }
    

    const fetchContent =()=>{
        setNotice({...notice, isDataFetching: true  });
        let url = API_URL+'/customers/display/all';
        axios.get(url, config).then(result=>{ 
            if(Array.isArray(result.data.data)){
            setContent(result.data.data)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 
    }


  
  

  const handleExportSelectedPdf =()=>{
    

let data = []
var newContent = JSON.parse(JSON.stringify(content));
for (var i in newContent){
    if(newContent[i].status===true){
        data.push(newContent[i].id)
    }
}
if(data.length===0){
    Alerts('Error!', 'danger', 'Please select customer to export') 
}else{

let url = ServerUrl+'/exportPDF_controller.php?tablename=tbl_selectedCustomer&data='+String(data);
window.open(url, '_self') 
    }
}
const handleExportSelected =()=>{
          
  
let data = []
var newContent = JSON.parse(JSON.stringify(content));
for (var i in newContent){
    if(newContent[i].status===true){
        data.push(newContent[i].ID)
    }
}
if(data.length===0){
    Alerts('Error!', 'danger', 'Please select customer to export') 
}else{

let url = ServerUrl+'/exportCsv_controller.php?tablename=tbl_selectedCustomer&data='+String(data);
window.open(url, '_self') 
    }
}

  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}

const handleCheckOne =(e)=>{  

    let {name, value} = e.target

    const newContent = [...content]
    const otherContent = newContent.filter(item=>item.code!==name)
    const custom = newContent.filter(item=>item.code===name)
    const status = value ===''? true:value==='true'?false:true 
    custom[0].status = status
    const currentContent = custom.concat(otherContent)
  setStatus({...status, checkAll:false})
   setContent(currentContent) 

       
      }
   

 const handleCheckAll =()=>{
   var newContent = JSON.parse(JSON.stringify(content));
   for (var i in newContent){
       newContent[i].status=!status.checkAll
   }
   setStatus({...status, checkAll:!status.checkAll})
   setContent(newContent)
}
const GeneralCheck =()=><div className="checkbox-fade fade-in-success">
<label>
<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
    <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
</label>
</div> 

    const tableHeader = [
        {dataField: 'code',  text: <GeneralCheck />, editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.code}  checked={row.status} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},
    {dataField: 'fullname', text: 'Fullname', formatter:(cell, row)=><span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{row.first_name + ' '+row.last_name}</span>},
    {dataField: 'email_address', text: 'Email', formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
    {dataField: 'telephone', text: 'Telephone'},
    
    {dataField: 'status', text: 'Status', sort: true, editor: {
        type: Type.SELECT, options:[{value:'Active', label:'Active'},
        {value:'Inactive', label:'Inactive'}]
      }, formatter:(cell)=>cell==='Active'? <div className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i title="Double click to change" className="fa fa-times  badge-inverse-danger"></i></div>},


    {dataField: 'company', text: 'Company', formatter:(cell, row)=><span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div><a href={`/customer/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a></div>, editable: false}
     ];
    
    


     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, code)=>{       
            const fd = new FormData(); 
                fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('code', code);
           let url = API_URL+'/company/client/change_field'
          axios.post(url, fd, config)
              .then(result => fetchContent())
              .catch((error)=>Alerts('Error!', 'danger', error.message)) 
            } 
         const { SearchBar } = Search;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                 
                                 
                                 <div className="dt-buttons btn-group pull-right"> 
    <a href="/customer/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</a>




    <div className="btn-group dropdown-split-primary">
    <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <i className="fa fa-download"></i>Export
    </button>
    <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <a className="dropdown-item waves-effect waves-light" href={ServerUrl+'/exportCsv_controller.php?tablename=tbl_customers&recordType=allfound'}>Export to Excel (all found)</a> 
        <a href="#!" onClick={handleExportSelected} className="dropdown-item waves-effect waves-light" >Export to Excel (only selected)</a>
        <a className="dropdown-item waves-effect waves-light" href={ServerUrl+'/exportPDF_controller.php?tablename=tbl_customers'}>Download as PDF (all found)</a>
        <a className="dropdown-item waves-effect waves-light" onClick={handleExportSelectedPdf} href="#!">Download as PDF (only selected)</a>
    </div>
</div>    



 </div>                   

                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                              beforeSaveCell: (oldValue, newValue, row, column) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.code);
                                
                               return '';
                              }
                              
                            }
                                      }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      





     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
			menu="People"
		submenu="Customers">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Customers" description='Displaying all customers' icon='feather icon-user'>
					</PageHeader>
        


{/* The Page Body start here */}
<div className="page-body">
        

<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Email Address" toolTip={'Customer email'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="email" value={search.email} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="isActive" toolTip={'Customer status'} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select name="isActive" value={search.isActive} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="true">Activated</option>
                        <option value="false">Deactivated</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Customers</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Customers) 