import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

import {Alerts} from '../components/notify'
import axios from 'axios'
import {compare } from '../components/globalFunction'
import { Token, config, ServerUrl } from '../components/include';

 const OnlineCustomer =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });

    const [logList, setLogList] = useState([])

    const fetchLog =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select fullname, lastIp, lastUse from tbl_customers where status = 'Online'" ;
          
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setLogList(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }

    const tableInHeader = [  
        {dataField: 'fullname', footer:'Name', text: 'Name', footerStyle: {
            backgroundColor: '#c8e6c9'}, formatter:(cell, row)=>row.fullname, headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},        
        
          {dataField: 'lastUse', footer:'Last Login', text: ' Last Login', footerStyle: {
            backgroundColor: '#c8e6c9'},headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},

            {dataField: 'lastIp', footer:'IP Address', text: 'IP Address', footerStyle: {
                backgroundColor: '#c8e6c9'}, headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},  
                
            {dataField: 'browser', footer:'Browser', text: 'Browser', footerStyle: {
                    backgroundColor: '#c8e6c9'},headerStyle: {backgroundColor: '#f0ff4d'}, editable:false},

            {dataField: 'location', footer:'Location', text: 'Location', footerStyle: {
                        backgroundColor: '#c8e6c9'},headerStyle: {backgroundColor: '#f0ff4d'}, editable:false}
   
     ];
    


    
    
     const TableRecord=(props)=>{
         const { SearchBar } = Search;  
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                             <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                     <BootstrapTable 
                        { ...props.baseProps }
                        striped
                    hover
                    condensed
                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                pagination={paginationFactory(options) }
              
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
   
useEffect(()=>{
	fetchLog()
},[]);	
	
        return (<Template 
			menu="People"
		submenu="Online Customers">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Online Customers" description='Current website visitor' icon='feather icon-user'>
 
					</PageHeader>
        
        


{/* The Page Body start here */}
<div className="page-body">
         

							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Online Customers</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
          {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={logList.sort(compare)}  columns={tableInHeader}  />}  
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(OnlineCustomer) 