import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { Popover, LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, DirectUrl} from '../../components/include'
import axios from 'axios'
import {PictureUrl, StaffId} from '../../components/include'
import { useParams } from 'react-router-dom'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {  compare } from '../../components/globalFunction'
import Select from 'react-select'
import Country from "../../components/country";
 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});

	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	const [province, setProvince] = useState([]);
	const [lga, setLga] = useState([])
	const [content, setContent] = useState([])
	
	const [note, setNote] = useState({
		ID:Math.random().toString(36).substr(2,9),
		dateCreate:new Date().toISOString().slice(0,10)+' '+ new Date().toLocaleTimeString(),
		note:''
	
	})


    const handleDeleteWithImage =(imageLink)=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
		const config = {
			headers: { 'content-type': 'multipart/form-data'}
		} 

		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'code')
		fd.append('data', params.code)
		fd.append('imagelink', imageLink)
		  
	let url = ServerUrl+'/deleteSingleWithImage_controller.php?tablename=tbl_vendors';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/vendors', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
}

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select * from tbl_vendors where code = '" +params.code+"'";
        
        const config = {
            headers: { 'content-type': 'multipart/form-data'}
        }    
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{ 
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
	const [defaultUrl, setDefaultUrl] = useState('');
	const [image, setImage] = useState('');

	const handleChangeNumber =(event)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		if(name==='pageName'){
			value = value.replace(/,?[ ]/g, '-')
		}
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}

	const handleChangeCheck =(event)=>{
		const newContent =JSON.parse(JSON.stringify(content))  
			let {name, value} = event.target;
			value = value ==='true'?'false':'true'	
			newContent[0][name]= value
			setContent(newContent);
			setErrors({...errors, [name]:''});
		}

	const handleChangeDescription =(data)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		newContent[0].description = data
		setContent(newContent);
	}

	const setLogo =()=>{
		const img = document.getElementById('viewLogo');
		img.src = PictureUrl+"/images/noImage.png" 
		document.getElementById('logo').value = '';
		setImage('')
	}
	const removeImageUrl =()=>{
		const newContent =JSON.parse(JSON.stringify(content))
		 newContent[0].imageUrl = '' 
		setContent(newContent);

		const img = document.getElementById('viewLogo');
		img.src = PictureUrl+"/images/noImage.png" 
		document.getElementById('logo').value = '';
		setImage('')
	}
	const [panel, setPanel] = useState({
		info:true,
		display:true,
		seo:false,
		
		basic:true,
		seoDisplay:false,
		noteDisplay:false,
		infoDisplay:false,
		displayDisplay:false
	})

	const goAdvance=()=>{
		setPanel({...panel,
		seoDisplay:true,
		noteDisplay:true,
		infoDisplay:true,
		displayDisplay:true,
		basic:false
	})
	}

	const goBasic=()=>{
		setPanel({...panel,
		seoDisplay:false,
		infoDisplay:false,
		displayDisplay:false,
		basic:true
	})
	}
	const fetchProvince =()=>{
	
		var sql ="Select ID, stateName from tbl_states order by ID ASC";
		
		const config = {
			headers: { 'content-type': 'multipart/form-data'}
		}    
		const fd = new FormData();
		fd.append("sql", sql);
		let url = ServerUrl+'/fetchBySql_controller.php';
		
		axios.post(url, fd, config).then(result=>{ 
			setProvince(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}
	
	const fetchLga =()=>{
		
		var sql ="Select ID, stateId, lgaName from tbl_lga"
		
		const config = {
			headers: { 'content-type': 'multipart/form-data'}
		}    
		const fd = new FormData();
		fd.append("sql", sql);
		let url = ServerUrl+'/fetchBySql_controller.php';
		
		axios.post(url, fd, config).then(result=>{ 
			setLga(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}
	const handleSelect = (option, action)=>{
		const newContent = JSON.parse(JSON.stringify(content)) 
		newContent[0][action.name] = option.value
		setContent(newContent);
	}

	const fetchNameByField =(Id, table, field)=>{
		const result = table.filter(list=>list.ID===Id);
	  const answer = result.map((ans)=>ans[field])
		return answer
	  }

    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		  const config = {
			  headers: { 'content-type': 'multipart/form-data'}
		  }    
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		 
			if(image!==''){
				fd.append("image", image);
			}
			
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_vendors';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
        
        if(!values.vendorName){
            errors.vendorName =Tooltip.required;
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }

        return errors;
        }


		const  handleFile = function(fieldName){
			return function(newValue){                 
				const value = newValue.target.files[0]
			  // setImage(value)
		   readURL(newValue);
		   }
		   
		  } 
		
		   const readURL = (input)=>{       
			let displayMessage = '';
			  var fileName =  document.getElementById('logo').value;
			var idxDot = fileName.lastIndexOf(".") + 1;
			var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
			if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
				//TO DO    
				
				 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
			if (FileSize > 0.5) {
				displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500 Kb';
			   document.getElementById(input.target.name).value = '';
			   setImage('')
			} else {
				if (input.target.files && input.target.files[0]) { //Check if input has files.
				  var reader = new FileReader();//Initialize FileReader.
				  reader.onload = function (e) {
			   			const newContent =JSON.parse(JSON.stringify(content))
							 newContent[0].imageUrl = '' 
							 setContent(newContent);
							 
				  const img = document.getElementById('viewLogo');
							 img.src = e.target.result             
							
				  };
				  reader.readAsDataURL(input.target.files[0]); 
				  setImage(input.target.files[0])
				  
				}
			}
			}else{
				displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500kb'
			   document.getElementById(input.target.name).value = '';
			   setImage('');
			}   
			
			if(displayMessage.length > 0){
			
				Alerts('Error!', 'danger', displayMessage)
			  }
		} 

		const handleRemoveNote =(ID)=>{
			
			const newContent = JSON.parse(JSON.stringify(content)) 
				const newNote = JSON.parse(newContent[0].notes);
			newContent[0].notes = JSON.stringify(newNote.filter(list=>list.ID!==ID))
				setContent(newContent)
		 }

		const handleAddNote =(event)=>{
			event.preventDefault()
			let close =   document.getElementById('closeNote')
			close.click();
			const newContent = JSON.parse(JSON.stringify(content)) 
			const newNote = JSON.parse(newContent[0].notes);
			newContent[0].notes = JSON.stringify(newNote.concat(note))
			setContent(newContent)
			setNote({
				ID:Math.random().toString(36).substr(2,9),
			dateCreate:new Date().toISOString().slice(0,10)+' '+ new Date().toLocaleTimeString(),
			note:''
			})
		}


		const  tableVendorNoteHeader = [
			{dataField: 'dateCreate',  text: 'Created On', editable: false},
			{dataField: 'note',  text: 'Note'},
			  {dataField: 'ID', text: 'Action', formatter: (cell)=><div>
				  <button type="button" className="btn btn-danger btn-sm" onClick= {()=>handleRemoveNote(cell)} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>
			  </div>, editable: false},
		
					  ];
				 
			
			
			const TableVendorNoteRecord=(props)=>{
				const handleUpdate=(column, newValue, ID)=>{     
					
					 const newContent = JSON.parse(JSON.stringify(content)) 
					const newNote = JSON.parse(newContent[0].notes);
			
					const otherNote = newNote.filter((list)=>list.ID!==ID)
					const noteData = newNote.filter((list)=>list.ID===ID)	
					noteData[0][column.dataField] = newValue
					const currentNote = otherNote.concat(noteData)
					newContent[0].notes = JSON.stringify(currentNote)
					setContent(newContent)
					
				} 
						 const { SearchBar } = Search;
						 
						 const customTotal = (from, to, size) => (
							<span >&nbsp;Showing { from } to { to } of { size } items</span>
						  );
						 const options = {
							showTotal: true,
						  paginationTotalRenderer: customTotal,
						  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
						  { text: 'All', value: props.data.length }]
						   
						  };
						 return  <ToolkitProvider search columnToggle 
									 keyField='ID' data={ props.data } columns={ props.columns } >
										 
										 {
											 props => (
											 <div >
											   <div className="form-group">
												
										 
			<div className="dt-buttons btn-group"> 
				 <button data-toggle="modal"   data-target='#addNote' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New Note</button>
		 </div>                   
			 </div>
												 
			<BootstrapTable
						{ ...props.baseProps }
									striped
								hover
								condensed
								noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
							pagination={paginationFactory(options) }
							cellEdit={ cellEditFactory({
								mode: 'dbclick',
								blurToSave: true,
								autoSelectText: true,
					  beforeSaveCell: (oldValue, newValue, row, column) => {
						if(oldValue !==newValue){
						handleUpdate(column, newValue, row.ID);
						
					   return true;
					  }
					  
					}
							  }) }
						/>
					</div>
					)
				}
		</ToolkitProvider>
					  }



	const handleChangeNote =(event)=>{
	let {name, value} = event.target;
	setNote({...note, [name]:value})
}


useEffect(()=>{
	fetchContent()
	fetchProvince()
	fetchLga()
},[]);	
	
        return (<Template menu="People"
        submenu="Vendors"
        pageUrl="/vendor/edit/">
            
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Edit Vendor details"
	   				menu="Vendors"
					   menuLink="/vendors"
					   submenu="Edit Vendor Details"
					   />


<div className="modal fade " id='addNote' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add Vendor Note</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Note" toolTip={'Enter this order note message'} />
					</div>
									
						<div className="col-sm-9 ">
					<textarea rows="5" name="note" className="form-control" onChange={handleChangeNote} value={note.note} >{note.note}</textarea>
							</div>
				</div>
			
			
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeNote' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={handleAddNote} className="btn btn-inverse">Save</button>
              </div>
          </div>
      </div>
  </div>




{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success btn-sm" onClick={(e)=>handleSubmit(e, '/vendors')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/vendor/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	<a href="/vendors" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDeleteWithImage(data.imageUrl)} mID={'delAll'} /> 

	{panel.basic?
	<button type="button" onClick={goAdvance} className="btn  btn-sm pull-right"> Basic Mode <i className="fa fa-check"></i></button> :
	<button type="button" onClick={goBasic} className="btn btn-sm btn-primary pull-right">  Advanced Mode <i className="fa fa-check"></i></button>}

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, info:!panel.info})}>
			<h5><i className="fa fa-info"></i> Vendor Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.info?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.info?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                              
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.vendorName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} name="vendorName" value={data.vendorName} className={errors.vendorName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.vendorName}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Description" toolTip={'Description of the vendor'} />
					</div>
									
						<div className="col-sm-9 ">
						<QuillText value={data.description} onChange={handleChangeDescription} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Email" toolTip={Tooltip.email} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  name="email" value={data.email} className={errors.email  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.email}</span>
							</div>
					</div>

					
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Active" toolTip={'A value indicating whether a vendor is active'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.isActive} name="isActive" checked={data.isActive==='true'?true:false} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
			
			
	{panel.infoDisplay?	<>	
				
				
				<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Admin Comment" toolTip={Tooltip.adminComment} />
		</div>
						
			<div className="col-sm-9 ">
				
			<textarea type="text" className="form-control" rows="5" name="comment" value={data.comment} onChange={handleChange}>{data.comment}
			</textarea>

				</div> 
		</div>	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Picture" toolTip={Tooltip.picture} />
					</div>
									
						<div className="col-sm-9 ">
						
						<div className="form-group">
							<img className="img-70" id="viewLogo" onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/images/noImage.png"}} src={PictureUrl+"/images/vendors/"+data.imageUrl} alt="Picture "  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
			{data.imageUrl?	<button type="button" onClick={removeImageUrl} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>	:''}

			{image?	<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>	:''}

										</div>
							</div>
					</div></>:''}	
	</section></div>
	
	
	{panel.infoDisplay?	<div className="card z-depth-0">
						<section className="card-block">
                                           

						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Country" toolTip={'Select Country'} />
					</div>
									
						<div className="col-sm-9 ">
						<Country handleSelect={handleSelect} country={data.country} />
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="State " toolTip={'Select State / province'} />
					</div>
									
						<div className="col-sm-9 ">
						{data.country==='Nigeria'?
						<Select  
				  options={province&&province.map((state, idx)=> {
					return {key:idx, value: state.ID, label: state.stateName, index:idx };
				  })}
onChange={handleSelect}  name="stateId"  value={{value: data.stateId, label: fetchNameByField(data.stateId, province, 'stateName')}} styles={selectStyles} />:'Others'} 

							</div>
				</div>

				
		{data.country==='Nigeria'?		<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Lga" toolTip={'Enter Lga'} />
					</div>
									
						<div className="col-sm-9 ">
			<Select   options={lga&&lga.map((lg, idx)=> {
                         return {key:idx, value: lg.ID, label: lg.lgaName, index:idx };
                                  })}
onChange={handleSelect}  name="lga" value={{value: data.lga, label: fetchNameByField(data.lga, lga, 'lgaName')}} styles={selectStyles} />

							</div>
				</div>:''}

				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="City" toolTip={'Enter city'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChange}  name="city" value={data.city} />
							</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Address" toolTip={'Enter Address'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChange}  name="address" value={data.address} />
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Telephone" toolTip={'Enter Telephone'} />
					</div>
					
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChange}  name="telephone" value={data.telephone} />
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-3">
						<FormLabel name ="Zip code" toolTip={'Enter Zip / postal code'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChange}  name="postalCode" value={data.postalCode} />
						</div>
				</div>
				
	</section></div>:''}
	
	</div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>
				<div  className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, display:!panel.display})}>
			<h5><i className="fa fa-television"></i> Display</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.display?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.display?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						{panel.displayDisplay?<>	
				
			
				<div className="form-group row">
				<div className="col-sm-3">
						<FormLabel name ="Allow page size" toolTip={Tooltip.customerPageSize}  />
				</div>
								
					<div className="col-sm-9 ">
					<div className="checkbox-fade fade-in-success">
					<label>
				<input type="checkbox" value={data.allowPageSize} name="allowPageSize" checked={data.allowPageSize==='true'?true:false} onChange={handleChangeCheck} />
						<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
					</label>
				</div>
						</div>
				</div>
				
				<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Page Size"  toolTip={Tooltip.customerPageSize} />
		</div>
						
			<div className="col-sm-4 ">
					<input type="number" className="form-control" onChange={handleChange}  name="pageSize" value={data.pageSize} />
						</div>
		</div>

				<div className="form-group row">
				<div className="col-sm-3">
						<FormLabel name ="Page Size Option" toolTip={Tooltip.pageSizeOption} />
				</div>
								
					<div className="col-sm-9 ">
					<input type="text" className="form-control" name="pageSizeOption" 
					value={data.pageSizeOption}  onChange={handleChange}  />

						</div>
				</div>
</>:''}

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={data.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>



				{panel.seoDisplay?			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, seo:!panel.seo})}>
			<h5><i className="fa fa-search-plus"></i> SEO</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.seo?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.seo?		<div className="card-block" >
		
 <div  className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Name" toolTip={Tooltip.seoName} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="text" className="form-control" name="pageName" 
						value={data.pageName} onChange={handleChange} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Title" toolTip={Tooltip.metaTitle} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaTitle" 
						value={data.metaTitle} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Keyword" toolTip={Tooltip.metaKeywords} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaKeywords" 
						value={data.metaKeywords} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Description" toolTip={Tooltip.metaDescription} />
					</div>
									
						<div className="col-sm-9 ">
						
						<textarea type="text" className="form-control" rows="5" name="metaDescription" 
						value={data.metaDescription} onChange={handleChange}>{data.metaDescription}</textarea>
					</div></div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}






							{panel.noteDisplay?		<div className="row " id="vendorNote">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-sticky-note-o"></i> Vendor Notes</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li>
                          <i className="feather minimize-card icon-minus"></i>
                        </li>
				</ul>
			</div>
		</div>
	<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                             

					<div className="row">
					<div className="table-responsive">
		 {<TableVendorNoteRecord data={JSON.parse(data.notes).sort(compare)} columns={tableVendorNoteHeader}  />} 
		</div>
						</div>


					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>:''}

				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 