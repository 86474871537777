import React, {useState, useEffect} from 'react'
import Preloader from './preloader'
import Footer from './footer'
import Topbar from './topbar'
import LeftSidebar from './leftSidebar'
import {decrypt, authenticate} from './authentication'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux';
import {Alerts, WarningModal} from './notify'
import {ServerUrl, config, Token, staffCode} from './include'
import axios from 'axios'
import { compare } from './globalFunction'

const Template = (props) =>{
    
    const dispatch = useDispatch()
    const [user, setUser] = useState({
		userPassword:'',
        loading:false
    })
    const [errors, setErrors] = useState({});
    const handleChange = event =>{		
        let {name, value} = event.target;	
       setUser({...user, [name] : value });
    }    


  const fetchNavigation =async()=>{

    var sql ="Select roles, menu from tbl_navigation WHERE ID =1";

    const fd = new FormData();
    fd.append("jwt", Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller.php';


    await  axios.post(url, fd, config).then((result)=>{
      
      if(Array.isArray(result.data) && result.data.length!==0){
        try{

          let response = JSON.parse(result.data[0].menu).sort(compare)
            let newMenu = JSON.parse(result.data[0].roles)
      
          let roles = []
          let menuLink = []

           for (var i in newMenu){
            if(newMenu[i].status===true){
            roles.push(newMenu[i].ID)
            menuLink.push(newMenu[i].menuUrl)
            }
    
     for(var k in newMenu[i].submenu){
            if(newMenu[i].submenu[k].status===true){
            roles.push(newMenu[i].submenu[k].ID)
            menuLink.push(newMenu[i].submenu[k].subUrl)
            }
    
         for(var m in newMenu[i].submenu[k].subsubmenu){
            if(newMenu[i].submenu[k].subsubmenu[m].status===true){
            roles.push(newMenu[i].submenu[k].subsubmenu[m].ID)
            menuLink.push(newMenu[i].submenu[k].subsubmenu[m].subsubUrl) 
            }
         }
        }
    }


    dispatch({
      type:'SET_RECORDS',
      name:'menuLink',
      data:menuLink
  })
  dispatch({
      type:'SET_RECORDS',
      name:'roles',
      data:roles
  })
  dispatch({
      type:'SET_RECORDS',
      name:'sidebar',
      data:response
  })

  }catch(e){
    //console.log(e)
  }

        }

      
      })
      
      .catch((error)=>{
          Alerts('danger', error.message)
      })

  }


	const submit=()=>{
       
		if(!user.userPassword){
            setErrors({errors, userPassword:'Please enter your password'})
        }else{

             setUser({...user, loading:true})
        const fd = new FormData();
       
		fd.append('jwt', Token)
        
        fd.append('userCode', staffCode)
		fd.append('userPassword', user.userPassword)
           let url = ServerUrl+'/update_controller.php?tablename=tbl_unlock_screen';
		axios.post(url, fd, config)
		.then(response =>{
	    if (response.data.type ==='success'){
            document.getElementById('closeLockscreen').click()
			
			}else{
                setErrors({errors, userPassword:response.data.message})
               
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
            window.location.href='/'
			 
		}).finally(()=>{
            setUser({...user, loading:false})
        })     
} 

    }
useEffect(()=>{
   authenticate()
   fetchNavigation()
    },[]);
    return (<>
        {/* <!-- Pre-loader start --> */}
        <Preloader />
        
        <div id="pcoded" className="pcoded">
    <div className="pcoded-overlay-box"></div>

    <div className="pcoded-container navbar-wrapper">

       {/* Header Component Here */}

       <Topbar />


       <a href="#!" data-toggle="modal" id='openlockscreenModal' data-target='#lockscreen' ></a>
  <div className="modal fade " data-backdrop="static" data-keyboard="false" id='lockscreen' tabIndex="-1" role="dialog">
        <div className="modal-dialog " role="document">
            <div className="modal-content">
               
                <div className="modal-body">
                <div className="row m-b-20">
			<div className="col-md-12">
				<h3 className="text-center"><i className="feather icon-lock text-primary f-60 p-t-15 p-b-20 d-block"></i></h3>
			</div>
</div>
			<div className="form-group form-primary">
        <button type="button" className="close" id='closeLockscreen' data-dismiss="modal" aria-label="Close"></button>
				<input type="password"  name="userPassword"  value={user.userPassword} placeholder="Enter your password to unlock" onChange={handleChange} className={errors.userPassword  ? 'form-control form-control-danger' : 'form-control'} /> 
				
				
			</div><span className=" form-bar error">{ errors.userPassword}</span>
			<div className="row">
				<div className="col-md-12">
				{user.loading===false?	<button type="button" onClick={submit}  className="btn btn-primary btn-md btn-block "><i className="icofont icofont-lock"></i> UnLock Screen </button>:<button type="button" disabled='true' className="btn btn-warning btn-md "><i className="icofont icofont-unlock"></i> Please wait... </button>}
				</div>
			</div>
                </div>
            </div>
        </div>
    </div>
 {/* 
 <!-- Sidebar inner chat end--> */}
 <div className="pcoded-main-container" >
     <div className="pcoded-wrapper">
        {/* Navbar start here */}

        <LeftSidebar 
        menu = {props.menu}
        submenu= {props.submenu}
        /> 

    <div className="pcoded-content">
        <div className="pcoded-inner-content">

{/*         uncomment this for forbidden access */}
  {/* {roles.includes(props.pageUrl)?props.children:<Error403 />} */} 
            {/* <!-- Main-body start --> */}
           
         
         {props.children}  

            

            {/* Footer Component Here */}
                <Footer />

{/* The Main Body close here */}
            </div>
            </div>          
           </div></div>
</div>
            </div> 
        
    </>)
}
export default React.memo(Template)