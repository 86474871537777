import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../../components/notify'
import {ServerUrl, staffCode, Token, config} from '../../components/include'
import axios from 'axios'
import { generateCardNo } from '../../components/globalFunction'
 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [defaultUrl, setDefaultUrl] = useState('');
	
	const [content, setContent] = useState({
		code:Math.random().toString(36).substr(2,9),
		comment:'',
		startDate:'0000-00-00 00:00:00',
		endDate:'0000-00-00 00:00:00',
		couponCode:'',
		requiredCode:false,
		maximumDiscount:0,
		percentage:0,
		discountAmount:0,
		isPercentage:false,
		discountType:'total',
		discountName:'',
		lastUsed:'',
		staffCreate:staffCode,
		timeUsed:0
	})
	
	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='isPercentage'){
			value = value==='false'?true:false
		}
		if(name==='requiredCode'){
			value = value==='false'?true:false
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	
	const ValidateExistence=()=>{
        if(content.discountName!==''){
             
            const fd = new FormData();
            fd.append("data", content.discountName)
			fd.append("jwt", Token);
            let url = ServerUrl+'/existence_controller.php?tablename=tbl_discount&column=discountName';
            axios.post(url, fd, config).then(response=>{
                if(response.data===true){
                    myRef.current.focus()
                    setErrors({...errors, discountName:content.discountName + ' already exist'})
                    setContent({...content, discountName:''});
                    return response.data;
                }
          })
        }
	} 
	function generateCardNo(t){
		t||(t=16);
		for(var e="",a=0;a<t;a++){
			var n=Math.floor(Math.random()*"1234567890".length);
			e+="1234567890".substring(n,n+1)
			}
			setContent({...content, couponCode : e });
			
			return e
	}	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		    
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append("jwt", Token);
			 let url = ServerUrl+'/save_controller.php?tablename=tbl_discount';
			axios.post(url, fd, config)
			
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.discountName){
			errors.discountName =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}

        return (<Template 
            menu="Promotions"
          submenu="Discount">
            
           {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
         
         <div  className="main-body">
              <div className="page-wrapper">
        
        <PageHeader title="Add New Discount" description='Create New Discount' icon='feather icon-package'>
        <li className="breadcrumb-item"><a href="/discount">Discount</a> </li>
                </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, '/discount')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/discount/create')}
  className="btn btn-inverse " ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/discount/edit/'+content.code)} className="btn btn-inverse "><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/discount" className="btn btn-warning "><i className="fa fa-arrow-left"></i> Return</a>


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Discount info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={'The name of the discount'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} onMouseOut={ValidateExistence} ref={myRef} name="discountName" value={content.discountName} className={errors.discountName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.discountName}</span>
							</div>
					</div>
					
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Discount type" toolTip={Tooltip.discountType} />
					</div>
									
						<div className="col-sm-9 ">
						<select onChange={handleChange} value={content.discountType}
                                      name="discountType"
									  className="form-control"
                                    >
                                      <option value="total">Assigned to order total</option>
									<option value="products">Assigned to products</option>
									<option value="categories">Assigned to categories</option>
									<option value="shipping">Assigned to shipping</option>
									<option value="subtotal">Assigned to order subtotal</option>
                                    </select>
							</div>
					</div>	
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Use percentage" toolTip={Tooltip.isPercentage}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isPercentage} name="isPercentage" checked={content.isPercentage} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			{!content.isPercentage?<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Discount amount" toolTip={Tooltip.discountAmount} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange}  name="discountAmount" value={content.discountAmount} className='form-control '/>
							
							</div>
							</div>
					</div>:''}
					
					{content.isPercentage?<>	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Discount percentage" toolTip={Tooltip.percentage} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange}  name="percentage" value={content.percentage} className='form-control '/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Maximum discount amount" toolTip={Tooltip.maximumDiscount} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange}  name="maximumDiscount" value={content.maximumDiscount} className='form-control '/>
							
							</div>
							</div>
					</div></>:''}
					
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Requires coupon code" toolTip={Tooltip.requiredCode}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.requiredCode} name="requiredCode" checked={content.requiredCode} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


			{content.requiredCode?	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Coupon code" toolTip={Tooltip.couponCode} />
					</div>
									
						<div className="col-sm-9 ">


					
							<div className="input-group">
						<input type="text" onChange={handleChange}  name="couponCode" value={content.couponCode} className={errors.couponCode  ? 'form-control formerror' : 'form-control '}/>
						<span class="input-group-addon" onClick={()=>generateCardNo(16)} id="basic-addon">Generate Code</span>


							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.couponCode}</span>
							</div>
					</div>:''}
					
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Start Date" toolTip={Tooltip.startDate} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="datetime-local" onChange={handleChange}  name="startDate" value={content.startDate} className='form-control '/>
							
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="End Date" toolTip={Tooltip.endDate} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="datetime-local" onChange={handleChange}  name="endDate" value={content.endDate} className='form-control '/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Admin Comment" toolTip={Tooltip.adminComment} />
		</div>
						
			<div className="col-sm-9 ">
				
			<textarea type="text" className="form-control" rows="5" name="comment" value={content.comment} onChange={handleChange}>{content.comment}
			</textarea>

				</div>
		</div>
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-th-list"></i> Requirements</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
			<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					You need to save the discount before you can add requirements for this discount page.
					</div>



					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 