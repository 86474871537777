import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {CustomToggleList} from '../../components/dataTable'
import {ServerUrl} from '../../components/include'
import axios from 'axios'
import { staffCode, Token, config} from '../../components/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle, CSVExport } from 'react-bootstrap-table2-toolkit';
import { longDate } from '../../components/globalFunction'

 const Tickets =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    
    const [search, setSearch]= useState({
        fromDate:'',
        toDate:'',
        priority:'all',
        ticketType:'all',
        ticketStatus:'all',
        isNew:'all',
    })
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);
    
    const SearchTable =(event)=>{
       event.preventDefault();
       fetchContent();
    }
   

    const handleOpenClosed =(status)=>{
          
      

    let dataId = []
    var newContent = [...content]
    for (var i in newContent){
        if(newContent[i].status===true){
            dataId.push(newContent[i].ID)
            
        }
    }
    if(dataId.length===0){
        Alerts('Info!', 'info', 'Please select at least one record')
          
    }else{
        setNotice({...notice, 
            isLoading: true}) 
    var data = JSON.stringify(dataId, null, 2)

        const fd = new FormData();
        fd.append('data', data)
        fd.append('status', status)
        fd.append("jwt", Token);
    let url = ServerUrl+'/update_controller.php?tablename=tbl_ticket_review';
          axios.post(url, fd, config)
          .then(response =>{
           if(response.data.type ==='success'){
              Alerts('Saved!', 'success', response.data.message)
              
                  } else{
              Alerts('Error!', 'danger', response.data)
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, 
                  isLoading: false
              })
              setStatus({checkAll:false,
                checkOne:false})
              fetchContent()
          }) 
        }
}


    const handleDelete =()=>{
          
           

        let dataId = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status===true){
                dataId.push(newContent[i].ID)
                
            }
        }
        let close =   document.getElementById('btnWarningDialog-delAll')
        if(dataId.length===0){
            Alerts('Error!', 'danger', Tooltip.deleteWarning)
              
        }else{
            setNotice({...notice, 
                isLoading: true}) 
        var data = JSON.stringify(dataId, null, 2)

            const fd = new FormData();
            fd.append('data', data)
            fd.append("jwt", Token);
        let url = ServerUrl+'/deleteMultiple_controller.php?tablename=tbl_tickets';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  
                      } else{
                  Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
                  fetchContent()
              }) 
            }
            close.click();
    }

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select t.ID, c.email, c.fullname, t.code, t.dateCreate, t.dateTime, t.customerCode, t.subject, t.ticketType, t.priority, t.ticketStatus, t.status from tbl_customers c, tbl_tickets t where c.code = t.customerCode   " ;

        if(search.fromDate.length!==0 && search.toDate.length!==0 ){
            sql = sql + ' and t.dateCreate between = "'+search.fromDate+'" and "'+search.toDate+'"'
        }
        if(search.ticketType !== 'all'){
            sql = sql + '  and t.ticketType = "'+search.ticketType+'"'
        }
        if(search.ticketStatus !=='all'){
            sql = sql + '  and t.ticketStatus = "'+search.ticketStatus+'"'
        }

        if(search.priority !=='all'){
            sql = sql + '  and t.priority = "'+search.priority+'"'
        }
        if(search.isNew !=='all'){
            sql = sql + '  and t.isNew = "'+search.isNew+'"'
        }
        sql = sql + '  order by t.ID DESC'

          
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append("jwt", Token);
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
   // setErrors({...errors, [name]:''});
}

  const handleCheckOne =(e)=>{  
    
    let {name, value} = e.target

    const newContent = [...content]
    const otherContent = newContent.filter(item=>item.ID!==name)
    const custom = newContent.filter(item=>item.ID===name)
    const status = value ===''? true:value==='true'?false:true 
    custom[0].status = status
    const currentContent = custom.concat(otherContent)
  setStatus({...status, checkAll:false})
   setContent(currentContent) 
        
       }
    

  const handleCheckAll =()=>{
    var newContent = JSON.parse(JSON.stringify(content));
    for (var i in newContent){
        newContent[i].status=!status.checkAll
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent)
 }

 
  const generalCheck =()=><div className="checkbox-fade fade-in-success">
  <label>
<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
      <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
  </label>
</div> 

    const tableHeader = [
        {dataField: '',  text: generalCheck(), editable: false, formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.ID}  checked={row.status} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},
        {dataField: 'code', text: 'Ticket', editable: false},
        {dataField: 'ticketStatus', text: 'Status', sort: true, formatter:(cell)=><span className={`badge 
        ${cell==='Open'?'badge-success':'badge-warning'}`}>{cell}</span>, editor: {
            type: Type.SELECT, options:[{value:'Open', label:'Open'},
            {value:'Closed', label:'Closed'}]

          }}, 
        {dataField: 'email', text: 'Customer', editable: false, formatter:(cell, row)=><a style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} href={"/customer/edit/"+row.customerCode} >{row.fullname}<br/>{row.email}</a>},
        {dataField: 'ticketType', text: 'Type', editable: false},
        {dataField: 'priority', text: 'Priority', editable: false, formatter:(cell)=><span className={`badge 
        ${cell==='Urgent'?'badge-danger': cell==='Low'?'badge-warning': cell==='Medium'?'badge-info': cell==='High'?'badge-accent':
        'badge-success'}`}>{cell}</span>},
        {dataField: 'subject', text: 'Subject', editable: false, editor: {
            type: Type.TEXTAREA}, formatter:(cell)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},

          {dataField: 'dateTime', text: 'Created On', editable: false, formatter:(cell, row)=><span>{longDate(row.dateCreate)+ cell }</span>},

        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/cms/ticket/reply/${row.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-mail-reply"></i> reply</a>, editable: false}
       
     ];
    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, ID)=>{       
         const fd = new FormData(); 
             fd.append('newValue', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
             fd.append("jwt", Token);
            let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_tickets'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                           
                                 
      <div className="dt-buttons btn-group pull-right"> 
    <button type="button" onClick={()=>handleOpenClosed('Closed')} className="btn btn-outline-success" > <i className="fa fa-check-square-o"></i>Close Selected</button>
    <button type="button" onClick={()=>handleOpenClosed('Open')} className="btn btn-outline-warning" > <i className="fa fa-minus-square-o"></i>Open Selected</button>                     
    <button className="btn btn-outline-danger" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button>
 </div>                   
               

      
                                 </div>
                                 
                     <BootstrapTable 
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return props.fetchContent;
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      

     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
			menu="Communications"
		  submenu="Tickets">
			
		   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		 
		 <div  className="main-body">
			  <div className="page-wrapper">
		
		<PageHeader title="Tickets" description='All Customer Created Tickets' icon='fa fa-commenting-o'>
		
				</PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal  handleAction={handleDelete} mID={'delAll'} /> 

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
        <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="From Date" toolTip={Tooltip.fromDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="fromDate" value={search.fromDate} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="To Date" toolTip={Tooltip.toDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}   name="toDate" value={search.toDate} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">  


            <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Ticket Status" toolTip={'Ticket Status'} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="ticketStatus" value={search.ticketStatus} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                    </select>
                </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Ticket Type" toolTip={'Ticket Type'} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="ticketType" value={search.ticketType} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Website problem">Website problem</option>
                    <option value="Partner request">Partner request</option>
                    <option value="Complaint">Complaint</option>
                    <option value="Info inquiry">Info inquiry</option>
                    <option value="Others">Others</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>
            <div className="row">                   
                
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <FormLabel name ="Priority" toolTip={"Ticket Priority"} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="priority" value={search.priority} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Urgent">Urgent</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                    </select>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Is New" toolTip={'Is New'} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="isNew" value={search.isNew} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="true">Yes</option>
                    <option value="false">No</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Tickets</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content} fetchdata={fetchContent} columns={tableHeader}  />}
            </div>
           
    
    </div></div></div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Tickets) 