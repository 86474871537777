import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts, WarningModal} from '../components/notify'
import {CustomToggleList} from '../components/dataTable'
import {ServerUrl, PictureUrl} from '../components/include'
import axios from 'axios'
import { StaffId} from '../components/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle, CSVExport } from 'react-bootstrap-table2-toolkit';

import { useNavigate } from 'react-router-dom'

 const Inventory =()=> {
     let myRef = useRef()
     const navigate = useNavigate();
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    
    const [search, setSearch]= useState({
        vendorName:'',
        isActive:'all'
    })
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);



const ComingSoon =()=><section style={{background: `url(${PictureUrl}/bg.jpg) no-repeat`}} 
className="login-block">
<div className="container">
  <div className="row">
      <div className="col-sm-12">
      <div className="row">
   <div className="col-md-12">
       <div className=" error-pages">
           <h1 className="error-title text-warning"> Coming Soon</h1>
           <h2 className="error-sub-title text-white">Oops! This page is under development</h2>

           <p className="error-message text-white text-uppercase"> We could not let you access this page.
           ONCE WE'RE DONE WE WILL LET YOU KNOW</p>
           
           <div className="mt-4">
             <a href="/dashboard" className="btn btn-danger btn-round shadow-danger m-1">Go To Home </a>
             <a href="#!" onClick={()=>navigate.goBack()} className="btn btn-outline-danger btn-round m-1">Previous Page </a>
           </div>

       </div>
   </div>
</div>
      </div>
  </div>
</div>
</section>



        return (<Template menu="Inventory"
        submenu="Product Inventory"
        pageUrl="/inventory">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Inventory"
	   				menu="Inventory"
					   />


{/* The Page Body start here */}
<div className="page-body"><ComingSoon />
         {/* 
         <WarningModal  handleAction={handleDeleteWithImage} mID={'delAll'} /> 

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Vendor Name" toolTip={Tooltip.vendorName} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  ref={myRef} name="vendorName" value={search.vendorName} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Active" toolTip={'Search base on publish'} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select name="select" name="isActive" value={search.isActive} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="true">Active Only</option>
                        <option value="false">Inactive Only</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Vendor list</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content} fetchdata={fetchContent} columns={tableHeader}  />}
            </div>
           
    
    </div></div></div>
			

							</div>
							</div>
	                   
                            </div> */}




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Inventory) 