import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { Popover, LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../../components/notify'
import {ServerUrl, API_URL, Token, config, PictureUrl,  staffCode, IMAGES_URL} from '../../components/include'
import axios from 'axios'

 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	
	let initialState = {
		code:'b-'+Math.random().toString(36).substring(2,9),
		logoUrl:IMAGES_URL+"/noImage.png",
		isLogo:false, 
		brandName:'',	
		description:'',
		pageName:'',
		metaTitle:'',
		metaKeywords:'',
		metaDescription:'',

		display:[{
			allowPageSize:true,
			pageSize:9,
			pageSizeOption:'9,15,27,33',
			priceRange:'0-2000;2000-5000;5000-10000;10000-20000;20000-40000;40000-',
		}],
		mapping:[{
			exclusive:'',
			limitation:'',
			discount:''
		}],
		published:true,
		displayOrder:1,
		staffCreate:staffCode,
	}

	const [content, setContent] = useState(initialState)
	const [defaultUrl, setDefaultUrl] = useState('');
	const [image, setImage] = useState('');
	const handleChangeNumber =(event)=>{
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}
	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='published'){
			value =!content.published
		}	
		if(name==='pageName'){
			value = value.replace(/,?[ ]/g, '-')
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleChangeDisplay =(event)=>{

		const newDisplay = JSON.parse(JSON.stringify(content.display));
		let {name, value} = event.target;
		if(name ==='allowPageSize'){
			value = value ==='true'?false:true
		}
		newDisplay[0][name] = value
		setContent({...content, display : newDisplay });
		setErrors({...errors, [name]:''});
	}


	const handleChangeDescription =(data)=>{
		setContent({...content, description : data });
	}
	const setLogo =()=>{
		const img = document.getElementById('viewLogo');
		img.src = content.logoUrl 
		document.getElementById('logo').value = '';
		setImage('')
		setContent({...content, isLogo:false})
	}
	const [panel, setPanel] = useState({
		info:true,
		display:true,
		seo:false,
		product:false,
		mapping:false,
		basic:true,
		seoDisplay:false,
		productDisplay:false,
		mappingDisplay:false,
		infoDisplay:false,
		displayDisplay:false
	})

	const goAdvance=()=>{
		setPanel({...panel,
		seoDisplay:true,
		productDisplay:true,
		mappingDisplay:true,
		infoDisplay:true,
		displayDisplay:true,
		basic:false
	})
	}

	const goBasic=()=>{
		setPanel({...panel,
		seoDisplay:false,
		productDisplay:false,
		mappingDisplay:false,
		infoDisplay:false,
		displayDisplay:false,
		basic:true
	})
	}

	
	const ValidateExistence=()=>{
        if(content.brandName!==''){
              
            const fd = new FormData();
            
            let url = API_URL+'/brand/existence/'+content.brandName;
            axios.get(url,  config).then(response=>{
                if(response.data.status===200){
                    myRef.current.focus()
                    setErrors({...errors, brandName:content.brandName + ' already exist'})
                    setContent({...content, brandName:''});
                    return response.data;
                }
          })
        }
	} 
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		    
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  if(content.pageName===''){
			fd.append("pageNames", content.brandName.replace(/,?[ ]/g, '-'))
		}else{
			fd.append("pageNames", content.pageName)
		}
		  fd.append('mappings', JSON.stringify(content.mapping, null, 2))
		  fd.append('displays', JSON.stringify(content.display, null, 2))
			if(image!==''){
				fd.append("image", image);
			}
			
			let url = API_URL+'/brand/create';

			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.brandName){
			errors.brandName =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}


		const  handleFile = function(fieldName){
			return function(newValue){                 
				const value = newValue.target.files[0]
			  // setImage(value)
		   readURL(newValue);
		   }
		   
		  } 
		
		   const readURL = (input)=>{       
			let displayMessage = '';
			  var fileName =  document.getElementById('logo').value;
			var idxDot = fileName.lastIndexOf(".") + 1;
			var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
			if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
				//TO DO    
				
				 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
			if (FileSize > 0.5) {
				displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500Kb';
			   document.getElementById(input.target.name).value = '';
			   setImage('')
			} else {
				if (input.target.files && input.target.files[0]) { //Check if input has files.
				  var reader = new FileReader();//Initialize FileReader.
				  reader.onload = function (e) {
			   
				  const img = document.getElementById('viewLogo');
							 img.src = e.target.result             
					  
				  };
				  reader.readAsDataURL(input.target.files[0]); 
				  setImage(input.target.files[0])  
				  setContent({...content, isLogo:true})
				}
			}
			}else{
				displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500Kb'
			   document.getElementById(input.target.name).value = '';
			   setImage('');
			}   
			
			if(displayMessage.length > 0){
			
				Alerts('Error!', 'danger', displayMessage)
			  }
		} 
		
		return (<Template 
            menu="Catalog"
        submenu="Brands">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">

<PageHeader title="Add New Brand" description='Create product brand here' icon='feather icon-book'>
	
<li className="breadcrumb-item"><a href="/brands">Brands</a> </li>

                    </PageHeader>

{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                
			  <section className="col-sm-12">
	
	<button type="button" onClick={(e)=>handleSubmit(e, '/brands/create')}
  className="btn btn-inverse btn-sm" ><i className="fa fa-save"></i> Save & New</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/brand/edit/'+content.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/brands" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>

	{panel.basic?
	<button type="button" onClick={goAdvance} className="btn btn-sm  pull-right"> Basic Mode <i className="fa fa-check"></i></button> :
	<button type="button" onClick={goBasic} className="btn btn-sm btn-primary pull-right">  Advanced Mode <i className="fa fa-check"></i></button>}

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, info:!panel.info})}>
			<h5><i className="fa fa-info"></i> Brand Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.info?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.info?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.manufacturerName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} onBlur={ValidateExistence} ref={myRef} name="brandName" value={content.brandName} className={errors.brandName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.brandName}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Description" toolTip={Tooltip.manufacturerDescription} />
					</div>
									
						<div className="col-sm-9 ">
						<QuillText value={content.description} onChange={handleChangeDescription} />
							</div>
					</div>

				{panel.infoDisplay?		<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Picture" toolTip={Tooltip.manufacturerLogo} />
					</div>
									
						<div className="col-sm-9 ">
						

						<div className="form-group">
						<img className="img-70" id="viewLogo"  src={PictureUrl+"/noImage.png"} alt="Picture "  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
				{content.isLogo?<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>:''}	
										</div>
							</div>
					</div>:''}	
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, display:!panel.display})}>
			<h5><i className="fa fa-television"></i> Display</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.display?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.display?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
				{panel.displayDisplay?<>	
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Published"  toolTip={Tooltip.publishManufacturer} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={content.published} name="published" checked={content.published} onChange={handleChange} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Allow page size" toolTip={Tooltip.customerPageSize}  />
					</div>
									
						<div className="col-sm-9 ">
						<div className="checkbox-fade fade-in-success">
						<label>
					<input type="checkbox" value={String(content.display.map((data)=>data.allowPageSize))} name="allowPageSize" checked={String(content.display.map((data)=>data.allowPageSize))==='false'?false:true} onChange={handleChangeDisplay} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Size" toolTip={Tooltip.manufacturerPageSize} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="pageSize" 
						value={content.display.map((data)=>data.pageSize)} onChange={handleChangeDisplay} />

							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Size Option" toolTip={Tooltip.pageSizeOption} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" name="pageSizeOption" 
						value={content.display.map((data)=>data.pageSizeOption)}  onChange={handleChangeDisplay}  />

							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Price Ranges" toolTip={Tooltip.priceRange} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" name="priceRange" 
						value={content.display.map((data)=>data.priceRange)} onChange={handleChangeDisplay}  />

							</div>
					</div></>:''}

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={content.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>




				{/* {panel.mappingDisplay?			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, mapping:!panel.mapping})}>
			<h5><i className="fa fa-bookmark"></i> Mappings</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.mapping?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.mapping?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Discounts" toolTip={Tooltip.discount} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="number" className="form-control" />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Exclusively To" toolTip={Tooltip.exclusive} />
					</div>
									
						<div className="col-sm-9 ">
						<div className="row ">
						<div className="col-sm-3">
						<input type="text" className="form-control" />
</div>
<div className="col-sm-9">
							<div className="alert alert-info background-info">
					
In order to use this functionality, you have to disable the following setting: Catalog settings {'>'} Ignore ACL rules.		
							</div></div>

							</div>
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Limited To Store" toolTip={Tooltip.limitedStore} />
					</div>
									
						<div className="col-sm-9 ">
						<div className="row ">
						<div className="col-sm-3">
						<input type="text" className="form-control" />
</div>
<div className="col-sm-9">
							<div className="alert alert-info background-info">
							
In order to use this functionality, you have to disable the following setting: Catalog settings {'>'} Ignore "limit per store" rules.
							</div></div>

							</div>
					</div></div>


	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}
 */}





				{panel.seoDisplay?			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, seo:!panel.seo})}>
			<h5><i className="fa fa-search-plus"></i> SEO</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.seo?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.seo?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Name" toolTip={Tooltip.seoName} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="text" className="form-control" name="pageName" 
						value={content.pageName} onChange={handleChange} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Title" toolTip={Tooltip.metaTitle} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaTitle" 
						value={content.metaTitle} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Keyword" toolTip={Tooltip.metaKeywords} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaKeywords" 
						value={content.metaKeywords} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Description" toolTip={Tooltip.metaDescription} />
					</div>
									
						<div className="col-sm-9 ">
						
						<textarea type="text" className="form-control" rows="5" name="metaDescription" 
						value={content.metaDescription} onChange={handleChange}>{content.metaDescription}</textarea>
					</div></div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}





						{panel.productDisplay?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, product:!panel.product})}>
			<h5><i className="fa fa-th-list"></i> Products</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.product?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.product?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					
You need to save the manufacturer before you can add products for this manufacturer page.
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 