import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts} from '../../components/notify'
import {ServerUrl} from '../../components/include'
import axios from 'axios'

 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [defaultUrl, setDefaultUrl] = useState('');
	let initialState = {
		code:'m'+Math.random().toString(36).substr(2,9),
		methodName:'',
		location:'',
		deliveryPeriod:'',
		handlingFee:0,
		isActive:true,	
		displayOrder:'1'
	}
	const [content, setContent] = useState(initialState)
	
	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='isActive'){
			value =!content.isActive
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}
	const handleChangeNumber =(event)=>{
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}
	
	const ValidateExistence=()=>{
        if(content.methodName!==''){
            const config = {
                headers: { 'content-type': 'multipart/form-data'}
            }    
            const fd = new FormData();
            fd.append("data", content.methodName)
            let url = ServerUrl+'/existence_controller.php?tablename=tbl_shipping_method&column=methodName';
            axios.post(url, fd, config).then(response=>{
                if(response.data===true){
                    myRef.current.focus()
                    setErrors({...errors, methodName:content.methodName + ' already exist'})
                    setContent({...content, methodName:''});
                    return response.data;
                }
          })
        }
	} 
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		  const config = {
			  headers: { 'content-type': 'multipart/form-data'}
		  }    
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
			
			 let url = ServerUrl+'/save_controller.php?tablename=tbl_shipping_method';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.methodName){
			errors.methodName =Tooltip.required;
			myRef.current.focus()
		}

		if(!values.deliveryPeriod){
			errors.deliveryPeriod =Tooltip.required;
			
		}
		return errors;
		}

        return (<Template menu="Shipping"
        submenu="Shipping Method"
        pageUrl="/shippingmethod/create">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Add a new shipping method"
	   				menu="Shipping"
					   menuLink="/shippingmethod"
					   submenu="Add a new shipping method"
					   />


{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, '/shippingmethod')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/shippingmethod/create')}
  className="btn btn-inverse " ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/shippingmethod/edit/'+content.code)} className="btn btn-inverse "><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/shippingmethod" className="btn btn-warning "><i className="fa fa-arrow-left"></i> Return</a>


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Method info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.methodName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} onBlur={ValidateExistence} ref={myRef} name="methodName" value={content.methodName} className={errors.methodName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.methodName}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Devlivery Period" toolTip={'Delvery Period'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  name="deliveryPeriod" value={content.deliveryPeriod} className={errors.deliveryPeriod  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.deliveryPeriod}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Location" toolTip={'Delvery Location if any (e.g Abuja only)'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" onChange={handleChange}  name="location" value={content.location} className='form-control '/>
							
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Handling Fee" toolTip={'Service Fee'} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="handlingFee" value={content.handlingFee} onChange={handleChangeNumber} />

							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={content.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Published"  toolTip={'Check to publish this shipping method'} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={content.isActive} name="isActive" checked={content.isActive} onChange={handleChange} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>	
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-object-group"></i> History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
			<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					You need to save the shipping method before you can add order for this method page.
					</div>



					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 