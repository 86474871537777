import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../../components/notify'
import {ServerUrl, Token, config, PictureUrl, staffCode} from '../../components/include'
import axios from 'axios'

import Select from 'react-select'
 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	const [blogCode, setBlogCode] = useState('blogpost-'+Math.random().toString(36).substr(2,9))
	let initialState = {
		
		logoUrl:PictureUrl+"/noImage.png",
		isLogo:false, 
		title:'',
		shortDescription:'',
		fullDescription:'',
		author:'',
		allowComment:true,
		tags:'',
		categories:'',
		pageName:'',
		metaTitle:'',
		metaKeywords:'',
		metaDescription:'',
		readCount:0,
		isActive:true,
		displayOrder:1,
		staffCreate:staffCode,
	}


	
	const [content, setContent] = useState(initialState)
	const [defaultUrl, setDefaultUrl] = useState('');
	const [image, setImage] = useState('');
	const [category, setCategory] = useState([])
	const [tags, setTags] = useState([])
	


	const fetchCategory =()=>{
        var sql ="Select ID, parentCategory, categoryName, code, blogList from tbl_blog_category order by categoryName ASC" ;
           
        const fd = new FormData();
        fd.append("sql", sql);
		fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        axios.post(url, fd, config).then(result=>{ 
			let initial= [{ID:'xrt', code:'xrt', parentCategory:'root', categoryName:'[Root]'}]
			
			setCategory(initial.concat(result.data))
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
		})

  }

  

const fetchTags =()=>{
	var sql ="Select ID, tagName from tbl_product_tag order by tagName ASC" ;
	const fd = new FormData();
	fd.append("sql", sql);
	fd.append("jwt", Token);
	let url = ServerUrl+'/fetchBySql_controller.php';
	axios.post(url, fd, config).then(result=>{  
		setTags(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}
	const handleChangeNumber =(event)=>{
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleChangeCheck =(event)=>{
		let {name, value} = event.target;	
		value = value ==='true'?false:true
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='pageName'){
			value = value.replace(/,?[ ]/g, '-')
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleSelect = (option, action)=>{
		setContent({...content, [action.name]: option});
		setErrors({...errors, [action.name]:''})
		 }

	const handleChangeDescription =(data)=>{
		setContent({...content, fullDescription : data });
	}

	const setLogo =()=>{
		const img = document.getElementById('viewLogo');
		img.src = content.logoUrl 
		document.getElementById('logo').value = '';
		setImage('')
		setContent({...content, isLogo:false})
	}
	const [panel, setPanel] = useState({
		info:true,
		display:true,
		seo:false,
		product:false,
		mapping:false,
		basic:true,
		seoDisplay:false,
		productDisplay:false,
		mappingDisplay:false,
		infoDisplay:false,
		displayDisplay:false
	})

	const goAdvance=()=>{
		setPanel({...panel,
		seoDisplay:true,
		productDisplay:true,
		mappingDisplay:true,
		infoDisplay:true,
		displayDisplay:true,
		basic:false
	})
	}

	const goBasic=()=>{
		setPanel({...panel,
		seoDisplay:false,
		productDisplay:false,
		mappingDisplay:false,
		infoDisplay:false,
		displayDisplay:false,
		basic:true
	})
	}

	
	const ValidateExistence=()=>{
        if(content.categoryName!==''){
          
            const fd = new FormData();
            fd.append("data", content.categoryName)
			fd.append("jwt", Token);
            let url = ServerUrl+'/existence_controller.php?tablename=tbl_blog_post&column=title';
            axios.post(url, fd, config).then(response=>{
                if(response.data===true){
                    myRef.current.focus()
                    setErrors({...errors, categoryName:content.categoryName + ' already exist'})
                    setContent({...content, categoryName:''});
                    return response.data;
                }
          })
        }
	} 
	


	const getCategoryList =(categoryList)=>{
		if(categoryList.length!==0){

			categoryList.map(data =>{
		const result = category.filter(item=>item.code===data.code)
		const allBloglist = JSON.parse(result[0].blogList);
		const newBlogList = allBloglist.concat([{code:blogCode}])
		const currentBloglist =	newBlogList.map(e=>e['code'])
					.map((e,i,final)=>final.indexOf(e)===i&&i)
					.filter(e=>newBlogList[e])
					.map(e=>newBlogList[e])

			const fd = new FormData();
			  fd.append('catCode', data.code)
			  fd.append('totalItem', currentBloglist.length)
			  fd.append("jwt", Token);
			  fd.append('blogList', JSON.stringify(currentBloglist, null, 2))
		let url = ServerUrl+'/update_controller.php?tablename=tbl_update_blog_cat';
		axios.post(url, fd, config)
		.then()
		.catch((error)=>{
		  Alerts('Error!', 'danger', error.message)
		})
	})
		}
	return ''
	}

    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		  
		  var category = [];
		  var tag = [];
		  for (var i = 0, l = (content.categories).length; i < l; i++) {
			category.push({code:content.categories[i].value});
		  }
		  for (var i = 0, l = (content.tags).length; i < l; i++) {
			  tag.push({ID:content.tags[i].value});
		  }
		  
		  const categoryList = getCategoryList(category)
		
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  
		fd.append('productCategories', JSON.stringify(category, null, 2))
		fd.append('productTags', JSON.stringify(tag, null, 2))
		fd.append('code', blogCode)
		fd.append("jwt", Token);
			if(image!==''){
				fd.append("image", image);
			}
			if(content.pageName===''){
				fd.append("pageNames", content.title.replace(/,?[ ]/g, '-'))
			}else{
				fd.append("pageNames", content.pageName)
			}
			
			 let url = ServerUrl+'/save_controller.php?tablename=tbl_blog_post';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.title){
			errors.title =Tooltip.required;
			myRef.current.focus()
		}
		if(!values.author){
			errors.author =Tooltip.required;
			
		}
		return errors;
		}


		const  handleFile = function(fieldName){
			return function(newValue){                 
				const value = newValue.target.files[0]
			  // setImage(value)
		   readURL(newValue);
		   }
		   
		  } 
		
		   const readURL = (input)=>{       
			let displayMessage = '';
			  var fileName =  document.getElementById('logo').value;
			var idxDot = fileName.lastIndexOf(".") + 1;
			var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
			if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
				//TO DO    
				
				 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
			if (FileSize > 0.5) {
				displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500 Kb';
			   document.getElementById(input.target.name).value = '';
			   setImage('')
			} else {
				if (input.target.files && input.target.files[0]) { //Check if input has files.
				  var reader = new FileReader();//Initialize FileReader.
				  reader.onload = function (e) {
			   
				  const img = document.getElementById('viewLogo');
							 img.src = e.target.result             
					  
				  };
				  reader.readAsDataURL(input.target.files[0]); 
				  setImage(input.target.files[0])  
				  setContent({...content, isLogo:true})
				}
			}
			}else{
				displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500kb'
			   document.getElementById(input.target.name).value = '';
			   setImage('');
			}   
			
			if(displayMessage.length > 0){
			
				Alerts('Error!', 'danger', displayMessage)
			  }
		} 


	  const DisplaySelect=()=>{	  
		let details = get_options();
		return ( <Select isMulti options={
			details&&details.map((node, id)=>{
				return {value:node.code, key:id, label:node.categoryName}
			})} 
			
onChange={handleSelect}  name="categories" value={content.categories} styles={selectStyles} /> 
)

}


function get_options(parent="root", indent="") {
		var result= []
		category&&category.map(cat=>{
			if(cat.parentCategory===parent){
				result.push({code:cat.code, categoryName:indent+cat.categoryName})
				result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
			}
		})
		return result;
	  }


		useEffect(()=>{
			fetchCategory()
			fetchTags()
		   },[]);
        return (<Template 
			menu="Content Management"
		submenu="Blogs">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Add new blog item" description='Create new blog post here' icon='feather icon-layers'>
  <li className="breadcrumb-item"><a href="/cms/blogs">Blogs</a> </li>
					</PageHeader>


{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success btn-sm" onClick={(e)=>handleSubmit(e, '/cms/blogs')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/cms/blog/create')}
  className="btn btn-inverse btn-sm" ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/cms/blog/edit/'+content.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/cms/blogs" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>

	{panel.basic?
	<button type="button" onClick={goAdvance} className="btn btn-sm  pull-right"> Basic Mode <i className="fa fa-check"></i></button> :
	<button type="button" onClick={goBasic} className="btn btn-sm btn-primary pull-right">  Advanced Mode <i className="fa fa-check"></i></button>}

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, info:!panel.info})}>
			<h5><i className="fa fa-info"></i> Blog Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.info?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.info?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Title" toolTip={'Title of this blog post'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} onBlur={ValidateExistence} ref={myRef} name="title" value={content.title} className={errors.title  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.title}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Author" toolTip={'Author of this blog post'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  name="author" value={content.author} className={errors.author  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.author}</span>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Short description" toolTip={'If specified then it will be used on the main blog page instead of full description'} />
					</div>
									
						<div className="col-sm-9 ">
							
						<textarea type="text" className="form-control" rows="5" name="shortDescription" value={content.shortDescription} onChange={handleChange}>{content.shortDescription}
						
						</textarea>

							</div>
					</div>

					

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Full Description" toolTip={'Full description of this post'} />
					</div>
									
						<div className="col-sm-9 ">
						<QuillText value={content.fullDescription} onChange={handleChangeDescription} />
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Blog Tag" toolTip={'Tags are keywords that this blog post can also be identified by. Select from list of the tags to be associated with this blog post.'} />
					</div>
									
						<div className="col-sm-9 ">
						<Select isMulti options={
				tags&&tags.map((data, id)=>{
					return {value:data.ID, key:id, label:data.tagName}
				})}   
                
onChange={handleSelect}  name="tags"  value={content.tags} styles={selectStyles} /> 
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Categories" toolTip={'Categories this post belongs to'} />
					</div>
									
						<div className="col-sm-9 ">
						<DisplaySelect /> 
							</div>
					</div>

	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Picture" toolTip={Tooltip.picture} />
					</div>
									
						<div className="col-sm-9 ">
						

						<div className="form-group">
						<img className="img-70" id="viewLogo"  src={PictureUrl+"/noImage.png"} alt="Picture "  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
				{content.isLogo?<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>:''}	
										</div>
							</div>
					</div>	
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, display:!panel.display})}>
			<h5><i className="fa fa-television"></i> Display</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.display?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.display?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
				{panel.displayDisplay?<>	
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Published"  toolTip={Tooltip.published} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={content.isActive} name="isActive" checked={content.isActive} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Allow Comment"  toolTip={'When checked, customers can leave comments about your blog entry.'} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={content.allowComment} name="allowComment" checked={content.allowComment} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
</>:''}

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={content.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>


				{panel.seoDisplay?			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, seo:!panel.seo})}>
			<h5><i className="fa fa-search-plus"></i> SEO</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.seo?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.seo?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Name" toolTip={Tooltip.seoName} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="text" className="form-control" name="pageName" 
						value={content.pageName} onChange={handleChange} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Title" toolTip={Tooltip.metaTitle} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaTitle" 
						value={content.metaTitle} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Keyword" toolTip={Tooltip.metaKeywords} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaKeywords" 
						value={content.metaKeywords} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Description" toolTip={Tooltip.metaDescription} />
					</div>
									
						<div className="col-sm-9 ">
						
						<textarea type="text" className="form-control" rows="5" name="metaDescription" 
						value={content.metaDescription} onChange={handleChange}>{content.metaDescription}</textarea>
					</div></div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}





						{panel.relatedDisplay?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, related:!panel.related})}>
			<h5><i className="fa fa-th-list"></i> Related Blog</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.related?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.related?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					
You need to save the blog item before you can add related item for this blog page.
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 