import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl} from '../../components/include'
import axios from 'axios'
import {PictureUrl, Token, config} from '../../components/include'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import cellEditFactory from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Type} from 'react-bootstrap-table2-editor';
import { compare, sort } from '../../components/globalFunction'
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [category, setCategory] = useState([])
	const [blogpost, setBlogpost] = useState([])
	const [content, setContent] = useState([])
	const [blogList, setBlogList] = useState([])
	
	

    const handleDelete =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
	
		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'code')
		fd.append('data', params.code)
		fd.append("jwt", Token);
	let url = ServerUrl+'/delete_controller.php?tablename=tbl_blog_category';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/cms/blog/categories', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
		
		
}


const getItemName =(code, field)=>{
	 const result = blogpost&&blogpost.filter(item=>item.code===code)
	const answer = result.length!==0?result[0][field]:[]
	return String(answer) 

}

	const getBlogName =()=>{
		let result = []
		blogList&&blogList.map((item, id)=>result.push({code:item.code, key:id, isActive:getItemName(item.code, 'isActive'), title:getItemName(item.code, 'title')}))
		return result
	}


    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select * from tbl_blog_category where code = '" +params.code+"'";
        
       
        const fd = new FormData();
        fd.append("sql", sql);
		fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{ 
			setContent(result.data)
			setBlogList(JSON.parse(result.data[0].blogList))
			
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
	const [defaultUrl, setDefaultUrl] = useState('');
	
	const handleChangeNumber =(event)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		if(name==='pageName'){
			value = value.replace(/,?[ ]/g, '-')
		}
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}

	const handleChangeCheck =(event)=>{
		const newContent =JSON.parse(JSON.stringify(content))  
			let {name, value} = event.target;
			value = value ==='true'?'false':'true'	
			newContent[0][name]= value
			setContent(newContent);
			setErrors({...errors, [name]:''});
		}

		
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		     
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append("jwt", Token);
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_blog_category';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.categoryName){
			errors.categoryName =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}



		const DisplaySelect=({value})=>{	  
			let options = get_options();
		   return (
			   <select name="parentCategory" value={value} className="form-control"   onChange={handleChange}>
				{options.map((node, id)=>{
					return <option key={id} value={node.code} >{node.categoryName}</option>
				})}
				</select>
		   )
				
	}
	
		  function get_options(parent="root", indent="") {
			var result= []
			category.map(cat=>{
				if(cat.parentCategory===parent){
					result.push({code:cat.code, categoryName:indent+cat.categoryName})
					result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
				}
			})
			return result;
		  }


		  const fetchBlogpost =()=>{
		
			var sql ="Select ID, code, title, displayOrder, status, categories, isActive from tbl_blog_post ";
			
			const fd = new FormData();
			fd.append("sql", sql);
			let url = ServerUrl+'/fetchBySql_controller.php';
			fd.append("jwt", Token);
			axios.post(url, fd, config).then(result=>{ 
				setBlogpost(result.data)
			})
			.catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			})
		
		}

		const handleRemoveBlogpost =(e, code)=>{
			e.preventDefault()

			let close =   document.getElementById('btnWarningDialog-'+code)
				close.click();
				
			const newContent = [...content] 
			const blogList = JSON.parse(newContent[0].blogList);
			const otherBloglist = blogList.filter(list=>list.code!==code)


			const newBlogpost = [...blogpost] 
			const selectBlog = newBlogpost.filter(list=>list.code===code)
			const catBloglist = JSON.parse(selectBlog[0].categories);

			const blogCategory = catBloglist.filter(list=>list.code!==params.code)
			
			UpdateBlogCategory(otherBloglist, blogCategory, code)
		}


		  const tableCategoryBlogHeader = [

			{dataField: 'title', text: 'Title', sort:true, editable: false, formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
			{dataField: 'isActive', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.isActive==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>},

			{dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>
				<a href={`/cms/blog/edit/${row.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>&nbsp;
				
		<WarningModal  handleAction={(e)=>handleRemoveBlogpost(e, row.code)} mID={row.code} />
			
		<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button></div>, editable: false}
		   
		 ];
	
	const TableCategoryBlog=(props)=>{
	
			 const { SearchBar } = Search;
			 
			 const customTotal = (from, to, size) => (
				<span >&nbsp;Showing { from } to { to } of { size } items</span>
			  );
			 const options = {
				showTotal: true,
			  paginationTotalRenderer: customTotal,
			  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
			  { text: 'All', value: props.data.length }]
			   
			  };
			 return  <ToolkitProvider search columnToggle 
						 keyField='code' data={ props.data } columns={ props.columns } >
							 
							 {
								 props => (
								 <div >
								   <div className="form-group">
									 <SearchBar
									 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
									 
									 <div className="dt-buttons btn-group pull-right"> 
			 <button data-toggle="modal"   data-target='#addNewBlog' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add a new blog</button>
	 </div>  
				                 
		 </div>
									 
						 <BootstrapTable
									 { ...props.baseProps }
												 striped
											 hover
											 condensed
											 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
										 pagination={paginationFactory(options) }
										 
									 />
								 </div>
								 )
							 }
		  </ToolkitProvider>
		  }
	
		 
	
		const fetchCategory =()=>{
			var sql ="Select ID, parentCategory, categoryName, code from tbl_blog_category order by categoryName ASC" ;
		   
			const fd = new FormData();
			fd.append("sql", sql);
			fd.append("jwt", Token);
			let url = ServerUrl+'/fetchBySql_controller.php';
			axios.post(url, fd, config).then(result=>{
				let initial= [{ID:'xrt', code:'xrt', parentCategory:'root', categoryName:'[Root]'}]
			
			setCategory(initial.concat(result.data))
		})
				
			.catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			})
	
	  }
	

	 
	
	function get_options(parent="root", indent="") {
		var result= []
		category&&category.map(cat=>{
			if(cat.parentCategory===parent){
				result.push({code:cat.code, categoryName:indent+cat.categoryName, parentCategory:parent})
				result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
			}
		})
		return result;
	  }
	

	  const handleAddNewBlogpost =(event, code)=>{
		event.preventDefault()
		
		let records = []
		let blogItem =[]
		const newContent = [...content ] 
		const newBlog = [...blogpost ]
		//get old product list
		const newBlogList = JSON.parse(newContent[0].blogList);
		 records.push({code:code})
		 blogItem.push({code:params.code})

		 const selectBlog = newBlog.filter(item=>item.code===code)
		 const newBlogCategory = JSON.parse(selectBlog[0].categories);
		const allCategory  = newBlogCategory.concat(blogItem)
		 
			//merger old prouct list with selected product
			const currentList = newBlogList.concat(records)
			
			//remove the duplicate item
			const blogList =	currentList.map(e=>e['code'])
			.map((e,i,final)=>final.indexOf(e)===i&&i)
			.filter(e=>currentList[e])
			.map(e=>currentList[e])

			const categoryList =allCategory.map(e=>e['code'])
			.map((e,i,final)=>final.indexOf(e)===i&&i)
			.filter(e=>allCategory[e])
			.map(e=>allCategory[e])


			if(records.length===0){
			Alerts('Error!', 'danger', 'Please select at least one blog to add')
			}else{

				UpdateBlogCategory(blogList, categoryList, code)
			}
			let close =   document.getElementById('closeNewBlog')
			close.click();
	}

	const UpdateBlogCategory =(blogList, categoryList, blogCode)=>{
          
		setNotice({...notice, 
			isLoading: true}) 
	var blogPostList = JSON.stringify(blogList, null, 2)
	var catList = JSON.stringify(categoryList, null, 2)
	
		const fd = new FormData();
		fd.append('blogPostList', blogPostList)
		fd.append('catList', catList)
		fd.append('blogCode', blogCode)
		fd.append('catCode', params.code)
		fd.append('totalItem', blogList.length)
		fd.append("jwt", Token);
	let url = ServerUrl+'/updateMultiple_controller.php?tablename=tbl_blog_category';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  
				  } else{
			  Alerts('Error!', 'danger', JSON.stringify(response.data))
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
			  fetchContent()
		  }) 
		
}


	  const  tableBlogHeader = [
		{dataField: 'ID',  text: '#!', editable: false,  formatter:(cell, row)=>
		<button type="button" onClick={(event)=>handleAddNewBlogpost(event, row.code)} className="btn btn-inverse">Select</button>},
		{dataField: 'title', text: 'Title', sort:true, editable: false, formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
		
		  {dataField: 'isActive', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.isActive==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>}
		  ];
	 


const TableBlogRecord=(props)=>{

			 const { SearchBar } = Search;
			 
			 const customTotal = (from, to, size) => (
				<span >&nbsp;Showing { from } to { to } of { size } items</span>
			  );
			 const options = {
				showTotal: true,
			  paginationTotalRenderer: customTotal,
			  sizePerPageList: [
				  {text: '20', value: 20}, 
			  {text: '50', value: 50}, 
			  {text: '70', value: 70}, 
			  {text: '100', value: 100}, 
			  {text: '150', value: 150},
			  { text: 'All', value: props.data.length }]
			   
			  };
			 return  <ToolkitProvider search columnToggle 
						 keyField='code' data={ props.data } columns={ props.columns } >
							 
							 {
								 props => (
								 <div >
								   <div className="form-group">
									 <SearchBar
									 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
													 
		 </div>
									 
<BootstrapTable
			{ ...props.baseProps }
						striped
					hover
					condensed
					noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
				pagination={paginationFactory(options) }
				
			/>
		</div>
		)
	}
</ToolkitProvider>
		  }




useEffect(()=>{
	fetchContent()
	fetchBlogpost()
	fetchCategory()
},[]);	
	
        return (<Template 
			menu="Content Management"
		submenu="Blogs">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Edit Blog Category" description='Update Blog Category' icon='feather icon-layers'>
  <li className="breadcrumb-item"><a href="/cms/blogs/categories">Categories</a> </li>
					</PageHeader>



<div className="modal fade " id='addNewBlog' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add new  blog</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
  <div className="table-responsive">
        
        <TableBlogRecord data={blogpost}  columns={tableBlogHeader}  />
            </div>
    </div>
              <div className="modal-footer">
                  <button type="button"  id='closeNewBlog' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
              </div>
          </div>
      </div>
  </div>


{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								

			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-inverse btn-sm" onClick={(e)=>handleSubmit(e, '/cms/blog/categories')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/cms/blog/category/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	<a href="/cms/blog/categories" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={handleDelete} mID={'delAll'} /> 


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								
			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Category info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.categoryName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  ref={myRef} name="categoryName" value={data.categoryName} className={errors.categoryName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.categoryName}</span>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Parent" toolTip={Tooltip.parentCategory} />
					</div>
							
						<div className="col-sm-9 ">

							   {category&&<DisplaySelect value={data.parentCategory} />}
							</div>
					</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>





				<div  className="row  ">
			<div className="col-sm-12">
								
			
			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-television"></i>  Display info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Published"  toolTip={Tooltip.published} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={data.published} name="published" checked={data.published==='true'?true:false} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Show on home page"  toolTip={Tooltip.showOnHome} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={data.showOnHome} name="showOnHome" checked={data.showOnHome==='true'?true:false} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Allow page size" toolTip={Tooltip.customerPageSize}  />
					</div>
									
						<div className="col-sm-9 ">
						<div className="checkbox-fade fade-in-success">
						<label>
					<input type="checkbox" value={data.allowPageSize} name="allowPageSize" checked={data.allowPageSize==='true'?true:false} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>
					
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Size Option" toolTip={Tooltip.pageSizeOption} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" name="pageSizeOption" 
						value={data.pageSizeOption}  onChange={handleChange}  />

							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={data.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


			<div className="row  ">
			<div className="col-sm-12">
								
			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-search-plus"></i>  SEO</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
		
 <div  className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Name" toolTip={Tooltip.seoName} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="text" className="form-control" name="pageName" 
						value={data.pageName} onChange={handleChange} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Title" toolTip={Tooltip.metaTitle} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaTitle" 
						value={data.metaTitle} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Keyword" toolTip={Tooltip.metaKeywords} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaKeywords" 
						value={data.metaKeywords} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Description" toolTip={Tooltip.metaDescription} />
					</div>
									
						<div className="col-sm-9 ">
						
						<textarea type="text" className="form-control" rows="5" name="metaDescription" 
						value={data.metaDescription} onChange={handleChange}>{data.metaDescription}</textarea>
					</div></div>
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


	<div className="row  ">
			<div className="col-sm-12">
								
			<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-search-plus"></i>  Blog</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="row">
					<div className="table-responsive">
		 {<TableCategoryBlog data={getBlogName()} columns={tableCategoryBlogHeader}  />} 
		</div>
					</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>



				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 