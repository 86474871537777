import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {Currency, FormatNumber, API_URL, IMAGES_URL, config} from '../../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { compare } from '../../components/globalFunction'
 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	const [newOption, setNewOption] = useState({
		ID:Math.random().toString(36).substr(2,9),
		name:'',
		price:0,
		order:1,
		isLogo:false,
		imageUrl:''
	})
	

	const [image, setImage] = useState('');
	const setLogo =()=>{
		const img = document.getElementById('viewLogo');
		img.src = content.logoUrl 
		document.getElementById('logo').value = '';
		setImage('')
		setNewOption({...newOption, isLogo:false})
	}
	const [products, setProducts] = useState([])
    const handleDelete =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
		 

		setNotice({...notice, 
			isLoading: true}) 

	let url = API_URL+'/product/attributes/delete/'+params.code;
		  axios.get(url,  config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/product-attributes', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 		
}
const handleChangeOption =(event)=>{
	const {name, value} = event.target;
	setNewOption({...newOption, [name] : value });
}



const fetchProducts =()=>{
		
	let url = API_URL+'/product/attributes/search/'+params.code;
        axios.get(url,  config).then(result=>{ 
			if(Array.isArray(result.data.data)){
		setProducts(result.data.data)
			}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})

}
const handleRemoveOption =(ID)=>{
	const newContent = [...content] 
	const options = JSON.parse(newContent[0].predefine_values);
	newContent[0].predefine_values = JSON.stringify(options.filter((list)=>list.ID!==ID))
	setContent(newContent);
}

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
        let url = API_URL+'/product/attributes/display/one/'+params.code;
        axios.get(url,  config).then(result=>{ 
			if(Array.isArray(result.data.data)){

				setContent(result.data.data)
				setNewOption({...newOption, order:Number(JSON.parse(result.data.data[0].predefine_values).length)+1})
			}

        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }



	const [defaultUrl, setDefaultUrl] = useState('');
	
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}

	const handleChangeDescription =(data)=>{
		const newContent =JSON.parse(JSON.stringify(content))
		newContent[0].description = data
		setContent(newContent);
	}

	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		   
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
			 let url = API_URL+'/product/attributes/update/'+params.code;
			axios.post(url, fd, config)
			
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleAddOptions =(event)=>{
		event.preventDefault()
		if(newOption.name===''){
			alert('Please enter option name')
		}else{
			
		const newContent = [...content] 
		const options = JSON.parse(newContent[0].predefine_values);
		newContent[0].predefine_values = JSON.stringify(options.concat(newOption))
		if(image!==''){
			setNotice({...notice, 
				isLoading: true
			})
			const fd = new FormData();
			fd.append("image", image)
			fd.append("imageUrl", newOption.ID+'.png')
			fd.append("predefine_values", newContent[0].predefine_values)
		
			let url = API_URL+'/product/attributes/add_image/'+params.code;
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				Alerts('Saved!', 'success', response.data.message)
			}})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				fetchContent()
				setImage(IMAGES_URL+"/noImage.png")
				document.getElementById('viewLogo').src=image
			})
		}else{
			setContent(newContent)
		}
		let close =   document.getElementById('closeOption')
		close.click();
		 setNewOption({
			ID:Math.random().toString(36).substr(2,9),
			name:'',
			price:0,
			order:0,
			isLogo:false,
			imageUrl:''
		}) 
}
}


	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.attribute_name){
			errors.attribute_name =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}


		const TableAttributeRecordHeader = [

			{dataField: 'productName', text: 'Product', sort:true, editable: false, formatter:(cell)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
			{dataField: 'isPublished', text: 'Is Published', sort:true, editable: false, formatter:(cell, row)=>row.isPublished==='true'? <div className="text-center"><i  className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times badge-inverse-danger"></i></div>},

			{dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/products/edit/${row.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>, editable: false}
		   
		 ];
	
	const TableAttributeRecord=(props)=>{
	
			 const { SearchBar } = Search;
			 
			 const customTotal = (from, to, size) => (
				<span >&nbsp;Showing { from } to { to } of { size } items</span>
			  );
			 const options = {
				showTotal: true,
			  paginationTotalRenderer: customTotal,
			  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
			  { text: 'All', value: props.data.length }]
			   
			  };
			 return  <ToolkitProvider search columnToggle 
						 keyField='ID' data={ props.data } columns={ props.columns } >
							 
							 {
								 props => (
								 <div >
								   <div className="form-group">
									 <SearchBar
									 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
									 
									 
				                 
		 </div>
									 
						 <BootstrapTable
									 { ...props.baseProps }
												 striped
											 hover
											 condensed
											 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
										 pagination={paginationFactory(options) }
										 
									 />
								 </div>
								 )
							 }
		  </ToolkitProvider>
		  }
	
	const tablePredefineValue = [
		{dataField: 'imageUrl', text: 'Image', editable: false, formatter:(cell, row)=><img id={cell} className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+"/noImage.png"}} src={`${IMAGES_URL}/attributes/${cell}`}   alt={row.imageUrl} />},

			{dataField: 'name', text: 'Name', sort:true, validator: (newValue, row, column) => {
				if (!newValue) {
				  return {
					valid: false,
					message: Tooltip.required
				  };
				}
				return true;
			}},
			{dataField: 'price', text: 'Additional Price', sort:true, validator: (newValue, row, column) => {
				if (!Number(newValue)) {
				  return {
					valid: false,
					message: 'This field required valid number'
				  };
				}
				return true;
			}, formatter:(cell)=><div className="text-center">{Currency+FormatNumber(cell)}</div>},
			{dataField: 'order', text: 'Order', sort:true, validator: (newValue, row, column) => {
				if (!Number(newValue)) {
				  return {
					valid: false,
					message: 'This field required valid number'
				  };
				}
				return true;
			}, formatter:(cell)=><div className="text-center">{cell}</div>},
			 {dataField: 'ID', text: 'Action', formatter:(cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleRemoveOption(row.ID)} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>, editable: false}
			  ];
		 
	
	
const TablePredefineOption =(props)=>{
	
				const handleUpdate=(column, newValue, ID)=>{     
					
			const newContent = [...content] 
			const options = JSON.parse(newContent[0].predefine_values);
	
			const otherOptions = options.filter((list)=>list.ID!==ID)
			const optionsData = options.filter((list)=>list.ID===ID)		
			optionsData[0][column.dataField] = newValue
			const currentOptions = otherOptions.concat(optionsData)
			newContent[0].predefine_values = JSON.stringify(currentOptions)
	
			setContent(newContent); 
				 } 
	
				 const { SearchBar } = Search;
				 
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='ID' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										 <SearchBar
										 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
										 
										 
										 
			<div className="dt-buttons btn-group pull-right"> 
				 <button data-toggle="modal"   data-target='#addOptions' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</button>
		 </div>                   
			 </div>
										 
							 <BootstrapTable
										 { ...props.baseProps }
													 striped
												 hover
												 condensed
												 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
											 pagination={paginationFactory(options) }
											 cellEdit={ cellEditFactory({
											   mode: 'dbclick',
											   blurToSave: true,
											   autoSelectText: true,
									 beforeSaveCell: (oldValue, newValue, row, column) => {
									   if(oldValue !==newValue){
									   handleUpdate(column, newValue, row.ID);
									   
									  return true;
									 }
									 
								   }
											 }) }
										 />
									 </div>
									 )
								 }
			  </ToolkitProvider>
			  }
	
			  const  handleFile = function(fieldName){
				return function(newValue){                 
					const value = newValue.target.files[0]
				  // setImage(value)
			   readURL(newValue);
			   }
			   
			  } 
			
			   const readURL = (input)=>{       
				let displayMessage = '';
				  var fileName =  document.getElementById('logo').value;
				var idxDot = fileName.lastIndexOf(".") + 1;
				var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
				if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
					//TO DO    
					
					 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
				if (FileSize > 1) {
					displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1mb';
				   document.getElementById(input.target.name).value = '';
				   setImage('')
				} else {
					if (input.target.files && input.target.files[0]) { //Check if input has files.
					  var reader = new FileReader();//Initialize FileReader.
					  reader.onload = function (e) {
				   
					  const img = document.getElementById('viewLogo');
								 img.src = e.target.result             
						  
					  };
					  reader.readAsDataURL(input.target.files[0]); 
					  setImage(input.target.files[0])  
					  let imageUrl =newOption.ID+'.png'
					  setNewOption({...newOption, isLogo:true, imageUrl:imageUrl})
					  document.getElementById(input.target.name).value = '';
					}
				}
				}else{
					displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 1mb'
				   document.getElementById(input.target.name).value = '';
				   setImage('');
				}   
				
				if(displayMessage.length > 0){
				alert(displayMessage)
					//Alerts('Error!', 'danger', displayMessage)
				  }
			} 

useEffect(()=>{
	fetchContent()
	fetchProducts()
},[]);	
	
        return (<Template 
            menu="Catalog"
        submenu="Attributes">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">

<PageHeader title="Edit product attribute" description='Update product attribute details' icon='feather icon-book'>
	
<li className="breadcrumb-item"><a href="/products">Products</a> </li>
<li className="breadcrumb-item"><a href="/product-attributes">Attributes</a> </li>
                    </PageHeader>
		




<div className="modal fade " id='addOptions' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add a new value</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Name" toolTip={'The name of the option'} />
					</div>
									
						<div className="col-sm-8 ">
							<div className="input-group">
						<input type="text" className="form-control" onChange={handleChangeOption}  name="name" value={newOption.name} />
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.name}</span>
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Amount" toolTip={'Additional amount. (It will be added to original price)'} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" className="form-control" onChange={handleChangeOption}  name="price" min="0" value={newOption.price} />
							
							</div>
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" className="form-control" onChange={handleChangeOption}  name="order" min="0" value={newOption.order} />
							
							</div>
							</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Picture" toolTip={'Value image'} />
					</div>
									
						<div className="col-sm-9 ">
						

						<div className="form-group">
						<img className="img-70" id="viewLogo"  src={IMAGES_URL+"/noImage.png"} alt="Picture "  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
				{newOption.isLogo?<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>:''}	
										</div>
							</div>
					</div>
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeOption' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(event)=>handleAddOptions(event)} className="btn btn-inverse">Add</button>
              </div>
          </div>
      </div>
  </div>

{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, '/product-attributes')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

<button type="button" onClick={(e)=>handleSubmit(e, '/product-attributes/edit/'+params.code)} className="btn btn-inverse "><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;

	<a href="/product-attributes" className="btn btn-warning "><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger " data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDelete()} mID={'delAll'} /> 


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.attributeName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  ref={myRef} name="attribute_name" value={data.attribute_name} className={errors.attribute_name  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.attribute_name}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Description" toolTip={Tooltip.attributeDescription} />
					</div>
									
						<div className="col-sm-9 ">

		<textarea name="description" value={data.description} onChange={handleChange} rows="3" className="form-control">{data.description}</textarea>
							</div>
					</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-check"></i> Predefined values</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
			<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					<div className="card bg-info">
					Predefined (default) values are helpful when creating new products. Then when you add the attribute to a product, you don't have to create the values again.
					</div></div>

					<div className="col-sm-12 table-responsive">
		{<TablePredefineOption data={JSON.parse(data.predefine_values).sort(compare)} columns={tablePredefineValue}  />}
		</div>
					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-th-list"></i> Used by products</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                         
					<div className="row">
					<div className="col-sm-12 table-responsive">
		{<TableAttributeRecord data={products} columns={TableAttributeRecordHeader}  />}
		</div>
					</div>

					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>




				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 