import React, {useState, useEffect} from 'react'
import {Alerts} from '../components/notify'
import {ServerUrl,  config, IMAGES_URL, Token} from '../components/include'
import axios from 'axios'
import Cookies from 'js-cookie'
import Preloader from '../components/preloader'
import { LoadEffect } from '../components/loader'

 const Login =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [user, setUser] = useState({
		email:'admin@iscreenstore.com',
		password:'123456',
		remember:false,
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({
		email:'',
		password:''
	});

	const handleChange = (event)=>{
		let {name, value} = event.target;	
		if(name==='remember'){
			value= value==='Yes'?'No':'Yes'
		}	
	   setUser({...user, [name] : value });
	   setErrors({...errors, [name]:''});
	}



const handleSubmit =(event)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';




if(!user.email){
error.email =msg;
formIsValid = false;
} 
if(!user.password){
error.password =msg;
formIsValid = false;
} 

if(!navigator.onLine){
    Alerts('Error', 'danger', 'No internet connection')
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

	setNotice({...notice, isLoading: true}) 	
       
    const fd = new FormData();
    fd.append('email', user.email);
    fd.append('password', user.password);

       
	let url = ServerUrl+'/login_controller.php?tablename=tbl_login_user';
axios.post(url, fd, config)
.then(response =>{
	if (response.data.type ==='success'){
		Alerts('Success!', 'success', response.data.message)
  //1000 * 60 * SESSION_IDEL_MINUTES
  var inSixtyMinutes = Date.now() + 419 * 60 * 1000;
  var inOneMinutes = Date.now() + 418 * 60 * 1000;
    var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
    
	

	localStorage.setItem('_tmlsp', inSixtyMinutes)
	localStorage.setItem('_btmlsp', inOneMinutes)

	
Cookies.set('stfmscd', response.data.code, {expires: inOneHours })
Cookies.set('mspgcd', response.data.groupId, {expires: inOneHours })
Cookies.set('msptk', response.data.jwt,  {expires: inOneHours }) 

 
  var queryString = new URLSearchParams(window.location.search).get("refferer")
            if(queryString!==null){
                window.open(queryString, '_self')
            }else{
                window.open('/dashboard', '_self')
            }   
  
  }else{
	Alerts('Error!', 'danger', response.data.message)
  }    
})
.catch((error)=>{
	Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice,  isLoading: false })
    })   
}
}



useEffect(()=>{  
	 
    if(Token!==undefined){
  window.open('/dashboard', '_self')
	 
   } 
  },[]); 


        return (<>
          <Preloader />

		  {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		  <section style={{background: `url(${IMAGES_URL}/background/bg.jpg) no-repeat`}} 
		  className="login-block">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    
	<form className="md-float-material form-material" onSubmit={handleSubmit}>
		
	<div className="auth-box card">
		<div className="card-block">
			<div className="row m-b-20">
				<div className="col-md-12">
				<div className="text-center">
		<img src={IMAGES_URL+'/about/logo.png'} className="img-fluid logo" alt="logo" />
		</div>
				</div>
			</div>
			<div className="form-group form-primary">

				<input type="email" id="email" name="email"  value={user.email} placeholder="Your Email Address" onChange={handleChange} className={errors.email  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 
				<span className=" form-bar error">{ errors.email}</span>

			</div>
			<div className="form-group form-primary">
			<input type="password" id="password" name="password"  value={user.password} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 
			<span className=" form-bar error">{errors.password }</span>


			</div>
			<div className="row m-t-25 text-left">
				<div className="col-12">
					<div className="checkbox-fade fade-in-primary d-">
						<label>
							<input type="checkbox" checked={user.remember} id="remember" name="remember" onChange={handleChange} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-primary"></i></span>
							<span className="text-inverse">Remember me</span>
						</label>
					</div>
					<div className="forgot-phone text-right f-right">
						<a href="/forgot_password" className="text-right f-w-600"> Forgot Password?</a>
					</div>
				</div>
			</div>
			<div className="row m-t-30">
				<div className="col-md-12">
					<button type="submit"  className="btn btn-inverse btn-md btn-block brand-color text-center m-b-20">Sign in</button>
				</div>
			</div>
			<hr/>
			<div className="row">
				<div className="col-md-10">
					<p className="text-inverse text-left m-b-0">Thank you.</p>
					<a className="text-inverse text-left" href="https://iscreenstore.com"><b className="f-w-600">Back to website</b></a>
				</div>
				<div className="col-md-2">
					
				<img src={IMAGES_URL+'/about/deep.png'} alt="logo" className="img-50" />
				</div>
			</div>
		</div>
                            </div>
                        </form>
                </div>
            </div>
        </div>
    </section>    
	<div className="footer bg-inverses brand-color">
            <p className="text-center"> Copyright © {new Date().getFullYear()} Iscreen Developed By<b> <a  href="#" className="text-blue">Deep Socket</a></b> All rights reserved.</p>
        </div> 
</> 

 );
        
}

export default React.memo(Login)  