import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, Currency, FormatNumber} from '../../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle, CSVExport } from 'react-bootstrap-table2-toolkit';
import Select from 'react-select'

import {Type} from 'react-bootstrap-table2-editor';

 const ShippingLocation =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    const [province, setProvince] = useState([]);
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	let initialState = {
		code:'l'+Math.random().toString(36).substr(2,9),
		location:'',
		stateId:'',
		amount:0,
		isActive:true
	}
	const [location, setLocation] = useState(initialState)
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);

    const handleChangeNumber =(event)=>{
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		setLocation({...location, [name] : value });
		setErrors({...errors, [name]:''});
	}

    const handleDelete =()=>{
          
        const config = {
            headers: { 'content-type': 'multipart/form-data'}
        } 

    let dataId = []
    var newContent = [...content];
    for (var i in newContent){
        if(newContent[i].status===true){
            dataId.push(newContent[i].ID)
            
        }
    }
    let close =   document.getElementById('btnWarningDialog-delAll')
    if(dataId.length===0){
        Alerts('Error!', 'danger', Tooltip.deleteWarning)
          
    }else{
        setNotice({...notice, 
            isLoading: true}) 
    var data = JSON.stringify(dataId, null, 2)

        const fd = new FormData();
        fd.append('data', data)
          
    let url = ServerUrl+'/deleteMultiple_controller.php?tablename=tbl_shipping_location';
          axios.post(url, fd, config)
          .then(response =>{
           if(response.data.type ==='success'){
              Alerts('Saved!', 'success', response.data.message)
              
                  } else{
              Alerts('Error!', 'danger', response.data)
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, 
                  isLoading: false
              })
              fetchContent()
          }) 
        }
        close.click();
}

const handleSelect = (option, action)=>{
    if(ValidateExistence(option.label)!==true){
    setLocation({...location, [action.name]:option, location:option.label})
    setErrors({...errors, location:''});
    }
}

const handleSubmit = (event) =>{
    event.preventDefault();         
    setErrors(ValidateInput(location));
        setIsSubmitting(true);
  }
  
useEffect(()=>{
  if(Object.keys(errors).length === 0 && isSubmitting){
  submitLocation()
  }
  },[errors, isSubmitting])

  const ValidateInput =(values)=>{
    let errors = {};   
    if(!values.location){
        errors.location =Tooltip.required;
    }

    if(values.amount<0){
        errors.amount ='Please enter a valid amount'; 
    }
    
    return errors;
    } 

const ValidateExistence=(value)=>{
    
        const config = {
            headers: { 'content-type': 'multipart/form-data'}
        }    
        const fd = new FormData();
        fd.append("data", value)
        let url = ServerUrl+'/existence_controller.php?tablename=tbl_shipping_location&column=location';
        axios.post(url, fd, config).then(response=>{
            if(response.data===true){
                setErrors({...errors, location:value + ' already exist'})
                setLocation({...location, location:''});
                return response.data;
            }
      })
    
} 

const fetchProvince =()=>{

    var sql ="Select ID, stateName from tbl_states order by ID ASC";
    
    const config = {
        headers: { 'content-type': 'multipart/form-data'}
    }    
    const fd = new FormData();
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller.php';
    
    axios.post(url, fd, config).then(result=>{ 
        setProvince(result.data)
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })

}

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select ID, code, location, amount, isActive, status  from tbl_shipping_location order by location ASC " ;


        const config = {
            headers: { 'content-type': 'multipart/form-data'}
        }    
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
 
function submitLocation(){  
    
    setNotice({...notice, 
      isLoading: true})     
      const config = {
          headers: { 'content-type': 'multipart/form-data'}
      }    
      let formData = location;
      const fd = new FormData();
      for(let k in formData){
        fd.append(k, formData[k])
      }
     
         let url = ServerUrl+'/save_controller.php?tablename=tbl_shipping_location';
        axios.post(url, fd, config)
        .then(response =>{
         if(response.data.type ==='success'){
            
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', response.data)
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            })
            fetchContent()
            setLocation(initialState)
            let close =   document.getElementById('closeLocation')
					close.click();
        }) 
    
}
  const handleCheckOne =(e)=>{  
     
    let {name, value} = e.target

    const newContent = [...content];
    const otherContent = newContent.filter(item=>item.ID!==name)
    const item = newContent.filter(item=>item.ID===name)
    const status = value ===''? true:value==='true'?false:true 
    item[0].status = status
    const currentContent = item.concat(otherContent)
  setStatus({...status, checkAll:false})
   setContent(currentContent) 
       }
    

  const handleCheckAll =()=>{
    var newContent = JSON.parse(JSON.stringify(content));
    for (var i in newContent){
        newContent[i].status=!status.checkAll
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent)
 }

 
  const generalCheck =()=><div className="checkbox-fade fade-in-success">
  <label>
<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
      <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
  </label>
</div> 

    const tableHeader = [
        {dataField: 'code',  text: generalCheck(), editable: false, formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.ID}  checked={row.status} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},
        {dataField: 'location', text: 'Location', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'This field is required'
              };
            }
            return true;
        }},
        {dataField: 'amount', text: 'Shipping Fee', sort: true, validator: (newValue, row, column) => {
            if (!Number(newValue)) {
              return {
                valid: false,
                message: 'This field required valid number'
              };
            }
            return true;
        }, formatter:(cell)=><div className="text-center">{Currency+FormatNumber(cell)}</div>},
        {dataField: 'isActive', text: 'Active', sort: true, editor: {
            type: Type.SELECT, options:[{value:'true', label:'Active'},
            {value:'false', label:'Inactive'}]
          }, formatter:(cell)=>cell==='true'? <div className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i title="Double click to change" className="fa fa-times  badge-inverse-danger"></i></div>},
     ];
    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, ID)=>{       
         const fd = new FormData(); 
             fd.append('newValue', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
           const config = {
               headers: { 'content-type': 'multipart/form-data'}
           }
            let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_shipping_location'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
      <div className="dt-buttons btn-group pull-right">
      <button data-toggle="modal"   data-target='#addLocation' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</button>

    <button className="btn btn-outline-danger" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button>
 </div>                   
                 </div>
                                 
                     <BootstrapTable 
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return props.fetchContent;
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
      const handleChange =(event)=>{
		setLocation({...location, isActive : !location.isActive });
	}

     useEffect(()=>{
        fetchContent()
        fetchProvince()
       },[]);

        return (<Template menu="Shipping"
        submenu="Customer Location"
        pageUrl="/customerlocation">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Customer Location"
	   				menu="Customer Location"
					   />


<div className="modal fade " id='addLocation' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add New Location</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Province " toolTip={'Province or state'} />
					</div>
									
						<div className="col-sm-9 ">
                        <Select  
				  options={province&&province.map((state, idx)=> {
					return {key:idx, value: state.ID, label: state.stateName, index:idx };
				  })}
onChange={handleSelect}  name="stateId"  value={location.stateId} styles={selectStyles} />
						 <span className="error">{errors.location}</span>	</div>
                           
				</div>

			  <div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Amount" toolTip={'Enter shipping fee'} />
					</div>
									
						<div className="col-sm-9 ">
					<input type="text"  name="amount" className="form-control" onChange={handleChangeNumber} value={location.amount} /><span className="error">{errors.amount}</span>
							</div>
				</div>
                <div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Published"  toolTip={'Check to publish this location'} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={location.isActive} name="isActive" checked={location.isActive} onChange={handleChange} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeLocation' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={handleSubmit} className="btn btn-inverse">Save</button>
              </div>
          </div>
      </div>
  </div>
{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal  handleAction={handleDelete} mID={'delAll'} /> 


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Location</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content} fetchdata={fetchContent} columns={tableHeader}  />}
            </div>
           
    
    </div></div></div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(ShippingLocation) 