import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, Token, config, API_URL} from '../../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { compare } from '../../components/globalFunction'

 const Specification =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);

    const handleDeleteSelected =()=>{
          
         

        let dataId = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status==='true'){
                dataId.push(newContent[i].code)
            }
        }
        let close =   document.getElementById('btnWarningDialog-delAll')
        if(dataId.length===0){
            Alerts('Error!', 'danger', Tooltip.deleteWarning)
              
        }else{
            setNotice({...notice, 
                isLoading: true}) 
        var data = JSON.stringify(dataId, null, 2)
        
            const fd = new FormData();
            fd.append('data', data)
        let url = API_URL+'/specification/delete_multiple';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  
                      } else{
                  Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
                  fetchContent()
              }) 
            }
            close.click();
    }

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        let url = API_URL+'/specification/display/all';
        
        axios.get(url, config).then(result=>{ 
            if(Array.isArray(result.data.data)){ 
            setContent(result.data.data)
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  

  const handleCheckOne =(e)=>{

    let {name, value} = e.target

    const currentContent = content.map((item)=>{
                   
        if(item.code ===name){
            return {...item, status:item.status==='false'?'true':'false'}
        }

         return item
          })

  setStatus({...status, checkAll:false})
   setContent(currentContent)
        
       }
    

  const handleCheckAll =()=>{
    var newContent = JSON.parse(JSON.stringify(content));
    for (var i in newContent){
        newContent[i].status=String(!status.checkAll)
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent)
 }

  const GeneralCheck =()=><div className="checkbox-fade fade-in-success">
  <label>
<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
      <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
  </label>
</div> 

    const tableHeader = [
        {dataField: 'code',  text: <GeneralCheck />, editable: false, formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.code} checked={row.status==='true'?true:false} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},
        {dataField: 'specification_name', text: 'Name', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'This field required valid number'
              };
            }
            return true;
        }},
        {dataField: 'displayOrder',  text: 'Display Order',  sort: true, validator: (newValue, row, column) => {
            if (!Number(newValue)) {
              return {
                valid: false,
                message: 'This field required valid number'
              };
            }
            return true;
        }, formatter:(cell)=><div className="text-center">{cell}</div>},
        {dataField: 'id', text: 'Action', formatter: (cell, row)=><a href={`/specification-attributes/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a>, editable: false},
       
     ];
    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, code)=>{       
            const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('code', code);
           let url = API_URL+'/specification/change_field'
          axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '20', value: 20}, 
			  {text: '50', value: 50}, 
			  {text: '70', value: 70}, 
			  {text: '100', value: 100}, 
			  {text: '150', value: 150},
			  { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                 
                                 
                                 <div className="dt-buttons btn-group pull-right"> 
    <a href="/specification-attributes/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</a>
                                
    <button className="btn btn-outline-danger" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button>
 </div>                   

      
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.code);
                               
                              return props.fetchContent;
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      





     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
            menu="Catalog"
            submenu="Attributes">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="Specification attributes" description='Listing all products Specification attributes' icon='feather icon-book'>
	
    
                        </PageHeader>
        
        


{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal  handleAction={handleDeleteSelected} mID={'delAll'} /> 

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
				<div className="card-block bg-success" >
       	
 <div className="row">
<div className="col-sm-12">
					Specification attributes are product features i.e, dress size, number of pockets, visible on product details page. Specification attributes can be used for filtering products on the category details page, brand product page and vendor product page. Unlike product attributes, specification attributes are used for information purposes only. You can add attribute for your product using existing list of attributes, or if you need to create a new one go to Catalog {'>'} Attributes {'>'} Specification attributes.

</div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Specifications</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content.sort(compare)} fetchdata={fetchContent} columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Specification) 