import React, { useState, useEffect, useRef } from "react";
import Template from "../../components/template";
import PageHeader from "../../components/pageheader";
import { LoadEffect, TableLoader } from "../../components/loader";
import { FormLabel } from "../../components/formControl";
import Tooltip from "../../components/toolTips";
import { Alerts, WarningModal, InfoModal } from "../../components/notify";
import { ServerUrl, IMAGES_URL, Currency, FormatNumber, Token, config,  FrontEndUrl, API_URL } from "../../components/include";
import axios from "axios";
import { useParams } from "react-router-dom";

import {Type} from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {  compare } from '../../components/globalFunction'
const Edit = () => {
  let myRef = useRef();
  let params = useParams();
  const [notice, setNotice] = useState({
    isLoading: false,
    isDataFetching: false,
  });
  const [errors, setErrors] = useState({});
 
  const [dataType, setDataType] = useState('shippingAddress');
const [displayStatus, setDisplayStatus] = useState(false)
const [editOrder, setEditOrder] = useState(false)
const [address, setAddress] = useState([])
const [editMethod, setEditMethod] = useState(false)


const [content, setContent] = useState({
  product:[],
  orders:[]

})

const [note, setNote] = useState({
	ID:Math.random().toString(36).substring(2,9),
	dateCreate:new Date().toISOString().slice(0,10)+' '+ new Date().toLocaleTimeString(),
	fileUrl:'',
	isDisplay:false,
	isFile:false,
	note:''

})
const [shippingMethod, setShippingMethod] = useState([])



const [noteFile, setNoteFile] = useState('');
const resetNoteFile =()=>{
	
	document.getElementById('logo').value = '';
	setNoteFile('')
	setNote({...note, fileUrl:'', isFile:false})
}




const fetchContent =()=>{
  setNotice({...notice, isDataFetching:true})
let url = API_URL+'/order/info/'+params.code;

axios.get(url, config)
.then(result =>{ 
   if(result.data.status===200){

    setContent({
           orders:result.data.order,
           product:result.data.data
       }) 
   }
   
})
.catch((error)=>{
   Alerts('Error!', 'danger', error.message)
}).finally(()=>{
   setNotice({...notice, isDataFetching:false})
})

}





 

  const handleDelete = () => {
    let close = document.getElementById("btnWarningDialog-delAll");
    close.click();
   
if(content.product.length!==0){
  Alerts("Error!", "danger", 'You must remove all products associated to this order first before you can delete');

  //window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
}else{
    setNotice({ ...notice, isLoading: true });

    const fd = new FormData();
    fd.append("ID", "ID");
    fd.append("data", content.orders[0].ID);
    fd.append('jwt', Token)
    let url =  ServerUrl +  "/delete_controller.php?tablename=tbl_orders";
    axios
      .post(url, fd, config)
      .then((response) => {
        if (response.data.type === "success") {
          Alerts("Saved!", "success", response.data.message);
          window.open("/sales/orders", "_self");
        } else {
          Alerts("Error!", "danger", response.data);
        }
      })
      .catch((error) => {
        Alerts("Error!", "danger", error.message);
      })
      .finally(() => {
        setNotice({ ...notice, isLoading: false });
      });
    
  }}

  const handleEditShipping = (data, dataType) => {
	setAddress(data)
	setDataType(dataType)
 let close = document.getElementById('openShippingModal');
 close.click();
}

  const handleChangeAddress =(event)=>{
	const newAddress = [...address] 
	let {name, value} = event.target;
	newAddress[0][name] = value
	setAddress(newAddress);
}

const handleUpdateShippingAddress =()=>{
	const newContent = JSON.parse(JSON.stringify(content.orders[0])) 
	newContent.shippingAddress = JSON.stringify(address, null, 2)
	let close = document.getElementById('closeShipping');
	close.click();
	submitUpdate(newContent)
}

const handleUpdateCustomerDetails =()=>{
	const newContent = JSON.parse(JSON.stringify(content.orders[0])) 
	newContent.customer_details = JSON.stringify(address, null, 2)
	let close = document.getElementById('closeShipping');
	close.click();
	submitUpdate(newContent)
}




  const handleChange = (event) => {
    const newContent = [...content.orders];
    let { name, value } = event.target;
    newContent[0][name] = value;
    setContent({...content,orders:newContent});
    setErrors({ ...errors, [name]: "" });
  };

  const handleSaveTotal = () => {
    const newContent = JSON.parse(JSON.stringify(content.orders[0]));
	var subtotal = Number(newContent.subtotal)
	var tax = Number(newContent.tax)
	var discount = Number(newContent.discount)
	var shippingFee = Number(newContent.handling_fee)
	var total = subtotal - discount + shippingFee + tax
	newContent.order_total = total;
 submitUpdate(newContent)

  };
  



  const handlePaymentStatus = () => {
	let close = document.getElementById("btnInfoDialog-markPaid");
    close.click();
  setNotice({ ...notice, isLoading: true })
	const fd = new FormData(); 

  fd.append('code', Math.random().toString(36).substring(2,9))
  fd.append('customer_code', content.orders[0].customer_code)
  fd.append('payment_details', '[]')
  fd.append('transaction_id', Math.random().toString(36).substring(2,9))
  fd.append('reference', 'admin')
  fd.append('payment_method', 'Admin Payment')
  fd.append('payment_status', 'Paid')
  fd.append('amount_paid', content.orders[0].order_total)
  fd.append('amount_topay', content.orders[0].order_total)
  fd.append('order_id', content.orders[0].order_code)

let url = API_URL+'/cart/payment';
  axios.post(url, fd, config)
  .then((response) => {
    if(response.data.status === 200){
	  Alerts("Saved!", "success", response.data.message);
	} else {
	  Alerts("Error!", "danger", response.data);
	}
  })
  .catch((error) => {
	Alerts("Error!", "danger", error.message);
  })
  .finally(() => {
	setNotice({ ...notice, isLoading: false });
	fetchContent()
  }); 

  };


  function submitUpdate(formData){  
	setNotice({...notice, 
	  isLoading: true})     
	  
	  const fd = new FormData();
	  for(let k in formData){
		fd.append(k, formData[k])
	  }
		 let url = API_URL+'/order/update_order';
		axios.post(url, fd, config)
		.then(response =>{  
      if(response.data.status ===200){
			Alerts('Saved!', 'success', response.data.message)
				} else{
			Alerts('Error!', 'danger', response.data)
				}   
		})
		.catch((error)=>{
			 Alerts('Error!', 'danger', error.message)
		}).finally(()=>{
			fetchContent()
			setNotice({...notice, 
				isLoading: false
			})
			setEditOrder(false)
			setEditMethod(false)
		}) 
}



   
   function removeNoteUrl(notes, imageUrl) {
    setNotice({ ...notice, isLoading: true });
    
    const fd = new FormData();
	  fd.append('notes', notes);
	  fd.append('code', content.orders[0].code);
	  fd.append('imageUrl', imageUrl);
    let url = API_URL + "/order/notes/remove";
    axios
      .post(url, fd, config)
      .then((response) => {
        if (response.data.status === 200) {
          Alerts("Saved!", "success", response.data.message);
        } else {
          Alerts("Error!", "danger", response.data);
        }
      })
      .catch((error) => {
        Alerts("Error!", "danger", error.message);
      })
      .finally(() => {
        setNotice({ ...notice, isLoading: false });
	   fetchContent()
      });
  }




  function submit(notes) {
    setNotice({ ...notice, isLoading: true });
    
    const fd = new FormData();

	  fd.append('notes', notes);
	  fd.append('code', content.orders[0].code);
	  fd.append('imageUrl', note.fileUrl);

    if (noteFile !== "") {
      fd.append("image", noteFile);
    }


    let url = API_URL+'/order/notes/change_field'
    axios
      .post(url, fd, config)
      .then((response) => {
        if (response.data.status === 200) {
          Alerts("Saved!", "success", response.data.message);
        } else {
          Alerts("Error!", "danger", response.data);
        }
      })
      .catch((error) => {
        Alerts("Error!", "danger", error.message);
      })
      .finally(() => {
        setNotice({ ...notice, isLoading: false });
	   fetchContent()
	   setNoteFile('')
			setNote({
				ID:Math.random().toString(36).substring(2,9),
				dateCreate:new Date().toISOString().slice(0,10)+' '+ new Date().toLocaleTimeString(),
				fileUrl:'',
				isDisplay:false,
				isFile:false,
				note:''
					})
      });
  }

  

  const handleFile = function (fieldName) {
    return function (newValue) {
      const value = newValue.target.files[0];
      // setImage(value)
      readURL(newValue);
    };
  };

  const readURL = (input) => {
    let displayMessage = "";

      var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
      if (FileSize > 5) {
        displayMessage =
          " File uploaded is more than maximum size Allow. it exceeds 5 Mb";
        document.getElementById(input.target.name).value = "";
		setNoteFile("");
		setNote({...note, fileUrl:''})
      } else {
        if (input.target.files && input.target.files[0]) {
          //Check if input has files.
		  setNoteFile(input.target.files[0]);

		  var fileName =  document.getElementById('logo').value;
		  var idxDot = fileName.lastIndexOf(".") + 1;
		  var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

		   var name =  Math.random().toString(36).substring(2,9)+'.'+extFile
		  setNote({...note, fileUrl:name, isFile:true})
        }
      }

    if (displayMessage.length > 0) {
      Alerts("Error!", "danger", displayMessage);
    }
  };






  const handleCancel =()=>{
	let close = document.getElementById("btnInfoDialog-cancelOrder");
	close.click();
	UpdateColumn('Cancelled');
}



  const UpdateColumn =(newValue = content.orders[0].order_status)=>{
  setNotice({ ...notice, isLoading: true })
	const fd = new FormData(); 
  fd.append('newValue', newValue);
  fd.append('column', 'order_status');
  fd.append('code', content.orders[0].code);

  let url = API_URL+'/order/change_field'
  
  axios.post(url, fd, config)
  .then((response) => {

	if (response.data.status === 200) {
		setDisplayStatus(false)
	  Alerts("Saved!", "success", response.data.message);
	} else {
	  Alerts("Error!", "danger", response.data);
	}
  })
  .catch((error) => {
	Alerts("Error!", "danger", error.message);
  })
  .finally(() => {
	setNotice({ ...notice, isLoading: false });
	fetchContent()
  }); 
}
  

const handleRemoveNote =(row)=>{
	let close =   document.getElementById('btnWarningDialog-'+row.ID)
		close.click();

	const newContent = [...content.orders] 
    const oldNote = JSON.parse(newContent[0].notes);
    const newNote  = oldNote.filter(item=>item.ID!==row.ID)
		removeNoteUrl(JSON.stringify(newNote), row.fileUrl)
		
 }



const handleDeleteProduct =(ID)=>{
  let close =   document.getElementById('btnWarningDialog-'+ID)
		close.click();
  

  setNotice({...notice, 
    isLoading: true}) 

  const fd = new FormData();
  fd.append('ID', 'ID')
  fd.append('data', ID)
  fd.append('jwt', Token)
let url = ServerUrl+'/delete_controller.php?tablename=tbl_product_orders';
    axios.post(url, fd, config)
    .then(response =>{
     if(response.data.type ==='success'){
      Alerts('Saved!', 'success', response.data.message)
        } else{
      Alerts('Error!', 'danger', response.data)
        }   
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
      setNotice({...notice, 
        isLoading: false
      })
      fetchContent()
    }) 
  
  
}

  
  const tableCustomerItemsHeader = [
        
	{dataField: '',  text: '#', editable: false, footer:'#', formatter:(cell, row, index)=>index+1},
{dataField: 'product_code', text: 'Picture', footer:'Picture',  editable: false, formatter:(cell, row)=><img id={row.ID} className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+"/noImage.png"}} src={`${IMAGES_URL}/products/${row.coverUrl}`}  title={row.product_code}  alt={row.product_code} />},

  {dataField: 'productName', text: 'Product Name', footer:'Product Name', editable: false, sort: true, formatter:(cell, row)=><div><span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}><a href={`/products/edit/${row.product_code}`} target="_blank" rel="noopener noreferrer">{row.productName}</a></span><b>Code:</b> {row.sku}</div>},

  {dataField: 'attributes', text: 'Product Attributes', footer:'Product Attributes', editable: false, sort: true, formatter:(cell, row)=> <div>{cell.length!==0?JSON.parse(cell).map(data=>
    <div key={data.attribute_code} className="font-size-sm">
      <span className="text-muted mr-2">{data.attribute_name}:</span>{data.optionName}</div>
):''}</div> },

	{dataField: 'price', footer:columnData => Currency+FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0)), text: 'Price ('+Currency+')', editable: false, sort: true, formatter:(cell, row)=>row.quantity_price > 0? FormatNumber(row.quantity_price):FormatNumber(row.price)},


  {dataField: 'shipping_fee', footer:columnData => Currency+FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0)), text: 'Shipping ('+Currency+')', sort: true, editable: false, formatter:(cell)=>FormatNumber(cell)},


	{dataField: 'quantity', footer:'Qty', text: 'Qty', sort: true, editable: false},


  {dataField: 'total_attribute_price',  text: 'Attributes Price', sort: true, editable: false, footer:columnData => Currency+FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0))},

  {dataField: 'total', footer:columnData => Currency+FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0)),  text: 'Total ('+Currency+')', sort: true, editable: false, formatter:(cell)=>FormatNumber(cell)},

/* 
  {dataField: 'info', footer:'Info', text: 'Info', sort: true, editable: false, formatter: (cell, row)=><div style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</div>}, */

	{dataField: 'ID', footer:'Action', text: 'Action', formatter: (cell, row)=><div>
		
		<WarningModal  handleAction={()=>handleDeleteProduct(row.id)} mID={row.id} />
			&nbsp;<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.id}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>
		</div>, editable: false},
   
 ];

 const TableCustomerItems=(props)=>{

  const { SearchBar } = Search;
	 const customTotal = (from, to, size) => (
		<span >&nbsp;Showing { from } to { to } of { size } items</span>
	  );
	 const options = {
		showTotal: true,
	  paginationTotalRenderer: customTotal,
	  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
	  { text: 'All', value: props.data.length }]
	   
	  };
	 return  <ToolkitProvider search columnToggle 
				 keyField='ID' data={ props.data } columns={ props.columns } >
					 
					 {
						 props => (
						 <div >
						   <div className="form-group">
               <SearchBar
								 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
	
</div>                   
							 
				 <BootstrapTable 
					{ ...props.baseProps }
					striped
				hover
				condensed
				noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
			pagination={paginationFactory(options) }
							 />
						 </div>
						 )
					 }
  </ToolkitProvider>
  }


const handleAddNote =(event)=>{
	event.preventDefault()
	let close =   document.getElementById('closeNote')
	close.click();
	const newNote = JSON.parse(content.orders[0].notes);
	const noteList = newNote.concat(note)
	submit(JSON.stringify(noteList));		
}

const handleChangeNote =(event)=>{
		let {name, value} = event.target;
		if( name ==='isDisplay' ){
			value = value==='false'?true:false
		}
		if( name ==='isFile' ){
			value = value==='false'?true:false
		}
		setNote({...note, [name]:value})
	}


  const  tableOrderNoteHeader = [
	{dataField: 'dateCreate',  text: 'Created On', editable: false},
	{dataField: 'note',  text: 'Note'},
	{dataField: 'fileUrl',  text: 'Attached File', editable: false, formatter: (cell, row)=>cell!==''?<a  rel="noopener noreferrer" target="_blank" href={IMAGES_URL+"/notes/"+cell} className="btn btn-info btn-sm"> <i className="fa fa-download"></i> Download File</a>:'No file attached'},			
	{dataField: 'isDisplay',  text: 'Display to Customer', editor: {
		type: Type.SELECT, options:[{value:true, label:'Display'},
		{value:false, label:'Do not Display'}]
	  }, formatter:(cell, row)=>row.isDisplay===true? <div style={{cursor:'pointer'}} className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div style={{cursor:'pointer'}}className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>},
	  {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>
	  <WarningModal  handleAction={()=>handleRemoveNote(row)} mID={row.ID} />
		  <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.ID}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>
	  </div>, editable: false},

			  ];
		 
	
	
	const TableOrderNoteRecord=(props)=>{
		const handleUpdate=(column, newValue, ID)=>{     
			
			 const newContent = [...content.orders] 
			const newNote = JSON.parse(newContent[0].notes);
	
			const otherNote = newNote.filter((list)=>list.ID!==ID)
			const noteData = newNote.filter((list)=>list.ID===ID)	
			noteData[0][column.dataField] = newValue
			const currentNote = otherNote.concat(noteData)
			newContent[0].notes = JSON.stringify(currentNote)
			submitUpdate(newContent[0])  
			
		} 
				 const { SearchBar } = Search;
				 
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='ID' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										
								 
	<div className="dt-buttons btn-group"> 
		 <button data-toggle="modal"   data-target='#addNote' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New Note</button>
 </div>                   
	 </div>
										 
	<BootstrapTable
				{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
					cellEdit={ cellEditFactory({
						mode: 'dbclick',
						blurToSave: true,
						autoSelectText: true,
			  beforeSaveCell: (oldValue, newValue, row, column) => {
				if(oldValue !==newValue){
				handleUpdate(column, newValue, row.ID);
				
			   return true;
			  }
			  
			}
					  }) }
				/>
			</div>
			)
		}
</ToolkitProvider>
        }
        

  useEffect(() => {
    fetchContent();

  
  }, []);

  return (<Template 
    menu="Sales"
submenu="Orders">
  
 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }

<div  className="main-body">
        <div className="page-wrapper">

<PageHeader title="Order details" description='Update order information here' icon='feather icon-shopping-cart'>

<li className="breadcrumb-item"><a href="/sales/orders">Orders</a> </li>
            </PageHeader>




  <div className="modal fade " id='addNote' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add Order Note</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Note" toolTip={'Enter this order note message'} />
					</div>
									
						<div className="col-sm-9 ">
					<textarea rows="5" name="note" className="form-control" onChange={handleChangeNote} value={note.note} >{note.note}</textarea>
							</div>
				</div>
				<div className="form-group row">
			<div className="col-sm-5">
					<FormLabel name ="Attach File" toolTip={'Upload a file attached to this order note'}  />
			</div>
							
				<div className="col-sm-7 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={note.isFile} name="isFile" checked={note.isFile} onChange={handleChangeNote} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>(check to upload file)
				</label>
			</div>
					</div>
			</div>


			{note.isFile?		<div className="form-group row">
					<div className="col-sm-3">
							{note.fileUrl?note.fileUrl:''}
					</div>
									
						<div className="col-sm-9 ">
						

						<div className="form-group">
				<label className="btn btn-success btn-round btn-sm" htmlFor="logo" > Choose  file </label>
                   
                <input id="logo" onChange={handleFile('logo')}   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
				{note.fileUrl?<button type="button" onClick={resetNoteFile} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>:''}	
										</div>
							</div>
					</div>:''}




			<div className="form-group row">
			<div className="col-sm-5">
					<FormLabel name ="Display to Customer" toolTip={'A value indicating wheather to display this note to customer'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={note.isDisplay} onChange={handleChangeNote} name="isDisplay" checked={note.isDisplay}  />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
			
			
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeNote' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={handleAddNote} className="btn btn-inverse">Save</button>
              </div>
          </div>
      </div>
  </div>


<button className="display-none" data-toggle="modal" id="openShippingModal" data-target="#editShipping" ></button>

  <div className="modal fade " id='editShipping' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Edit Address</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
{address&&address.map((adr, id)=><div key={id}>
			  <div className="form-group row">
					<div className="col-sm-3">
						<FormLabel name ="Full name" toolTip={'Enter firstname and lastname'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChangeAddress}  name="fullname" value={adr.fullname} />
						</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Email" toolTip={'Enter email'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChangeAddress} name="email" value={adr.email} />
							</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Company" toolTip={'Enter company name'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChangeAddress} name="company" value={adr.company} />
							</div>
				</div>


				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Address" toolTip={'Enter Address'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChangeAddress}  name="address" value={adr.address} />
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Telephone" toolTip={'Enter Telephone'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChangeAddress}  name="telephone" value={adr.telephone} />
							</div>
				</div>

							</div>)}
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeShipping' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={dataType==='shippingAddress'? handleUpdateShippingAddress:handleUpdateCustomerDetails}  className="btn btn-inverse">Save</button>
              </div>
          
		  </div>
      </div>
  </div>



          {/* The Page Body start here */}
      {content.orders&&content.orders.map((data, id)=> 
          <div key ={id} className="page-body">


            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-block">
                    <section className="col-sm-12">
                      <div className="pull-right">
                        <a className="btn btn-success btn-sm" href={ServerUrl+'/downloadOrderInvoicePDF_controller.php?tablename=tbl_orderInvoice&orderId='+params.code}><i className="fa fa-file"></i> Download Invoice (PDF)</a>
                        &nbsp;

                        <a href="/sales/orders" className="btn btn-warning btn-sm"> <i className="fa fa-arrow-left"></i> Return
                        </a> &nbsp;<button className="btn btn-danger btn-sm" data-toggle="modal" data-target={`#warningdialog-${"delAll"}`} ><i className="icofont icofont-delete-alt"></i> Delete
                        </button>
                        <WarningModal  handleAction={handleDelete} mID={"delAll"}  />
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>

            <div className="row  ">
              <div className="col-sm-12">
                <div className="card z-depth-0">
                  <div className="card-header">
                    <h5>
                      <i className="fa fa-info"></i> Info
                    </h5>
                    <div className="card-header-right">
                      <ul className="list-unstyled card-option">
                        <li>  <i className="feather icon-maximize full-card"></i>  </li>
                        <li>  <i className="feather minimize-card icon-minus"></i> </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-block">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card z-depth-0">
                          <section className="card-block">
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel name="Order ID #" toolTip={Tooltip.orderNumber} />
                              </div>

                              <div className="col-sm-9 ">
                                <span>{data.order_code}</span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel name="Created on"   toolTip={Tooltip.createdOn} />
                              </div>

                              <div className="col-sm-9 "> <span>{data.created_at}</span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-3">
                              <FormLabel  name="Customer"  toolTip={Tooltip.customerId} />
                              </div>

                              <div className="col-sm-9 "> {data.customer_code!=='' || data.customer_code!==undefined?
                              
                                <a href={`/customer/edit/${data.customer_code}`}>{JSON.parse(data.customer_details)[0].first_name  + ' '+JSON.parse(data.customer_details)[0].last_name}</a>:JSON.parse(data.customer_details)[0].first_name + ' '+JSON.parse(data.customer_details)[0].last_name}
                              </div> 
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel
                                  name="Order status"  toolTip={Tooltip.orderStatus}  />
                              </div>
							 
                {data.order_status!=='Cancelled'?
                              <div className="col-sm-9 ">
                                <div className="form-group">
                                  <b>{data.order_status}</b>&nbsp;
								  <InfoModal
                          handleConfirm={handleCancel }
						  mID={"cancelOrder"}
						  title="Are you sure you want to perform this action?"
                        />	
									 <button  data-toggle="modal"  data-target={`#infoDialog-${"cancelOrder"}`}
                                    type="button"  className="btn btn-danger btn-sm btn-round" >  Cancel Order  </button>
                                  &nbsp;{!displayStatus?
                                  <button  onClick={()=>setDisplayStatus(true)}  type="button"  className="btn btn-inverse btn-round btn-sm" >
                                    Change Status  </button>:''}
                                </div>
                              </div>:'Cancelled'}



                            </div>

                        {displayStatus?    <div className="form-group row">
                              <div className="col-md-4"></div>

                              <div className="col-md-8 ">
                                <div className="row">
                                  <div className="col-md-8">
                                    
									<select  name="order_status" value={data.order_status} onChange={handleChange}  className="form-control" id='order_status' >

                                   {/*  <option value="Pending">Pending</option> */}
                                      <option value="Processing"> Processing</option>
                                    <option value="Delayed">Delayed</option>
                                    <option value="Delivered">Delivered</option>
                                    <option value="Completed">Completed</option>
                                    </select>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <button
									  onClick={(e)=>UpdateColumn()}
                                        type="button"  className="btn btn-inverse btn-sm "  >Save </button>
                                      &nbsp;
                      <button  onClick={()=>setDisplayStatus(false)} type="button"  className="btn btn-info btn-sm"  >  Cancel </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>:''}
                          </section>
                        </div>

                        <div className="card z-depth-0">
                          <section className="card-block">
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel
                                  name="Order subtotal"
                                  toolTip={Tooltip.orderSubtotal}
                                />
                              </div>

                              <div className="col-sm-5 ">
                              
							  {!editOrder?
							    <span> {Currency + FormatNumber(data.subtotal)}
                                  <b> excl tax</b>
                                </span>:  
                                <input onChange={handleChange} type="number" className="form-control"  name="subtotal" value={data.subtotal}  />}
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel  name="Delivery Fee " toolTip={Tooltip.orderShipping}
                                />
                              </div>

                              <div className="col-sm-5 ">
                              {!editOrder?  <span>
                                  {Currency + FormatNumber(data.handling_fee)}
                                </span>:
                                <input onChange={handleChange}  type="number" className="form-control" name="handling_fee"  value={data.handling_fee}
                                />}
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel
                                  name="Order Tax"
                                  toolTip={Tooltip.orderTax}
                                />
                              </div>

                              <div className="col-sm-5 ">
							  {!editOrder? <span>{Currency + FormatNumber(data.tax)}</span>:
                                <input onChange={handleChange}
                                  type="number"
                                  className="form-control"
                                  name="tax"
                                  value={data.tax}
                                />}
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel  name="Promo Amount" toolTip={"Coupon Discount"}
                                />
                              </div>

                              <div className="col-sm-5 ">
                              <span>{Currency + FormatNumber(data.promo_amount)}</span>
                              </div>
                            </div>


                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel  name="Order Discount" toolTip={"Total Discount Applied"}
                                />
                              </div>

                              <div className="col-sm-5 ">
                              {!editOrder?  <span>{Currency + FormatNumber(data.discount)}</span>:
                                <input onChange={handleChange}  type="number" className="form-control" name="discount" value={data.discount}
                                />}
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel
                                  name="Order Total"
                                  toolTip={Tooltip.orderTotal}
                                />
                              </div>

                              <div className="col-sm-9 ">
                                <span>{Currency + FormatNumber(data.order_total)}</span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel  name="Profit" toolTip={Tooltip.profit}
                                />
                              </div>

                              <div className="col-sm-5 ">
                              {!editOrder?  <span>{Currency + FormatNumber(data.profit)}</span>: <input onChange={handleChange} type="number" className="form-control"  name="profit" value={data.profit}
                                />}
                                <br />

								{!editOrder?   <button
								onClick={()=>setEditOrder(true)} type="button" className="btn btn-inverse btn-sm "
                                >
                                  Edit Order Totals
                                </button>:
                                <div className="form-group">
                               <button  onClick={handleSaveTotal}  type="button"  className="btn btn-success btn-sm " >Save Order Totals  </button>
                                      &nbsp;
                             <button  onClick={()=>setEditOrder(false)}  type="button"  className="btn btn-info btn-sm"  > Cancel </button>
                                    </div>}
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel  name="Payment Method" toolTip={Tooltip.paymentMethod}
                                />
                              </div>

                              <div className="col-sm-9 ">
                                <span>{data.payment_method}</span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel name="Amount Paid" toolTip={'Amount Paid'}
                                />
                              </div>

                              <div className="col-sm-9 ">
                                <span> {Currency + FormatNumber(data.amount_paid)}</span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel name="Reference" toolTip={'Payment Reference'}
                                />
                              </div>

                              <div className="col-sm-9 ">
                                <span>{data.reference}</span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel
                                  name="Payment Status"
                                  toolTip={Tooltip.paymentStatus}
                                />
                              </div>

                              <div className="col-sm-9 ">
                                <span>{data.payment_status}</span>
                                <br />
								<InfoModal
							   handleConfirm={handlePaymentStatus }
							   mID={"markPaid"}
							   title="Are you sure you want to perform this action?"
							 />	
						{data.payment_status!=='Paid'?
                               
										  <button
										  data-toggle="modal"
										  data-target={"#infoDialog-markPaid"}
										 type="button"  className="btn btn-inverse btn-sm "
                                > Mark as Paid
                                </button>:''}
                              </div>
                            </div>
                          </section>
                        </div>

                        <div className="card z-depth-0">
                          <section className="card-block">
                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel name="Store" toolTip={Tooltip.storeName}
                                />
                              </div>

                              <div className="col-sm-9 ">
                                <span>Iscreen Repair</span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel name="Comments" toolTip={'Customer Comments'}
                                />
                              </div>

                              <div className="col-sm-9 ">
                                <b>{data.comment} </b>
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-3">
                                <FormLabel  name="Last IP address" toolTip={Tooltip.customerIp}
                                />
                              </div>

                              <div className="col-sm-9 ">
							  {data.ip_address}
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  ">
              <div className="col-sm-12">
                <div className="card z-depth-0">
                  <div className="card-header">
                    <h5>
                      <i className="fa fa-bus"></i> Shipping Info
                    </h5>
                    <div className="card-header-right">
                      <ul className="list-unstyled card-option">
                        <li>
                          <i className="feather icon-maximize full-card"></i>
                        </li>
                        <li>
                          <i className="feather minimize-card icon-minus"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-block">
                    <div className="row">
                      <div className="col-sm-12">


                      <div className="card z-depth-0">
								<section className="card-block">
								<div className="form-group row">
								<div className="col-sm-3">
								<FormLabel name="Shipping Status" toolTip={'Shipping Status'} />
								</div>

								<div className="col-sm-9 ">
								
					{!editMethod?<div><span>{data.shipping_status}</span>&nbsp;&nbsp;
								<button onClick={()=>setEditMethod(true)} type="button"  className="btn btn-inverse btn-sm "
                                >  Edit </button></div>:''}

							{editMethod?	<div className="input-group">
								<input  type="text" onChange={handleChange}   className="form-control" name="shipping_status" value={data.shipping_status} />
								<button 	onClick={()=>submitUpdate(content.orders[0])}  type="button"  className="btn btn-success btn-sm "  >
                                  Save </button>
								<button 	onClick={()=>setEditMethod(false)}  type="button" className="btn btn-info btn-sm "
                                >Cancel </button>
								</div>:''}

								</div>
								</div>

								<div className="form-group row">
								<div className="col-sm-3">
								<FormLabel 	name="Shipping Type" 	toolTip={'Shipping mode of delivery'}
								/>
								</div>

								<div className="col-sm-9 ">
								<span>{data.shipping_type}	</span>
								</div>
								</div>
                <div className="form-group row">
								<div className="col-sm-3">
								<FormLabel 	name="Shipping Method" toolTip={'Shipping mode of delivery'}	/>
								</div>

								<div className="col-sm-9 ">
								<span>{shippingMethod&&shippingMethod.filter(item=>item.code===data.shipping_method).map(item=>item.methodName +' ('+item.location+') ' + item.deliveryPeriod)}	</span>
								</div>
								</div>

								</section>
								</div>

                        <div className="card z-depth-0">
                          <section className="card-block">
                            <div className="row">
                              <div className="col-md-6">
								  <div className="table-responsive">
                                <table  className="table table-hover table-bordered"  >
                                  <thead><tr>
                                      <th colSpan="2">
                                        <strong>Customer Information</strong>
                                      </th></tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <td>Full name</td>
                                    <td>{data&&JSON.parse(data.customer_details)[0].first_name+ ' '+JSON.parse(data.customer_details)[0].last_name}</td>
                                  </tr>
                                  <tr>
                                    <td>Email</td>
                                    <td> <a  href="#!"  >  [{data&&JSON.parse(data.customer_details)[0].email_address}]
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Phone</td>
                                    <td>{data&&JSON.parse(data.customer_details)[0].telephone}</td>
                                  </tr>
                                  <tr>
                                    <td>Company</td>
                                    <td>{data&&JSON.parse(data.customer_details)[0].company}</td>
                                  </tr>
                                  <tr>
                                    <td>Address </td>
                                    <td style={{ width:'100%', whiteSpace:'normal', wordWrap:'break-word' }}>{data&&JSON.parse(data.customer_details)[0].address}</td> 
                                  </tr>
                                                      </tbody> 
                                </table>
								</div>
                                <div className="row">
                                  <div className="form-group">
                                    <div className="col-md-12">
									<button
									  onClick={()=>handleEditShipping(JSON.parse(data.customer_details), 'customerDetails')}
                                        className="btn btn-primary"  >  Edit </button> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <div className="col-md-6">
								  <div className="table-responsive">
                             {data.shipping_type==='Deliver to me'?   <table  className="table table-hover table-bordered"
                                 
                                >
                                 <thead><tr>
                                      <th colSpan="2">
                                        <strong>Shipping address</strong>
                                      </th></tr>
                                  </thead>
                                <tbody>
								  
                                  <tr>
                                    <td>Full name</td>
                                    <td>{JSON.parse(data.shipping_address).fullname}</td>
                                  </tr>
                                  <tr>
                                    <td>Email</td>
                                    <td>
                                      <a
                                        href="#!"
                                      >
                                        [{JSON.parse(data.shipping_address).email_address}]
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Phone</td>
                                    <td>{JSON.parse(data.shipping_address).telephone}</td>
                                  </tr>
                                  <tr>
                                    <td>Company</td>
                                    <td>{JSON.parse(data.shipping_address).company}</td>
                                  </tr>
                                  <tr>
                                    <td>Address </td>
                                    <td>{JSON.parse(data.shipping_address).address}</td>
                                  </tr>
                                  
                                  <tr>
                                    <td colSpan="2">
                                      <img  alt="google maps" src={IMAGES_URL+'/google-maps.gif'}
                                      />
                                      <a  href="https://www.google.com/maps/@9.0578695,7.4922513,15z" rel="noopener noreferrer"  target="_blank" >  View address on Google Maps  </a>
                                    </td>
                                  </tr></tbody> 
                                </table>:

                  <table  className="table table-hover table-bordered" >
                  <thead><tr>
                        <th colSpan="2">
                          <strong>Pickup Address</strong>
                        </th></tr>
                    </thead>
                   <tbody>

                    <tr>
                      <td>Address</td>
                      <td>{JSON.parse(data.pickup_address).address}</td>
                    </tr>
                    <tr>
                      <td>Contact Person</td>
                      <td>  {JSON.parse(data.pickup_address).telephone}
                      </td>
                    </tr></tbody> </table>}
								        </div>
                        {data.isPickup_address==='false'?   <div className="row">
                                  <div className="form-group">
                                    <div className="col-md-12">
                                    <button  onClick={()=>handleEditShipping(JSON.parse(data.shipping_address), 'shippingAddress')} className="btn btn-primary"  >  Edit </button> 
                                    </div>
                                  </div>
                                </div>:''}

                              </div>

                            </div>
                          </section>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



         

				<div className="row  " id="products">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-th-list"></i> Products</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li>
                          <i className="feather minimize-card icon-minus"></i>
                        </li>
				</ul>
			</div>
		</div>
	<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                         
					<div className="row">
					<div className="table-responsive">
		 {notice.isDataFetching?<TableLoader />: <TableCustomerItems  data={content.product} columns={tableCustomerItemsHeader}  />} 
		</div>
						</div>
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


				<div className="row " id="orderNote">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-sticky-note-o"></i> Order Notes</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li>
                          <i className="feather minimize-card icon-minus"></i>
                        </li>
				</ul>
			</div>
		</div>
	<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                             

					<div className="row">
					<div className="table-responsive">
		  {<TableOrderNoteRecord data={JSON.parse(data.notes).sort(compare)} columns={tableOrderNoteHeader}  />} 
		</div>
						</div>


					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

          </div>

           )} 

          {/* The Page Body close here */}
        </div>
      </div>
    </Template>
  );
};

export default React.memo(Edit);
