import React, {useState, useEffect, useRef} from 'react'
import {Alerts} from '../components/notify'
import {ServerUrl, PictureUrl, config} from '../components/include'
import axios from 'axios'
import Cookies from 'js-cookie'
import Preloader from '../components/preloader'
import { LoadEffect } from '../components/loader'
import { useParams } from 'react-router'


 const NewPassword =()=> {
	 let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [user, setUser] = useState({
		email:'',
        token:params.mytoken,
        password:'',
        confirmPassword:'',
		rsMessage:''
    })

    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
    const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(user));
        setIsSubmitting(true);
    }
    const ValidateInput =(values)=>{
        let errors = {};   
        if(values.confirmPassword !== values.password){
            errors.confirmPassword ='Password end does not match';
        }

        if(values.password.length !==6){
            errors.password ='Password must contain at least 6 characters, including numbers.';
        } 

        if(!values.password){
            errors.password ='Please enter your password';
        } 
        
        if(!values.confirmPassword){
            errors.confirmPassword ='Please re-enter your password';
        }
        

        return errors;
        }
		useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            submit()
            }
            },[errors])

        	
	function submit(){  
		setNotice({...notice,  isLoading: true}) 	
           
        let formData = user;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }
        
           let url = ServerUrl+'/update_controller.php?tablename=tbl_update_password';
		axios.post(url, fd, config)
		.then(response =>{
	    if (response.data.type ==='success'){
			setUser({...user, rsMessage:response.data.message, password:'', confirmPassword:''})
			
            setInterval(() => {
                window.location.href='/'
               }, 3000)

			}else if (response.data.type ==='error'){
				Alerts('Error!', 'danger', response.data.message)
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
			 
		}).finally(()=>{
            setIsSubmitting(false)
            setNotice({...notice,  isLoading: false }) 
        })     
}

const verifyToken =()=>{
   
    var sql =" select fullname, email FROM tbl_reset_password where token ='"+params.mytoken+"' and email ='"+params.myemail+"' and status ='active' and dateCreate >= DATE_SUB(NOW(), INTERVAL 2 HOUR)  "
     
    const fd = new FormData();
    fd.append("sql", sql);
    let url = ServerUrl+'/verify_password.php?tablename=tbl_verify_password';
    axios.post(url, fd, config).then(result=>{

    if(Array.isArray(result.data) && result.data.length>0){
        setUser({...user, email:result.data[0].email})
    }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })

}

const clearCookies=()=>{
	Cookies.remove('stfms')	
 Cookies.remove('stfmscd')
 Cookies.remove('mspgcd')
 Cookies.remove('msptk')
 Cookies.remove('msprls')

 localStorage.setItem('_tmlsp', Date.now())
 localStorage.setItem('_btmlsp', Date.now())
 localStorage.setItem('_mspmnulk', Date.now())
}

useEffect(()=>{  
 clearCookies()
 verifyToken()
},[]);


        return (<>
          <Preloader />

		  {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		  <section style={{background: `url(${PictureUrl}/bg.jpg) no-repeat`}}
		  className="login-block">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
        {user.email.length===0?<div className='alert alert-danger'>Invalid or expired Token</div>:             
	<form className="md-float-material form-material">
		<div className="text-center">
		<img src={PictureUrl+'/logo.png'} className="img-100" alt="logo" />
		</div>
	<div className="auth-box card">
		
        
        <div className="card-block">
			<div className="row m-b-20">
				<div className="col-md-12">
			
			{user.rsMessage.length!==0?
				<div className='alert alert-success'>{user.rsMessage}</div>:''}

					<h3 className="text-center">Choose a new password</h3>
				</div>
			</div>

			<div className="form-group form-primary">
			<input type="password" id="password" name="password"  value={user.password} placeholder="New Password" onChange={handleChange} className={errors.password  ? 'form-control formerror' : 'form-control'} /> 
			
			</div><span className=" form-bar error">{errors.password }</span>

            <div className="form-group form-primary">
			<input type="password" id="cpassword" name="confirmPassword"  value={user.confirmPassword} placeholder="Confirm Password" onChange={handleChange} className={errors.confirmPassword  ? 'form-control formerror' : 'form-control'} /> 
			
			</div><span className=" form-bar error">{errors.confirmPassword }</span>


			<div className="row m-t-30">
				<div className="col-md-12">
					<button type="button" onClick={handleSubmit} className="btn_full btn btn-action btn-block btn-lg btn-inverse">Change Password</button>
				</div>
			</div>


            <p className="f-w-600 text-right">Back to <a href="/">Login.</a></p>
		
		</div>



                            </div>
                        </form>}
                </div>
            </div>
        </div>
    </section>
         
</> 

 );
        
}

export default React.memo(NewPassword) 