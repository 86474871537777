import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {CustomToggleList} from '../../components/dataTable'
import {ServerUrl, FormatNumber, Currency, Token, config} from '../../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

import Select from 'react-select'

 const ShipmentList =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    const [search, setSearch]= useState({
        fromDate:'',
        toDate:'',
        customerCode:'',
        orderStatus:'',
        shippingCompany:'',
        orderId:''
    })
    
    
    const [content, setContent] = useState([]);
	const [customer, setCustomer] = useState([])
    const SearchTable =(event)=>{
       event.preventDefault();
       fetchContent();
    }


		

  const fetchCustomer =()=>{


	var sql ="Select id, code, first_name, last_name, telephone from tbl_customers order by first_name ASC" ;
	const fd = new FormData();
	fd.append("sql", sql);
    fd.append('jwt', Token)
	let url = ServerUrl+'/fetchBySql_controller.php';
	axios.post(url, fd, config).then(result=>{  
		setCustomer(result.data)
	})
	.catch((error)=>{
		Alerts('Error!- customer', 'danger', error.message)
	})
}


    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select ID, code, orderId, trackingId, dateCreate,	orderStatus, shippingCompany, estimateDatetime, courierPhone, note, customerCode  from tbl_track_order where code <> ''  "

        if(search.fromDate.length!==0 && search.toDate.length!==0 ){
            sql = sql + ' and dateCreate between = "'+search.fromDate+'" and "'+search.toDate+'"'
        }
        
         if(search.customerCode.length!==0){
            sql = sql + ' and customerCode = "'+search.customerCode.value+'"'
        }


        if(search.orderId.length!==0){
            sql = sql + ' and orderId = "'+search.orderId+'"'
        }

        if(search.shippingCompany.length!==0){
            sql = sql + ' and shippingCompany Like "'+search.shippingCompany+'"'
        }

        if(search.orderStatus.length!==0){
            sql = sql + ' and orderStatus = "'+search.orderStatus+'"'
        }

        
        
        sql = sql + ' order by ID DESC'
          
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}

    


    const tableHeader = [
        
    {dataField: '',  text: 'Action',  sort: true, editable: false, formatter:(cell, row)=><a href={"/sales/shipmentList/edit/"+row.orderId} className="btn btn-warning btn-sm" ><i className="fa fa-pencil"></i>Edit </a>},
    {dataField: 'orderId',  text: 'Order ID',  sort: true, editable: false},

{dataField: 'trackingId',  text: 'Tracking Number',  sort: true, editable: false},

{dataField: 'shippingCompany',  text: 'Company',  sort: true, editable: false},

{dataField: 'orderStatus',  text: 'Status',  sort: true, editable: false},



{dataField: 'note',  text: 'Note',  sort: true, editable: false, formatter:(cell)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},

{dataField: 'dateCreate',  text: 'Created On',  sort: true, editable: false}
       
     ];
    
     const TableRecord=(props)=>{
         const { SearchBar } = Search;  
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                            
                     <BootstrapTable 
                        { ...props.baseProps }
                        striped
                    hover
                    condensed
                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                pagination={paginationFactory(options) }
              
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
      const handleSelect = (option, action)=>{
		setSearch({...search, [action.name]:option.value})
	  } 

     useEffect(()=>{
        fetchContent()
        fetchCustomer()
       },[]);

        return (<Template 
            menu="Sales"
            submenu="Shipments">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="Shipments" description='Listing All Shipment Record' icon='feather icon-shopping-cart'>
                        </PageHeader>
        
        
        


{/* The Page Body start here */}
<div className="page-body">
         

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">   

            <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="From Date" toolTip={Tooltip.fromDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="fromDate" value={search.fromDate} />
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="To Date" toolTip={Tooltip.toDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="toDate" value={search.toDate} />
                        </div>
                    </div>


                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Customer" toolTip={'Customer Name'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                       <Select  options={
				customer&&customer.map((data, id)=>{
					return {value:data.code, key:id, label:data.fullname}
				})}  
                
onChange={handleSelect}  name="customerCode"  value={search.customerCode}  /> 
                        </div>
                    </div>
                  
             
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">

                <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Order ID" toolTip={'Unique identifier of the order'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="orderId" value={search.orderId} />
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Company" toolTip={'Delivery Company'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <input type="text" className="form-control" onChange={handleChange}  name="shippingCompany" value={search.shippingCompany} />
                        </div>
                    </div>


                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Order Status" toolTip={'Status of the order'} />
                    </div>
                   
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="orderStatus" value={search.orderStatus} className="form-control" onChange={handleChange}>
                    <option value="Order confirmed">Order confirmed</option>
                                      <option value="Picked by the courier"> Picked by the courier</option>
                                    <option value="On the way">On the way</option>
                                    <option value="Ready for pickup">Ready for pickup</option>
                    </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Order list</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(ShipmentList) 