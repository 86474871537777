import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {CustomToggleList} from '../../components/dataTable'
import {ServerUrl, FormatNumber, Currency, Token, config} from '../../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle, CSVExport } from 'react-bootstrap-table2-toolkit';

 const Discount =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    
    const [search, setSearch]= useState({
        discountType:'all',
        startDate:'',
        endDate:'',
        couponCode:''
    })
    
    const [content, setContent] = useState([]);

    const SearchTable =(event)=>{
        event.preventDefault();
        fetchContent();
     }

     const handleChange =(event)=>{
        let {name, value} = event.target;
        setSearch({...search, [name] : value });
    }
    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select ID, discountName, status, timeUsed, startDate, endDate, discountType, percentage, isPercentage, discountAmount, code from tbl_discount where discountName <> '' " ;

        if(search.discountType.length==='all'){
            sql = sql + ' and discountType = "'+search.discountType+'"'
        }
        if(search.startDate.length!==0 && search.endDate.length!==0 ){
            sql = sql + ' and startDate >= "'+search.startDate+'" and endDate <= "'+search.endDate+'"'
        }
        if(search.couponCode.length!==0){
            sql = sql + ' and couponCode = "'+search.couponCode+'"'
        }
      
  
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  
    

    const tableHeader = [
        {dataField: 'discountName',  text: 'Name', editable: false, sort: true, formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
            {dataField: 'discountType',  text: 'Type',  sort: true, editable: false},
    {dataField: 'discountAmount',  text: 'Discount',  sort: true, editable: false, formatter:(cell, row)=><span >{row.isPercentage==='true'?row.percentage+'%':Currency+FormatNumber(row.discountAmount)}</span>},
            {dataField: 'startDate',  text: 'Start Date',  sort: true, editable: false},
            {dataField: 'endDate',  text: 'End Date',  sort: true, editable: false},
            {dataField: 'timeUsed',  text: 'Time Used',  sort: true, editable: false},
			{dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/discount/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a>, editable: false}
       
     ];
    
     const TableRecord=(props)=>{

         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                   
                <div className="dt-buttons btn-group pull-right"> 
                <a href="/discount/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</a>
                                
        </div>             
             </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                    
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      


     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
            menu="Promotions"
          submenu="Discount">
            
           {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
         
         <div  className="main-body">
              <div className="page-wrapper">
        
        <PageHeader title="Discount" description='Displaying All Discount' icon='feather icon-package'>
        
                </PageHeader>


{/* The Page Body start here */}
<div className="page-body">

         <div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group row">
                <div className="col-lg-4 col-md-4 col-sm-4">
							<FormLabel name ="Start Date" toolTip={Tooltip.startDate} />
					</div>
									
                    <div className="col-lg-8 col-md-8 col-sm-8">
							<div className="input-group">
						<input type="datetime-local" onChange={handleChange}  name="startDate" value={search.startDate} className='form-control '/>
							
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-lg-4 col-md-4 col-sm-4">
							<FormLabel name ="End Date" toolTip={Tooltip.endDate} />
					</div>
									
                    <div className="col-lg-8 col-md-8 col-sm-8">
							<div className="input-group">
						<input type="datetime-local" onChange={handleChange}  name="endDate" value={search.endDate} className='form-control '/>
							
							</div>
							</div>
					</div>
                    
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Coupon Code" toolTip={Tooltip.couponCode} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="couponCode" value={search.couponCode} />
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Discount Type" toolTip={Tooltip.discountType} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select name="discountType" value={search.discountType} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="total">Assigned to order total</option>
                        <option value="products">Assigned to products</option>
                        <option value="categories">Assigned to categories</option>
                        <option value="manufacturers">Assigned to manufacturers</option>
                        <option value="shipping">Assigned to shipping</option>
                        <option value="subtotal">Assigned to order subtotal</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Discount</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
           
    </div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Discount) 