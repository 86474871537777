import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { Popover, LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl} from '../../components/include'
import axios from 'axios'
import { staffCode, config, Token} from '../../components/include'
import { useParams } from 'react-router-dom'
import { longDate } from '../../components/globalFunction'

 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});


    const [content, setContent] = useState([])
	
	
    const handleDelete =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
		

		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'code')
		fd.append('data', params.code)
		fd.append('jwt', Token)
	let url = ServerUrl+'/delete_controller.php?tablename=tbl_product_review';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/product-review', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
		
		
}

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select c.email, c.fullname, r.code, p.productName, p.sku, r.productId,  r.customerId, r.reviewText, r.title, r.isApproved, r.dateTime, r.rating, r.ID, r.status, r.staffApprove, r.replyText   from tbl_product_review r, tbl_customers c, tbl_products p where p.code = r.productId and c.code= r.customerId and r.code = '" +params.code+"'";
        
         
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append('jwt', Token)
        axios.post(url, fd, config).then(result=>{ 
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
	const [defaultUrl, setDefaultUrl] = useState('');
	
	
	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;	
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}

	const handleChangeCheck =(event)=>{
		const newContent =JSON.parse(JSON.stringify(content))  
			let {name, value} = event.target;
			value = value ==='true'?'false':'true'	
			newContent[0][name]= value
			setContent(newContent);
			setErrors({...errors, [name]:''});
		}

	
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		    
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
			fd.append("StaffId", staffCode)
			fd.append('jwt', Token)
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_product_review';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.title){
			errors.title =Tooltip.required;
			myRef.current.focus()
		}
		if(!values.reviewText){
			errors.reviewText =Tooltip.required;
		}
		return errors;
		}


useEffect(()=>{
	fetchContent()
},[]);	
	
        return (<Template 
            menu="Catalog"
        submenu="Product Reviews">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">

<PageHeader title="Edit Product Reveiw" description='Update product review details here' icon='feather icon-book'>
	
<li className="breadcrumb-item"><a href="/product-review">Product Reviews</a> </li>

                    </PageHeader>
		
		

{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-inverse btn-sm" onClick={(e)=>handleSubmit(e, '/product-review')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/product-review/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	<a href="/product-review" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDelete()} mID={'delAll'} /> 


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-chat"></i> Review</h5>
			<div className="card-header-right">
			<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
			<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
				
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Product" toolTip={Tooltip.reviewProduct} />
					</div>
									
						<div className="col-sm-9 ">
							<a href={"/products/edit/"+data.productId} >{data.productName}<br/> Code: <b>{data.sku}</b></a>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Vendor" toolTip={'The vendor name inwhich this review was written'} />
					</div>
									
						<div className="col-sm-9 "><a  href={"/vendor/edit/"+data.vendor}>{data.vendorName}</a>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Customer" toolTip={Tooltip.customerReview} />
					</div>
									
						<div className="col-sm-9 ">
							<a  href={"/customer/edit/"+data.customerId}>{data.email}</a>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Title" toolTip={Tooltip.reviewTitle} />
					</div>
									
					<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  ref={myRef} name="title" value={data.title} className={errors.title  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.title}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Review Text" toolTip={Tooltip.reveiwText} />
					</div>
									
					<div className="col-sm-9 ">
							<div className="input-group">
								<textarea name="reviewText" onChange={handleChange} value={data.reviewText} className={errors.reviewText  ? 'form-control formerror' : 'form-control '}rows="4" >{data.reviewext}

								</textarea>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.reviewText}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Reply Text" toolTip={Tooltip.replyText} />
					</div>
									
					<div className="col-sm-9 ">
							<div className="input-group">
								<textarea name="replyText" onChange={handleChange} value={data.replyText} className={errors.replyText  ? 'form-control formerror' : 'form-control '} rows="4" >{data.replyText}

								</textarea>
							
							</div>
							</div>
					</div>
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Rating"  toolTip={Tooltip.rating} />
			</div>
							
				<div className="col-sm-9 ">{data.rating}
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Is Approved"  toolTip={Tooltip.isApproved} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={data.isApproved} name="isApproved" checked={data.isApproved==='true'?true:false} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Created On"  toolTip={Tooltip.dateCreate} />
			</div>
							
				<div className="col-sm-9 ">{data.dateTime}
					</div>
			</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>
				
				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 