import React, {useState} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect } from '../../components/loader'
import Tooltip from '../../components/toolTips'
import {Alerts} from '../../components/notify'
import {ServerUrl} from '../../components/include'
import axios from 'axios'
import {staffCode, config, Token} from '../../components/include'

 const Import =()=> {
	const [notice, setNotice] = useState({
        isLoading : false
	});
	const [errors, setErrors] = useState({});
	   
const [excelFile, setExcelFile]=useState('')

const  handleFile = function(fieldName){
    return function(newValue){  
        setErrors({...errors, [fieldName]:''})
   readURL(newValue);
   }
   
} 

  const readURL = (input)=>{       
    let displayMessage = '';
    var fileName = document.getElementById('excelFile').value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile==="csv" || extFile==="CSV"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1 Mb';
        document.getElementById(input.target.name).value = '';
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            const value = input.target.files[0]
     setExcelFile(input.target.files[0])
     setErrors({...errors, categoryExcel:''})
     }
    }
    }else{
        displayMessage = 'Only excel (.csv) files are allowed with maximum of 1Mb'
        document.getElementById(input.target.name).value = '';
        setExcelFile('')
    }   
    
    if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
} 

 const handleSubmit = event =>{
    event.preventDefault();    
     if(!excelFile){
        var categoryExcel ='Please choose file to upload';
        setErrors({...errors, categoryExcel:categoryExcel})

    }else{
        setNotice({...notice,   isLoading: true})    
    const fd = new FormData(); 
    fd.append('excelFile', excelFile);  
    fd.append('staffCreate', staffCode); 
    fd.append('jwt', Token)
      let url = ServerUrl+'/importExcel_controller.php?tablename=tbl_category';
      axios.post(url, fd, config)
      .then(response =>{
    if (response.data.type ==='success'){
          Alerts('Success!', 'success', response.data.message)
          } else{
            Alerts('Error!', 'danger', response.data)
          } 
      })
      .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
           
      }).finally(()=>{
        setNotice({...notice,  isLoading: false  }) 
        document.getElementById('excelFile').value=''
        setExcelFile(''); 
      })   
           
    } 
}
	
        return (<Template 
            menu="Catalog"
        submenu="Category">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">

<PageHeader title="Import Category" description='Upload bulk category here' icon='feather icon-book'>
	
<li className="breadcrumb-item"><a href="/category">Category</a> </li>

                    </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
								

			<div className="row  ">
			<div className="col-sm-12">
								
	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-upload"></i> Upload Bulk Category</h5>
			<div className="card-header-right">
            <ul className="list-unstyled card-option">
                <li><i className="feather icon-minus minimize-card"></i></li>
            </ul>
			</div>
		</div>
			<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block ">
                        <h4 className="text-bold">Prepare your category file:</h4>      
                <div className="row">
                        <div className="col-sm-3 text-right bold text-red"><u>Required Columns</u></div>
                        <div className="col-sm-7 bold"><u>Allowed values</u></div>                           
                            <div className="col-sm-3 text-right text-bold">Category Name</div>
                            <div className="col-sm-7">{Tooltip.categoryName} <br/>                  
                            </div>    
                                           								
                            <div className="col-sm-3 text-right text-bold">Display Order</div>
                            <div className="col-sm-7">{Tooltip.displayOrder}<br/></div>                                                      
                            <div className="col-sm-3 text-right text-bold">Published</div>
                            <div className="col-sm-7">{Tooltip.published}<br/>     <br/> 
                                </div>
                            <div className="col-sm-3 text-right text-bold text-yellow"><u>Optional Columns</u></div>
                            <div className="col-sm-7 text-bold"><u>Allowed Values</u></div>                           
                            <div className="col-sm-3 text-right text-bold">Description</div>
                            <div className="col-sm-7">{Tooltip.categoryDescription}<br/>     
                                </div>
                               
                                <div className="col-sm-3 text-right text-bold">Show on home page</div>
                            <div className="col-sm-7">{Tooltip.showOnHome}<br/>     
                                </div>
                                <div className="col-sm-3 text-right text-bold">Include in top menu</div>
                            <div className="col-sm-7">{Tooltip.includeTopMenu}<br/>     
                                </div>
                               
                                <div className="col-sm-3 text-right text-bold">Price Range</div>
                            <div className="col-sm-7">{Tooltip.priceRange}<br/>     
                                </div>
                                <div className="col-sm-3 text-right text-bold">Page Name</div>
                            <div className="col-sm-7">{Tooltip.seoName}<br/>     
                                </div>
                                <div className="col-sm-3 text-right text-bold">Meta Title</div>
                            <div className="col-sm-7">{Tooltip.metaTitle}<br/>     
                                </div>
                                <div className="col-sm-3 text-right text-bold">Meta Keywords</div>
                            <div className="col-sm-7">{Tooltip.metaKeywords}<br/>     
                                </div>
                                <div className="col-sm-3 text-right text-bold">Meta Description</div>
                            <div className="col-sm-7">{Tooltip.metaDescription}<br/>     
                                </div>
                        </div>
	</section></div></div></div>


    <div className="row " >
        <div className=" card ">
            <div className="well"><h4 className="alert-heading ">Download CSV File</h4>

            <form method='POST' action={ServerUrl+'/downloadCsvSample_controller.php?tablename=tbl_category'} target='_blank'>
                <input type='hidden' name='jwt' value ={Token} />
                
        <button type="submit" className="btn btn-warning btn-sm pull-right"> <i className="fa fa-download"></i> Download Sample File</button>
        
        </form>

<p><span className="text-info">The first line in downloaded CSV file is the header and should remain as it is. Please do not change the order of columns.</span><br/><span className="text-success">The correct column order is (<b>Category Name, Display Order,  Published, Description,  ShowonHome, IncludeInTopMenu, Page Name, Meta Title, Meta Keywords, Meta Description</b>)</span> <span className="text-primary">&amp; you must follow this.</span></p>

 <a href="https://www.ablebits.com/office-addins-blog/2014/04/24/convert-excel-csv/" className="bold" target="_blank">Also, Click to see How to convert Excel file to CSV</a>
</div>
        </div></div>

</div>

<div className="row">
<div className="col-lg-12">
           <div className="card">
              <div className="card-body"> 
              
			<div className="text-white bg-primary shadow-primary">
            <section className="col-md-12 ">
            <h4 className="alert-heading ">Upload Excel File</h4>
						{Tooltip.uploadHelp}
                        <hr/><br/> 
                            <div className="form-row">
                    <input id="excelFile" name="excelFile" accept=".csv" onChange={handleFile('excelFile')} className="form-control"  type="file" /> 
                    <span style={{color:'red'}}>{errors.categoryExcel && errors.categoryExcel}</span>
					</div> 								
					
                									
                <hr/>
			<button type="button" id="submit"  onClick={handleSubmit} className="btn  btn-success pull-right"><i className="fa fa-save"></i> Upload from Excel</button>
                            </section>    

                        </div></div> </div></div>
    </div>
							</div>
							</div>
	                   
                            </div>


						

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Import) 