import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts} from '../../components/notify'
import {ServerUrl, Token, config} from '../../components/include'
import axios from 'axios'

import Select from 'react-select'
 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	let initialState = {
		code:'c'+Math.random().toString(36).substr(2,9),
		checkoutName:'',
		textPrompt:'',
		controlType:'select',
		isRequired:false,
		displayOrder:1,
		shippedAble:false,
		isTaxExempt:false,
		taxCategory:'',
		limitedStore:'',
	}
	
	
	const [content, setContent] = useState(initialState)
	const [defaultUrl, setDefaultUrl] = useState('');
	
	const [vendor, setVendor] = useState([])
	const handleChangeNumber =(event)=>{
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleChangeCheck =(event)=>{
		let {name, value} = event.target;	
		value = value ==='true'?false:true
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}
	const handleSelect = (option, action)=>{
		setContent({...content, [action.name]: option});
		setErrors({...errors, [action.name]:''})
		 }

	const fetchVendor =()=>{
		var sql ="Select ID, vendorName,  code from tbl_vendors order by vendorName ASC" ;
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append('jwt', Token)
		let url = ServerUrl+'/fetchBySql_controller.php';
		axios.post(url, fd, config).then(result=>{  
			setVendor(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}
	const handleChange =(event)=>{
		let {name, value} = event.target;
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}


	const [panel, setPanel] = useState({
		info:true,
		values:true,
		condition:false,
		
		basic:true,
		infoDisplay:false,
		valuesDisplay:true,
		conditionDisplay:false
	})

	const goAdvance=()=>{
		setPanel({...panel,
			infoDisplay:true,
			valuesDisplay:true,
			conditionDisplay:true,
			condition:true,
			basic:false
	})
	}

	const goBasic=()=>{
		setPanel({...panel,
			infoDisplay:true,
			valuesDisplay:true,
			conditionDisplay:false,
			basic:true,
			condition:false
	})
	}

	
	const ValidateExistence=()=>{
        if(content.checkoutName!==''){
              
            const fd = new FormData();
            fd.append("data", content.checkoutName)
			fd.append('jwt', Token)
            let url = ServerUrl+'/existence_controller.php?tablename=tbl_checkout_attribute&column=checkoutName';
            axios.post(url, fd, config).then(response=>{
                if(response.data===true){
                    myRef.current.focus()
                    setErrors({...errors, checkoutName:content.checkoutName + ' already exist'})
                    setContent({...content, checkoutName:''});
                    return response.data;
                }
          })
        }
	} 
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		      

		  var limitedStores = [];
		  for (var i = 0, l = (content.limitedStore || []).length; i < l; i++) {
			limitedStores.push({code:content.limitedStore[i].value});
		}

		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }

		  fd.append('productLimitedStore', JSON.stringify(limitedStores, null, 2))
		  fd.append('jwt', Token)
			 let url = ServerUrl+'/save_controller.php?tablename=tbl_checkout_attribute';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.checkoutName){
			errors.checkoutName =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}


	const fetchOrder =()=>{
		var sql ="Select count(*) as num from tbl_checkout_attribute" ;
		  
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append('jwt', Token)
		let url = ServerUrl+'/fetchBySql_controller.php';
		axios.post(url, fd, config).then(result=>{ 
			if(Array.isArray(result.data))
			setContent({...content, displayOrder:Number(result.data[0].num)+1})
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}


		useEffect(()=>{
			fetchVendor()
			fetchOrder()
			},[]);


        return (<Template 
            menu="Catalog"
        submenu="Attributes">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">

<PageHeader title="Add checkout attribute" description='Create a new checkout attribute here' icon='feather icon-book'>
	
<li className="breadcrumb-item"><a href="/products">Products</a> </li>
<li className="breadcrumb-item"><a href="/checkout-attributes">Checkout Attributes</a> </li>
                    </PageHeader>
		
		

{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success btn-sm" onClick={(e)=>handleSubmit(e, '/checkout-attributes')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/checkout-attributes/create')}
  className="btn btn-inverse btn-sm" ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/checkout-attributes/edit/'+content.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/checkout-attributes" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>

	{panel.basic?
	<button type="button" onClick={goAdvance} className="btn btn-sm  pull-right"> Basic Mode <i className="fa fa-check"></i></button> :
	<button type="button" onClick={goBasic} className="btn btn-sm btn-primary pull-right">  Advanced Mode <i className="fa fa-check"></i></button>}

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>



							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, info:!panel.info})}>
			<h5><i className="fa fa-info"></i> Attribute info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.info?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.info?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
				
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.checkoutName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} onMouseOut={ValidateExistence}  ref={myRef} name="checkoutName" value={content.checkoutName} className={errors.checkoutName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.checkoutName}</span>
							</div>
					</div>


                    <div className="form-group row">
                    <div className="col-sm-3">
                            <FormLabel name ="Control type	" toolTip={Tooltip.controlType} />
                    </div>
                                    
					<div className="col-sm-9 ">
                    <select name="controlType" value={content.controlType} className="form-control" onChange={handleChange}>
					<option value="select">Drop-down list</option>
					<option value="radio">Radio button list</option>
					<option value="checkbox">Checkboxes</option>
					<option value="textbox">Textbox</option>
					<option value="textarea">Multiline textbox</option>
					<option value="date">Date picker</option>
					<option value="file">File upload</option>
					<option value="color">Color squares</option>
					<option value="readOnly">Read-only checkboxes</option>
                    </select>
                </div>
                </div>
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Required"  toolTip={Tooltip.isRequired} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={content.isRequired} name="isRequired" checked={content.isRequired} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={content.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>
					
					{panel.infoDisplay?<>	
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Text prompt" toolTip={Tooltip.textPrompt} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  name="textPrompt" value={content.textPrompt} className={'form-control '}/>
							
							</div>
							</div>
					</div>
			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Shippable required"  toolTip={Tooltip.shippedAble} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={content.shippedAble} name="shippedAble" checked={content.shippedAble} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Tax exempt"  toolTip={Tooltip.isTaxExempt} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={content.isTaxExempt} name="isTaxExempt" checked={content.isTaxExempt} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Limited To vendor" toolTip={Tooltip.limitedStore} />
					</div>
									
						<div className="col-sm-9 ">
						<Select isMulti options={
				vendor&&vendor.map((node, id)=>{
					return {value:node.code, key:id, label:node.vendorName}
				})} 
                
onChange={handleSelect}  name="limitedStore"  value={content.limitedStore} styles={selectStyles} /> 
					</div></div>

					</>:''}
				
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>


						{panel.valuesDisplay?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, values:!panel.values})}>
			<h5><i className="fa fa-television"></i> Attribute values</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.values?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.values?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					You need to save the checkout attribute before you can add values for this checkout attribute page.
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}


							{panel.conditionDisplay?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, condition:!panel.condition})}>
			<h5><i className="fa fa-bookmark"></i>conditions</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.condition?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.condition?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					You need to save the checkout attribute before you can edit conditional attributes.
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 