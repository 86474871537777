import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../../components/notify'
import {API_URL,  config,  staffCode} from '../../components/include'
import axios from 'axios'

 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [defaultUrl, setDefaultUrl] = useState('');
	
	const [content, setContent] = useState({
		code:Math.random().toString(36).substr(2,9),
		promo_code:'',	
		percentage:'',
		expiry_date:'0000-00-00',
		status:'Active',
		staff_code: staffCode,	
		usage:[]
	})
	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='status'){
			value = value==='Active'?'Inactive':'Active'
		}
		if(name==='percentage' && value.length>2){
			value = value.slice(0,2)
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	function generateCardNo(){
		let code = Math.random().toString(36).substring(2,9)

			setContent({...content, promo_code : code.toUpperCase() });
			setErrors({...errors, percentage:''});
			return code.toUpperCase()
	}	

	
	const ValidateExistence=()=>{
        if(content.promo_code!==''){
               
            let url = API_URL+'/promo/existence/'+content.promo_code;
            axios.get(url, config).then(response=>{
                if(response.data.status===200){
                    myRef.current.focus()
                    setErrors({...errors, promo_code:content.promo_code + ' already exist'})
                    setContent({...content, code:''});
                    return response.data;
                }
          })
        }
	} 
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		   
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
			
			 let url = API_URL+'/promo/create';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.promo_code){
			errors.promo_code =Tooltip.required;
			myRef.current.focus()
		}

		if(!values.percentage){
			errors.percentage =Tooltip.required;
			
		}
		return errors;
		}

        return (<Template menu="Promotions"
        submenu="Promo Code"
        pageUrl="/promo/create">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Add a new promo code"
	   				menu="Promo Code"
					   menuLink="/promo"
					   subsubmenu="Add a new promo code"
					   />


{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, '/promo')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/promo/create')}
  className="btn btn-inverse " ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/promo/edit/'+content.code)} className="btn btn-inverse "><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/promo" className="btn btn-warning "><i className="fa fa-arrow-left"></i> Return</a>


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i> Promo Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Code" toolTip={"Code use during checkout"} />
					</div>
									
						<div className="col-sm-9 ">


					
							<div className="input-group">
						<input type="text" onChange={handleChange} onBlur={ValidateExistence} ref={myRef} name="promo_code" value={content.promo_code} className={errors.promo_code  ? 'form-control formerror' : 'form-control '}/>
						<span class="input-group-addon" onClick={generateCardNo} id="basic-addon">Generate Code</span>


							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.promo_code}</span>
							</div>
					</div>




					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Percentage" toolTip={"Promo value in %"} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="number" autoComplete="off" onChange={handleChange} max='2'  name="percentage" value={content.percentage} className={errors.percentage  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.percentage}</span>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Expiry Date" toolTip={"Promo expiry date"} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="date" onChange={handleChange}   name="expiry_date" value={content.expiry_date} className={errors.expiry_date  ? 'form-control formerror' : 'form-control '}/>
							
							</div>
							</div>
					</div>


					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Status" toolTip={'Determine if the code is activated'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.status} name="status" checked={content.status==='Active'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


	
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-check"></i> Usage History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
			<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					History of Promo usage
					</div>



					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 