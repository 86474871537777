import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts, WarningModal} from '../components/notify'
import {CustomToggleList} from '../components/dataTable'
import {ServerUrl, FormatNumber, Token, config} from '../components/include'
import axios from 'axios'
import { Currency} from '../components/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

 const ReturnRequest =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
    const [search, setSearch]= useState({
        fromDate:'',
        toDate:'',
        returnStatus:'',
        ID:''
    })
   
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);

	const [customer, setCustomer] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const SearchTable =(event)=>{
       event.preventDefault();
       fetchContent();
    }

  const fetchCustomer =()=>{
	var sql ="Select ID, fullname, email, telephone from tbl_customers order by fullName ASC" ;
	const fd = new FormData();
	fd.append("sql", sql);
    fd.append('jwt', Token)
	let url = ServerUrl+'/fetchBySql_controller.php';
	axios.post(url, fd, config).then(result=>{  
		setCustomer(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}


    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select *  from tbl_return_request where quantity <> '' "

        if(search.fromDate.length!==0 && search.toDate.length!==0 ){
            sql = sql + ' and dateCreate between = "'+search.fromDate+'" and "'+search.toDate+'"'
        }
        
         if(search.ID.length!==0){
            sql = sql + ' and ID = '+search.ID
        }
        if(search.requestStatus!=='all'){
            sql = sql + ' and requestStatus = "'+search.requestStatus+'"'
        }
        
        sql = sql + ' order by ID DESC'
         
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}

  const handleCheckOne =(index)=>{  
     var newContent = JSON.parse(JSON.stringify(content));    
   const value = newContent[index].status ==='true'? false:newContent[index].status ===true?false:true;
   newContent[index].status = value;
   setContent(newContent) 
        
       }
    


    const tableHeader = [
        
{dataField: 'ID',  text: 'ID',  sort: true, editable: false},
{dataField: 'productCode',  text: 'Product',  sort: true, editable: false, formatter:(cell)=><span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
{dataField: 'quantity',  text: 'Quantity',  sort: true, editable: false},
{dataField: 'customerId',  text: 'Customer',  sort: true, editable: false, formatter:(cell)=><span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
{dataField: 'orderId',  text: 'Order #',  sort: true, editable: false},
{dataField: 'dateCreate',  text: 'Date',  sort: true, editable: false},

{dataField: 'requestStatus',  text: 'Status',  sort: true, editable: false},
{dataField: '', text: 'Action', formatter: (cell, row)=><a href="#!" className="btn btn-success btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>, editable: false},
       
     ];
    
     const TableRecord=(props)=>{
         const { SearchBar } = Search;  
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                            
                     <BootstrapTable 
                        { ...props.baseProps }
                        striped
                    hover
                    condensed
                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                pagination={paginationFactory(options) }
              
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
      const handleSelect = (option, action)=>{
		setSearch({...search, [action.name]:option.value})
	  } 

     useEffect(()=>{
        //fetchContent()
       // fetchCustomer()
       },[]);

        return (<Template 
            menu="Sales"
            submenu="Return Request">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="Return Request" description='Listing All Return Requests' icon='feather icon-shopping-cart'>
                        </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">   

            <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="From Date" toolTip={Tooltip.fromDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="fromDate" value={search.fromDate} />
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="To Date" toolTip={Tooltip.toDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="toDate" value={search.toDate} />
                        </div>
                    </div>
                  
             
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">

                <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <FormLabel name ="ID" toolTip={'Search by unique identifier'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="ID" value={search.ID} />
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Return Status" toolTip={"Search by a specific return request status e.g. Received."} />
                    </div>
                   
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="returnStatus" value={search.returnStatus} className="form-control" onChange={handleChange}>
                    <option value="all">All</option>
                    <option value="Pending">Pending</option>
                    <option value="Received">Received</option>
                    <option value="Return authorized">Return authorized</option>
                    <option value="Item(s) repaired">Item(s) repaired</option>
                    <option value="Item(s) refunded">Item(s) refunded</option>
                    <option value="Request rejected">Request rejected</option>
                    <option value="Cancelled">Cancelled</option>
                    </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Order list</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12">
              <p>The Return Request Feature (RMF) enables customers to send products back to you. Here you can find all submitted return requests.</p>
          <div className="table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content} fetchdata={fetchContent} columns={tableHeader}  />}
            </div>
           
    
    </div></div></div>
			

							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(ReturnRequest) 