import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../../components/notify'
import {ServerUrl, FormatNumber, Currency, API_URL} from '../../components/include'
import axios from 'axios'
import { staffCode, Token, config} from '../../components/include'

import Select from 'react-select'
 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [discount, setDiscount] = useState([])
	const [group, setGroup] = useState([])

	const [manufacturer, setManufacturer] = useState([])
	const [productCode, setProductCode] = useState('p-'+Math.random().toString(36).substring(2,9))
	const [vendor, setVendor] = useState([])
	let initialState = {
		productName:'',
		shortDescription:'',
		fullDescription:'',
		sku:'',
		categories:[],
		manufacturers:{value:'', label:''},
		customerRoles:'',
		limitedStore:'',
		tags:[],
		isPublished:false,
		gtin:'',
		mPartNumber:'',
		showOnHome:true,
		displayOrder:1,
		productType:'simple',
		vendor:'',
		isCustomerReveiw:true,
		availableStart:'',
		availableEnd:'',
		isNew:false,
		isNewStart:'',
		isNewEnd:'',
		adminComment:'',
		staffCreate:staffCode,
		price:0,
		oldPrice:0,
		costPrice:0,
		isOutOfStock:false,
		isWishlistDisabled:false,
		isPreorder:false,
		preorderStart:'',
		isCallforPrice:false,
		isCustomerPrice:false,
		minAmount:0,
		maxAmount:0,
		discount:0,
		isTaxExempt:false,
		taxCategory:'',	
		isShippable:false,
		isFreeShipping:false,
		shippings:[{
			weight:'',
			height:'',
			length:'',
			width:'',
			deliveryPeriod:'',
			isShippedSeperate:false,
			shippingCharge:0
		}],
		inventoryMethod:'0',
		inventory:[{
			wharehouse:'0',
			isMultipleWharehouse:false,
			isDisplayAvailability:false,
			isDisplayStockQuantity:false,
			minQuantity:0,
			lowStockActivity:'0',
			notifyAdminNum:'0',
			isBackInStock:true,
			availableRange:'0',
			minCart:1,
			maxCart:'',
			allowedQty:1,
			isReturnable:false
		}],
		pageName:'',
		metaTitle:'',
		metaKeywords:'',
		metaDescription:'',
		coverUrl:'',
		youtubeUrl:'',
		isFeatured:false,
		bulkPrices:'[]',
		attributes:'[]',
		specifications:'[]',
		related:'[]',
		crossSell:'[]',
		quantity:0,
		rating:0,
		isPrinting:false,
		designFee:0,
		saveType:'save',
		designNote:'Order your design by simply uploading your own design or allow us to design it for you. We will print and deliver to your doorstep anywhere within Nigeria.'
	}
	const generateCode =()=>{
		setContent({...content, sku : (Math.random().toString(36).substr(2,9)).toUpperCase() });
	}

	const [content, setContent] = useState(initialState)
	const [category, setCategory] = useState([])
const [defaultUrl, setDefaultUrl] = useState('');
const [image, setImage] = useState('');
	const [tags, setTags] = useState([])

	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

	let basic = {
		basic:true,
		infoDisplay:false,
		displayPrice:false,
		displayShipping:false,
		displayInventory:false,

		displayPrinting:false,
		displayPicture:true,
		displayProductAttribute:false,
		displaySpecAttribute:false,
		seoDisplay:false,
		productDisplay:false,
		displayRelated:false,
		displayCrossSell:false,
	}

	const [panel, setPanel] = useState({
		...basic,
		info:true,
		price:true,
		shipping:true,
		inventory:true,
		picture:true,
		printing:true,
		productAttribute:true,
		specAttribute:true,
		seo:false,
		product:false,
		related:false,
		crossSell:false,
	})

	const goAdvance=()=>{
		setPanel({...panel,
		basic:false,
		infoDisplay:true,
		displayPrice:true,
		displayShipping:true,
		displayInventory:true,
		displayPicture:true,
		displayProductAttribute:true,
		seoDisplay:true,
		productDisplay:true,
		displayRelated:true,
		displayCrossSell:true,
		displayPrinting:true,
	})
	}

	const goBasic=()=>{
		setPanel({...panel,
			...basic})
	}
	
	const fetchCategory =()=>{
		let url = API_URL+'/category/display/all';
        axios.get(url, config).then(result=>{ 
			if(Array.isArray(result.data.data)){ 
            setCategory(result.data.data)
			}
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
  }
  

  

const fetchTags =()=>{
	
	let url = API_URL+'/tag/display/all';
        axios.get(url, config).then(result=>{ 
			if(Array.isArray(result.data.data)){   
		setTags(result.data.data)
			}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}

const fetchGroup =()=>{

	const fd = new FormData();
	fd.append("sql", 'get_groups');
	fd.append('jwt', Token)
	let url = ServerUrl+'/fetch_data.php';
	axios.post(url, fd, config).then(result=>{  
		setGroup(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}



const fetchManufacturer =()=>{
	
	let url = API_URL+'/brand/display/all'
	axios.get(url, config).then(result=>{ 
		if(Array.isArray(result.data.data)){
			setManufacturer(result.data.data)
		}else{
			setManufacturer([])
		}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}) 
}




const fetchVendor =()=>{
	
	let url = API_URL+'/vendor/display/all'
	axios.get(url, config).then(result=>{ 
		if(Array.isArray(result.data.data)){  
		setVendor(result.data.data)
		}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}



const fetchDiscount =()=>{

	const fd = new FormData();
	fd.append("sql", 'get_discount');
	fd.append('jwt', Token)
	let url = ServerUrl+'/fetch_data.php';
	axios.post(url, fd, config).then(result=>{  
		setDiscount(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}

	const handleChangeNumber =(event)=>{
		let {name, value} = event.target;
		value = value.replace(/[^0-9]/g, "")

		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}




	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='pageName'){
			value = value.replace(/,?[ ]/g, '-')
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleChangeDescription =(data, name)=>{
		setContent({...content, [name] : data });
	}

	const handleChangeCheck =(event)=>{
		let {name, value} = event.target;	
		value = value ==='true'?false:true
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleSelect = (option, action)=>{
		setContent({...content, [action.name]: option});
		setErrors({...errors, [action.name]:''})
		 }
	
const handleChangeShipping =(event)=>{

			const newShippings = JSON.parse(JSON.stringify(content.shippings));
			let {name, value} = event.target;
			if(name ==='isFreeShipping' ){
				value = value ==='true'?false:true
			}
			if( name ==='isShippedSeperate' ){
				value = value ==='true'?false:true
			}
			newShippings[0][name] = value
			setContent({...content, shippings : newShippings });
	}

	const handleChangeInventory =(event)=>{

		const newInventory = JSON.parse(JSON.stringify(content.inventory));
		let {name, value} = event.target;
		    
		if(name ==='isMultipleWharehouse' ){
			value = value ==='true'?false:true
		}
		if( name ==='isDisplayAvailability' ){
			value = value ==='true'?false:true
		}
		if(name ==='isDisplayStockQuantity' ){
			value = value ==='true'?false:true
		}
		if( name ==='isBackInStock' ){
			value = value ==='true'?false:true
		}
		if( name ==='isReturnable' ){
			value = value ==='true'?false:true
		}
		newInventory[0][name] = value
		setContent({...content, inventory : newInventory });
}
	

const getCategoryList =(categoryList)=>{
	if(categoryList.length!==0){

categoryList.map(data =>{
	const result = category.filter(item=>item.code===data.code)
	const allCategory = JSON.parse(result[0].productList);


		const fd = new FormData();
		  fd.append('code', data.code)
		  fd.append('totalItem', allCategory.concat([{code:productCode}]).length)
		  fd.append('productList', JSON.stringify(allCategory.concat([{code:productCode}]), null, 2))

            let url = API_URL+'/category/update_list'
           axios.post(url, fd, config)
	.then()
	.catch((error)=>{
	  Alerts('Error!', 'danger', error.message)
	})
})
	}
return ''
}

const getBrand=(code)=>{
	try{

	
	const result = manufacturer.filter(item=>item.code===code)
	const allProductList = JSON.parse(result[0].productList);
	const newProductList = allProductList.concat([{code:productCode}])
	return newProductList

	}catch(e){

	}
	}

const getVendor=(code)=>{
const result = vendor.filter(item=>item.code===code)
const allVendorList = JSON.parse(result[0].productList);
const newVendorList = allVendorList.concat([{code:productCode}])
return newVendorList
}



    function submit(){  
		setNotice({...notice, 
		  isLoading: true}) 
		 
		var category = [];
		var tag = [];

	
		const brandList =content.manufacturers.value===''?'[]':getBrand(content.manufacturers.value)

		var totalBrand = brandList.length

		var customerRole = [];
		var limitedStores = [];
		
		const vendorList = content.vendor
		var totalVendor = vendorList&&vendorList.length

		for (var i = 0, l = (content.categories).length; i < l; i++) {
		  category.push(content.categories[i].value);
		}

		for (var b = 0, c = (content.tags).length; b < c; b++) {
			tag.push(content.tags[b].value);
		}

		for ( i = 0, l = (content.customerRoles).length; i < l; i++) {
			customerRole.push(content.customerRoles[i].value);
		}
		for ( i = 0, l = (content.limitedStore).length; i < l; i++) {
			limitedStores.push(content.limitedStore[i].value);
		}
		
		//const categoryList = getCategoryList(category)
   
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append('shipping', JSON.stringify(content.shippings, null, 2))
		  fd.append('inventories', JSON.stringify(content.inventory, null, 2))
		  fd.append('vendorList', JSON.stringify(vendorList, null, 2))
		  fd.append('totalVendor', totalVendor)

		  fd.append('brandList', JSON.stringify(brandList, null, 2))
		  fd.append('totalBrand', totalBrand)

		  fd.append('code', productCode)
		fd.append('productCategories', JSON.stringify(category, null, 2))
		fd.append('productTags', JSON.stringify(tag, null, 2))
		fd.append('productCustomerRole', JSON.stringify(customerRole, null, 2))
		fd.append('productLimitedStore', JSON.stringify(limitedStores, null, 2))
		fd.append('productManufacturer', content.manufacturers.value)

		  if(content.pageName===''){
			fd.append("pageNames", content.productName.replace(/,?[ ]/g, '-'))
		}else{
			fd.append("pageNames", content.pageName)
		}

		let url = API_URL+'/product/create';
		axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.productName){
			errors.productName =Tooltip.required;
			//myRef.current.focus()
		}
		return errors;
		}


		const DisplaySelect=()=>{	  
			let details = get_options();
			return ( <Select isMulti options={
				details&&details.map((node, id)=>{
					return {value:node.code, key:id, label:node.categoryName}
				})} 
                
	onChange={handleSelect}  name="categories" className={errors.categories ? 'form-control formerror' : ''} value={content.categories} styles={selectStyles} /> 
	)

}
	

 function get_options(parent="root", indent="") {
			var result= []
			category&&category.map(cat=>{
				if(cat.parentCategory===parent){
					result.push({code:cat.code, categoryName:indent+cat.categoryName})
					result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
				}
			})
			return result;
		  }
	

useEffect(()=>{
	fetchCategory()
	fetchTags()
	fetchGroup()
	fetchVendor()
	fetchDiscount()
	fetchManufacturer()
	},[]);
	
        return (<Template 
            menu="Catalog"
        submenu="Products">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">

<PageHeader title="Add a new Product" description='Create new product  here' icon='feather icon-book'>
	
<li className="breadcrumb-item"><a href="/products">Products</a> </li>

                    </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-sm btn-success" onClick={(e)=>handleSubmit(e, '/products')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/products/create')}
  className="btn btn-inverse btn-sm" ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/products/edit/'+productCode)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/products" className="btn btn-sm btn-warning "><i className="fa fa-arrow-left"></i> Return</a>
<div className="pull-right">
	{panel.basic?
	<button type="button" onClick={goAdvance} className="btn btn-sm  "> Basic Mode <i className="fa fa-check"></i></button> :
	<button type="button" onClick={goBasic} className="btn btn-primary btn-sm ">  Advanced Mode <i className="fa fa-check"></i></button>}
  </div>
			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, info:!panel.info})}>
			<h5><i className="fa fa-info"></i> Product Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.info?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.info?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Product Name" toolTip={Tooltip.productName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}  ref={myRef} name="productName" value={content.productName} className={errors.productName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.productName}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Short description" toolTip={Tooltip.shortDescription} />
					</div>
									
						<div className="col-sm-9 ">
							
					

						<QuillText value={content.shortDescription} onChange={(data)=>handleChangeDescription(data, 'shortDescription')} />

							</div>
					</div>

					

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Full Description" toolTip={Tooltip.fullDescription} />
					</div>
									
						<div className="col-sm-9 ">
						<QuillText value={content.fullDescription} onChange={(data)=>handleChangeDescription(data, 'fullDescription')} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Product Code" toolTip={Tooltip.sku} />
					</div>
									
					<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" autoComplete="off" onChange={handleChange}   name="sku" value={content.sku} className='form-control ' />
							
							<span className="input-group-addon" onClick={generateCode} id="basic-addon">Generate Code</span>
							</div>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Categories" toolTip={Tooltip.category} />
					</div>
									
						<div className="col-sm-9 ">
						<DisplaySelect /> 
							</div>
					</div>

				
<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Brand" toolTip={Tooltip.productManufacturer} />
					</div>
					<div className="col-sm-9">	
						<Select  options={
				manufacturer&&manufacturer.map((data, id)=>{
					return {value:data.code, key:id, label:data.brandName}
				})}  
                
onChange={handleSelect}  name="manufacturers"   value={content.manufacturers} styles={selectStyles} /> 

							</div>
					</div>

					{panel.infoDisplay?<>
				
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Published"  toolTip={Tooltip.published} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={content.isPublished} name="isPublished" checked={content.isPublished} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>


					<div className="form-group row">
				<div className="col-sm-3">
						<FormLabel name ="Vendor" toolTip={Tooltip.vendor} />
				</div>
								
					<div className="col-sm-9 ">
				<div className="input-group">
				<select  name="vendor" value={content.vendor} className="form-control" onChange={handleChange}>
				<option value="">no vendor</option>
				{vendor&&vendor.map((node, id)=>{
					return <option  key={id} value={node.code}>{node.vendorName}</option>
				})} 
				</select>
						
						</div>
						</div>
				</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Is Featured"  toolTip={'Featured products appears on featured page'} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={content.isFeatured} name="isFeatured" checked={content.isFeatured} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Rating" toolTip={'Select product rating'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="rating" value={content.rating} className="form-control" onChange={handleChange}>
							<option value="1">1</option>
                        	<option value="2">2</option>

                        	<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
                    </select>
							
							</div>
							</div>
			</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Products Tag" toolTip={Tooltip.tags} />
					</div>
									
						<div className="col-sm-9 ">
						<Select isMulti options={
				tags&&tags.map((data, id)=>{
					return {value:data.code, key:id, label:data.tagName}
				})}   
                
onChange={handleSelect}  name="tags"  value={content.tags} styles={selectStyles} /> 
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="GTIN" toolTip={Tooltip.gtin} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="gtin" value={content.gtin} className='form-control' />
							
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Youtube Url" toolTip={'Youtube Video of product'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="youtubeUrl" value={content.youtubeUrl} className='form-control' />
							
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Manufacturer Part Number" toolTip={Tooltip.partNumber} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="mPartNumber" value={content.mPartNumber} className='form-control' />
							
							</div>
							</div>
					</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Show on home page"  toolTip={Tooltip.showOnHome} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={content.showOnHome} name="showOnHome" checked={content.showOnHome} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

{content.showOnHome?
			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="text" min="0" className="form-control" name="displayOrder" value={content.displayOrder} onChange={handleChangeNumber} />

							</div>
			</div>:''}

			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Product Type" toolTip={Tooltip.productType} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="productType" value={content.productType} className="form-control" onChange={handleChange}>
                        <option value="simple">Simple</option>
                        <option value="grouped">Grouped</option>
                    </select>
							
							</div>
							</div>
			</div>
 
	{content.productType==='grouped'?	<div className="row" >
		<div className="col-sm-12 ">
		<div className="panel panel-default margin-bottom p-10 bg-info m-b-10" >
		<div className="panel-heading">
		Associated products (variants)
		</div>
		<div className="panel-body ">
		You need to save the product before you can add associated products for this product page.
		</div>
		</div></div>
		</div> :''}

				<div className="form-group row">
				 	<div className="col-sm-3">
							<FormLabel name ="Customer Roles" toolTip={Tooltip.customerRole} />
					</div>
									
						<div className="col-sm-9 ">
						<Select isMulti options={
				group&&group.map((node, id)=>{
					return {value:node.ID, key:id, label:node.groupName}
				})} 
                
onChange={handleSelect}  name="customerRoles"  value={content.customerRoles} styles={selectStyles} /> 
									
</div>


							</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Limited To Vendor" toolTip={Tooltip.limitedStore} />
					</div>
									
						<div className="col-sm-9 ">
						
				<Select isMulti options={
				vendor&&vendor.map((node, id)=>{
					return {value:node.code, key:id, label:node.vendorName}
				})} 
                
onChange={handleSelect}  name="limitedStore"  value={content.limitedStore} styles={selectStyles} /> 	
					</div></div> 



				<div className="form-group row">
				<div className="col-sm-3">
						<FormLabel name ="Allow customer reveiw" toolTip={Tooltip.customerReview}  />
				</div>
								
					<div className="col-sm-9 ">
					<div className="checkbox-fade fade-in-success">
					<label>
				<input type="checkbox" value={content.isCustomerReveiw} name="isCustomerReveiw" checked={content.isCustomerReveiw} onChange={handleChangeCheck} />
						<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
					</label>
				</div>
						</div>
				</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Available Start" toolTip={Tooltip.availableStart} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="datetime-local" onChange={handleChange}  name="availableStart" value={content.availableStart} className='form-control' />
							
							</div>
							</div>
					</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Available End" toolTip={Tooltip.availableEnd} />
			</div>
							
				<div className="col-sm-4 ">
					<div className="input-group">
				<input type="datetime-local" onChange={handleChange}  name="availableEnd" value={content.availableEnd} className='form-control' />
					
					</div>
					</div>
			</div>


			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Mark as New" toolTip={Tooltip.markNew}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isNew} name="isNew" checked={content.isNew} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

{content.isNew?<>
			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Mark new Start" toolTip={Tooltip.markNewStart} />
			</div>
							
				<div className="col-sm-4 ">
					<div className="input-group">
				<input type="datetime-local" onChange={handleChange}  name="isNewStart" value={content.isNewStart} className='form-control' />
					
					</div>
					</div>
			</div>


		<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Mark New End" toolTip={Tooltip.markNewEnd} />
		</div>
						
			<div className="col-sm-4 ">
				<div className="input-group">
			<input type="datetime-local" onChange={handleChange}  name="isNewEnd" value={content.isNewEnd} className='form-control' />
				
				</div>
				</div>
		</div>
		</>:''}


		<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Admin Comment" toolTip={Tooltip.adminComment} />
		</div>
						
			<div className="col-sm-9 ">
				
			<textarea type="text" className="form-control" rows="5" name="adminComment" value={content.adminComment} onChange={handleChange}>{content.adminComment}
			</textarea>

				</div>
		</div>
					
					</>:''}
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>




{/* Price panel start here  */}
							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, price:!panel.price})}>
			<h5><i className="fa fa-money"></i> Price</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.price?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.price?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           

						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Price" toolTip={Tooltip.price} />
					</div>
					
					<div className="col-sm-9 ">	
					<div className="row">	
						<div className="col-sm-5 ">
						<input type="text" className="form-control" min="0" name="price" 
						value={content.price} onChange={handleChangeNumber} />
						</div>
						<div className="col-sm-5 "><b>{content.price!==0?Currency+FormatNumber(content.price):''}</b></div>
							
					</div>
					</div>	
					
					</div>
					{panel.displayPrice?<>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Old Price" toolTip={Tooltip.oldPrice} />
					</div>
									
					<div className="col-sm-9 ">	
					<div className="row">	
						<div className="col-sm-5 ">
						<input type="text" className="form-control" min="0" name="oldPrice" 
						value={content.oldPrice} onChange={handleChangeNumber} />
						</div>
						<div className="col-sm-5 "><b>{content.oldPrice!==0?Currency+FormatNumber(content.oldPrice):''}</b></div>
							
					</div>
					</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Product Cost" toolTip={Tooltip.costPrice} />
					</div>
									
					<div className="col-sm-9 ">	
					<div className="row">	
						<div className="col-sm-5 ">
						<input type="text" className="form-control" min="0" name="costPrice" 
						value={content.costPrice} onChange={handleChangeNumber} />
						</div>
						<div className="col-sm-5 "><b>{content.costPrice!==0?Currency+FormatNumber(content.costPrice):''}</b></div>
							
					</div>
					</div>
					</div>


					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Disable Add To Cart" toolTip={Tooltip.buyButon}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isOutOfStock} name="isOutOfStock" checked={content.isOutOfStock} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Disable wishlist" toolTip={Tooltip.wishList}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isWishlistDisabled} name="isWishlistDisabled" checked={content.isWishlistDisabled} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Available for pre-order" toolTip={Tooltip.preorder}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isPreorder} name="isPreorder" checked={content.isPreorder} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
			{content.isPreorder?
	<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Pre-order  startdate" toolTip={Tooltip.preorderStart} />
					</div>
									
					<div className="col-sm-4 ">
					<div className="input-group">
				<input type="datetime-local" onChange={handleChange}  name="preorderStart" value={content.preorderStart} className='form-control' />
					
					</div>
					</div>
		</div>:''}

		<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Call for Price" toolTip={Tooltip.callforPrice}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isCallforPrice} name="isCallforPrice" checked={content.isCallforPrice} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Customer enters price" toolTip={Tooltip.customerPrice}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isCustomerPrice} name="isCustomerPrice" checked={content.isCustomerPrice} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			{content.isCustomerPrice?<>

			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Minimum Amount" toolTip={Tooltip.minAmount} />
					</div>
									
					<div className="col-sm-9 ">	
					<div className="row">	
						<div className="col-sm-5 ">
						<input type="text" className="form-control" min="0" name="minAmount" 
						value={content.minAmount} onChange={handleChangeNumber} />
						</div>
						<div className="col-sm-5 "><b>{content.minAmount!==0?Currency+FormatNumber(content.minAmount):''}</b></div>
							
					</div>
					</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Maximum Amount" toolTip={Tooltip.maxAmount} />
					</div>
									
					<div className="col-sm-9 ">	
					<div className="row">	
						<div className="col-sm-5 ">
						<input type="text" className="form-control" min="0" name="maxAmount" 
						value={content.maxAmount} onChange={handleChangeNumber} />
						</div>
						<div className="col-sm-5 "><b>{content.maxAmount!==0?Currency+FormatNumber(content.maxAmount):''}</b></div>
							
					</div>
					</div>
					</div></>:''}





					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Discount" toolTip={Tooltip.discount} />
					</div>
									
						<div className="col-sm-9 ">
						<select  name="discount" value={content.discount} className="form-control" onChange={handleChange}>
						<option value="none">[none]</option>
                        {discount&&discount.map((node, id)=>{
					return <option  key={id} value={node.code}>{node.discountName}</option>
				})} 
                    </select>

							</div>
					</div>
					</>:''}

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Tax exempt"  toolTip={Tooltip.isTaxExempt} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={content.isTaxExempt} name="isTaxExempt" checked={content.isTaxExempt} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
					
					

	</section></div>
	
	


	</div></div>
				</div>:''}
	</div>
							</div>
	                   
                            </div>



{/* printing panel start here */}
			{/* 
<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, printing:!panel.printing})}>
			<h5><i className="fa fa-print"></i> Printing Product</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.printing?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.printing?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Is Product Printing"  toolTip={Tooltip.isPrinting} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={content.isPrinting} name="isPrinting" checked={content.isPrinting} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
				
			{content.isPrinting?<>


			
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Graphic Design Fee" toolTip={'Design fee will be added only when customer want your expert to design it '} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="designFee" 
						value={content.designFee} onChange={handleChange} />

							</div>

						<div className="col-sm-5 "><b>{content.designFee!==0?Currency+FormatNumber(content.designFee):''}</b></div>
					</div>
					

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Note" toolTip={'Design Note'} />
					</div>
									
						<div className="col-sm-8 ">
						<textarea type="text" className="form-control" rows="5" name="designNote" value={content.designNote} onChange={handleChange}>{content.designNote}
			</textarea>

							</div>
					</div>


					</>:''}	

	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>
 */}


{/* shipping panel start here */}
			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, shipping:!panel.shipping})}>
			<h5><i className="fa fa-bus"></i> Shipping</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.shipping?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.shipping?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Shipping enabled"  toolTip={Tooltip.isShipped} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={content.isShippable} name="isShippable" checked={content.isShippable} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
				
			{content.isShippable?<>


			
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Weight" toolTip={Tooltip.weight} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="text" className="form-control" min="0" name="weight" 
						value={content.shippings.map((data)=>data.weight)} onChange={handleChangeShipping} />

							</div>
					</div>
					

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Length" toolTip={Tooltip.length} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="text" className="form-control" min="0" name="length" 
						
						value={content.shippings.map((data)=>data.length)} onChange={handleChangeShipping} />

							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Width" toolTip={Tooltip.width} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="text" className="form-control" min="0" name="width" 
						
						value={content.shippings.map((data)=>data.width)} onChange={handleChangeShipping} />

							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Height" toolTip={Tooltip.height} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="text" className="form-control" min="0" name="height" value={content.shippings.map((data)=>data.height)} onChange={handleChangeShipping} />

							</div>
					</div>

					{panel.displayShipping?<>
				

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Ship separately" toolTip={Tooltip.shipSeperate}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox"  name="isShippedSeperate" checked={String(content.shippings.map((data)=>data.isShippedSeperate))==='false'?false:true} value={String(content.shippings.map((data)=>data.isShippedSeperate))} onChange={handleChangeShipping} />

					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Free Shipping" toolTip={Tooltip.isFreeShipping}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox"  name="isFreeShipping" checked={content.isFreeShipping} value={content.isFreeShipping} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
{content.isFreeShipping?'':
			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Shipping charge" toolTip={Tooltip.shippingCharge} />
					</div>
									
					<div className="col-sm-9 ">	
					<div className="row">	
						<div className="col-sm-5 ">
						<input type="number" className="form-control" min="0" name="shippingCharge" 
						value={content.shippings.map((data)=>data.shippingCharge)} onChange={handleChangeShipping} />
						</div>
						<div className="col-sm-5 "><b>{content.shippings.map((data)=>data.shippingCharge)!==0?Currency+FormatNumber(content.shippings.map((data)=>data.shippingCharge)):''}</b></div>
							
					</div>
					</div>
			</div>}

			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Delivery Period" toolTip={Tooltip.deliveryDate} />
					</div>
									
						<div className="col-sm-9 ">
					<select  name="deliveryPeriod" className="form-control" 
						value={String(content.shippings.map((data)=>data.deliveryPeriod))} onChange={handleChangeShipping}>
						<option  value="0">None</option>
						<option value="1">1-2 days</option>
						<option value="2">3-5 days</option>
						<option value="3">1 week</option>
                    </select>

							</div>
					</div></>:''}	
					</>:''}	

	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>








			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, inventory:!panel.inventory})}>
			<h5><i className="fa fa-sitemap"></i> Inventory</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.inventory?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.inventory?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Inventory method"  toolTip={Tooltip.inventoryMethod} />
			</div>
							
				<div className="col-sm-9 ">
					
				<select  name="inventoryMethod" value={content.inventoryMethod} className="form-control" onChange={handleChange}>
				<option  value="0">Don't track inventory</option>
				<option value="1">Track inventory</option>
                    </select>
					</div>
			</div>
			{content.inventoryMethod ==='1'? 
			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Stock Quantity" toolTip={Tooltip.stockQuantity} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="quantity"  value={content.quantity} onChange={handleChange} />

							</div>
					</div>:''}

					{panel.displayInventory && content.inventoryMethod !=='0'?<>
			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Wharehouse"  toolTip={Tooltip.wharehouse} />
			</div>
							
				<div className="col-sm-9 ">
					
				<select  name="wharehouse" className="form-control" value={String(content.inventory.map((data)=>data.wharehouse))} onChange={handleChangeInventory}>
						<option value="default">Default Wharehouse</option>
                    </select>
					</div>
			</div>
			
			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Multiple warehouses" toolTip={Tooltip.multipleWharehouse}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox"  name="isMultipleWharehouse" checked={String(content.inventory.map((data)=>data.isMultipleWharehouse))==='false'?false:true} value={String(content.inventory.map((data)=>data.isMultipleWharehouse))} onChange={handleChangeInventory} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Display availability" toolTip={Tooltip.displayAvailability}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" name="isDisplayAvailability" checked={String(content.inventory.map((data)=>data.isDisplayAvailability))==='false'?false:true} value={String(content.inventory.map((data)=>data.isDisplayAvailability))} onChange={handleChangeInventory}  />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Display stock quantity" toolTip={Tooltip.displayStockQuantity}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" name="isDisplayStockQuantity" checked={String(content.inventory.map((data)=>data.isDisplayStockQuantity))==='false'?false:true} value={String(content.inventory.map((data)=>data.isDisplayStockQuantity))} onChange={handleChangeInventory} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>




			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Minimum stock qty" toolTip={Tooltip.minimumQuantity} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="minQuantity"  value={content.inventory.map((data)=>data.minQuantity)} onChange={handleChangeInventory} />

							</div>
					</div>
					
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Low stock activity"  toolTip={Tooltip.lowStoreActivity} />
			</div>
							
				<div className="col-sm-9 ">
					
				<select  name="lowStockActivity" value={String(content.inventory.map((data)=>data.lowStockActivity))} onChange={handleChangeInventory} className="form-control" >
				<option  value="0">Nothing</option>
				<option value="1">Disable buy button</option>
				<option value="2">Unpublish</option>
                    </select>
					</div>
			</div>


			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Notify for qty below" toolTip={Tooltip.notifyAdmin} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="notifyAdminNum"  value={content.inventory.map((data)=>data.notifyAdminNum)} onChange={handleChangeInventory} />

							</div>
					</div>

					
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Allow back in stock subscriptions" toolTip={Tooltip.backInStock}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox"  name="isBackInStock" checked={String(content.inventory.map((data)=>data.isBackInStock))==='false'?false:true} value={String(content.inventory.map((data)=>data.isBackInStock))} onChange={handleChangeInventory}  />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Product availability range"  toolTip={Tooltip.availableRange} />
			</div>
							
				<div className="col-sm-9 ">
					
				<select  name="availableRange" className="form-control" value={String(content.inventory.map((data)=>data.availableRange))} onChange={handleChangeInventory}>
				<option  value="0">None</option>
				<option value="1">2-4 days</option>
				<option value="2">7-10 days</option>
				<option value="3">2 week</option>
                    </select>
					</div>
			</div>

			</>:''}


			
			{panel.displayInventory?<>
			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Minimum cart qty	" toolTip={Tooltip.minCart} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="minCart"  value={content.inventory.map((data)=>data.minCart)} onChange={handleChangeInventory} />

							</div>
			</div>

			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Maximum cart qty	" toolTip={Tooltip.maxCart} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" className="form-control" min="0" name="maxCart"  value={content.inventory.map((data)=>data.maxCart)} onChange={handleChangeInventory} />

							</div>
					</div>
			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Allowed quantities" toolTip={Tooltip.allowedQty} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" min="0" name="allowedQty"  value={content.inventory.map((data)=>data.allowedQty)} onChange={handleChangeInventory} />

							</div>
			</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Not returnable" toolTip={Tooltip.isReturnable}  />
			</div>
							
				<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox"  name="isReturnable" checked={String(content.inventory.map((data)=>data.isReturnable))==='false'?false:true} value={String(content.inventory.map((data)=>data.isReturnable))} onChange={handleChangeInventory} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div></>:''}


	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>






							{panel.displayPicture?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, picture:!panel.picture})}>
			<h5><i className="fa fa-image"></i> Pictures</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.picture?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.picture?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					You need to save the product before you can upload pictures for this product page.
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}



							{panel.displayProductAttribute?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, productAttribute:!panel.productAttribute})}>
			<h5><i className="fa fa-paperclip"></i> Products Attributes</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.productAttribute?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.productAttribute?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					Product attributes are quantifiable or descriptive aspects of a product (such as, color). For example, if you were to create an attribute for color, with the values of blue, green, yellow, and so on, you may want to apply this attribute to shirts, which you sell in various colors (you can adjust a price or weight for any of existing attribute values). You can add attribute for your product using existing list of attributes, or if you need to create a new one go to Catalog {'>'} Attributes {'>'} Product attributes. Please notice that if you want to manage inventory by product attributes (e.g. 5 green shirts and 3 blue ones), then ensure that "Inventory method" is set to "Track inventory by product attributes".
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}




							{panel.displaySpecAttribute?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, specAttribute:!panel.specAttribute})}>
			<h5><i className="fa fa-cog"></i> Specification Attributes</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.specAttribute?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.specAttribute?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					Specification attributes are product features i.e, screen size, number of USB-ports, visible on product details page. Specification attributes can be used for filtering products on the category details page. Unlike product attributes, specification attributes are used for information purposes only. You can add attribute for your product using existing list of attributes, or if you need to create a new one go to Catalog {'>'} Attributes {'>'} Specification attributes.
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}


	{panel.seoDisplay?			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, seo:!panel.seo})}>
			<h5><i className="fa fa-search-plus"></i> SEO</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.seo?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.seo?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Name" toolTip={Tooltip.seoName} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="text" className="form-control" name="pageName" 
						value={content.pageName} onChange={handleChange} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Title" toolTip={Tooltip.metaTitle} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaTitle" 
						value={content.metaTitle} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Keyword" toolTip={Tooltip.metaKeywords} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaKeywords" 
						value={content.metaKeywords} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Description" toolTip={Tooltip.metaDescription} />
					</div>
									
						<div className="col-sm-9 ">
						
						<textarea type="text" className="form-control" rows="5" name="metaDescription" 
						value={content.metaDescription} onChange={handleChange}>{content.metaDescription}</textarea>
					</div></div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}



						{panel.displayRelated?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, related:!panel.related})}>
			<h5><i className="fa fa-object-group"></i> Related Products</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.related?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.related?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					The Related Products option provides the opportunity to advertise products that are not part of the selected category, to your visitors. These products are displayed on the product details pages.
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}






							{panel.displayCrossSell?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, crossSell:!panel.crossSell})}>
			<h5><i className="fa fa-random"></i> Cross Sell</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.crossSell?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.crossSell?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					The Cross-sell products option provides the opportunity to buy additional products that generally go with the selected product. They are displayed at the bottom of the checkout page.
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}





                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 