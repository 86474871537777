import Cookies from 'js-cookie'
import { APP_KEY } from './include'
var CryptoJS = require('crypto-js')



const mainToken =()=>{
  var token = Cookies.get('msptk')?Cookies.get('msptk'):'null'
 return token
 }

export const authenticate = () => {
    if (Cookies.get('msptk') && Cookies.get('stfmscd')){ 
        try {
          const main =  mainToken() 
        if(main!=='null'){
          return true
        }else{
          window.location.href='/logout'
        return false
        }
       
      } catch (error) {
        window.location.href='/logout'
        return false
      }
      }else{  
    window.location.href='/logout'
    return false
  } 
    }


   export const encrypt=data=>CryptoJS.AES.encrypt(data, APP_KEY).toString();
 
  export const decrypt=data=>{
  var byte =  CryptoJS.AES.decrypt(data, APP_KEY)
  return byte.toString(CryptoJS.enc.Utf8)
  } 