import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts, WarningModal} from '../components/notify'
import {CustomToggleList} from '../components/dataTable'
import {ServerUrl, FormatNumber, Token, config, FrontEndUrl, PictureUrl, API_URL, IMAGES_URL} from '../components/include'
import axios from 'axios'
import { Currency} from '../components/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

import Select from 'react-select'

 const Wishlist =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
    const [search, setSearch]= useState({
        fromDate:'',
        toDate:'',
        customerCode:'',
        sku:''
    })
   
    const [content, setContent] = useState([]);
	const [customer, setCustomer] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
   
    const SearchTable =(event)=>{
       event.preventDefault();

       let wish = [...content]
        if(search.customerCode.length!==0){
            let rs = wish.filter(item=>item.customerCode===search.customerCode.value)
            setContent(rs)
        }
    }

  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}

const handleSelect = (option, action)=>{
    setSearch({...search, [action.name]:option})
  } 
    





const fetchContent =()=>{

    setNotice({...notice, 
        isDataFetching: true });

      let url = API_URL+'/order/wishlist';
      axios.get(url, config).then(result=>{
          if(Array.isArray(result.data.data) && result.data.data.length!==0){ 
              try{
          setContent(result.data.data)
      }catch(e){
                  
      }
          }
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
      }).finally(()=>{
        setNotice({...notice, 
            isDataFetching: false });
      })
}



    const tableHeader = [
        

{dataField: '',  text: '#', editable: false, footer:'#', formatter:(cell, row, index)=>index+1},
{dataField: 'coverUrl', text: 'Picture', footer:'Picture',  editable: false, formatter:(cell, row)=><img id={row.id} className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+"/noImage.png"}} src={`${IMAGES_URL}/products/${row.coverUrl}`} title={row.productCode}  alt={row.productCode} />},


{dataField: 'productName', text: 'Product Name', footer:'Product Name', editable: false, sort: true, formatter:(cell, row)=><div><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}><a href={`/products/edit/${row.productCode}`} target="_blank" rel="noopener noreferrer">{row.productName}</a></span><b>Code:</b> {row.sku}</div>},


{dataField: 'customer_code',  text: 'Customer', footer:'Customer', sort: true, editable: false, formatter:(cell, row)=><span style={{display:'block', width:150, whiteSpace:'normal', wordWrap:'break-word' }}>{row.first_name+' '+row.last_name+' '+row.email_address}</span>},


{dataField: 'created_at',  text: 'Date Added', footer:'Date Added',  sort: true, editable: false},

{dataField: 'code', text: 'Action', footer:'Action', formatter: (cell, row)=><button type='button'  className="btn btn-danger btn-sm" ><i className="feather icon-trash"></i> Delete</button>, editable: false},
       
     ];
    
     const TableRecord=(props)=>{
         const { SearchBar } = Search;  
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '70', value: 70}, {text: '150', value: 150}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                            
                     <BootstrapTable 
                        { ...props.baseProps }
                        striped
                    hover
                    condensed
                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                pagination={paginationFactory(options) }
              
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
     

     

     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
            menu="Sales"
            submenu="Wishlist">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="Wishlist" description='Listing All customer wishlist' icon='feather icon-shopping-cart'>
                        </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">   

            <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="From Date" toolTip={Tooltip.fromDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="fromDate" value={search.fromDate} />
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="To Date" toolTip={Tooltip.toDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="toDate" value={search.toDate} />
                        </div>
                    </div>
                  
             
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">

                <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <FormLabel name ="Product Code" toolTip={'Search by unique identifier'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="sku" value={search.sku} />
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Customer" toolTip={'Customer Name'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                       <Select  options={
				customer&&customer.map((data, id)=>{
					return {value:data.code, key:id, label:data.first_name, last_name:data.last_name}
				})}  
                
onChange={handleSelect}  name="customerCode"  value={search.customerCode} style={selectStyles}  /> 
                        </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Wishlist</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Wishlist) 