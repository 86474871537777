import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {CustomToggleList} from '../../components/dataTable'
import {ServerUrl, config, Token, API_URL} from '../../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle, CSVExport } from 'react-bootstrap-table2-toolkit';
import { sort } from '../../components/globalFunction'

 const Category =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    
    const [search, setSearch]= useState({
        categoryName:'',
        published:'all'
    })
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);

    const SearchTable =(event)=>{
       event.preventDefault();
       fetchContent();
    }




    const handleDeleteWithImage =()=>{
          

        let data = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status==='true'){
                data.push({code:newContent[i].code, image:newContent[i].imageUrl})
               
            }
        }
        if(data.length===0){
            Alerts('Error!', 'danger', Tooltip.deleteWarning)
              
        }else{
            let close =   document.getElementById('btnWarningDialog-delAll')
            close.click();
            setNotice({...notice, 
                isLoading: true}) 

            const fd = new FormData();
            fd.append('data', JSON.stringify(data, null, 2))
              
        let url = API_URL+'/category/delete_multiple';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  
                      } else{
                  Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
                  fetchContent()
              }) 
            }
    }







    const fetchContent =()=>{
        setNotice({...notice,  isDataFetching: true  });
       
        let url = API_URL+'/category/search';
        const fd = new FormData();
        fd.append("categoryName", search.categoryName);
        fd.append("published", search.published);
        axios.post(url, fd, config)
        .then(result =>{ 
            if(Array.isArray(result.data.data)){
                setContent(result.data.data)
            }else{
                setContent([])
            }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }


  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
   // setErrors({...errors, [name]:''});
}

  const handleCheckOne =(e)=>{  

    let {name, value} = e.target

    const currentContent = content.map((item)=>{
                   
        if(item.code ===name){
            return {...item, status:item.status==='false'?'true':'false'}
        }

         return item
          })

  setStatus({...status, checkAll:false})
   setContent(currentContent) 
        
       }
    

  const handleCheckAll =()=>{
    var newContent = JSON.parse(JSON.stringify(content));
    for (var i in newContent){
        newContent[i].status=String(!status.checkAll)
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent)
 }

 const DisplaySelect=({code})=>{	  
    let options = get_options();
   return (
       <div>
        {options.map((data) =>data.code===code?data.categoryName:'') }
        </div> 
   )
        
}

  function get_options(parent="root", indent="") {
    var result= []
    content&&content.map(cat=>{
        if(cat.parentCategory===parent){
            result.push({code:cat.code, categoryName:indent+cat.categoryName})
            result = result.concat(get_options(cat.code, indent+cat.categoryName+" =>\t"))
           
        } 
        return ''
    })
    return result;
  }
   
  const GeneralCheck =()=><div className="checkbox-fade fade-in-success">
  <label>
<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
      <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
  </label>
</div> 

    const tableHeader = [
        {dataField: 'code',  text: <GeneralCheck />, editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.code}  checked={row.status==='true'?true:false} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},
        {dataField: 'categoryName', text: 'Name', formatter:(cell, row)=><DisplaySelect code={row.code} />},
        {dataField: 'published', text: 'Published', sort: true, editor: {
            type: Type.SELECT, options:[{value:'true', label:'Published'},
            {value:'false', label:'Unpublished'}]
          }, formatter:(cell)=>cell==='true'? <div className="text-center"><i title="Double click to change" className="fa fa-check  badge-inverse-success"></i></div>: <div className="text-center"><i title="Double click to change" className="fa fa-times  badge-inverse-danger"></i></div>}, 

        {dataField: 'displayOrder',  text: 'Display Order',  sort: true, validator: (newValue, row, column) => {
            if (!Number(newValue)) {
              return {
                valid: false,
                message: 'This field required valid number'
              };
            }
            return true;
        }, formatter:(cell)=><div className="text-center">{cell}</div>},
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/category/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a>, editable: false},
       
     ];
    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, code)=>{       
         const fd = new FormData(); 
             fd.append('newValue', newValue);
             fd.append('column', column.dataField);
             fd.append('code', code);
            let url = API_URL+'/category/change_field'
           axios.post(url, fd, config)
           .then(result => fetchContent())
           
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '20', value: 20}, 
          {text: '50', value: 50}, 
          {text: '70', value: 70}, 
          {text: '100', value: 100}, 
          {text: '150', value: 150},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                               
  <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'220px', width:'200px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
      <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>
                                 
                                 
                                 <div className="dt-buttons btn-group pull-right"> 
    <a href="/category/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</a>
                                

<div className="btn-group dropdown-split-primary">
    <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <i className="fa fa-download"></i>Export
    </button>
    <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>


    <form method='POST' action={ServerUrl+'/exportCsv_controller.php?tablename=tbl_category'} target='_blank'>
                <input type='hidden' name='jwt' value ={Token} />
                <button type="submit" className="dropdown-item waves-effect waves-light " >  Export to Excel</button>
        </form>
        <form method='POST' action={ServerUrl+'/printout/category.php?token='+Token} target='_blank'>
                <input type='hidden' name='jwt' value ={Token} />
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
        
        </form>
    </div>
</div>         
        <button className="btn btn-outline-danger" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button>
 </div>                   

      
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.code);
                               
                               return props.fetchContent;
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      





     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
            menu="Catalog"
            submenu="Category">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="All Categories" description='Listing all product categories' icon='feather icon-book'>
	
    
                        </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal  handleAction={handleDeleteWithImage} mID={'delAll'} /> 

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Category Name" toolTip={Tooltip.categoryName} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  ref={myRef} name="categoryName" value={search.categoryName} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Published" toolTip={Tooltip.IsPublished} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="published" value={search.published} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="true">Published Only</option>
                        <option value="false">Unpublished Only</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Category list</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content.sort(sort)}  columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Category) 