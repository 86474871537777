import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {CustomToggleList} from '../../components/dataTable'
import {ServerUrl,  IMAGES_URL, API_URL, FormatNumber, config, Token} from '../../components/include'
import axios from 'axios'
import { Currency} from '../../components/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

 const Products =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    const [category, setCategory] = useState([])
    const [search, setSearch]= useState({
        productName:'',
        published:'all',
        rating:'all',
        category:'all',
        sku:'',
        vendor:'all'

    })
     
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);

	const [vendor, setVendor] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const SearchTable =(event)=>{
       event.preventDefault();
       fetchContent();
    }


    const fetchCategory =()=>{
       let url = API_URL+'/category/display/all';
        axios.get(url, config).then(result=>{ 

			if(Array.isArray(result.data.data)){
            setCategory(result.data.data)
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
  }
  const fetchVendor =()=>{
	let url = API_URL+'/vendor/display/all';
        axios.get(url, config).then(result=>{ 

			if(Array.isArray(result.data.data)){
		setVendor(result.data.data)
            }
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}
  
const handleExportSelectedPdf =()=>{
      
let data = []
var newContent = JSON.parse(JSON.stringify(content));
for (var i in newContent){
    if(newContent[i].status===true){
        data.push(newContent[i].ID)
    }
}
if(data.length===0){
    Alerts('Error!', 'danger', 'Please select product to export') 
}else{

let url = ServerUrl+'/exportPDF_controller.php?tablename=tbl_selectedProducts&data='+String(data);
window.open(url, '_self') 
    }
}


const handleExportSelected =()=>{
          
let data = []
var newContent = JSON.parse(JSON.stringify(content));
for (var i in newContent){
    if(newContent[i].status===true){
        data.push(newContent[i].ID)
    }
}
if(data.length===0){
    Alerts('Error!', 'danger', 'Please select product to export') 
}else{

let url = ServerUrl+'/exportCsv_controller.php?tablename=tbl_selectedProducts&data='+String(data);
window.open(url, '_self') 
    }
}



const handleDeleteSelected =()=>{
          

    let data = []
    var newContent = JSON.parse(JSON.stringify(content));
    for (var i in newContent){
        if(newContent[i].status==='true'){
            data.push({code:newContent[i].code})
           
        }
    }
    if(data.length===0){
        Alerts('Error!', 'danger', Tooltip.deleteWarning)
          
    }else{
        let close =   document.getElementById('btnWarningDialog-delAll')
        close.click();
        setNotice({...notice, 
            isLoading: true}) 

        const fd = new FormData();
        fd.append('data', JSON.stringify(data, null, 2))
          
    let url = API_URL+'/product/delete_multiple';
          axios.post(url, fd, config)
          .then(response =>{
           if(response.data.type ==='success'){
              Alerts('Saved!', 'success', response.data.message)
              
                  } else{
              Alerts('Error!', 'danger', response.data)
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, 
                  isLoading: false
              })
              fetchContent()
          }) 
        }
}


    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
           
        let url = API_URL+'/product/search';
        const fd = new FormData();
        fd.append("productName", search.productName);
        fd.append("category", search.category);
        fd.append("vendor", search.vendor);
        fd.append("sku", search.sku);
        fd.append("rating", search.rating);
        fd.append("published", search.published);
        axios.post(url, fd, config)
        .then(result =>{ 
            if(Array.isArray(result.data.data)){
            setContent(result.data.data)
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }


  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
   // setErrors({...errors, [name]:''});
}

  const handleCheckOne =(e)=>{  

    let {name, value} = e.target

    const currentContent = content.map((item)=>{
                   
        if(item.code ===name){
            return {...item, status:item.status==='false'?'true':'false'}
        }

         return item
          })

  setStatus({...status, checkAll:false})
   setContent(currentContent) 

   
        
       }
    




    const tableHeader = [
        
        {dataField: 'code',  text: '#', editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.code}  checked={row.status==='true'?true:false} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},
    {dataField: 'coverUrl', text: 'Image', editable: false, formatter:(cell, row)=><img id={cell} className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+"/noImage.png"}} src={`${IMAGES_URL}/products/${cell}`} title={row.productName}  alt={row.coverUrl} />},
        {dataField: 'productName', text: 'Product Name',  sort: true, validator: (newValue) => {
            if (!newValue) {
              return {
                valid: false,
                message: Tooltip.required
              };
            }
            return true;
        }, formatter:(cell)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},

        {dataField: 'sku', text: 'Product Code', sort: true, validator: (newValue) => {
            if (!newValue) {
              return {
                valid: false,
                message: Tooltip.required
              };
            }
            return true;
        },formatter:(cell)=><span style={{display:'block', width:100, overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}>{cell}</span>},
        {dataField: 'price', text: 'Price ('+Currency+')', sort: true, validator: (newValue) => {
            if (!Number(newValue)) {
              return {
                valid: false,
                message: 'This field required valid Number'
              };
            }
            return true;
        }, formatter:(cell)=>FormatNumber(cell)},
        {dataField: 'isPublished', text: 'Published', sort: true, editor: {
            type: Type.SELECT, options:[{value:'true', label:'Published'},
            {value:'false', label:'Unpublished'}]
          }, formatter:(cell)=>cell==='true'? <div className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>}, 

        {dataField: 'displayOrder',  text: 'Order',  sort: true, validator: (newValue, row, column) => {
            if (!Number(newValue)) {
              return {
                valid: false,
                message: 'This field required valid number'
              };
            }
            return true;
        }, formatter:(cell)=><div className="text-center">{cell}</div>},
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/products/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a>, editable: false},
       
     ];
    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, code)=>{       
         const fd = new FormData(); 
         fd.append('newValue', newValue);
         fd.append('column', column.dataField);
         fd.append('code', code);
        let url = API_URL+'/product/change_field'
       axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '70', value: 70}, {text: '150', value: 150}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='id' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                               <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />

                                 
                               
  <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'220px', width:'200px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
      <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>
                                 
                                 
                 <div className="dt-buttons btn-group pull-right"> 
                     <a href="/products/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</a>
                                

<div className="btn-group dropdown-split-primary">
    <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         <i className="fa fa-download"></i>Export
    </button>


    <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        
    <form method='POST' action={ServerUrl+'/exportCsv_controller.php?tablename=tbl_products&recordType=allfound'} target='_blank'>
                <input type='hidden' name='jwt' value ={Token} />
                <button type="submit" className="dropdown-item waves-effect waves-light " > Export to Excel (all found)</button>
        </form>
       

        <form method='POST' action={ServerUrl+'/exportPDF_controller.php?tablename=tbl_products'} target='_blank'>
                <input type='hidden' name='jwt' value ={Token} />
        <button type="submit" className="dropdown-item waves-effect waves-light" > Download as PDF (all found)</button>
        
        </form>
        
        <a href="#!" onClick={handleExportSelected} className="dropdown-item waves-effect waves-light" >Export to Excel (only selected)</a>
        <a className="dropdown-item waves-effect waves-light" onClick={handleExportSelectedPdf} href="#!">Download as PDF (only selected)</a>



    </div>
</div>         
                   
                   <button className="btn btn-outline-danger" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button>
 </div>                   

      
                                 </div>
                                 
                     <BootstrapTable 
                        { ...props.baseProps }
                        striped
                    hover
                    condensed
                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                pagination={paginationFactory(options) }
                cellEdit={ cellEditFactory({
                  mode: 'dbclick',
                  blurToSave: true,
                  autoSelectText: true,
        beforeSaveCell: (oldValue, newValue, row, column) => {
          if(oldValue !==newValue){
          handleUpdate(column, newValue, row.code);
          
         return props.fetchContent;
        }
        
      }
                }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
      const DisplaySelect=()=>{	  
        let details = get_options();
        return ( 
            <select  name="category" value={search.category} className="form-control" onChange={handleChange}>
            <option value="all">All</option>
            {details&&details.map((data, id)=><option key={id} value={data.code}>{data.categoryName}</option>)}
        </select> 
)

}


function get_options(parent="root", indent="") {
        var result= []
        category&&category.map(cat=>{
            if(cat.parentCategory===parent){
                result.push({code:cat.code, categoryName:indent+cat.categoryName})
                result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
            }
            return ''
        })
        return result;
      }

     useEffect(()=>{
        fetchContent()
        fetchCategory()
        fetchVendor()
       },[]);

        return (<Template 
            menu="Catalog"
            submenu="Products">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="All Products" description='Listing all products' icon='feather icon-book'>
	
    
                        </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal  handleAction={handleDeleteSelected} mID={'delAll'} /> 

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Product Name" toolTip={Tooltip.productName} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  ref={myRef} name="productName" value={search.productName} />
                        </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Categories" toolTip={Tooltip.category} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <DisplaySelect /> 
                        </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Rating" toolTip={Tooltip.manufacturerName} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">

                    <select  name="rating" value={content.rating} className="form-control" onChange={handleChange}>
                    <option value="all">All</option>
                            <option value="0">0</option>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
                    </select>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Product Code" toolTip={Tooltip.sku} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="sku" value={search.sku} />
                        </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Vendor" toolTip={"Search by vendor property"} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">

                    <select  name="vendor" value={search.vendor} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        {vendor&&vendor.map((node, id)=>{
					return <option  key={id} value={node.code}>{node.vendorName}</option>
				})} 
                    </select>
                        </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Published" toolTip={Tooltip.published} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select name="published" value={search.published} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="true">Published Only</option>
                        <option value="false">Unpublished Only</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Product list</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content} fetchdata={fetchContent} columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Products) 