import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect } from '../components/loader'
import {Alerts} from '../components/notify'
import {ServerUrl,  PictureUrl, Token, config} from '../components/include'
import axios from 'axios'
import { useParams, useNavigate} from 'react-router-dom'
import { longDate } from '../components/globalFunction'

 const Profile =()=> {
    let params = useParams()

   const navigate = useNavigate();
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    const [content, setContent] = useState([]);
    
	
    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select g.groupName, u.fullname, u.gender, u.email, u.lastUsed, u.telephone, u.imageUrl, u.isActive, u.dateCreate from tbl_account_group g,  tbl_users u where g.ID = u.groupId and  u.code ='"+params.code+"'" ;
          
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append("jwt", Token);
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
  
     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
			menu="People"
		submenu="Users">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Users" description='Listing all user with Access' icon='feather icon-user'>
  
					</PageHeader>


{/* The Page Body start here */}
<div className="page-body">


<div className="col-md-12">
                <div className="card z-depth-0">
        
        <div className="card-block">


    <div className="card z-depth-0">
        
                                    
                                    
    {content&&content.map((item, idx)=>
        <div key={idx} className="card">
        <div className="row">
        <div className="col-lg-12">
            <div className="cover-profile">
                <div className="profile-bg-img">
            <img className="profile-bg-img img-fluid" src={`${PictureUrl}/bg.jpg`} style={{display:'block', height:'200px',width:'1000px'}} alt="bg-img"/>
                    <div className="card-block user-info">
                        <div className="col-md-12">
                            <div className="media-left">
                                <a href="#!" className="profile-image">
                <img className="user-img img-radius" src={`${PictureUrl}/users/${item.imageUrl}`} alt="user-img" style={{display:'block', height:'150px',width:'150px'}} /> </a> </div>
                            <div className="media-body row">
                                <div className="col-lg-12">
                                    <div className="user-title">
                                        <h2>{item.fullname}</h2>
                                         <h2 >{item.groupName}</h2>
                                    </div></div><div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<div className="col-sm-12">
<div className="row ">
<div className="col-sm-1"></div>
    <div className="col-sm-7">
    <br/>
                 
        <ul className="list-unstyled">
            <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Gender</b>:&nbsp;{item.gender}</span> </p>
            </li>
            <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Member Since</b>:&nbsp;{longDate(item.dateCreate)}</span> </p></li>
            <li><p className="text-muted">
                <span className="txt-color-darken"><b>Fullname </b>:&nbsp;{item.fullname}</span>
                </p></li>
            
           
        </ul>
        <br/>                                           
        <p className="font-md" ><i >Last login</i></p><p> {item.lastUsed}</p>
            <br/>
        <p className="font-md" ><i >Permanent Address</i></p><p> {item.permanentAddress}</p>
<br/>
    <p className="font-md" ><i >Status</i></p><p>{item.isActive==='true'? <span className="pcoded-badge label label-success">ACTIVE</span>: <span className="pcoded-badge label label-danger">INACTIVE</span>}</p>
            <br/>
    </div>
    <div className="col-sm-4">
        <h1><small >Contact Info</small></h1>
    <ul className="list-unstyled">
        <li> <p className="text-muted">
                <i className="fa  fa-mobile"></i>&nbsp;&nbsp;
                <span className="txt-color-darken">{item.telephone}</span>
            </p> </li>
        <li><p className="text-muted">
                <span className="txt-color-darken" style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                    <i className="fa fa-envelope"></i>&nbsp;&nbsp;{item.email}                                                  </span></p>
        </li>
       
        <li><p className="text-muted"><i className="fa fa-at"></i>&nbsp;&nbsp;
                <span className="txt-color-darken">{item.email}</span></p></li>
       
        <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Username </b>:-&nbsp;</span> </p> </li>
       
    </ul>
                        </div>
                    </div>
                </div>
            </div>
            )}
            
            
            </div>
    
<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button"  className="btn btn-inverse btn-sm" onClick={()=>navigate.goBack()}><i className="fa fa-arrow-left"></i> Back</button>&nbsp;&nbsp;
	
								</footer>		</section>		
							</div>
    </div> </div>
            </div> 
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Profile) 