import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, Currency, FormatNumber, config, Token} from '../../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { longDate } from '../../components/globalFunction'

 const Newsletter =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    const [search, setSearch]= useState({
        email:'',
        isActive:'all'
    })
    
    const [group, setGroup] = useState([])
    const [content, setContent] = useState([]);
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    
   
    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select * from tbl_newsletter where code <> ''" ;
          
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append("jwt", Token);
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  
  const tableHeader = [   
    {dataField: 'newsName',  text: 'Name', editable: false, sort: true, formatter:(cell, row)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
    {dataField: 'planDate',  text: 'Plan date of sending',  sort: true, editable: false, formatter:(cell)=>longDate(cell)},
    {dataField: 'dateCreate',  text: 'Created On',  sort: true, editable: false, formatter:(cell)=>longDate(cell)},
	{dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/newsletter/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a>, editable: false}
           
         ];
        
         const TableRecord=(props)=>{
             const { SearchBar } = Search;  
             const customTotal = (from, to, size) => (
                <span >&nbsp;Showing { from } to { to } of { size } items</span>
              );
             const options = {
                showTotal: true,
              paginationTotalRenderer: customTotal,
              sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
              { text: 'All', value: props.data.length }]
               
              };
             return  <ToolkitProvider search columnToggle 
                         keyField='ID' data={ props.data } columns={ props.columns } >
                             
                             {
                                 props => (
                                 <div >
                                     <SearchBar
                                     { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                      <div className="dt-buttons btn-group pull-right"> 
                <a href="/newsletter/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</a>
                                
        </div> 
                         <BootstrapTable 
                            { ...props.baseProps }
                            striped
                        hover
                        condensed
                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                    pagination={paginationFactory(options) }
                  
                                     />
                                 </div>
                                 )
                             }
          </ToolkitProvider>
          }
          

     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
			menu="Communications"
		  submenu="Newsletter">
			
		   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		 
		 <div  className="main-body">
			  <div className="page-wrapper">
		
		<PageHeader title="Newsletter" description='Displaying all newsletter' icon='fa fa-commenting-o'>
		
				</PageHeader>


{/* The Page Body start here */}
<div className="page-body">


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Newsletter</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Newsletter) 