import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl,   API_URL, IMAGES_URL, Token, config} from '../../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { compare } from '../../components/globalFunction'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
 const Edit =()=> {
	 let myRef = useRef()
	 const [defaultUrl, setDefaultUrl] = useState('');
	
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	const [list, setList] = useState([])
	const [banner, setBanner] = useState({
		code:'b'+Math.random().toString(36).substring(2,9),
		title:'',
		url:'',
		imageUrl:'',
		target:'blank',
		status:'',
		isLogo:false,
		status:true,
		order:1
	})

	

	   const [image, setImage] = useState('');



const handleDeleteWithImage =(imageLink)=>{
	let close =   document.getElementById('btnWarningDialog-delAll')
	close.click();
	 

	setNotice({...notice, 
		isLoading: true}) 

	const fd = new FormData();
	fd.append('ID', 'code')
	fd.append('data', params.code)
	fd.append('imagelink', '../images/banner/'+imageLink)
	fd.append("jwt", Token);
let url = ServerUrl+'/deleteSingleWithImage_controller.php?tablename=tbl_banner';
	  axios.post(url, fd, config)
	  .then(response =>{
	   if(response.data.type ==='success'){
		  Alerts('Saved!', 'success', response.data.message)
		  window.open('/cms/slider', '_self')
			  } else{
		  Alerts('Error!', 'danger', response.data)
			  }   
	  })
	  .catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	  }).finally(()=>{
		  setNotice({...notice, 
			  isLoading: false
		  })
	  }) 
	
	
}
const setLogo =()=>{
	const img = document.getElementById('viewLogo');
	img.src = IMAGES_URL+"/noImage.png" 
	document.getElementById('logo').value = '';
	setImage('')
	setBanner({...banner, isLogo:false})
}

const removeImageUrl =()=>{
	const newContent =[...content]
	 newContent[0].imageUrl = '' 
	setContent(newContent);

	const img = document.getElementById('viewLogo');
	img.src = IMAGES_URL+"/noImage.png" 
	document.getElementById('logo').value = '';
	setImage('')
}




const fetchContent =()=>{
	setNotice({...notice, isLoading: true  });
   
	let url = API_URL+'/banner/display/one/'+params.code;
	
	axios.get(url, config).then(result=>{ 
		if(result.data.data.length!==0){

			try{

		setContent(result.data.data[0])
		setList(JSON.parse(result.data.data[0].banner_list).sort(compare))

				
			}catch(e){

			}
	}
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, 
			isLoading: false
		});
	}) 

}


	

 

	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='status'){
			value =value ==='Active'?'Inactive':'Active'
		}	
		setBanner({...banner, [name]:value}) 
		setErrors({...errors, [name]:''});
	}
 
    
const  handleFile = function(fieldName){
	return function(newValue){                 
		const value = newValue.target.files[0]
	  // setImage(value)
   readURL(newValue);
   }
   
  } 

   const readURL = (input)=>{       
	let displayMessage = '';
	  var fileName =  document.getElementById('logo').value;
	var idxDot = fileName.lastIndexOf(".") + 1;
	var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
	if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
		//TO DO    
		
		 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
	if (FileSize > 2) {
		displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 2 mb';
	   document.getElementById(input.target.name).value = '';
	   setImage('')
	} else {
		if (input.target.files && input.target.files[0]) { //Check if input has files.
		  var reader = new FileReader();//Initialize FileReader.
		  reader.onload = function (e) {
				   
		  const img = document.getElementById('viewLogo');
					 img.src = e.target.result             
					
		  };
		  reader.readAsDataURL(input.target.files[0]); 
		  setImage(input.target.files[0])

		  setBanner({...banner, imageUrl:banner.code+'.png', isLogo:true})
		  
		}
	}
	}else{
		displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 2 mb'
	   document.getElementById(input.target.name).value = '';
	   setImage('');
	}   
	
	if(displayMessage.length > 0){
	
		Alerts('Error!', 'danger', displayMessage)
	  }
} 



const handleAddOptions =(event)=>{
	event.preventDefault()
	if(!image){
		Alerts('Error!', 'danger', 'Please upload banner image ')
	}else{
	
		setNotice({...notice, 
			isLoading: true
		})
		const fd = new FormData();
		fd.append("image", image)
		fd.append("image_url", banner.code+'.png')
		fd.append("banner_list", JSON.stringify(list.concat(banner), null, 2))

		let url = API_URL+'/banner/update/'+params.code;

		axios.post(url, fd, config)
		.then(response =>{
		 if(response.data.type ==='success'){
			Alerts('Saved!', 'success', response.data.message)
		}})
		.catch((error)=>{
		  Alerts('Error!', 'danger', error.message)
		}).finally(()=>{
			setNotice({...notice, 
				isLoading: false
			})
			fetchContent()
			setLogo()
			setBanner({
				code:Math.random().toString(36).substring(2,9),
				title:'',
				url:'',
				imageUrl:'',
				target:'blank',
				status:'',
				isLogo:false,
				status:true,
				order:1
			}) 
		})
	}
}




    function submit(banner_list){
		
	 setNotice({...notice, 
		  isLoading: true})     
		const fd = new FormData();
		
		fd.append("banner_list", JSON.stringify(banner_list, null, 2) )
		let url = API_URL+'/banner/update/'+params.code;
		axios.post(url, fd, config)
		.then(response =>{
		 if(response.data.status ===200){
			//Alerts('Saved!', 'success', response.data.message)
		}})
		.catch((error)=>{
		  Alerts('Error!', 'danger', error.message)
		}).finally(()=>{
			setNotice({...notice, 
				isLoading: false
			})
			fetchContent()
		}) 
	}
	

		const handleRemovePhoto =(e, code)=>{
		 
			e.preventDefault()
			let close =   document.getElementById('btnWarningDialog-'+code)
				close.click();
			
		const newImageList = JSON.parse(content.banner_list);
		const otherImageList = newImageList.filter(list=>list.code!==code)
		setContent({...content, banner_list:JSON.stringify(otherImageList, null, 2)})
		submit(otherImageList)
		}   



		const tableImageHeader = [
			 
			{dataField: 'imageUrl', text: 'Picture', editable: false, formatter:(cell, row)=><img id={cell} className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+"/noImage.png"}} src={`${IMAGES_URL}/banners/${cell}`} title={row.imageUrl}  alt={row.order} />},
			{dataField: 'target', text: 'Target', sort:true},
			{dataField: 'title', text: 'Title', sort:true, formatter:(cell)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
			{dataField: 'url', text: 'Link', sort:true, formatter:(cell)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
			 {dataField: 'order', text: 'Order', sort:true, validator: (newValue, row, column) => {
				if (!Number(newValue)) {
				  return {
					valid: false,
					message: 'This field required valid number'
				  };
				}
				return true;
			}, formatter:(cell)=><div className="text-center">{cell}</div>},
		 
			 {dataField: 'status', text: 'Status', sort:true,  editor: {
				type: Type.SELECT, options:[{value:'true', label:'Active'},
				{value:'false', label:'Inactive'}]
			  }, formatter:(cell, row)=>row.status===true? <div style={{cursor:'pointer'}} className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div style={{cursor:'pointer'}}className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>},
		 
			 {dataField: 'code', text: 'Action', formatter:(cell, row)=><div>

			 <WarningModal  handleAction={(e)=>handleRemovePhoto(e, row.code, row.imageUrl)} mID={row.code} />
			
			 <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>
			 
			 </div>, editable: false}
			  ];
		 
			

	const TablePictureRecord=(props)=>{

		const handleUpdate=(column, newValue, code)=>{     
			 
	const newImageList = JSON.parse(content.banner_list);



	/* const currentContent = newImageList.map((item)=>{   
        if(item.code !==code){
            return {...item, [column.dataField]:newValue}
        }

         return item
		 
          }) */




	const otherImageList = newImageList.filter((list)=>list.code!==code)
	const imageData = newImageList.filter((list)=>list.code===code)		
	imageData[0][column.dataField] = newValue
	const currentImageList = otherImageList.concat(imageData)

	setContent({...content, banner_list:JSON.stringify(currentImageList)})
	submit(currentImageList)
		 } 


		 
		 const customTotal = (from, to, size) => (
			<span >&nbsp;Showing { from } to { to } of { size } items</span>
		  );
		 const options = {
			showTotal: true,
		  paginationTotalRenderer: customTotal,
		  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
		  { text: 'All', value: props.data.length }]
		   
		  };
		 return  <ToolkitProvider search columnToggle 
					 keyField='code' data={ props.data } columns={ props.columns } >
						 
						 {
							 props => (
							 <div >
							   				 
					 <BootstrapTable
								 { ...props.baseProps }
											 striped
										 hover
										 condensed
										 noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
									 pagination={paginationFactory(options) }
									 cellEdit={ cellEditFactory({
									   mode: 'dbclick',
									   blurToSave: true,
									   autoSelectText: true,
							 beforeSaveCell: (oldValue, newValue, row, column) => {
							   if(oldValue !==newValue){
							   handleUpdate(column, newValue, row.code);
							   
							  return true;
							 }
							 
						   }
									 }) }
								 />
							 </div>
							 )
						 }
	  </ToolkitProvider>
	  }

useEffect(()=>{
	fetchContent()
},[]);	
	
        return (<Template 
			menu="Content Management"
		submenu="Banners">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Edit Banners" description='Edit Website banner' icon='feather icon-layers'>
  <li className="breadcrumb-item"><a href="/cms/banners">Banners</a> </li>
					</PageHeader>
{/* The Page Body start here */}
<div  className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<a href="/cms/banners" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Banner info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
					<div className="form-group row">
					<div className="col-sm-3">
							
					<FormLabel name ="Title" toolTip={'Banner title'} />
					</div>
									
						<div className="col-sm-9 ">{content.title}
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Banner Layout" toolTip={'Sample of how this layout look like'} />
					</div>
									
						<div className="col-sm-9 ">
						<img  className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=IMAGES_URL+"/noImage.png"}} src={`${IMAGES_URL}/banners/${content.layout_url}`} title={content.title}  alt={content.title} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							
					<FormLabel name ="Dimension" toolTip={'Preferrable dimension in pixel'} />
					</div>
									
						<div className="col-sm-9 "><b>Height: </b>{content.prefer_height}<br/><b>Width: </b>{content.prefer_width}
							</div>
					</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Is Active" toolTip={'Check to display banner'}  />
			</div>
							
			{content.status ==='Active'? <div className="text-center"><i  className="fa fa-check  badge-inverse-success"></i></div>: <div className="text-center"><i className="fa fa-times  badge-inverse-danger"></i></div>}
			</div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>





							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Add new image</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
					<div className="form-group row">
					<div className="col-sm-3">
							
					<FormLabel name ="Title" toolTip={'Image title'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="title" value={banner.title} className='form-control '/>
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Banner Url" toolTip={'Banner link to open when click'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="url" value={banner.url} className={errors.url  ? 'form-control formerror' : 'form-control '}/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.url}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Open In" toolTip={'Browser Target'} />
					</div>
									
						<div className="col-sm-9 ">

							<select  name="target" value={banner.target} className="form-control" onChange={handleChange}>
							<option value="blank">New Window</option>
							<option value="_self">Same Window</option>
							</select>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange}   name="order" value={banner.order} className='form-control'/>
							
							</div>
							</div>
					</div>


					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Status" toolTip={'Check to appear on home page'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.status} name="status" checked={content.status==='Active'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


	
			<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name =" Image" toolTip={Tooltip.picture} />
					</div>
									
						<div className="col-sm-9 ">
						

						<div className="form-group">
						<img className="img-100" id="viewLogo"  src={IMAGES_URL+"/noImage.png"} alt="Picture "  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
				{banner.isLogo?<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>:''}	
										</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
					</div>		
	<button type="button" className="btn btn-info " onClick={handleAddOptions} ><i className="fa fa-save"></i> Save Image</button>
	</div>
	</section></div></div></div>
			



	<div className="row" >
	<div className="col-sm-12">
					<div className="card z-depth-0">
							<section className="card-block">
							<div className="table-responsive">
		{<TablePictureRecord data={list} columns={tableImageHeader}  />}
		</div>
							</section>
					</div>
			</div>
		</div>
							</div>

							</div>
							</div>
	                   
                            </div>


						



				</div>
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 