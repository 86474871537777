import React, {useState, useEffect} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import { LoadEffect } from '../components/loader'
import { FormLabel } from '../components/formControl'
import {Alerts, WarningModal} from '../components/notify'
import {ServerUrl, Token, config} from '../components/include'
import axios from 'axios'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';

 const Groups =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [content, setContent] = useState([])

const [group, setGroup] = useState({
	groupName:'',
	description:'',
	status:true
})

const fetchContent =()=>{
	setNotice({...notice, 
		isLoading: true
	});
	
	var sql ="Select * from tbl_account_group order by groupName ASC";
	  
	const fd = new FormData();
	fd.append("sql", sql);
	fd.append("jwt", Token);
	let url = ServerUrl+'/fetchBySql_controller.php';
	
	axios.post(url, fd, config).then(result=>{ 
		setContent(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, 
			isLoading: false
		});
	}) 

}


	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='status'){
			value = !group.status
		}
		setGroup({...group, [name] : value });
	}
	
	
function submit(event){  
		event.preventDefault()

		if(group.groupName ===''){
			Alerts('Error!', 'danger', 'Group name is required')
		}else{
		setNotice({...notice, 
		  isLoading: true})     
		  
		  const fd = new FormData();
		  for(let k in group){
			fd.append(k, group[k])
		  }
		  fd.append("jwt", Token);
		  let url = ServerUrl+'/save_controller.php?tablename=tbl_account_group';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				Alerts('Saved!', 'success', response.data.message)
					} else{
				Alerts('Error!', 'danger', JSON.stringify(response.data))
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{

			let close =   document.getElementById('closeGroup')
			close.click();
				setNotice({...notice, 
					isLoading: false
				})
				fetchContent()
				setGroup({
					groupName:'',
					description:'',
					status:true
				})
			}) 
	}
	
}

const  tableContentHeader = [
	{dataField: 'groupName',  text: 'Name'},
{dataField: 'description',  text: 'description', formatter:(cell)=><div style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</div>},	
	{dataField: 'status',  text: 'Active', editor: {
		type: Type.SELECT, options:[{value:true, label:'Active'},
		{value:false, label:'In Active'}]
      }, formatter:(cell, row)=>row.status==='true'? <div style={{cursor:'pointer'}} className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div style={{cursor:'pointer'}}className="text-center"><i title="Double click to change" className="fa fa-times badge-inverse-danger"></i></div>},
      
	  {dataField: 'ID', text: 'Action', formatter: (cell)=><div>
	  <WarningModal  handleAction={()=>handleDelete(cell)} mID={cell} />
		  <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Remove</button>
	  </div>, editable: false},

			  ];
		 

		const handleDelete =(ID)=>{
		let close =   document.getElementById('btnWarningDialog-'+ID)
		close.click();
		
		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'ID')
		fd.append('data', ID)
		fd.append("jwt", Token);
	let url = ServerUrl+'/delete_controller.php?tablename=tbl_account_group';
			axios.post(url, fd, config)
			.then(response =>{
			if(response.data.type ==='success'){
				Alerts('Saved!', 'success', response.data.message)
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				fetchContent()
			}) 
							
		}
	
	const TableContentRecord=(props)=>{
		const handleUpdate=(column, newValue, ID)=>{     		
			const fd = new FormData(); 
			fd.append('newValue', newValue);
			fd.append('column', column.dataField);
			fd.append('ID', ID);
			fd.append("jwt", Token);
		   let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_account_group'
		  axios.post(url, fd, config)
		  //.then(result => console.log(result.data))
		  .then()
		  .catch((error)=>Alerts('Error!', 'danger', error.message)) 
		}  
		
				// const { SearchBar } = Search;
				 
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='ID' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									   <div className="form-group">
										
								 
	<div className="dt-buttons btn-group"> 
		 <button data-toggle="modal"   data-target='#addGroup' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New Group</button>
 </div>                   
	 </div>
										 
	<BootstrapTable
				{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
					cellEdit={ cellEditFactory({
						mode: 'dbclick',
						blurToSave: true,
						autoSelectText: true,
			  beforeSaveCell: (oldValue, newValue, row, column) => {
				if(oldValue !==newValue){
				handleUpdate(column, newValue, row.ID);
				
			   return true;
			  }
			  
			}
					  }) }
				/>
			</div>
			)
		}
</ToolkitProvider>
			  }





	useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
			menu="People"
		submenu="Access Control">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="User Group" description='Listing All User group' icon='feather icon-user'>
  
  <li className="breadcrumb-item"><a href="/users">Users</a> </li>
					</PageHeader>

{/* The Page Body start here */}
<div className="page-body">
			
<div className="modal fade " id='addGroup' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add group</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
			  <div className="form-group row">
					<div className="col-sm-4">
						<FormLabel name ="Group name" toolTip={'Enter group name'} />
					</div>
									
						<div className="col-sm-8 ">
						<input type="text" className="form-control" onChange={handleChange}  name="groupName" value={group.groupName} />
						</div>
				</div>
				

				<div className="form-group row">
					<div className="col-sm-4">
							<FormLabel name ="Description" toolTip={'Enter Group description'} />
					</div>
									
						<div className="col-sm-8 ">
						<input type="text" className="form-control" onChange={handleChange}  name="description" value={group.description} />
							</div>
				</div>

                <div className="form-group row">
			<div className="col-sm-4">
					<FormLabel name ="Is Active" toolTip={'Check to activate this group'}  />
			</div>
							
				<div className="col-sm-5 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={group.status} onChange={handleChange} name="status" checked={group.status}  />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>	
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeGroup' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={(e)=>submit(e)}  className="btn btn-inverse">Save</button>
              </div>
          
		  </div>
      </div>
  </div>

			
	<div className="row  ">
	<div className="col-sm-12">
								
	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-info"></i> Groups Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
		 <TableContentRecord data={content}  columns={tableContentHeader}  />  
            </div>
           
    
    </div></div>
	</section>
	
	
	</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

		



                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Groups) 