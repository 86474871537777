import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, config, API_URL, Token} from '../../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle, CSVExport } from 'react-bootstrap-table2-toolkit';

 const ProductTag =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    const [tag, setTag]= useState({
        tagName:''
    })
   
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);

   
    const handleDelete  =()=>{
          

        let data = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status==='true'){
                data.push({code:newContent[i].code})
               
            }
        }
        if(data.length===0){
            Alerts('Error!', 'danger', Tooltip.deleteWarning)
              
        }else{
            let close =   document.getElementById('btnWarningDialog-delAll')
            close.click();
            setNotice({...notice, 
                isLoading: true}) 

            const fd = new FormData();
            fd.append('data', JSON.stringify(data, null, 2))
              
        let url = API_URL+'/tag/delete_multiple';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  
                      } else{
                  Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
                  fetchContent()
              }) 
            }

        
    }


    const fetchContent =()=>{
        setNotice({...notice, isLoading: true  });
        let url = API_URL+'/tag/display/all';
        axios.get(url, config).then(result=>{ 
            if(Array.isArray(result.data.data)){
            setContent(result.data.data)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 
    }
 

const handleChangeTag =(event)=>{
    let {name, value} = event.target;
    setTag({...tag, [name] : value });
}

function submitTag(){  
    if(!tag.tagName){
        Alerts('Error!', 'danger', 'Please enter tag name')
    }else{

    
    setNotice({...notice, 
      isLoading: true})     
          
      let formData = tag;
      const fd = new FormData();
      for(let k in formData){
        fd.append(k, formData[k])
      }
       let url = API_URL+'/tag/create';
			axios.post(url, fd, config)
			.then(response =>{
         if(response.data.type ==='success'){
            
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', response.data)
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            })
            fetchContent()
            setTag({ tagName:'' })
            let close =   document.getElementById('closeTag')
					close.click();
        }) 
    }
}


  const handleCheckOne =(e)=>{  
     
    let {name, value} = e.target
    const currentContent = content.map((item)=>{   
        if(item.code ===name){
            return {...item, status:item.status==='false'?'true':'false'}
        }

         return item
          })

  setStatus({...status, checkAll:false})
   setContent(currentContent) 

       }
    

  const handleCheckAll =()=>{
    var newContent = JSON.parse(JSON.stringify(content));
    for (var i in newContent){
        newContent[i].status=String(!status.checkAll)
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent)
 }

 
  const generalCheck =()=><div className="checkbox-fade fade-in-success">
  <label>
<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
      <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
  </label>
</div> 

    const tableHeader = [
        {dataField: 'code',  text: generalCheck(), editable: false, formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.code}  checked={row.status==='true'?true:false} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},
        {dataField: 'tagName', text: 'Tag Name'},
        {dataField: 'products', text: 'Tag Products', editable: false},
     ];
    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, code)=>{       
         const fd = new FormData(); 
         fd.append('newValue', newValue);
         fd.append('column', column.dataField);
         fd.append('code', code);
        let url = API_URL+'/tag/change_field'
       axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
      <div className="dt-buttons btn-group pull-right">
      <button data-toggle="modal"   data-target='#addTag' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</button>

    <button className="btn btn-outline-danger" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button>
 </div>                   
                 </div>
                                 
                     <BootstrapTable 
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.code);
                               
                              return props.fetchContent;
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      

     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
            menu="Catalog"
            submenu="Product Tags">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="All Product Tags" description='Listing all product tags' icon='feather icon-book'>
	
                <li className="breadcrumb-item"><a href="/products">Products</a> </li>
                        </PageHeader>


<div className="modal fade " id='addTag' tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add New Tag</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Tag Name" toolTip={'Enter tag name'} />
					</div>
									
						<div className="col-sm-9 ">
					<input type="text"  name="tagName" className="form-control" onChange={handleChangeTag} value={tag.tagName} />
							</div>
				</div>
		
			
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeTag' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={submitTag} className="btn btn-inverse">Save</button>
              </div>
          </div>
      </div>
  </div>
{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal  handleAction={handleDelete} mID={'delAll'} /> 


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Product Tags</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isLoading ? <TableLoader />:
        <TableRecord data={content} fetchdata={fetchContent} columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(ProductTag) 