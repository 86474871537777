import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {CustomToggleList} from '../../components/dataTable'
import {ServerUrl, PictureUrl} from '../../components/include'
import axios from 'axios'
import { StaffId} from '../../components/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle, CSVExport } from 'react-bootstrap-table2-toolkit';

import { useNavigate } from 'react-router-dom'

 const Purchases =()=> {
     let myRef = useRef()
     const navigate = useNavigate();
     
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    
    const [search, setSearch]= useState({
        vendorName:'',
        isActive:'all'
    })
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);

    const SearchTable =(event)=>{
       event.preventDefault();
       fetchContent();
    }

    const handleDeleteWithImage =()=>{
          
            const config = {
                headers: { 'content-type': 'multipart/form-data'}
            } 

        let dataId = []
        let dataImage = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status===true){
                dataId.push(newContent[i].ID)
                dataImage.push('../images/category/'+newContent[i].imageUrl)
            }
        }
        let close =   document.getElementById('btnWarningDialog-delAll')
        if(dataId.length===0){
            Alerts('Error!', 'danger', Tooltip.deleteWarning)
              
        }else{
            setNotice({...notice, 
                isLoading: true}) 
        var data = JSON.stringify(dataId, null, 2)
        var imageLink = JSON.stringify(dataImage, null, 2);

            const fd = new FormData();
            fd.append('data', data)
            fd.append('imagelink', imageLink)
              
        let url = ServerUrl+'/deleteMultipleWithImage_controller.php?tablename=tbl_vendors';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  
                      } else{
                  Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
                  fetchContent()
              }) 
            }
            close.click();
    }

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select ID, vendorName, status, email, isActive, displayOrder, code from tbl_vendors where vendorName <> ''  " ;

        if(search.vendorName.length!==0){
            sql = sql + ' and vendorName = "'+search.vendorName+'"'
        }
        if(search.isActive!=='all'){
            sql = sql + '  and isActive = "'+search.isActive+'"'
        }
        sql = sql + ' order by displayOrder ASC'
        const config = {
            headers: { 'content-type': 'multipart/form-data'}
        }    
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
   // setErrors({...errors, [name]:''});
}

 

    const tableHeader = [
        
        {dataField: 'vendorName', text: 'Name', formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
        {dataField: 'email', text: 'Email', formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
        {dataField: 'isActive', text: 'Active', sort: true, editor: {
            type: Type.SELECT, options:[{value:'true', label:'Active'},
            {value:'false', label:'Not Active'}]
          }, formatter:(cell)=>cell==='true'? <div className="text-center"><i title="Double click to change" className="fa fa-check fa-2x badge-inverse-success"></i></div>: <div className="text-center"><i title="Double click to change" className="fa fa-times fa-2x badge-inverse-danger"></i></div>},
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/vendor/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a>, editable: false},
       
     ];
    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, ID)=>{       
         const fd = new FormData(); 
             fd.append('newValue', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
           const config = {
               headers: { 'content-type': 'multipart/form-data'}
           }
            let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_vendors'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                
    <a href="/vendor/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</a>   

      
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      


const ComingSoon =()=><section style={{background: `url(${PictureUrl}/bg.jpg) no-repeat`}} 
className="login-block">
<div className="container">
  <div className="row">
      <div className="col-sm-12">
      <div className="row">
   <div className="col-md-12">
       <div className=" error-pages">
           <h1 className="error-title text-warning"> Coming Soon</h1>
           <h2 className="error-sub-title text-white">Oops! This page is under development</h2>

           <p className="error-message text-white text-uppercase"> We could not let you access this page.
           ONCE WE'RE DONE WE WILL LET YOU KNOW</p>
           
           <div className="mt-4">
             <a href="/" className="btn btn-danger btn-round shadow-danger m-1">Go To Home </a>
             <a href="#!" onClick={()=>navigate.goBack()} className="btn btn-outline-danger btn-round m-1">Previous Page </a>
           </div>

       </div>
   </div>
</div>
      </div>
  </div>
</div>
</section>


     useEffect(()=>{
       // fetchContent()
       },[]);

        return (<Template menu="Inventory"
        submenu="Purchases"
        pageUrl="/inventory/purchases">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Purchases"
	   				menu="Purchases"
					   />


{/* The Page Body start here */}
<div className="page-body"><ComingSoon />
         {/* 
         <WarningModal  handleAction={handleDeleteWithImage} mID={'delAll'} /> 

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Vendor Name" toolTip={Tooltip.vendorName} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  ref={myRef} name="vendorName" value={search.vendorName} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Active" toolTip={'Search base on publish'} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select name="select" name="isActive" value={search.isActive} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="true">Active Only</option>
                        <option value="false">Inactive Only</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Vendor list</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content} fetchdata={fetchContent} columns={tableHeader}  />}
            </div>
           
    
    </div></div></div>
			

							</div>
							</div>
	                   
                            </div> */}




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Purchases) 