import React, {useState} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect } from '../../components/loader'
import Tooltip from '../../components/toolTips'
import {Alerts} from '../../components/notify'
import {ServerUrl} from '../../components/include'
import axios from 'axios'
import {StaffId} from '../../components/include'

 const Import =()=> {
	const [notice, setNotice] = useState({
        isLoading : false
	});
	   
const [excelFile, setExcelFile]=useState('')

const  handleFile = function(fieldName){
    return function(newValue){  
   readURL(newValue);
   }
   
} 

  const readURL = (input)=>{       
    let displayMessage = '';
    var fileName = document.getElementById('excelFile').value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile==="csv" || extFile==="CSV"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 0.5) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500 Kb';
        document.getElementById(input.target.name).value = '';
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            
     setExcelFile(input.target.files[0])
    
     }
    }
    }else{
        displayMessage = 'Only excel  files are allowed with maximum of 500Kb'
        document.getElementById(input.target.name).value = '';
        setExcelFile('')
    }   
    
    if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
} 


 const handleSubmit = event =>{
    event.preventDefault();    
     if(!excelFile){
       var errors='You have not choose any file to upload';
        Alerts('Error!', 'danger', errors)
    }else{

        setNotice({...notice, 
            isLoading: true})    
    const fd = new FormData(); 

   let  display=[{
        allowPageSize:true,
        pageSize:6,
        pageSizeOption:'6,3,9',
        priceRange:'',
    }]
  let  mapping =[{
        exclusive:'',
        limitation:'',
        discount:''
    }]

    fd.append('excelFile', excelFile);      
    fd.append('staffCreate', StaffId); 
    fd.append('mappings', JSON.stringify(mapping, null, 2))
     fd.append('displays', JSON.stringify(display, null, 2))
      
     const config = {
        headers: { 'content-type': 'multipart/form-data'}
    } 

          let url = ServerUrl+'/importExcel_controller.php?tablename=tbl_manufacturer&token=import123456';
      axios.post(url, fd, config)
      .then(response =>{
    if (response.data.type ==='success'){
          Alerts('Success!', 'success', response.data.message)
          } else{
            Alerts('Error!', 'danger', response.data)
          } 
      })
      .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
           
      }).finally(()=>{
        setNotice({...notice, 
            isLoading: false
        }) 
        document.getElementById('excelFile').value=''
        setExcelFile(''); 
      })   
           
    } 
}
	
        return (<Template
        
            menu="Catalog"
            submenu="Brands"
            pageUrl="/brand/import">
            
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Import Brands from CSV"
	   				menu="Brands"
					   menuLink="/brands"
					   submenu="Import Brands"
					   />


{/* The Page Body start here */}
<div className="page-body">
								

			<div className="row  ">
			<div className="col-sm-12">
								
	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-upload"></i> Upload Bulk Brands</h5>
			<div className="card-header-right">
            <ul className="list-unstyled card-option">
                <li><i className="feather icon-maximize full-card"></i></li>
                <li><i className="feather icon-minus minimize-card"></i></li>
            </ul>
			</div>
		</div>
			<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block ">
                        <h4 className="text-bold">Prepare your brands file:</h4>      
                <div className="row">
                        <div className="col-sm-3 text-right bold text-red"><u>Required Columns</u></div>
                        <div className="col-sm-7 bold"><u>Allowed values</u></div>                           
                            <div className="col-sm-3 text-right text-bold">Brand Name</div>
                            <div className="col-sm-7">{Tooltip.manufacturerName} <br/>                  
                            </div>          								
                            <div className="col-sm-3 text-right text-bold">Display Order</div>
                            <div className="col-sm-7">{Tooltip.displayOrder}<br/></div>                                                      
                            <div className="col-sm-3 text-right text-bold">Published</div>
                            <div className="col-sm-7">{Tooltip.publishManufacturer}<br/>  <br/><br/>   
                                </div>
                            <div className="col-sm-3 text-right text-bold text-yellow"><u>Optional Columns</u></div>
                            <div className="col-sm-7 text-bold"><u>Allowed Values</u></div>                           
                            <div className="col-sm-3 text-right text-bold">Description</div>
                            <div className="col-sm-7">{Tooltip.manufacturerDescription}<br/>     
                                </div>
                               
                                
                                <div className="col-sm-3 text-right text-bold">Page Name</div>
                            <div className="col-sm-7">{Tooltip.seoName}<br/>     
                                </div>
                                <div className="col-sm-3 text-right text-bold">Meta Title</div>
                            <div className="col-sm-7">{Tooltip.metaTitle}<br/>     
                                </div>
                                <div className="col-sm-3 text-right text-bold">Meta Keywords</div>
                            <div className="col-sm-7">{Tooltip.metaKeywords}<br/>     
                                </div>
                                <div className="col-sm-3 text-right text-bold">Meta Description</div>
                            <div className="col-sm-7">{Tooltip.metaDescription}<br/>     
                                </div>
                        </div>
	</section></div></div></div>
			

    <div className="row " >
        <div className="well">
<a href={ServerUrl+'/downloadCsvSample_controller.php?tablename=tbl_manufacturer&token=ecopysample123456'}  className="btn btn-info btn-sm pull-right"><i className="fa fa-download"></i> Download sample File</a>

<p><span className="text-info">The first line in downloaded CSV file should remain as it is. Please do not change the order of columns.</span><br/><span className="text-success">The correct column order is (<b>Manufacturer Name, Display Order,  Published, Description, Page Name, Meta Title, Meta Keywords, Meta Description</b>)</span> <span className="text-primary">&amp; you must follow this.</span></p>

<a href="https://www.ablebits.com/office-addins-blog/2014/04/24/convert-excel-csv/" className="bold" target="_blank">Also, Click to see How to convert Excel file to CSV</a>
</div>
        </div>

</div>

<div className="row">
<div className="col-lg-12">
           <div className="card">
              <div className="card-body"> 
              
			<div className="card text-white bg-primary shadow-primary">
            <section className="col-md-12 ">
            <h4 className="alert-heading ">Excel File</h4>
						{Tooltip.uploadHelp}
                        <hr/><br/> 
                            <div className="form-row">
                    <input id="excelFile" name="excelFile" onChange={handleFile('excelFile')} className="form-control"  type="file" accept=".csv" /> 
				</div> 								
					
                									
                <hr/>
			<button type="button"  onClick={handleSubmit} className="btn  btn-success pull-right"><i className="fa fa-save"></i> Upload from Excel</button>
                            </section>    

                        </div></div> </div></div>
    </div>
							</div>
							</div>
	                   
                            </div>


						

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Import) 