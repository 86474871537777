import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../../components/notify'
import {ServerUrl, staffCode, IMAGES_URL, API_URL, Token, config, PictureUrl} from '../../components/include'
import axios from 'axios'

import Select from 'react-select'
import Country from "../../components/country";
 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	
	const [province, setProvince] = useState([]);
	const [lga, setLga] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	let initialState = {
		code:'v-'+Math.random().toString(36).substr(2,9),
		logoUrl:IMAGES_URL+"/noImage.png",
		isLogo:false, 
		vendorName:'',	
		description:'',
		allowPageSize:true,
		pageSizeOption:'9,15,27,33',
		pageSize:'9',
		priceRange:'-2000;2000-5000;5000-10000;10000-20000;20000-40000;40000-',
		postalCode:'',
		telephone:'',
		address:'',
		city:'',
		lga:'',
		stateId:'',
		 country:'',
		  comment:'',
		   isActive:true,
		    email:'', 
		pageName:'',
		metaTitle:'',
		metaKeywords:'',
		metaDescription:'',
		displayOrder:1,
		staffCreate:staffCode,
		notes:'[]'
	}

	const [content, setContent] = useState(initialState)
	const [defaultUrl, setDefaultUrl] = useState('');
	const [image, setImage] = useState('');

  const fetchProvince =()=>{
	
	var sql ="Select ID, stateName from tbl_states order by ID ASC";
	
	const fd = new FormData();
	fd.append("sql", sql);
	fd.append("jwt", Token);
	let url = ServerUrl+'/fetchBySql_controller.php';
	
	axios.post(url, fd, config).then(result=>{ 
		setProvince(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})

}

const fetchLga =(stateId)=>{
	
	var sql ="Select ID, stateId, lgaName from tbl_lga where stateId='"+stateId+"' order by ID ASC";
	
	const fd = new FormData();
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller.php';
	fd.append("jwt", Token);
	axios.post(url, fd, config).then(result=>{ 
		setLga(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})

}
	const handleChangeNumber =(event)=>{
		let {name, value} = event.target;
		if(Number(value)<0){
			value =0
		}	
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleChangeCheck =(event)=>{
		let {name, value} = event.target;	
		value = value ==='true'?false:true
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='pageName'){
			value = value.replace(/,?[ ]/g, '-')
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleChangeDescription =(data)=>{
		setContent({...content, description : data });
	}

	const setLogo =()=>{
		const img = document.getElementById('viewLogo');
		img.src = content.logoUrl 
		document.getElementById('logo').value = '';
		setImage('')
		setContent({...content, isLogo:false})
	}
	const [panel, setPanel] = useState({
		info:true,
		display:true,
		seo:false,
		basic:true,
		seoDisplay:false,
		infoDisplay:false,
		displayDisplay:false
	})

	const goAdvance=()=>{
		setPanel({...panel,
		seoDisplay:true,
		infoDisplay:true,
		displayDisplay:true,
		basic:false
	})
	}

	const goBasic=()=>{
		setPanel({...panel,
		seoDisplay:false,
		infoDisplay:false,
		displayDisplay:false,
		basic:true
	})
	}
	const handleSelectCountry = (option, action)=>{
		setContent({...content, [action.name]:option.value})
	}
	const handleSelect = (option, action)=>{
		setContent({...content, [action.name]:option})
		if(action.name==='stateId'){
		fetchLga(option.value) 
		}
	}
	const ValidateExistence=()=>{
		if(content.email!==''){
             
            let url = API_URL+'/vendor/existence/'+content.email;
            axios.get(url,  config).then(response=>{
                if(response.data.status===200){
                    myRef.current.focus()
                    setErrors({...errors, email:content.email + ' already exist'})
                    setContent({...content, email:''});
                    return response.data;
                }
          })
        }

	} 
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		   
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append("state", content.stateId.value);
		  fd.append("lgaId", content.lga.value);
			if(image!==''){
				fd.append("image", image);
			}
			if(content.pageName===''){
				fd.append("pageNames", content.vendorName.replace(/,?[ ]/g, '-'))
			}else{
				fd.append("pageNames", content.pageName)
			}

			let url = API_URL+'/vendor/create';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
        
        if(!values.vendorName){
            errors.vendorName =Tooltip.required;
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }

        return errors;
        }


		const  handleFile = function(fieldName){
			return function(newValue){                 
				const value = newValue.target.files[0]
			  // setImage(value)
		   readURL(newValue);
		   }
		   
		  } 
		
		   const readURL = (input)=>{       
			let displayMessage = '';
			  var fileName =  document.getElementById('logo').value;
			var idxDot = fileName.lastIndexOf(".") + 1;
			var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
			if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
				//TO DO    
				
				 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
			if (FileSize > 1) {
				displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1 mb';
			   document.getElementById(input.target.name).value = '';
			   setImage('')
			} else {
				if (input.target.files && input.target.files[0]) { //Check if input has files.
				  var reader = new FileReader();//Initialize FileReader.
				  reader.onload = function (e) {
			   
				  const img = document.getElementById('viewLogo');
							 img.src = e.target.result             
					  
				  };
				  reader.readAsDataURL(input.target.files[0]); 
				  setImage(input.target.files[0])  
				  setContent({...content, isLogo:true})
				}
			}
			}else{
				displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500kb'
			   document.getElementById(input.target.name).value = '';
			   setImage('');
			}   
			
			if(displayMessage.length > 0){
			
				Alerts('Error!', 'danger', displayMessage)
			  }
		} 
		
	  useEffect(() => {
		fetchProvince();
	  }, []);
	
        return (<Template 
			menu="People"
		submenu="Vendors">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Add Vendor" description='Create new vendor' icon='feather icon-user'>
  <li className="breadcrumb-item"><a href="/vendors">Vendors</a> </li>
					</PageHeader>
		
		

{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success btn-sm" onClick={(e)=>handleSubmit(e, '/vendors')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/vendor/create')}
  className="btn btn-inverse btn-sm" ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/vendor/edit/'+content.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/vendors" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>

	{panel.basic?
	<button type="button" onClick={goAdvance} className="btn btn-sm  pull-right"> Basic Mode <i className="fa fa-check"></i></button> :
	<button type="button" onClick={goBasic} className="btn btn-sm btn-primary pull-right">  Advanced Mode <i className="fa fa-check"></i></button>}

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, info:!panel.info})}>
			<h5><i className="fa fa-info"></i> Vendor Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.info?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.info?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.vendorName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} name="vendorName" value={content.vendorName} className={errors.vendorName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.vendorName}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Description" toolTip={'Description of the vendor'} />
					</div>
									
						<div className="col-sm-9 ">
						<QuillText value={content.description} onChange={handleChangeDescription} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Email" toolTip={Tooltip.email} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} onBlur={ValidateExistence} ref={myRef} name="email" value={content.email} className={errors.email  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.email}</span>
							</div>
					</div>

					
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Active" toolTip={'A value indicating whether a vendor is active'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isActive} name="isActive" checked={content.isActive} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
			
			

				{panel.infoDisplay?	<>	
				
				
				<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Admin Comment" toolTip={Tooltip.adminComment} />
		</div>
						
			<div className="col-sm-9 ">
				
			<textarea type="text" className="form-control" rows="5" name="comment" value={content.comment} onChange={handleChange}>{content.comment}
			</textarea>

				</div>
		</div>
				
				
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Picture" toolTip={Tooltip.picture} />
					</div>
									
						<div className="col-sm-9 ">
						

						<div className="form-group">
						<img className="img-70" id="viewLogo"  src={IMAGES_URL+"/noImage.png"} alt="Picture "  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
				{content.isLogo?<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>:''}	
										</div>
							</div>
					</div></>:''}	

					
	</section></div>
	
	{panel.infoDisplay?	<div className="card z-depth-0">
						<section className="card-block">
                                           

						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Country" toolTip={'Select Country'} />
					</div>
									
						<div className="col-sm-9 ">
						<Country handleSelect={handleSelectCountry} country={content.country} />
							</div>
				</div>



				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="State " toolTip={'Select State / province'} />
					</div>
									
						<div className="col-sm-9 ">
						{content.country==='Nigeria'?
						<Select  
				  options={province&&province.map((state, idx)=> {
					return {key:idx, value: state.ID, label: state.stateName, index:idx };
				  })}
onChange={handleSelect}  name="stateId"  value={content.stateId} styles={selectStyles} />:'Others'} 

							</div>
				</div>

				
		{content.country==='Nigeria'?		<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Lga" toolTip={'Enter Lga'} />
					</div>
									
						<div className="col-sm-9 ">
			<Select   options={lga&&lga.map((lg, idx)=> {
                         return {key:idx, value: lg.ID, label: lg.lgaName, index:idx };
                                  })}
onChange={handleSelect}  name="lga" value={content.lga} styles={selectStyles} />

							</div>
				</div>:''}

				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="City" toolTip={'Enter city'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChange}  name="city" value={content.city} />
							</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Address" toolTip={'Enter Address'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChange}  name="address" value={content.address} />
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Telephone" toolTip={'Enter Telephone'} />
					</div>
					
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChange}  name="telephone" value={content.telephone} />
							</div>
				</div>

				<div className="form-group row">
					<div className="col-sm-3">
						<FormLabel name ="Zip code" toolTip={'Enter Zip / postal code'} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" onChange={handleChange}  name="postalCode" value={content.postalCode} />
						</div>
				</div>
				
	</section></div>:''}
			
	</div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, display:!panel.display})}>
			<h5><i className="fa fa-television"></i> Display</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.display?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.display?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
				{panel.displayDisplay?<>	
				
			
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Allow page size" toolTip={Tooltip.customerPageSize}  />
					</div>
									
						<div className="col-sm-9 ">
						<div className="checkbox-fade fade-in-success">
						<label>
					<input type="checkbox" value={content.allowPageSize} name="allowPageSize" checked={content.allowPageSize} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>
					
					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Page Size"  toolTip={Tooltip.customerPageSize} />
			</div>
							
				<div className="col-sm-4 ">
						<input type="number" className="form-control" onChange={handleChange}  name="pageSize" value={content.pageSize} />
							</div>
			</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Size Option" toolTip={Tooltip.pageSizeOption} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" name="pageSizeOption" 
						value={content.pageSizeOption}  onChange={handleChange}  />

							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Price Range" toolTip={Tooltip.priceRange} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" name="priceRange" 
						value={content.priceRange}  onChange={handleChange}  />

							</div>
					</div>
</>:''}

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="number" min="0" className="form-control" name="displayOrder" value={content.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>


				{panel.seoDisplay?			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, seo:!panel.seo})}>
			<h5><i className="fa fa-search-plus"></i> SEO</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.seo?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.seo?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Name" toolTip={Tooltip.seoName} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="text" className="form-control" name="pageName" 
						value={content.pageName} onChange={handleChange} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Title" toolTip={Tooltip.metaTitle} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaTitle" 
						value={content.metaTitle} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Keyword" toolTip={Tooltip.metaKeywords} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaKeywords" 
						value={content.metaKeywords} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Description" toolTip={Tooltip.metaDescription} />
					</div>
									
						<div className="col-sm-9 ">
						
						<textarea type="text" className="form-control" rows="5" name="metaDescription" 
						value={content.metaDescription} onChange={handleChange}>{content.metaDescription}</textarea>
					</div></div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 