import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, FormatNumber, Currency, PictureUrl, config, Token} from '../../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { convertDate } from '../../components/globalFunction'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

 const Edit =()=> {
     let myRef = useRef()
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [products, setProducts] = useState([])
	const [defaultUrl, setDefaultUrl] = useState('');
	const [tracker, setTracker] = useState([]);
		const [product, setProduct] = useState([]);


		function generateCardNo(t){
			t||(t=16);
			for(var e="",a=0;a<t;a++){
				var n=Math.floor(Math.random()*"1234567890".length);
				e+="1234567890".substring(n,n+1)
				}

				const newContent =[...tracker]
				newContent[0].trackingId = e
				setTracker(newContent);	
				return e
		}	


		const handleChange =(event)=>{
			const newContent =[...tracker]  
				let {name, value} = event.target;
				if(name==='isActive'){
					value =value ==='true'?'false':'true'
				}	
				newContent[0][name]= value
				setTracker(newContent);
				setErrors({...errors, [name]:''});
			}


		const fetchProduct =()=>{


			var sql ="Select o.ID, p.productName, p.coverUrl, o.status, o.dateTime, o.attributes, o.files, o.quantityPrice, o.productCode, p.isPrinting, p.bulkPrices, o.quantity, o.total, o.attributes, p.price, o.totalAttributePrice, o.graphicFee, o.shippingFee from  tbl_product_orders o, tbl_products p where o.productCode = p.code and o.orderId = '"+ params.code+"' order by o.ID DESC limit 3" ;
				
			const fd = new FormData();
			fd.append("sql", sql);
			fd.append('jwt', Token)
			let url = ServerUrl+'/fetchBySql_controller.php';
			axios.post(url, fd, config).then(result=>{
				setProduct(result.data)
			})
			.catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			})
		}
	


		const fetchTracker =()=>{
			setNotice({...notice, 
				isLoading: true
			});
			var sql ="Select ID, code, orderId, trackingId, dateCreate,	orderStatus, shippingCompany, estimateDatetime, courierPhone, note, customerCode  from tbl_track_order where orderId ='"+params.code+"' Limit 1" ;
				

			const fd = new FormData();
			fd.append("sql", sql);
			fd.append('jwt', Token)
			let url = ServerUrl+'/fetchBySql_controller.php';
			axios.post(url, fd, config).then(result=>{
				if(result.data.length!==0){
					setTracker(result.data)
				}
			})
			.catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				});
			})
		}

		const fetchProducts =()=>{
	
			var sql ="Select ID, code,  costPrice, productName, isShippable, shippingDetails, taxCategory, discount, isTaxExempt, status, sku, coverUrl, price from tbl_products order by productName ASC";
			
			const fd = new FormData();
			fd.append("sql", sql);
		  fd.append('jwt', Token)
			let url = ServerUrl+'/fetchBySql_controller.php';
			
			axios.post(url, fd, config).then(result=>{ 
				setProducts(result.data)
			})
			.catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			})
		
		}

		const getProductDetails = (code, field) =>{ 
			const result = products.filter((list)=>list.code===code);
			const answer = result.map((c)=>c[field])
				 return  String(answer);
			   }



    const handleDelete =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
		

		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'code')
		fd.append('data', params.code)
		fd.append('jwt', Token)
	let url = ServerUrl+'/delete_controller.php?tablename=tbl_track_order';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/sales/shipmentList', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 	
}

 
	
	
	

	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		    
		  let formData = tracker[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append('jwt', Token)
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_track_order';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}



	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();     
		  setDefaultUrl(url)
		  submit()	  
	}
	
	
		const tableHeader = [
        
			{dataField: '',  text: '#', editable: false, footer:'#', formatter:(cell, row, index)=>index+1},
		{dataField: 'productCode', text: 'Picture', footer:'Picture',  editable: false, formatter:(cell, row)=><img id={row.ID} className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/noImage.png"}} src={`${PictureUrl}/product/${getProductDetails(row.productCode, 'coverUrl')}`} title={row.productCode}  alt={row.productCode} />},
		
		  {dataField: 'productName', text: 'Product Name', footer:'Product Name', editable: false, sort: true, formatter:(cell, row)=><div><span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}><a href={`/products/edit/${row.productCode}`} target="_blank" rel="noopener noreferrer">{getProductDetails(row.productCode, 'productName')}</a></span><b>Code:</b> {getProductDetails(row.productCode, 'sku')}</div>},
		
		  {dataField: 'attributes', text: 'Product Attributes', footer:'Product Attributes', editable: false, sort: true, formatter:(cell, row)=><div>{cell.length!==0?JSON.parse(cell).map(data=>
			<div key={data.attributeCode} className="font-size-sm">
			  <span className="text-muted mr-2">{data.attributeName}:</span>{data.optionName}</div>
		):''}</div>},
		
			{dataField: 'quantity', footer:'Qty', text: 'Qty', sort: true, editable: false},
		
		  {dataField: 'total', footer:columnData => Currency+FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0)),  text: 'Total ('+Currency+')', sort: true, editable: false, formatter:(cell)=>FormatNumber(cell)}
		   
		 ];
				
				 const TableRecord=(props)=>{
					 const { SearchBar } = Search;  
					 const customTotal = (from, to, size) => (
						<span >&nbsp;Showing { from } to { to } of { size } items</span>
					  );
					 const options = {
						showTotal: true,
					  paginationTotalRenderer: customTotal,
					  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
					  { text: 'All', value: props.data.length }]
					   
					  };
					 return  <ToolkitProvider search columnToggle 
								 keyField='ID' data={ props.data } columns={ props.columns } >
									 
									 {
										 props => (
										 <div >
										
								 <BootstrapTable 
									{ ...props.baseProps }
									striped
								hover
								condensed
								noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
							pagination={paginationFactory(options) }
						  
											 />
										 </div>
										 )
									 }
				  </ToolkitProvider>
				  }
				  








useEffect(()=>{
	fetchProduct()
	fetchProducts()
	fetchTracker()
},[]);	
	
        return (<Template menu="Sales"
        submenu="Shipments">
            
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">
     
  <PageHeader title="Edit Shipment details" description='Update order track records' icon='feather icon-shopping-cart'>
  <li className="breadcrumb-item"><a href="/sales/shipmentList">Shipments</a> </li>
					</PageHeader>

{/* The Page Body start here */}
{tracker&&tracker.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, '/sales/shipmentList')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

<button type="button" onClick={(e)=>handleSubmit(e, '/sales/shipmentList/edit/'+params.code)} className="btn btn-inverse "><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;

	<a href="/sales/shipmentList" className="btn btn-warning "><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger " data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDelete()} mID={'delAll'} /> 


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Order Track Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">

										<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Order ID" toolTip={"Unique Order Number"} />
					</div>
									
						<div className="col-sm-9 ">
							{ data.orderId}
							</div>
					</div>


						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Tracking ID" toolTip={"Usually issued by the shipping company"} />
					</div>
									
						<div className="col-sm-9 ">

							<div className="input-group">
						<input type="text" onChange={handleChange}  name="trackingId" value={data.trackingId} className='form-control' />
						<span class="input-group-addon"  onClick={()=>generateCardNo(12)}  id="basic-addon">Generate Code</span>
							<span className="required-span"> *</span>
							</div>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Order Status" toolTip={"Current status of the order"} />
					</div>
									
						<div className="col-sm-9 ">
						<select  name="orderStatus" value={data.orderStatus} onChange={handleChange}  className="form-control" id='orderStatus' >

                                    <option value="Order confirmed">Order confirmed</option>
                                      <option value="Picked by the courier"> Picked by the courier</option>
                                    <option value="On the way">On the way</option>
                                    <option value="Ready for pickup">Ready for pickup</option>
                                    </select>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Shipping Company" toolTip={"Shipping Company"} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" onChange={handleChange}   name="shippingCompany" value={data.shippingCompany} className='form-control' />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Note" toolTip={"Message to customer"} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<textarea type="text" onChange={handleChange}   name="note" value={data.note} className='form-control'> {data.note}</textarea>
							
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Courier Phone" toolTip={"Contact Person"} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="courierPhone" value={data.courierPhone} className='form-control'/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Created Date" toolTip={"Date created"} />
					</div>
									
						<div className="col-sm-9 ">
							{ convertDate(data.dateCreate)}
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Estimated Date" toolTip={"Expected arrival date and time"} />
					</div>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="estimateDatetime" value={data.estimateDatetime} className='form-control'/>
							
							</div>
							</div>
					</div>


					

					<div className="form-group row">
                              <div className="col-sm-3">
                              <FormLabel  name="Customer"  toolTip={Tooltip.customerId} />
                              </div>

                              <div className="col-sm-9 "> {data.customerCode!=='null'?
                              
                                <a href={`/customer/edit/${data.customerCode}`}>[Click here to see]</a>:''}
                              </div>
                            </div>

	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-th-list"></i> Products</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                  

       	 <div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
		  <TableRecord data={product}  columns={tableHeader}  /> 
            </div>
           
    
    </div></div>
					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>




				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 