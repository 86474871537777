import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import Comingsoon from '../../components/comingsoon'

 const TrackOrder =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });

        return (<Template menu="Shipping"
        submenu="Tracker"
        pageUrl="/trackorder">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Track Order"
	   				menu="Tracker"
					   />

{/* The Page Body start here */}
<div className="page-body"><Comingsoon />


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(TrackOrder) 