import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {CustomToggleList} from '../../components/dataTable'
import {ServerUrl, Token, API_URL, config} from '../../components/include'
import axios from 'axios'
import { StaffId} from '../../components/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle, CSVExport } from 'react-bootstrap-table2-toolkit';

 const ProductReview =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    
    const [search, setSearch]= useState({
        fromDate:'',
        toDate:'',
        vendor:'all',
        isApproved:'all',
    })
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);
    const [vendor, setVendor] = useState([])
  
    const SearchTable =(event)=>{
       event.preventDefault();
       fetchContent();
    }


   const fetchVendor =()=>{
		let url = API_URL+'/vendor/display/all'
		axios.get(url, config).then(result=>{ 
			if(Array.isArray(result.data.data)){  
			setVendor(result.data.data)
			}
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}



    const handleApproveDissapprove =(status)=>{
          
      

    let dataId = []
    var newContent = JSON.parse(JSON.stringify(content));
    for (var i in newContent){
        if(newContent[i].status===true){
            dataId.push(newContent[i].ID)
            
        }
    }
    if(dataId.length===0){
        Alerts('Info!', 'info', 'Please select at least one record')
          
    }else{
        setNotice({...notice, 
            isLoading: true}) 
    var data = JSON.stringify(dataId, null, 2)

        const fd = new FormData();
        fd.append('data', data)
        fd.append('status', status)
        fd.append('action', 'ApproveReview')
        fd.append('jwt', Token)
    let url = ServerUrl+'/update_controller.php?tablename=tbl_product_review_all';
          axios.post(url, fd, config)
          .then(response =>{
           if(response.data.type ==='success'){
              Alerts('Saved!', 'success', response.data.message)
              
                  } else{
              Alerts('Error!', 'danger', response.data)
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, 
                  isLoading: false
              })
              setStatus({checkAll:false,
                checkOne:false})
              fetchContent()
          }) 
        }
}


    const handleDelete =()=>{
          
         
        let dataId = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status===true){
                dataId.push(newContent[i].ID)
                
            }
        }
        let close =   document.getElementById('btnWarningDialog-delAll')
        if(dataId.length===0){
            Alerts('Error!', 'danger', Tooltip.deleteWarning)
              
        }else{
            setNotice({...notice, 
                isLoading: true}) 
        var data = JSON.stringify(dataId, null, 2)

            const fd = new FormData();
            fd.append('data', data)
            fd.append('jwt', Token)
        let url = ServerUrl+'/deleteMultiple_controller.php?tablename=tbl_product_review';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  
                      } else{
                  Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
                  fetchContent()
              }) 
            }
            close.click();
    }

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="SELECT c.email, c.fullname, r.code, p.productName, p.sku, r.productId,  r.customerId, r.reviewText, r.title, r.isApproved, r.dateTime, r.rating, r.ID, r.status, r.staffApprove, r.replyText   from tbl_product_review r, tbl_customers c, tbl_products p where p.code = r.productId and c.code= r.customerId " ;

        if(search.fromDate.length!==0 && search.toDate.length!==0 ){
            sql = sql + ' and r.dateCreate between = "'+search.fromDate+'" and "'+search.toDate+'"'
        }
        if(search.vendor !== 'all'){
            sql = sql + '  and r.vendor = "'+search.vendor+'"'
        }
        if(search.isApproved!=='all'){
            sql = sql + '  and r.isApproved = "'+search.isApproved+'"'
        }

        sql = sql + '  order by r.ID DESC'

          
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append('jwt', Token)
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
           // Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
   // setErrors({...errors, [name]:''});
}

  const handleCheckOne =(e)=>{  
    
    let {name, value} = e.target

    const newContent = [...content]
    const otherContent = newContent.filter(item=>item.ID!==name)
    const custom = newContent.filter(item=>item.ID===name)
    const status = value ===''? true:value==='true'?false:true 
    custom[0].status = status
    const currentContent = custom.concat(otherContent)
  setStatus({...status, checkAll:false})
   setContent(currentContent) 
        
       }
    

  const handleCheckAll =()=>{
    var newContent = JSON.parse(JSON.stringify(content));
    for (var i in newContent){
        newContent[i].status=!status.checkAll
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent)
 }

 
  const generalCheck =()=><div className="checkbox-fade fade-in-success">
  <label>
<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
      <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
  </label>
</div> 

    const tableHeader = [
        {dataField: 'code',  text: generalCheck(), editable: false, formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.ID}  checked={row.status} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},
    
        {dataField: 'email', text: 'Customer', editable: false, formatter:(cell, row)=><a style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} href={"/customer/edit/"+row.customerId} >{row.fullname}<br/>{row.email}</a>},
    {dataField: 'productName', text: 'Product', editable: false, formatter:(cell, row)=><a style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} href={"/products/edit/"+row.productId} >{cell}<br/>Code:<b>{row.sku}</b></a>},
        {dataField: 'title', text: 'Title', editor: {
            type: Type.TEXTAREA}, formatter:(cell)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
        {dataField: 'reviewText', text: 'reviewText', editor: {
            type: Type.TEXTAREA}, formatter:(cell)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
        {dataField: 'rating', text: 'Rating', editable: false},
        {dataField: 'isApproved', text: 'Is Approved', sort: true, editor: {
            type: Type.SELECT, options:[{value:'true', label:'Approved'},
            {value:'false', label:'Disapproved'}]
          }, formatter:(cell)=>cell==='true'? <div className="text-center"><i title="Double click to change" className="fa fa-check badge-inverse-success"></i></div>: <div className="text-center"><i title="Double click to change" className="fa fa-times  badge-inverse-danger"></i></div>}, 

          {dataField: 'dateTime', text: 'Created On', editable: false},

        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/product-review/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a>, editable: false}
       
     ];
    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, ID)=>{       
         const fd = new FormData(); 
             fd.append('newValue', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
             fd.append('jwt', Token)
            let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_product_review'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                           
                                 
      <div className="dt-buttons btn-group pull-right"> 
    <button type="button" onClick={()=>handleApproveDissapprove('true')} className="btn btn-outline-success" > <i className="fa fa-check-square-o"></i>Approve Selected</button>
    <button type="button" onClick={()=>handleApproveDissapprove('false')} className="btn btn-outline-warning" > <i className="fa fa-minus-square-o"></i>Disapprove Selected</button>                     
    <button className="btn btn-outline-danger" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button>
 </div>                   
               

      
                                 </div>
                                 
                     <BootstrapTable 
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return props.fetchContent;
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      





     useEffect(()=>{
        //fetchContent()
       // fetchVendor()
       },[]);

        return (<Template 
            menu="Catalog"
            submenu="Product Reviews">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="All Product Reviews" description='Listing all product reviews' icon='feather icon-book'>
	
                <li className="breadcrumb-item"><a href="/products">Products</a> </li>
                        </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal  handleAction={handleDelete} mID={'delAll'} /> 

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
        <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="From Date" toolTip={Tooltip.fromDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="fromDate" value={search.fromDate} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Approved" toolTip={Tooltip.approved} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="isApproved" value={search.isApproved} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="true">Approved Only</option>
                        <option value="false">Disapproved Only</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="To Date" toolTip={Tooltip.toDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}   name="toDate" value={search.toDate} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <FormLabel name ="Vendor" toolTip={"Search by vendor property"} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="vendor" value={search.vendor} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        {vendor&&vendor.map((node, id)=>{
					return <option  key={id} value={node.code}>{node.vendorName}</option>
				})} 
                    </select>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Reviews</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
       
        <TableRecord data={content} fetchdata={fetchContent} columns={tableHeader}  />
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(ProductReview) 