
import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')



export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export const APP_KEY = process.env.REACT_APP_KEY;
export const TINYMCE_KEY ='';
export const staffName = 'Staff Name';


export const staffCode = Cookies.get('stfmscd')?Cookies.get('stfmscd'):undefined

export const Token = Cookies.get('msptk')?Cookies.get('msptk'):undefined


export const config = {
    headers: { 'content-type': 'multipart/form-data',
    'Authorization': `Bearer ${Token}`}
} 
export  const ServerUrl = process.env.REACT_APP_SERVER_URL;
export  const PictureUrl = process.env.REACT_APP_PUBLIC_IMAGE_URL;
export const DatabaseName = process.env.REACT_APP_DATABASE;  
export  const FrontEndUrl = process.env.REACT_APP_FRONT_URL;

export const Currency = '₦'
export const StaffId= '1'



export  const API_URL = process.env.REACT_APP_API_URL;
export  const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;
