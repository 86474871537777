import React from 'react'

export const LoadEffect = (props)=>{
    return <div className={props.className}>
          <div className="loader animation-start ">
    <span className="circle delay-1 size-2"></span>
    <span className="circle delay-2 size-4"></span>
    <span className="circle delay-3 size-6"></span>
    <span className="circle delay-4 size-7"></span>
    <span className="circle delay-5 size-7"></span>
    <span className="circle delay-6 size-6"></span>
    <span className="circle delay-7 size-4"></span>
    <span className="circle delay-8 size-2"></span>
</div></div>
};

export const Loader = ()=>{
    return <React.Fragment>  
          <div className="loader-block overlay">
          <svg id="loader2" viewBox="0 0 100 100">
        <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
    </svg> 
                        </div>
    </React.Fragment>
}

 export const TableLoader = ()=>{
    return <div className="preloader3 loader-block">
            <div className="circ1 loader-primary"></div>
            <div className="circ2 loader-primary"></div>
            <div className="circ3 loader-primary"></div>
            <div className="circ4 loader-primary"></div>
        </div>
}

export default React.memo(Loader)