import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../../components/notify'
import {ServerUrl, StaffId} from '../../components/include'
import axios from 'axios'

 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [defaultUrl, setDefaultUrl] = useState('');
	
	const [content, setContent] = useState({
		code:Math.random().toString(36).substr(2,9),
		cardNo:'',	
		cardValue:'',
		expiryDate:'0000-00-00',
		isActive:false,
		staffCreate: StaffId,	
		usage:[]
	})
	
	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='isActive'){
			value = value==='false'?true:false
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	function generateCardNo(t){
		t||(t=16);
		for(var e="",a=0;a<t;a++){
			var n=Math.floor(Math.random()*"1234567890".length);
			e+="1234567890".substring(n,n+1)
			}
			setContent({...content, cardNo : e });
			
			return e
	}	

	
	const ValidateExistence=()=>{
        if(content.cardNo!==''){
            const config = {
                headers: { 'content-type': 'multipart/form-data'}
            }    
            const fd = new FormData();
            fd.append("data", content.cardNo)
            let url = ServerUrl+'/existence_controller.php?tablename=tbl_giftcard&column=cardNo';
            axios.post(url, fd, config).then(response=>{
                if(response.data===true){
                    myRef.current.focus()
                    setErrors({...errors, cardNo:content.cardNo + ' already exist'})
                    setContent({...content, cardNo:''});
                    return response.data;
                }
          })
        }
	} 
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		  const config = {
			  headers: { 'content-type': 'multipart/form-data'}
		  }    
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
			
			 let url = ServerUrl+'/save_controller.php?tablename=tbl_giftcard';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.cardNo){
			errors.cardNo =Tooltip.required;
			myRef.current.focus()
		}

		if(!values.cardValue){
			errors.cardValue =Tooltip.required;
			
		}
		return errors;
		}

        return (<Template menu="Sales"
        submenu="Gift Cards"
        pageUrl="/giftcard/create">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Add a new gift card"
	   				menu="Giftcard"
					   menuLink="/giftcards"
					   subsubmenu="Add a new gift card"
					   />


{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, '/sales/giftcards')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/giftcard/create')}
  className="btn btn-inverse " ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/giftcard/edit/'+content.code)} className="btn btn-inverse "><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/sales/giftcards" className="btn btn-warning "><i className="fa fa-arrow-left"></i> Return</a>


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i> Gift Card Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Card No" toolTip={"Code use during checkout"} />
					</div>
									
						<div className="col-sm-9 ">


					
							<div className="input-group">
						<input type="text" onChange={handleChange} onBlur={ValidateExistence} ref={myRef} name="cardNo" value={content.cardNo} className={errors.cardNo  ? 'form-control formerror' : 'form-control '}/>
						<span class="input-group-addon" onClick={()=>generateCardNo(12)} id="basic-addon">Generate Code</span>


							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.cardNo}</span>
							</div>
					</div>




					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Card Value" toolTip={"The initial value of this gift card"} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="number" autoComplete="off" onChange={handleChange}   name="cardValue" value={content.cardValue} className={errors.cardValue  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.cardValue}</span>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Expiry Date" toolTip={"Giftcard expire date"} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="date" onChange={handleChange}   name="expiryDate" value={content.expiryDate} className={errors.expiryDate  ? 'form-control formerror' : 'form-control '}/>
							
							</div>
							</div>
					</div>


					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Active" toolTip={'Determine if the giftcard is activated'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isActive} name="isActive" checked={content.isActive} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


	
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-check"></i> Usage History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
			<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					History of giftcard
					</div>



					
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 