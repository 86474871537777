import React, {useState, useEffect} from 'react'
import {Alerts} from './notify'
import {ServerUrl, PictureUrl, API_URL, Token, IMAGES_URL} from './include'
import {decrypt} from './authentication'
import axios from 'axios'
import Cookies from 'js-cookie'
import { longDate } from './globalFunction'
import { Currency, FormatNumber, config, staffCode } from './include'
const Topbar = ()=>{
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    const [isFull, setIsFull] = useState(false)
/* Get the element you want displayed in fullscreen */ 
var elem = document.documentElement;

const IsFullMode =()=>{
     const windowWidth = window.innerWidth * window.devicePixelRatio;
    const windowHeight = window.innerHeight * window.devicePixelRatio;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    if (((windowWidth/screenWidth)>=0.95) && ((windowHeight/screenHeight)>=0.95)) {
        closeFullscreen()
        return true;
    }
    else {
        openFullscreen()
        return false
    } 
}


/* Function to open fullscreen mode */
function openFullscreen() {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem = window.top.document.body; //To break out of frame in IE
    elem.msRequestFullscreen();
  }
  setIsFull(true);
}

/* Function to close fullscreen mode */
function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    window.top.document.msExitFullscreen();
  }
  setIsFull(false);
}


const [user, setUser]=useState({})
const [orders, setOrders]=useState([])
const [products, setProducts]=useState([])
const [ticket, setTicket]=useState([])

const fetchProducts =()=>{
    setNotice({...notice, 
        isLoading: true
    });
    let url = API_URL+'/products/display/false';
    axios.get(url, config).then(result=>{ 

        if(Array.isArray(result.data.data)){
            setProducts(result.data.data)
        }
      
        
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, 
            isLoading: false
        });
    }) 

}


const fetchTicket =()=>{
   
    /* if ( Cookies.get('stfmscd') && Cookies.get('stfmscd')!==undefined ){ 
    var sql ="Select t.ID, c.email, c.fullname, t.code, t.dateCreate, t.dateTime, t.customerCode, t.subject, t.ticketType, t.priority, t.ticketStatus, t.status from tbl_customers c, tbl_tickets t where c.code = t.customerCode   and isNew = 'true' "
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller.php';
    
    axios.post(url, fd, config).then(result=>{ 
        if( Array.isArray(result.data)){
            setTicket(result.data)
        }
        
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }else{
        window.location.href='/logout'
    } */
}







const fetchContent =()=>{
       
    if ( Cookies.get('stfmscd') && Cookies.get('stfmscd')!==undefined ){ 
    let url = API_URL+'/customer/data/'+staffCode;
    axios.get(url, config)
    .then(result =>{ 
        if(result.data.status===200){
            
            setOrders(result.data.order)
            setUser(result.data.user[0])

        }else{
            
    window.location.href='/logout'
        }
        
    })
    .catch((error)=>{
        Alerts('Error! Content', 'danger', error.message)
    })

}else{
    window.location.href='/logout'
}
}

useEffect(()=>{
fetchContent() 
fetchProducts()
fetchTicket()
},[]);



    return <nav  className="navbar header-navbar  pcoded-header" header-theme="theme1" >
    <div className="navbar-wrapper">

        <div className="navbar-logo">
            <a className="mobile-menu" id="mobile-collapse" href="#!">
                <i className="feather icon-menu"></i></a>
            <a href="/dashboard">
                <img className="img-fluid logo"  src={IMAGES_URL+'/about/logob.png'} alt="Theme-Logo" />
            </a>
            <a href="#!" className="mobile-options">
                <i className="feather icon-more-horizontal"></i>
            </a>
        </div>

        <div className="navbar-container container-fluid">
            <ul className="nav-left" >
            <li > <a href="#!" ><i className="feather icon-search" ></i></a>
                </li>
                <li>
                    
                   <a href="#!" onClick={!isFull?()=>openFullscreen():()=>IsFullMode()} >
                        <i className={`feather ${!isFull?'icon-maximize':'icon-minimize'} full-screen`} ></i>
                    </a> 
                </li>
                

            </ul>


            <ul className="nav-right">

            {products.length !==0?    <li className="header-notification">
                    <div className="dropdown-primary dropdown">
                        <div className="dropdown-toggle" data-toggle="dropdown">
                            <i className="fa fa-bullhorn"></i>
                            <span className="badge bg-c-pink">{products.length}</span>
                        </div>
                        <ul className="show-notification notification-view dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                        <li> <h6>Product waiting to be publish</h6>
                                        <label className="label label-danger">{products.length}</label>
                                    </li>

                            {products && products.slice(0,4).map((data, id)=>
                            <li key={id} onClick={()=>window.open('/products/edit/'+data.code, '_self')}>
                                <div className="media">
                                    <div className="media-body">
                            <h5 className="notification-user">{data.productName}</h5>
                                        <p className="notification-msg">Amount {Currency+FormatNumber(data.price)}</p>
                                        <span className="notification-time">Created on: {longDate(data.created_at)}</span>
                                    </div>
                                </div>
                            </li>
                            )}

                            <li>
                            <a href="/products" className="label label-danger">See all products</a>
                            </li>
                        </ul>
                    </div>
                </li>:''}
                {ticket.length !==0?      
                <li className="header-notification">
                    <div className="dropdown-primary dropdown">
                        <div className="dropdown-toggle" data-toggle="dropdown">
                            <i className="feather icon-mail"></i>
                            <span className="badge bg-c-blue">{ticket.length}</span>
                        </div>
                        <ul className="show-notification notification-view dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                           <li>Recent Ticket</li>
                            {ticket && ticket.slice(0,4).map((data, id)=>
                            <li key={id} onClick={()=>window.open('/cms/ticket/reply/'+data.code, '_self')}>
                                <div className="media">
                                    <div className="media-body">
                            <p className="notification-msg">Type: <b>{data.ticketType} -{data.priority}</b></p>
                                        <span className="notification-time">{longDate(data.dateCreate)}</span>
                                    </div>
                                </div>
                            </li>
                            )}

                            <li> <a href="/cms/tickets" className="label label-success">See all tickets</a></li>
                        </ul>
                    </div>
                </li>:''}
                
                {orders.length !==0?      
                <li className="header-notification">
                    <div className="dropdown-primary dropdown">
                        <div className="dropdown-toggle" data-toggle="dropdown">
                            <i className="feather icon-bell"></i>
                            <span className="badge bg-c-green">{orders.length}</span>
                        </div>
                        <ul className="show-notification notification-view dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                           <li>Recent Order</li>
                            {orders && orders.slice(0,4).map((data, id)=>
                            <li key={id} onClick={()=>window.open('/sales/orders/edit/'+data.orderId, '_self')}>
                                <div className="media">
                                    <div className="media-body">
                                        <p className="notification-msg">{data.order_code} {"=>"} Total Amount {Currency+FormatNumber(data.order_total)}</p>
                                        <span className="notification-time">{longDate(data.created_at)}</span>
                                    </div>
                                </div>
                            </li>
                            )}

                            <li> <a href="/sales/orders" className="label label-success">See all orders</a></li>
                        </ul>
                    </div>
                </li>:''}

                <li className="user-profile header-notification">
                    <div className="dropdown-primary dropdown">
                        <div className="dropdown-toggle" data-toggle="dropdown">
                            <img src={IMAGES_URL+"/users/"+user.photo} onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/noImage.png"}} className="img-radius" alt="User-Profile" />
                            <span>{user&&user.fullname}</span>
                            <i className="feather icon-chevron-down"></i>
                        </div>
                        <ul className="show-notification profile-notification dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                            <li><a href="/general/settings"><i className="feather icon-settings"></i> Settings</a></li>
                            <li>
                                <a href={`/profile/`+staffCode}>
                                    <i className="feather icon-user"></i> Profile
                                </a>
                            </li>
                            <li>
                                <a href="#!">
                                    <i className="feather icon-mail"></i> My Messages
                                </a>
                            </li>
                                            <li>
                    
                    <a href="#!" onClick={()=>document.getElementById('openlockscreenModal').click()}>
                        <i className="feather icon-lock"></i> Lock Screen
                    </a>
                </li>
                            <li>
                                <a href="/logout">
                                    <i className="feather icon-log-out"></i> Logout
                                </a>
                            </li>
                        </ul>

                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
}
export default React.memo(Topbar)