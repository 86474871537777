import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import { LoadEffect } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import Tooltip from '../../components/toolTips'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../../components/notify'
import {API_URL, ServerUrl, Token, config} from '../../components/include'
import axios from 'axios'
import {PictureUrl,  staffCode} from '../../components/include'
import Select from 'react-select'

 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});


	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	let initialState = {
		code:'c-'+Math.random().toString(36).substr(2,9),
		logoUrl:PictureUrl+"/noImage.png",
		isLogo:false, 
		categoryName:'',	
		description:'',	
		allowPageSize:true,
		showOnHome:true,
		includeTopMenu:true,
		parentCategory:{value:'xrt', parentCategory:'root', label:'[Root]'},
		pageSize:9,
		pageSizeOption:'9,15,27,33',
		priceRange:'0-2000;2000-5000;5000-10000;10000-20000;20000-40000;40000-',
		exclusiveTo:'',
		limitation:'',
		discount:'',
		pageName:'',
		metaTitle:'',
		metaKeywords:'',
		metaDescription:'',
		products:[],
		published:true,
		displayOrder:1,
		staffCreate:staffCode,
	}


	
	const [content, setContent] = useState(initialState)
	const [defaultUrl, setDefaultUrl] = useState('');
	const [image, setImage] = useState('');
	const [category, setCategory] = useState([])


	const fetchCategory =()=>{
        let url = API_URL+'/category/display/all';
        axios.get(url, config).then(result=>{ 

			if(Array.isArray(result.data.data)){
			let initial= [{id:'xrt', code:'xrt', parentCategory:'root', categoryName:'[Root]'}]
			setCategory(initial.concat(result.data.data))

		setContent({...content, displayOrder:Number(result.data.data.length)+1})
			}
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
		})
  }


	const handleChangeNumber =(event)=>{
		let {name, value} = event.target;
		value = value.replace(/[^0-9]/g, "")
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleChangeCheck =(event)=>{
		let {name, value} = event.target;	
		value = value ==='true'?false:true
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='pageName'){
			value = value.replace(/,?[ ]/g, '-')
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}

	const handleSelect = (option, action)=>{
		setContent({...content, [action.name]: option});
		setErrors({...errors, [action.name]:''})
		 }

	const handleChangeDescription =(data)=>{
		setContent({...content, description : data });
	}

	const setLogo =()=>{
		const img = document.getElementById('viewLogo');
		img.src = content.logoUrl 
		document.getElementById('logo').value = '';
		setImage('')
		setContent({...content, isLogo:false})
	}
	const [panel, setPanel] = useState({
		info:true,
		display:true,
		seo:false,
		product:false,
		mapping:false,
		basic:true,
		seoDisplay:false,
		productDisplay:false,
		mappingDisplay:false,
		infoDisplay:false,
		displayDisplay:false
	})

	const goAdvance=()=>{
		setPanel({...panel,
		seoDisplay:true,
		productDisplay:true,
		mappingDisplay:true,
		infoDisplay:true,
		displayDisplay:true,
		basic:false
	})
	}

	const goBasic=()=>{
		setPanel({...panel,
		seoDisplay:false,
		productDisplay:false,
		mappingDisplay:false,
		infoDisplay:false,
		displayDisplay:false,
		basic:true
	})
	}

	
	const ValidateExistence=()=>{
        if(content.categoryName!==''){
             
            let url = API_URL+'/category/existence/'+content.categoryName;
            axios.get(url,  config).then(response=>{
                if(response.data.status===200){
                    myRef.current.focus()
                    setErrors({...errors, categoryName:content.categoryName + ' already exist'})
                    setContent({...content, categoryName:''});
                    return response.data;
                }
          })
        }
	} 
	
    function submit(){  
		setNotice({...notice,  isLoading: true})     
		     
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		fd.append('parent', content.parentCategory.value==='xrt'?'root':content.parentCategory.value)

			if(image!==''){
				fd.append("image", image);
			}
			if(content.pageName===''){
				fd.append("pageNames", content.categoryName.replace(/,?[ ]/g, '-'))
			}else{
				fd.append("pageNames", content.pageName)
			}
			
			 let url = API_URL+'/category/create';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				
				setTimeout(() => {
					
				window.open(defaultUrl, '_self')
				}, 3000);
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
		let errors = {};   
		if(!values.categoryName){
			errors.categoryName =Tooltip.required;
			myRef.current.focus()
		}
		return errors;
		}


		const  handleFile = function(fieldName){
			return function(newValue){                 
				const value = newValue.target.files[0]
			  // setImage(value)
		   readURL(newValue);
		   }
		   
		  } 
		
		   const readURL = (input)=>{       
			let displayMessage = '';
			  var fileName =  document.getElementById('logo').value;
			var idxDot = fileName.lastIndexOf(".") + 1;
			var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
			if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
				//TO DO    
				
				 var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
			if (FileSize > 0.5) {
				displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500 Kb';
			   document.getElementById(input.target.name).value = '';
			   setImage('')
			} else {
				if (input.target.files && input.target.files[0]) { //Check if input has files.
				  var reader = new FileReader();//Initialize FileReader.
				  reader.onload = function (e) {
			   
				  const img = document.getElementById('viewLogo');
							 img.src = e.target.result             
					  
				  };
				  reader.readAsDataURL(input.target.files[0]); 
				  setImage(input.target.files[0])  
				  setContent({...content, isLogo:true})
				}
			}
			}else{
				displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500kb'
			   document.getElementById(input.target.name).value = '';
			   setImage('');
			}   
			
			if(displayMessage.length > 0){
			
				Alerts('Error!', 'danger', displayMessage)
			  }
		} 
		

const Item =(props)=> {
	  const { ID, categoryName, children } = props;
	  return (
		<>
		  <option value={ID} key={ID}>{categoryName}</option>
		{children &&  <option style={{margin: '5px 25px'}}>
			 {children.map((item, index) => <Item key={index} {...item} />)}
		  </option>}
		</>
	  )
	}



	  const DisplaySelect=()=>{	  
		let details = get_options();
		return ( <Select  options={
			details&&details.map((node, id)=>{
				return {value:node.code, key:id, label:node.categoryName}
			})} 
			
onChange={handleSelect}  name="parentCategory" className={errors.categories ? 'form-control formerror' : ''} value={content.parentCategory} styles={selectStyles} /> 
)

}


function get_options(parent="root", indent="") {
		var result= []
		category&&category.map(cat=>{
			if(cat.parentCategory===parent){
				result.push({code:cat.code, categoryName:indent+cat.categoryName})
				result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
			}
		})
		return result;
	  }


		useEffect(()=>{
			fetchCategory()
		   },[]);
        return (<Template 
            menu="Catalog"
        submenu="Category">
          
         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
                <div className="page-wrapper">

<PageHeader title="Add New Category" description='Create new product category here' icon='feather icon-book'>
	
<li className="breadcrumb-item"><a href="/category">Category</a> </li>

                    </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success btn-sm" onClick={(e)=>handleSubmit(e, '/category')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/category/create')}
  className="btn btn-inverse btn-sm" ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/category/edit/'+content.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/category" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>

	{panel.basic?
	<button type="button" onClick={goAdvance} className="btn btn-sm  pull-right"> Basic Mode <i className="fa fa-check"></i></button> :
	<button type="button" onClick={goBasic} className="btn btn-sm btn-primary pull-right">  Advanced Mode <i className="fa fa-check"></i></button>}

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, info:!panel.info})}>
			<h5><i className="fa fa-info"></i> Category Info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.info?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.info?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Name" toolTip={Tooltip.categoryName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} onBlur={ValidateExistence} ref={myRef} name="categoryName" value={content.categoryName} className={errors.categoryName  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.categoryName}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Description" toolTip={Tooltip.categoryDescription} />
					</div>
									
						<div className="col-sm-9 ">
						<QuillText value={content.description} onChange={handleChangeDescription} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Parent" toolTip={Tooltip.parentCategory} />
					</div>
							
						<div className="col-sm-9 ">

							   {category&&<DisplaySelect />}
							</div>
					</div>

				{panel.infoDisplay?		<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Picture" toolTip={Tooltip.picture} />
					</div>
									
						<div className="col-sm-9 ">
						

						<div className="form-group">
						<img className="img-70" id="viewLogo"  src={PictureUrl+"/noImage.png"} alt="Picture "  />
				<label className="btn btn-inverse btn-round" htmlFor="logo" > Upload  file </label>
                   
                <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
				{content.isLogo?<button type="button" onClick={setLogo} className="btn btn-danger btn-round" htmlFor="logo" > Remove File</button>:''}	
										</div>
							</div>
					</div>:''}	
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, display:!panel.display})}>
			<h5><i className="fa fa-television"></i> Display</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.display?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.display?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
				{panel.displayDisplay?<>	
				<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Published"  toolTip={Tooltip.published} />
					</div>
									
						<div className="col-sm-9 ">
				<div className="checkbox-fade fade-in-success">
						<label>
							<input type="checkbox" value={content.published} name="published" checked={content.published} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Show on home page"  toolTip={Tooltip.showOnHome} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={content.showOnHome} name="showOnHome" checked={content.showOnHome} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Include in top menu"  toolTip={Tooltip.includeTopMenu} />
			</div>
							
				<div className="col-sm-9 ">
		<div className="checkbox-fade fade-in-success">
				<label>
					<input type="checkbox" value={content.includeTopMenu} name="includeTopMenu" checked={content.includeTopMenu} onChange={handleChangeCheck} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Allow page size" toolTip={Tooltip.customerPageSize}  />
					</div>
									
						<div className="col-sm-9 ">
						<div className="checkbox-fade fade-in-success">
						<label>
					<input type="checkbox" value={content.allowPageSize} name="allowPageSize" checked={content.allowPageSize} onChange={handleChangeCheck} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
						</label>
					</div>
							</div>
					</div>
					
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Size Option" toolTip={Tooltip.pageSizeOption} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" name="pageSizeOption" 
						value={content.pageSizeOption}  onChange={handleChange}  />

							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Price Ranges" toolTip={Tooltip.priceRange} />
					</div>
									
						<div className="col-sm-9 ">
						<input type="text" className="form-control" name="priceRange" 
						value={content.priceRange} onChange={handleChange}  />

							</div>
					</div></>:''}

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Order" toolTip={Tooltip.displayOrder} />
					</div>
									
						<div className="col-sm-4 ">
						<input type="text" min="0" className="form-control" name="displayOrder" value={content.displayOrder} onChange={handleChangeNumber} />

							</div>
					</div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>





				{panel.seoDisplay?			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, seo:!panel.seo})}>
			<h5><i className="fa fa-search-plus"></i> SEO</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.seo?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.seo?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Page Name" toolTip={Tooltip.seoName} />
					</div>
									
						<div className="col-sm-9 ">
				
						<input type="text" className="form-control" name="pageName" 
						value={content.pageName} onChange={handleChange} />
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Title" toolTip={Tooltip.metaTitle} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaTitle" 
						value={content.metaTitle} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Keyword" toolTip={Tooltip.metaKeywords} />
					</div>
									
						<div className="col-sm-9 ">
						
						<input type="text" className="form-control" name="metaKeywords" 
						value={content.metaKeywords} onChange={handleChange} />
					</div></div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Meta Description" toolTip={Tooltip.metaDescription} />
					</div>
									
						<div className="col-sm-9 ">
						
						<textarea type="text" className="form-control" rows="5" name="metaDescription" 
						value={content.metaDescription} onChange={handleChange}>{content.metaDescription}</textarea>
					</div></div>
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}





						{panel.productDisplay?	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, product:!panel.product})}>
			<h5><i className="fa fa-th-list"></i> Products</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className={`feather  ${panel.product?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.product?		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="row">
					
You need to save the category before you can add products for this category page.
					</div>



					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>:''}


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 