import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import Tooltip from '../../components/toolTips'
import {Alerts, WarningModal} from '../../components/notify'
import {ServerUrl, Token, config} from '../../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle, CSVExport } from 'react-bootstrap-table2-toolkit';

 const CheckoutAttribute =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    
    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);

    const handleDeleteSelected =()=>{

        let dataId = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status===true){
                dataId.push(newContent[i].ID)
            }
        }
        let close =   document.getElementById('btnWarningDialog-delAll')
        if(dataId.length===0){
            Alerts('Error!', 'danger', Tooltip.deleteWarning)
              
        }else{
            setNotice({...notice, 
                isLoading: true}) 
        var data = JSON.stringify(dataId, null, 2)
        
            const fd = new FormData();
            fd.append('data', data)
            fd.append('jwt', Token)
        let url = ServerUrl+'/deleteMultiple_controller.php?tablename=tbl_checkout_attribute';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  
                      } else{
                  Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
                  fetchContent()
              }) 
            }
            close.click();
    }

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select ID, checkoutName, status, code, displayOrder, controlType, isRequired from tbl_checkout_attribute order by checkoutName ASC" ;

         
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append('jwt', Token)
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  

  const handleCheckOne =(e)=>{  
    let {name, value} = e.target

    const newContent = [...content];
    const otherContent = newContent.filter(item=>item.code!==name)
    const attribute = newContent.filter(item=>item.code===name)
    const status = value ===''? true:value==='true'?false:true 
    attribute[0].status = status
    const currentContent = attribute.concat(otherContent)
  setStatus({...status, checkAll:false})
   setContent(currentContent) 
        
       }
    

  const handleCheckAll =()=>{
    var newContent = JSON.parse(JSON.stringify(content));
    for (var i in newContent){
        newContent[i].status=!status.checkAll
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent)
 }

  const generalCheck =()=><div className="checkbox-fade fade-in-success">
  <label>
<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
      <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
  </label>
</div> 

    const tableHeader = [
        {dataField: 'code',  text: generalCheck(), editable: false,  formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.code}  checked={row.status} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},
        {dataField: 'checkoutName', text: 'Name', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'This field required valid number'
              };
            }
            return true;
        }},
        {dataField: 'controlType', text: 'Control Type', editor: {
            type: Type.SELECT, options:[
            {value:'select', label:'Drop-down list'},
            {value:'radio', label:'Radio button list'},
            {value:'checkbox', label:'Checkboxes'},
            {value:'textbox', label:'Textbox'},
            {value:'textarea', label:'Multiline textbox'},
            {value:'date', label:'Date picker'},
            {value:'file', label:'File upload'},
            {value:'color', label:'Color squares'},
            {value:'readOnly', label:'Read-only checkboxes'}]
          }, formatter:(cell)=><div className="text-center">{cell}</div>},
        {dataField: 'displayOrder',  text: 'Display Order',  sort: true, validator: (newValue, row, column) => {
            if (!Number(newValue)) {
              return {
                valid: false,
                message: 'This field required valid number'
              };
            }
            return true;
        }, formatter:(cell)=><div className="text-center">{cell}</div>},

        {dataField: 'isRequired', text: 'Required', sort: true, editor: {
            type: Type.SELECT, options:[{value:'true', label:'Required'},
            {value:'false', label:'Not Required'}]
          }, formatter:(cell)=>cell==='true'? <div className="text-center"><i title="Double click to change" className="fa fa-check  badge-inverse-success"></i></div>: <div className="text-center"><i title="Double click to change" className="fa fa-times  badge-inverse-danger"></i></div>},

        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/checkout-attributes/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a>, editable: false},
       
     ];
    
     const TableRecord=(props)=>{

        const handleUpdate=(column, newValue, ID)=>{       
         const fd = new FormData(); 
             fd.append('newValue', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
             fd.append('jwt', Token)
            let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_checkout_attribute'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                 
                                 
                                 <div className="dt-buttons btn-group pull-right"> 
    <a href="/checkout-attributes/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</a>
                                
    <button className="btn btn-outline-danger" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button>
 </div>                   

      
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      


     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
            menu="Catalog"
            submenu="Attributes">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                <PageHeader title="Checkout Attributes" description='Listing all Checkout Attributes' icon='feather icon-book'>
	
    
                        </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal  handleAction={handleDeleteSelected} mID={'delAll'} /> 

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
				<div className="card-block bg-info" >
       	
 <div className="row">
<div className="col-sm-12">
Checkout attributes are displayed on the shopping cart page and provide the opportunity to offer more services to customers, i.e. gift wrapping, before placing the order.

</div></div>		</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Checkout Attributes</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content} fetchdata={fetchContent} columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(CheckoutAttribute) 