import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts, WarningModal} from '../components/notify'
import {CustomToggleList} from '../components/dataTable'
import {ServerUrl, FormatNumber, Currency, Token, config} from '../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

import Select from 'react-select'
 const TopProduct =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
    const [search, setSearch]= useState({
        fromDate:'',
        toDate:'',
        manufacturer:'all',
        category:'all',
        orderStatus:'all',
        paymentStatus:'all',

    })
    
    const [category, setCategory] = useState([])
    const [content, setContent] = useState([]);
	const [customer, setCustomer] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const SearchTable =(event)=>{
       event.preventDefault();
       fetchContent();
    }
    const fetchCategory =()=>{
        var sql ="Select ID, categoryName, parentCategory, code from tbl_category order by categoryName ASC" ;
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        axios.post(url, fd, config).then(result=>{  
            setCategory(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
  }
  
  
  


    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select SUM(s.total) as total, sum(s.quantity) as quantity, s.product_code, p.productName  from tbl_product_orders s, tbl_products p  where s.product_code = p.code   "

        if(search.fromDate.length!==0 && search.toDate.length!==0 ){
            sql = sql + ' and s.created_at between  "'+search.fromDate+'" and "'+search.toDate+'"'
        }
        
         
         if(search.orderStatus!=='all'){
            sql = sql + ' and o.order_status = "'+search.orderStatus+'"'
        } 
        if(search.paymentStatus!=='all'){
            sql = sql + ' and o.payment_status = "'+search.paymentStatus+'"'
        }  
       
        if(search.category!=='all'){
            sql = sql + ' and p.categories Like "%'+search.category+'%"'
        }
        
        sql = sql + ' group by s.product_code order by SUM(s.total) DESC'
          
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append('jwt', Token);

        console.log(sql)
        axios.post(url, fd, config).then(result=>{  

            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }

  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}
function get_options(parent="root", indent="") {
    var result= []
    category&&category.map(cat=>{
        if(cat.parentCategory===parent){
            result.push({code:cat.code, categoryName:indent+cat.categoryName})
            result = result.concat(get_options(cat.code, indent+cat.categoryName+"=>\t"))
        }
    })
    return result;
  }

const DisplaySelect=()=>{	  
    let details = get_options();
    return ( 
        <select  name="category" value={search.category} className="form-control" onChange={handleChange}>
        <option value="all">All</option>
        {details&&details.map((data, id)=><option key={id} value={data.code}>{data.categoryName}</option>)}
    </select> 
)

}
    

    const tableHeader = [

    {dataField: 'productName', text: 'Product Name', footer:'Product Name', editable: false, sort: true, formatter:(cell, row)=><span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
    
    {dataField: 'total', editable: false, footer:columnData => Currency+FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0)), text: 'Total ('+Currency+')', sort: true, formatter:(cell)=>FormatNumber(cell)},
    
	{dataField: 'quantity', footer:'Qty', text: 'Qty', sort: true, editable: false},
	
{dataField: 'productCode', footer:'Action', text: 'Action', formatter: (cell, row)=><a href={`/products/edit/${row.productCode}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> View</a>, editable: false}   
     ];
    
     const TableRecord=(props)=>{
         const { SearchBar } = Search;  
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='productCode' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                            
                     <BootstrapTable 
                        { ...props.baseProps }
                        striped
                    hover
                    condensed
                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                pagination={paginationFactory(options) }
              
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
      const handleSelect = (option, action)=>{
		setSearch({...search, [action.name]:option})
	  } 

     useEffect(()=>{
       // fetchContent()
        //fetchCategory()
       },[]);

        return (<Template 
            menu="Reports"
          submenu="Top Products">
            
           {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
         
         <div  className="main-body">
              <div className="page-wrapper">
        
        <PageHeader title="Top Products" description='List of the most selling items' icon='fa fa-bar-chart'>
        
                </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">   

            <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="From Date" toolTip={Tooltip.fromDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="fromDate" value={search.fromDate} />
                        </div>
                    </div>

                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="To Date" toolTip={Tooltip.toDate} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="toDate" value={search.toDate} />
                        </div>
                    </div>

                   


                  
             
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Categories" toolTip={Tooltip.category} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <DisplaySelect /> 
                        </div>
                    </div>
                <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Order Status" toolTip={Tooltip.orderStatus} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">

                    <select  name="orderStatus" value={search.orderStatus} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Processing">Processing</option>
                        <option value="Complete">Complete</option>
                        <option value="Cancelled">Cancelled</option>
                    </select>
                        </div>
                    </div> 

                  


                  {/*   <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Payment Status" toolTip={Tooltip.paymentStatus} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="paymentStatus" value={search.paymentStatus} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Authorized">Authorized</option>

                        <option value="Processing">Processing</option>
                        <option value="Paid">Paid</option>
                        <option value="Partially refunded">Partially refunded</option>
                        <option value="Refunded">Refunded</option>
                        <option value="Voided">Voided</option>
                    </select>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Top list</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content} fetchdata={fetchContent} columns={tableHeader}  />}
            </div>
           
    
    </div>
			

							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(TopProduct) 