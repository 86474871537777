import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import {LoadEffect, TableLoader } from '../components/loader'
import {Alerts} from '../components/notify'
import {ServerUrl, FormatNumber, Currency, Token, config} from '../components/include'
import axios from 'axios'
import { convertDate } from '../components/globalFunction'
 const Monthlysales =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
   
    const [content, setContent] = useState([])
    const [details, setDetails] = useState([])
    const [year, setYear]= useState(new Date().getFullYear())
    var date = new Date()
    var from = new Date(date.getFullYear(), date.getMonth(), 1, 1, 23, 59, 59);
    var to = new Date(date.getFullYear(), date.getMonth()+1, 1, 0, 23, 59, 59);


    const handleYearNext=()=>{
        let nYear = year+1
        setYear(nYear)
        displayMonth(nYear)
      }
      const handleYearPrevious=()=>{
        let nYear = year-1
        setYear(nYear)
        displayMonth(nYear)
      }
      const handleYearReset=()=>{
        let nYear = new Date().getFullYear()
        setYear(nYear)
        displayMonth(nYear)
      }
          
      const fetchDetails =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       

  var sql = "Select SUM(o.handling_fee) as shippingFee, SUM(o.tax) as tax, SUM(o.discount) as discount, SUM(o.order_total) as order_total, SUM(o.subtotal) as subtotal, h.payment_status from tbl_payment_history h, tbl_orders o  where h.payment_status='paid' and  h.created_at >=  '"+convertDate(from)+"' and h.created_at <= '"+convertDate(to)+"'"


        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append('jwt', Token);
        axios.post(url, fd, config).then(result=>{ 

          setDetails(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 
  
  }


      const displayMonth =(year)=>{     
        var start =''
        var end = ''     
        var answer = []
        for(var i=1; i<13; i++){
                if(i<10 ){	
                  start = year+'-0'+i+'-01'
                  end = new Date(year, i, 1, 0, 23, 59, 59).toISOString().slice(0,10)
                  
                  var sql = "Select SUM(o.handling_fee) as handling_fee, SUM(o.subtotal) as subtotal, SUM(o.tax) as tax, SUM(o.discount) as discount, SUM(o.order_total) as order_total, h.payment_status from tbl_payment_history h, tbl_orders o  where h.payment_status='paid' and  h.created_at >=   '"+start+"' and h.created_at <= '"+end+"'"
                     
                const fd = new FormData();
                fd.append("sql", sql);
                let url = ServerUrl+'/fetchBySql_controller.php';
                fd.append('jwt', Token);
                axios.post(url, fd, config).then(result=>{   
                answer.push(result.data)       
              })      
                 
              }else{
                
                start = year+'-'+i+'-01'
                end = new Date(year, i, 1, 0, 23, 59, 59).toISOString().slice(0,10)
                 
                 sql = "Select SUM(o.handling_fee) as handling_fee, SUM(o.subtotal) as subtotal, SUM(o.tax) as tax, SUM(o.discount) as discount, SUM(o.order_total) as order_total, h.payment_status from tbl_payment_history h, tbl_orders o  where h.payment_status='paid' and  h.created_at >=   '"+start+"' and h.created_at <= '"+end+"'"
                   
            const fd = new FormData();
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller.php';
            fd.append('jwt', Token);
            axios.post(url, fd, config).then(result=>{ 
              answer.push(result.data)       
                setContent(answer)
            })
              }     
              }    
        
            }

  
     useEffect(()=>{
        fetchDetails()
        displayMonth(year)
       },[]);

        return (<Template 
            menu="Reports"
          submenu="Monthly Sales">
            
           {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
         
         <div  className="main-body">
              <div className="page-wrapper">
        
        <PageHeader title="Monthly Sales" description='Displaying monthly sales report' icon='fa fa-bar-chart'>
        
                </PageHeader>


{/* The Page Body start here */}
<div className="page-body">
         

<div className="row">
        <div className="col-lg-12">
		  <div id="contentHere" >

          <div className="row">
          <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
            <div class="card bg-c-green text-white">
                <div class="card-body">
                    <div class="row align-items-center">
                    <div class="col col-auto text-right">
                            <i class="feather icon-shopping-cart f-50 text-c-green"></i>
                        </div>
                        <div class="col">
                            <p class="m-b-5"> Sales</p>
                            <h4 class="m-b-0">{Currency+FormatNumber(details&&Number(details.map(data=>data.order_total)))}</h4>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>


        <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
            <div class="card bg-c-pink text-white">
                <div class="card-body">
                    <div class="row align-items-center">
                    <div class="col col-auto text-right">
                            <i class="feather icon-shopping-cart f-50 text-c-pink"></i>
                        </div>
                        <div class="col">
                            <p class="m-b-5"> Tax</p>
                            <h4 class="m-b-0">{details&&Currency+FormatNumber(Number(details.map(data=>data.tax)))}</h4>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
            <div class="card bg-c-blue text-white">
                <div class="card-body">
                    <div class="row align-items-center">
                    <div class="col col-auto text-right">
                            <i class="feather icon-shopping-cart f-50 text-c-blue"></i>
                        </div>
                        <div class="col">
                            <p class="m-b-5"> Discount</p>
                            <h4 class="m-b-0">{details&&Currency+FormatNumber(Number(details.map(data=>data.discount)))}</h4>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
            <div class="card bg-c-yellow text-white">
                <div class="card-body">
                    <div class="row align-items-center">
                    <div class="col col-auto text-right">
                            <i class="feather icon-shopping-cart f-50 text-c-yellow"></i>
                        </div>
                        <div class="col">
                            <p class="m-b-5">Shipping Fee</p>
                            <h4 class="m-b-0">{details&&Currency+FormatNumber(Number(details.map(data=>data.handling_fee)))}</h4>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
             <div className="card-body">
            
             <div className="row">
             <div className="table-responsive">
             <table className="table table-bordered">
			<thead>
                <tr className="active">
                    <th ><div className="text-center"> <a href="#!" onClick={()=>handleYearPrevious()}>&lt;&lt;</a> </div></th>
                    <th colSpan="10">  <a href="#!" className="pull-left" onClick={()=>handleYearReset()}>Reset</a>
                    <div className="text-center"> {year} </div></th>
                    <th><div className="text-center"> <a href="#!" onClick={()=>handleYearNext()}>&gt;&gt;</a> </div></th>
                </tr>
                </thead>
            <tbody>
                <tr>
                    <td>January</td>
                    <td>February</td>
                    <td>March</td>
                    <td>April</td>
                    <td>May</td>
                    <td>June</td>
                    <td>July</td>
                    <td>August</td>
                    <td>September</td>
                    <td>October</td>
                    <td>November</td>
                    <td>December</td>
                </tr>
                <tr>
                    {content&&content.map((record, id)=>{
            return <td key={id}>{record.map((data, k)=>data.order_total?
                            <table key={k} className="table ">
        <tbody>
        <tr>
        <td><br/>Subtotal<br/> ShippingFee <br/> Tax<br/>Discount<span style={{fontWeight:'bold'}}><br/>Total</span></td>
        <td style={{textAlign:'right'}}><br/>
        {Currency+FormatNumber(data.subtotal)}<br/>
        {Currency+FormatNumber(data.handling_fee)}<br/>
        {Currency+FormatNumber(data.tax)}<br/>
        {Currency+FormatNumber(data.discount)}
        <span style={{fontWeight:'bold'}}><br/> {Currency+FormatNumber(data.order_total)}</span></td>
        </tr>
        </tbody>
        </table>:''
                            )}</td>
                    })}
                </tr>
            </tbody></table>

          </div>
          </div>
          </div>
        </div>
      </div>
      </div>
        </div>
      </div>
          </div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Monthlysales) 