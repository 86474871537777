import React, {useState, useEffect, useRef} from 'react'
import Template from '../../components/template'
import PageHeader from '../../components/pageheader'
import {LoadEffect, TableLoader } from '../../components/loader'
import { FormLabel } from '../../components/formControl'
import {Alerts, WarningModal} from '../../components/notify'
import {CustomToggleList} from '../../components/dataTable'
import {ServerUrl, Currency, FormatNumber} from '../../components/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

 const Giftcard =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    const [search, setSearch]= useState({
        cardNo:'',
        isActive:'all'
    })
    
    const [content, setContent] = useState([]);

    const SearchTable =(event)=>{
        event.preventDefault();
        fetchContent();
     }

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select * from tbl_giftcard where cardNo <> ''" ;

        if(search.cardNo.length!==0){
            sql = sql + ' and cardNo = "'+search.cardNo+'"'
        }
        if(search.isActive!=='all'){
            sql = sql + '  and isActive = "'+search.isActive+'"'
        }
        const config = {
            headers: { 'content-type': 'multipart/form-data'}
        }    
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  
  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}
  

    const tableHeader = [
        {dataField: 'cardValue', text: 'Initial Value ('+Currency+')', editable: false,  formatter:(cell)=>FormatNumber(cell)},
    {dataField: 'remainAmount', text: 'Remain Amount ('+Currency+')', editable: false, formatter:(cell)=>FormatNumber(cell)},
    {dataField: 'cardNo', text: 'Card Number', editable: false},
        {dataField: 'isActive', text: 'Is Active', editable: false, formatter:(cell)=>cell==='true'? <div className="text-center"><i className="fa fa-check  badge-inverse-success"></i></div>: <div className="text-center"><i  className="fa fa-times  badge-inverse-danger"></i></div>},
        {dataField: 'dateCreate', text: 'Create On', editable: false},
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><a href={`/giftcard/edit/${row.code}`} className="btn btn-warning btn-sm" rel="noopener noreferrer"><i className="fa fa-pencil"></i> Edit</a>, editable: false}
     ];
    

     const TableRecord=(props)=>{

       
         const { SearchBar } = Search;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                 
                                 
                                 <div className="dt-buttons btn-group pull-right"> 
    <a href="/giftcard/create" className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</a>
 </div>                   

      
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                   
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      





     useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template menu="Sales"
        submenu="Gift Cards"
        pageUrl="/giftcards">
       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
       <PageHeader title="Gift cards"
	   				menu="Gift cards"
					   />


{/* The Page Body start here */}
<div className="page-body">
        

<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="Card Number" toolTip={'Code use during checkout'} />
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="cardNo" value={search.cardNo} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <FormLabel name ="isActive" toolTip={'Card status'} />
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select name="isActive" value={search.isActive} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="true">Activated</option>
                        <option value="false">Deactivated</option>
                    </select>
                </div>
                    </div>
                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Giftcard list</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
           
    
    </div></div></div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Giftcard) 