import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import { LoadEffect } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts} from '../components/notify'
import {ServerUrl, staffCode, config, Token} from '../components/include'
import axios from 'axios'
import Select from 'react-select'


 const Create =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [defaultUrl, setDefaultUrl] = useState('');
	const [group, setGroup] = useState([])
	 
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	const [content, setContent] = useState({
		code:'c'+Math.random().toString(36).substr(2,9),
		email:'',
		password:'',
		fullname:'',
		gender:'Male',
		birthDate:'0000-00-00',
		company:'',
		roles:'',
		vendors:'',
		telephone:'',
		isActive:true,
		isNewsletter:true,
		comment:'',
		lastIp:'',
		staffCreate: staffCode,
		country:'Nigeria',
		stateId:'',
		lgaId:'',
		address:'[]'
	})
	const [vendor, setVendor] = useState([])
	const handleChange =(event)=>{
		let {name, value} = event.target;
		if(name==='isActive'){
			value = value==='false'?true:false
		}
		if(name==='isNewsletter'){
			value = value==='false'?true:false
		}
		setContent({...content, [name] : value });
		setErrors({...errors, [name]:''});
	}
	
	const fetchVendor =()=>{
		var sql ="Select ID, code, vendorName,  code from tbl_vendors order by vendorName ASC" ;
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append("jwt", Token);
		let url = ServerUrl+'/fetchBySql_controller.php';
		axios.post(url, fd, config).then(result=>{  
			setVendor(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}
	const fetchGroup =()=>{
		var sql ="Select ID, groupName from tbl_groups order by groupName ASC" ;
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append("jwt", Token);
		let url = ServerUrl+'/fetchBySql_controller.php';
		axios.post(url, fd, config).then(result=>{  
			setGroup(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}
	const handleSelect = (option, action)=>{
		setContent({...content, [action.name]: option});
		setErrors({...errors, [action.name]:''})
		 }
	
	const ValidateExistence=()=>{
        if(content.email!==''){
             
            const fd = new FormData();
            fd.append("data", content.email)
			fd.append("jwt", Token);
            let url = ServerUrl+'/existence_controller.php?tablename=tbl_customers&column=email';
            axios.post(url, fd, config).then(response=>{
                if(response.data===true){
                    myRef.current.focus()
                    setErrors({...errors, email:content.email + ' already exist'})
                    setContent({...content, email:''});
                    return response.data;
                }
          })
        }
	} 
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		
		  var roles = [];
		  for (var i = 0, l = (content.roles || []).length; i < l; i++) {
			roles.push({ID:content.roles[i].value});
		}
		  
		  let formData = content;
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
			fd.append('customerRole', JSON.stringify(roles, null, 2))
			fd.append("jwt", Token);
			 let url = ServerUrl+'/save_controller.php?tablename=tbl_customers';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	
	const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
        
        if(!values.fullname){
            errors.fullname =Tooltip.required;
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }


        if(values.password.length <6){
            errors.password ='Please enter minimum of 6 characters';
        } 
       
       
        return errors;
        }

	useEffect(()=>{
		fetchGroup()
		fetchVendor()
		},[]);


        return (<Template 
			menu="People"
		submenu="Customers">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Add Customers" description='Create new customer' icon='feather icon-user'>
  <li className="breadcrumb-item"><a href="/customers">Customers</a> </li>
					</PageHeader>


{/* The Page Body start here */}
<div className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
	<button type="button" className="btn btn-success" onClick={(e)=>handleSubmit(e, '/customers')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

	<button type="button" onClick={(e)=>handleSubmit(e, '/customer/create')}
  className="btn btn-inverse " ><i className="fa fa-save"></i> Save & New</button>&nbsp;


	<button type="button" onClick={(e)=>handleSubmit(e, '/customer/edit/'+content.code)} className="btn btn-inverse "><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;
	
	<a href="/customers" className="btn btn-warning "><i className="fa fa-arrow-left"></i> Return</a>


			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i> Customer info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Email Address" toolTip={Tooltip.customerEmail} />
					</div>
									
						<div className="col-sm-9 ">

							<div className="input-group">
						<input type="text" onChange={handleChange} onBlur={ValidateExistence} ref={myRef} name="email" value={content.email} className={errors.email  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.email}</span>
							</div>
					</div>




					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Password" toolTip={Tooltip.password} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="password" autoComplete="off" onChange={handleChange}   name="password" value={content.password} className={errors.password  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.password}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Fullname" toolTip={Tooltip.fullname} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="fullname" value={content.fullname} className={errors.fullname  ? 'form-control formerror' : 'form-control '}/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.fullname}</span>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Gender" toolTip={Tooltip.gender} />
					</div>
									
						<div className="col-sm-9 ">
						
			<div className="form-radio">
						<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Male' checked={content.gender==='Male'?true:false} />
						<i className="helper"></i>Male
					</label>
				</div>
				<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Female' checked={content.gender==='Female'?true:false} />
						<i className="helper"></i>Female
					</label>
				</div>
				</div>

							</div>
					</div>



					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Date of birth" toolTip={Tooltip.birthday} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="date" onChange={handleChange}   name="birthDate" value={content.birthDate} className='form-control'/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Telephone" toolTip={'Customer Telephone'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="telephone" value={content.telephone} className='form-control'/>
							
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Company Name" toolTip={Tooltip.companyName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="company" value={content.company} className='form-control'/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Subscribe to Newsletter" toolTip={'Subscribe to newsletter'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isNewsletter} name="isNewsletter" checked={content.isNewsletter} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Active" toolTip={'Determine if the customer is activate'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.isActive} name="isActive" checked={content.isActive} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
				<div className="col-sm-3">
						<FormLabel name ="Manager of Vendor" toolTip={Tooltip.vendor} />
				</div>
								
					<div className="col-sm-9 ">
				<div className="input-group">
				<select  name="vendors" value={content.vendors} className="form-control" onChange={handleChange}>
				<option value="no vendor">not a  vendor</option>
				{vendor&&vendor.map((node, id)=>{
					return <option  key={id} value={node.code}>{node.vendorName}</option>
				})}
				</select>
						
						</div>
						</div>
				</div>

				{/* <div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Customer Roles" toolTip={Tooltip.customerRole} />
					</div>
									
						<div className="col-sm-9 ">
						<Select isMulti options={
				group&&group.map((node, id)=>{
					return {value:node.ID, key:id, label:node.groupName}
				})} 
                
onChange={handleSelect}  name="roles"  value={content.roles} styles={selectStyles} /> 
</div>
	</div> */}



			<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Admin Comment" toolTip={Tooltip.adminComment} />
		</div>
						
			<div className="col-sm-9 ">
				
			<textarea type="text" className="form-control" rows="5" name="comment" value={content.comment} onChange={handleChange}>{content.comment}
			</textarea>

				</div>
		</div>
	
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Create) 