import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import { LoadEffect } from '../components/loader'
import { FormLabel } from '../components/formControl'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../components/notify'
import {ServerUrl, Token, config} from '../components/include'
import axios from 'axios'

 const Payment =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [panel, setPanel] = useState({
		transfer:true,
		paypal:false,
		flutterwave:false,
		wallet:false,
		giftcard:false,
	}) 

	const [content, setContent] = useState([])
const [errors, setErrors] = useState({});

const [transfer, setTransfer] = useState([])
const [flutterwave, setFlutterwave] = useState([])
const [arrival, setArrival] = useState([])
const [wallet, setWallet] = useState([])

const fetchContent =()=>{
	setNotice({...notice, 
		isLoading: true
	});

	
	var sql ="Select * from tbl_payment_gateway";
	   
	const fd = new FormData();
	fd.append("sql", sql);
	fd.append('jwt', Token)
	let url = ServerUrl+'/fetchBySql_controller.php';
	
	axios.post(url, fd, config).then(result=>{ 
		setContent(result.data)
		setTransfer(result.data[0])
		setArrival(result.data[1])
		setFlutterwave(result.data[2])
		setWallet(result.data[3])
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, 
			isLoading: false
		});
	}) 

}


	const handleChangeTransfer =(event)=>{
		let {name, value} = event.target;
		if(name==='status'){
			value = value==='true'?'false':'true'
		}
		setTransfer({...transfer, [name] : value });
	}
	const handleChangeArrival =(event)=>{
		let {name, value} = event.target;
		if(name==='status'){
			value = value==='true'?'false':'true'
		}
		setArrival({...arrival, [name] : value });
	}
	const handleChangeWallet =(event)=>{
		let {name, value} = event.target;
		if(name==='status'){
			value = value==='true'?'false':'true'
		}
		setWallet({...wallet, [name] : value });
	}
	
	const handleChangeflutterwave =(event)=>{
		let {name, value} = event.target;
		if(name==='status'){
			value = value==='true'?'false':'true'
		}
		setFlutterwave({...flutterwave, [name] : value });
	}
	
	function updatePayment(formData){  
		setNotice({...notice, 
		  isLoading: true})     
		 
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append('jwt', Token)
		  fd.append('jwt', Token)
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_payment_gateway';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
					} else{
				Alerts('Error!', 'danger', JSON.stringify(response.data))
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
			}) 
	}
	
	const handleChangeInstruction =(data)=>{
		setTransfer({...transfer, details : data });
	}
  
	
	useEffect(()=>{
        fetchContent()
       },[]);

        return (<Template 
			menu="General"
		submenu="Payment Gateway">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="System Payment Gateway" description='Update system payment platform here' icon='fa fa-cogs'>
  
					</PageHeader>


{/* The Page Body start here */}
<div className="page-body">
		
			
	<div className="row  ">
	<div className="col-sm-12">
								
	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, transfer:!panel.transfer})}>
			<h5><i className="fa fa-info"></i>  Bank Transfer</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.transfer?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.transfer?<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
					{transfer.length !==0?	<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Name" toolTip={'Display Name'} />
					</div>
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChangeTransfer} name="title" value={transfer.title} className={errors.title  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.title}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Transfer Instructions" toolTip={'The instructions you want displaying to customers who choose this payment method'} />
					</div>
									
						<div className="col-sm-9 ">
					 	<QuillText value={transfer.details} onChange={handleChangeInstruction} /> 
							</div>
					</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Is Active" toolTip={'To deactivate this gateway module, you must first choose an alternative for any products & invoices currently assigned to it to be switched to.'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={transfer.status} name="status" checked={transfer.status==='true'?true:false} onChange={handleChangeTransfer} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>	

			<div className="form-group row">
			<div className="col-sm-3">
					
			</div>
							
				<div className="col-sm-4 ">
				<button type="button" onClick={()=>updatePayment(transfer)} className="btn btn-success "><i className="fa fa-save"></i> Save Changes</button>	
					</div>
			</div>	
			
	</section>:''}
	
	
	</div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>

		<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, arrival:!panel.arrival})}>
			<h5><i className="fa fa-info"></i>  Pay on Arrival</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.arrival?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.arrival?<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
				{arrival.length !==0?	<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Name" toolTip={'Display Name'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChangeArrival} name="title" value={arrival.title} className={errors.title  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.title}</span>
							</div>
					</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Is Active" toolTip={'To deactivate this gateway module, you must first choose an alternative for any products & invoices currently assigned to it to be switched to.'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
				<input type="checkbox" value={arrival.status} name="status" checked={arrival.status==='true'?true:false} onChange={handleChangeArrival} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
			<div className="form-group row">
			<div className="col-sm-3">
					
			</div>
							
				<div className="col-sm-4 ">
				<button type="button" onClick={()=>updatePayment(arrival)} className="btn btn-success "><i className="fa fa-save"></i> Save Changes</button>	
					</div>
			</div>	
	</section>:''}
	</div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>

						{/* 	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, wallet:!panel.wallet})}>
			<h5><i className="fa fa-info"></i>  Pay with Wallet</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.wallet?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.wallet?<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
				{wallet.length !==0?	<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Name" toolTip={'Display Name'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChangeWallet} name="title" value={wallet.title} className={errors.title  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.title}</span>
							</div>
					</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Is Active" toolTip={'To deactivate this gateway module, you must first choose an alternative for any products & invoices currently assigned to it to be switched to.'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
				<input type="checkbox" value={wallet.status} name="status" checked={wallet.status==='true'?true:false} onChange={handleChangeWallet} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
			<div className="form-group row">
			<div className="col-sm-3">
					
			</div>
							
				<div className="col-sm-4 ">
				<button type="button" onClick={()=>updatePayment(wallet)} className="btn btn-success "><i className="fa fa-save"></i> Save Changes</button>	
					</div>
			</div>	
	</section>:''}
	</div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div> */}

							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, flutterwave:!panel.flutterwave})}>
			<h5><i className="fa fa-info"></i>  Flutterwave</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.flutterwave?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
		{panel.flutterwave?<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Display Name" toolTip={'Display Name'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChangeflutterwave}   name="title" value={flutterwave.title} className='form-control ' />
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.title}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Public key" toolTip={'Your public key from flutterwave'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChangeflutterwave}  name="details" value={flutterwave.details} className={errors.details  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.details}</span>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Return URL " toolTip={'The Return URL should be https://www.ptmt.com/invoice/notifyUrl/flutterwave'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChangeflutterwave}  name="returnUrl" value={flutterwave.returnUrl} className={errors.returnUrl  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.returnUrl}</span>
							</div>
					</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Is Active" toolTip={'To deactivate this gateway module, you must first choose an alternative for any products & invoices currently assigned to it to be switched to.'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
				<input type="checkbox" value={flutterwave.status} name="status" checked={flutterwave.status==='true'?true:false} onChange={handleChangeflutterwave} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
			<div className="form-group row">
			<div className="col-sm-3">
					
			</div>
							
				<div className="col-sm-4 ">
				<button type="button" onClick={()=>updatePayment(flutterwave)} className="btn btn-success "><i className="fa fa-save"></i> Save Changes</button>	
					</div>
			</div>	
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Payment) 