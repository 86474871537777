import React, {useState, useEffect, useRef} from 'react'
import Template from '../components/template'
import PageHeader from '../components/pageheader'
import { LoadEffect, TableLoader } from '../components/loader'
import { FormLabel } from '../components/formControl'
import Tooltip from '../components/toolTips'
import {Alerts, WarningModal} from '../components/notify'
import {ServerUrl, FormatNumber, Currency, FrontEndUrl, staffCode, Token, config, PictureUrl} from '../components/include'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { convertDate, longDate, compare, formatGroupLabel } from '../components/globalFunction'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import {CustomToggleList} from '../components/dataTable'
import Select from 'react-select'
import cellEditFactory from 'react-bootstrap-table2-editor';

 const Edit =()=> {
	 let myRef = useRef()
	 const [defaultUrl, setDefaultUrl] = useState('');
	
     let params = useParams()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
const [password, setPassword] = useState('')
const [products, setProducts] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false); 
	const [errors, setErrors] = useState({});
	const [content, setContent] = useState([])
	const [group, setGroup] = useState([])
	const [orders, setOrders] = useState([])
	const [billingAddress, setBillingAddress] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	const [wishlist, setWishlist] = useState([]);
	const [walletTransaction, setWalletTransaction] = useState([]);
	const [wallet, setWallet] = useState([]);
	const [province, setProvince] = useState([]);
	const [lga, setLga] = useState([])
	const [panel, setPanel] = useState({
		orderHistory:false,
		addresses:false,
		shoppingCart:false,
		log:false,
		subscription:false,
		wallet:false
	})
	const [transaction, setTransaction] = useState({
		isLien:false,
		isTransaction:false,
		transactionType:{value: "Deposit", label: "Deposit", transactionType: "Credit"},
		typeLabel:'Deposit',
		typeValue:'Credit',
		staffCreate:staffCode,
		usercode:'',
		wallet:'',
		description:'',
		amount:0,
		balance:0
	});
	const [logList, setLogList] = useState([])
	const [vendor, setVendor] = useState([])
	const fetchVendor =()=>{
		var sql ="Select ID, code, vendorName,  code from tbl_vendors order by vendorName ASC" ;
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append("jwt", Token);
		let url = ServerUrl+'/fetchBySql_controller.php';
		axios.post(url, fd, config).then(result=>{  
			setVendor(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}


	const fetchProvince =()=>{
	
		var sql ="Select ID, stateName from tbl_states order by ID ASC";
		   
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append("jwt", Token);
		let url = ServerUrl+'/fetchBySql_controller.php';
		
		axios.post(url, fd, config).then(result=>{ 
			setProvince(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}


	const fetchWalletHistory =()=>{
	
		var sql ="Select * from tbl_wallet_transaction where usercode ='"+params.code+"' order by ID ASC";
		
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append("jwt", Token);
		let url = ServerUrl+'/fetchBySql_controller.php';
		
		axios.post(url, fd, config).then(result=>{ 
			setWalletTransaction(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}


	const fetchWallet =()=>{
		var sql ="Select c.fullname, c.email, c.telephone, c.imageUrl, c.gender, w.ID, w.wallet, w.balance, w.lienAmount, w.isActive, w.dateTimeCreate, w.lastUsed from tbl_wallet w, tbl_customers c where c.code = w.usercode and usercode ='"+params.code+"' Limit 1";
		 
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append("jwt", Token);
		let url = ServerUrl+'/fetchBySql_controller.php';
		
		axios.post(url, fd, config).then(result=>{
			setWallet(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}
	const fetchLga =()=>{
	
		var sql ="Select ID, stateId, lgaName from tbl_lga";
		
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append("jwt", Token);
		let url = ServerUrl+'/fetchBySql_controller.php';
		
		axios.post(url, fd, config).then(result=>{ 
			setLga(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	
	}


	const ClearUserlog =()=>{
		setNotice({...notice,  isLoading: true });

		const fd = new FormData();
		fd.append("url",  FrontEndUrl+"/controller/loginreport.json");
		fd.append("actionType", "clearuserdata");
		fd.append("usercode", params.code);
		fd.append("jwt", Token);
		let url = ServerUrl+'/fetch_jsonData.php';
		axios.post(url, fd, config).then(result=>{  
			fetchLog();
			setNotice({...notice, 
				isLoading: false
			});
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}


	const handleDeleteWallet =()=>{
		let close =   document.getElementById('btnWarningDialog-delWallet')
		close.click();
		
		
		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'userCode')
		fd.append('data', params.code)
		fd.append("jwt", Token);
	let url = ServerUrl+'/delete_controller.php?tablename=tbl_wallet';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
			  fetchWallet()
			  fetchWalletHistory()
		  }) 
}





    const handleDelete =()=>{
		let close =   document.getElementById('btnWarningDialog-delAll')
		close.click();
		
		
		setNotice({...notice, 
			isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'code')
		fd.append('data', params.code)
		fd.append("jwt", Token);
	let url = ServerUrl+'/delete_controller.php?tablename=tbl_customers';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
			  window.open('/customers', '_self')
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 	
}


const getItemLabel = (ID, mainData, recordName) =>{ 
	const result = mainData.filter((list)=>list.ID===ID);
const answer = result.map((c)=>c[recordName])
	 return  String(answer);
   }

const getTagData=(field, mainData, recordName)=>{
	var value = [];
		var item;
  JSON.parse(field).map((itm, idx)=>{
	item ={value:itm.ID, label:getItemLabel(itm.ID, mainData, recordName), key:idx}
		value.push(item);
		return ''
	});
	
  return value;
  
  }

const handleSelectMultiple = (option, action)=>{
	const newContent =JSON.parse(JSON.stringify(content))
	var value = [];
		  for (var i = 0, l = (option || []).length; i < l; i++) {
			value.push({ID:option[i].value});
		  }
		  newContent[0][action.name] = JSON.stringify(value)
		  setContent(newContent);
  }

    const fetchContent =()=>{
        setNotice({...notice, 
            isLoading: true
        });
       
        var sql ="Select * from tbl_customers where code = '" +params.code+"'";
        
         
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php';
        fd.append("jwt", Token);
        axios.post(url, fd, config).then(result=>{ 

			if(result.data.length!==0){
            setContent(result.data)
				setBillingAddress(JSON.parse(result.data[0].address)) 

			}
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            });
        }) 

  }
	
	
  const handleChangeTransaction =(event)=>{ 
		const {name, value} = event.target;
		const newWallet = [...wallet]
		newWallet[0] = {...newWallet[0], [name]:value}
		setWallet(newWallet)
	}


	const handleChange =(event)=>{
	const newContent =JSON.parse(JSON.stringify(content))  
		let {name, value} = event.target;
		if(name==='isActive'){
			value =value ==='true'?'false':'true'
		}	
		if(name==='isNewsletter'){
			value = value==='false'?'true':'false'
		}
		newContent[0][name]= value
		setContent(newContent);
		setErrors({...errors, [name]:''});
	}
 
const changePassword =(event)=>{
	const {name, value} = event.target;
	setPassword(value);
}

    function updatePassword(){  
		if(password===''){
			Alerts('Error!', 'danger', 'Please enter your new password')
		}else{
		 setNotice({...notice, 
		  isLoading: true})     
		  
		  const fd = new FormData();
		  fd.append('password', password)
		  fd.append('code', params.code)
		  fd.append("jwt", Token);
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_customers_password';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){	
				Alerts('Saved!', 'success', response.data.message)
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
			})  
	}
}
	
    function submit(){  
		setNotice({...notice, 
		  isLoading: true})     
		     
		  let formData = content[0];
		  const fd = new FormData();
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  fd.append("jwt", Token);
			 let url = ServerUrl+'/update_controller.php?tablename=tbl_customers';
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='success'){
				
				Alerts('Saved!', 'success', response.data.message)
				window.open(defaultUrl, '_self')
					} else{
				Alerts('Error!', 'danger', response.data)
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isLoading: false
				})
				setIsSubmitting(false);
			}) 
	}
	
	const handleSubmit = (event, url) =>{
	  event.preventDefault();         
	  setErrors(ValidateInput(content[0]));
		  setIsSubmitting(true);
		  setDefaultUrl(url)
	}
	
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
	},[errors, isSubmitting])
  
	const fetchGroup =()=>{
		var sql ="Select ID, groupName from tbl_groups order by groupName ASC" ;
		const fd = new FormData();
		fd.append("sql", sql);
		fd.append("jwt", Token);
		let url = ServerUrl+'/fetchBySql_controller.php';
		axios.post(url, fd, config).then(result=>{  
			setGroup(result.data)
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		})
	}
	const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
        
        if(!values.fullname){
            errors.fullname =Tooltip.required;
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }
       
       
        return errors;
		}
		
	const fetchOrders =()=>{
			setNotice({...notice, 
				isDataFetching: true
			});
		   
			var sql ="Select ID, orderId, orderStatus, shippingType, shippingStatus, customerId,  dateCreate, orderTotal from tbl_orders where customerId = '"+params.code+"' order by ID DESC"
			const fd = new FormData();
			fd.append("sql", sql);
			fd.append("jwt", Token);
			let url = ServerUrl+'/fetchBySql_controller.php';
			
			axios.post(url, fd, config).then(result=>{  
				setOrders(result.data)
			})
			.catch((error)=>{
				Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				setNotice({...notice, 
					isDataFetching: false
				});
			}) 
	  }



  const tableOrderHeader = [
        
	{dataField: 'orderId',  text: 'Order #',  sort: true, editable: false},
	{dataField: 'orderStatus',  text: 'Order Status',  sort: true, editable: false, formatter:(cell)=>cell==='Complete'?<div className="btn-success text-center">{cell}</div>:<div className="btn-danger text-center">{cell}</div>},

	{dataField: 'shippingType',  text: 'Shipping Type',  sort: true, editable: false, formatter:(cell)=><span style={{display:'block', width:100,  whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},

	{dataField: 'shippingStatus',  text: 'Shipping Status',  sort: true, editable: false, formatter:(cell)=><span style={{display:'block',  width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
	{dataField: 'dateCreate',  text: 'Created On',  sort: true, editable: false},
	{dataField: 'orderTotal',  text: 'Total ('+Currency+')',  sort: true, editable: false, formatter:(cell)=>FormatNumber(cell)},
	 {dataField: 'guid', text: 'Action', formatter: (cell, row)=><a href={`/orders/edit/${row.orderId}`} className="btn btn-success btn-sm" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a>, editable: false},
		   
		 ];
		
		 const TableOrderRecord=(props)=>{
			
			 const customTotal = (from, to, size) => (
				<span >&nbsp;Showing { from } to { to } of { size } items</span>
			  );
			 const options = {
				showTotal: true,
			  paginationTotalRenderer: customTotal,
			  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
			  { text: 'All', value: props.data.length }]
			   
			  };
			 return  <ToolkitProvider search columnToggle 
						 keyField='ID' data={ props.data } columns={ props.columns } >
							 
							 {
								 props => (
								 <div >
								  
									 
						 <BootstrapTable 
							{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
				  
									 />
								 </div>
								 )
							 }
		  </ToolkitProvider>
		  }
		  

		


		  const tableBillingHeader = [
        
			{dataField: 'address',  text: 'Address',  sort: true, editable: false, formatter:(cell, row)=><div style={{display:'block',  width:300, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</div>},
			{dataField: 'country',  text: 'country',  sort: true, editable: false},
			{dataField: 'state',  text: 'State',  sort: true, editable: false},
			{dataField: 'lga',  text: 'LGA',  sort: true, editable: false},
			{dataField: 'isDefault',  text: 'Default',  sort: true, editable: false},
				   	   
				 ];
				
				 const TableBillingRecord=(props)=>{
					 
					 const customTotal = (from, to, size) => (
						<span >&nbsp;Showing { from } to { to } of { size } items</span>
					  );
					 const options = {
						showTotal: true,
					  paginationTotalRenderer: customTotal,
					  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
					  { text: 'All', value: props.data.length }]
					   
					  };
					 return  <ToolkitProvider search columnToggle 
								 keyField='ID' data={ props.data } columns={ props.columns } >
									 
									 {
										 props => (
										 <div >
										  
											 
								 <BootstrapTable 
									{ ...props.baseProps }
									striped
								hover
								condensed
								noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
							pagination={paginationFactory(options) }
						  
											 />
										 </div>
										 )
									 }
				  </ToolkitProvider>
				  }





	const tableHeader = [
	{dataField: 'dateTime',  text: 'Date Login',  sort: true, editable: false},
	{dataField: 'browser',  text: 'Browser',  sort: true, editable: false},
	{dataField: 'device',  text: 'Device',  sort: true, editable: false},
	{dataField: 'ip',  text: 'IP',  sort: true, editable: false}
	];
			
			
		 const TableRecord=(props)=>{
				const { SearchBar } = Search;  
				const customTotal = (from, to, size) => (
				<span >&nbsp;Showing { from } to { to } of { size } items</span>
				);
				const options = {
				showTotal: true,
				paginationTotalRenderer: customTotal,
				sizePerPageList: [{text: '70', value: 70}, {text: '150', value: 150}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
				{ text: 'All', value: props.data.length }]
				
				};
				return  <ToolkitProvider search columnToggle 
							keyField='dateCreate' data={ props.data } columns={ props.columns } >
								
								{
									props => (
									<div >
									<SearchBar
							{ ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />

<div className="dt-buttons btn-group pull-right"> 
	<a href="#!" onClick={()=>ClearUserlog()} className="btn btn-outline-danger" > <i className="fa fa-trash"></i>Clear Log</a>         
		</div>
							<BootstrapTable 
							{ ...props.baseProps }
							striped
						hover
						condensed
						noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
					pagination={paginationFactory(options) }
					
										/>
									</div>
									)
								}
			</ToolkitProvider>
			}
				
			

	const fetchLog =()=>{

	const fd = new FormData();
	fd.append("url", FrontEndUrl+"/controller/loginreport.json");
	fd.append("actionType", "getData");
	fd.append("jwt", Token);
	let url = ServerUrl+'/fetch_jsonData.php';
	axios.post(url, fd, config).then(result=>{  
		setLogList(JSON.parse(result.data))
		
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}




const tableWalletHeader = [
          
{dataField: 'dateTime',  text: 'Date',  sort: true, editable: false, headerStyle: {backgroundColor: '#f0ff4d'}},
{dataField: 'transactionType',  text: 'Type',  sort: true, editable: false, headerStyle: {backgroundColor: '#f0ff4d'}},
{dataField: 'wallet',  text: 'Wallet',  sort: true, editable: false, headerStyle: {backgroundColor: '#f0ff4d'}},
{dataField: 'description',  text: 'Description',  sort: true, formatter:(cell)=><span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>, headerStyle: {backgroundColor: '#f0ff4d'}},
{dataField: 'debit',  text: 'Debit ('+Currency+')',  sort: true, editable: false, formatter:(cell, row)=>row.transactionMode==='Debit'?FormatNumber(row.amount):'', headerStyle: {backgroundColor: '#f0ff4d'}},

{dataField: 'credit',  text: 'Credit ('+Currency+')',  sort: true, editable: false, formatter:(cell, row)=>row.transactionMode==='Credit'?FormatNumber(row.amount):'', headerStyle: {backgroundColor: '#f0ff4d'}},
{dataField: 'balance',  text: 'Balance ('+Currency+')',  sort: true, editable: false, formatter:(cell)=>FormatNumber(cell), headerStyle: {backgroundColor: '#f0ff4d'}},
{dataField: 'ID',  text: 'Action',  sort: true, editable: false, headerStyle: {backgroundColor: '#f0ff4d'}, formatter:(cell, row)=><div>
	
<WarningModal  handleAction={(e)=>handleDeleteTransaction(row.ID, row.transactionMode, row.amount)} mID={row.ID} />

<button type="button" className="btn btn-warning btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.ID}`} title="Delete"><i className="icofont icofont-delete-alt"></i> Delete</button></div>},
   
 ];

 const TableWalletRecord=(props)=>{

	const handleUpdate=(column, newValue, ID)=>{       
		const fd = new FormData(); 
			fd.append('newValue', newValue);
			fd.append('column', column.dataField);
			fd.append('ID', ID);
			fd.append("jwt", Token);
		   let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_wallet_transaction'
		  axios.post(url, fd, config)
		  //.then(result => console.log(result.data))
		  .then()
		  .catch((error)=>Alerts('Error!', 'danger', error.message)) 
		}



	 const { SearchBar } = Search;
	 const { ToggleList } = ColumnToggle;
	 //const { ExportCSVButton } = CSVExport;
	 const customTotal = (from, to, size) => (
		<span >&nbsp;Showing { from } to { to } of { size } items</span>
	  );
	 const options = {
		showTotal: true,
	  paginationTotalRenderer: customTotal,
	  sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
	  { text: 'All', value: props.data.length }]
	   
	  };
	 return  <ToolkitProvider search columnToggle 
				 keyField='ID' data={ props.data } columns={ props.columns } >
					 
					 {
						 props => (
						 <div >
						   <div className="form-group">
						   <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
						   
						   <div className="dt-buttons btn-group pull-right">
<div className="btn-group dropdown-split-success">
  
  <button type="button" className="btn btn-primary btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <i className="fa fa-search-plus"></i> Columns
  </button>
  
  <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'220px', width:'200px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
  <CustomToggleList { ...props.columnToggleProps } />
  </div>
  </div>
							 
							  

<div className="btn-group dropdown-split-primary">
<button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
	 <i className="fa fa-download"></i>Export
</button>
<div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
	<a className="dropdown-item waves-effect waves-light" href={ServerUrl+'/exportCsv_controller.php?tablename=tbl_wallet_transaction&recordType=allfound'}>Export to Excel </a> 
	<a href="#!"  className="dropdown-item waves-effect waves-light" >Print as PDF</a>
</div>
</div>         
			     
</div>                   
				 </div>
							 
				 <BootstrapTable 
					{ ...props.baseProps }
					striped
				hover
				condensed
				noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
			pagination={paginationFactory(options) }
			cellEdit={ cellEditFactory({
				mode: 'dbclick',
				blurToSave: true,
				autoSelectText: true,
	  beforeSaveCell: (oldValue, newValue, row, column) => {
		if(oldValue !==newValue){
		handleUpdate(column, newValue, row.ID);
		
	   return '';
	  }
	  
	}
			  }) }
							 />
		 </div>
			 )
					 }
  </ToolkitProvider>
  }
  


  const handleSaveTransaction =()=>{
	  if(transaction.amount===0){
		Alerts('Error!', 'danger', 'Transaction amount must be greater than 0')
	  }else{
	setNotice({...notice, 
		isLoading: true})     
		  
		
		let formData = transaction;
		const fd = new FormData();
		for(let k in formData){
		  fd.append(k, formData[k])
		}
		fd.append("jwt", Token);
		   let url = ServerUrl+'/save_controller.php?tablename=tbl_save_transaction';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  
			  Alerts('Saved!', 'success', response.data.message)
			  
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
			  setTransaction({
				isLien:false,
				isTransaction:false,
				transactionType:{value: "Deposit", label: "Deposit", transactionType: "Credit"},
				typeLabel:'Deposit',
				typeValue:'Credit',
				staffCreate:staffCode,
				usercode:params.code,
				wallet:'',
				description:'',
				amount:0,
				balance:0
			})
			 fetchWallet()
			 fetchWalletHistory()
		  }) 
		}
}

const handleCreateWallet =()=>{
	setNotice({...notice, 
		isLoading: true})     
	
		const fd = new FormData();
		fd.append('userCode', params.code)
		   fd.append('staffCreate', staffCode)
		   fd.append("jwt", Token);
		   let url = ServerUrl+'/save_controller.php?tablename=tbl_wallet';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  
			  Alerts('Saved!', 'success', response.data.message)
			  
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
			 fetchWallet()
			 fetchWalletHistory()
		  }) 
}

const handleUpdateWallet=(newValue, column)=>{  
	setNotice({...notice, 
		isLoading: true
	})     
	const fd = new FormData(); 
		fd.append('newValue', newValue);
		fd.append('column', column);
		fd.append('ID', wallet[0].ID);
		fd.append("jwt", Token);
	   let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_wallet'
	  axios.post(url, fd, config)
	  //.then(result => console.log(result.data))
	  .then()
	  .catch((error)=>Alerts('Error!', 'danger', error.message))
	  .finally(()=>{
		setNotice({...notice, 
			isLoading: false
		})
	   fetchWallet()
	})  
	}  

	const handleTransactionAmount =(event)=>{
		const {name, value} = event.target;
		var balance = wallet[0].balance
		if(transaction.typeValue==='Credit'){
			balance = Number(balance) + Number(value)
		}else if(transaction.typeValue==='Debit'){
			balance = Number(balance) - Number(value)
		}
		setTransaction({...transaction, [name]: value, balance:balance, wallet:wallet[0].wallet, usercode:params.code});
		setErrors({...errors, [name]:''})
	}

	const handleDescription =(event)=>{
		const {name, value} = event.target;
		setTransaction({...transaction, [name]: value});
	}

	const handleSelect = (option, action)=>{
	setTransaction({...transaction, [action.name]: option, typeValue:option.transactionType, typeLabel:option.label, amount:0});
	 }



	 const handleDeleteTransaction =(ID, transactionMode, amount)=>{
		let close =   document.getElementById('btnWarningDialog-'+ID)
		close.click();
		 
	var balance = wallet[0].balance
		if(transactionMode==='Credit'){
			balance = Number(balance) - Number(amount)
		}else if(transactionMode==='Debit'){
			balance = Number(balance) + Number(amount)
		}
	
		
		setNotice({...notice, 
			isLoading: true}) 
	
		const fd = new FormData();
		fd.append('ID', ID)
		fd.append('wallet', wallet[0].wallet)
		fd.append('balance', balance)
		fd.append("jwt", Token);
	let url = ServerUrl+'/update_controller.php?tablename=tbl_update_transaction';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'success', response.data.message)
				  } else{
			  Alerts('Error!', 'danger', response.data)
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
			  setNotice({...notice, 
				  isLoading: false
			  })
			  fetchWallet()
			 fetchWalletHistory()
		  }) 	
	}
	
	

    

	const newWishList =	wishlist.map(e=>e['productCode'])
	.map((e,i,final)=>final.indexOf(e)===i&&i)
	.filter(e=>wishlist[e])
	.map(e=>wishlist[e])  

	
   
    
	const fetchProduct =()=>{
	  var sql ="Select ID, productName, coverUrl, attributes, code, price from  tbl_products where isPublished ='true'" ;
	  const fd = new FormData();
	  fd.append("sql", sql);
	  fd.append('jwt', Token)
	  let url = ServerUrl+'/fetchBySql_controller.php';
	  axios.post(url, fd, config).then(result=>setProducts(result.data))
	  .catch((error)=>{
		  Alerts('Error!', 'danger', error.message)
	  })
  }

const fetchWishlist =()=>{    

const fd = new FormData();
fd.append("url", FrontEndUrl+"/controller/wishlist.json");
fd.append("actionType", "getData");

fd.append('jwt', Token)
let url = ServerUrl+'/fetch_jsonData.php';
axios.post(url, fd, config).then(result=>{  
  let answer = result.data.length!==0?JSON.parse(result.data):[]
        setWishlist(answer.length!==0?answer.filter(item=>item.customerCode===params.code):[])
})
.catch((error)=>{
  Alerts('Error!', 'danger', error.message)
})
}

const fetchProductByField =(code,  field)=>{
	const result = products.filter(list=>list.code===code);
  const answer = result.map(ans=>ans[field])
	return String(answer)
  }

	const tableWishHeader = [
        

		{dataField: '',  text: '#', editable: false, footer:'#', formatter:(cell, row, index)=>index+1},
		{dataField: 'productCode', text: 'Picture', footer:'Picture',  editable: false, formatter:(cell, row)=><img id={row.ID} className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/noImage.png"}} src={`${PictureUrl}/product/${fetchProductByField(row.productCode, 'coverUrl')}`} title={row.productCode}  alt={row.productCode} />},
		
		
		{dataField: 'productName', text: 'Product Name', footer:'Product Name', editable: false, sort: true, formatter:(cell, row)=><div><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}><a href={`/products/edit/${row.productCode}`} target="_blank" rel="noopener noreferrer">{fetchProductByField(row.productCode, 'productName')}</a></span><b>Code:</b> {fetchProductByField(row.productCode, 'sku')}</div>},
		
		
		{dataField: 'dateCreate',  text: 'Date Added', footer:'Date Added',  sort: true, editable: false}
			   
			 ];
			
			 const TableWishRecord=(props)=>{
				 const { SearchBar } = Search;  
				 const customTotal = (from, to, size) => (
					<span >&nbsp;Showing { from } to { to } of { size } items</span>
				  );
				 const options = {
					showTotal: true,
				  paginationTotalRenderer: customTotal,
				  sizePerPageList: [{text: '70', value: 70}, {text: '150', value: 150}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
				  { text: 'All', value: props.data.length }]
				   
				  };
				 return  <ToolkitProvider search columnToggle 
							 keyField='code' data={ props.data } columns={ props.columns } >
								 
								 {
									 props => (
									 <div >
									
							 <BootstrapTable 
								{ ...props.baseProps }
								striped
							hover
							condensed
							noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
						pagination={paginationFactory(options) }
					  
										 />
									 </div>
									 )
								 }
			  </ToolkitProvider>
			  }
			  
			 


useEffect(()=>{
	fetchContent()
	 fetchGroup()
	fetchLga()
	fetchWallet()
	fetchProvince()
	fetchOrders()
	fetchVendor()
	fetchLog()
	fetchWalletHistory() 
	fetchWishlist()
	fetchProduct()
},[]);	
	
        return (<Template 
			menu="People"
		submenu="Customers">
		  
		 {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   
   <div  className="main-body">
				<div className="page-wrapper">
  
  <PageHeader title="Edit Customers" description='Update customer details' icon='feather icon-user'>
  <li className="breadcrumb-item"><a href="/customers">Customers</a> </li>
					</PageHeader>
		

{/* The Page Body start here */}
{content&&content.map((data, id)=>
<div key={id} className="page-body">
								
			<div className="row">
          <div className="col-md-12">
                  <div className="card">
              <div className="card-block">
                 

			  <section className="col-sm-12">
				  
	<button type="button" className="btn btn-inverse btn-sm" onClick={(e)=>handleSubmit(e, '/customers')} ><i className="fa fa-save"></i> Save & Return</button>&nbsp;

<button type="button" onClick={(e)=>handleSubmit(e, '/customer/edit/'+params.code)} className="btn btn-inverse btn-sm"><i className="fa fa-edit"></i> Save & Edit</button>&nbsp;&nbsp;
	<a href="/customers" className="btn btn-warning btn-sm"><i className="fa fa-arrow-left"></i> Return</a>&nbsp;

	<button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}><i className="icofont icofont-delete-alt"></i> Delete</button>
	<WarningModal  handleAction={()=>handleDelete()} mID={'delAll'} /> 
	<WarningModal  handleAction={handleDeleteWallet} mID={'delWallet'} /> 

	<div className="pull-right">
	<button type="button"  className="btn btn-primary btn-sm "><i className="feather icon-log-in"></i>  Login as this customer </button>
  </div>

			</section>
            </div>
            
            </div>
  
            </div>
            
            </div>

			
			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Customer info</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                           
						<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Email Address" toolTip={Tooltip.customerEmail} />
					</div>
									
						<div className="col-sm-9 ">

							<div className="input-group">
						<input type="text" onChange={handleChange} disabled={true} name="email" value={data.email} className={errors.email  ? 'form-control formerror' : 'form-control '}/>
							<span className="required-span"> *</span>
							
							</div>
							<span className="error">{errors.email}</span>
							</div>
					</div>




					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Password" toolTip={Tooltip.password} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="password" autoComplete="off" onChange={changePassword}   name="password" value={password} className='form-control ' />
							
							<span className="input-group-addon" onClick={updatePassword} id="basic-addon">Change Password</span><span className="required-span"> *</span>
							</div>
							<span className="error">{errors.password}</span>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Fullname" toolTip={Tooltip.fullname} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="fullname" value={data.fullname} className={errors.fullname  ? 'form-control formerror' : 'form-control '}/>
						<span className="required-span"> *</span>
							</div>
							<span className="error">{errors.fullname}</span>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Gender" toolTip={Tooltip.gender} />
					</div>
									
						<div className="col-sm-9 ">
						
			<div className="form-radio">
						<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Male' checked={data.gender==='Male'?true:false} />
						<i className="helper"></i>Male
					</label>
				</div>
				<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Female' checked={data.gender==='Female'?true:false} />
						<i className="helper"></i>Female
					</label>
				</div>
				</div>

							</div>
					</div>



					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Date of birth" toolTip={Tooltip.birthday} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="date" onChange={handleChange}   name="birthDate" value={convertDate(data.birthDate)} className='form-control'/>
							
							</div>
							</div>
					</div>
					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Telephone" toolTip={'Customer Telephone'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="telephone" value={data.telephone} className='form-control'/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Company Name" toolTip={Tooltip.companyName} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="company" value={data.company} className='form-control'/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Subscribe to Newsletter" toolTip={'Subscribe to newsletter'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.isNewsletter} name="isNewsletter" checked={data.isNewsletter==='true'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

					<div className="form-group row">
			<div className="col-sm-3">
					<FormLabel name ="Active" toolTip={'Determine if the customer is activate'}  />
			</div>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={data.isActive} name="isActive" checked={data.isActive==='true'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>

			<div className="form-group row">
				<div className="col-sm-3">
						<FormLabel name ="Manager of Vendor" toolTip={'Choose a vendor associated to this customer account. When associated this customer will be able to login to the chosen vendor portal and manage his products and orders.'} />
				</div>
								
					<div className="col-sm-9 ">
				<div className="input-group">
				<select  name="vendors" value={data.vendors} className="form-control" onChange={handleChange}>
				<option value="no vendor">not a  vendor</option>
				{vendor&&vendor.map((node, id)=>{
					return <option  key={id} value={node.code}>{node.vendorName}</option>
				})}
				</select>
						
						</div>
						</div>
				</div>

			



			<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Admin Comment" toolTip={Tooltip.adminComment} />
		</div>
						
			<div className="col-sm-9 ">
				
			<textarea type="text" className="form-control" rows="5" name="comment" value={content.comment} onChange={handleChange}>{content.comment}
			</textarea>

				</div>
		</div>
		<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Created On" toolTip={'The date the customer record is created.'} />
		</div>
						
			<div className="col-sm-9 ">{longDate(data.dateCreate)}

				</div>
		</div>
		<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Last Used IP" toolTip={'The last used IP address.'} />
		</div>
						
			<div className="col-sm-9 ">{data.lastIp}

				</div>
		</div>
		<div className="form-group row">
		<div className="col-sm-3">
				<FormLabel name ="Last Activity" toolTip={'The last activity date.'} />
		</div>
						
			<div className="col-sm-9 ">{data.lastUse}

				</div>
		</div>
	</section></div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>




							<div className="row  " id='wallet'>
			<div className="col-sm-12">
								

	<div className="card z-depth-0" >
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, wallet:!panel.wallet})}>
			<h5><i className="fa fa-money"></i> Wallet Account</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.wallet?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
	{panel.wallet?	<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                  

       	 <div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
		  <div className="box box-widget">
            <div className="box-header with-border">
		
		{wallet.length===0?<button type="button" onClick={handleCreateWallet}  className="btn btn-success btn-sm" >Create Wallet Account for this Customer?</button>:	
		
		<div className="col-lg-12 alerts">
            	  <div className="row">
				
				      <div className="col-sm-5">
                        <div className="user-block">
                     <img className="img-circle img-100" title={wallet[0].gender} onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/"+wallet[0].gender+".png"}} src={FrontEndUrl+"/users/"+wallet[0].imageUrl} alt="User " />
                            <span className="username"> {wallet[0].fullname}  </span>
                            <span className="description" style={{fontSize:'13px'}}><b>{wallet[0].wallet}</b>&nbsp;
							<br />{wallet[0].isActive ==='true'?
							<i title="Wallet is active" className="fa fa-check  badge-inverse-success fa-2x"></i>:
							<i title="Wallet is disabled" className="fa fa-times  badge-inverse-danger fa-2x"></i>}
							
							<br />
								
								Available Balance: {Currency+FormatNumber(Number(wallet[0].balance)- Number(wallet[0].lienAmount))}<br />
								
								Ledger Balance: {Currency+FormatNumber(wallet[0].balance)}<br />
								</span>
								<br/>
								<span style={{fontSize:'13px', color:'#000000'}}>Last Transaction: {longDate(wallet[0].lastUsed)} <br/> Wallet Created On: {longDate(wallet[0].dateTimeCreate.slice(0, 10))} {wallet[0].dateTimeCreate.slice(11, 22)} </span>
                        </div>       
                    </div>
                    <div className="col-sm-3">
                        <ul className="list-unstyled">
                        
                        </ul>
                    </div>
                    <div className="col-sm-4">
                        <ul className="list-unstyled">
                        
                        
                        <li><b>Mobile:</b> {wallet[0].telephone}           
						 <div className="btn-group-horizontal">
                
<button type="button"  className="btn btn-instagram btn-sm"><i className="fa fa-commenting-o"></i> Send SMS</button>
            </div></li><br/>
                         <li><b>Email:</b><a target="_blank" rel="noopener noreferrer" href={`mailto:${wallet[0].email}`}> {wallet[0].email}</a>
            <div className="btn-group-horizontal">
				
<button type="button"  className="btn btn-instagram btn-sm"><i className="fa fa-envelope-o"></i> Send Email</button>
                
            </div></li> 
                        </ul>
                    </div>
                </div>
				
				
                <div className="row">
                    <div className="col-sm-8"><br/>
            <div className="btn-group-horizontal">
			{wallet[0].isActive ==='true'? <button type="button" className="btn btn-danger btn-sm" onClick={()=>handleUpdateWallet('false', 'isActive')} >Disable Wallet</button>:
			<button type="button" className="btn btn-primary btn-sm" onClick={()=>handleUpdateWallet('true', 'isActive')} >Enable Wallet</button>}&nbsp;&nbsp;
                <button type="button"  className="btn btn-success btn-sm" onClick={()=>setTransaction({...transaction, isTransaction:true})} >Add Transaction</button>&nbsp;&nbsp;
				<a href="/wallet" className="btn btn-inverse btn-sm" >View All Wallet</a>
            </div>
                    </div>
                    <div className="col-sm-4"><br/>
                        <div className="pull-left">
						<button type="button" className="btn btn-pinterest btn-sm" data-toggle="modal"   data-target={`#warningdialog-${'delWallet'}`} >Delete Wallet</button> &nbsp;&nbsp;
						<button type="button" className="btn btn-facebook btn-sm" onClick={()=>setTransaction({...transaction, isLien:true})} >Place Lien on Wallet</button>
						
                        </div>
                    </div>
                </div>             
            </div> }
        </div>
            </div>
			</div>
    
    </div></div>
					
	</section></div></div></div>
			
	{transaction.isTransaction?
	<div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
						<h3> Add Transaction</h3><hr/>
						<div className="form-group row">
				<div className="col-sm-3">
						<FormLabel name ="Type" toolTip={'Selection transaction type'} />
				</div>
								
					<div className="col-sm-9 ">


					<Select options={
						[{value:'Credit', label:'Credit', options:[
							{value:'Deposit', label:'Deposit', transactionType:'Credit'},
							{value:'Interest', label:'Interest', transactionType:'Credit'},
							{value:'Dividend', label:'Dividend', transactionType:'Credit'},
							{value:'Transfer In', label:'Transfer In', transactionType:'Credit'},
							{value:'Bonus', label:'Bonus', transactionType:'Credit'},
							{value:'Debit Reverse', label:'Debit Reverse', transactionType:'Credit'}
						]},
						
						{value:'Debit', label:'Debit', options:[
							{value:'Withdrawal', label:'Withdrawal', transactionType:'Debit'},
							{value:'Bank Fees', label:'Bank Fees', transactionType:'Debit'},
							{value:'Transfer Out', label:'Transfer Out', transactionType:'Debit'},
							{value:'Commission', label:'Commission', transactionType:'Debit'},
							{value:'Purchase', label:'Purchase', transactionType:'Debit'}
						]}]
				}
				formatGroupLabel={formatGroupLabel} 
                
onChange={handleSelect}  name="transactionType"   value={transaction.transactionType} styles={selectStyles} />

						</div>
				</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Amount" toolTip={'Transaction Amount'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="number" onChange={handleTransactionAmount}   name="amount" value={transaction.amount} className='form-control'/>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Description" toolTip={'Transaction Description (Optional)'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<textarea rows="3" onChange={handleDescription}   name="description" value={transaction.description} className='form-control'>{transaction.description}</textarea>
							
							</div>
							</div>
					</div>

					<div className="form-group row">
					<div className="col-sm-3">
							
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						
							<button type="button" className="btn btn-facebook btn-sm" onClick={handleSaveTransaction} >Save</button>
							&nbsp;
							<button type="button" className="btn btn-danger btn-sm" onClick={()=>setTransaction({...transaction, isTransaction:false})}  >Close</button>
							</div>
							</div>
					</div>


						</section></div></div></div>:''}




		{transaction.isLien?					
	<div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">

						<div className="row">
						<div className="card bg-success">
<p>Below, enter the lien balance amount. The customer will not be allowed to use the wallet if the balance of the account will be less than the lien amount after the withdrawal. For example if the current balance is 10,000 and you put the Lien on Wallet as 8,000, then the customer can use a maximum of 2,000. If the current balance is 7,000, then the customer will not be allowed to use the account since the balance is less than the lien balance of 8,000.<br/>

This is particularly useful if the customer must have a certain amount before it can be use and  you want to ensure that the customer account has a minimum balance amount.</p>
					</div></div>


					<div className="form-group row">
					<div className="col-sm-3">
							<FormLabel name ="Lien Amount" toolTip={'Lien Amount to Place on wallet'} />
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="number" onChange={handleChangeTransaction}   name="lienAmount" value={wallet[0].lienAmount} className='form-control'/>
							
							</div>
							</div>
					</div>


					<div className="form-group row">
					<div className="col-sm-3">
							
					</div>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						
							<button type="button" className="btn btn-facebook btn-sm"  onClick={()=>handleUpdateWallet(wallet[0].lienAmount, 'lienAmount')}  >Save</button>
							&nbsp;
							<button type="button" className="btn btn-danger btn-sm" onClick={()=>setTransaction({...transaction, isLien:false})} >Close</button>
							</div>
							</div>
					</div>


						</section></div></div></div>:''}



					{wallet.length!==0?		<div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">

						<div className="table-responsive">
        <TableWalletRecord data={walletTransaction}  columns={tableWalletHeader}  />
            </div>
						</section></div></div></div>:''}

						</div>:''}
						</div>




							</div>
	                   
                            </div>


	<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, orderHistory:!panel.orderHistory})}>
			<h5><i className="fa fa-cart-plus"></i> Order History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.orderHistory?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
	{panel.orderHistory?	<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                  

       	 <div className="row">
          <div className=" col-sm-12 table-responsive">
		<TableOrderRecord data={orders}  columns={tableOrderHeader}  /> 
            </div>
           
    
    </div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>



							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, addresses:!panel.addresses})}>
			<h5><i className="fa fa-address-book-o"></i> Billing Addresses</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.addresses?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
	{panel.addresses?	<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                  

       	 <div className="row">
          <div className="col-sm-12 table-responsive">
		<TableBillingRecord data={billingAddress}  columns={tableBillingHeader}  /> 
           
           
    
    </div></div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, shoppingCart:!panel.shoppingCart})}>
			<h5><i className="fa fa-cart-plus"></i> Customer Wishlist</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.shoppingCart?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
	{panel.shoppingCart?	<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                  

       	 <div className="row">
          <div className="col-sm-12 table-responsive">
		 
		  {notice.isDataFetching ? <TableLoader />:
        <TableWishRecord data={newWishList}  columns={tableWishHeader}  />}
           
    
    </div></div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>

							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header slide-display" onClick={()=>setPanel({...panel, log:!panel.log})}>
			<h5><i className="fa fa-list-alt"></i> Login History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className={`feather  ${panel.log?'icon-minus':'icon-plus'}`}></i></li>
				</ul>
			</div>
		</div>
	{panel.log?	<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

				<div className="card z-depth-0">
						<section className="card-block">
                                  

						<div className="row">
          <div className="col-sm-12">
          <div className="table-responsive">
		<TableRecord data={logList.filter(item=>item.code===params.code).sort(compare)}  columns={tableHeader}  /> 
            </div>
           
    
    </div></div>
					
	</section></div></div></div>
			
							</div>:''}

							</div>
							</div>
	                   
                            </div>



				</div>)}
				
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 

 );
        
}

export default React.memo(Edit) 